import _ from 'lodash';
import {
  SAVE_ENTITY_SUCCESS,
  GET_ENTITY,
  CREATE_INITIAL_ENTITY,
  SAVE_ENTITY_VALIDATION_FAILURE,
  CLEAR_VALIDATION_FAILURE,
  CLEAR_ENTITY
} from '../actions/types';

export const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_INITIAL_ENTITY:
      return {
        ...state,
        [action.entityName]: action.initialState
      };
    case GET_ENTITY:
      return {
        ...state,
        [action.entityName]: action.payload,
        errors: null
      };
    case CLEAR_ENTITY:
      return _.omit(state, [action.entityName]);
    case SAVE_ENTITY_SUCCESS:
      return {
        ...state,
        id: action.id,
        errors: null
      };
    case SAVE_ENTITY_VALIDATION_FAILURE:
      return {
        ...state,
        [action.entityName]: action[action.entityName],
        errors: action.errors
      };
    case CLEAR_VALIDATION_FAILURE:
      return {
        ...state,
        errors: null
      };
    default:
      return state;
  }
}

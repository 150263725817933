import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { permissions } from '../../constants/global';
import { hasPermission } from '../../utils/utilities';

class OrganisationAdminView extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    if (hasPermission([permissions.USER_ADMIN])) history.push('/organisation/user');
    else if (hasPermission([permissions.ORGANISATION_HOLDER_ADMIN])) history.push('/organisation/organisationMember');
    else if (hasPermission([permissions.READ_DATA])) history.push('/organisation/notification');
  }

  render() {
    return null;
  }
}

OrganisationAdminView.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(OrganisationAdminView);

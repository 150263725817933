import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { loadingSelector } from '../../../store/selectors/shared';
import { getEntity, listEntities } from '../../../store/actions/EntityActions';
import { setInitialSearchFilter, changeSearchFilter, createInitialTable } from '../../../store/actions/SearchActions';
import { style } from '../../../assets/style';
import { ALL, NO_SEARCH_VALUE, VALIDATED_FIELD_TYPES } from '../../../constants/global';
import { tableSelector } from '../../../store/selectors/table';
import { listCredentialRequest } from '../../../utils/entityRequests/credentialRequestRequests';
import CatalogList from './CatalogList';
import { listCatalogRequest } from '../../../utils/entityRequests/catalogRequests';
import ValidatedField from '../../widgets/ValidatedField';
import { listTrainingOrganisationRequest } from '../../../utils/entityRequests/referenceDataRequests';
import { entityArraySelector } from '../../../store/selectors/entity';

const styles = {
  ...style
};

class CatalogCourseListView extends React.Component {
  state = {
    trainingOrganisationUuid: '',
    searchText: ''
  };

  searchTimeout = null;

  scrollRef = React.createRef();

  searchTimeoutDuration = 500;

  async componentWillMount() {
    const { listEntities, createInitialTable, getEntity } = this.props;
    await createInitialTable('catalog');
    getEntity(listTrainingOrganisationRequest());
    listEntities(0, 10, listCatalogRequest(NO_SEARCH_VALUE, ''));
  }

  handleChange = event => {
    const { listEntities } = this.props;
    const { name, value } = event.target;

    this.setState(
      prevState => ({
        ...prevState,
        [name]: value
      }),
      () => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
          const { trainingOrganisationUuid, searchText } = this.state;
          listEntities(
            0,
            10,
            listCatalogRequest(trainingOrganisationUuid === '' ? NO_SEARCH_VALUE : trainingOrganisationUuid, searchText)
          );
        }, this.searchTimeoutDuration);
      }
    );
  };

  handleScroll = async () => {
    const { table, listEntities } = this.props;
    const { trainingOrganisationUuid, searchText } = this.state;
    const { currentPage, pageSize, payload } = table;
    const { totalRecords, records } = payload;

    if (totalRecords !== records.length) {
      await listEntities(
        currentPage + 1,
        pageSize,
        listCatalogRequest(trainingOrganisationUuid === '' ? NO_SEARCH_VALUE : trainingOrganisationUuid, searchText)
      );
    }
  };

  render() {
    const { classes, table, isLoading, organisations } = this.props;
    const { searchText, trainingOrganisationUuid } = this.state;
    return (
      <Grid container className={classNames(classes.mainContentColumn)}>
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start" justify="center">
          <Grid item xs={5}>
            <ValidatedField
              type={VALIDATED_FIELD_TYPES.SELECT}
              id="trainingOrganisationUuid"
              label="Training Provider"
              name="trainingOrganisationUuid"
              value={trainingOrganisationUuid || ALL}
              handleChange={this.handleChange}
              validators={[]}
              values={organisations ? [{ label: 'All', value: ALL }, ...organisations] : []}
              optionName="label"
              optionValue="value"
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={5}>
            <ValidatedField
              id="searchText"
              label="Search Courses"
              name="searchText"
              value={searchText}
              onChange={this.handleChange}
              validators={[]}
            />
          </Grid>
          <Grid item xs={8}>
            <CatalogList
              complete={!isLoading}
              emptyMessage="There are no courses"
              rowConfigs={table.payload ? table.payload.records : []}
              scroll={this.handleScroll}
              scrollRef={this.scrollRef}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

CatalogCourseListView.propTypes = {
  classes: PropTypes.object.isRequired,
  setInitialSearchFilter: PropTypes.func.isRequired,
  createInitialTable: PropTypes.func.isRequired,
  getEntity: PropTypes.func.isRequired,
  listCredentialRequest: PropTypes.func.isRequired,
  listEntities: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  credentialTypes: PropTypes.array,
  organisations: PropTypes.array,
  table: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading:
    loadingSelector(state, 'listEntities') ||
    loadingSelector(state, 'search-credentials') ||
    loadingSelector(state, 'getEntity'),
  ...tableSelector(state, 'catalog'),
  ...entityArraySelector(state, 'organisations')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setInitialSearchFilter,
    createInitialTable,
    changeSearchFilter,
    getEntity,
    listEntities,
    listCredentialRequest
  })
)(CatalogCourseListView);

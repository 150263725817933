import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity, listEntities, putEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { colors, IMPORT_STATUS, IMPORT_ROW_STATUS } from '../../assets/constants';
import ListingWithSearch from '../widgets/ListingWithSearch';
import ScrollableTable from '../table/ScrollableTable';
import { loadingSelector } from '../../store/selectors/shared';
import { convertUtcToLocal } from '../../utils/utilities';
import { tableSelector } from '../../store/selectors/table';
import {
  getImportRequest,
  listImportRowRequest,
  restartImportRequest
} from '../../utils/entityRequests/importRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import NewActionButton from '../widgets/NewActionButton';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style,
  tableMargin: {
    marginTop: '20px'
  },
  error: {
    color: colors.red
  }
};

class ImportView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getImportRequest(id));
  }

  getImportRows = (currentPage, pageSize) => {
    const { match, listEntities } = this.props;
    const { params } = match;
    const { id } = params;

    // Up to here
    listEntities(currentPage, pageSize, listImportRowRequest(id));
  };

  refreshData = async () => {
    const { getEntity, match, listEntities } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getImportRequest(id));
    listEntities(0, 20, listImportRowRequest(id));
  };

  getRowConfigs = () => {
    const { table, classes } = this.props;
    const items = table.payload ? table.payload.records : [];
    return items.map(item => {
      const cells = [
        {
          value: IMPORT_ROW_STATUS[item.status].label
        },
        {
          value: (
            <div className={classes.ellipsisText} title={item.error}>
              {item.error}
            </div>
          )
        }
      ];
      item.rowData.forEach(datum =>
        cells.push({
          value: datum
        })
      );

      return {
        cells
      };
    });
  };

  getListing = props => {
    const { isLoading, imported } = this.props;
    const headers = [
      {
        value: 'Status'
      },
      {
        value: 'Error'
      }
    ];

    imported.headers.forEach(item => headers.push({ value: item }));

    return (
      <ScrollableTable
        headers={headers}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Import Rows Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, imported, table, putEntity } = this.props;
    return (
      Object.keys(imported).length > 0 && (
        <Grid
          container
          className={classNames(classes.viewPage, classes.page, classes.mainContentColumn)}
          alignContent="flex-start"
        >
          <NewHeaderViewActions header="Import Details" cancelUrl="/credential/import" noMargin>
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">autorenew</i>}
              text="Refresh"
              onChange={this.refreshData}
              filled
            />
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">play_arrow</i>}
              text="Restart Import"
              onChange={() => putEntity(restartImportRequest(imported.identifier))}
              filled
            />
          </NewHeaderViewActions>
          <Grid container className={classes.formContent}>
            <Grid item xs={6}>
              <DisplayField heading="Credential Type" value={imported.credentialTypeName} />
              <DisplayField heading="Creator Name" value={imported.creatorName} />
              <DisplayField
                heading="Create Date"
                value={convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', imported.createDate)}
              />
              <DisplayField heading="Status" value={IMPORT_STATUS[imported.status].label} />

              {imported.error && <DisplayField heading="Error" value={imported.error} />}
              <DisplayField
                heading="Processed Rows"
                value={`${imported.numberProcessed} / ${imported.totalNumberRows} (${
                  imported.totalNumberRows !== 0
                    ? ((imported.numberProcessed / imported.totalNumberRows) * 100).toFixed(1)
                    : 0
                }%)`}
              />
            </Grid>
            <Grid item xs={12} className={classNames(classes.noPaddingLeftRightGrid, classes.tableMargin)}>
              <Grid container>
                <ListingWithSearch
                  name="importRow"
                  onList={this.getImportRows}
                  renderList={props => this.getListing(props)}
                  list={table}
                  hideSearch
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    );
  }
}

ImportView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  listEntities: PropTypes.func,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  imported: PropTypes.object,
  isLoading: PropTypes.bool,
  table: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'imported'),
  ...tableSelector(state, 'importRow'),
  isLoading: loadingSelector(state, 'listEntities')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, putEntity, listEntities })
)(ImportView);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { entityArraySelector } from '../../store/selectors/entity';

const CredentialHolderListBreadcrumb = ({ holderCredentials, isLoading }) => {
  const subText = `${holderCredentials ? holderCredentials.length : 0} in total`;

  return <GenericBreadcrumbItem mainText="Holder Credentials" subText={subText} isLoading={isLoading} />;
};

const mapStateToProps = state => ({
  ...entityArraySelector(state, 'holderCredentials'),
  isLoading: loadingSelector(state, 'getEntity')
});

CredentialHolderListBreadcrumb.propTypes = {
  holderCredentials: PropTypes.array,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(CredentialHolderListBreadcrumb);

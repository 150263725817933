import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { documentDownloadsSelector } from '../../store/selectors/document';
import { getEntity, putEntity, saveEntity } from '../../store/actions/EntityActions';
import { listUserFileDownloads, deleteUserFileDownload } from '../../store/actions/DocumentActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import ShowUser from '../common/ShowUser';
import {
  disableHolderRequest,
  enableHolderRequest,
  getHolderRequest,
  lockHolderRequest,
  recreateCognitoHolderRequest,
  resetHolderLoginAttemptRequest,
  resetHolderPasswordRequest,
  unlockHolderRequest
} from '../../utils/entityRequests/holderRequests';
import { entitySelector } from '../../store/selectors/entity';

const styles = {
  ...layout,
  ...style
};

class HolderView extends React.Component {
  async componentDidMount() {
    const { getEntity, match, listUserFileDownloads } = this.props;
    const { params } = match;
    const { id } = params;
    const user = await getEntity(getHolderRequest(id));
    await listUserFileDownloads(user.uuid);
  }

  updateHolderStatus = () => {
    const { match, user, putEntity, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    if (user.enabled) {
      putEntity(disableHolderRequest(id)).then(() => getEntity(getHolderRequest(id)));
    } else {
      putEntity(enableHolderRequest(id)).then(() => getEntity(getHolderRequest(id)));
    }
  };

  updateHolderLockStatus = () => {
    const { match, user, putEntity, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    if (user.locked) {
      putEntity(unlockHolderRequest(id)).then(() => getEntity(getHolderRequest(id)));
    } else {
      putEntity(lockHolderRequest(id)).then(() => getEntity(getHolderRequest(id)));
    }
  };

  resetPassword = () => {
    const { match, putEntity, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    putEntity(resetHolderPasswordRequest(id)).then(() => getEntity(getHolderRequest(id)));
  };

  resetLoginAttempts = () => {
    const { match, putEntity, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    putEntity(resetHolderLoginAttemptRequest(id)).then(() => getEntity(getHolderRequest(id)));
  };

  recreateCognitoUser = () => {
    const { match, getEntity, saveEntity } = this.props;
    const { params } = match;
    const { id } = params;

    saveEntity(recreateCognitoHolderRequest(id)).then(() => getEntity(getHolderRequest(id)));
  };

  render() {
    const { match, user, isLoading, fileDownloads, deleteUserFileDownload } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(user).length > 0 && (
        <ShowUser
          resetPassword={this.resetPassword}
          editUrl={`/admin/holder/edit/${id}`}
          updateUserLockStatus={this.updateHolderLockStatus}
          updateUserStatus={this.updateHolderStatus}
          resetLoginAttempts={this.resetLoginAttempts}
          recreateCognitoUser={this.recreateCognitoUser}
          isLoading={isLoading}
          user={user}
          overrideAuthType="INTERNAL"
          type="Holder"
          documentDownloads={fileDownloads}
          deleteUserFileDownload={deleteUserFileDownload}
        />
      )
    );
  }
}

HolderView.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  saveEntity: PropTypes.func,
  listUserFileDownloads: PropTypes.func,
  deleteUserFileDownload: PropTypes.func,
  user: PropTypes.object,
  fileDownloads: PropTypes.array,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'user'),
  ...documentDownloadsSelector(state)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    putEntity,
    saveEntity,
    listUserFileDownloads,
    deleteUserFileDownload
  })
)(HolderView);

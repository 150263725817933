import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { toggleSetting } from '../../store/actions/SettingsActions';
import { settingSelector } from '../../store/selectors/shared';
import { colors, commonFont } from '../../assets/constants';
import LinkButton from '../widgets/LinkButton';

const styles = {
  cancel: {
    ...commonFont,
    fontSize: '14px',
    color: colors.blue,
    '& img': {
      marginRight: '8px'
    },
    marginRight: '22px'
  },
  buttons: {
    marginRight: '40px',
    display: 'flex',
    flexDirection: 'row'
  },
  header: {
    marginLeft: '30px',
    ...commonFont,
    fontSize: '28px',
    fontWeight: 600,
    color: colors.blueDark
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.white,
    borderRadius: '5px',
    height: '45px',
    textTransform: 'none',
    ...commonFont,
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    marginLeft: '20px'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: '22px',
    marginBottom: '30px'
  }
};

class NewHeaderActions extends React.PureComponent {
  render() {
    const { header, classes, buttons } = this.props;
    return (
      <div className={classes.headerContainer}>
        <div className={classes.header}>{header}</div>
        <div className={classes.buttons}>
          {buttons.map(item => (
            <LinkButton
              icon={item.icon}
              primary={item.primary}
              text={item.text}
              url={item.url}
              filled
              key={`key-${item.text}`}
            />
          ))}
        </div>
      </div>
    );
  }
}

NewHeaderActions.defaultProps = {
  submitButtonText: 'Save',
  declineButtonText: 'Back',
  renderAsPortal: true,
  preserveMode: false,
  isDisabled: false,
  hasSubmitBtn: true,
  buttonClassName: ''
};

NewHeaderActions.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  buttons: PropTypes.array,
  submitButtonText: PropTypes.string,
  declineButtonText: PropTypes.string,
  renderAsPortal: PropTypes.bool,
  preserveMode: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasSubmitBtn: PropTypes.bool,
  buttonClassName: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  editMode: settingSelector(state, `${ownProps.name}`)
});

export default compose(connect(mapStateToProps, { toggleSetting }), withStyles(styles), withRouter)(NewHeaderActions);

import moment from 'moment';

import { dateFilterPresets, futureDateFilterPresets } from '../../assets/constants';

export const today = moment().local();
export const yesterday = moment()
  .subtract(1, 'day')
  .local();

export const datePresets = [
  {
    text: 'Today',
    start: today.clone().startOf('day'),
    end: today.clone().endOf('day'),
    type: dateFilterPresets.TODAY
  },
  {
    text: 'Yesterday',
    start: yesterday.clone().startOf('day'),
    end: yesterday.clone().endOf('day'),
    type: dateFilterPresets.YESTERDAY
  },
  {
    text: 'Last 7 Days',
    start: today.clone().subtract(6, 'day'),
    end: today,
    type: dateFilterPresets.LAST_7_DAYS
  },
  {
    text: 'This Week',
    start: today.clone().startOf('week'),
    end: today,
    type: dateFilterPresets.THIS_WEEK
  },
  {
    text: 'Last Week',
    start: today
      .clone()
      .startOf('week')
      .subtract(7, 'day'),
    end: today
      .clone()
      .startOf('week')
      .subtract(1, 'day'),
    type: dateFilterPresets.LAST_WEEK
  },
  {
    text: 'This Month',
    start: today.clone().startOf('month'),
    end: today,
    type: dateFilterPresets.THIS_MONTH
  },
  {
    text: 'Last Month',
    start: today
      .clone()
      .startOf('month')
      .subtract(1, 'month'),
    end: today
      .clone()
      .startOf('month')
      .subtract(1, 'day'),
    type: dateFilterPresets.LAST_MONTH
  },
  {
    text: 'This Year',
    start: today.clone().startOf('year'),
    end: today,
    type: dateFilterPresets.THIS_YEAR
  },
  {
    text: 'Last Year',
    start: today
      .clone()
      .startOf('year')
      .subtract(1, 'year'),
    end: today
      .clone()
      .startOf('year')
      .subtract(1, 'day'),
    type: dateFilterPresets.LAST_YEAR
  },
  {
    text: 'Custom Range',
    type: dateFilterPresets.CUSTOM_RANGE
  }
];

export const futureDatePresets = [
  {
    text: 'Today',
    start: today.clone().startOf('day'),
    end: today.clone().endOf('day'),
    type: futureDateFilterPresets.TODAY
  },
  {
    text: 'Next 7 Days',
    start: today.startOf('day'),
    end: today
      .clone()
      .add(6, 'day')
      .endOf('day'),
    type: futureDateFilterPresets.NEXT_7_DAYS
  },
  {
    text: 'This Month',
    start: today.clone().startOf('month'),
    end: today
      .clone()
      .startOf('month')
      .add(1, 'month')
      .subtract(1, 'day'),
    type: futureDateFilterPresets.THIS_MONTH
  },
  {
    text: 'Next Month',
    start: today
      .clone()
      .startOf('month')
      .add(1, 'month'),
    end: today
      .clone()
      .startOf('month')
      .add(1, 'month')
      .endOf('month'),
    type: futureDateFilterPresets.NEXT_MONTH
  },
  {
    text: 'Next Quarter',
    start: today
      .clone()
      .startOf('month')
      .add(1, 'month'),
    end: today
      .clone()
      .startOf('month')
      .add(3, 'month')
      .endOf('month'),
    type: futureDateFilterPresets.NEXT_QUARTER
  },
  {
    text: 'This Year',
    start: today.clone().startOf('year'),
    end: today.clone().endOf('year'),
    type: futureDateFilterPresets.THIS_YEAR
  },
  {
    text: 'Last 7 Days',
    start: today.clone().subtract(6, 'day'),
    end: today,
    type: futureDateFilterPresets.LAST_7_DAYS
  },
  {
    text: 'Last Month',
    start: today
      .clone()
      .startOf('month')
      .subtract(1, 'month'),
    end: today
      .clone()
      .startOf('month')
      .subtract(1, 'day'),
    type: futureDateFilterPresets.LAST_MONTH
  },
  {
    text: 'Last Quarter',
    start: today
      .clone()
      .startOf('month')
      .subtract(3, 'month'),
    end: today
      .clone()
      .startOf('month')
      .subtract(1, 'day'),
    type: futureDateFilterPresets.LAST_QUARTER
  },

  {
    text: 'Custom Range',
    type: dateFilterPresets.CUSTOM_RANGE
  }
];

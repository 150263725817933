import { colors } from './constants';

export default {
  editorClassName: {
    border: 'solid 1px #f1f1f1',
    padding: '8px',
    backgroundColor: colors.white,
    '& div.public-DraftStyleDefault-block': {
      margin: '2px'
    },
    '& h1': {
      margin: '2px'
    }
  },
  toolbarClassName: {
    borderBottom: 'none',
    marginBottom: 0
  },
  editorContainer: {
    marginTop: '13px'
  }
};

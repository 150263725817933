import { formInitialState } from '../entityInitialStates';

export const listAllCredentialTypeRequest = () => ({
  url: `${process.env.REACT_APP_LIST_ALL_CREDENTIAL_TYPES}`,
  entityName: 'allCredentialTypes',
  initialState: []
});

export const listCompetenceOrganisationRequest = () => {
  return {
    url: process.env.REACT_APP_LIST_ALL_COMPETENCE_ORGANISATIONS,
    entityName: 'organisations'
  };
};

export const listTrainingOrganisationRequest = () => {
  return {
    url: process.env.REACT_APP_LIST_ALL_TRAINING_ORGANISATIONS,
    entityName: 'organisations'
  };
};

export const listAssuranceOrganisationRequest = () => {
  return {
    url: process.env.REACT_APP_LIST_ALL_ASSURANCE_ORGANISATIONS,
    entityName: 'organisations'
  };
};

export const listCurrencyRequest = () => {
  return {
    url: process.env.REACT_APP_LIST_CURRENCY,
    entityName: 'currencies'
  };
};

export const listWorkplaceForOrganisationRequest = id => {
  return {
    url: `${process.env.REACT_APP_LIST_ORGANISATION_WORK_PLACE}${id}`,
    entityName: 'organisationWorkPlaces',
    initialState: []
  };
};

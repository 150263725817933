import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grow, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { colors, commonFont } from '../../assets/constants';
import { style } from '../../assets/style';
import HeadingIcon from '../../assets/images/heading-icon.svg';
import OrangeDownArrowIcon from '../../assets/images/orange-down-arrow.svg';
import OrangeUpArrowIcon from '../../assets/images/orange-up-arrow.svg';
import { settingSelector } from '../../store/selectors/shared';

import { toggleSetting } from '../../store/actions/SettingsActions';

const styles = {
  ...style,
  credentialExpander: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderBottom: `solid 1px ${colors.borderBlue}`,
    paddingBottom: '30px'
  },
  header: {
    display: 'flex',
    ...commonFont,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.blueDark,
    alignItems: 'center',
    '& > img': {
      marginRight: '9px'
    }
  },
  headerExpand: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingLeft: '21px',
    paddingRight: '26px',
    '& > img': {
      cursor: 'pointer'
    }
  },
  subheader: {
    paddingLeft: '10px',
    paddingRight: '26px',
    ...commonFont,
    fontWeight: 500,
    fontSize: '14px',
    color: colors.blue,
    marginTop: '13px'
  },
  expandTransition: {
    transitionProperty: 'opacity, left, top, width',
    transitionDuration: '2s, 1s'
  }
};

const CredentialExpanderSection = ({ classes, children, header, subHeading, open, name, toggleSetting }) => {
  return (
    <div className={classNames(classes.credentialExpander)}>
      <div className={classes.headerExpand}>
        <div className={classes.header}>
          <img src={HeadingIcon} /> {header}
        </div>
        <Tooltip
          title="Expand"
          onClick={() => {
            toggleSetting(name, !open);
          }}
        >
          <img src={open ? OrangeUpArrowIcon : OrangeDownArrowIcon} />
        </Tooltip>
      </div>
      {open && (
        <Grow
          direction="down"
          in={open}
          style={{ transformOrigin: '0 0 0', width: '100%' }}
          {...(open ? { timeout: 1000 } : {})}
        >
          <div>
            <div className={classes.subheader}>{subHeading}</div>
            <div className={classes.expandTransition}>{children}</div>
          </div>
        </Grow>
      )}
    </div>
  );
};

CredentialExpanderSection.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  header: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  toggleSetting: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  open: settingSelector(state, ownProps.name) || false
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { toggleSetting }),
  withRouter
)(CredentialExpanderSection);

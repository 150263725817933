import { style } from './style';
import { commonStyles } from './constants';
import { centerVertically, centerHorizontally } from './mixins';

export default {
  ...style,
  container: {
    ...style.flexRowCentered
  },
  link: {
    ...style.flexRowCentered,
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  arrow: {
    marginLeft: '8px',
    marginRight: '8px',
    color: '#bbb',
    fontSize: '32px'
  },
  outerContainer: {
    transition: commonStyles.transition
  },
  breadcrumbItemContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  headerText: {
    fontSize: '16px',
    fontWeight: 500,
    letterSpacing: '0.2px',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  headerSubText: {
    color: 'rgba(0, 0, 0, 0.4)',
    fontSize: '12px',
    lineHeight: 1.33,
    letterSpacing: '0.4px',
    fontWeight: 'normal',
    position: 'relative'
  },
  butLast: {
    pointerEvents: 'none',
    opacity: 0.4
  },
  loader: {
    position: 'absolute',
    ...centerVertically,
    ...centerHorizontally
  }
};

import { combineReducers } from 'redux';
import AuthenticationReducer from './AuthenticationReducer';
import GlobalErrorReducer from './GlobalErrorReducer';
import ValidationReducer from './ValidationReducer';
import SettingsReducer from './SettingsReducer';
import NotificationReducer from './NotificationReducer';
import LoadingReducer from './LoadingReducer';
import SearchReducer from './SearchReducer';
import DocumentReducer from './DocumentReducer';
import TableReducer from './TableReducer';
import EntityReducer from './EntityReducer';

export default combineReducers({
  authentication: AuthenticationReducer,
  validation: ValidationReducer,
  globalError: GlobalErrorReducer,
  settings: SettingsReducer,
  notification: NotificationReducer,
  loading: LoadingReducer,
  search: SearchReducer,
  document: DocumentReducer,
  table: TableReducer,
  entity: EntityReducer
});

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import { getEntity, putEntity, listEntities, deleteEntity } from '../../../store/actions/EntityActions';
import { style } from '../../../assets/style';
import { entityArraySelector, entitySelector } from '../../../store/selectors/entity';
import { tableSelector } from '../../../store/selectors/table';
import TrainingScheduleComponent from './TrainingScheduleComponent';

const styles = {
  ...style
};

class SchedulingView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trainingScheduleConfig: {
        courseSessionId: '',
        scheduledSessionId: '',
        scheduledCourseId: '',
        durationMinutes: 0,
        allDay: false,
        startTime: '',
        endTime: '',
        roomId: '',
        centreId: '',
        showCourseFilter: true,
        scrollToTime: moment()
          .startOf('day')
          .add(8, 'hours')
          .toDate(),
        modal: false
      }
    };
  }

  render() {
    const { classes } = this.props;
    const { trainingScheduleConfig } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, classes.flexNowrap, {
          [classes.editMode]: false
        })}
      >
        <Grid item xs={12}>
          <TrainingScheduleComponent
            closeView={this.handleCloseSchedule}
            trainingScheduleConfig={trainingScheduleConfig}
          />
        </Grid>
      </Grid>
    );
  }
}

SchedulingView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  putEntity: PropTypes.func,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  course: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'scheduledCourse'),
  ...entityArraySelector(state, 'scheduledSessions'),
  ...tableSelector(state, 'scheduledCourseHolders')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, putEntity, listEntities, deleteEntity })
)(SchedulingView);

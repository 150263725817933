import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { loadingSelector } from '../../store/selectors/shared';
import { setInitialSearchFilter, changeSearchFilter } from '../../store/actions/SearchActions';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import { INCIDENT_TYPE, RELATIONSHIP_TYPE } from '../../assets/constants';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { tableSelector } from '../../store/selectors/table';
import { convertUtcToLocal } from '../../utils/utilities';
import { NO_SEARCH_VALUE } from '../../constants/global';
import PresetDateRangePicker from '../widgets/PresetDateRangePicker';
import { listIncidentRequest } from '../../utils/entityRequests/incidentRequests';
import { listOrganisationWorkplaceRequest } from '../../utils/entityRequests/workPlaceRequests';
import { entityArraySelector } from '../../store/selectors/entity';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class IncidentListView extends React.Component {
  async componentDidMount() {
    const { getEntity, setInitialSearchFilter } = this.props;

    await setInitialSearchFilter('incidents', {
      workPlace: NO_SEARCH_VALUE,
      dates: {
        startDate: moment()
          .local()
          .startOf('month')
          .format('DD-MM-YYYY HH:mm:ss'),
        endDate: moment()
          .local()
          .endOf('day')
          .format('DD-MM-YYYY HH:mm:ss')
      }
    });
    await getEntity(listOrganisationWorkplaceRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/workplace/incident/view/${id}`);
  };

  getRowConfigs = () => {
    const { table } = this.props;
    const items = table.payload ? table.payload.records : [];

    return items.map(incident => ({
      clickAction: () => this.actionNavigate(incident.identifier),
      cells: [
        {
          value: incident.holder
            ? incident.holder.name
            : `${incident.incidentPerson.firstName} ${incident.incidentPerson.lastName}`
        },
        { value: convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', incident.incidentTime) },
        { value: INCIDENT_TYPE[incident.incidentType].label },
        { value: RELATIONSHIP_TYPE[incident.relationshipType].label },
        { value: incident.workPlace.name }
      ]
    }));
  };

  getListing = props => {
    const { isLoading, search, organisationWorkPlaces } = this.props;
    const workPlaceFilterValue = search && search.filters.workPlace ? search.filters.workPlace : NO_SEARCH_VALUE;

    return (
      <ScrollableTable
        headers={[
          { value: 'Name' },
          { value: 'Time' },
          { value: 'Incident Type' },
          { value: 'Relationship Type' },
          {
            value: 'Workplace Name',
            filter: {
              value: workPlaceFilterValue,
              options: organisationWorkPlaces || [],
              onChange: event => props.handleFilterChange(event, 'workPlace')
            }
          }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Incidents Found"
        {...props}
      />
    );
  };

  handleSelectDate = async model => {
    const { changeSearchFilter, listEntities, listIncidentRequest } = this.props;

    await changeSearchFilter('incidents', 'dates', {
      startDate: model.startDate,
      endDate: model.endDate
    });

    listEntities(0, 20, listIncidentRequest());
  };

  render() {
    const { classes, listEntities, table, organisationWorkPlaces, listIncidentRequest } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="Incident"
              buttons={[
                {
                  text: 'Add Incident',
                  icon: <AddWhiteIcon />,
                  url: '/workplace/incident/create',
                  primary: true
                }
              ]}
            />
            <PresetDateRangePicker
              className={classes.datepicker}
              startDate={moment()
                .local()
                .startOf('month')}
              endDate={moment()
                .local()
                .endOf('day')}
              onSelectDate={this.handleSelectDate}
            />
            <ListingWithSearch
              name="incidents"
              onList={listEntities}
              filtersList={{
                workPlace: organisationWorkPlaces
              }}
              renderList={props => this.getListing(props)}
              list={table}
              hideSearch
              additionalSearchParams={listIncidentRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

IncidentListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  listIncidentRequest: PropTypes.func,
  changeSearchFilter: PropTypes.func,
  setInitialSearchFilter: PropTypes.func,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object,
  organisationWorkPlaces: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities'),
  ...tableSelector(state, 'incidents'),
  ...entityArraySelector(state, 'organisationWorkPlaces')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { setInitialSearchFilter, changeSearchFilter, getEntity, listEntities, listIncidentRequest })
)(IncidentListView);

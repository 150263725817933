import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { entityArraySelector } from '../../store/selectors/entity';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { listRoleRequest } from '../../utils/entityRequests/userAdminRequests';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class RoleListView extends React.Component {
  componentDidMount() {
    const { getEntity } = this.props;
    getEntity(listRoleRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/admin/userRole/view/${id}`);
  };

  getRowConfigs = () => {
    const { roles } = this.props;
    const items = roles || [];
    return items.map(role => ({
      clickAction: () => this.actionNavigate(role.identifier),
      cells: [
        {
          value: role.name
        },
        {
          value: role.reppleOnly ? <i className="material-icons">done</i> : ''
        }
      ]
    }));
  };

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="Role"
              buttons={[
                {
                  text: 'Add Role',
                  icon: <AddWhiteIcon />,
                  url: '/admin/userRole/create',
                  primary: true
                }
              ]}
            />

            <ScrollableTable
              headers={[{ value: 'Name' }, { value: 'Repple Only' }]}
              rowConfigs={this.getRowConfigs()}
              complete={!isLoading}
              emptyMessage="No Roles Found"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

RoleListView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  roles: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  ...entityArraySelector(state, 'roles')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(RoleListView);

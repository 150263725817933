import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { colors, FILE_UPLOAD_STATUS, VALIDATORS_BY_NAME } from '../../assets/constants';
import ValidatedField from '../widgets/ValidatedField';
import { isRepple } from '../../utils/utilities';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';
import YesNoDialog from '../widgets/YesNoDialog';
import DisplayField from '../widgets/DisplayField';

const styles = () => ({
  ...style,
  ...layout,
  dialogBody: {
    fontSize: '15px',
    color: colors.grey4,
    lineHeight: '20px'
  }
});

class ChangeUser extends Component {
  isUploadingCompleted = uploads => {
    let noInComplete = 0;

    Object.keys(uploads).forEach(upload => {
      noInComplete += uploads[upload].filter(
        item =>
          !item.status ||
          item.status === FILE_UPLOAD_STATUS.UPLOADED.id ||
          (item.status === FILE_UPLOAD_STATUS.CLEAN.id && item.mimeType.startsWith('image'))
      ).length;
    });

    return noInComplete === 0;
  };

  render() {
    const {
      classes,
      isSaving,
      roles,
      permissions,
      errors,
      cancelUrl,
      user,
      handleChange,
      foundHolder,
      handleNo,
      handleYes,
      handleEmailPhoneChange,
      handleRoleChange,
      handleClose,
      type,
      handleSave,
      onDocumentChange,
      togglePermissionChange,
      documentUploads,
      checkLinkHolder
    } = this.props;
    return (
      <Grid container className={classNames(classes.mainContentColumn)}>
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={cancelUrl}
              isSaving={isSaving}
              onSubmit={handleSave}
              name="changeFormHeaderAction"
              isDisabled={!this.isUploadingCompleted(documentUploads)}
              header={`${type} Details`}
            />
            <Grid item xs={12} container className={classes.formContent}>
              <Grid item xs={6}>
                {foundHolder && (
                  <Fragment>
                    <DisplayField heading="Name" value={foundHolder.name} />
                    <DisplayField heading="Phone" value={foundHolder.phone} />
                    <DisplayField heading="Email" value={foundHolder.email} />
                  </Fragment>
                )}
                {!foundHolder && (
                  <Fragment>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          id="firstName"
                          label="First Name"
                          name="firstName"
                          value={user.firstName}
                          onChange={handleChange}
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          id="lastName"
                          label="Last Name"
                          name="lastName"
                          value={user.lastName}
                          onChange={handleChange}
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          id="email"
                          label="Email"
                          name="email"
                          value={user.email}
                          onChange={handleEmailPhoneChange}
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                          errorText={errors && errors.fieldErrors.email}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.PHONE}
                          id="phone"
                          label="Phone"
                          name="phone"
                          value={user.phone}
                          onChange={handleEmailPhoneChange}
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                          errorText={errors && errors.fieldErrors.phone}
                        />
                      </div>
                    </Grid>
                  </Fragment>
                )}

                {type === 'User' && (
                  <Fragment>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.SELECT}
                          key="role.identifier"
                          label="Role"
                          value={(roles || []).find(item => item.identifier === user.role.identifier) || null}
                          onChange={handleRoleChange}
                          items={roles || []}
                          id="role.identifier"
                          itemName="name"
                          itemValue="identifier"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    {user.role.identifier && (
                      <Grid item xs={12}>
                        <div className={classes.fieldContainer}>
                          <NewValidatedField
                            type={VALIDATED_FIELD_TYPES.CHECKBOXES}
                            key="permissions"
                            label="Permissions"
                            onChange={togglePermissionChange}
                            options={(permissions || []).map(item => {
                              return {
                                label: item.name,
                                name: item.identifier,
                                checked: user.permissions.indexOf(item.identifier) !== -1,
                                value: item.identifier
                              };
                            })}
                            id="permissions"
                            validators={[VALIDATORS_BY_NAME.REQUIRED]}
                          />
                        </div>
                      </Grid>
                    )}
                  </Fragment>
                )}
                {type === 'Holder' && isRepple() && (
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        key="facialRecognitionImages"
                        type={VALIDATED_FIELD_TYPES.FILE}
                        label="Facial Recognition Images"
                        name="facialRecognitionImages"
                        value={(documentUploads && documentUploads[`${user.uuid}-facialRecognitionImages`]) || []}
                        id="facialRecognitionImages"
                        validators={[]}
                        onDocumentChange={onDocumentChange}
                        uploadMimeTypes={['image/*']}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton
              isSaving={isSaving}
              isDisabled={!this.isUploadingCompleted(documentUploads)}
              onSubmit={handleSave}
            />
          </Grid>
          <YesNoDialog
            close={handleClose}
            open={checkLinkHolder}
            title="Link current holder to organisation"
            handleNo={handleNo}
            handleYes={handleYes}
          >
            <div className={classes.dialogBody}>
              The user being created has been found as a current holder in the Repple System. Would you like to add them
              to the organisation?
            </div>
          </YesNoDialog>
        </Grid>
      </Grid>
    );
  }
}

ChangeUser.defaultProps = {
  onDocumentChange: () => {},
  fileUploads: [],
  documentUploads: {}
};

ChangeUser.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func,
  handleEmailPhoneChange: PropTypes.func,
  togglePermissionChange: PropTypes.func,
  handleNo: PropTypes.func,
  handleYes: PropTypes.func,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
  errors: PropTypes.object,
  type: PropTypes.string,
  cancelUrl: PropTypes.string,
  roles: PropTypes.array,
  permissions: PropTypes.array,
  isSaving: PropTypes.bool,
  checkLinkHolder: PropTypes.bool,
  isEdit: PropTypes.bool,
  onDocumentChange: PropTypes.func,
  documentUploads: PropTypes.object,
  foundHolder: PropTypes.object,
  fileUploads: PropTypes.array
};

export default withStyles(styles)(ChangeUser);

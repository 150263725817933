import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import { organisationInitialState } from '../../utils/entityInitialStates';
import { uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads } from '../../store/actions/DocumentActions';
import { getOrganisationRequest, saveOrganisationRequest } from '../../utils/entityRequests/organisationRequests';
import {
  listCurrencyRequest,
  listAssuranceOrganisationRequest
} from '../../utils/entityRequests/referenceDataRequests';
import { documentUploadsSelector } from '../../store/selectors/document';

import { entityArraySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import AvatarUpload from '../widgets/AvatarUpload';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout,
  marginTop: {
    marginTop: '10px'
  }
});

class ChangeOrganisationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisation: organisationInitialState
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/admin/organisation/view/${id}` : '/admin/organisation';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    await getEntity(listAssuranceOrganisationRequest());
    await getEntity(listCurrencyRequest());

    if (this.isEdit) {
      const organisation = await getEntity(getOrganisationRequest(match.params.id));
      this.setState({ organisation });
    } else {
      this.setState({
        organisation: organisationInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ organisation: organisationInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { organisation } = this.state;

    const organisationResult = _.cloneDeep(organisation);
    _.set(organisationResult, name, value);

    this.setState({
      organisation: organisationResult
    });
  };

  handleChangeCapability = event => {
    const { name } = event.target;
    const { organisation } = this.state;
    this.handleChange({ target: { name, value: !organisation[name] } });
  };

  handleImageUpload = imageUpload => {
    this.setState({
      imageUpload
    });
  };

  handleClearImageUpload = () => {
    const { organisation } = this.state;
    const organisationResult = _.cloneDeep(organisation);
    organisationResult.imageKey = '';
    organisationResult.imageUrl = '';

    this.setState({
      organisation: organisationResult
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, requestPublicImageUploadUrl, uploadToS3 } = this.props;
    const { organisation, imageUpload } = this.state;
    const organisationResult = _.cloneDeep(organisation);

    if (imageUpload && !organisationResult.imageKey) {
      const upload = await requestPublicImageUploadUrl(imageUpload);
      const response = await uploadToS3([{ url: upload.url, control: 'organisationImage' }], [imageUpload]);
      if (response[0].status === 200) organisationResult.imageKey = upload.key;
    }

    const organisationId = await saveEntity(saveOrganisationRequest(organisationResult));

    if (!organisationId) return;

    history.push(`/admin/organisation/view/${organisationId}`);
  };

  render() {
    const { classes, isSaving, errors, organisations, currencies, documentUploads, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { organisation } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={classNames(classes.viewPage, classes.page)} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              declineButtonText="Back"
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header={`${id ? 'Edit' : 'New'} Organisation`}
              isSaving={isSaving}
            />
          </Grid>

          <Grid container item xs={12} className={classes.formContent}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <NewValidatedField
                    id="name"
                    label="Name"
                    name="name"
                    value={organisation.name}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                  />
                </div>
              </Grid>
              {!organisation.identifier && (
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="key"
                      label="Key"
                      name="key"
                      value={organisation.key}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      errorText={errors && errors.fieldErrors.key}
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <NewValidatedField
                    type={VALIDATED_FIELD_TYPES.SELECT}
                    key="currency.identifier"
                    label="Currency"
                    value={
                      ((currencies && currencies.currencies) || []).find(
                        item => item.identifier === organisation.currency.identifier
                      ) || null
                    }
                    onChange={this.handleChange}
                    items={(currencies && currencies.currencies) || []}
                    id="currency.identifier"
                    itemName="name"
                    itemValue="identifier"
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <NewValidatedField
                    id="acceptableCredentialCheckDistance"
                    label="Acceptable Credential Check Distance (Metres)"
                    name="acceptableCredentialCheckDistance"
                    value={organisation.acceptableCredentialCheckDistance}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.NATURAL_NUMBER]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.fieldContainer)}>
                  <NewValidatedField
                    type={VALIDATED_FIELD_TYPES.SELECT}
                    key="parentReportingId"
                    label="Parent Reporting Organisation"
                    value={
                      (organisations ? organisations.filter(item => item.value !== organisation.uuid) : []).find(
                        item => item.value === organisation.parentReportingId
                      ) || null
                    }
                    onChange={this.handleChange}
                    items={organisations ? organisations.filter(item => item.value !== organisation.uuid) : []}
                    id="parentReportingId"
                    itemName="label"
                    itemValue="value"
                    validators={[]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <NewValidatedField
                    type={VALIDATED_FIELD_TYPES.CHECKBOXES}
                    label="Capability"
                    overrideClassName={classes.fullWidth}
                    validators={[]}
                    onChange={this.handleChangeCapability}
                    options={[
                      { label: 'Assurance', checked: organisation.assurance, name: 'assurance' },
                      { label: 'Competence', checked: organisation.competence, name: 'competence' },
                      { label: 'Training', checked: organisation.training, name: 'training' }
                    ]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <NewValidatedField
                    type={VALIDATED_FIELD_TYPES.CHECKBOX}
                    id="member"
                    label="Member"
                    name="member"
                    value={organisation.member}
                    labelPlacement="end"
                    onChange={() => {
                      this.handleChange({
                        target: { name: 'member', value: !organisation.member }
                      });
                    }}
                    validators={[]}
                    className={classes.paddingRight}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.fieldContainer, classes.marginTop)}>
                  <label className={classes.displayLabel}>Logo</label>
                  <AvatarUpload
                    width={150}
                    height={150}
                    round
                    handleImageUpload={this.handleImageUpload}
                    clearImageUpload={this.handleClearImageUpload}
                    uploadStatus={
                      documentUploads && documentUploads.organisationImage ? documentUploads.organisationImage : []
                    }
                    isSaving={isSaving}
                    image={organisation ? organisation.imageUrl : ''}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeOrganisationView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  uploadToS3: PropTypes.func.isRequired,
  requestPublicImageUploadUrl: PropTypes.func.isRequired,
  clearUserFileDownloads: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  organisations: PropTypes.array,
  documentUploads: PropTypes.array,
  currencies: PropTypes.object,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'organisation'),
  ...entityArraySelector(state, 'currencies'),
  ...entityArraySelector(state, 'organisations'),
  ...documentUploadsSelector(state),
  isSaving:
    loadingSelector(state, 'saveEntity') ||
    loadingSelector(state, 'requestPublicImageUploadUrl') ||
    loadingSelector(state, 'uploadToS3')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity, uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads }),
  withRouter
)(ChangeOrganisationView);

// Organisations
import OrganisationListView from '../components/organisation/OrganisationListView';
import ChangeOrganisationView from '../components/organisation/ChangeOrganisationView';
import OrganisationView from '../components/organisation/OrganisationView';
import OrganisationListBreadcrumb from '../components/breadcrumbs/OrganisationListBreadcrumb';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';

// Holder Admin
import HolderListView from '../components/holder/HolderListView';
import HolderRepListView from '../components/holder/HolderRepListView';
import ChangeHolderView from '../components/holder/ChangeHolderView';
import HolderView from '../components/holder/HolderView';
import HolderCredentialView from '../components/holder/HolderCredentialView';
import HolderCredentialListView from '../components/holder/HolderCredentialListView';
import HolderListItemBreadcrumb from '../components/breadcrumbs/HolderListItemBreadcrumb';
import CredentialHolderListBreadcrumb from '../components/breadcrumbs/CredentialHolderListBreadcrumb';
import CredentialHolderListItemBreadcrumb from '../components/breadcrumbs/CredentialHolderListItemBreadcrumb';
import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';

// Email
import EmailListView from '../components/email/EmailListView';
import EmailView from '../components/email/EmailView';

import TestWriteHolderCredentialView from '../components/credential/TestWriteHolderCredentialView';
import ChangeRoleView from '../components/userAdmin/ChangeRoleView';
import RoleView from '../components/userAdmin/RoleView';
import RoleListView from '../components/userAdmin/RoleListView';
import RoleListBreadcrumb from '../components/breadcrumbs/RoleListBreadcrumb';
import ChangePostView from '../components/post/ChangePostView';
import PostView from '../components/post/PostView';
import PostListView from '../components/post/PostListView';
import CrowdSourceOrganisationListView from '../components/crowdsource/CrowdsourceOrganisationListView';
import CrowdsourceOrganisationView from '../components/crowdsource/CrowdsourceOrganisationView';
import CrowdsourceCredentialTypeListView from '../components/crowdsource/CrowdsourceCredentialTypeListView';
import CrowdsourceCredentialTypeView from '../components/crowdsource/CrowdsourceCredentialTypeView';

export default [
  // organisation routes
  {
    path: '/admin/crowdsource/credentialType/view/:id',
    component: CrowdsourceCredentialTypeView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'crowdsourceCredentialType',
    itemName: 'name',
    heading: 'Crowdsource Credential Type'
  },
  {
    path: '/admin/crowdsource/organisation/view/:id',
    component: CrowdsourceOrganisationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'crowdsourceOrganisation',
    itemName: 'name',
    heading: 'Crowdsource Organisation'
  },
  {
    path: '/admin/crowdsource/organisation',
    component: CrowdSourceOrganisationListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'crowdOrganisations',
    functionName: 'listEntities',
    heading: 'Crowdsource Organisation'
  },
  {
    path: '/admin/crowdsource/credentialType',
    component: CrowdsourceCredentialTypeListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'crowdCredentialTypes',
    functionName: 'listEntities',
    heading: 'Crowdsource Credential'
  },
  { path: '/admin/organisation/create', component: ChangeOrganisationView, breadcrumb: null, protected: true },
  {
    path: '/admin/organisation/view/:id',
    component: OrganisationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'organisation',
    itemName: 'name',
    heading: 'Organisation'
  },
  {
    path: '/admin/organisation/edit/:id',
    component: ChangeOrganisationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'organisation',
    itemName: 'name',
    heading: 'Organisation'
  },
  {
    path: '/admin/organisation',
    component: OrganisationListView,
    breadcrumb: OrganisationListBreadcrumb,
    protected: true
  },

  // holder routes
  {
    path: '/admin/holder/view/:id/reps',
    component: HolderRepListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'reps',
    functionName: 'listEntities',
    heading: 'Rep'
  },
  {
    path: '/admin/holder/view/:id/credentials/view/:credentialId',
    component: HolderCredentialView,
    breadcrumb: CredentialHolderListItemBreadcrumb,
    protected: true
  },
  {
    path: '/admin/holder/view/:id/credentials',
    component: HolderCredentialListView,
    breadcrumb: CredentialHolderListBreadcrumb,
    protected: true
  },
  { path: '/admin/holder/create', component: ChangeHolderView, breadcrumb: null, protected: true },
  {
    path: '/admin/holder/edit/:id',
    component: ChangeHolderView,
    breadcrumb: HolderListItemBreadcrumb,
    protected: true
  },
  { path: '/admin/holder/view/:id', component: HolderView, breadcrumb: HolderListItemBreadcrumb, protected: true },
  {
    path: '/admin/holder',
    component: HolderListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'holders',
    functionName: 'listEntities',
    heading: 'Holder'
  },

  // Email routes
  {
    path: '/admin/email/view/:id',
    component: EmailView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'email',
    itemName: 'name',
    heading: 'Email'
  },
  {
    path: '/admin/email',
    component: EmailListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'emails',
    functionName: 'listEntities',
    heading: 'Email'
  },

  {
    path: '/credential/test',
    component: TestWriteHolderCredentialView,
    breadcrumb: null,
    protected: true
  },
  { path: '/admin/userRole/create', component: ChangeRoleView, breadcrumb: null, protected: true },
  {
    path: '/admin/userRole/edit/:id',
    component: ChangeRoleView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'role',
    itemName: 'name',
    heading: 'Role'
  },
  {
    path: '/admin/userRole/view/:id',
    component: RoleView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getRole',
    propertyName: 'role',
    itemName: 'name',
    heading: 'Role'
  },
  { path: '/admin/userRole', component: RoleListView, breadcrumb: RoleListBreadcrumb, protected: true },
  // Posts
  {
    path: '/admin/post/edit/:id',
    component: ChangePostView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'post',
    itemName: 'name',
    heading: 'Post'
  },
  { path: '/admin/post/create', component: ChangePostView, breadcrumb: null, protected: true },
  {
    path: '/admin/post/view/:id',
    component: PostView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'post',
    itemName: 'name',
    heading: 'Post'
  },
  {
    path: '/admin/post',
    component: PostListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'posts',
    functionName: 'listEntities',
    heading: 'Post'
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import color from 'color';
import classNames from 'classnames';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';

import { style } from '../../assets/style';
import { colors } from '../../assets/constants';
import { NO_SEARCH_VALUE, NO_SEARCH_LABEL } from '../../constants/global';

const styles = {
  ...style,
  dropdownStyle: {
    padding: 0
  },
  selectMenu: {
    padding: '0 10px',
    minWidth: '70px',
    textAlign: 'right'
  },
  select: {
    fontSize: '12px',
    color: colors.blueLight,
    minWidth: 'auto',
    '&:focus': {
      background: 'transparent'
    }
  },
  inputFocused: {
    background: 'transparent'
  },
  menuItemRoot: {
    fontSize: '12px',
    padding: '6px 12px',
    height: 'auto',
    '&:hover': {
      background: colors.orangeDark,
      color: colors.white
    }
  },
  menuItemSelected: {
    backgroundColor: `${color(colors.orangeDark)
      .lighten(0.1)
      .hex()} !important`,
    color: colors.white
  },
  icon: {
    position: 'absolute',
    zIndex: -1,
    right: '-13px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: colors.blueLight
  }
};

export const inputInitialValue = NO_SEARCH_VALUE;
export const initialFilterValue = {
  value: inputInitialValue,
  label: NO_SEARCH_LABEL
};
export const getValue = (event, options) => {
  const { value } = event.target;
  const selected = options.find(option => option.value === value);

  return selected ? { value: selected.value, label: selected.label } : initialFilterValue;
};

const SimplisticSelect = ({ classes, value, name, onChange, options, showDefaultValue, className, hasCaret }) => (
  <Select
    className={className}
    value={value}
    onChange={onChange}
    name={name}
    input={<Input disableUnderline id="select-simple" classes={{ focused: classes.inputFocused }} />}
    IconComponent={() => hasCaret && <i className={classNames('material-icons', classes.icon)}>arrow_drop_down</i>}
    MenuProps={{
      // classes: { root: classes.dropdownStyle },
      getContentAnchorEl: null,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left'
      }
    }}
    classes={{
      root: classes.selectRoot,
      selectMenu: classes.selectMenu,
      select: classes.select,
      icon: classes.icon
    }}
  >
    {showDefaultValue && (
      <MenuItem
        value={inputInitialValue}
        classes={{
          root: classes.menuItemRoot,
          selected: classes.menuItemSelected
        }}
      >
        {initialFilterValue.label}
      </MenuItem>
    )}
    {options.map((option, index) => (
      <MenuItem
        key={`${index}menu${option.value}`}
        value={option.value}
        classes={{
          root: classes.menuItemRoot,
          selected: classes.menuItemSelected
        }}
      >
        {option.label}
      </MenuItem>
    ))}
  </Select>
);

SimplisticSelect.defaultProps = {
  value: inputInitialValue,
  showDefaultValue: true,
  hasCaret: false,
  className: {}
};

SimplisticSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
  showDefaultValue: PropTypes.bool,
  hasCaret: PropTypes.bool,
  className: PropTypes.string
};

export default withStyles(styles)(SimplisticSelect);

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { style } from '../../../assets/style';
import { sizes, DIFFICULTY_TYPE, LOCATION_TYPE, PERIOD_TYPE } from '../../../assets/constants';

const styles = () => ({
  ...style,
  catalogContainer: {
    // borderTop: 'solid 1px #e0e0e0',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: '40px'
  },
  courseContainer: {
    padding: '20px',
    marginTop: '30px',
    '&:hover': {
      boxShadow: '0px 3px 8px 0 rgba(0, 0, 0, 0.3)'
    }
  },
  imageAndData: {
    display: 'flex',
    flexDirection: 'row'
  },
  heading: {
    fontSize: sizes.fontBig,
    fontWeight: 550,
    color: '#222',
    lineHeight: 0.89
  },
  secondTierInfo: {
    marginTop: '15px',
    fontSize: '14px',
    fontWeight: '200',
    width: '100%'
  },
  secondTierGroup: {
    marginRight: '20px',
    alignItems: 'end',
    display: 'flex',
    '& > div > i': {
      fontSize: '14px',
      marginRight: '6px'
    }
  },
  explanation: {
    marginTop: '12px',
    color: 'rgba(0, 0, 0, 0.8)'
  }
});

class CourseDisplay extends PureComponent {
  handleNavigate = () => {
    const { history, clickUrl } = this.props;
    history.push(clickUrl);
  };

  render() {
    const { classes, course, clickUrl } = this.props;
    return (
      <>
        <Paper
          className={classNames(classes.courseContainer, { [classes.pointer]: clickUrl })}
          onClick={this.handleNavigate}
        >
          <div className={classes.imageAndData}>
            <div>
              <img src={course.imageUrl} width={300} height={200} alt="Course Display" />
            </div>
            <div className={classNames(classes.columnDirection)} style={{ marginLeft: '15px' }}>
              <div className={classes.heading}>{course.title}</div>
              <div className={classes.columnDirection}>
                <div className={classNames(classes.rowDirection, classes.secondTierInfo)}>
                  <div className={classes.secondTierGroup}>
                    <div>
                      <i className="material-icons" title="Difficulty">
                        signal_cellular_alt
                      </i>
                    </div>
                    <div>{DIFFICULTY_TYPE[course.difficultyType].label}</div>
                  </div>
                  <div className={classes.secondTierGroup}>
                    <div>
                      <i className="material-icons" title="Duration">
                        access_time
                      </i>
                    </div>
                    <div>
                      {course.duration} {PERIOD_TYPE[course.periodType].label}
                    </div>
                  </div>
                  <div className={classes.secondTierGroup}>
                    <div>
                      <i className="material-icons" title="Study Method">
                        my_location
                      </i>
                    </div>
                    <div>{LOCATION_TYPE[course.locationType].label}</div>
                  </div>
                  <div className={classes.secondTierGroup}>
                    <div>
                      <i className="material-icons" title="Internal Only">
                        home
                      </i>
                    </div>
                    <div>{course.internal ? 'Internal Only' : 'Internal & External'}</div>
                  </div>
                  <div className={classes.secondTierGroup}>
                    <div>
                      <i className="material-icons" title="Cost">
                        monetization_on
                      </i>
                    </div>
                    <div>{course.cost}</div>
                  </div>
                </div>
                {course.keywords.length !== 0 && (
                  <div className={classNames(classes.rowDirection, classes.secondTierInfo)}>
                    <div className={classes.secondTierGroup}>
                      <div>
                        <i className="material-icons" title="Keywords">
                          vpn_key
                        </i>
                      </div>
                      <div>{course.keywords.map(item => item.label).join(', ')}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className={classes.explanation}>{course.blurb}</div>
            </div>
          </div>
        </Paper>
      </>
    );
  }
}

CourseDisplay.propTypes = {
  classes: PropTypes.object.isRequired,
  course: PropTypes.object,
  history: PropTypes.object,
  clickUrl: PropTypes.string
};

export default compose(withRouter, withStyles(styles))(CourseDisplay);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { IconButton, LinearProgress } from '@material-ui/core';
import ReactAvatarEditor from 'react-avatar-editor';
import { style } from '../../assets/style';
import credentialDesignStyles from '../../assets/credentialDesignStyles';
import { colors } from '../../assets/constants';

const styles = () => ({
  ...style,
  ...credentialDesignStyles,
  upload: {
    width: '299px',
    marginTop: '5px',
    backgroundColor: colors.orangeDarkHalf,
    '& > div': {
      backgroundColor: colors.orangeDark
    }
  },
  imageHelpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingBottom: '20px',
    justifyContent: 'space-between'
  },
  helpText: {
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '12px',
    color: '#777',
    marginBottom: '5px'
  },
  helpContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatarComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3E4042',
    borderRadius: '5px 5px 0 0',
    '& canvas': {
      marginTop: '20px'
    }
  },
  avatar: {
    borderRadius: '75px'
  },
  slider: {
    width: '100%',
    bottom: '-4px',
    position: 'relative'
  }
});

class AvatarUpload extends Component {
  state = { isDragging: false, file: null, position: { x: 0.5, y: 0.5 }, scale: 1, rotate: 0 };

  dropRef = React.createRef();

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
    div.addEventListener('hover', this.handleStopPropagate);
    div.addEventListener('mousemove', this.handleStopPropagate);
  }

  componentWillUnmount() {
    if (this.dropRef.current) {
      const div = this.dropRef.current;
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
      div.removeEventListener('hover', this.handleStopPropagate);
      div.removeEventListener('mousemove', this.handleStopPropagate);
    }
  }

  handleStopPropagate = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ isDragging: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();

    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ isDragging: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const { handleImageUpload } = this.props;

    this.setState({ isDragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.setState({ file: e.dataTransfer.files[0] });
      handleImageUpload(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  handleImageReady = () => {
    const { handleImageUpload } = this.props;
    const { file } = this.state;
    const reader = new FileReader();
    this.editor.getImageScaledToCanvas().toBlob(blob => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        handleImageUpload(this.dataURLtoFile(reader.result, file.name));
      };
    });
  };

  clearImage = () => {
    const { clearImageUpload } = this.props;
    this.setState({
      file: null
    });

    clearImageUpload();
  };

  handleScale = event => {
    const { handleImageUpload } = this.props;
    const { file } = this.state;
    const { target } = event;
    const { value } = target;
    const scale = parseFloat(value);
    this.setState({ scale });
    const reader = new FileReader();
    this.editor.getImageScaledToCanvas().toBlob(blob => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        handleImageUpload(this.dataURLtoFile(reader.result, file.name));
      };
    });
  };

  setEditorRef = editor => {
    if (editor) this.editor = editor;
  };

  handlePositionChange = position => {
    const { handleImageUpload } = this.props;
    const { file } = this.state;
    this.setState({ position });
    const reader = new FileReader();
    this.editor.getImageScaledToCanvas().toBlob(blob => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        handleImageUpload(this.dataURLtoFile(reader.result, file.name));
      };
    });
  };

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImage = new File([u8arr], filename, { type: mime });
    return croppedImage;
  }

  render() {
    const {
      classes,
      image,
      uploadStatus,
      width,
      height,
      containerWidth,
      containerHeight,
      round,
      noRadius
    } = this.props;
    const { isDragging, file, scale, position, rotate } = this.state;

    return (
      <div className={classes.layout}>
        <div>
          <div className={classes.container}>
            <div
              className={classNames(classes.imageContainer, {
                [classes.imageContainerActive]: isDragging,
                [classes.hidden]: file || image
              })}
              style={{ width: containerWidth, height: containerHeight }}
              ref={this.dropRef}
            >
              <div>Drop the logo here</div>
              <div className={classes.imageSize}>100 x 100</div>
            </div>
            {(file || image) && (
              <div>
                {image && (
                  <div className={classes.imageDeleteContainer}>
                    <img src={image} alt="Uploaded" className={classNames({ [classes.avatar]: !noRadius })} />
                    <div className={classes.imageHelpContainer}>
                      <div>
                        <IconButton onClick={this.clearImage}>
                          <i className={classNames('material-icons', classes.da)}>delete</i>
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}
                {file && (
                  <div className={classes.rowDirection}>
                    <div
                      className={classNames(classes.columnDirection, classes.avatarComponent)}
                      style={{ width: containerWidth, height: containerHeight }}
                    >
                      <ReactAvatarEditor
                        ref={this.setEditorRef}
                        scale={parseFloat(scale)}
                        width={width}
                        height={height}
                        position={position}
                        onPositionChange={this.handlePositionChange}
                        rotate={parseFloat(rotate)}
                        borderRadius={round ? width / 2 : 0}
                        onImageReady={this.handleImageReady}
                        image={file}
                        className="editor-canvas"
                        border={0}
                      />
                      <input
                        name="scale"
                        type="range"
                        onChange={this.handleScale}
                        min="0.1"
                        max="2"
                        step="0.01"
                        defaultValue="1"
                        className={classes.slider}
                      />
                    </div>
                    <div>
                      <div className={classes.imageHelpContainer}>
                        <div>
                          <IconButton onClick={this.clearImage}>
                            <i className={classNames('material-icons', classes.da)}>delete</i>
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {uploadStatus.length > 0 && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadStatus[0].completeness}
                      className={classNames(classes.upload, {
                        [classes.uploadError]: uploadStatus[0].error
                      })}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

AvatarUpload.defaultProps = {
  noRadius: false
};

AvatarUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
  fields: PropTypes.array,
  uploadStatus: PropTypes.array,
  handleChange: PropTypes.func,
  handleImageUpload: PropTypes.func,
  clearImageUpload: PropTypes.func,
  isSaving: PropTypes.bool,
  round: PropTypes.bool,
  noRadius: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number
};

export default withStyles(styles)(AvatarUpload);

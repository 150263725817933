import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import color from 'color';
import classNames from 'classnames';
import { colors, commonFont } from '../../assets/constants';

const style = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.white,
    borderRadius: '5px',
    height: '45px',
    textTransform: 'none',
    ...commonFont,
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    marginLeft: '20px',
    marginBottom: '10px'
  },
  textAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  primary: {
    background: colors.orangeNew,
    '&:hover': {
      background: color(colors.orangeNew)
        .lighten(0.1)
        .hex()
    },
    '& svg, & i': {
      marginLeft: '23px'
    }
  },
  secondary: {
    background: colors.blue,
    border: `solid 1px ${colors.blue}`,
    '&:hover': {
      background: color(colors.blue)
        .lighten(0.1)
        .hex()
    },
    '& svg, & i': {
      marginRight: '23px'
    }
  },
  unfilled: {
    background: 'none !important',
    color: colors.blue
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  },
  circular: {
    '& > svg': {
      color: colors.white,
      marginLeft: 0
    }
  }
};
const renderButtonContent = (primary, isSaving, text, icon, classes) => {
  if (isSaving) {
    return <CircularProgress size={15} className={classes.circular} />;
  }
  if (primary) {
    return (
      <div className={classes.textAndIcon}>
        <div>{text}</div>
        <div className={classes.icon}>{icon}</div>
      </div>
    );
  }
  return (
    <div className={classes.textAndIcon}>
      <div className={classes.icon}>{icon}</div>
      <div>{text}</div>
    </div>
  );
};

const NewActionButton = ({ classes, primary, onChange, text, icon, filled, className, isSaving, style }) => (
  <Button
    data-qa="ok"
    className={classNames(
      className,
      classes.button,
      { [classes.primary]: primary },
      { [classes.secondary]: !primary },
      { [classes.unfilled]: !filled }
    )}
    variant="contained"
    onClick={onChange}
    style={style}
  >
    {renderButtonContent(primary, isSaving, text, icon, classes)}
  </Button>
);

NewActionButton.defaultProps = {
  isSaving: false
};

NewActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  primary: PropTypes.bool.isRequired,
  filled: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  style: PropTypes.object,
  icon: PropTypes.any.isRequired,
  className: PropTypes.object
};

export default withStyles(style)(NewActionButton);

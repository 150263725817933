// Posts
import ChangePostView from '../components/post/ChangePostView';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';
import PostView from '../components/post/PostView';
import PostListView from '../components/post/PostListView';
import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';
import ChangeQualificationView from '../components/qualification/ChangeQualificationView';
import QualificationView from '../components/qualification/QualificationView';
import QualificationListView from '../components/qualification/QualificationListView';
import ChangeReasonView from '../components/reason/ChangeReasonView';
import ReasonListView from '../components/reason/ReasonListView';
import ReasonListBreadcrumb from '../components/breadcrumbs/ReasonListBreadcrumb';

export default [
  {
    path: '/workforce/post/edit/:id',
    component: ChangePostView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'post',
    itemName: 'name',
    heading: 'Post'
  },
  { path: '/workforce/post/create', component: ChangePostView, breadcrumb: null, protected: true },
  {
    path: '/workforce/post/view/:id',
    component: PostView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'post',
    itemName: 'name',
    heading: 'Post'
  },
  {
    path: '/workforce/post',
    component: PostListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'posts',
    functionName: 'listEntities',
    heading: 'Post'
  },
  // Qualification routes
  {
    path: '/workforce/qualification/edit/:id',
    component: ChangeQualificationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'qualification',
    itemName: 'name',
    heading: 'Qualification'
  },
  { path: '/workforce/qualification/create', component: ChangeQualificationView, breadcrumb: null, protected: true },
  {
    path: '/workforce/qualification/view/:id',
    component: QualificationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'qualification',
    itemName: 'name',
    heading: 'Qualification'
  },
  {
    path: '/workforce/qualification',
    component: QualificationListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    functionName: 'listEntities',
    tableName: 'qualifications',
    heading: 'Qualification'
  },
  // reason routes
  {
    path: '/workforce/reason/edit/:id',
    component: ChangeReasonView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'reason',
    itemName: 'name',
    heading: 'Reason'
  },
  { path: '/workforce/reason/create', component: ChangeReasonView, breadcrumb: null, protected: true },
  { path: '/workforce/reason', component: ReasonListView, breadcrumb: ReasonListBreadcrumb, protected: true }
];

import { organisationInitialState } from '../entityInitialStates';

export const getOrganisationRequest = id => ({
  url: `${process.env.REACT_APP_ORGANISATION_GET}${id}`,
  entityName: 'organisation',
  initialState: organisationInitialState
});

export const saveOrganisationRequest = entity => ({
  url: process.env.REACT_APP_ORGANISATION_SAVE,
  entityName: 'Organisation',
  entity
});

export const listOrganisationRequest = () => {
  return {
    url: `${process.env.REACT_APP_ORGANISATION_LIST}`,
    entityName: 'organisations'
  };
};

import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Button, Checkbox, IconButton, Slide, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fade from '@material-ui/core/Fade';
import uuid from 'uuid';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import {
  colors,
  commonFont,
  FILE_UPLOAD_STATUS,
  FILE_UPLOAD_TYPE,
  STANDARD_CREDENTIAL_TYPE
} from '../../assets/constants';
import { uploadDocuments, downloadBlob } from '../../store/actions/DocumentActions';
import { loadingSelector } from '../../store/selectors/shared';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { documentUploadsSelector } from '../../store/selectors/document';
import { getCredentialTypeRequest } from '../../utils/entityRequests/credentialTypeRequests';
import { saveImportRequest } from '../../utils/entityRequests/importRequests';
import { entitySelector } from '../../store/selectors/entity';
import credentialDesignStyles from '../../assets/credentialDesignStyles';
import NewSectionHeading from '../common/NewSectionHeading';
import DisplayField from '../widgets/DisplayField';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import { ReactComponent as LicensesAndCertificates } from '../../assets/images/licenses-certifications-icon.svg';
import { ReactComponent as Education } from '../../assets/images/education-icon.svg';
import { ReactComponent as Passport } from '../../assets/images/personal-documents-icon.svg';
import { ReactComponent as Covid19 } from '../../assets/images/covid19-icon.svg';
import { ReactComponent as ImportIcon } from '../../assets/images/import-icon.svg';
import CredentialPreview from './CredentialPreview';
import NewActionButton from '../widgets/NewActionButton';
import NewValidatedField from '../widgets/NewValidatedField';

const styles = {
  ...layout,
  ...style,
  ...credentialDesignStyles,
  importHeader: {
    marginRight: '40px'
  },
  radio: {
    '& > span > svg': {
      height: '0.75em',
      width: '0.75em'
    }
  },
  selected: {
    '& > span > svg': {
      color: colors.orangeDark
    }
  },
  marginTop: {
    marginTop: '30px'
  },
  credentialTypeButtonMarginBottom: {
    marginBottom: '50px'
  },
  credentialTypeButton: {
    border: `1px solid ${colors.borderBlue}`,
    height: '70px',
    borderRadius: '5px',
    ...commonFont,
    color: colors.blue,
    marginRight: '20px',
    cursor: 'pointer',
    paddingLeft: '17px',
    paddingTop: '7px',
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: '12px',
      color: colors.blue
    },
    '& > div': {
      marginTop: '10px',
      color: colors.blueDark,
      fontWeight: 600,
      '& svg': {
        marginRight: '18px'
      }
    }
  },
  tableRow: {
    '& td': {
      paddingTop: '10px',
      paddingBottom: '0px'
    },
    '& td:not(:last-of-type)': {
      borderRight: `solid 1px ${colors.borderBlue}`,
      position: 'relative'
    }
  },
  table: {
    tableLayout: 'fixed',
    width: 'calc(100% - 20px)',
    borderLeft: `solid 1px ${colors.borderBlue}`,
    borderRight: `solid 1px ${colors.borderBlue}`,
    borderTop: `solid 1px ${colors.borderBlue}`,
    borderRadius: '5px',
    // borderSpacing: 0,
    borderCollapse: 'inherit'
    // '& tr:last-of-type': {
    //   borderBottom: 'none'
    // }
  },
  previewSpacer: {
    borderLeft: `solid 1px ${colors.borderBlue}`,
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  formContent: {
    background: 'rgba(226, 238, 243, 0.5)',
    borderRadius: '5px',
    marginTop: '41px',
    marginBottom: '41px',
    padding: '0px 27px',
    height: '100%'
  },
  sectionPadding: {
    paddingTop: '30px',
    paddingBottom: '60px'
  },
  importMarginTop: {
    marginTop: '15px'
  }
};

class CredentialTypeView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldDisplayImport: false,
      emailAfterImport: false,
      uuid: uuid()
    };
  }

  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getCredentialTypeRequest(id));
  }

  downloadCSVTemplate = () => {
    const { credentialType, downloadBlob } = this.props;
    downloadBlob(
      `${window.reppleEnv.REACT_APP_API_URL}/competence/credentialType/template/${credentialType.uuid}`,
      `${credentialType.name}.csv`
    );
  };

  closeImport = () => {
    this.setState({
      shouldDisplayImport: false
    });
  };

  openImport = () => {
    this.setState({
      shouldDisplayImport: true
    });
  };

  handleDocumentChange = (id, file) => {
    const { uploadDocuments } = this.props;
    const { uuid } = this.state;
    uploadDocuments([file], id, uuid, FILE_UPLOAD_TYPE.DOCUMENT);
  };

  handleImport = async () => {
    const { saveEntity, credentialType, documentUploads, history } = this.props;
    const { emailAfterImport, uuid } = this.state;
    const id = await saveEntity(
      saveImportRequest({
        credentialTypeUuid: credentialType.uuid,
        fileIdentifier: documentUploads[`${uuid}-import`][0].fileId,
        email: emailAfterImport,
        uuid
      })
    );
    history.push(`/credential/credentialType/import/view/${id}`);
  };

  isUploadingCompleted = uploads => {
    let noInComplete = 0;

    Object.keys(uploads).forEach(upload => {
      noInComplete += uploads[upload].filter(item => !item.status || item.status === FILE_UPLOAD_STATUS.UPLOADED.id)
        .length;
    });

    return noInComplete === 0;
  };

  handleChangeEmail = () => {
    this.setState(prevState => ({
      ...prevState,
      emailAfterImport: !prevState.emailAfterImport
    }));
  };

  getLicenseAndCertificateTableRows = credentialType => {
    const { classes } = this.props;
    return (
      <Fragment>
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Name" value={credentialType.name} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Type" value={credentialType.type || '...'} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Category" value={credentialType.category ? credentialType.category.name : '---'} />
          </TableCell>
        </TableRow>
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <DisplayField
              heading="Industries"
              value={
                credentialType.industries.length > 0
                  ? credentialType.industries.map(item => item.name).join(', ')
                  : '---'
              }
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Expires" value={credentialType.expires ? 'Yes' : 'No'} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Public Credential" value={credentialType.publicCredential ? 'Yes' : 'No'} />
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  getEducationTableRows = credentialType => {
    const { classes } = this.props;
    return (
      <Fragment>
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Name" value={credentialType.name} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Field of Study" value={credentialType.fieldOfStudy || '...'} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Public Credential" value={credentialType.publicCredential ? 'Yes' : 'No'} />
          </TableCell>
        </TableRow>
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Time for Verification" value={`${credentialType.verificationTime} days`} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField
              heading="Single valid credential"
              value={credentialType.singleValidCredential ? 'Yes' : 'No'}
            />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Credential expires" value={credentialType.expires ? 'Yes' : 'No'} />
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  getPassportTableRows = credentialType => {
    const { classes } = this.props;
    return (
      <Fragment>
        <TableRow className={classes.tableRow}>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Name" value={credentialType.name} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField heading="Time for Verification" value={`${credentialType.verificationTime} days`} />
          </TableCell>
          <TableCell className={classes.tableCell}>
            <DisplayField
              heading="Single valid credential"
              value={credentialType.singleValidCredential ? 'Yes' : 'No'}
            />
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  render() {
    const { classes, match, credentialType, documentUploads, isSaving } = this.props;
    const { shouldDisplayImport, emailAfterImport, uuid } = this.state;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(credentialType).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <NewHeaderViewActions
                editUrl={`/credential/credentialType/edit/${id}`}
                header={credentialType.name}
                cancelUrl="/credential/credentialType"
                noMargin
              >
                <NewActionButton
                  primary={false}
                  icon={<i className="material-icons">get_app</i>}
                  text="Download Import Template"
                  onChange={this.downloadCSVTemplate}
                  filled
                />
                <NewActionButton
                  primary={false}
                  icon={<ImportIcon />}
                  text="Import"
                  onChange={this.openImport}
                  filled
                />
              </NewHeaderViewActions>
            </Grid>
          </Grid>
          <Grid container className={classes.formContent}>
            <Grid item lg={8} xs={12} md={6} className={classes.sectionPadding}>
              <Grid container>
                <Grid item xs={12}>
                  <NewSectionHeading heading="Background" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div className={classNames(classes.credentialTypeButton, classes.credentialTypeButtonMarginBottom)}>
                    <label>Background</label>
                    {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id && (
                      <div className={classes.rowDirection}>
                        <LicensesAndCertificates fill={colors.blueDark} />
                        License and Certifications
                      </div>
                    )}
                    {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id && (
                      <div className={classes.rowDirection}>
                        <Education fill={colors.blueDark} />
                        Education
                      </div>
                    )}
                    {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id && (
                      <div className={classes.rowDirection}>
                        <Passport fill={colors.blueDark} />
                        Passport
                      </div>
                    )}
                    {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.COVID19.id && (
                      <div className={classes.rowDirection}>
                        <Covid19 fill={colors.blueDark} />
                        Covid 19 test result
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <NewSectionHeading heading="Standard Information" />
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <Table className={classes.table}>
                    <TableBody>
                      {(credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id ||
                        credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.COVID19.id) &&
                        this.getPassportTableRows(credentialType)}
                      {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id &&
                        this.getLicenseAndCertificateTableRows(credentialType)}
                      {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id &&
                        this.getEducationTableRows(credentialType)}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={6} xs={12} className={classes.previewSpacer}>
              <Grid container className={classes.sectionPadding}>
                <Grid item xs={12}>
                  <NewSectionHeading heading="Preview" />
                  <CredentialPreview
                    backgroundColor={credentialType.backgroundColor}
                    name={credentialType.name}
                    backgroundImage={credentialType.imageUrl}
                    logoImage={credentialType.logoUrl}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Fade in={shouldDisplayImport} timeout={500}>
            <Slide direction="left" in={shouldDisplayImport} mountOnEnter unmountOnExit>
              <Grid item xs={5} className={classes.modalContainer}>
                <div className={classes.overlayContainer}>
                  <Grid container justify="flex-start" className={classes.overLayHeadingActionContainer}>
                    <Grid
                      container
                      item
                      xs={12}
                      justify="space-between"
                      direction="row"
                      alignItems="center"
                      className={classes.importHeader}
                    >
                      <IconButton onClick={this.closeImport}>
                        <i className={classNames('material-icons filter-icon', [classes.searchInputIcon])}>clear</i>
                      </IconButton>
                      <Button
                        data-qa="ok"
                        className={classes.submit}
                        variant="contained"
                        color="primary"
                        onClick={this.handleImport}
                        disabled={
                          !this.isUploadingCompleted(documentUploads) ||
                          Object.keys(documentUploads).length === 0 ||
                          isSaving
                        }
                      >
                        Import
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={classNames(classes.formPage, classes.sliderBody)}>
                    <Grid container item xs={12} alignContent="flex-start">
                      <Grid item xs={12}>
                        <NewSectionHeading heading="Import" className={classes.importMarginTop} />
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.FILE}
                          label="Import"
                          name="import"
                          value={(documentUploads && documentUploads[`${uuid}-import`]) || []}
                          id="import"
                          validators={[]}
                          onDocumentChange={this.handleDocumentChange}
                          uploadMimeTypes={['text/csv']}
                        />
                        <FormControlLabel
                          value={false}
                          control={
                            /* eslint-disable react/jsx-wrap-multilines */
                            <Checkbox
                              checked={emailAfterImport}
                              onChange={this.handleChangeEmail}
                              value={false}
                              className={classNames(classes.radio, { [classes.selected]: emailAfterImport })}
                            />
                            /* eslint-enable react/jsx-wrap-multilines */
                          }
                          label="Email on completion"
                          className={classNames(classes.radioOptionLabel, classes.independentLabel)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Slide>
          </Fade>
        </Grid>
      )
    );
  }
}

CredentialTypeView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  saveEntity: PropTypes.func,
  getEntity: PropTypes.func,
  downloadBlob: PropTypes.func,
  credentialType: PropTypes.object,
  documentUploads: PropTypes.object,
  isSaving: PropTypes.bool,
  history: PropTypes.object,
  uploadDocuments: PropTypes.func
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'credentialType'),
  ...documentUploadsSelector(state),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { uploadDocuments, downloadBlob, getEntity, saveEntity })
)(CredentialTypeView);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { entitySelector, entityArraySelector } from '../../store/selectors/entity';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import ScrollableTable from '../table/ScrollableTable';
import { getRoleRequest, listPermissionRequest } from '../../utils/entityRequests/userAdminRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import NewSectionHeading from '../common/NewSectionHeading';

const styles = {
  ...layout,
  ...style
};

class RoleView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPermissions: []
    };
  }

  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    const response = await getEntity(getRoleRequest(id));

    this.setState({ selectedPermissions: response.permissions.map(item => item.identifier) });
    await getEntity(listPermissionRequest());
  }

  getRowConfigs = () => {
    const { permissions, role } = this.props;
    const { selectedPermissions } = this.state;
    const items = permissions || [];
    return (role.reppleOnly ? items : items.filter(item => !item.reppleOnly)).map(permission => ({
      cells: [
        {
          value: permission.name
        },
        {
          value:
            selectedPermissions.indexOf(permission.identifier) !== -1 ? (
              <i className="material-icons">done</i>
            ) : (
              <i className="material-icons">clear</i>
            )
        }
      ]
    }));
  };

  render() {
    const { classes, match, role, isLoading } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(role).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions editUrl={`/admin/userRole/edit/${id}`} header="Role Details" />
          <Grid item xs={9} lg={6} md={6}>
            <DisplayField heading="Name" value={role.name} />
            <DisplayField
              heading="Repple Only"
              value={role.reppleOnly ? <i className="material-icons">done</i> : <i className="material-icons">clear</i>}
            />
            <NewSectionHeading heading="Permissions" />

            <ScrollableTable
              headers={[{ value: 'Name' }, { value: 'Selected' }]}
              rowConfigs={this.getRowConfigs()}
              complete={!isLoading}
              emptyMessage="No Permissions Found"
            />
          </Grid>
        </Grid>
      )
    );
  }
}

RoleView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  role: PropTypes.object,
  isLoading: PropTypes.bool,
  permissions: PropTypes.array
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'role'),
  ...entityArraySelector(state, 'permissions'),
  isLoading: loadingSelector(state, 'getEntity')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(RoleView);

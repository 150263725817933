import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { style } from '../../assets/style';
import { tableStyles } from '../../assets/table';

const styles = {
  ...style,
  ...tableStyles
};

class TableCellInput extends Component {
  renderInput() {
    const { id, classes, readOnly, value, name } = this.props;

    return (
      <input
        id={`cellInput${id}`}
        onChange={event => this.props.handleChange(event)}
        onBlur={event => {
          if (this.props.handleBlur) this.props.handleBlur(event);
        }}
        name={name}
        className={classNames(classes.tableCellEditField, classes.itemValue, {
          [classes.selectValue]: !readOnly && !value,
          [classes.selectedValue]: !readOnly && value
        })}
        value={value}
      />
    );
  }

  render() {
    const { classes, readOnly, value, error } = this.props;

    if (!readOnly) {
      if (error) {
        return (
          <Tooltip
            title={error}
            placement="bottom"
            open={!!error}
            classes={{
              tooltip: classes.errorToolTip,
              popper: classes.popper
            }}
          >
            {this.renderInput()}
          </Tooltip>
        );
      }

      return this.renderInput();
    }

    return value;
  }
}

TableCellInput.propTypes = {
  classes: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func
};

export default withStyles(styles)(TableCellInput);

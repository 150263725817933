import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import classNames from 'classnames';
import Select from '@material-ui/core/Select/Select';
import MenuItem from '@material-ui/core/MenuItem/MenuItem';
import PropTypes from 'prop-types';
import { style } from '../../assets/style';
import { tableStyles } from '../../assets/table';

const styles = {
  ...style,
  ...tableStyles,
  textFieldContainer: {
    borderBottom: 'none'
  }
};

class TableCellSelect extends Component {
  renderSelect() {
    const {
      name,
      id,
      classes,
      readOnly,
      value,
      values,
      items,
      itemName,
      itemValue,
      multiple,
      placeholder
    } = this.props;

    return (
      <div
        className={classNames(classes.selectContainer, {
          [classes.selectedValue]: !readOnly && ((value && !multiple) || (multiple && values.length !== 0)),
          [classes.selectValue]: !readOnly && ((!value && !multiple) || (multiple && values.length === 0))
        })}
        key={`${id}itemDiv`}
      >
        <Select
          multiple={multiple}
          className={classes.selectItem}
          value={multiple ? values : value}
          name={name}
          onChange={this.props.handleChange}
          // input={<Input id="select-simple" className={classes.textFieldContainer}/>}
          inputProps={{ className: classes.textFieldContainer }}
          IconComponent={() => null}
          classes={{
            root: classes.selectParent,
            select: classes.select
          }}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {items.map((item, i) => (
            <MenuItem key={`${item[itemValue]}-${i}-cell`} value={item[itemValue]}>
              {item[itemName]}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  render() {
    const { classes, readOnly, value, error } = this.props;

    if (!readOnly) {
      if (error) {
        return (
          <Tooltip
            title={error}
            placement="bottom"
            classes={{
              tooltip: classes.errorToolTip
            }}
          >
            {this.renderSelect()}
          </Tooltip>
        );
      }

      return this.renderSelect();
    }

    return value;
  }
}

TableCellSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  multiple: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  values: PropTypes.array,
  handleChange: PropTypes.func,
  items: PropTypes.array.isRequired,
  itemName: PropTypes.string.isRequired,
  itemValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

export default withStyles(styles)(TableCellSelect);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { entitySelector } from '../../store/selectors/entity';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { getProfileRequest } from '../../utils/entityRequests/userRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';

const styles = () => ({
  ...layout,
  ...style,
  marginTop: {
    marginTop: '10px'
  },
  avatar: {
    borderRadius: '75px'
  }
});

class ProfileView extends Component {
  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(getProfileRequest());
  }

  render() {
    const { classes, profile } = this.props;
    return (
      Object.keys(profile).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions editUrl="/profile/edit" header="Profile Details" />
          <Grid item xs={9} lg={6} md={6}>
            <DisplayField heading="First Name" value={profile.firstName} />
            <DisplayField heading="Last Name" value={profile.lastName} />
            <DisplayField heading="Phone" value={profile.phone} />
            {profile.avatarUrl && (
              <DisplayField
                heading="Avatar"
                value={<img src={profile.avatarUrl} alt="Uploaded" className={classes.avatar} />}
              />
            )}
          </Grid>
        </Grid>
      )
    );
  }
}

ProfileView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  match: PropTypes.object,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'profile')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity
  }),
  withRouter
)(ProfileView);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { NavLink } from 'react-router-dom';
import withBreadcrumb from 'react-router-breadcrumbs-hoc';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { colors, commonFont } from '../../assets/constants';
import allRoutes from '../../routes/allRoutes';
import { style } from '../../assets/style';

const styles = () => ({
  ...style,
  container: {
    ...style.flexRowCentered,
    backgroundColor: colors.backgroundBlue,
    minHeight: '49px',
    borderBottom: `solid 1px ${colors.borderBlue}`
  },
  firstBreadcrumb: {
    marginLeft: '38px'
  },
  otherBreadcrumb: {
    marginLeft: '20px'
  },
  breadcrumb: {
    marginRight: '20px',
    ...commonFont,
    fontWeight: 'normal',
    fontSize: '12px',
    color: colors.fontLightGrey
  },
  link: {
    ...style.flexRowCentered,
    textDecoration: 'none'
  },
  lineSpacer: {
    border: `solid 1px ${colors.spacerBlue}`,
    height: '30px',
    marginRight: '20px'
  }
});

const NewBreadcrumbs = ({ breadcrumbs, classes, editMode }) => (
  <div className={classes.container}>
    {breadcrumbs.map((breadcrumb, index) => {
      const isButLast = index < breadcrumbs.length - 1;
      const isFirst = index === 0;

      return (
        <span
          className={classNames(
            classes.flexRowCentered,
            classes.outerContainer,
            {
              [classes.butLast]: editMode && isButLast
            },
            { [classes.firstBreadcrumb]: isFirst }
          )}
          key={breadcrumb.key}
        >
          <NavLink to={breadcrumb.match.url} className={classNames(classes.link, classes.breadcrumb)}>
            {breadcrumb.breadcrumb}
          </NavLink>
          {isButLast && <div className={classes.lineSpacer} />}
        </span>
      );
    })}
  </div>
);

const config = {
  disableDefaults: true
};

NewBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  editMode: PropTypes.bool,
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({});

export default compose(withBreadcrumb(allRoutes, config), withStyles(styles), connect(mapStateToProps))(NewBreadcrumbs);

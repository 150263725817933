import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { permissions } from '../../constants/global';
import { hasPermission } from '../../utils/utilities';

class WorkforceView extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    if (hasPermission([permissions.TIMELINE_ADMIN])) history.push('/workforce/post');
    else if (hasPermission([permissions.QUALIFICATION_ADMIN])) history.push('/workforce/qualification');
  }

  render() {
    return null;
  }
}

WorkforceView.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(WorkforceView);

/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { convertUtcToLocal, getFirstPath } from '../../utils/utilities';
import {
  getPostRequest,
  getPostFeedbackRequest,
  savePostFeedbackRequest
} from '../../utils/entityRequests/postRequests';
import { entityArraySelector, entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import MessagingComponent from '../common/MessagingComponent';
import { colors } from '../../assets/constants';

const styles = {
  ...layout,
  ...style,
  whiteBackground: {
    backgroundColor: colors.white
  }
};

class PostView extends React.Component {
  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getPostRequest(id));
    await getEntity(getPostFeedbackRequest(id));
  }

  getPath = () => {
    const { location } = this.props;
    const { pathname } = location;
    return getFirstPath(pathname);
  };

  handleSendMessage = async message => {
    const { saveEntity, getEntity, post } = this.props;

    if (message) {
      return saveEntity(savePostFeedbackRequest(post.identifier, message)).then(async () => {
        return getEntity(getPostFeedbackRequest(post.identifier));
      });
    }
  };

  render() {
    const { classes, match, post, feedbacks } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(post).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={`${this.getPath()}/post/edit/${id}`}
            header="Post Details"
            cancelUrl={`${this.getPath()}/post`}
          />

          <Grid item xs={6}>
            <DisplayField heading="Name" value={post.name} />
            <DisplayField heading="Posted By" value={post.postedBy.name} />
            <DisplayField
              heading="Posted Date"
              value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', post.postedDate)}
            />
            <DisplayField heading="Content" dangerousHtml value={post.content} />
          </Grid>
          <Grid item xs={6} className={classes.whiteBackground}>
            <MessagingComponent isSending={false} messages={feedbacks || []} sendMessage={this.handleSendMessage} />
          </Grid>
        </Grid>
      )
    );
  }
}

PostView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  saveEntity: PropTypes.func,
  feedbacks: PropTypes.array,
  location: PropTypes.object,
  post: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'post'),
  ...entityArraySelector(state, 'feedbacks')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity, saveEntity }))(PostView);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import layout from '../../assets/layout';
import {
  searchOrganisationMemberRequest,
  inviteOrganisationMemberRequest
} from '../../utils/entityRequests/organisationMemberRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';

const styles = {
  ...style,
  ...layout,
  buttonSpace: {
    marginTop: '20px'
  }
};

class InviteOrganisationMemberView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      name: '',
      searched: false
    };
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/organisation/organisationMember/view/${id}`);
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  searchHolder = async () => {
    const { getEntity } = this.props;
    const { searchText } = this.state;
    await getEntity(searchOrganisationMemberRequest(searchText));

    this.setState({
      searched: true
    });
  };

  inviteCompanyHolder = async () => {
    const { organisationMemberSearch, history, saveEntity } = this.props;
    await saveEntity(inviteOrganisationMemberRequest({ userUuid: organisationMemberSearch.uuid }));
    history.push('/organisation/organisationMember');
  };

  inviteHolder = async () => {
    const { saveEntity, history } = this.props;
    const { name, searchText } = this.state;

    await saveEntity(inviteOrganisationMemberRequest({ name, searchText }));
    history.push('/organisation/organisationMember');
  };

  render() {
    const { classes, organisationMemberSearch, isInviting } = this.props;
    const { searchText, searched, name } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl="/organisation/organisationMember"
              isSaving={isInviting}
              onSubmit={organisationMemberSearch.uuid ? this.inviteCompanyHolder : this.inviteHolder}
              name="changeFormHeaderAction"
              header="Invite Organisation Member"
              submitButtonText="Invite"
              hasSubmitBtn={searched}
            />
            <Grid item xs={12} container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <NewValidatedField
                    id="searchText"
                    label="Search Email"
                    name="searchText"
                    value={searchText}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    inputTextProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i
                            className={classNames('material-icons', classes.icon, classes.iconAction)}
                            onClick={this.searchHolder}
                          >
                            search
                          </i>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                {searched && organisationMemberSearch.uuid && (
                  <Grid item xs={12}>
                    <div className={classes.displayLabelAndField}>
                      <label className={classes.displayLabel}>
                        The member has been found. Click invite request they link up with the organisation.
                      </label>
                    </div>
                  </Grid>
                )}
                {searched && !organisationMemberSearch.uuid && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <div className={classes.displayLabelAndField}>
                        <label className={classes.displayLabel}>
                          The member is not registered. Please provide information below to invite them to register and
                          join the organisation.
                        </label>
                      </div>
                      <NewValidatedField
                        id="name"
                        label="Member Name"
                        name="name"
                        value={name}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        className={classes.buttonSpace}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {searched && (
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton
                isSaving={isInviting}
                isDisabled={false}
                text="Invite"
                onSubmit={organisationMemberSearch.uuid ? this.inviteCompanyHolder : this.inviteHolder}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

InviteOrganisationMemberView.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isInviting: PropTypes.bool,
  history: PropTypes.object,
  saveEntity: PropTypes.func,
  getEntity: PropTypes.func,
  organisationMemberSearch: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  isInviting: loadingSelector(state, 'getEntity'),
  ...entitySelector(state, 'organisationMemberSearch')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { saveEntity, getEntity })
)(InviteOrganisationMemberView);

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import NewHeaderEditActions from '../../header/NewHeaderEditActions';
import { getEntity, saveEntity } from '../../../store/actions/EntityActions';
import { loadingSelector } from '../../../store/selectors/shared';
import { isEdit } from '../../../utils/utilities';
import { style } from '../../../assets/style';
import layout from '../../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../../assets/constants';
import ValidatedField from '../../widgets/ValidatedField';
import { entityWithErrorsSelector } from '../../../store/selectors/entity';
import { roomInitialState } from '../../../utils/entityInitialStates';
import { getRoomRequest, saveRoomRequest } from '../../../utils/entityRequests/trainingRequests';
import SaveButton from '../../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout
});

class ChangeRoomView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      room: roomInitialState
    };

    const { id, roomId } = this.props.match.params;
    this.cancelUrl = roomId ? `/training/centre/view/${id}/room/view/${roomId}` : `/training/centre/view/${id}`;
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const room = await getEntity(getRoomRequest(match.params.id, match.params.roomId));

      this.setState({ room });
    } else {
      this.setState({
        room: roomInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ room: roomInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { room } = this.state;

    const roomResult = _.cloneDeep(room);
    _.set(roomResult, name, value);

    this.setState({
      room: roomResult
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, match } = this.props;
    const { room } = this.state;

    const roomId = await saveEntity(saveRoomRequest(match.params.id, room));

    if (!roomId) return;

    history.push(`/training/centre/view/${match.params.id}/${roomId}`);
  };

  render() {
    const { classes, isSaving } = this.props;
    const { room } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header="Room Details"
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={room.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                    <div className={classes.fieldContainer}>
                      <ValidatedField
                        id="location"
                        label="Location"
                        name="location"
                        value={room.location}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                    <div className={classes.fieldContainer}>
                      <ValidatedField
                        id="capacity"
                        label="Capacity"
                        name="capacity"
                        value={room.capacity}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeRoomView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'room'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeRoomView);

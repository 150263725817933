import { CREATE_INITIAL_TABLE, LIST_TABLE } from '../actions/types';

export const initialState = {
  records: [],
  totalPages: 0,
  totalRecords: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CREATE_INITIAL_TABLE:
      return {
        ...state,
        [action.entityName]: initialState
      };
    case LIST_TABLE:
      const rows = state[action.entityName].payload ? state[action.entityName].payload.records.slice() : [];
      if (action.currentPage !== 0) {
        action.payload.records = rows.concat(action.payload.records);
      }
      return {
        ...state,
        [action.entityName]: {
          payload: action.payload,
          currentPage: action.currentPage,
          pageSize: action.pageSize
        }
      };
    default:
      return state;
  }
}

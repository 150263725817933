import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Delete } from '@material-ui/icons';
import Fade from '@material-ui/core/Fade';

import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { settingSelector } from '../../store/selectors/shared';
import IconButtonActionConfirmationDialog from '../widgets/IconButtonActionConfirmationDialog';
import { colors, commonFont } from '../../assets/constants';
import { ReactComponent as IconEdit } from '../../assets/images/icon-edit.svg';
import LinkButton from '../widgets/LinkButton';
import BackIcon from '../../assets/images/back-icon.svg';

const style = {
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: '22px',
    width: '100%'
  },
  marginBottom: {
    marginBottom: '30px'
  },
  cancel: {
    ...commonFont,
    fontSize: '14px',
    color: colors.blue,
    '& img': {
      marginRight: '8px'
    },
    marginRight: '22px'
  },
  lineSpacer: {
    borderLeft: `solid 1px ${colors.borderBlue}`,
    height: '35px'
  },
  buttons: {
    marginRight: '40px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  header: {
    marginLeft: '19px',
    ...commonFont,
    fontSize: '28px',
    fontWeight: 600,
    color: colors.blueDark
  },
  cancelAndHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  }
};

const NewHeaderViewActions = ({
  editMode,
  onDelete,
  deleteTitle,
  deleteBody,
  editUrl,
  children,
  hasPermission,
  header,
  classes,
  cancelUrl,
  declineButtonText,
  noMargin
}) =>
  hasPermission && (
    <Fade in={!editMode} timeout={500}>
      <div className={classNames(classes.headerContainer, { [classes.marginBottom]: !noMargin })}>
        <div className={classNames(classes.cancelAndHeader, classes.flexRowCentered)}>
          {cancelUrl && (
            <Fragment>
              <Link to={cancelUrl} style={{ textDecoration: 'none' }} className={classes.cancel}>
                <img src={BackIcon} /> {declineButtonText}
              </Link>
              <div className={classes.lineSpacer} />
            </Fragment>
          )}
          <div className={classes.header}>{header}</div>
        </div>
        <div className={classes.buttons}>
          {onDelete && (
            <IconButtonActionConfirmationDialog
              icon={<Delete />}
              deleteFunction={onDelete}
              title={deleteTitle}
              body={deleteBody}
            />
          )}
          {children}
          {editUrl && (
            <LinkButton
              url={editUrl}
              text="Edit"
              primary={false}
              icon={<IconEdit fill={colors.blue} />}
              filled={false}
            />
          )}
        </div>
      </div>
    </Fade>
  );

NewHeaderViewActions.defaultProps = {
  onDelete: null,
  deleteBody: null,
  hasPermission: true
};

NewHeaderViewActions.propTypes = {
  editUrl: PropTypes.string,
  onDelete: PropTypes.func,
  deleteTitle: PropTypes.string,
  header: PropTypes.string,
  deleteBody: PropTypes.any,
  editMode: PropTypes.bool,
  cancelUrl: PropTypes.any,
  declineButtonText: PropTypes.string,
  hasPermission: PropTypes.bool,
  noMargin: PropTypes.bool,
  children: PropTypes.any,
  classes: PropTypes.object
};

const mapStateToProps = state => ({
  editMode: settingSelector(state, 'editMode')
});

export default compose(withStyles(style), connect(mapStateToProps))(NewHeaderViewActions);

import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { ruleInitialState } from '../entityInitialStates';
import { CLEAR_FILE_UPLOADS } from '../../store/actions/types';

export const listImportRequest = () => (dispatch, getState) => {
  if (getState().search.import) {
    const { filters } = getState().search.import;
    const credentialTypeFilter = filters.credentialType || NO_SEARCH_VALUE;
    const statusFilter = filters.status || NO_SEARCH_VALUE;

    return {
      /* eslint-disable max-len */
      url: `${process.env.REACT_APP_IMPORT_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${credentialTypeFilter}/${statusFilter}`,
      entityName: 'import'
    };
  }
};

export const getImportRequest = id => ({
  url: `${process.env.REACT_APP_IMPORT_GET}${id}`,
  entityName: 'imported',
  initialState: ruleInitialState
});

export const restartImportRequest = id => ({
  url: `${process.env.REACT_APP_IMPORT_GET}${id}`,
  entityName: 'import',
  action: 'restarted'
});

export const listImportRowRequest = id => {
  return {
    url: `${process.env.REACT_APP_IMPORT_GET}${id}/rows/list/${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'importRow',
    initialState: []
  };
};

export const saveImportRequest = entity => ({
  url: process.env.REACT_APP_IMPORT_SAVE,
  dispatchOverride: {
    type: CLEAR_FILE_UPLOADS
  },
  entity
});

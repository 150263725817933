import ls from 'local-storage';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { Auth } from 'aws-amplify';
import { CircularProgress } from '@material-ui/core';
import querySearch from 'query-string';
import { login, googleLogin, resetRestartLogin, completeNewPassword } from '../../store/actions/AuthenticationActions';
import authStyles from '../../assets/authStyles';
import { authenticationDetailsSelector } from '../../store/selectors/authentication';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import ChangePasswordView from './ChangePasswordView';
import { loadingSelector } from '../../store/selectors/shared';
import NewValidatedField from '../widgets/NewValidatedField';
import NewActionButton from '../widgets/NewActionButton';
import { ReactComponent as RightArrowIcon } from '../../assets/images/icon-right-arrow.svg';

const styles = {
  ...authStyles,
  authImage: {
    background: 'linear-gradient(180deg, #F9FAFB 0%, rgba(255, 255, 255, 0) 70.42%), url(authentication.png)',
    width: '100%',
    height: '100%'
  },
  authLayout: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  authContainer: {
    width: '420px'
  },
  welcomeHeader: {
    fontFamily: 'Encode Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '-0.06px',
    color: '#251B54',
    marginTop: '40px',
    textAlign: 'left'
  },
  welcomeSubMenu: {
    fontFamily: 'Encode Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '1px',
    color: '#9EA0A5',
    textAlign: 'left',
    marginTop: '4px'
  },
  subText: {
    fontFamily: 'Encode Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1px',
    color: '#9EA0A5',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  emailText: {
    fontFamily: 'Encode Sans',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '1px',
    color: '#9EA0A5',
    textAlign: 'left',
    marginTop: '20px',
    marginBottom: '10px'
  },
  googleLoginButton: {
    marginTop: '20px',
    cursor: 'pointer'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
  },
  loadingText: {
    marginLeft: 10,
    fontSize: 14
  },
  loginButton: {
    width: '100%',
    marginLeft: 0,
    marginTop: '16px'
  },
  buttonAndLink: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
};

class LoginView extends React.Component {
  state = {
    user: {
      username: '',
      password: ''
    },
    authenticating: false,
    changePassword: false,
    forgot: false
  };

  componentDidMount() {
    const { restartLogin, resetRestartLogin, location } = this.props;
    const { search } = location;
    const params = querySearch.parse(search);

    if (restartLogin) {
      resetRestartLogin();
      Auth.federatedSignIn({ provider: 'Google' });
    } else {
      this.setState({ authenticating: ls.get('authenticating'), forgot: params.forgot });
      setTimeout(() => {
        ls.remove('authenticating');
        this.setState({ authenticating: false });
      }, 5000);
    }
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { login } = this.props;
    const { user } = this.state;

    const response = await login(user);

    if (response && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      this.setState({ changePassword: true, user: response });
    }
  };

  changePassword = async password => {
    const { completeNewPassword } = this.props;
    const { user } = this.state;

    await completeNewPassword(user, password);
  };

  cancelChangePassword = () => {
    this.setState({ changePassword: false });
  };

  render() {
    const { classes, error, loginFailure, googleLogin, isLoggingIn } = this.props;
    const { user, authenticating, changePassword, forgot } = this.state;
    const commonProps = {
      onChange: this.handleChange,
      validators: [VALIDATORS_BY_NAME.REQUIRED],
      autoComplete: 'off'
    };

    return (
      <Grid container justify="center" alignContent="center" alignItems="center" className={classes.container}>
        <Grid item xs={12} className={classNames(classes.authImageContainer)}>
          <Grid item xs={6} className={classNames(classes.authImageContainer)}>
            <div className={classes.authImage} />
          </Grid>
          <Grid item xs={6} className={classes.authLayout}>
            <div className={classes.authContainer}>
              <img src="/repple-auth-logo.png" alt="Repple Logo" className={classes.logo} />
              {changePassword && (
                <ChangePasswordView
                  cancelChangePassword={this.cancelChangePassword}
                  changePassword={this.changePassword}
                />
              )}
              {!changePassword && !authenticating && (
                <div>
                  <div className={classes.welcomeHeader}>Welcome,</div>
                  <div className={classes.welcomeSubMenu}>Please login to account</div>
                  <div
                    className={classes.googleLoginButton}
                    onClick={() => {
                      googleLogin();
                    }}
                  >
                    <img src="/google-button.png" alt="Login in with Google" />
                  </div>
                  <div className={classes.subText}>or login with email address</div>

                  {forgot && <div className={classes.emailText}>An email with a temporary password has been sent.</div>}
                  {loginFailure && <FormHelperText error>{loginFailure}</FormHelperText>}
                  <div className={classes.formContainer}>
                    <NewValidatedField
                      id="username"
                      name="username"
                      label="Username"
                      value={user.username}
                      {...commonProps}
                    />

                    <NewValidatedField
                      id="password"
                      name="password"
                      label="Password"
                      value={user.password}
                      type="PASSWORD"
                      {...commonProps}
                    />
                    {error && <FormHelperText error>{error}</FormHelperText>}
                    <div className={classes.buttonAndLink}>
                      <Link to="/forgotPassword" className={classes.primaryLink}>
                        Forgot Password
                      </Link>

                      <NewActionButton
                        onChange={this.submitForm}
                        text="Login"
                        icon={<RightArrowIcon />}
                        primary
                        filled
                        className={classes.loginButton}
                        isSaving={isLoggingIn}
                      />
                    </div>
                  </div>
                </div>
              )}
              {!changePassword && authenticating && (
                <div className={classes.loading}>
                  <CircularProgress className={classes.circular} />
                  <div className={classes.loadingText}>Authenticating...</div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

LoginView.propTypes = {
  login: PropTypes.func,
  completeNewPassword: PropTypes.func,
  resetRestartLogin: PropTypes.func,
  googleLogin: PropTypes.func,
  loginFailure: PropTypes.string,
  forgot: PropTypes.bool,
  isLoggingIn: PropTypes.bool,
  loginView: PropTypes.string,
  restartLogin: PropTypes.bool,
  classes: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  error: PropTypes.object,
  authenticationDetails: PropTypes.object
};

const mapStateToProps = state => ({
  ...authenticationDetailsSelector(state),
  isLoggingIn: loadingSelector(state, 'login')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    login,
    googleLogin,
    resetRestartLogin,
    completeNewPassword
  })
)(LoginView);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ls from 'local-storage';

import GenericBreadcrumbItem from './GenericBreadcrumbItem';

const styles = () => ({
  avatar: {
    backgroundColor: '#d8d8d8',
    marginRight: '7px',
    border: 'solid 1px #979797'
  },
  breadcrumbArrow: {
    marginLeft: '8px',
    marginRight: '8px',
    color: '#bbb',
    fontSize: '32px'
  },
  breadcrumbItemContainer: {
    display: 'flex'
  }
});

const HomepageBreadcrumb = props => {
  const { classes, location } = props;

  return (
    <div className={classes.breadcrumbItemContainer}>
      <GenericBreadcrumbItem key="bc-home" mainText={ls.get('organisationName')} subText="" />
      {location.pathname === '/' ? (
        <span style={{ display: 'flex' }}>
          <i key="bc-home-arrow" className={`material-icons ${classes.breadcrumbArrow}`}>
            keyboard_arrow_right
          </i>
          <GenericBreadcrumbItem key="bc-dashboard" mainText="Dashboard" subText="" />
        </span>
      ) : null}
    </div>
  );
};

HomepageBreadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withStyles(styles)(HomepageBreadcrumb);

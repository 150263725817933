import { teamInitialState } from '../entityInitialStates';

export const listTeamRequest = () => {
  return {
    url: process.env.REACT_APP_TEAM_LIST,
    entityName: 'teams'
  };
};

export const getTeamRequest = id => ({
  url: `${process.env.REACT_APP_TEAM_GET}${id}`,
  entityName: 'team',
  initialState: teamInitialState
});

export const saveTeamRequest = entity => ({ url: process.env.REACT_APP_TEAM_SAVE, entityName: 'team', entity });

import React from 'react';
import { CustomPicker } from 'react-color';
import { Saturation, Hue } from 'react-color/lib/components/common';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import NewValidatedField from './NewValidatedField';
import { VALIDATORS_BY_NAME } from '../../assets/constants';

const styles = () => ({
  pickerContainer: {
    width: '345px'
  },
  pickerHue: {
    width: '345px',
    display: 'flex',
    flexDirection: 'row'
  },
  hue: {
    position: 'relative',
    marginLeft: '19px',
    width: '26px',
    borderRadius: '5px',
    '& > div > div > div > div': {
      width: '28px !important',
      backgroundColor: 'transparent !important',
      border: 'solid 2px white !important',
      borderRadius: '2px'
    }
  },
  picker: {
    width: '297px',
    height: '120px',
    padding: '10px 10px 0',
    boxSizing: 'initial',
    background: '#fff',
    borderRadius: '5px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.15), 0 8px 16px rgba(0,0,0,.15)',
    position: 'relative'
  },
  paddingTop: {
    marginTop: '14px'
  },
  colorCircle: {
    width: '18px',
    height: '18px',
    borderRadius: '9px',
    marginRight: '7px'
  }
});
class ReactColorPicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hsl: {
        h: 0,
        s: 0,
        l: 1
      },
      hsv: {
        h: 0,
        s: 0,
        v: 1
      },
      hex: ''
    };
  }

  static getDerivedStateFromProps(props, prevProps) {
    const { hex } = prevProps;
    const { color } = props;
    const hexCode = tinycolor(color);
    const previousHexCode = tinycolor(hex);

    if (color && !hex && hexCode !== previousHexCode) {
      return {
        hsv: hexCode.toHsv(),
        hsl: hexCode.toHsl(),
        hex: color
      };
    }
    if (!color) {
      return {
        hsl: {
          h: 0,
          s: 0,
          l: 1
        },
        hsv: {
          h: 0,
          s: 0,
          v: 1
        },
        hex: ''
      };
    }
  }

  handleHueChange = hue => {
    this.setState({
      hsl: hue
    });
  };

  handleHexChange = event => {
    const { changeHex, name } = this.props;
    const { target } = event;
    const { value } = target;
    const color = tinycolor(value);

    if (
      ((value.startsWith('#') && value.length === 7) || (!value.startsWith('#') && value.length === 6)) &&
      color.isValid()
    ) {
      this.setState({
        hsv: color.toHsv(),
        hsl: color.toHsl(),
        hex: `#${color.toHex()}`
      });
      changeHex({ target: { name, value: `#${color.toHex()}` } });
    } else {
      this.setState({
        hex: value,
        hsl: {
          h: 0,
          s: 0,
          l: 1
        },
        hsv: {
          h: 0,
          s: 0,
          v: 1
        }
      });
    }
  };

  handleSaturationChange = hsv => {
    const color = tinycolor(hsv);
    this.setState({
      hsv: color.toHsv(),
      hsl: color.toHsl(),
      hex: `#${color.toHex()}`
    });
    this.handleHexChange({ target: { value: color.toHex() } });
  };

  render() {
    const { classes } = this.props;
    const { hsv, hsl, hex } = this.state;

    return (
      <div className={classes.pickerContainer}>
        <div className={classes.pickerHue}>
          <div className={classes.picker}>
            <Saturation hsl={hsl} hsv={hsv} onChange={this.handleSaturationChange} />
          </div>
          <div className={classes.hue}>
            <Hue hsl={hsl} onChange={this.handleHueChange} direction="vertical" />
          </div>
        </div>
        <NewValidatedField
          id="backgroundColor"
          label="Background Colour"
          name="backgroundColor"
          value={hex}
          onChange={this.handleHexChange}
          validators={[VALIDATORS_BY_NAME.REQUIRED]}
          className={classes.paddingTop}
          helperText="Hex i.e. #ffffff"
          inputTextProps={{
            startAdornment: tinycolor(hex).isValid() ? (
              <div className={classes.colorCircle} style={{ backgroundColor: hex }} />
            ) : null
          }}
        />
      </div>
    );
  }
}

ReactColorPicker.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.string,
  name: PropTypes.string.isRequired,
  changeHex: PropTypes.func.isRequired
};

export default withStyles(styles)(CustomPicker(ReactColorPicker));

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { permissions } from '../../constants/global';
import { hasPermission } from '../../utils/utilities';

class CatalogView extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    if (hasPermission([permissions.READ_DATA])) history.push('/catalog/courseCatalog');
  }

  render() {
    return null;
  }
}

CatalogView.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(CatalogView);

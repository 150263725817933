import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import { getEntity, putEntity, listEntities } from '../../store/actions/EntityActions';
import { entityArraySelector } from '../../store/selectors/entity';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import NewValidatedField from './NewValidatedField';
import Heading from '../common/Heading';
import ActionButton from './ActionButton';
import { listAllUserRequest } from '../../utils/entityRequests/userAdminRequests';
import { getUserInitials } from '../../utils/utilities';
import { listIssuerCredentialTypeReferenceRequest } from '../../utils/entityRequests/credentialTypeRequests';

const styles = {
  popover: {
    width: '380px',
    height: '480px',
    padding: '30px'
  },
  popoverMargin: {
    marginTop: '30px',
    marginLeft: '-85px'
  },
  buttons: {
    marginTop: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  optionLabel: {
    backgroundBlue: 'red'
  },
  imageOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    '& > div:last-of-type': {
      marginLeft: '10px'
    }
  },
  avatar: {
    height: '30px',
    width: '30px'
  }
};

const dateOptions = [
  { id: 'TODAY', label: 'Today' },
  { id: 'YESTERDAY', label: 'Yesterday' },
  { id: 'THIS_WEEK', label: 'This Week' },
  { id: 'LAST_WEEK', label: 'Last Week' },
  { id: 'THIS_MONTH', label: 'This Month' },
  { id: 'LAST_MONTH', label: 'Last Month' },
  { id: 'THIS_YEAR', label: 'This Year' },
  { id: 'LAST_YEAR', label: 'Last Year' }
];

const expireOptions = [
  { id: 'TODAY', label: 'Today' },
  { id: 'TOMORROW', label: 'Tomorrow' },
  { id: 'THIS_WEEK', label: 'This Week' },
  { id: 'NEXT_WEEK', label: 'Next Week' },
  { id: 'THIS_MONTH', label: 'This Month' },
  { id: 'NEXT_MONTH', label: 'Next Month' },
  { id: 'NEXT_THREE_MONTH', label: 'Next 3 Months' },
  { id: 'THIS_YEAR', label: 'This Year' }
];

const orderOptions = [
  { id: 'asc', label: 'Order Date Ascending' },
  { id: 'desc', label: 'Order Date Descending' }
];

class CredentialInboxFilterDialog extends React.Component {
  constructor(props) {
    super(props);
    const { open, anchorEl } = this.props;
    this.state = {
      open,
      anchorEl,
      order: props.order,
      date: props.date,
      type: props.type,
      assignee: props.assignee,
      expire: props.expire
    };
  }

  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(listAllUserRequest());
    await getEntity(listIssuerCredentialTypeReferenceRequest());
  }

  handleChange = event => {
    const { name, value } = event.target;
    const stateModel = _.cloneDeep(this.state);
    _.set(stateModel, name, value);
    this.setState(stateModel);
  };

  handleClose = () => {
    const { handleClose } = this.props;
    handleClose();
  };

  clickFilter = async () => {
    const { search } = this.props;
    const { order, date, assignee, type, expire } = this.state;
    await search(order, date, assignee, type, expire);
    this.handleClose();
  };

  render() {
    const { open, anchorEl, order, date, assignee, type, expire } = this.state;
    const { classes, users, credentialTypes } = this.props;
    const userList = (users || []).map(item => ({
      label: `${item.firstName} ${item.lastName}`,
      id: item.identifier,
      avatarUrl: item.avatarUrl
    }));

    return (
      <div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          className={classes.popoverMargin}
        >
          <Grid container className={classes.popover}>
            <Grid item xs={12}>
              <Heading heading="Filter Credentials" fontSize="16px" />
              <NewValidatedField
                type={VALIDATED_FIELD_TYPES.SELECT}
                key="assignee"
                label="Assignee"
                value={(userList || []).find(item => item.id === assignee) || null}
                onChange={this.handleChange}
                items={userList || []}
                id="assignee"
                itemName="label"
                itemValue="id"
                renderOption={option => (
                  <Fragment>
                    <div className={classes.imageOption}>
                      <Avatar className={classes.avatar}>{getUserInitials(option.avatarUrl, option.label)}</Avatar>
                      <div>{option.label}</div>
                    </div>
                  </Fragment>
                )}
                validators={[]}
                icon={{
                  startAdornment: (userList || []).find(item => item.id === assignee) && (
                    <InputAdornment position="start">
                      <Avatar className={classes.avatar}>
                        {getUserInitials(
                          (userList || []).find(item => item.id === assignee).avatarUrl,
                          (userList || []).find(item => item.id === assignee).label
                        )}
                      </Avatar>
                    </InputAdornment>
                  )
                }}
              />
              <NewValidatedField
                type={VALIDATED_FIELD_TYPES.SELECT}
                key="type"
                label="Type"
                value={(credentialTypes || []).find(item => item.value === type) || null}
                onChange={this.handleChange}
                items={credentialTypes || []}
                id="type"
                itemName="label"
                itemValue="value"
                validators={[]}
              />
              <NewValidatedField
                type={VALIDATED_FIELD_TYPES.SELECT}
                key="date"
                label="Date"
                value={dateOptions.find(item => item.id === date) || null}
                onChange={this.handleChange}
                items={dateOptions}
                id="date"
                itemName="label"
                itemValue="id"
                validators={[]}
              />
              <NewValidatedField
                type={VALIDATED_FIELD_TYPES.SELECT}
                key="expire"
                label="Expire Date"
                value={expireOptions.find(item => item.id === expire) || null}
                onChange={this.handleChange}
                items={expireOptions}
                id="expire"
                itemName="label"
                itemValue="id"
                validators={[]}
              />
              <NewValidatedField
                type={VALIDATED_FIELD_TYPES.SELECT}
                key="order"
                label="Order"
                value={orderOptions.find(item => item.id === order) || null}
                onChange={this.handleChange}
                items={orderOptions}
                id="order"
                itemName="label"
                itemValue="id"
                validators={[]}
                displayClear
              />
              <div className={classes.buttons}>
                <Button onClick={this.handleClose}>Cancel</Button>
                <ActionButton
                  buttonText="Filter"
                  onClick={this.clickFilter}
                  isActionOccurring={false}
                  isDisabled={false}
                />
              </div>
            </Grid>
          </Grid>
        </Popover>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...entityArraySelector(state, 'users'),
  ...entityArraySelector(state, 'credentialTypes')
});

CredentialInboxFilterDialog.propTypes = {
  open: PropTypes.bool,
  classes: PropTypes.object,
  anchorEl: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  listEntities: PropTypes.func,
  organisations: PropTypes.array,
  credentialTypes: PropTypes.array,
  users: PropTypes.array,
  handleClose: PropTypes.func,
  expire: PropTypes.string,
  search: PropTypes.func,
  order: PropTypes.string.isRequired,
  date: PropTypes.string,
  assignee: PropTypes.string,
  type: PropTypes.string
};

export default withStyles(styles)(
  connect(mapStateToProps, { getEntity, putEntity, listEntities })(CredentialInboxFilterDialog)
);

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { style } from '../../assets/style';
import credentialDesignStyles from '../../assets/credentialDesignStyles';
import { colors, commonFont } from '../../assets/constants';
import CredentialPreviewStatus from '../../assets/images/credential-preview-status.svg';
import CredentialPreviewImg from '../../assets/images/credential-preview.svg';

const styles = () => ({
  ...style,
  ...credentialDesignStyles,
  topCredentialContainer: {
    borderRadius: '8px 8px 0 0',
    width: '345px',
    minHeight: '120px',
    padding: '16px'
  },
  bottomCredentialContainer: {
    borderRadius: '0 0 8px 8px',
    width: '345px',
    padding: '16px 16px 22px 16px'
  },
  credentialContainer: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: colors.white,
    padding: '15px 15px 22px 15px',
    borderRadius: '8px'
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  logoHolder: {
    width: '50px',
    height: '50px'
  },
  credentialName: {
    ...commonFont,
    fontSize: '12px',
    color: colors.white,
    fontWeight: 'bold',
    marginTop: '8px'
  },
  credentialNameAndImage: {
    textAlign: 'right'
  },
  credentialTimeline: {
    display: 'flex',
    marginTop: '15px',
    flexDirection: 'row',
    color: colors.white,
    alignItems: 'center',
    ...commonFont,
    fontWeight: 600,
    fontSize: '10px',
    '& > div:first-of-type': {
      width: '60px',
      marginRight: '13px'
    },
    '& > div:last-of-type': {
      width: '60px',
      marginLeft: '13px'
    }
  },
  bar: {
    height: '6px',
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: '6px'
  },
  progress: {
    height: '6px',
    backgroundColor: colors.lightGreen,
    borderRadius: '6px'
  },
  date: {
    width: '70px !important'
  }
});

class CredentialPreview extends PureComponent {
  render() {
    const { classes, backgroundColor, backgroundImage, logoImage, name } = this.props;
    return (
      <Fragment>
        <div
          className={classes.topCredentialContainer}
          style={{
            backgroundColor,
            backgroundImage: backgroundImage ? `url(${backgroundImage})` : ''
          }}
        >
          <div className={classes.topRow}>
            {logoImage && <img src={logoImage} height="50px" width="50px" style={{ borderRadius: '5px' }} />}
            {!logoImage && <div className={classes.logoHolder} />}
            <div className={classNames(classes.columnDirection, classes.credentialNameAndImage)}>
              <img src={CredentialPreviewStatus} />
              <div className={classes.credentialName}>{name}</div>
            </div>
          </div>
          <div className={classes.credentialTimeline}>
            <div className={classes.date}>SEP 2018</div>
            <div className={classes.bar}>
              <div className={classes.progress} style={{ width: '50%' }} />
            </div>
            <div className={classes.date}>SEP 2020</div>
          </div>
        </div>
        <div
          className={classes.bottomCredentialContainer}
          style={{
            backgroundColor
          }}
        >
          <div className={classes.credentialContainer}>
            <img src={CredentialPreviewImg} />
          </div>
        </div>
      </Fragment>
    );
  }
}
CredentialPreview.propTypes = {
  classes: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  name: PropTypes.string
};

export default withStyles(styles)(CredentialPreview);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { getEntity, createInitialEntity, listEntities } from '../../../store/actions/EntityActions';
import { style } from '../../../assets/style';
import layout from '../../../assets/layout';
import SectionHeading from '../../common/SectionHeading';
import NewHeaderViewActions from '../../header/NewHeaderViewActions';
import { loadingSelector } from '../../../store/selectors/shared';
import { entitySelector } from '../../../store/selectors/entity';
import { getCentreRequest, listCentreRoomRequest } from '../../../utils/entityRequests/trainingRequests';
import ListingWithSearch from '../../widgets/ListingWithSearch';
import { tableSelector } from '../../../store/selectors/table';
import ScrollableTable from '../../table/ScrollableTable';
import { getRecords } from '../../../utils/utilities';

const styles = {
  ...layout,
  ...style
};

class CentreView extends React.Component {
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getCentreRequest(id));
  }

  // componentWillUnmount() {
  //   const { createInitialEntity } = this.props;
  //   createInitialEntity(getCentreRequest(''));
  // }

  renderMarkers = (map, maps, centre) => {
    const marker = new maps.Marker({
      position: { lat: parseFloat(centre.position.latitude), lng: parseFloat(centre.position.longitude) },
      map
    });
    return marker;
  };

  listCentreRooms = async (page, pageSize, additionalSearchParam) => {
    const { listEntities } = this.props;
    await listEntities(0, 20, additionalSearchParam.request(additionalSearchParam.id));
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);

    return items.map(room => ({
      clickAction: () => this.navigateRoom(room.identifier),
      cells: [
        {
          value: room.name
        },
        {
          value: room.location
        },
        {
          value: room.capacity
        }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Name' }, { value: 'Location' }, { value: 'Capacity' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Rooms Found"
        {...props}
      />
    );
  };

  navigateAddRoom = () => {
    const { history, match } = this.props;
    const { params } = match;
    const { id } = params;
    history.push(`/training/centre/view/${id}/room/create`);
  };

  navigateRoom = roomId => {
    const { history, match } = this.props;
    const { params } = match;
    const { id } = params;
    history.push(`/training/centre/view/${id}/room/view/${roomId}`);
  };

  render() {
    const { classes, match, centre, table } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(centre).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={`/training/centre/edit/${id}`}
            header="Centre Details"
            cancelUrl="/training/centre"
          />
          <Grid item xs={6}>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Name</label>
              <div className={classes.displayField}>{centre.name}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Address</label>
              <div className={classes.displayField}>{centre.address}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Latitude</label>
              <div className={classes.displayField}>{(centre.position && centre.position.latitude) || 'Not Set'}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Longitude</label>
              <div className={classes.displayField}>{(centre.position && centre.position.longitude) || 'Not Set'}</div>
            </div>
            <div className={classes.sectionHeadingContainer}>
              <SectionHeading heading="Rooms" className={classes.headingAndAction}>
                <Tooltip title="Add Room" aria-label="add" placement="bottom">
                  <IconButton
                    aria-owns="menu-appbar"
                    onClick={this.navigateAddRoom}
                    color="inherit"
                    key="openSelectUser"
                  >
                    <i className={`material-icons ${classes.icon}`}>add</i>
                  </IconButton>
                </Tooltip>
              </SectionHeading>
            </div>
            <ListingWithSearch
              name="rooms"
              onList={this.listCentreRooms}
              renderList={props => this.getListing(props)}
              list={table}
              hideSearch
              additionalSearchParams={{ id, request: listCentreRoomRequest }}
            />
          </Grid>
          <Grid item xs={6}>
            {centre.position && centre.position.latitude && centre.position.longitude && (
              <div className={classes.googleMap}>
                {/*<GoogleMapReact*/}
                {/*  bootstrapURLKeys={{ key: window.reppleEnv.GOOGLE_KEY }}*/}
                {/*  defaultCenter={{*/}
                {/*    lat: parseFloat(centre.position.latitude),*/}
                {/*    lng: parseFloat(centre.position.longitude)*/}
                {/*  }}*/}
                {/*  defaultZoom={15}*/}
                {/*  yesIWantToUseGoogleMapApiInternals*/}
                {/*  onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps, centre)}*/}
                {/*/>*/}
              </div>
            )}
          </Grid>
        </Grid>
      )
    );
  }
}

CentreView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  listEntities: PropTypes.func,
  createInitialEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  table: PropTypes.object,
  centre: PropTypes.object,
  course: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'centre'),
  isLoading: loadingSelector(state, 'getEntity'),
  ...tableSelector(state, 'rooms')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, createInitialEntity, listEntities })
)(CentreView);

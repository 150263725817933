import { CLEAR_GLOBAL_ERROR, GLOBAL_ERROR } from './types';

export const clearGlobalError = () => ({
  type: CLEAR_GLOBAL_ERROR
});

export const dispatchGlobalError = (errorTitle, message) => async dispatch =>
  dispatch({
    type: GLOBAL_ERROR,
    payload: { error_description: message, errorTitle }
  });

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import color from 'color';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { colors, commonFont } from '../../assets/constants';

const style = {
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.white,
    borderRadius: '5px',
    height: '45px',
    textTransform: 'none',
    ...commonFont,
    fontWeight: 600,
    fontSize: '14px',
    boxShadow: 'none',
    marginLeft: '20px',
    marginBottom: '10px'
  },
  textAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  primary: {
    background: colors.orangeNew,
    '&:hover': {
      background: color(colors.orangeNew)
        .lighten(0.1)
        .hex()
    },
    '& svg, & i': {
      marginLeft: '23px'
    }
  },
  secondary: {
    background: colors.blue,
    border: `solid 1px ${colors.blue}`,
    '&:hover': {
      background: color(colors.blue)
        .lighten(0.1)
        .hex()
    },
    '& svg, & i': {
      marginRight: '23px'
    }
  },
  unfilled: {
    background: 'none !important',
    color: colors.blue
  },
  icon: {
    display: 'flex',
    alignItems: 'center'
  }
};

const LinkButton = ({ classes, primary, url, text, icon, filled }) => (
  <Link to={url} style={{ textDecoration: 'none' }}>
    <Button
      data-qa="ok"
      className={classNames(
        classes.button,
        { [classes.primary]: primary },
        { [classes.secondary]: !primary },
        { [classes.unfilled]: !filled }
      )}
      variant="contained"
    >
      {primary && (
        <div className={classes.textAndIcon}>
          <div>{text}</div>
          <div className={classes.icon}>{icon}</div>
        </div>
      )}
      {!primary && (
        <div className={classes.textAndIcon}>
          <div className={classes.icon}>{icon}</div>
          <div>{text}</div>
        </div>
      )}
    </Button>
  </Link>
);

LinkButton.propTypes = {
  classes: PropTypes.object.isRequired,
  primary: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  filled: PropTypes.bool,
  icon: PropTypes.any.isRequired
};

export default withStyles(style)(LinkButton);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities, putEntity, deleteEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { capitalise } from '../../utils/string';
import { tableSelector } from '../../store/selectors/table';
import {
  listNotificationRequest,
  notificationViewedRequest,
  notificationDeleteRequest
} from '../../utils/entityRequests/notificationRequests';
import NewHeaderActions from '../header/NewHeaderActions';

const styles = {
  ...style
};

class NotificationListView extends React.Component {
  actionNavigate = (id, url) => {
    const { history, putEntity } = this.props;
    putEntity(notificationViewedRequest(id));
    if (!url.startsWith('http')) history.push(url);
    else document.location.href = url;
  };

  handleDelete = (event, id) => {
    const { deleteEntity, listEntities } = this.props;
    event.stopPropagation();
    event.preventDefault();
    deleteEntity(notificationDeleteRequest(id)).then(() => {
      listEntities(0, 20, listNotificationRequest());
    });
  };

  getRowConfigs = () => {
    const { table } = this.props;
    const items = table.payload ? table.payload.records : [];
    return items.map(notification => ({
      clickAction: () => this.actionNavigate(notification.identifier, notification.url),
      actions: [
        {
          action: (
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={event => this.handleDelete(event, notification.identifier)}>
                <i className="material-icons">delete</i>
              </IconButton>
            </Tooltip>
          )
        }
      ],
      class: notification.viewDate ? '' : 'bold',
      cells: [{ value: capitalise(notification.notificationType) }, { value: notification.message }]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Type' }, { value: 'Message' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Notifications Found"
        overrideHeight="calc(100vh - 140px)"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, table } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
          <NewHeaderActions header="Notification" buttons={[]} />

          <ListingWithSearch
            name="notifications"
            onList={listEntities}
            renderList={props => this.getListing(props)}
            list={table}
            // hideSearch
            additionalSearchParams={listNotificationRequest}
          />
        </Grid>
      </Grid>
    );
  }
}

NotificationListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  putEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities'),
  ...tableSelector(state, 'notifications')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listEntities, putEntity, deleteEntity })
)(NotificationListView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { putEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { changePasswordRequest } from '../../utils/entityRequests/userAdminRequests';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout,
  marginTop: {
    marginTop: '10px'
  }
});

class ChangePasswordView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: {
        previousPassword: '',
        password: '',
        repeatPassword: ''
      }
    };

    this.cancelUrl = '/profile/view';
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { changePassword } = this.state;
    const changePasswordModel = _.cloneDeep(changePassword);
    _.set(changePasswordModel, name, value);

    this.setState({
      changePassword: changePasswordModel
    });
  };

  submitForm = async () => {
    const { putEntity, history } = this.props;
    const { changePassword } = this.state;
    const changePasswordResult = _.cloneDeep(changePassword);
    const profileId = await putEntity(changePasswordRequest(changePasswordResult));

    if (!profileId) return;

    history.push('/profile/view');
  };

  render() {
    const { classes, isSaving, errors } = this.props;
    const { changePassword } = this.state;
    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              isDisabled={isSaving}
              header="Change Password"
            />

            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classNames(classes.fieldContainer)}>
                    <NewValidatedField
                      id="previousPassword"
                      name="previousPassword"
                      label="Previous Password"
                      value={changePassword.previousPassword}
                      type="PASSWORD"
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      errorText={errors && errors.fieldErrors['previousPassword']}
                    />
                  </div>
                  <div className={classNames(classes.fieldContainer)}>
                    <NewValidatedField
                      id="password"
                      name="password"
                      label="New Password"
                      value={changePassword.password}
                      type="PASSWORD"
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      errorText={errors && errors.fieldErrors['password']}
                    />
                  </div>
                  <div className={classNames(classes.fieldContainer)}>
                    <NewValidatedField
                      id="repeatPassword"
                      name="repeatPassword"
                      label="Repeat Password"
                      value={changePassword.repeatPassword}
                      type="PASSWORD"
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      errorText={errors && errors.fieldErrors['repeatPassword']}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton isSaving={isSaving} onSubmit={this.submitForm} isDisabled={isSaving} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangePasswordView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  uploadToS3: PropTypes.func.isRequired,
  putEntity: PropTypes.func,
  match: PropTypes.object,
  isSaving: PropTypes.bool,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'changePassword'),
  isSaving: loadingSelector(state, 'putEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    putEntity
  }),
  withRouter
)(ChangePasswordView);

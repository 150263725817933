import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { style } from '../../assets/style';

const styles = {
  ...style,
  select2: {
    marginBottom: '-1px',
    '&:before': {
      borderBottom: 'none'
    }
  },
  selector__control: {
    background: 'none',
    border: 'none'
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px'
  },
  selectRoot: {
    backgroundColor: '#fafafa',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    '&:before': {
      transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
    }
  },
  error: {
    borderBottom: '2px solid red !important'
  },
  formHelper: {
    margin: '8px 12px 0'
  }
};

const DropdownIndicator = props => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowDropDownIcon
          color="action"
          style={{
            top: '50%',
            transform: 'translateY(-50%)',
            position: 'absolute',
            right: '0px'
          }}
        />
      </components.DropdownIndicator>
    )
  );
};

const customStyles = {
  control: () => ({
    boxShadow: '0',
    height: '56px'
  }),
  input: () => ({
    padding: '27px 12px 10px'
  })
};

class SelectWithInput extends Component {
  state = {
    focus: false
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  handleMenuOpen = () => {
    this.setState({
      focus: true
    });
  };

  handleMenuClose = () => {
    this.setState({
      focus: false
    });
  };

  handleChange = props => {
    const { multiple, id, handleChange } = this.props;
    const singleValue = props ? props.value : '';
    handleChange({ target: { name: id, value: multiple ? props : singleValue } });
  };

  handleFocus = () => {
    const { multiple, id, handleChange } = this.props;

    if (multiple) return;

    handleChange({ target: { name: id, value: '' } });
  };

  render() {
    const { items, itemName, itemValue, value, className, classes, label, error, multiple, values } = this.props;
    const options = items.map(item => ({ value: item[itemValue], label: item[itemName] }));
    const { focus } = this.state;
    const shrink = focus || (multiple && values.length !== 0) || (!multiple && !!value.value);

    return (
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel variant="filled" shrink={shrink} error={!!error}>
            {label}
          </InputLabel>
          <div className={classNames({ [classes.error]: !!error }, classes.selectRoot)}>
            <CreatableSelect
              isClearable
              backspaceRemovesValue
              blurInputOnSelect
              classNamePrefix="selector"
              isMulti={multiple}
              components={{ DropdownIndicator }}
              styles={customStyles}
              value={multiple ? values : value}
              onFocus={this.handleFocus}
              onChange={this.handleChange}
              options={options}
              className={`${className} ${classNames(classes.select2)}`}
              placeholder=""
              onMenuOpen={this.handleMenuOpen}
              onMenuClose={this.handleMenuClose}
              showNewOptionAtTop={false}
            />
          </div>
          {!!error && (
            <FormHelperText error className={classNames(classes.formHelper, classes.fieldHelperText)}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      </div>
    );
  }
}

SelectWithInput.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string.isRequired,
  itemName: PropTypes.string,
  itemValue: PropTypes.string,
  items: PropTypes.array.isRequired,
  value: PropTypes.object,
  values: PropTypes.array,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(SelectWithInput);

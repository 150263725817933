import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import authenticationRoute from '../routes/AuthenticationRoute';

const AuthenticationLayout = () => {
  return (
    <Switch>
      {authenticationRoute.map((prop, key) => {
        if (prop.redirect) return <Redirect from={prop.path} to={prop.to} key={key} />;
        return <Route path={prop.path} component={prop.component} key={key} exact={prop.exact} />;
      })}
    </Switch>
  );
};

export default AuthenticationLayout;

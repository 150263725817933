/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { STANDARD_CREDENTIAL_TYPE } from '../../assets/constants';
import { getCrowdsourceCredentialTypeRequest } from '../../utils/entityRequests/crowdsourceRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style
};

class CrowdsourceCredentialTypeView extends React.Component {
  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getCrowdsourceCredentialTypeRequest(id));
  }

  render() {
    const { classes, crowdCredentialType } = this.props;

    return (
      Object.keys(crowdCredentialType).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions header="Crowdsource Credential Type" />
          <Grid item xs={6}>
            <DisplayField heading="Name" value={crowdCredentialType.name} />
            <DisplayField heading="Holder" value={crowdCredentialType.holderName} />
            <DisplayField
              heading="Background Credential"
              value={STANDARD_CREDENTIAL_TYPE[crowdCredentialType.standardCredentialType].label}
            />
            <DisplayField
              heading="Organisation"
              value={
                crowdCredentialType.organisation
                  ? crowdCredentialType.organisation.name
                  : crowdCredentialType.crowdsourceOrganisation.name
              }
            />
            <DisplayField heading="Expires" value={crowdCredentialType.expires ? 'True' : 'False'} />
            {crowdCredentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE && (
              <DisplayField heading="Type" value={crowdCredentialType.type} />
            )}
            {crowdCredentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION && (
              <DisplayField heading="Field of Study" value={crowdCredentialType.fieldOfStudy} />
            )}
            {crowdCredentialType.logoUrl && <img src={crowdCredentialType.logoUrl} />}
          </Grid>
        </Grid>
      )
    );
  }
}

CrowdsourceCredentialTypeView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  crowdCredentialType: PropTypes.object,
  getEntity: PropTypes.func,
  crowdOrganisation: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'crowdCredentialType')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(CrowdsourceCredentialTypeView);

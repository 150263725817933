import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity, putEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import {
  getOrganisationMemberRequest,
  removeMemberFromOrganisationRequest
} from '../../utils/entityRequests/organisationMemberRequests';
import { entitySelector } from '../../store/selectors/entity';
import DisplayField from '../widgets/DisplayField';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import NewActionButton from '../widgets/NewActionButton';

const styles = {
  ...layout,
  ...style
};

class OrganisationMemberView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getOrganisationMemberRequest(id));
  }

  handleRemoveHolder = async () => {
    const { organisationMember, putEntity, history } = this.props;
    await putEntity(removeMemberFromOrganisationRequest(organisationMember.identifier));
    history.push('/organisation/organisationMember');
  };

  render() {
    const { classes, organisationMember } = this.props;

    return (
      Object.keys(organisationMember).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions header="Organisation Member Details">
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">cancel</i>}
              text="Remove Member"
              onChange={this.handleRemoveHolder}
              filled
            />
          </NewHeaderViewActions>
          <Grid item xs={6}>
            <DisplayField
              heading="Name"
              value={`${organisationMember.user.firstName} ${organisationMember.user.lastName}`}
            />
            <DisplayField heading="Email" value={organisationMember.user.email} />
            <DisplayField heading="Phone" value={organisationMember.user.phone} />
            <DisplayField heading="Join Date" value={organisationMember.startDate} />
          </Grid>
        </Grid>
      )
    );
  }
}

OrganisationMemberView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  organisationMember: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'organisationMember')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity, putEntity }))(OrganisationMemberView);

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { style } from '../../assets/style';
import { colors, commonFont } from '../../assets/constants';
import { ReactComponent as OrangeDownArrowIcon } from '../../assets/images/orange-down-carret.svg';

const styles = {
  ...style,
  root: {
    '& .MuiInput-underline:after': {
      borderBottom: 'none'
    },
    '& .MuiFormLabel-root': {
      fontWeight: 'normal',
      ...commonFont
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.white,
      '& fieldset': {
        borderColor: 'rgba(135, 152, 173, 0.3)',
        backgroundColor: colors.white
      },
      '&:hover fieldset': {
        borderColor: 'rgba(135, 152, 173, 0.3)',
        backgroundColor: colors.white
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.orangeNew,
        backgroundColor: colors.white
      }
    }
  },
  formControl: {
    width: '100%',
    marginBottom: '16px',
    '& .MuiAutocomplete-popupIndicator svg': {
      height: '6px',
      width: '12px',
      marginRight: '4px',
      marginLeft: '4px'
    }
  },
  selectRoot: {
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    borderBottom: 'none'
  },
  error: {
    '& fieldset': {
      border: `1px solid ${colors.errorRed} !important`
    }
  }
  // formHelper: {
  //   margin: '8px 12px 0'
  // }
};

const filter = createFilterOptions();

class SelectWithSearch extends Component {
  handleChange = (event, option) => {
    const { id, handleChange, itemValue } = this.props;
    if (option) {
      if (Array.isArray(option)) {
        handleChange({ target: { name: id, value: option.map(item => item[itemValue]) || id } });
      } else handleChange({ target: { name: id, value: option[itemValue] } });
    } else handleChange({ target: { name: id, value: '' } });
  };

  render() {
    const {
      items,
      itemName,
      itemValue,
      value,
      classes,
      label,
      error,
      icon,
      id,
      className,
      renderOption,
      displayClear,
      creatable,
      multiple
    } = this.props;
    return (
      <FormControl className={classNames(classes.formControl, classes.root, className)}>
        <div className={classNames({ [classes.error]: !!error }, classes.selectRoot)}>
          {creatable && (
            <Autocomplete
              id="autocomplete"
              onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                  this.handleChange(event, {
                    [itemName]: newValue
                  });
                } else if (newValue && newValue[itemValue]) {
                  this.handleChange(event, {
                    [itemName]: newValue[itemValue],
                    [itemValue]: newValue[itemValue]
                  });
                } else {
                  this.handleChange(event, newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    [itemValue]: params.inputValue,
                    [itemName]: `Add "${params.inputValue}"`
                  });
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              options={items}
              getOptionLabel={option => {
                if (typeof option === 'string') {
                  return option;
                }
                return option[itemName];
              }}
              style={{ width: '100%' }}
              value={value}
              disableClearable={displayClear}
              popupIcon={<OrangeDownArrowIcon fill={colors.orangeNew} />}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      ...icon
                    }}
                    label={label}
                    name={id}
                    variant="outlined"
                  />
                );
              }}
              renderOption={renderOption}
              multiple={multiple}
              variant="outlined"
            />
          )}
          {!creatable && (
            <Autocomplete
              id="autocompleteno"
              onChange={this.handleChange}
              options={items}
              getOptionLabel={option => option[itemName] || ''}
              style={{ width: '100%', zIndex: 0 }}
              value={value}
              disableClearable={displayClear}
              popupIcon={<OrangeDownArrowIcon fill={colors.orangeNew} />}
              multiple={multiple}
              renderInput={params => {
                return (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      ...icon
                    }}
                    label={label}
                    name={id}
                    variant="outlined"
                  />
                );
              }}
              renderOption={renderOption}
              variant="outlined"
            />
          )}
        </div>
        {!!error && (
          <FormHelperText error className={classNames(classes.formHelper, classes.fieldHelperText)}>
            {error}
          </FormHelperText>
        )}
      </FormControl>
    );
  }
}

SelectWithSearch.defaultProps = {
  displayClear: false,
  creatable: false,
  multiple: false
};

SelectWithSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  id: PropTypes.string,
  itemName: PropTypes.string,
  itemValue: PropTypes.string,
  items: PropTypes.array.isRequired,
  value: PropTypes.any,
  values: PropTypes.array,
  handleChange: PropTypes.func,
  renderOption: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  icon: PropTypes.any,
  displayClear: PropTypes.bool,
  creatable: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(SelectWithSearch);

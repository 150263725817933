import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getWorkPlaceUrl } from '../../utils/searchUrls';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { listWorkplaceRequest } from '../../utils/entityRequests/workPlaceRequests';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class WorkPlaceListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/workplace/location/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(workPlace => ({
      clickAction: () => this.actionNavigate(workPlace.identifier),
      cells: [
        { value: workPlace.name },
        { value: workPlace.address },
        { value: workPlace.position && workPlace.position.latitude },
        { value: workPlace.position && workPlace.position.longitude }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Name' }, { value: 'Address' }, { value: 'Latitude' }, { value: 'Longitude' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Workplaces Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, table } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="Location"
              buttons={[
                {
                  text: 'Add Location',
                  icon: <AddWhiteIcon />,
                  url: '/workplace/location/create',
                  primary: true
                }
              ]}
            />
            <ListingWithSearch
              name="workPlaces"
              getSearchUrl={getWorkPlaceUrl}
              onList={listEntities}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listWorkplaceRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

WorkPlaceListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-workPlaces'),
  ...tableSelector(state, 'workPlaces')
});

export default compose(withStyles(styles), connect(mapStateToProps, { listEntities }))(WorkPlaceListView);

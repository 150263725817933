import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import color from 'color';
import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import classNames from 'classnames';
import { colors, customTheme, commonFont } from '../../assets/constants';

const style = {
  first: {
    borderRadius: '5px 0 0 5px',
    '& > div > div': {
      marginLeft: '12px !important'
    }
  },
  last: {
    borderRadius: '0 5px 5px 0',
    '& > div > div': {
      marginRight: '12px'
    }
  },
  firstLast: {
    borderRadius: '5px 5px 5px 5px'
  },
  notFirstLast: {
    '& > div > div': {
      marginLeft: '12px !important'
    }
  },
  button: {
    ...commonFont,
    width: '126px',
    minHeight: '45px',
    fontSize: '12px',
    fontWeight: 600,
    backgroundColor: colors.backgroundBlue,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    // borderRadius: '5px',
    '&:hover': {
      background: color(colors.backgroundBlue)
        .darken(0.05)
        .hex()
    }
  },
  buttonDiv: {
    '& > div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    }
  }
};

const TopLevelButton = ({
  classes,
  isActionOccurring,
  isDisabled,
  buttonText,
  buttonIcon,
  buttonColor,
  first,
  last,
  onClick,
  className
}) => {
  return (
    <div
      className={classNames(
        className,
        classes.button,
        { [classes.buttonDiv]: !isActionOccurring },
        { [classes.first]: first },
        { [classes.last]: last },
        { [classes.firstLast]: first && last },
        { [classes.notFirstLast]: !first && !last }
      )}
      onClick={() => {
        if (!isDisabled && !isActionOccurring) onClick();
      }}
      key={buttonText}
      style={{ color: buttonColor }}
    >
      {isActionOccurring && (
        <MuiThemeProvider theme={customTheme}>
          <CircularProgress size={15} color="inherit" />
        </MuiThemeProvider>
      )}
      {!isActionOccurring && (
        <div>
          {(first || (!first && !last)) && (
            <Fragment>
              {buttonIcon}
              <div>{buttonText}</div>
            </Fragment>
          )}
          {!first && last && (
            <Fragment>
              <div>{buttonText}</div>
              {buttonIcon}
            </Fragment>
          )}
        </div>
      )}
    </div>
  );
};

TopLevelButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isActionOccurring: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  isDisabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  buttonIcon: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default withStyles(style)(TopLevelButton);

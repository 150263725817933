import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import { joinOrganisationMemberRequest } from '../../utils/entityRequests/organisationMemberRequests';
import { CircularProgress } from '@material-ui/core';
import { colors, commonFont } from '../../assets/constants';

const styles = {
  ...style,
  centerAll: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%'
  },
  processing: {
    ...commonFont,
    color: colors.blueDark,
    display: 'flex',
    flexDirection: 'row',
    '& div': {
      marginLeft: '8px'
    },
    alignItems: 'center'
  }
};

class JoinOrganisationMemberView extends React.Component {
  state = { message: '' };
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { uuid } = params;
    const response = await getEntity(joinOrganisationMemberRequest(uuid));

    this.setState({ message: response ? response.value : 'We are unable to process the join request' });
  }

  render() {
    const { classes } = this.props;
    const { message } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: false
        })}
      >
        <Grid item xs={12} className={classes.centerAll}>
          {message ? (
            message
          ) : (
            <div className={classes.processing}>
              <CircularProgress size={15} color="inherit" className={classes.spaceSpinner} />
              <div>Processing...</div>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

JoinOrganisationMemberView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func
};

const mapStateToProps = () => ({});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(JoinOrganisationMemberView);

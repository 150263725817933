import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';

export const getCrowdsourceOrganisationRequest = id => ({
  url: `${process.env.REACT_APP_GET_CROWDSOURCE_ORGANISATION}${id}`,
  entityName: 'crowdOrganisation'
});

export const listCrowdsourceOrganisationRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_CROWDSOURCE_ORGANISATION}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'crowdOrganisations'
  };
};

export const listCrowdsourceCredentialTypeRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_CROWDSOURCE_CREDENTIAL_TYPE}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'crowdCredentialTypes'
  };
};

export const getCrowdsourceCredentialTypeRequest = id => ({
  url: `${process.env.REACT_APP_GET_CROWDSOURCE_CREDENTIAL_TYPE}${id}`,
  entityName: 'crowdCredentialType'
});

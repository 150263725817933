import { TOGGLE_SETTING } from '../actions/types';

export const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SETTING: {
      const { name, toggle } = action.payload;

      return {
        ...state,
        [name]: toggle
      };
    }

    default:
      return state;
  }
}

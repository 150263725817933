import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../../store/actions/EntityActions';
import { style } from '../../../assets/style';
import layout from '../../../assets/layout';
import NewHeaderViewActions from '../../header/NewHeaderViewActions';
import { loadingSelector } from '../../../store/selectors/shared';
import { entitySelector } from '../../../store/selectors/entity';
import { getRoomRequest } from '../../../utils/entityRequests/trainingRequests';

const styles = {
  ...layout,
  ...style
};

class RoomView extends React.Component {
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id, roomId } = params;
    await getEntity(getRoomRequest(id, roomId));
  }

  render() {
    const { classes, match, room } = this.props;
    const { params } = match;
    const { id, roomId } = params;

    return (
      Object.keys(room).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={`/training/centre/view/${id}/room/edit/${roomId}`}
            header="Room Details"
            cancelUrl={`/training/centre/view/${id}`}
          />
          <Grid item xs={6}>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Name</label>
              <div className={classes.displayField}>{room.name}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Location</label>
              <div className={classes.displayField}>{room.location}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Capacity</label>
              <div className={classes.displayField}>{room.capacity}</div>
            </div>
          </Grid>
        </Grid>
      )
    );
  }
}

RoomView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  room: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'room'),
  isLoading: loadingSelector(state, 'getEntity')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(RoomView);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { listReasonRequest } from '../../utils/entityRequests/reasonRequests';
import { entityArraySelector } from '../../store/selectors/entity';

const styles = {
  ...style
};

class ReasonListView extends React.Component {
  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(listReasonRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/workforce/reason/edit/${id}`);
  };

  getRowConfigs = () => {
    const { reasons } = this.props;
    const items = reasons || [];
    return items.map(reason => ({
      clickAction: () => this.actionNavigate(reason.identifier),
      cells: [
        {
          value: reason.name
        },
        {
          value: reason.deprecated ? 'Yes' : 'No'
        }
      ]
    }));
  };

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <ScrollableTable
              headers={[{ value: 'Name' }, { value: 'Deprecated' }]}
              rowConfigs={this.getRowConfigs()}
              complete={!isLoading}
              emptyMessage="No Reasons Found"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ReasonListView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  reasons: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  ...entityArraySelector(state, 'reasons')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(ReasonListView);

import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';
import ChangeWorkPlaceView from '../components/workplace/ChangeWorkPlaceView';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';
import WorkPlaceView from '../components/workplace/WorkPlaceView';
import WorkPlaceListView from '../components/workplace/WorkPlaceListView';
import WorkPlaceMemberListView from '../components/workplace/WorkPlaceMemberListView';
import ChangeIncidentView from '../components/incident/ChangeIncidentView';
import IncidentView from '../components/incident/IncidentView';
import IncidentListView from '../components/incident/IncidentListView';
import AttendanceListView from '../components/attendance/AttendanceListView';
import OrganisationCredentialCheckView from '../components/credentialCheck/OrganisationCredentialCheckView';
/* eslint-disable max-len */
import OrganisationCredentialCheckListItemBreadcrumb from '../components/breadcrumbs/OrganisationCredentialCheckListItemBreadcrumb';
/* eslint-enable max-len */
import OrganisationCredentialCheckListView from '../components/credentialCheck/OrganisationCredentialCheckListView';
import WorkPlaceMemberView from '../components/workplace/WorkPlaceMemberView';
import InviteWorkPlaceMemberView from '../components/workplace/InviteWorkPlaceMemberView';
import GenericHeadingOnlyBreadcrumb from '../components/breadcrumbs/GenericHeadingOnlyBreadcrumb';
import ObservationListView from '../components/workplace/ObservationListView';
import ObservationView from '../components/workplace/ObservationView';
import ChangeObservationView from '../components/workplace/ChangeObservationView';

export default [
  // Work place routes
  {
    path: '/workplace/location/view/:id/member/create',
    component: InviteWorkPlaceMemberView,
    breadcrumb: GenericHeadingOnlyBreadcrumb,
    protected: true,
    heading: 'Workplace Member'
  },
  {
    path: '/workplace/location/view/:id/member/view/:memberId',
    component: WorkPlaceMemberView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'workPlaceMember',
    itemName: 'holder.name',
    heading: 'Workplace Member'
  },
  {
    path: '/workplace/location/view/:id/member',
    component: WorkPlaceMemberListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'workPlaceMembers',
    functionName: 'listEntities',
    heading: 'Workplace Members'
  },
  {
    path: '/workplace/location/view/:id/observation/create',
    component: ChangeObservationView,
    breadcrumb: GenericHeadingOnlyBreadcrumb,
    protected: true,
    heading: 'Observation'
  },
  {
    path: '/workplace/location/view/:id/observation/edit/:observationId',
    component: ChangeObservationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'observation',
    itemName: 'title',
    heading: 'Observation'
  },
  {
    path: '/workplace/location/view/:id/observation/view/:observationId',
    component: ObservationView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'observation',
    itemName: 'title',
    heading: 'Observation'
  },
  {
    path: '/workplace/location/view/:id/observation',
    component: ObservationListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'observations',
    functionName: 'listEntities',
    heading: 'Workplace Observations'
  },
  {
    path: '/workplace/location/edit/:id',
    component: ChangeWorkPlaceView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'workPlace',
    itemName: 'name',
    heading: 'Workplace'
  },
  { path: '/workplace/location/create', component: ChangeWorkPlaceView, breadcrumb: null, protected: true },
  {
    path: '/workplace/location/view/:id',
    component: WorkPlaceView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'workPlace',
    itemName: 'name',
    heading: 'Workplace'
  },
  {
    path: '/workplace/location',
    component: WorkPlaceListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'workPlaces',
    functionName: 'listEntities',
    heading: 'Workplace'
  },

  // Incident routes
  {
    path: '/workplace/incident/create',
    component: ChangeIncidentView,
    breadcrumb: null,
    protected: true
  },
  {
    path: '/workplace/incident/edit/:id',
    component: ChangeIncidentView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'incident',
    itemName: 'workPlace.name',
    heading: 'Incident'
  },
  {
    path: '/workplace/incident/view/:id',
    component: IncidentView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'incident',
    itemName: 'workPlace.name',
    heading: 'Incident'
  },
  {
    path: '/workplace/incident',
    component: IncidentListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    functionName: 'listEntities',
    tableName: 'incidents',
    heading: 'Incident'
  },

  // Attendance routes
  {
    path: '/workplace/attendance',
    component: AttendanceListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    functionName: 'listEntities',
    tableName: 'attendance',
    heading: 'Attendance'
  },
  {
    path: '/workplace/organisationCredentialCheck/view/:id',
    component: OrganisationCredentialCheckView,
    breadcrumb: OrganisationCredentialCheckListItemBreadcrumb,
    protected: true
  },
  {
    path: '/workplace/organisationCredentialCheck',
    component: OrganisationCredentialCheckListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'organisationCredentialChecks',
    functionName: 'listEntities',
    heading: 'Credential Check'
  }
];

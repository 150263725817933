import { colors, headerHeight } from './constants';

export default {
  dashboardBackground: {
    backgroundColor: colors.grey5,
    height: `calc(100vh - ${headerHeight}px)`
  },
  dashboardStatContainer: {
    width: '300px',
    overflow: 'initial'
  },
  statsContainer: {
    padding: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  graphContainer: {
    padding: '40px'
  },
  statIconBox: {
    padding: '40px'
  },
  dashboardStatValue: {
    display: 'flex',
    fontSize: '250%',
    justifyContent: 'center',
    color: 'rgba(0, 0, 0, 0.5)'
  },
  iconContainer: {
    padding: '15px',
    width: '76px',
    height: '76px',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
    borderRadius: '3px',
    alignItems: 'center',
    marginTop: '-36px'
  },
  completedIconContainer: {
    background: 'linear-gradient(60deg, #66bb6a, #43a047)'
  },
  createdIconContainer: {
    background: 'linear-gradient(60deg, #26c6da, #00acc1)'
  },
  assignedIconContainer: {
    background: 'linear-gradient(60deg, rgb(255, 167, 38), rgb(251, 140, 0))'
  },
  failedIconContainer: {
    background: 'linear-gradient(60deg, #ef5350, #e53935)'
  },
  cardContent: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  statIcon: {
    fontSize: '30px'
  },
  statTitle: {
    fontSize: '14px',
    color: colors.grey4,
    fontWeight: '300',
    '& > div': {
      color: 'rgba(60, 72, 88)',
      fontSize: '26px',
      textAlign: 'right',
      marginTop: '7px'
    }
  },
  graphTitle: {
    padding: '10px 0 0 15px',
    '& > div span': {
      fontSize: '18px',
      color: colors.grey6,
      fontWeight: '300',
      marginLeft: '20px'
    }
  },
  dashboardGraphContainer: {
    width: '550px',
    overflow: 'initial',
    padding: '5px 10px 15px 10px'
  },
  extendedGraphContainer: {
    width: '700px',
    overflow: 'initial',
    padding: '5px 10px 15px 10px',
    alignItems: 'flex-start'
  },
  extendedGraphCardContent: {
    display: 'flex'
  },
  chartPointer: {
    cursor: 'pointer'
  }
};

import { NOTIFICATION, CLEAR_NOTIFICATION, UPDATE_SERVICE_WORKER } from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
        stayOpen: false
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: ''
      };
    case UPDATE_SERVICE_WORKER:
      return {
        ...state,
        notification: 'The application has been updated.',
        stayOpen: true
      };
    default:
      return state;
  }
}

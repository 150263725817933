import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { permissions } from '../../constants/global';
import { hasPermission } from '../../utils/utilities';

class WorkplaceAdminView extends React.Component {
  componentWillMount() {
    const { history } = this.props;
    if (hasPermission([permissions.WORKPLACE_LOCATION_ADMIN])) history.push('/workplace/location');
  }

  render() {
    return null;
  }
}

WorkplaceAdminView.propTypes = {
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(WorkplaceAdminView);

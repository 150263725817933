import { credentialVersionInitialState, ruleInitialState } from '../entityInitialStates';
import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';

export const getCredentialRequest = id => ({
  url: `${process.env.REACT_APP_CREDENTIAL_GET}${id}`,
  entityName: 'credential',
  initialState: ruleInitialState
});

export const revokeCredentialRequest = documentIds => ({
  url: `${process.env.REACT_APP_CREDENTIAL_SAVE}revoke`,
  entityName: 'credential',
  action: 'revoke',
  entity: documentIds
});

export const rejectCredentialRequest = documentIds => ({
  url: `${process.env.REACT_APP_CREDENTIAL_SAVE}reject`,
  entityName: 'credential',
  action: 'reject',
  entity: documentIds
});

export const verifyCredentialRequest = documentIds => ({
  url: `${process.env.REACT_APP_CREDENTIAL_SAVE}verify`,
  entityName: 'credential',
  action: 'verify',
  entity: documentIds
});

export const expireCredentialRequest = entity => ({
  url: `${process.env.REACT_APP_CREDENTIAL_SAVE}${entity.documentId}/expire`,
  entityName: 'credential',
  action: 'updated',
  entity
});

export const listCredentialVersionRequest = id => ({
  url: `${process.env.REACT_APP_CREDENTIAL_VERSION_LIST}${id}`,
  entityName: 'credentialVersions',
  initialState: []
});

export const getCredentialVersionRequest = (id, transactionId) => ({
  url: `${process.env.REACT_APP_CREDENTIAL_VERSION_GET}${id}/${transactionId}`,
  entityName: 'credentialVersion',
  initialState: credentialVersionInitialState
});

export const saveCredentialRequest = (credentialRequestId, entity) => ({
  url: `${process.env.REACT_APP_CREDENTIAL_SAVE}${credentialRequestId}`,
  entityName: 'credential',
  entity,
  action: 'created'
});

export const listCredentialRequest = (status, order, date, assignee, type, expire, searchText) => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_CREDENTIAL_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${status}/${order}/${assignee ||
      'ALL'}/${type || 'ALL'}/${date || 'ALL'}/${expire || 'ALL'}?searchText=${searchText || ''}`,
    entityName: 'credentials'
  };
};

export const getCredentialMessagesRequest = id => ({
  url: `${process.env.REACT_APP_CREDENTIAL_GET}${id}/messages`,
  entityName: 'messages',
  initialState: { messages: [] }
});

export const sendCredentialMessageRequest = (id, message) => ({
  url: `${process.env.REACT_APP_CREDENTIAL_GET}${id}/message`,
  entity: { value: message },
  entityName: 'message',
  action: 'sent'
});

export const getCreateCredentialRequest = crdentialTypeUuid => ({
  url: `${process.env.REACT_APP_TEST_GET_CREATE_CREDENTIAL}${crdentialTypeUuid}`,
  entityName: 'credential'
});

export const listOtherCredentialRequest = (holderId, documentId) => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_CREDENTIAL_LIST}${holderId}/${documentId}`,
    entityName: 'otherCredentials'
  };
};

export const assignCredentialRequest = (userId, documentIds) => ({
  url: `${process.env.REACT_APP_CREDENTIAL_SAVE}assign/${userId}`,
  entityName: 'credential',
  action: 'assigned',
  entity: { ids: documentIds }
});

export const getCredentialLogBookRequest = id => ({
  url: `${process.env.REACT_APP_CREDENTIAL_LOG_BOOK_GET}${id}`,
  entityName: 'credentialLogBook',
  initialState: []
});

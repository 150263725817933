import _ from 'lodash';

import {
  CLEAR_FILE_UPLOADS,
  FILE_UPLOAD_DELETED,
  LIST_FILE_CREDENTIAL_REQUEST_DOWNLOADS,
  LIST_FILE_DOWNLOADS,
  LIST_FILE_UPLOADS,
  UPDATE_FILE_PROGRESS
} from '../actions/types';

const initialState = {
  documentUploads: {},
  fileDownloads: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LIST_FILE_UPLOADS:
      const docUploads = _.cloneDeep(state.documentUploads);
      Object.keys(docUploads).forEach(docUpload => {
        docUploads[docUpload].forEach(upload => {
          if (upload.completeness === 100) {
            const fileUpload = action.payload.find(item => item.identifier === upload.fileId);
            if (fileUpload) {
              upload.status = fileUpload.status;
              upload.mimeType = fileUpload.mimeType;
              upload.error = fileUpload.error;
            }
          }
        });
      });

      return {
        ...state,
        fileUploads: action.payload,
        documentUploads: docUploads
      };
    case UPDATE_FILE_PROGRESS: {
      const { fileName, fileId, progress, error, controlName } = action.payload;

      const newUpload = {
        name: fileName,
        fileId,
        completeness: Number.isNaN(progress.loaded / progress.total) ? 0 : (progress.loaded / progress.total) * 100,
        error
      };

      const docUploads = _.cloneDeep(state.documentUploads);
      if (!docUploads[controlName]) docUploads[controlName] = [];

      const upload = docUploads[controlName].find(item => item.fileId === action.payload.fileId);

      if (!upload) {
        docUploads[controlName].push(newUpload);
      } else {
        upload.completeness = Number.isNaN(progress.loaded / progress.total)
          ? 0
          : (progress.loaded / progress.total) * 100;
      }

      return {
        ...state,
        documentUploads: docUploads
      };
    }
    case LIST_FILE_DOWNLOADS:
      return {
        ...state,
        fileDownloads: action.payload
      };
    case CLEAR_FILE_UPLOADS:
      return {
        ...state,
        documentUploads: {}
      };
    case LIST_FILE_CREDENTIAL_REQUEST_DOWNLOADS:
      return {
        ...state,
        credentialRequestFileDownloads: action.payload
      };
    case FILE_UPLOAD_DELETED:
      return {
        ...state,
        fileDownloads: state.fileDownloads.slice().filter(item => item.identifier !== action.id)
      };
    default:
      return state;
  }
}

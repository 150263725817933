import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@material-ui/core';
import Modal from '@material-ui/core/Modal/Modal';
import moment from 'moment';
import { getEntity, putEntity, listEntities, deleteEntity } from '../../../store/actions/EntityActions';
import { style } from '../../../assets/style';
import {
  deleteScheduledSessionRequest,
  getScheduledCourseRequest,
  listScheduledCourseHolderRequest
} from '../../../utils/entityRequests/trainingRequests';
import SectionHeading from '../../common/SectionHeading';
import { entityArraySelector, entitySelector } from '../../../store/selectors/entity';
import { PERIOD_TYPE } from '../../../assets/constants';
import ScrollableTable from '../../table/ScrollableTable';
import { convertUtcToLocal, convertUtcToLocalMoment } from '../../../utils/utilities';
import ListingWithSearch from '../../widgets/ListingWithSearch';
import { tableSelector } from '../../../store/selectors/table';
import TrainingScheduleComponent from './TrainingScheduleComponent';

const styles = {
  ...style
};

class ScheduledCourseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleOpen: false,
      trainingScheduleConfig: {
        courseSessionId: '',
        scheduledSessionId: '',
        scheduledCourseId: '',
        durationMinutes: 0,
        allDay: false,
        startTime: '',
        endTime: '',
        roomId: '',
        centreId: '',
        showCourseFilter: false,
        scrollToTime: '',
        modal: true
      }
    };
  }

  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getScheduledCourseRequest(id));
  }

  handleClickUnscheduledSession = session => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    this.setState({
      scheduleOpen: true,
      trainingScheduleConfig: {
        courseSessionId: session.identifier,
        scheduledCourseId: id,
        scheduledSessionId: '',
        durationMinutes: this.calculateNumberMinutes(session.duration, session.periodType),
        allDay: session.periodType === PERIOD_TYPE.DAY.id,
        scrollToTime: moment()
          .startOf('day')
          .add(8, 'hours')
          .toDate(),
        startTime: '',
        endTime: '',
        roomId: '',
        centreId: '',
        modal: true
      }
    });
  };

  handleClickDeleteScheduledSession = scheduledSessionId => {
    const { deleteEntity, match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;
    deleteEntity(deleteScheduledSessionRequest(id, scheduledSessionId)).then(() => {
      getEntity(getScheduledCourseRequest(id));
    });
  };

  handleClickScheduledSession = session => {
    const { match } = this.props;
    const { params } = match;
    const { id } = params;

    this.setState({
      scheduleOpen: true,
      trainingScheduleConfig: {
        courseSessionId: session.courseSessionId,
        scheduledCourseId: id,
        scheduledSessionId: session.identifier,
        durationMinutes: this.calculateNumberMinutes(session.duration, session.periodType),
        allDay: session.periodType === PERIOD_TYPE.DAY.id,
        scrollToTime: convertUtcToLocalMoment('DD-MM-YYYY HH:mm', session.sessionTime)
          .startOf('day')
          .add(8, 'hours')
          .toDate(),
        startTime: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', session.sessionTime),
        endTime: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', session.sessionEndTime),
        roomId: session.room.identifier,
        centreId: session.centre.identifier,
        defaultDate: convertUtcToLocalMoment('DD-MM-YYYY HH:mm', 'DD-MM-YYYY', session.sessionTime),
        modal: true
      }
    });
  };

  handleCloseSchedule = () => {
    this.setState({
      scheduleOpen: false,
      trainingScheduleConfig: {
        courseSessionId: '',
        scheduledSessionId: '',
        scheduledCourseId: '',
        durationMinutes: 0,
        allDay: false,
        scrollToTime: '',
        startTime: '',
        endTime: '',
        roomId: '',
        centreId: '',
        modal: true
      }
    });
  };

  calculateNumberMinutes = (duration, periodType) => {
    let minutes = 0;

    if (periodType === PERIOD_TYPE.MINUTE) minutes = duration;
    else if (periodType === PERIOD_TYPE.HOUR) minutes = duration * 60;
    else if (periodType === PERIOD_TYPE.DAY) minutes = duration * 60 * 24;

    return minutes;
  };

  calculateDuration = (startTime, endTime) => {
    const minutes = endTime.diff(startTime, 'minutes');
    let duration = '';
    if (minutes / 1440 >= 1) duration = `${Math.floor(minutes / 1440)} days`;
    if (minutes % 1440 !== 0 && minutes % 1440 > 60) {
      duration += minutes / 60 < 24 ? `${Math.floor(minutes / 60)} hours` : `, ${Math.floor(minutes / 60 - 24)} hours`;
    }
    if (minutes % 60 !== 0) duration += minutes / 60 > 1 ? `, ${minutes % 60} minutes` : `${minutes % 60} minutes`;
    return duration;
  };

  getRowConfigs = () => {
    const { scheduledCourse } = this.props;

    const items = scheduledCourse.scheduledSessions || [];
    const results = items.map(scheduledSession => ({
      actions: [
        {
          action: (
            <Tooltip title="Change Scheduled Session" aria-label="add" placement="bottom">
              <IconButton onClick={() => this.handleClickScheduledSession(scheduledSession)}>
                <i className="material-icons">schedule</i>
              </IconButton>
            </Tooltip>
          )
        },
        {
          action: (
            <Tooltip title="Delete Scheduled Session" aria-label="add" placement="bottom">
              <IconButton onClick={() => this.handleClickDeleteScheduledSession(scheduledSession.identifier)}>
                <i className="material-icons">delete</i>
              </IconButton>
            </Tooltip>
          )
        }
      ],
      cells: [
        {
          value: scheduledSession.centre.name
        },
        {
          value: scheduledSession.room.name
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledSession.sessionTime)
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledSession.sessionEndTime)
        },
        {
          value: this.calculateDuration(
            convertUtcToLocalMoment('DD-MM-YYYY HH:mm', scheduledSession.sessionTime),
            convertUtcToLocalMoment('DD-MM-YYYY HH:mm', scheduledSession.sessionEndTime)
          )
        }
      ]
    }));

    const sessions = scheduledCourse.unscheduledSessions || [];
    sessions.forEach(session => {
      results.push({
        actions: [
          {
            action: (
              <Tooltip title="Schedule Session" aria-label="add" placement="bottom">
                <IconButton onClick={() => this.handleClickUnscheduledSession(session)}>
                  <i className="material-icons">schedule</i>
                </IconButton>
              </Tooltip>
            )
          }
        ],
        cells: [
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: ''
          },
          {
            value: session.allDay ? '' : `${session.duration} ${PERIOD_TYPE[session.periodType].label}`
          }
        ]
      });
    });

    return results;
  };

  getListingRowConfigs = () => {
    const { table } = this.props;
    return (table.payload ? table.payload.records : []).map(scheduledCourseHolder => ({
      cells: [
        {
          value: scheduledCourseHolder.name
        },
        {
          value: scheduledCourseHolder.email
        },
        {
          value: scheduledCourseHolder.phone
        }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[
          {
            value: 'Name'
          },
          {
            value: 'Email'
          },
          {
            value: 'Phone'
          }
        ]}
        rowConfigs={this.getListingRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Scheduled Attendees Found"
        {...props}
      />
    );
  };

  render() {
    const { match, classes, scheduledCourse, isLoading, listEntities, table } = this.props;
    const { scheduleOpen, trainingScheduleConfig } = this.state;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(scheduledCourse).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, classes.flexNowrap, {
            [classes.editMode]: false
          })}
        >
          <Grid item xs={12}>
            <SectionHeading heading="Scheduled Course Details" underlined />
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Course</label>
              <div className={classes.displayField}>{scheduledCourse.courseTitle}</div>
            </div>

            <SectionHeading heading="Training Sessions" />
            <ScrollableTable
              headers={[
                { value: 'Centre' },
                { value: 'Room' },
                { value: 'Start Time' },
                { value: 'End Time' },
                { value: 'Duration' }
              ]}
              rowConfigs={this.getRowConfigs()}
              complete={!isLoading}
              emptyMessage="No training sessions Found"
              overrideStyle={{ height: 'auto', overflowY: 'visible' }}
            />
            <SectionHeading heading="Attendees" />
            <ListingWithSearch
              name="scheduledCourseHolders"
              onList={listEntities}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={() => listScheduledCourseHolderRequest(id)}
              hideSearch
            />
            <Modal open={scheduleOpen} onClose={this.handleCloseSchedule} disableEnforceFocus>
              <Grid item xs={12} className={classes.modalContainer}>
                <TrainingScheduleComponent
                  closeView={this.handleCloseSchedule}
                  trainingScheduleConfig={trainingScheduleConfig}
                />
              </Grid>
            </Modal>
          </Grid>
        </Grid>
      )
    );
  }
}

ScheduledCourseView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  putEntity: PropTypes.func,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  scheduledCourse: PropTypes.object,
  match: PropTypes.object,
  course: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'scheduledCourse'),
  ...entityArraySelector(state, 'scheduledSessions'),
  ...tableSelector(state, 'scheduledCourseHolders')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, putEntity, listEntities, deleteEntity })
)(ScheduledCourseView);

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { InView } from 'react-intersection-observer';

import { CircularProgress, MuiThemeProvider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { style } from '../../../assets/style';
import { commonStyles, customTheme, sizes } from '../../../assets/constants';
import CourseDisplay from './CourseDisplay';

const styles = () => ({
  ...style,
  catalogContainer: {
    // borderTop: 'solid 1px #e0e0e0',
    justifyContent: 'center',
    display: 'flex',
    paddingTop: '40px'
  },
  courseContainer: {
    padding: '20px',
    '&:hover': {
      boxShadow: '0px 3px 8px 0 rgba(0, 0, 0, 0.3)'
    },
    cursor: 'pointer'
  },
  imageAndData: {
    display: 'flex',
    flexDirection: 'row'
  },
  heading: {
    fontSize: sizes.fontBig,
    fontWeight: 550,
    color: '#222',
    lineHeight: 0.89
  },
  secondTierInfo: {
    marginTop: '15px',
    fontSize: '14px',
    fontWeight: '200'
  },
  secondTierGroup: {
    marginRight: '20px',
    alignItems: 'end',
    display: 'flex',
    '& > div > i': {
      fontSize: '14px',
      marginRight: '6px'
    }
  },
  explanation: {
    marginTop: '12px',
    color: 'rgba(0, 0, 0, 0.8)'
  },
  scrollingBody: {
    overflowY: 'auto',
    height: 'calc(100vh - 109px)',
    position: 'relative',
    borderTop: '1px transparent',
    transition: commonStyles.transition
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%'
  },
  loadingText: {
    marginLeft: 10,
    fontSize: 14
  }
});

class CatalogList extends Component {
  scrollRef = React.createRef();

  getStyleOverride = () => {
    const { overrideHeight } = this.props;

    return overrideHeight ? { height: overrideHeight } : {};
  };

  handleScroll = async () => {
    const { scroll } = this.props;
    const scrollTop = this.scrollRef.current.scrollTop;
    await scroll();
    this.scrollRef.current.scrollTop = scrollTop;
  };

  renderLoader() {
    const { classes } = this.props;

    return (
      <div className={classes.loading}>
        <MuiThemeProvider theme={customTheme}>
          <CircularProgress className={classes.circular} />
        </MuiThemeProvider>
        <div className={classes.loadingText}>Loading...</div>
      </div>
    );
  }

  renderNoResults() {
    const { emptyMessage } = this.props;

    return <div>{emptyMessage}</div>;
  }

  renderRows(ref) {
    const { rowConfigs } = this.props;
    return rowConfigs.map((row, rowIndex) => {
      return (
        <Fragment key={`course-${row.identifier}`}>
          {rowIndex === rowConfigs.length - 3 && <div ref={ref} />}
          <CourseDisplay course={row} clickUrl={`/catalog/courseCatalog/view/${row.uuid}`} />
          {rowConfigs.length - 3 <= 0 && <div ref={ref} />}
        </Fragment>
      );
    });
  }

  renderContent(ref) {
    const { complete, rowConfigs } = this.props;

    if (!complete) return this.renderLoader();
    if (!rowConfigs || rowConfigs.length === 0) return this.renderNoResults();
    return this.renderRows(ref);
  }

  render() {
    const { classes, rowConfigs, complete } = this.props;
    return (
      <div className={classNames(classes.scrollingBody)} style={this.getStyleOverride()} ref={this.scrollRef}>
        {rowConfigs.length !== 0 && complete && (
          <InView
            key="view-inView"
            onChange={inView => {
              if (inView) this.handleScroll();
            }}
            threshold={1}
          >
            {({ ref }) => this.renderContent(ref)}
          </InView>
        )}
        {rowConfigs.length === 0 && this.renderContent()}
      </div>
    );
  }
}

CatalogList.defaultProps = {
  initialSearch: () => {},
  scroll: () => {}
};

CatalogList.propTypes = {
  classes: PropTypes.object.isRequired,
  complete: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  rowConfigs: PropTypes.array,
  scroll: PropTypes.func,
  initialSearch: PropTypes.func,
  organisationMemberSearch: PropTypes.object,
  scheduledSearch: PropTypes.object,
  isSaving: PropTypes.bool,
  overrideHeight: PropTypes.string
};

export default compose(withRouter, withStyles(styles))(CatalogList);

/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { convertUtcToLocal } from '../../utils/utilities';
import { INCIDENT_TYPE, RELATIONSHIP_TYPE } from '../../assets/constants';
import { getIncidentRequest } from '../../utils/entityRequests/incidentRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style
};

class IncidentView extends React.Component {
  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getIncidentRequest(id));
  }

  render() {
    const { classes, match, incident } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(incident).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions editUrl={`/workplace/incident/edit/${id}`} header="Incident Details" />
          <Grid item xs={6}>
            <DisplayField
              heading="Holder Name"
              value={
                incident.incidentPerson.email
                  ? `${incident.incidentPerson.firstName} ${incident.incidentPerson.lastName}`
                  : incident.holder.name
              }
            />
            <DisplayField
              heading="Holder Phone"
              value={incident.incidentPerson.email ? incident.incidentPerson.phone : incident.holder.phone}
            />
            <DisplayField
              heading="Holder Email"
              value={incident.incidentPerson.email ? incident.incidentPerson.email : incident.holder.phone}
            />
            {!incident.incidentPerson.email && (
              <DisplayField heading="Holder D.O.B" value={incident.holder.dateOfBirth || 'Not Set'} />
            )}
            <DisplayField
              heading="Incident Time"
              value={convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', incident.incidentTime)}
            />
            <DisplayField
              heading="Incident Type"
              value={incident.incidentType ? INCIDENT_TYPE[incident.incidentType].label : ''}
            />
            <DisplayField
              heading="Relationship Type"
              value={incident.relationshipType ? RELATIONSHIP_TYPE[incident.relationshipType].label : ''}
            />
            <DisplayField heading="Outcome" value={incident.hospitalised ? 'Hospitalised' : 'Treated'} />
            <DisplayField heading="Description" value={incident.description} dangerousHtml />
            <DisplayField heading="Cost" value={incident.cost || 'Not Set'} />
          </Grid>
        </Grid>
      )
    );
  }
}

IncidentView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  incident: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'incident')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(IncidentView);

import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity, createInitialEntity, deleteEntity, putEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { loadingSelector } from '../../store/selectors/shared';
import { entitySelector } from '../../store/selectors/entity';
import {
  getWorkPlaceMemberRequest,
  removeWorkPlaceMemberRequest,
  declineWorkPlaceMemberRequest,
  acceptWorkPlaceMemberRequest
} from '../../utils/entityRequests/workPlaceRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import NewActionButton from '../widgets/NewActionButton';
import { convertUtcToLocal } from '../../utils/utilities';

const styles = {
  ...layout,
  ...style,
  displayLabelAndField: {
    marginTop: '6px'
  },
  qualifications: {
    marginTop: '20px'
  }
};

class WorkPlaceMemberView extends React.Component {
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { memberId } = params;
    await getEntity(getWorkPlaceMemberRequest(memberId));
  }

  removeMember = async () => {
    const { match, deleteEntity, history } = this.props;
    const { params } = match;
    const { memberId, id } = params;
    await deleteEntity(removeWorkPlaceMemberRequest(memberId));
    history.push(`/workplace/location/view/${id}/member`);
  };

  declineMember = async () => {
    const { match, putEntity, history } = this.props;
    const { params } = match;
    const { memberId, id } = params;
    await putEntity(declineWorkPlaceMemberRequest(memberId));
    history.push(`/workplace/location/view/${id}/member`);
  };

  acceptMember = async () => {
    const { match, putEntity, history } = this.props;
    const { params } = match;
    const { memberId, id } = params;
    await putEntity(acceptWorkPlaceMemberRequest(memberId));
    history.push(`/workplace/location/view/${id}/member`);
  };

  render() {
    const { classes, match, workPlaceMember } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(workPlaceMember).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <NewHeaderViewActions header="Member Details" cancelUrl={`/workplace/location/view/${id}/member`}>
                {workPlaceMember.joinStartDate && !workPlaceMember.joinEndDate && (
                  <NewActionButton
                    primary
                    icon={<i className="material-icons">clear</i>}
                    text="Remove Member"
                    onChange={this.removeMember}
                    filled
                  />
                )}
                {!workPlaceMember.joinStartDate && workPlaceMember.requestDate && !workPlaceMember.declinedDate && (
                  <Fragment>
                    <NewActionButton
                      primary
                      icon={<i className="material-icons">check</i>}
                      text="Accept Membership"
                      onChange={this.acceptMember}
                      filled
                    />
                    <NewActionButton
                      primary={false}
                      icon={<i className="material-icons">highlight_off</i>}
                      text="Decline Membership"
                      onChange={this.declineMember}
                      filled={false}
                    />
                  </Fragment>
                )}
              </NewHeaderViewActions>
            </Grid>
          </Grid>
          <Grid item lg={6} md={12}>
            <DisplayField heading="Name" value={workPlaceMember.holder.name} />
            {workPlaceMember.inviter && <DisplayField heading="Inviter Name" value={workPlaceMember.inviter.name} />}
            {workPlaceMember.inviteDate && (
              <DisplayField
                heading="Invite Date"
                value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', workPlaceMember.inviteDate)}
              />
            )}
            {workPlaceMember.requestDate && (
              <DisplayField
                heading="Request Date"
                value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', workPlaceMember.requestDate)}
              />
            )}
            {workPlaceMember.declineDate && (
              <DisplayField
                heading="Decline Date"
                value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', workPlaceMember.declineDate)}
              />
            )}
            {workPlaceMember.joinStartDate && (
              <DisplayField
                heading="Start Date"
                value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', workPlaceMember.joinStartDate)}
              />
            )}
            {workPlaceMember.joinEndDate && (
              <DisplayField
                heading="End Date"
                value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', workPlaceMember.joinEndDate)}
              />
            )}
          </Grid>
        </Grid>
      )
    );
  }
}

WorkPlaceMemberView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  createInitialEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  workPlaceMember: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'workPlaceMember'),
  isLoading: loadingSelector(state, 'getEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, createInitialEntity, deleteEntity, putEntity })
)(WorkPlaceMemberView);

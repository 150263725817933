import breadcrumbRoutes from './breadcrumbRoutes';

import pageRoutes from './pageRoutes';
import credentialDefinitionPageRoutes from './credentialDefinitionPageRoutes';
import workplacePageRoutes from './workplacePageRoutes';
import workforcePageRoutes from './workforcePageRoutes';
import organisationPageRoutes from './organisationPageRoutes';

export default [
  ...breadcrumbRoutes,
  ...pageRoutes,
  ...credentialDefinitionPageRoutes,
  ...workplacePageRoutes,
  ...workforcePageRoutes,
  ...organisationPageRoutes
];

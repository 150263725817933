import _ from 'lodash';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { IconButton, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getIncidentRequest, saveIncidentRequest } from '../../utils/entityRequests/incidentRequests';
import { listOrganisationWorkplaceRequest } from '../../utils/entityRequests/workPlaceRequests';
import { searchOrganisationMemberRequest } from '../../utils/entityRequests/organisationMemberRequests';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { INCIDENT_TYPE, RELATIONSHIP_TYPE, VALIDATORS_BY_NAME } from '../../assets/constants';
import { loadingSelector } from '../../store/selectors/shared';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { convertUtcToLocal, isEdit } from '../../utils/utilities';
import NewSectionHeading from '../common/NewSectionHeading';
import { entityArraySelector, entitySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import { incidentInitialState } from '../../utils/entityInitialStates';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import DisplayField from '../widgets/DisplayField';
import SaveButton from '../widgets/SaveButton';

const styles = {
  ...layout,
  ...style,
  holderDetails: {
    backgroundColor: '#fafafa',
    marginBottom: '16px',
    padding: '15px'
  }
};
const incidentTypes = [
  { label: INCIDENT_TYPE.DANGEROUS_INCIDENT.label, value: INCIDENT_TYPE.DANGEROUS_INCIDENT.id },
  { label: INCIDENT_TYPE.DEATH.label, value: INCIDENT_TYPE.DEATH.id },
  { label: INCIDENT_TYPE.SERIOUS_ILLNESS.label, value: INCIDENT_TYPE.SERIOUS_ILLNESS.id },
  { label: INCIDENT_TYPE.SERIOUS_INJURY.label, value: INCIDENT_TYPE.SERIOUS_INJURY.id }
];

const relationshipTypes = [
  { label: RELATIONSHIP_TYPE.CONTRACTOR.label, value: RELATIONSHIP_TYPE.CONTRACTOR.id },
  { label: RELATIONSHIP_TYPE.GROUP_TRAINING.label, value: RELATIONSHIP_TYPE.GROUP_TRAINING.id },
  { label: RELATIONSHIP_TYPE.LABOUR_HIRE.label, value: RELATIONSHIP_TYPE.LABOUR_HIRE.id },
  {
    label: RELATIONSHIP_TYPE.MEMBER_OF_PUBLIC.label,
    value: RELATIONSHIP_TYPE.MEMBER_OF_PUBLIC.id
  },
  { label: RELATIONSHIP_TYPE.SELF_EMPLOYED.label, value: RELATIONSHIP_TYPE.SELF_EMPLOYED.id },
  { label: RELATIONSHIP_TYPE.WORKER.label, value: RELATIONSHIP_TYPE.WORKER.id }
];

const outcomeTypes = [
  { name: 'Hospitalised', identifier: 'hospitalised' },
  { name: 'Treated', identifier: 'treated' },
  { name: 'Nothing', identifier: 'nothing' }
];

class ChangeIncidentView extends React.Component {
  constructor(props) {
    super(props);
    const { id } = this.props.match.params;
    this.cancelUrl = `/workplace/incident/view/${id}`;
    this.isEdit = isEdit(this.props.location.pathname);

    this.state = {
      incident: incidentInitialState,
      searchText: '',
      searched: false
    };
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    await getEntity(listOrganisationWorkplaceRequest());

    if (this.isEdit) {
      const incident = await getEntity(getIncidentRequest(id));
      this.setState({ incident });
    } else {
      this.setState({
        incident: incidentInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ incident: incidentInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { incident } = this.state;
    const incidentResult = _.cloneDeep(incident);
    _.set(incidentResult, name, value);

    this.setState({
      incident: incidentResult
    });
  };

  handleChangeSearch = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  handleOutcomeChange = event => {
    const { value } = event.target;
    const { incident } = this.state;

    const incidentResult = _.cloneDeep(incident);

    if (value === 'hospitalised') {
      incidentResult.hospitalised = true;
      incidentResult.treated = false;
    } else if (value === 'treated') {
      incidentResult.hospitalised = false;
      incidentResult.treated = true;
    } else {
      incidentResult.hospitalised = false;
      incidentResult.treated = false;
    }

    this.setState({
      incident: incidentResult
    });
  };

  searchHolder = async () => {
    const { getEntity } = this.props;
    const { searchText } = this.state;
    const organisationMemberSearch = await getEntity(searchOrganisationMemberRequest(searchText));

    this.handleChange({
      target: {
        name: 'holder.uuid',
        value: organisationMemberSearch.uuid
      }
    });

    this.setState({
      searched: true
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { history, saveEntity } = this.props;
    const { incident } = this.state;

    const incidentId = await saveEntity(saveIncidentRequest(incident));

    if (!incidentId) return;

    history.push(`/workplace/incident/view/${incidentId}`);
  };

  getName = () => {
    const { organisationMemberSearch } = this.props;
    const { searched, incident } = this.state;

    if (!incident) return '';
    if (searched && organisationMemberSearch.uuid) return organisationMemberSearch.name;
    if (incident.incidentPerson && incident.incidentPerson.email) {
      return `${incident.incidentPerson.firstName} ${incident.incidentPerson.lastName}`;
    }
    return incident.holder.name;
  };

  getPhone = () => {
    const { organisationMemberSearch } = this.props;
    const { searched, incident } = this.state;

    if (!incident) return '';
    if (searched && organisationMemberSearch.uuid) return organisationMemberSearch.phone;
    if (incident.incidentPerson && incident.incidentPerson.email) return incident.incidentPerson.phone;
    return incident.holder.phone;
  };

  getEmail = () => {
    const { organisationMemberSearch } = this.props;
    const { searched, incident } = this.state;

    if (!incident) return '';
    if (searched && organisationMemberSearch.uuid) return organisationMemberSearch.email;
    if (incident.incidentPerson && incident.incidentPerson.email) return incident.incidentPerson.email;
    return incident.holder.email;
  };

  getDob = () => {
    const { organisationMemberSearch } = this.props;
    const { searched, incident } = this.state;

    if (!incident) return '';
    if (searched && organisationMemberSearch.uuid) return organisationMemberSearch.dateOfBirth;
    if (incident.incidentPerson && incident.incidentPerson.email) return incident.incidentPerson.dateOfBirth;
    return incident.holder.dateOfBirth;
  };

  getWorkplace = () => {
    const { organisationWorkPlaces } = this.props;
    const { incident } = this.state;
    if (incident.workPlace && incident.workPlace.identifier) {
      const workplace = (organisationWorkPlaces || []).find(item => item.value === incident.workPlace.identifier);
      return workplace ? { label: workplace.label, value: workplace.value } : null;
    }
    return null;
  };

  getIncidentType = () => {
    const { incident } = this.state;
    if (incident.incidentType) {
      return incidentTypes.find(item => item.value === incident.incidentType);
    }
    return null;
  };

  getRelationshipType = () => {
    const { incident } = this.state;
    if (incident.relationshipType) {
      return relationshipTypes.find(item => item.value === incident.relationshipType);
    }
    return null;
  };

  getOutcomeType = () => {
    const { incident } = this.state;
    if (incident.hospitalised) {
      return { name: 'Hospitalised', identifier: 'hospitalised' };
    }
    if (incident.treated) {
      return { name: 'Treated', identifier: 'treated' };
    }

    return { name: 'Nothing', identifier: 'nothing' };
  };

  render() {
    const { classes, isSaving, organisationWorkPlaces, organisationMemberSearch, errors } = this.props;
    const { incident, searchText, searched } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              isDisabled={!this.isEdit && !searched}
              header="Incident Details"
            />
            <Grid item xs={12} container className={classes.formContent}>
              {!this.isEdit && (
                <Grid item xs={6}>
                  <div className={classNames(classes.fieldContainer, classes.headingAndAction)}>
                    <NewValidatedField
                      id="searchText"
                      label="Search Email / Phone"
                      name="searchText"
                      value={searchText}
                      onChange={this.handleChangeSearch}
                      validators={[]}
                    />
                    <Tooltip title="Search" aria-label="add" placement="bottom">
                      <IconButton aria-owns="menu-appbar" onClick={this.searchHolder} color="inherit">
                        <i className={`material-icons ${classes.icon}`}>search</i>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
              )}
              {((searched && organisationMemberSearch.uuid) || this.isEdit) && (
                <Grid container>
                  <Grid item xs={6} className={classes.holderDetails}>
                    <NewSectionHeading heading="Holder Details" />
                    <DisplayField heading="Name" value={this.getName()} />
                    <DisplayField heading="Phone" value={this.getPhone()} />
                    <DisplayField heading="Email" value={this.getEmail()} />
                    <DisplayField heading="D.O.B" value={this.getDob() || 'Not Set'} />
                  </Grid>
                </Grid>
              )}
              {searched && !organisationMemberSearch.uuid && (
                <Grid item xs={12}>
                  <Grid item xs={6}>
                    <FormHelperText error>Unable to find holder.</FormHelperText>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        id="incidentPerson.firstName"
                        label="First Name"
                        name="incidentPerson.firstName"
                        value={incident.incidentPerson.firstName}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        id="incidentPerson.lastName"
                        label="Last Name"
                        name="incidentPerson.lastName"
                        value={incident.incidentPerson.lastName}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        id="incidentPerson.email"
                        label="Email"
                        name="incidentPerson.email"
                        value={incident.incidentPerson.email}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.PHONE}
                        id="incidentPerson.phone"
                        label="Phone"
                        name="incidentPerson.phone"
                        value={incident.incidentPerson.phone}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        errorText={errors && errors.fieldErrors['incidentPerson.phone']}
                      />
                    </div>
                  </Grid>
                </Grid>
              )}

              <Grid container>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.DATE_TIME}
                        name="incidentTime"
                        label="Incident Time"
                        value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', incident.incidentTime)}
                        onChange={this.handleChange}
                        id="incidentTime"
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        maxDate={new Date()}
                      />
                    </div>
                  </Grid>
                  {!this.isEdit && (
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.SELECT}
                          key="workPlace.identifier"
                          label="Workplace"
                          value={this.getWorkplace()}
                          onChange={this.handleChange}
                          items={organisationWorkPlaces || []}
                          name="workPlace.identifier"
                          id="workPlace.identifier"
                          itemName="label"
                          itemValue="value"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.SELECT}
                        key="incidentType"
                        label="Incident Type"
                        value={this.getIncidentType()}
                        onChange={this.handleChange}
                        items={incidentTypes}
                        id="incidentType"
                        name="incidentType"
                        itemName="label"
                        itemValue="value"
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.SELECT}
                        key="relationshipType"
                        label="Relationship Type"
                        value={this.getRelationshipType()}
                        onChange={this.handleChange}
                        items={relationshipTypes}
                        id="relationshipType"
                        itemName="label"
                        itemValue="value"
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.SELECT}
                        key="outcome"
                        label="Outcome"
                        value={this.getOutcomeType()}
                        onChange={this.handleOutcomeChange}
                        items={outcomeTypes}
                        id="outcome"
                        itemName="name"
                        itemValue="identifier"
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        id="description"
                        label="Description"
                        name="description"
                        value={incident.description}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        multiline
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        id="cost"
                        label="Cost"
                        name="cost"
                        value={incident.cost}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.DECIMAL_NUMBERS_ONLY_REGEX]}
                        multiline
                        errorText={errors && errors.fieldErrors.cost}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeIncidentView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  history: PropTypes.object,
  errors: PropTypes.object,
  location: PropTypes.object,
  organisationMemberSearch: PropTypes.object,
  isSaving: PropTypes.bool,
  saveEntity: PropTypes.func,
  getEntity: PropTypes.func,
  listOrganisationWorkPlaces: PropTypes.func,
  organisationWorkPlaces: PropTypes.array,
  incident: PropTypes.object
};

const mapStateToProps = state => ({
  isSaving: loadingSelector(state, 'saveEntity'),
  ...entitySelector(state, 'organisationMemberSearch'),
  ...entityWithErrorsSelector(state, 'incident'),
  ...entityArraySelector(state, 'organisationWorkPlaces')
});

export default compose(withStyles(styles), connect(mapStateToProps, { saveEntity, getEntity }))(ChangeIncidentView);

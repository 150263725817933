import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { entitySelector } from '../../store/selectors/entity';
import { getEntity, putEntity, saveEntity, deleteEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import ShowUser from '../common/ShowUser';
import {
  getUserRequest,
  disableUserRequest,
  enableUserRequest,
  resetUserPasswordRequest,
  resetFiledLoginAttemptRequest,
  deleteUserRequest,
  recreateCognitoUserRequest
} from '../../utils/entityRequests/userAdminRequests';

const styles = {
  ...layout,
  ...style
};

class UserView extends React.Component {
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getUserRequest(id));
  }

  updateUserStatus = () => {
    const { match, user, getEntity, putEntity } = this.props;
    const { params } = match;
    const { id } = params;

    if (user.enabled) {
      putEntity(disableUserRequest(id)).then(() => getEntity(getUserRequest(id)));
    } else {
      putEntity(enableUserRequest(id)).then(() => getEntity(getUserRequest(id)));
    }
  };

  recreateCognitoUser = () => {
    const { match, getEntity, saveEntity } = this.props;
    const { params } = match;
    const { id } = params;

    saveEntity(recreateCognitoUserRequest(id)).then(() => getEntity(getUserRequest(id)));
  };

  resetPassword = () => {
    const { match, putEntity, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    putEntity(resetUserPasswordRequest(id)).then(() => getEntity(getUserRequest(id)));
  };

  resetLoginAttempts = () => {
    const { match, putEntity, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    putEntity(resetFiledLoginAttemptRequest(id)).then(() => getEntity(getUserRequest(id)));
  };

  deleteUser = () => {
    const { match, deleteEntity, history } = this.props;
    const { params } = match;
    const { id } = params;

    deleteEntity(deleteUserRequest(id)).then(() => history.push('/organisation/user'));
  };

  render() {
    const { match, user, isLoading } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(user).length > 0 && (
        <ShowUser
          resetPassword={this.resetPassword}
          resetLoginAttempts={this.resetLoginAttempts}
          editUrl={`/organisation/user/edit/${id}`}
          updateUserLockStatus={this.updateUserLockStatus}
          updateUserStatus={this.updateUserStatus}
          deleteUser={this.deleteUser}
          recreateCognitoUser={this.recreateCognitoUser}
          isLoading={isLoading}
          user={user}
          type="User"
        />
      )
    );
  }
}

UserView.propTypes = {
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  saveEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  user: PropTypes.object,
  isLoading: PropTypes.bool
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'user')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    putEntity,
    saveEntity,
    deleteEntity
  })
)(UserView);

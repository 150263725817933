import { paddings, commonStyles, sizes, colors } from './constants';

export default {
  midColumnContainer: {
    ...paddings.noSidePadding,
    transition: commonStyles.transition
  },
  mainContentColumn: {
    position: 'relative',
    padding: sizes.globalSide,
    overflow: 'auto',
    height: sizes.contentHeight,
    outline: 'none',
    backgroundColor: colors.backgroundBlue
  },
  editMode: {
    position: 'relative',
    zIndex: '5',
    boxShadow: '0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)'
  },
  flexNowrap: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  formContent: {
    background: 'rgba(226, 238, 243, 0.5)',
    borderRadius: '5px',
    marginTop: '41px',
    padding: '30px 27px'
  },
  get sliderBody() {
    return {
      ...this.editMode,
      ...this.mainContentColumn,
      position: 'static'
    };
  }
};

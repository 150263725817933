import moment from 'moment';
import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import {
  centreInitialState,
  courseInitialState,
  roomInitialState,
  scheduledCourseInitialState
} from '../entityInitialStates';
import { convertLocalDateStringToUtcDateString, convertMomentToUtc } from '../utilities';

export const listCourseRequest = () => (dispatch, getState) => {
  if (getState().search.courses) {
    const { filters } = getState().search.courses;
    const statusFilter = filters.status || NO_SEARCH_VALUE;
    const internalFilter = filters.internal || NO_SEARCH_VALUE;
    const locationFilter = filters.location || NO_SEARCH_VALUE;

    return {
      url:
        `${process.env.REACT_APP_COURSE_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/` +
        `${statusFilter}/${internalFilter}/${locationFilter}`,
      entityName: 'courses'
    };
  }
};

export const getCourseRequest = id => ({
  url: `${process.env.REACT_APP_COURSE_GET}${id}`,
  entityName: 'course',
  initialState: courseInitialState
});

export const saveCourseRequest = entity => ({ url: process.env.REACT_APP_COURSE_SAVE, entityName: 'save', entity });

export const deprecateCourseRequest = id => ({
  url: `${process.env.REACT_APP_COURSE_DEPRECATE}${id}`,
  entityName: 'course',
  action: 'deprecated'
});

export const deleteCourseRequest = id => ({
  url: `${process.env.REACT_APP_COURSE_DEPRECATE}${id}`,
  entityName: 'course',
  action: 'deleted'
});

export const publishCourseRequest = id => ({
  url: `${process.env.REACT_APP_COURSE_PUBLISH}${id}`,
  entityName: 'course',
  action: 'published'
});

export const listCentreRequest = () => {
  return {
    url: `${process.env.REACT_APP_CENTRE_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'centres'
  };
};

export const getCentreRequest = id => ({
  url: `${process.env.REACT_APP_CENTRE_GET}${id}`,
  entityName: 'centre',
  initialState: centreInitialState
});

export const saveCentreRequest = entity => ({ url: process.env.REACT_APP_CENTRE_SAVE, entityName: 'centre', entity });

export const listCentreSelectRequest = () => ({
  url: `${process.env.REACT_APP_CENTRE_LIST}`,
  entityName: 'centres',
  initialState: []
});

export const listCourseSelectRequest = () => ({
  url: `${process.env.REACT_APP_COURSE_LIST}`,
  entityName: 'courses',
  initialState: []
});

export const listCentreRoomRequest = id => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_CENTRE_GET}${id}/room/list/${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'rooms'
  };
};

export const getRoomRequest = (id, roomId) => ({
  url: `${process.env.REACT_APP_CENTRE_GET}${id}/room/${roomId}`,
  entityName: 'room',
  initialState: roomInitialState
});

export const saveRoomRequest = (id, entity) => ({
  url: `${process.env.REACT_APP_CENTRE_GET}${id}/room`,
  entityName: 'room',
  entity
});

export const listCentreRoomSelectRequest = id => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_CENTRE_GET}${id}/room/list`,
    entityName: 'rooms'
  };
};

export const listScheduledCourseRequest = () => (dispatch, getState) => {
  if (getState().search.scheduleCourses) {
    const { filters } = getState().search.scheduleCourses;
    const courseFilter = filters.course || NO_SEARCH_VALUE;
    const datesFilter = filters.dates || { startDate: '', endDate: '' };
    const startDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.startDate);
    const endDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.endDate);

    return {
      /* eslint-disable max-len */
      url: `${process.env.REACT_APP_SCHEDULED_COURSE_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${courseFilter}?startDate=${startDate}&endDate=${endDate}`,
      entityName: 'scheduleCourses'
    };
  }
};

export const getScheduledCourseRequest = id => ({
  url: `${process.env.REACT_APP_SCHEDULED_COURSE_GET}${id}`,
  entityName: 'scheduledCourse',
  initialState: scheduledCourseInitialState
});

export const listScheduledCourseSessionRequest = id => (dispatch, getState) => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_SCHEDULED_COURSE_LIST}${id}/scheduledSession/list`,
    entityName: 'scheduleSessions'
  };
};

export const listScheduledCourseHolderRequest = id => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_SCHEDULED_COURSE_GET}${id}/attendee/list/${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'scheduledCourseHolders'
  };
};

export const saveScheduledSessionRequest = (scheduledCourseId, entity) => ({
  url: `${process.env.REACT_APP_SCHEDULED_COURSE_GET}${scheduledCourseId}/scheduledSession`,
  entityName: 'scheduled session',
  entity
});

export const listScheduledSessionRequest = (courseUuid, centreId, roomId, startDate, endDate) => {
  const startDateString = convertMomentToUtc('DD-MM-YYYY HH:mm', moment(startDate));
  const endDateString = convertMomentToUtc('DD-MM-YYYY HH:mm', moment(endDate));

  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_SCHEDULED_SESSION_GET}${courseUuid}/${centreId}/${roomId}?startDate=${startDateString}&endDate=${endDateString}`,
    entityName: 'scheduledSessions'
  };
};

export const deleteScheduledSessionRequest = (scheduledCourseId, id) => ({
  url: `${process.env.REACT_APP_SCHEDULED_COURSE_GET}${scheduledCourseId}/scheduledSession/${id}`,
  entityName: 'scheduled session',
  action: 'deleted'
});

export const listKeywordRequest = () => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_COURSE_GET}keyword/list`,
    entityName: 'keywords'
  };
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import {
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  MuiThemeProvider
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import SimplisticSelect from '../control/SimplisticSelect';
import { style } from '../../assets/style';
import { tableStyles } from '../../assets/table';
import { border, commonStyles, colors, customTheme } from '../../assets/constants';
import { sortOrder } from '../../constants/global';
import { splitToChunksByProp, joinChunks, sortRowsByColumn } from '../../utils/tableSort';

const styles = () => ({
  ...style,
  ...tableStyles,
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },
  loadingText: {
    marginLeft: 10,
    fontSize: 14
  },
  smallCol: {
    width: '90px'
  },
  mediumCol: {
    width: '190px'
  },
  bold: {
    '& > td': {
      fontWeight: 'bold',
      color: 'black'
    }
  },
  fixedHeader: {
    tableLayout: 'fixed'
  },
  tableHideElement: {
    overflow: 'hidden',
    borderStyle: 'hidden',
    height: 0
  },
  shadow: {
    borderTop: border.style_1px,
    boxShadow: 'inset 0px 6px 20px -9px rgba(0,0,0,0.57);'
  },
  scrollingBody: {
    overflowY: 'auto',
    height: 'calc(100vh - 109px)',
    position: 'relative',
    borderTop: '1px transparent',
    transition: commonStyles.transition
  },
  filter: {
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  noResults: {
    verticalAlign: 'middle',
    textAlign: 'center'
  },
  sortColumn: {
    display: 'inline-block',
    userSelect: 'none',
    cursor: 'pointer'
  },
  activeSortColumn: {
    color: colors.fontGreyDark,
    fontWeight: 500,
    '& svg': {
      opacity: 1
    }
  },
  disabledSortColumn: {
    cursor: 'initial'
  },
  sortIcon: {
    position: 'absolute',
    top: '12px',
    opacity: 0.3
  },
  circular: {
    '& > svg': {
      color: colors.orangeDark
    }
  }
});

const isSortingEnabled = false;

class ScrollableTable extends Component {
  isComponentMounted = false;
  /* eslint-disable lines-between-class-members */
  state = { isScrolled: false, sortColumnIndex: 0, orderByType: sortOrder.asc };
  loadMoreRef = React.createRef();
  scrollRef = React.createRef();
  tableRef = React.createRef();
  /* eslint-enable lines-between-class-members */

  async componentDidMount() {
    this.isComponentMounted = true;
    await this.setupIntersectionObservers();
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  setupIntersectionObservers = async () => {
    const scrollRef = this.scrollRef.current;
    const tableRef = this.tableRef.current;
    const loadMoreRef = this.loadMoreRef.current;

    if (!scrollRef || !tableRef || !loadMoreRef) return;

    this.infiniteLoaderObserver = new IntersectionObserver(this.infiniteLoaderIntersectionCb, {
      threshold: 1,
      root: tableRef,
      rootMargin: '0px 0px 100px 0px'
    });
    this.scrollObserver = new IntersectionObserver(this.scrollIntersectionCb, {
      threshold: 1
    });

    await this.props.initialSearch();

    this.infiniteLoaderObserver.observe(loadMoreRef);
    this.scrollObserver.observe(scrollRef);
  };

  scrollIntersectionCb = entries => {
    entries.forEach(entry => {
      if (this.isComponentMounted) this.setState({ isScrolled: entry.intersectionRatio === 0 });
    });
  };

  infiniteLoaderIntersectionCb = entries => {
    const { scroll } = this.props;
    entries.forEach(async entry => {
      if (entry.intersectionRatio === 1 && scroll) {
        const scrollTop = this.tableRef.current.scrollTop;
        await scroll();
        this.tableRef.current.scrollTop = scrollTop;
      }
    });
  };

  getHiddenBreakPoints = breakPoint => {
    const breakPoints = ['xs', 'sm', 'md', 'lg', 'xl'];
    return breakPoints.slice(0, breakPoints.indexOf(breakPoint));
  };

  getStyleOverride = () => {
    const { overrideHeight, overrideStyle } = this.props;

    if (overrideStyle) return overrideStyle;
    return overrideHeight ? { height: overrideHeight } : {};
  };

  toggleOrderByType = () => {
    if (this.state.orderByType === sortOrder.asc) {
      if (this.isComponentMounted) this.setState({ orderByType: sortOrder.desc });
    } else if (this.isComponentMounted) this.setState({ orderByType: sortOrder.asc });
  };

  setSortColumnIndex = (index, isSortingDisabled) => () => {
    const { sortColumnIndex } = this.state;

    if (isSortingDisabled) return;

    if (index === sortColumnIndex) {
      this.toggleOrderByType();
    } else if (this.isComponentMounted) this.setState({ orderByType: sortOrder.desc });
    if (this.isComponentMounted) this.setState({ sortColumnIndex: index });
  };

  isActiveRow = row => row.class && row.class.includes('tableRowActive');

  renderLoader() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <div className={classes.loading}>
          <MuiThemeProvider theme={customTheme}>
            <CircularProgress className={classes.circular} />
          </MuiThemeProvider>
          <div className={classes.loadingText}>Loading...</div>
        </div>
      </Grid>
    );
  }

  renderNoResults() {
    const { classes, headers, emptyMessage } = this.props;

    return (
      <TableRow className={classes.table}>
        <TableCell className={classes.noResults} colSpan={headers.length}>
          {emptyMessage}
        </TableCell>
      </TableRow>
    );
  }

  renderTableCell(cell, row, rowIndex, cellIndex) {
    const { classes } = this.props;

    return (
      <TableCell
        key={`cell${cellIndex}`}
        className={classNames(classes.table, classes.tableCell, cell.className, {
          // Apply to first cell only, when row is not heading, and has depth
          // Тodo: apply this in smarter way
          [classes.hasDepth]: cellIndex === 0 && !row.isHeading && row.rowDepth > 0,
          [classes.hasDepth2]: cellIndex === 0 && !row.isHeading && row.rowDepth === 2
        })}
        colSpan={row.colSpan ? row.colSpan : cell.colSpan || 1}
        onClick={event => {
          if (cell.clickAction) cell.clickAction(event);
        }}
        style={{
          ...cell.style
        }}
      >
        {cell.actionText ? (
          <div className={classes.tableHeaderWithAction}>
            {cell.actionText && (
              <div className={classes.tableHeaderWithAction}>
                <div>
                  <div>{cell.value}</div>
                </div>
                <div className={classes.tableHeaderLinkContainer}>
                  <span onClick={cell.actionFunction}>{cell.actionText}</span>
                </div>
              </div>
            )}
          </div>
        ) : (
          cell.value
        )}
        {!!row.actions && cellIndex === row.cells.length - 1 && (
          <div key={`cellAction${rowIndex}`} className={classes.tableAction}>
            {row.actions.map((action, index) => {
              return (
                <div
                  key={`action-button-${index}`}
                  className={action.isBelowRow ? classes.tableActionItemBelowRow : classes.tableActionItem}
                >
                  {action.action}
                </div>
              );
            })}
          </div>
        )}
      </TableCell>
    );
  }

  renderRows() {
    const { classes, rowConfigs } = this.props;
    const { sortColumnIndex, orderByType } = this.state;

    let rows;

    if (isSortingEnabled) {
      const isNestedTable = rowConfigs.some(row => row.isHeading);
      // Todo: implement a better way of determing if a row is actually active
      const hasActiveRow = rowConfigs.some(this.isActiveRow);
      const shouldSort = sortColumnIndex !== -1;

      // 1. Determine if table is nested
      // 2. if true sort by chunking into pieces and sorting them separately
      // 3. if false sort normally
      // 4. handle sorting when having active row

      if (isNestedTable) {
        const chunked = splitToChunksByProp(rowConfigs, 'isHeading');
        chunked.chunks.forEach(chunk => {
          // Todo: try to exclude active row from sorting??
          if (shouldSort && !hasActiveRow) chunk.sort(sortRowsByColumn(sortColumnIndex, orderByType));
        });
        const joinedRows = joinChunks(chunked);
        rows = joinedRows;
      } else {
        rows = rowConfigs;
        if (shouldSort && !hasActiveRow) rows.sort(sortRowsByColumn(sortColumnIndex, orderByType));
      }
    } else {
      rows = rowConfigs;
    }

    return rows.map((row, rowIndex) => {
      return (
        <TableRow
          className={classNames(
            classes.table,
            classes.tableRow,
            { [classes[row.class]]: row.class },
            { [classes.tableRowFaded]: row.faded },
            { [classes.disabled]: row.disabled },
            { [classes.tableRowHeading]: row.isHeading && row.rowDepth === 1 },
            // TODO: make this flexible with a css generating function for nested levels
            { [classes.tableRowHasDepth2]: row.isHeading && row.rowDepth === 2 }
          )}
          key={`row${rowIndex}`}
          onClick={event => {
            // event.preventDefault();
            // event.stopPropagation();
            if (row.clickAction) row.clickAction();
          }}
          data-qa={row.qaAttribute || 'table-row'}
        >
          {row.cells.map((cell, cellIndex) => {
            return cell.hiddenBelow ? (
              <Hidden only={this.getHiddenBreakPoints(cell.hiddenBelow)} key={`cell-hidden-${cellIndex}`}>
                {this.renderTableCell(cell, rowConfigs[rowIndex], rowIndex, cellIndex)}
              </Hidden>
            ) : (
              this.renderTableCell(cell, rowConfigs[rowIndex], rowIndex, cellIndex)
            );
          })}
        </TableRow>
      );
    });
  }

  renderSortingArrows = (headerIndex, isDisabled) => {
    const { classes } = this.props;
    const { orderByType, sortColumnIndex } = this.state;

    if (isDisabled) return null;

    if (orderByType === sortOrder.asc && sortColumnIndex === headerIndex) {
      return <ArrowDropUp className={classes.sortIcon} />;
    }

    if (orderByType === sortOrder.desc && sortColumnIndex === headerIndex) {
      return <ArrowDropDown className={classes.sortIcon} />;
    }

    return sortColumnIndex > -1 && sortOrder.asc ? (
      <ArrowDropUp className={classes.sortIcon} />
    ) : (
      <ArrowDropDown className={classes.sortIcon} />
    );
  };

  renderTableHeadCell(header, filter, headerIndex) {
    const { classes } = this.props;
    const { sortColumnIndex } = this.state;
    const { hasDisabledSorting } = header;

    return (
      <TableCell
        key={`tableHeader${headerIndex}`}
        className={`${classes[header.class]} ${classes.table} ${classes.tableHeader}`}
      >
        {header.actionText && (
          <div className={classes.tableHeaderWithAction}>
            {header.actionText && (
              <div className={classes.tableHeaderWithAction}>
                <div>
                  <div
                    className={classNames({
                      [classes.sortColumn]: isSortingEnabled,
                      [classes.activeSortColumn]: isSortingEnabled && headerIndex === sortColumnIndex,
                      [classes.disabledSortColumn]: isSortingEnabled && hasDisabledSorting
                    })}
                    onClick={this.setSortColumnIndex(headerIndex, hasDisabledSorting)}
                  >
                    {header.value}
                    {isSortingEnabled && this.renderSortingArrows(headerIndex, hasDisabledSorting)}
                  </div>
                </div>
                <div className={classes.tableHeaderLinkContainer}>
                  <span onClick={header.actionFunction}>{header.actionText}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {filter && (
          <SimplisticSelect
            value={filter.value}
            name={filter.name ? filter.name : null}
            onChange={filter.onChange}
            options={filter.options}
            className={classes.filter}
          />
        )}
        {!header.actionText && (
          <div
            className={classNames({
              [classes.sortColumn]: isSortingEnabled,
              [classes.activeSortColumn]: isSortingEnabled && headerIndex === sortColumnIndex,
              [classes.disabledSortColumn]: isSortingEnabled && hasDisabledSorting
            })}
            onClick={this.setSortColumnIndex(headerIndex, hasDisabledSorting)}
          >
            {header.value}
            {isSortingEnabled && this.renderSortingArrows(headerIndex, hasDisabledSorting)}
          </div>
        )}
      </TableCell>
    );
  }

  renderTableHead() {
    const { classes, headers } = this.props;

    if (!headers.length) {
      /* Empty <tr></tr> to track the start of the list */
      return (
        <TableHead>
          <TableRow ref={this.scrollRef} />
        </TableHead>
      );
    }

    return (
      <TableHead>
        <TableRow className={classes.table}>
          {headers.map((header, i) => {
            const { filter } = header;

            return header.hiddenBelow ? (
              <Hidden only={this.getHiddenBreakPoints(header.hiddenBelow)} key={`header-hidden-${i}`}>
                {this.renderTableHeadCell(header, filter, i)}
              </Hidden>
            ) : (
              this.renderTableHeadCell(header, filter, i)
            );
          })}
        </TableRow>
        {/* Empty <tr></tr> to track the start of the list */}
        <TableRow ref={this.scrollRef} />
      </TableHead>
    );
  }

  renderTableBodyContent() {
    const { rowConfigs } = this.props;

    // if (!complete) return this.renderLoader();
    if (!rowConfigs || rowConfigs.length === 0) return this.renderNoResults();

    return this.renderRows();
  }

  render() {
    const { classes, complete } = this.props;
    const { isScrolled } = this.state;

    return (
      <div
        ref={this.tableRef}
        className={classNames(classes.scrollingBody, { [classes.shadow]: isScrolled })}
        style={this.getStyleOverride()}
      >
        {/* {complete && ( */}
        <Table className={classes.fixedHeader}>
          {this.renderTableHead()}
          <TableBody className={classes.tableBody}>
            <>
              {this.renderTableBodyContent()}
              {/* Empty <tr></tr> to track the end of the list */}
              <tr ref={this.loadMoreRef} />
            </>
          </TableBody>
        </Table>
        {/* )} */}
        {!complete && this.renderLoader()}
      </div>
    );
  }
}

ScrollableTable.defaultProps = {
  initialSearch: () => {},
  scroll: () => {}
};

ScrollableTable.propTypes = {
  classes: PropTypes.object.isRequired,
  complete: PropTypes.bool.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  headers: PropTypes.array.isRequired,
  rowConfigs: PropTypes.array,
  scroll: PropTypes.func,
  initialSearch: PropTypes.func,
  overrideHeight: PropTypes.string,
  overrideStyle: PropTypes.object
};

export default compose(withRouter, withStyles(styles))(ScrollableTable);

/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { colors, commonFont } from '../../assets/constants';

const style = {
  displayField: {
    display: 'flex',
    flexDirection: 'column',
    ...commonFont,
    fontSize: '12px',
    marginBottom: '20px'
  },
  heading: {
    color: colors.blue
  },
  value: {
    color: colors.black,
    fontWeight: 600,
    marginTop: '4px'
  }
};

const DisplayField = ({ classes, heading, value, dangerousHtml, values }) => (
  <div className={classes.displayField}>
    <div className={classes.heading}>{heading}</div>
    {!dangerousHtml && value && <div className={classes.value}>{value}</div>}
    {dangerousHtml && (
      <div className={classes.value} dangerouslySetInnerHTML={{ __html: value.replace('\n', '<br/>') }} />
    )}
    {values && values.map(item => <div className={classes.value}>{item}</div>)}
  </div>
);

DisplayField.defaultProps = {
  dangerousHtml: false
};

DisplayField.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  dangerousHtml: PropTypes.bool,
  values: PropTypes.array
};

export default withStyles(style)(DisplayField);

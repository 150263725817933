import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tooltip, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { loadingSelector } from '../../../store/selectors/shared';
import { getEntity, saveEntity, clearValidationError } from '../../../store/actions/EntityActions';
import { style } from '../../../assets/style';
import {
  enrolScheduledCourseRequest,
  getCatalogCourseScheduledCourseRequest
} from '../../../utils/entityRequests/catalogRequests';
import { entitySelector, entityWithErrorsSelector } from '../../../store/selectors/entity';
import { VALIDATORS_BY_NAME } from '../../../assets/constants';
import SectionHeading from '../../common/SectionHeading';
import { convertUtcToLocal } from '../../../utils/utilities';
import ValidatedField from '../../widgets/ValidatedField';
import { searchOrganisationMemberRequest } from '../../../utils/entityRequests/organisationMemberRequests';
import { VALIDATED_FIELD_TYPES } from '../../../constants/global';
import NewHeaderEditActions from '../../header/NewHeaderEditActions';
import { dispatchGlobalError } from '../../../store/actions/GlobalErrorActions';

const styles = {
  ...style
};

class EnrolCatalogCourseView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searched: false
    };
    const { match } = this.props;
    const { params } = match;
    const { id } = params;
    this.cancelUrl = `/catalog/courseCatalog/view/${id}`;
  }

  static getDerivedStateFromProps(props, prevProps) {
    const { errors: previousErrors } = prevProps;
    const { errors, dispatchGlobalError, clearValidationError } = props;
    if (!previousErrors && errors) {
      dispatchGlobalError('Course Enrollment', errors.objectErrors[0]);
      clearValidationError();
    }
    return props;
  }

  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { scheduleCourseId } = params;
    await getEntity(getCatalogCourseScheduledCourseRequest(scheduleCourseId));
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  handleSave = async () => {
    const { saveEntity, history, organisationMemberSearch, match } = this.props;
    const { params } = match;
    const { scheduleCourseId } = params;
    const id = await saveEntity(enrolScheduledCourseRequest(scheduleCourseId, organisationMemberSearch.uuid));
    if (!id) return;
    history.push(this.cancelUrl);
  };

  searchHolder = async () => {
    const { getEntity } = this.props;
    const { searchText } = this.state;
    await getEntity(searchOrganisationMemberRequest(searchText));

    this.setState({
      searched: true
    });
  };

  render() {
    const { classes, organisationMemberSearch, scheduledCourse, isSaving } = this.props;
    const { searchText, searched } = this.state;

    return (
      <Grid container className={classNames(classes.mainContentColumn)}>
        <Grid
          container
          className={`${classes.viewPage} ${classes.page}`}
          alignContent="flex-start"
          justify="flex-start"
        >
          <NewHeaderEditActions
            cancelUrl={this.cancelUrl}
            isSaving={isSaving}
            onSubmit={this.handleSave}
            name="changeFormHeaderAction"
            isDisabled={!(searched && organisationMemberSearch.uuid)}
            header="Course Enrolment"
          />

          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={12}>
                <SectionHeading heading="Course Enrolment" underlined />
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.displayLabelAndField)}>
                  <label className={classes.displayLabel}>Course</label>
                  <div className={classes.displayField}>{scheduledCourse.courseTitle}</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.displayLabelAndField)}>
                  <label className={classes.displayLabel}>Start Time</label>
                  <div className={classes.displayField}>
                    {convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledCourse.startTime)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.displayLabelAndField)}>
                  <label className={classes.displayLabel}>End Time</label>
                  <div className={classes.displayField}>
                    {convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledCourse.endTime)}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.fieldContainer, classes.headingAndAction)}>
                  <ValidatedField
                    id="searchText"
                    label="Search Email / Phone of holder to enrol"
                    name="searchText"
                    value={searchText}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    errorText={searched && !organisationMemberSearch.uuid ? 'The member was unable to be found' : ''}
                  />
                  <Tooltip title="Search" aria-label="add" placement="bottom">
                    <IconButton aria-owns="menu-appbar" onClick={this.searchHolder} color="inherit">
                      <i className={`material-icons ${classes.icon}`}>search</i>
                    </IconButton>
                  </Tooltip>
                </div>
              </Grid>
              {searched && organisationMemberSearch.uuid && (
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      id="name"
                      label="Holder Name"
                      name="name"
                      value={organisationMemberSearch.name}
                      validators={[]}
                      disabled
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      id="email"
                      label="Holder Email"
                      name="email"
                      value={organisationMemberSearch.email}
                      validators={[]}
                      disabled
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      type={VALIDATED_FIELD_TYPES.PHONE}
                      id="phone"
                      label="Holder Phone"
                      name="phone"
                      value={organisationMemberSearch.phone}
                      validators={[]}
                      disabled
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

EnrolCatalogCourseView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  clearValidationError: PropTypes.func.isRequired,
  dispatchGlobalError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  organisationMemberSearch: PropTypes.object,
  history: PropTypes.object,
  scheduledCourse: PropTypes.object,
  search: PropTypes.object,
  isSaving: PropTypes.bool,
  match: PropTypes.object,
  params: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'scheduledCourse'),
  ...entitySelector(state, 'scheduledCourse'),
  ...entitySelector(state, 'organisationMemberSearch'),
  ...entityWithErrorsSelector(state, 'enrolled'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    saveEntity,
    dispatchGlobalError,
    clearValidationError
  })
)(EnrolCatalogCourseView);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { CircularProgress, MuiThemeProvider, Tooltip } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import ls from 'local-storage';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { colors, commonFont, customTheme, textStyles } from '../../assets/constants';
import ExpandIcon from '../../assets/images/expand.svg';
import DownArrowIcon from '../../assets/images/down-arrow.svg';
import HelpIcon from '../../assets/images/help.svg';
import SettingsIcon from '../../assets/images/settings.svg';
import NotificationIcon from '../../assets/images/notification.svg';
import GoToPremiumIcon from '../../assets/images/go-to-premium.svg';
import { toggleSetting } from '../../store/actions/SettingsActions';
import { getEntity, putEntityWithoutValidation } from '../../store/actions/EntityActions';
import { settingSelector } from '../../store/selectors/shared';
import { style } from '../../assets/style';

import { entityArraySelector } from '../../store/selectors/entity';
import { listOrganisationRequest } from '../../utils/entityRequests/organisationRequests';
import { hasPermission } from '../../utils/utilities';
import { permissions } from '../../constants/global';
import { changeOrganisationRequest } from '../../utils/entityRequests/holderRequests';
import { refreshOauthToken } from '../../store/actions/AuthenticationActions';
import NewUserProfile from '../widgets/NewUserProfile';

const styles = () => ({
  ...style,
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '0 0 0 16px',
    height: '89px'
  },
  menuAndHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    '& i': {
      color: colors.blue,
      cursor: 'pointer'
    },
    borderBottom: `solid 1px ${colors.borderBlue}`
  },
  expandIcon: {
    paddingLeft: '38px',
    cursor: 'pointer'
  },
  helpIcon: {
    marginRight: '9px'
  },
  settingsIcon: {
    marginRight: '28px',
    cursor: 'pointer'
  },
  goToPremiumIcon: {
    marginRight: '34px',
    cursor: 'pointer',
    borderRadius: '5px',
    border: `1px solid ${colors.borderBlue}`
  },
  organisationSelectionText: {
    ...textStyles.organisationSelectionText,
    display: 'flex',
    alignItems: 'center'
  },
  helpText: {
    ...commonFont,
    fontWeight: 600,
    fontSize: '14px',
    color: colors.fontLightGrey,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  dropDownMenu: {
    marginTop: '20px'
  },
  spaceSpinner: {
    marginLeft: '10px'
  },
  lineSpacer: {
    border: `solid 1px ${colors.spacerBlue}`,
    height: '41px',
    marginLeft: '24px'
  },
  rightSideHeader: {
    marginRight: '18px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  rightSideSpacer: {
    marginRight: '24px',
    marginLeft: '27px'
  },
  notification: {
    marginRight: '31px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
  },
  notificationNumber: {
    backgroundColor: colors.orangeNew,
    borderRadius: '10px',
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '11px',
    color: colors.white,
    width: '25px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '18px',
    top: '-7px'
  }
});

class NewHeader extends React.Component {
  state = {
    menuClosed: true,
    anchorEl: null,
    organisations: []
  };

  static getDerivedStateFromProps(props, prevProps) {
    const { leftSideMenuPermanentlyOpen: previousLeftSideMenuPermanentlyOpen } = prevProps;
    const { leftSideMenuPermanentlyOpen } = props;
    if (previousLeftSideMenuPermanentlyOpen !== leftSideMenuPermanentlyOpen) {
      return { menuClosed: leftSideMenuPermanentlyOpen };
    }
    return props;
  }

  fetchOrganisations = async () => {
    const { getEntity } = this.props;
    const response = await getEntity(listOrganisationRequest());

    this.setState({ organisations: response });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleMenuOpen = event => {
    this.fetchOrganisations();
    this.setState({ anchorEl: event.currentTarget });
  };

  changeOrganisation = async key => {
    const { putEntityWithoutValidation } = this.props;
    this.setState({ changingOrganisation: true });
    const response = await putEntityWithoutValidation(changeOrganisationRequest(key));

    if (response.status === 200) {
      refreshOauthToken().then(() => {
        ls.set('key', response.data.key);
        ls.set('assuranceCapability', response.data.assurance);
        ls.set('competenceCapability', response.data.competence);
        ls.set('trainingCapability', response.data.training);
        ls.set('organisationName', response.data.name);
        this.setState({ changingOrganisation: false });
        window.location.reload();
      });
    }
    this.handleClose();
  };

  render() {
    const { classes, toggleSetting } = this.props;
    const { menuClosed, anchorEl, changingOrganisation, organisations } = this.state;
    return (
      <div className={classes.menuAndHeading}>
        <Tooltip
          title={!menuClosed ? 'Open Menu' : 'Close Menu'}
          aria-label="write"
          placement="bottom"
          onClick={() => {
            toggleSetting('leftSideMenuPermanentlyOpen', !menuClosed);
          }}
        >
          <img src={ExpandIcon} alt="Expand Icon" className={classes.expandIcon} />
        </Tooltip>

        <div className={classes.headerContainer}>
          <div className={classes.organisationSelectionText}>
            {ls.get('organisationName')}
            {changingOrganisation && <CircularProgress size={15} color="inherit" className={classes.spaceSpinner} />}
            {hasPermission([permissions.CHANGE_ORGANISATION]) && (
              <Fragment>
                <Tooltip title="Change Organisation" onClick={this.handleMenuOpen}>
                  <img src={DownArrowIcon} alt="Change Organisation Icon" className={classes.expandIcon} />
                </Tooltip>
                <div className={classes.lineSpacer} />
                <Menu
                  id="changeOrganisationMenu"
                  anchorEl={anchorEl}
                  anchorReference="anchorEl"
                  open={Boolean(anchorEl)}
                  getContentAnchorEl={null}
                  onClose={this.handleClose}
                  className={classes.dropDownMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  {(organisations || []).map(organisation => {
                    return (
                      <MenuItem
                        onClick={() => {
                          this.changeOrganisation(organisation.key);
                        }}
                        key={`menuItem${organisation.key}`}
                      >
                        {organisation.name}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Fragment>
            )}
          </div>
          <div className={classes.rightSideHeader}>
            <Tooltip
              title="Go to premium"
              onClick={() => {
                document.location.href = 'http://www.repple.com/plans';
              }}
            >
              <img src={GoToPremiumIcon} alt="Go to premium" className={classes.goToPremiumIcon} />
            </Tooltip>
            <div className={classes.notification}>
              <img src={NotificationIcon} alt="Notification" className={classes.notificationIcon} />
              <div className={classes.notificationNumber}>5</div>
            </div>
            <img src={SettingsIcon} alt="Settings" className={classes.settingsIcon} />
            <div
              className={classes.helpText}
              onClick={() => {
                document.location.href = 'http://www.repple.com/faq';
              }}
            >
              <img src={HelpIcon} alt="Help" className={classes.helpIcon} />
              Help
            </div>
            <div className={classNames(classes.lineSpacer, classes.rightSideSpacer)} />
            <NewUserProfile />
          </div>
        </div>
      </div>
    );
  }
}

NewHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleSetting: PropTypes.func.isRequired,
  organisations: PropTypes.array,
  getEntity: PropTypes.func.isRequired,
  putEntityWithoutValidation: PropTypes.func.isRequired,
  leftSideMenuPermanentlyOpen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  leftSideMenuPermanentlyOpen: settingSelector(state, 'leftSideMenuPermanentlyOpen'),
  ...entityArraySelector(state, 'organisations')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, putEntityWithoutValidation, toggleSetting })
)(NewHeader);

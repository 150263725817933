import { VALIDATORS_BY_NAME } from '../assets/constants';

// Validation regexes
export const GLOBAL_STRING_REGEX = new RegExp(/^[a-z0-9]{0,10}$/);
export const NUMBERS_ONLY_REGEX = new RegExp(/^\d+(\.\d+)?$/);
export const NATURAL_NUMBERS_ONLY_REGEX = new RegExp(/^[1-9][0-9]*$/);
export const DECIMAL_NUMBERS_ONLY_REGEX = new RegExp(/^\d+(\.\d*)?$/);
export const LOWERCASE_ONLY_REGEX = new RegExp('[a-z]');

// Validation for required fields.
export const requiredFieldMessage = label => `${label} is a required field.`;

// We would have all types of validations which would be functions that return an error or null
export default {
  [VALIDATORS_BY_NAME.REQUIRED]: (text, label) => {
    return (Array.isArray(text) && text.length === 0) || !text ? requiredFieldMessage(label) : '';
  },
  [VALIDATORS_BY_NAME.LOWERCASE]: text => {
    let error = '';

    const isValid = LOWERCASE_ONLY_REGEX.test(text);

    if (!isValid) {
      error = 'Only lower case letters are allowed.';
    }

    return error;
  },
  [VALIDATORS_BY_NAME.STRING]: text => {
    let error = '';

    const isValid = GLOBAL_STRING_REGEX.test(text);

    if (!isValid) {
      // Just for testing
      // TODO: Add a rule for all plain strings
      error = 'A maximum of 10 characters is allowed';
    }

    return error;
  },
  [VALIDATORS_BY_NAME.NUMBER]: text => {
    let error = '';

    const isValid = NUMBERS_ONLY_REGEX.test(text);

    if (!isValid && text !== '') {
      error = 'Only numbers are allowed.';
    }

    return error;
  },
  [VALIDATORS_BY_NAME.NATURAL_NUMBER]: text => {
    let error = '';

    const isValid = NATURAL_NUMBERS_ONLY_REGEX.test(text);

    if (!isValid && text !== '') {
      error = 'Only natural numbers are allowed.';
    }

    return error;
  },
  [VALIDATORS_BY_NAME.DECIMAL_NUMBERS_ONLY_REGEX]: text => {
    let error = '';

    const isValid = DECIMAL_NUMBERS_ONLY_REGEX.test(text);

    if (!isValid && text !== '') {
      error = 'Only decimal numbers are allowed.';
    }

    return error;
  }
};

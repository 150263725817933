import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { sizes, colors, globalSpacing, globalSpacingPixels, border } from '../../assets/constants';
import { flexRowCentered } from '../../assets/mixins';

const style = {
  headingContainer: {
    ...flexRowCentered,
    position: 'relative',
    paddingTop: globalSpacingPixels,
    paddingBottom: `${globalSpacing / 4}px`
  },
  heading: {
    fontSize: sizes.fontBig,
    fontWeight: 300,
    color: colors.grey4,
    textTransform: 'uppercase',
    padding: sizes.globalHalfSide,
    lineHeight: 0.89
  },
  actions: {
    height: '48px',
    ...flexRowCentered,
    position: 'absolute',
    alignItems: 'center',
    right: 0,
    bottom: 0,
    '& button': { padding: `${globalSpacing / 4}px` }
  },
  underlined: {
    borderBottom: border.style_1px
  }
};

const SectionHeading = ({ classes, className, heading, children, underlined, ...rest }) => (
  <div
    className={classNames(classes.headingContainer, className, {
      [classes.underlined]: underlined
    })}
    {...rest}
  >
    <div className={classes.heading}>{heading}</div>
    <div className={classes.actions}>{children}</div>
  </div>
);

SectionHeading.defaultProps = {
  underlined: false,
  className: null
};

SectionHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  children: PropTypes.any,
  underlined: PropTypes.bool,
  className: PropTypes.string
};

export default withStyles(style)(SectionHeading);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Button, CircularProgress, TextField } from '@material-ui/core';
import moment from 'moment';
import { style } from '../../assets/style';
import { loadingSelector } from '../../store/selectors/shared';
import { tableSelector } from '../../store/selectors/table';
import { colors, commonFont } from '../../assets/constants';
import { convertUtcToLocal } from '../../utils/utilities';

const styles = () => ({
  ...style,
  messagesContainer: {
    height: 'calc(100vh - 560px)',
    display: 'flex',
    borderRadius: '8px 8px 0px 0px',
    flexDirection: 'column',
    paddingTop: '20px',
    overflowY: 'scroll'
  },
  sendContainer: {
    borderTop: `solid 1px ${colors.grey2}`,
    // boxShadow: '0 -1px 2px 0 rgba(0,0,0,.12)',
    // borderRadius: '0px 0px 8px 8px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center'
    // maxHeight: '60px'
  },
  message: {
    display: 'flex',
    borderRadius: '8px',
    padding: '9px 15px',
    fontSize: '12px',
    width: '60%'
  },
  organisationMessageContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    '& $messageDate': {
      paddingRight: '15px'
    },
    '& $message': {
      backgroundColor: '#F78B0E',
      marginBottom: '5px',
      color: `${colors.white}`
    },
    margin: '10px 30px 0px 30px'
  },
  userMessageContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    '& $messageDate': {
      paddingLeft: '15px'
    },
    '& $message': {
      backgroundColor: 'rgba(236, 236, 236)',
      marginBottom: '5px'
    },
    margin: '10px 30px 0px 30px'
  },
  messageDate: {
    fontSize: '10px',
    color: '#9b9b9b'
  },
  noUnderline: {
    '&:before, &:after': {
      borderBottom: 'none !important'
    }
  },
  sendMessageTextFieldContainer: {
    // background: 'rgba(246, 246, 246) !important',
    color: `${colors.fontLightGrey} !important`,
    height: '100%',
    fontSize: '16px',
    fontWeight: 500,
    ...commonFont,
    // borderRadius: '8px',
    paddingLeft: '15px'
  },
  spaceBottom: {
    marginBottom: '8px'
  }
});

class MessagingComponent extends React.Component {
  scrollRef = React.createRef();

  inputRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      message: ''
    };
  }

  async componentDidMount() {
    const scrollRef = this.scrollRef.current;
    scrollRef.scrollIntoView({ behavior: 'smooth' });
    this.inputRef.current.select();
    this.inputRef.current.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    this.inputRef.current.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    if (e.key === 'Enter') this.handleSendMessage();
  };

  handleSendMessage = () => {
    const { sendMessage } = this.props;
    const { message } = this.state;

    if (message) {
      sendMessage(message).then(async () => {
        this.setState({
          message: ''
        });
        const scrollRef = this.scrollRef.current;
        scrollRef.scrollIntoView({ behavior: 'smooth' });
      });
    }
  };

  handleChange = event => {
    const { value } = event.target;
    this.setState({
      message: value
    });
  };

  getMessageTime = time => {
    const dayDate = convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY', time);
    const today = convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY', moment().utc());

    if (dayDate !== today) {
      return convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY hh:mm a', time);
    }

    return `Today ${convertUtcToLocal('DD-MM-YYYY HH:mm', 'hh:mm a', time)}`;
  };

  render() {
    const { classes, messages, isSending } = this.props;
    const { message } = this.state;

    return (
      <div>
        <div>
          <div className={classes.messagesContainer}>
            {messages.map((message, index) => (
              <div
                className={classNames({
                  [classes.userMessageContainer]: !message.organisationUser,
                  [classes.organisationMessageContainer]: message.organisationUser
                })}
                key={`message-${message.identifier}`}
              >
                <div className={classes.message}>{message.message}</div>
                <div className={classes.messageDate}>
                  {message.organisationUser ? `${message.user.name} ` : ''}
                  {this.getMessageTime(message.createTime)}
                </div>
              </div>
            ))}
            <div className={classes.spaceBottom} ref={this.scrollRef} />
          </div>
          <div className={classes.sendContainer}>
            <TextField
              onChange={this.handleChange}
              name="message"
              id="message"
              InputProps={{ className: classNames(classes.sendMessageTextFieldContainer, classes.noUnderline) }}
              className={classes.textField}
              placeholder="Write a message..."
              value={message}
              inputRef={this.inputRef}
            />
            <Button
              data-qa="ok"
              className={classes.submit}
              variant="contained"
              color="primary"
              onClick={this.handleSendMessage}
              disabled={isSending}
            >
              {isSending ? <CircularProgress size={15} color="inherit" /> : 'Send'}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

MessagingComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  sendMessage: PropTypes.func.isRequired,
  messages: PropTypes.array,
  isSending: PropTypes.bool
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-holders'),
  ...tableSelector(state, 'holders')
});

export default withStyles(styles)(connect(mapStateToProps, {})(MessagingComponent));

import React, { Component } from 'react';
import {
  XYPlot,
  YAxis,
  XAxis,
  HorizontalGridLines,
  VerticalGridLines,
  DiscreteColorLegend,
  LineMarkSeries,
  Hint,
  AreaSeries
} from 'react-vis';
import { EXTENDED_DISCRETE_COLOR_RANGE } from 'react-vis/es/theme';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  graphLabel: {
    color: 'rgba(60, 72, 88)'
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'rgba(60, 72, 88)'
  }
};

class LineChart extends Component {
  state = {
    hint: false
  };

  handleValueMouseOver = event => {
    this.setState({ hint: event });
  };

  handleFixColors = graphData => {
    if (!graphData.colorsApplied) {
      graphData.legendItems.forEach((item, index) => {
        item.color = EXTENDED_DISCRETE_COLOR_RANGE[index];
      });
    }

    return graphData.legendItems;
  };

  render() {
    const { classes, graphData, width, height, legendOrientation, legendStyle } = this.props;
    const { hint } = this.state;
    return graphData ? (
      <div className={classes.graphContainer}>
        <XYPlot width={width} height={height} xType="ordinal" onMouseLeave={() => this.setState({ hint: false })}>
          <DiscreteColorLegend
            style={legendStyle}
            orientation={legendOrientation}
            items={this.handleFixColors(graphData)}
          />
          <HorizontalGridLines />
          <VerticalGridLines />
          <YAxis title={graphData.yaxisName} />
          <XAxis title={graphData.xaxisName} />

          {graphData.graphSeries.map((series, index) => {
            return (
              <LineMarkSeries
                title={series.name}
                data={series.points}
                getNull={d => d.y !== null}
                color={series.color || EXTENDED_DISCRETE_COLOR_RANGE[index]}
                size="3"
                onValueMouseOver={this.handleValueMouseOver}
                key={`mark-series-${series.name}`}
              />
            );
          })}
          {graphData.graphSeries.map((series, index) => {
            return (
              <AreaSeries
                title={series.name}
                data={series.points}
                getNull={d => d.y !== null}
                color={series.color || EXTENDED_DISCRETE_COLOR_RANGE[index]}
                size="3"
                onValueMouseOver={this.handleValueMouseOver}
                opacity={0.5}
                key={`area-series-${series.name}`}
              />
            );
          })}
          {hint ? (
            <Hint
              value={hint}
              format={() => {
                return [{ value: hint.y, title: 'Val' }];
              }}
            />
          ) : null}
        </XYPlot>
      </div>
    ) : null;
  }
}

LineChart.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  graphData: PropTypes.object,
  onClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  legendStyle: PropTypes.object.isRequired,
  legendOrientation: PropTypes.string.isRequired
};

export default withStyles(styles)(LineChart);

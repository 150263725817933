import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { entitySelector } from '../../store/selectors/entity';
import { getEntity, createInitialEntity, putEntity } from '../../store/actions/EntityActions';
import {
  getFormRequest,
  deleteFormRequest,
  deprecateFormRequest,
  publishFormRequest
} from '../../utils/entityRequests/formRequests';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayForm from './DisplayForm';
import { FORM_STATUS_TYPE } from '../../assets/constants';
import { loadingSelector } from '../../store/selectors/shared';

const styles = {
  ...layout,
  ...style
};

class FormView extends React.Component {
  constructor(props) {
    super(props);
    const { createInitialEntity } = props;
    createInitialEntity(getFormRequest(''));
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getFormRequest(id));
  }

  publishForm = () => {
    const { match, getEntity, putEntity } = this.props;
    const { params } = match;
    const { id } = params;

    putEntity(publishFormRequest(id)).then(() => getEntity(getFormRequest(id)));
  };

  deprecateForm = () => {
    const { match, getEntity, form, history, putEntity } = this.props;
    const { params } = match;
    const { id } = params;

    if (form.formStatusType === FORM_STATUS_TYPE.DRAFT.value) {
      putEntity(deleteFormRequest(id)).then(() => history.push('/credential/credentialDefinition/form/list'));
    } else {
      putEntity(deprecateFormRequest(id)).then(() => getEntity(getFormRequest(id)));
    }
  };

  render() {
    const { classes, match, form } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(form).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, classes.flexNowrap, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={
              form.formStatusType !== FORM_STATUS_TYPE.DEPRECATED.value
                ? `/credential/credentialDefinition/form/edit/${id}`
                : ''
            }
            cancelUrl="/credential/credentialDefinition/form"
            header="Form Details"
          >
            {(form.formStatusType === FORM_STATUS_TYPE.DRAFT.value ||
              form.formStatusType === FORM_STATUS_TYPE.DEPRECATED.value) && (
              <Tooltip title="Publish Form" aria-label="edit" placement="bottom">
                <IconButton onClick={this.publishForm}>
                  <i className="material-icons">refresh</i>
                </IconButton>
              </Tooltip>
            )}
            {(form.formStatusType === FORM_STATUS_TYPE.PUBLISHED.value ||
              form.formStatusType === FORM_STATUS_TYPE.DRAFT.value) && (
              <Tooltip
                title={form.formStatusType === FORM_STATUS_TYPE.DRAFT.value ? 'Delete Form' : 'Deprecate Form'}
                aria-label="edit"
                placement="bottom"
              >
                <IconButton onClick={this.deprecateForm}>
                  <i className="material-icons">delete</i>
                </IconButton>
              </Tooltip>
            )}
          </NewHeaderViewActions>
          <Grid item xs={6}>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Name</label>
              <div className={classes.displayField}>{form.name}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Status</label>
              <div className={classes.displayField}>
                {form.formStatusType && FORM_STATUS_TYPE[form.formStatusType].label}
              </div>
            </div>
            <DisplayForm form={form} key="displayForm" />
          </Grid>
        </Grid>
      )
    );
  }
}

FormView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  createInitialEntity: PropTypes.func,
  putEntity: PropTypes.func,
  isSaving: PropTypes.bool,
  form: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'form'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    createInitialEntity,
    putEntity
  })
)(FormView);

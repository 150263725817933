import { reasonInitialState } from '../entityInitialStates';

export const listReasonRequest = () => {
  return {
    url: process.env.REACT_APP_REASON_LIST,
    entityName: 'reasons'
  };
};

export const getReasonRequest = id => ({
  url: `${process.env.REACT_APP_REASON_GET}${id}`,
  entityName: 'reason',
  initialState: reasonInitialState
});

export const saveReasonRequest = entity => ({ url: process.env.REACT_APP_REASON_SAVE, entityName: 'reason', entity });

export const RESTART_LOGIN = 'RESTART_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_INITIALISED = 'LOGIN_INITIALISED';
export const LOGIN_REINITIALISE = 'LOGIN_REINITIALISE';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SENT = 'FORGOT_PASSWORD_SENT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_VALIDATION_FAILURE = 'FORGOT_PASSWORD_VALIDATION_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_VALIDATION_FAILURE = 'RESET_PASSWORD_VALIDATION_FAILURE';

export const GLOBAL_ERROR = 'GLOBAL_ERROR';
export const CLEAR_GLOBAL_ERROR = 'CLEAR_GLOBAL_ERROR';

export const MIDDLEWARE_VALIDATE = 'MIDDLEWARE_VALIDATE';
export const CREATE_FIELD = 'CREATE_FIELD';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const VALIDATE_FIELD = 'VALIDATE_FIELD';

export const TOGGLE_SETTING = 'TOGGLE_SETTING';

export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const NOTIFICATION = 'NOTIFICATION';
export const UPDATE_SERVICE_WORKER = 'UPDATE_SERVICE_WORKER';

export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';

export const SEARCH = 'SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const CHANGE_SEARCH_FILTER = 'CHANGE_SEARCH_FILTER';
export const SET_INITIAL_SEARCH_FILTER = 'SET_INITIAL_SEARCH_FILTER';
export const CREATE_INITIAL_SEARCH = 'CREATE_INITIAL_SEARCH';

export const UPDATE_FILE_PROGRESS = 'UPDATE_FILE_PROGRESS';
export const LIST_FILE_UPLOADS = 'LIST_FILE_UPLOADS';
export const CLEAR_FILE_UPLOADS = 'CLEAR_FILE_UPLOADS';
export const LIST_FILE_DOWNLOADS = 'LIST_FILE_DOWNLOADS';
export const LIST_FILE_CREDENTIAL_REQUEST_DOWNLOADS = 'LIST_FILE_CREDENTIAL_REQUEST_DOWNLOADS';
export const FILE_UPLOAD_DELETED = 'FILE_UPLOAD_DELETED';

export const LIST_TABLE = 'LIST_TABLE';
export const CREATE_INITIAL_TABLE = 'CREATE_INITIAL_TABLE';

export const GET_ENTITY = 'GET_ENTITY';
export const SAVE_ENTITY_SUCCESS = 'SAVE_ENTITY_SUCCESS';
export const SAVE_ENTITY_VALIDATION_FAILURE = 'SAVE_ENTITY_VALIDATION_FAILURE';
export const CLEAR_VALIDATION_FAILURE = 'CLEAR_VALIDATION_FAILURE';
export const CREATE_INITIAL_ENTITY = 'CREATE_INITIAL_ENTITY';
export const CLEAR_ENTITY = 'CLEAR_ENTITY';

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { listTeamRequest } from '../../utils/entityRequests/teamRequests';
import { entityArraySelector } from '../../store/selectors/entity';

const styles = {
  ...style
};

class TeamListView extends React.Component {
  componentDidMount() {
    const { getEntity } = this.props;
    getEntity(listTeamRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/organisation/team/view/${id}`);
  };

  getRowConfigs = () => {
    const { teams } = this.props;
    const items = teams || [];
    return items.map(team => ({
      clickAction: () => this.actionNavigate(team.identifier),
      cells: [
        {
          value: team.name
        }
      ]
    }));
  };

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <ScrollableTable
              headers={[{ value: 'Name' }]}
              rowConfigs={this.getRowConfigs()}
              complete={!isLoading}
              emptyMessage="No Teams Found"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

TeamListView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  teams: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  ...entityArraySelector(state, 'teams')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(TeamListView);

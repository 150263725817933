/* eslint-disable no-unused-vars */
import ls from 'local-storage';
/* eslint-disable-next-line */
import { refreshOauthToken } from '../../store/actions/AuthenticationActions';

class AjaxUtil {
  getUrl(url) {
    return `${window.reppleEnv.REACT_APP_API_URL}${url}`;
  }

  getWithoutRefresh(url) {
    return fetch(url, {
      headers: this.getHeaders()
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  getClientHeader(url) {
    return fetch(url, {
      headers: this.getHeaders()
    }).then(this.processClientHeader);
  }

  getBlob(url) {
    return fetch(url, {
      headers: this.getHeaders()
    })
      .then(this.processBlobResponse)
      .then(this.processStatusAndData);
  }

  getExternal(url) {
    return fetch(url, {
      headers: this.getExternalHeaders()
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  processClientHeader(response) {
    return new Promise((resolve, reject) => {
      response.headers.forEach((val, key) => {
        if (key === 'key') ls.set('key', val);
      });
      resolve({ status: 200 });
    });
  }

  get(url, body) {
    if (parseInt(ls.get('tokenExpiry'), 10) < new Date().getTime()) {
      return refreshOauthToken(ls.get('refreshToken'))
        .then(response => {
          return fetch(this.getUrl(url), {
            headers: this.getHeaders(),
            body: JSON.stringify(body)
          })
            .then(this.processResponse)
            .then(this.processStatusAndData);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log('error on GET', err);
        });
    }
    return fetch(this.getUrl(url), {
      headers: this.getHeaders()
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  delete(url) {
    if (parseInt(ls.get('tokenExpiry'), 10) < new Date().getTime()) {
      return refreshOauthToken(ls.get('refreshToken')).then(response => {
        return fetch(this.getUrl(url), {
          method: 'DELETE',
          headers: this.getHeaders()
        })
          .then(this.processResponse)
          .then(this.processStatusAndData);
      });
    }
    return fetch(this.getUrl(url), {
      method: 'DELETE',
      headers: this.getHeaders()
    })
      .then(
        response =>
          new Promise((resolve, reject) => {
            const { status } = response;

            if (status === 200) {
              resolve(response);
            } else {
              this.processResponse(response).then(response => reject(response));
            }
          })
      )
      .catch(error => new Promise((resolve, reject) => reject(error)));
  }

  post(url, body) {
    if (parseInt(ls.get('tokenExpiry'), 10) < new Date().getTime()) {
      return refreshOauthToken(ls.get('refreshToken')).then(response => {
        return fetch(this.getUrl(url), {
          method: 'POST',
          headers: this.getHeaders(),
          body: JSON.stringify(body)
        })
          .then(this.processResponse)
          .then(this.processStatusAndData);
      });
    }
    return fetch(this.getUrl(url), {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify(body)
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  postWithoutRefresh(url, headers) {
    // const formData = new FormData();
    // const searchParams = new URLSearchParams();
    // searchParams.set('grant_type', 'refresh_token');
    // searchParams.set('client_id', window.reppleEnv.cognito.APP_CLIENT_ID);
    // searchParams.set('refresh_token', ls.get('refreshToken'));
    const params = `grant_type=refresh_token&client_id=${
      window.reppleEnv.cognito.APP_CLIENT_ID
    }&refresh_token=${encodeURIComponent(ls.get('refreshToken'))}`;
    return fetch(url, {
      method: 'POST',
      headers,
      body: params
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  postWithHeaders(url, body, headers) {
    if (parseInt(ls.get('tokenExpiry'), 10) < new Date().getTime()) {
      return refreshOauthToken(ls.get('refreshToken')).then(response => {
        return fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(body)
        })
          .then(this.processResponse)
          .then(this.processStatusAndData);
      });
    }
    return fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body)
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  put(url, body) {
    if (parseInt(ls.get('tokenExpiry'), 10) < new Date().getTime()) {
      return refreshOauthToken(ls.get('refreshToken')).then(response => {
        return fetch(this.getUrl(url), {
          method: 'PUT',
          headers: this.getHeaders(),
          body: JSON.stringify(body)
        })
          .then(this.processResponse)
          .then(this.processStatusAndData);
      });
    }
    return fetch(this.getUrl(url), {
      method: 'PUT',
      headers: this.getHeaders(),
      body: JSON.stringify(body)
    })
      .then(this.processResponse)
      .then(this.processStatusAndData);
  }

  processResponse(response) {
    return new Promise((resolve, reject) => {
      response
        .json()
        .then(data => resolve({ status: response.status, data }))
        .catch(error => reject(error));
    });
  }

  processBlobResponse(response) {
    return new Promise((resolve, reject) => {
      response
        .blob()
        .then(data => resolve({ status: response.status, data }))
        .catch(error => reject(error));
    });
  }

  processStatusAndData(response) {
    return new Promise((resolve, reject) => {
      // Deals with the token no longer being valid
      if (response.status === 401 && response.data.error === 'invalid_token') {
        // ls.remove('token');
        // ls.remove('refreshToken');
        // ls.remove('tokenExpiry');
        // ls.remove('user_id');
        // ls.remove('state');
        // if (ls.get('homeKey') !== null) {
        //   ls.set('key', ls.get('homeKey'));
        // }
        // Todo: this dangerous because it leads to auto-refresh if checks don't pass as expected.
        // Candidate for refactoring.
        // if (window.location.href.indexOf('googleAuth') === -1) window.location.reload();
        // ls.remove('homeKey');
      }

      // will resolve or reject depending on status, will pass both "status" and "data" in either case
      const func = response.status < 400 ? resolve : reject;
      func({ status: response.status, data: response.data });
    });
  }

  getHeaders() {
    const headers = new Headers({
      'Content-Type': 'application/json;charset=UTF-8',
      'X-ACCESS-TOKEN': ls.get('accessToken') || '',
      'X-USER-ID': ls.get('user_id') || '',
      key: ls.get('key') || ''
    });

    if (ls.get('token') !== null) {
      headers.append('Authorization', `Bearer ${ls.get('token')}`);
    }
    return headers;
  }

  getExternalHeaders() {
    const headers = new Headers({
      'Access-Control-Allow-Origin': window.reppleEnv.REACT_APP_API_URL,
      'Content-type': 'application/json; charset=UTF-8',
      'Access-Control-Allow-Methods': 'OPTIONS, GET'
    });

    return headers;
  }
}

export default new AjaxUtil();

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import { style } from '../../assets/style';
import { colors } from '../../assets/constants';

const styles = {
  ...style,
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
  textFieldContainer: {
    borderBottom: 'none'
  },
  radioLabel: {
    fontSize: '14px'
  },
  radio: {
    '& > span > div > svg': {
      height: '0.75em',
      width: '0.75em'
    }
  },
  selected: {
    '& > span > div > svg': {
      color: colors.orangeDark
    }
  },
  radioOptionLabel: {
    '& > span': {
      fontSize: '14px'
    }
  },
  radioGroup: {
    backgroundColor: colors.white,
    padding: '20px 0px 10px 13px',
    borderBottom: 'none'
  },
  radioBorder: {
    borderRadius: '5px',
    border: 'solid 1px rgba(135, 152, 173, 0.3)',
    '& > legend': {
      backgroundColor: colors.white,
      paddingLeft: '6px',
      paddingRight: '6px'
    }
  }
};

function RadioButtons({
  classes,
  error,
  id,
  value,
  values,
  optionValue,
  optionName,
  label,
  handleChange,
  overrideClassName,
  labelConfig,
  helperText,
  disabled
}) {
  let options = null;

  if (values && Object.getOwnPropertyNames(values).length !== 0) {
    options = values.map((v, idx) => {
      return (
        <FormControlLabel
          value={optionValue ? v[optionValue] : v}
          control={
            /* eslint-disable react/jsx-wrap-multilines */
            <Radio
              className={classNames(classes.radio, {
                [classes.selected]: value === (optionValue ? v[optionValue] : v)
              })}
            />
            /* eslint-enable react/jsx-wrap-multilines */
          }
          label={v[optionName]}
          key={`${idx}optionName`}
          className={classes.radioOptionLabel}
        />
      );
    });
  }
  return values ? (
    <FormControl
      className={classNames(
        { [overrideClassName]: !!overrideClassName },
        { [classes.formControl]: !overrideClassName },
        classes.radioGroup,
        classes.textField,
        classes.radioBorder
      )}
      component="fieldset"
      error={!!error}
      variant="outlined"
      disabled={disabled}
      fullWidth
    >
      <InputLabel shrink component="legend">
        {label}
      </InputLabel>
      <RadioGroup name={id} value={value} onChange={handleChange}>
        {options}
      </RadioGroup>
      {helperText && <FormHelperText className={classes.fieldHelperText}>{helperText}</FormHelperText>}
      {error && (
        <FormHelperText className={classes.fieldHelperText} style={{ marginLeft: 0 }}>
          {' '}
          {error}
        </FormHelperText>
      )}
    </FormControl>
  ) : null;
}

RadioButtons.defaultProps = {
  disabled: false
};

RadioButtons.propTypes = {
  optionName: PropTypes.string.isRequired,
  optionValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  changeParameter: PropTypes.string,
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  values: PropTypes.array,
  handleChange: PropTypes.func,
  overrideClassName: PropTypes.string,
  inputConfig: PropTypes.object,
  selectConfig: PropTypes.object,
  labelConfig: PropTypes.object,
  helperText: PropTypes.string,
  disabled: PropTypes.bool
};

export default withStyles(styles)(RadioButtons);

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { qualificationInitialState } from '../entityInitialStates';

export const listOrganisationQualificationRequest = () => ({
  url: process.env.REACT_APP_LIST_ORGANISATION_QUALIFICATION,
  entityName: 'organisationQualifications',
  initialState: []
});

export const listQualificationRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_QUALIFICATION}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'qualifications'
  };
};

export const getQualificationRequest = id => ({
  url: `${process.env.REACT_APP_GET_QUALIFICATION}${id}`,
  entityName: 'qualification',
  initialState: qualificationInitialState
});

export const saveQualificationRequest = entity => ({
  url: process.env.REACT_APP_SAVE_QUALIFICATION,
  entityName: 'qualification',
  entity
});

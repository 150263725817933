import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getUsersUrl } from '../../utils/searchUrls';
import { listUserRequest } from '../../utils/entityRequests/userAdminRequests';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class UserListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/organisation/user/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table, classes } = this.props;
    const items = getRecords(search, table);
    return items.map(user => ({
      clickAction: () => this.actionNavigate(user.identifier),
      cells: [
        {
          value: (
            <div>
              <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar}>
                  {user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()}
                </Avatar>
              </div>
              {`${user.firstName} ${user.lastName}`}
            </div>
          )
        },
        { value: user.email, hiddenBelow: 'md' },
        { value: user.role.name }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Name' }, { value: 'Email' }, { value: 'Role' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Users Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, table } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="User"
              buttons={[
                {
                  text: 'Add user',
                  icon: <AddWhiteIcon />,
                  url: '/organisation/user/create',
                  primary: true
                }
              ]}
            />

            <ListingWithSearch
              name="users"
              getSearchUrl={getUsersUrl}
              onList={listEntities}
              // In case of multiple filters this would accept event and name params
              // based on which it would determine filterValue
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listUserRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

UserListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-users'),
  ...tableSelector(state, 'users')
});

export default compose(withStyles(styles), connect(mapStateToProps, { listEntities }))(UserListView);

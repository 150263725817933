import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
// import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  MuiThemeProvider,
  Paper,
  Slide,
  Tooltip
} from '@material-ui/core';
import _ from 'lodash';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { customTheme, VALIDATORS_BY_NAME } from '../../assets/constants';
import QualificationList from '../qualification/QualificationList';
import CredentialTypeList from '../credentialType/CredentialTypeList';
import { getWorkPlaceRequest, saveWorkPlaceRequest } from '../../utils/entityRequests/workPlaceRequests';
import { workPlaceInitialState } from '../../utils/entityInitialStates';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import NewSectionHeading from '../common/NewSectionHeading';
import SaveButton from '../widgets/SaveButton';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import UserAssignment from '../team/UserAssignment';
import { clearUserFileDownloads, requestPublicImageUploadUrl, uploadToS3 } from '../../store/actions/DocumentActions';
import ImageUpload from '../widgets/ImageUpload';

const styles = () => ({
  ...style,
  ...layout,
  displayField: {
    fontSize: '15px',
    color: 'rgba(0,0,0,.87)'
  },
  marginTop: {
    marginTop: '20px'
  }
});

class ChangeWorkPlaceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workPlace: workPlaceInitialState,
      shouldDisplaySelectCredentialType: false,
      shouldDisplaySelectQualificationType: false,
      shouldDisplaySelectUser: false
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/workplace/location/view/${id}` : '/workplace/location';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const workPlace = await getEntity(getWorkPlaceRequest(match.params.id));
      this.setState({ workPlace });
    } else {
      this.setState({
        workPlace: workPlaceInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ workPlace: workPlaceInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      workPlace: {
        ...prevState.workPlace,
        [name]: value
      }
    }));
  };

  handleLatLongChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      workPlace: {
        ...prevState.workPlace,
        position: {
          ...prevState.workPlace?.position,
          [name]: value
        }
      }
    }));
  };

  handleDeleteCredentialType = identifier => {
    this.setState(prevState => ({
      ...prevState,
      workPlace: {
        ...prevState.workPlace,
        compulsoryCredentialTypes: prevState.workPlace.compulsoryCredentialTypes
          .slice()
          .filter(item => item.identifier !== identifier)
      }
    }));
  };

  handleDeleteQualification = identifier => {
    this.setState(prevState => ({
      ...prevState,
      workPlace: {
        ...prevState.workPlace,
        compulsoryQualifications: prevState.workPlace.compulsoryQualifications
          .slice()
          .filter(item => item.identifier !== identifier)
      }
    }));
  };

  closeSelectUser = () => {
    this.setState({
      shouldDisplaySelectUser: false
    });
  };

  openSelectUser = () => {
    this.setState({
      shouldDisplaySelectUser: true
    });
  };

  handleAddUser = user => {
    const { workPlace } = this.state;
    const workPlaceModel = _.cloneDeep(workPlace);

    if (!workPlaceModel.admins.find(item => item.identifier === user.identifier)) {
      workPlaceModel.admins.push(user);
      this.setState({
        workPlace: workPlaceModel
      });
    }
  };

  handleDeleteUser = identifier => {
    const { workPlace } = this.state;
    const workPlaceModel = _.cloneDeep(workPlace);
    const users = workPlaceModel.admins.filter(item => item.identifier !== identifier);

    this.setState({
      workPlace: {
        ...workPlaceModel,
        admins: users
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, requestPublicImageUploadUrl, uploadToS3 } = this.props;
    const { workPlace, imageUpload } = this.state;
    const workPlaceResult = _.cloneDeep(workPlace);
    if (imageUpload && !workPlaceResult.imageKey) {
      const upload = await requestPublicImageUploadUrl(imageUpload);
      const response = await uploadToS3([{ url: upload.url, control: 'workPlaceImage' }], [imageUpload]);
      if (response[0].status === 200) workPlaceResult.imageKey = upload.key;
    }

    const workPlaceId = await saveEntity(saveWorkPlaceRequest(workPlaceResult));

    if (!workPlaceId) return;

    history.push(`/workplace/location/view/${workPlaceId}`);
  };

  // handleAddressChange = address => {
  //   this.setState(prevState => ({
  //     ...prevState,
  //     workPlace: {
  //       ...prevState.workPlace,
  //       address,
  //       position: {
  //         latitude: '',
  //         longitude: ''
  //       }
  //     }
  //   }));
  // };

  handleSelectCredentialTypeChange = compulsoryCredentialTypes => {
    this.setState(prevState => ({
      ...prevState,
      workPlace: {
        ...prevState.workPlace,
        compulsoryCredentialTypes
      }
    }));

    this.closeDisplaySelectCredentialType();
  };

  handleSelectQualificationsChange = compulsoryQualifications => {
    this.setState(prevState => ({
      ...prevState,
      workPlace: {
        ...prevState.workPlace,
        compulsoryQualifications
      }
    }));

    this.closeDisplaySelectQualificationType();
  };

  // handleSelect = async (address, place) => {
  //   // const result = await geocodeByPlaceId(place);
  //   // const latLong = await getLatLng(result[0]);
  //
  //   this.setState(prevState => ({
  //     ...prevState,
  //     workPlace: {
  //       ...prevState.workPlace,
  //       address,
  //       // position: {
  //       //   latitude: latLong.lat,
  //       //   longitude: latLong.lng
  //       // }
  //     }
  //   }));
  // };

  openDisplaySelectCredentialType = () => {
    this.setState(prevState => ({
      ...prevState,
      shouldDisplaySelectCredentialType: true
    }));
  };

  closeDisplaySelectCredentialType = () => {
    this.setState(prevState => ({
      ...prevState,
      shouldDisplaySelectCredentialType: false
    }));
  };

  openDisplaySelectQualificationType = () => {
    this.setState(prevState => ({
      ...prevState,
      shouldDisplaySelectQualificationType: true
    }));
  };

  closeDisplaySelectQualificationType = () => {
    this.setState(prevState => ({
      ...prevState,
      shouldDisplaySelectQualificationType: false
    }));
  };

  handleClearImageUpload = () => {
    const { workPlace } = this.state;
    const workPlaceResult = _.cloneDeep(workPlace);
    workPlaceResult.imageKey = '';
    workPlaceResult.imageUrl = '';

    this.setState({
      workPlace: workPlaceResult
    });
  };

  handleImageUpload = imageUpload => {
    this.setState({
      imageUpload
    });
  };

  render() {
    const { classes, isSaving, documentUploads } = this.props;
    const {
      workPlace,
      shouldDisplaySelectCredentialType,
      shouldDisplaySelectQualificationType,
      shouldDisplaySelectUser
    } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header="Work Place Details"
            />
            <Grid item xs={12} container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={workPlace.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="address"
                      label="Address"
                      value={workPlace.address}
                      name="address"
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />

                    {/*<PlacesAutocomplete*/}
                    {/*  value={workPlace.address}*/}
                    {/*  onChange={this.handleAddressChange}*/}
                    {/*  onSelect={this.handleSelect}*/}
                    {/*  debounce={1000}*/}
                    {/*>*/}
                    {/*  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (*/}
                    {/*    <div>*/}
                    {/*      <NewValidatedField*/}
                    {/*        id="address"*/}
                    {/*        label="Address"*/}
                    {/*        value={workPlace.address}*/}
                    {/*        name="address"*/}
                    {/*        validators={[VALIDATORS_BY_NAME.REQUIRED]}*/}
                    {/*        className="location-search-input"*/}
                    {/*        inputTextProps={{ ...getInputProps({}) }}*/}
                    {/*      />*/}
                    {/*      <div>*/}
                    {/*        {loading && (*/}
                    {/*          <MuiThemeProvider theme={customTheme}>*/}
                    {/*            <CircularProgress size={12} />*/}
                    {/*          </MuiThemeProvider>*/}
                    {/*        )}*/}
                    {/*        <Paper square>*/}
                    {/*          {suggestions.map(suggestion => {*/}
                    {/*            return (*/}
                    {/*              <MenuItem*/}
                    {/*                selected={suggestion.active}*/}
                    {/*                component="div"*/}
                    {/*                onClick={this.handleSelect}*/}
                    {/*                key={suggestion.placeId}*/}
                    {/*                {...getSuggestionItemProps(suggestion)}*/}
                    {/*              >*/}
                    {/*                {suggestion.description}*/}
                    {/*              </MenuItem>*/}
                    {/*            );*/}
                    {/*          })}*/}
                    {/*        </Paper>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  )}*/}
                    {/*</PlacesAutocomplete>*/}
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="latitude"
                      label="Latitude"
                      value={workPlace?.position?.latitude || ''}
                      name="latitude"
                      onChange={this.handleLatLongChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="longitude"
                      label="longitude"
                      value={workPlace?.position?.longitude || ''}
                      name="longitude"
                      onChange={this.handleLatLongChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="type"
                      label="Type"
                      name="type"
                      value={workPlace.type}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="openHours"
                      label="Open Hours"
                      name="openHours"
                      value={workPlace.openHours}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="description"
                      label="Description"
                      name="description"
                      value={workPlace.description}
                      onChange={this.handleChange}
                      validators={[]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.CHECKBOX}
                      id="privateWorkplace"
                      label="Private"
                      name="privateWorkplace"
                      value={workPlace.privateWorkplace}
                      onChange={() => {
                        this.handleChange({ target: { name: 'privateWorkplace', value: !workPlace.privateWorkplace } });
                      }}
                      validators={[]}
                      labelPlacement="end"
                    />
                  </div>
                  <div className={classNames(classes.fieldContainer, classes.marginTop)}>
                    <label className={classes.displayLabel}>Workplace Image</label>
                    <ImageUpload
                      handleImageUpload={this.handleImageUpload}
                      clearImageUpload={this.handleClearImageUpload}
                      uploadStatus={
                        documentUploads && documentUploads.workPlaceImage ? documentUploads.workPlaceImage : []
                      }
                      isSaving={isSaving}
                      image={workPlace.imageUrl}
                    />
                  </div>
                </Grid>
                {workPlace.privateWorkplace && (
                  <Grid item xs={12}>
                    <NewSectionHeading heading="Admins">
                      <Tooltip title="Add Admin" aria-label="add" placement="bottom">
                        <IconButton
                          aria-owns="menu-appbar"
                          onClick={this.openSelectUser}
                          color="inherit"
                          key="openSelectUser"
                        >
                          <i className={`material-icons ${classes.icon}`}>add</i>
                        </IconButton>
                      </Tooltip>
                    </NewSectionHeading>
                    {workPlace.admins.map(user => (
                      <div className={classes.displayLabelAndField} key={`team-user-${user.identifier}`}>
                        <div className={classNames(classes.displayField, classes.headingAndAction)}>
                          {user.name}
                          <Tooltip title="Delete Admin" aria-label="add" placement="bottom">
                            <IconButton
                              aria-owns="menu-appbar"
                              onClick={() => this.handleDeleteUser(user.identifier)}
                              color="inherit"
                              key={`delete-user-${user.identifier}`}
                            >
                              <i className={`material-icons ${classes.icon}`}>delete</i>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </Grid>
                )}
                <Grid item xs={12} className={classes.marginTop}>
                  <NewSectionHeading heading="Compulsory Credential Types">
                    <Tooltip title="Change Compulsory Credential Types" aria-label="add" placement="bottom">
                      <IconButton
                        aria-owns="menu-appbar"
                        onClick={this.openDisplaySelectCredentialType}
                        color="inherit"
                        key="openSelectUser"
                      >
                        <i className={`material-icons ${classes.icon}`}>edit</i>
                      </IconButton>
                    </Tooltip>
                  </NewSectionHeading>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    {workPlace.compulsoryCredentialTypes.map(selectedCredentialType => (
                      <div key={`credentialType-${selectedCredentialType.identifier}`}>
                        <div className={classNames(classes.displayField, classes.headingAndAction)}>
                          {selectedCredentialType.name}
                          <Tooltip title="Delete Credential Type" aria-label="add" placement="bottom">
                            <IconButton
                              aria-owns="menu-appbar"
                              onClick={() => this.handleDeleteCredentialType(selectedCredentialType.identifier)}
                              color="inherit"
                              key={`delete-credentialType-${selectedCredentialType.identifier}`}
                            >
                              <i className={`material-icons ${classes.icon}`}>delete</i>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} className={classes.marginTop}>
                  <NewSectionHeading heading="Compulsory Qualifications">
                    <Tooltip title="Change Compulsory Qualifications" aria-label="add" placement="bottom">
                      <IconButton
                        aria-owns="menu-appbar"
                        onClick={this.openDisplaySelectQualificationType}
                        color="inherit"
                        key="openSelectUser"
                      >
                        <i className={`material-icons ${classes.icon}`}>edit</i>
                      </IconButton>
                    </Tooltip>
                  </NewSectionHeading>
                </Grid>
                <Grid item xs={12}>
                  <div className={classNames(classes.fieldContainer)}>
                    {workPlace.compulsoryQualifications.map(selectedQualification => (
                      <div key={`qualification-${selectedQualification.identifier}`}>
                        <div className={classNames(classes.displayField, classes.headingAndAction)}>
                          {selectedQualification.name}
                          <Tooltip title="Delete Qualification" aria-label="add" placement="bottom">
                            <IconButton
                              aria-owns="menu-appbar"
                              onClick={() => this.handleDeleteQualification(selectedQualification.identifier)}
                              color="inherit"
                              key={`delete-qualification-${selectedQualification.identifier}`}
                            >
                              <i className={`material-icons ${classes.icon}`}>delete</i>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Modal open={shouldDisplaySelectCredentialType} onClose={this.closeDisplaySelectCredentialType}>
                <Slide direction="left" in={shouldDisplaySelectCredentialType} mountOnEnter unmountOnExit>
                  <Grid item xs={6} className={`${classes.modalContainer}`}>
                    <CredentialTypeList
                      closeDisplaySelectCredentialType={this.closeDisplaySelectCredentialType}
                      selectCredentialTypes={this.handleSelectCredentialTypeChange}
                    />
                  </Grid>
                </Slide>
              </Modal>
              <Modal open={shouldDisplaySelectQualificationType} onClose={this.closeDisplaySelectQualificationType}>
                <Slide direction="left" in={shouldDisplaySelectQualificationType} mountOnEnter unmountOnExit>
                  <Grid item xs={6} className={`${classes.modalContainer}`}>
                    <QualificationList
                      closeDisplaySelectQualificationType={this.closeDisplaySelectQualificationType}
                      selectQualifications={this.handleSelectQualificationsChange}
                    />
                  </Grid>
                </Slide>
              </Modal>
              <Modal open={shouldDisplaySelectUser} onClose={this.closeSelectUser}>
                <Slide direction="left" in={shouldDisplaySelectUser} mountOnEnter unmountOnExit>
                  <Grid item xs={4} className={`${classes.modalContainer}`}>
                    <UserAssignment
                      closeSelectUser={this.closeSelectUser}
                      addUser={this.handleAddUser}
                      heading="Admins"
                    />
                  </Grid>
                </Slide>
              </Modal>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton isSaving={isSaving} isDisabled={false} onSubmit={this.submitForm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeWorkPlaceView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func,
  match: PropTypes.object,
  workPlace: PropTypes.object,
  isSaving: PropTypes.bool,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'workPlace'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity, uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads }),
  withRouter
)(ChangeWorkPlaceView);

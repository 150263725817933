import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';

class GenericHeadingOnlyBreadcrumb extends PureComponent {
  render() {
    const { isLoading, heading } = this.props;
    return <GenericBreadcrumbItem mainText={heading} subText="" isLoading={isLoading} />;
  }
}

const mapStateToProps = (state, props) => ({
  isLoading: loadingSelector(state, props.functionName)
});

GenericHeadingOnlyBreadcrumb.propTypes = {
  isLoading: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  subText: PropTypes.string
};

export default connect(mapStateToProps)(GenericHeadingOnlyBreadcrumb);

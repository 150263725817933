import _ from 'lodash';
import React, { Component } from 'react';
import { MuiThemeProvider, withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import Button from '@material-ui/core/Button/Button';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import connect from 'react-redux/es/connect/connect';
import { style } from '../../assets/style';
import { getEntity, putEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import ValidatedField from '../widgets/ValidatedField';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { customTheme } from '../../assets/constants';
import { expireCredentialRequest, getCredentialRequest } from '../../utils/entityRequests/credentialRequests';
import { entitySelector } from '../../store/selectors/entity';

const styles = {
  ...style
};

class YesNoDialog extends Component {
  handleClose = () => {
    const { close } = this.props;
    close();
  };

  render() {
    const { open, title, handleNo, handleYes, children } = this.props;

    return (
      <MuiThemeProvider theme={customTheme}>
        <Dialog
          fullWidth
          open={open}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          onClose={this.handleClose}
        >
          <DialogTitle id="form-dialog-title">{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <Button onClick={handleNo} color="primary">
              No
            </Button>
            <Button onClick={handleYes} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

YesNoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  handleNo: PropTypes.func.isRequired,
  handleYes: PropTypes.func.isRequired
};

export default compose(withStyles(styles))(YesNoDialog);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Grid } from '@material-ui/core';
import { entityArraySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import { getRoleRequest, listPermissionRequest, saveRoleRequest } from '../../utils/entityRequests/userAdminRequests';
import { roleInitialState } from '../../utils/entityInitialStates';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout
});

class ChangeRoleView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPermissions: [],
      role: roleInitialState
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/admin/userRole/view/${id}` : '/admin/userRole';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const role = await getEntity(getRoleRequest(match.params.id));
      this.setState({ selectedPermissions: role.permissions.map(item => item.identifier), role });
    } else {
      this.setState({
        role: roleInitialState
      });
    }

    await getEntity(listPermissionRequest());
  }

  componentWillUnmount() {
    this.setState({ role: roleInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      role: {
        ...prevState.role,
        [name]: value
      }
    }));
  };

  handlePermissionChange = event => {
    const { name } = event.target;
    const { permissions } = this.props;
    const { role } = this.state;
    const noRoles = role.permissions.length;
    const filteredPermissions = role.permissions.filter(item => item.identifier !== name);
    const newPermissions =
      filteredPermissions.length !== noRoles
        ? filteredPermissions
        : role.permissions.slice().concat(permissions.find(item => item.identifier === name));

    this.setState(prevState => ({
      ...prevState,
      selectedPermissions: newPermissions.map(item => item.identifier),
      role: {
        ...prevState.role,
        permissions: newPermissions
      }
    }));
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { role } = this.state;

    const roleId = await saveEntity(saveRoleRequest(role));

    if (!roleId) return;

    history.push(`/admin/userRole/view/${roleId}`);
  };

  render() {
    const { classes, isSaving, permissions, errors, match } = this.props;
    const { role, selectedPermissions } = this.state;
    const perms = permissions || [];
    const { params } = match;
    const { id } = params;
    const assignablePerms = role.reppleOnly ? perms : perms.filter(item => !item.reppleOnly);

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <Grid item xs={12}>
              <NewHeaderEditActions
                cancelUrl={this.cancelUrl}
                declineButtonText="Back"
                onSubmit={this.submitForm}
                name="changeFormHeaderAction"
                header={`${id ? 'Edit' : 'New'} Role`}
                isSaving={isSaving}
              />
            </Grid>
            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={role.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                </Grid>
                {!this.isEdit && (
                  <Grid item xs={12}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.CHECKBOX}
                      id="reppleOnly"
                      label="Repple Only"
                      name="reppleOnly"
                      value={role.reppleOnly}
                      onChange={() => {
                        this.handleChange({ target: { name: 'reppleOnly', value: !role.reppleOnly } });
                      }}
                      validators={[]}
                      className={classes.paddingRight}
                      labelPlacement="end"
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  {errors && errors.fieldErrors.permissions && (
                    <FormHelperText error>{errors.fieldErrors.permissions}</FormHelperText>
                  )}
                  <NewValidatedField
                    type={VALIDATED_FIELD_TYPES.CHECKBOXES}
                    label="Permissions"
                    overrideClassName={classes.fullWidth}
                    validators={[]}
                    onChange={this.handlePermissionChange}
                    options={assignablePerms.map(permission => ({
                      label: permission.name,
                      checked: selectedPermissions.indexOf(permission.identifier) !== -1,
                      name: permission.identifier
                    }))}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeRoleView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func,
  match: PropTypes.object,
  role: PropTypes.object,
  permissions: PropTypes.array,
  isSaving: PropTypes.bool,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'role'),
  ...entityArraySelector(state, 'permissions'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeRoleView);

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { style } from '../assets/style';
import renderRoutes from '../routes/renderRoutes';
import informationRoutes from '../routes/informationRoutes';
import { colors } from '../assets/constants';

const styles = () => ({
  ...style,
  baseLayoutContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row'
  },
  baseLayoutContentContainer: {
    ...style.container,
    flexDirection: 'column'
  },
  pageContainer: {
    paddingTop: '0 !important',
    paddingRight: '0 !important',
    // display: 'flex',
    overflowY: 'auto',
    backgroundColor: colors.backgroundBlue,
    height: '100%'
  }
});

class InformationLayout extends React.Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.baseLayoutContainer}>
        <div className={classes.baseLayoutContentContainer}>
          <div className={classes.pageContainer}>
            <Switch>{renderRoutes(informationRoutes)}</Switch>
          </div>
        </div>
      </div>
    );
  }
}

InformationLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withRouter)(InformationLayout);

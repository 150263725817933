import { textButton } from './buttons';
import { globalSpacingPixels, globalHalfSpacingPixels, globalQuarterSpacingPixels, colors } from './constants';

export default {
  container: {
    height: '100vh',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center -26vh',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center'
  },
  authImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh'
  },
  logo: {
    maxWidth: '200px'
  },
  formContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: 'auto',
    marginTop: '24px'
  },
  textField: {
    flex: 1,
    color: `${colors.fontGreyLessLight} !important`
  },
  textLabel: {
    color: `${colors.fontGreyLessLight} !important`,
    opacity: 0.5
  },
  underline: {
    '&:before, &:after': {
      borderBottomColor: `${colors.grey4} !important`
    }
  },
  bottomFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: globalHalfSpacingPixels
  },
  submit: {
    backgroundColor: `${colors.orangeDark}`,
    textTransform: 'capitalize',
    fontWeight: 'normal',
    paddingLeft: `calc( ${globalSpacingPixels} - ${globalQuarterSpacingPixels} )`,
    paddingRight: `calc( ${globalSpacingPixels} - ${globalQuarterSpacingPixels} )`,
    '&:hover': {
      backgroundColor: `${colors.orangeDark}`
    }
  },
  cancelLink: {
    ...textButton,
    color: colors.grey4
  },
  primaryLink: {
    ...textButton,
    color: `${colors.orangeNew}`
  },
  emailResetConfirmation: {
    margin: `${globalSpacingPixels} 0`
  },
  googleLoginButton: {
    display: 'block',
    margin: '40px auto'
  },
  passwordContainer: {
    width: '500px'
  }
};

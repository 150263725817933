import { colors, sizes, commonStyles } from './constants';

export const navItemBorderWidth = '3px';

export default {
  logo: {
    height: sizes.headerHeight,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
    '& svg': {
      height: '100%'
    },
    textDecoration: 'none'
  },
  settingsMenu: {
    marginLeft: '48px'
  },
  settingsMenuContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '20px',
    outline: 'none',
    minWidth: '250px'
  },
  settingsMenuHeader: {
    fontSize: '24px',
    fontWeight: 300,
    textTransform: 'uppercase',
    borderBottom: '2px solid #e9e9e9',
    width: '100%',
    textAlign: 'center',
    paddingBottom: '10px',
    marginBottom: '10px'
  },
  settingsMenuLink: {
    padding: '10px',
    borderBottom: `2px solid ${colors.grey2}`,
    textAlign: 'left',
    textDecoration: 'none',
    width: '100%',
    color: 'rgba(0, 0, 0, 0.57)'
  },
  settingsLink: {
    '& i': {
      position: 'relative',
      zIndex: 2,
      borderRadius: '50%',
      '&:after': {
        content: '""',
        transition: commonStyles.transition,
        position: 'absolute',
        width: 0,
        height: '100%',
        top: 0,
        left: '50%',
        right: '-10px',
        background: colors.white,
        zIndex: '-1'
      }
    },

    '&.state-open': {
      '& i': {
        color: colors.orangeDark,
        background: colors.white,
        borderRadius: '50%',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        '&:after': {
          width: `calc(100% + ${navItemBorderWidth})`,
          transitionDelay: '300ms'
        }
      }
    }
  },
  trainingMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '400px'
  },
  trainingMenu: {
    '& ul': {
      backgroundColor: `${colors.grey1}`
    }
  },
  navLink: {
    textDecoration: 'none',
    padding: '10px 0',
    width: '50px',
    height: '40px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
    // borderLeft: `${navItemBorderWidth} solid transparent`,
    // borderRight: `${navItemBorderWidth} solid transparent`,
    transition: commonStyles.transition,
    '& i, & svg': {
      transition: commonStyles.transition,
      color: colors.blue
    },
    '&.selected': {
      // borderLeft: `3px solid ${colors.white}`,
      backgroundColor: colors.orangeLight,
      borderRadius: '8px'
    }
  },
  navigationItemSettings: {
    marginTop: 'auto',
    marginBottom: '20px'
  },
  navPlaceHolder: {
    height: '40px'
  },
  fullList: {
    width: 250
  },
  leftSideNavContainer: {
    transition: commonStyles.transition,
    position: 'relative',
    zIndex: 30,
    marginRight: 0,
    width: '325px',
    transitionProperty: 'opacity, left, top, width',
    transitionDuration: '1s, .5s'
  },
  leftSideNavContainerClosed: {
    '& $viewHeading': {
      transform: 'scale(0)',
      opacity: 0,
      maxWidth: 0
    },
    '& $viewHeadingContainer': {
      marginLeft: 0,
      borderBottom: 'none'
    },
    width: '90px !important'
  },
  treeItemContainer: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: '14px',
    color: colors.blue,
    padding: '5px',
    '& i': {
      marginRight: '7px'
    },
    textDecoration: 'none',
    border: 'none',
    background: 'transparent',
    '&:focus': {
      border: 'none',
      outline: 'none',
      boxShadow: 'none'
    }
  },
  treeItem: {
    // marginTop: '10px'
  },
  treeMenuContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  treeMenu: {
    width: '290px'
  },
  selectedItem: {
    backgroundColor: colors.orangeLight,
    borderRadius: '8px',
    '& i, & label, & svg': {
      color: colors.orangeNew,
      fill: `${colors.orangeNew} !important`
    }
  },
  createMargin: {
    marginTop: '40px'
  }
};

import axios from 'axios';
import { startLoading, stopLoading } from './LoadingActions';
import ajax from '../../components/http/AjaxUtil';
import {
  GLOBAL_ERROR,
  LIST_FILE_CREDENTIAL_REQUEST_DOWNLOADS,
  LIST_FILE_DOWNLOADS,
  LIST_FILE_UPLOADS,
  UPDATE_FILE_PROGRESS,
  FILE_UPLOAD_DELETED,
  CLEAR_FILE_UPLOADS
} from './types';
import { FILE_UPLOAD_STATUS } from '../../assets/constants';

let timeout = null;

export const updateFileProgress = (fileName, fileId, progress, controlName, error) => {
  return {
    type: UPDATE_FILE_PROGRESS,
    payload: {
      fileName,
      fileId,
      progress,
      controlName,
      error
    }
  };
};

export const requestUploadUrl = (documents, id, uuid, uploadType) => async dispatch => {
  const url = `${process.env.REACT_APP_REQUEST_FILE_UPLOAD}`;
  const payload = documents.map(document => ({
    fileName: document.name,
    mimeType: document.type,
    size: document.size
  }));

  try {
    const s3UploadURLs = await ajax.put(url, {
      uploads: payload,
      entityUuid: uuid,
      controlName: id,
      fileUploadType: uploadType
    });

    return s3UploadURLs.data.responses;
  } catch (e) {
    return e;
  }
};

export const requestPublicImageUploadUrl = document => async dispatch => {
  const url = `${process.env.REACT_APP_IMAGE_FILE_UPLOAD}`;
  dispatch(startLoading('requestPublicImageUploadUrl'));
  try {
    const s3UploadURL = await ajax.put(url, {
      fileName: document.name,
      mimeType: document.type,
      size: document.size
    });
    dispatch(stopLoading('uploadToS3'));
    return s3UploadURL.data;
  } catch (e) {
    return e;
  }
};

export const uploadToS3 = (uploadUrls, files) => dispatch => {
  dispatch(startLoading('uploadToS3'));
  const s3UploadRequests = uploadUrls.map((file, index) => {
    if (!file.error) {
      return axios
        .request({
          method: 'put',
          url: file.url,
          data: files[index],
          headers: { 'content-type': files[index].type },
          onUploadProgress: progress => {
            dispatch(updateFileProgress(files[index].name, file.identifier, progress, file.control));
          }
        })
        .then(response => {
          return response;
        });
    }
    dispatch(stopLoading('uploadToS3'));
    dispatch(
      updateFileProgress(files[index].name, file.identifier, { total: 100, loaded: 100 }, file.control, file.error)
    );

    return { status: 200 };
  });

  return Promise.all(s3UploadRequests);
};

export const uploadDocuments = (documents, id, uuid, uploadType) => async dispatch => {
  dispatch(startLoading('uploadDocuments'));
  try {
    // Step 1: Get presigned URLS for uploading the files to AWS
    const uploadUrls = await dispatch(requestUploadUrl(documents, id, uuid, uploadType));
    // Step 2: Upload the files using the presigned urls
    const s3Response = await dispatch(uploadToS3(uploadUrls, documents));

    // Handle each file upload status individually
    s3Response.forEach((item, index) => {
      if (item.status !== 200) {
        dispatch(
          updateFileProgress(
            uploadUrls[index].fileName,
            uploadUrls[index].identifier,
            { total: 100, loaded: 100 },
            `${uuid}-${id}`,
            'Error uploading document'
          )
        );
      }
    });

    setTimeout(() => {
      dispatch(listFileUploads(uuid, true));
    }, 2000);
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('uploadDocuments'));
};

export const listFileUploads = (uuid, withTimer = false) => async dispatch => {
  dispatch(startLoading('listFileUploads'));
  const url = `${process.env.REACT_APP_LIST_FILE_UPLOAD}${uuid}/upload/list`;
  if (timeout) clearTimeout(timeout);
  try {
    const uploads = await ajax.get(url);
    dispatch({
      type: LIST_FILE_UPLOADS,
      payload: uploads.data
    });

    if (withTimer) {
      const numberInComplete = uploads.data.filter(
        item =>
          item.status === FILE_UPLOAD_STATUS.UPLOADED.id ||
          (item.mimeType.startsWith('image') &&
            item.status !== FILE_UPLOAD_STATUS.RESIZED_THUMBNAIL.id &&
            item.status !== FILE_UPLOAD_STATUS.LINKED.id &&
            item.status !== FILE_UPLOAD_STATUS.ERROR.id)
      ).length;

      if (numberInComplete !== 0) {
        timeout = setTimeout(() => {
          dispatch(listFileUploads(uuid, true));
        }, 2000);
      }
    }
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('listFileUploads'));
};

export const listCredentialFileDownloads = id => async dispatch => {
  dispatch(startLoading('listCredentialFileDownloads'));
  const url = `${process.env.REACT_APP_LIST_CREDENTIAL_FILE_DOWNLOAD}${id}/list`;

  try {
    const response = await ajax.get(url);
    dispatch({
      type: LIST_FILE_DOWNLOADS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('listCredentialFileDownloads'));
};

export const downloadFile = (url, fileName, openInNewTab = false) => async dispatch => {
  dispatch(startLoading('downloadFile'));
  try {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = url;

    if (openInNewTab) link.target = '_blank';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('downloadFile'));
};

export const downloadBlob = (url, fileName, openInNewTab = false) => async dispatch => {
  dispatch(startLoading('downloadBlob'));
  try {
    const response = await ajax.getBlob(url);
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    if (openInNewTab) link.target = '_blank';
    link.href = blobUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('downloadBlob'));
};

export const listCredentialRequestFileDownloads = id => async dispatch => {
  dispatch(startLoading('listCredentialRequestFileDownloads'));
  const url = `${process.env.REACT_APP_LIST_CREDENTIAL_REQUEST_FILE_DOWNLOAD}${id}/list`;

  try {
    const response = await ajax.get(url);
    dispatch({
      type: LIST_FILE_CREDENTIAL_REQUEST_DOWNLOADS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('listCredentialRequestFileDownloads'));
};

export const listCredentialFileDownloadsWithoutAuthority = id => async dispatch => {
  dispatch(startLoading('listCredentialFileDownloadsWithoutAuthority'));
  const url = `${process.env.REACT_APP_LIST_CREDENTIAL_FILE_DOWNLOAD}${id}/noOrganisation/list`;

  try {
    const response = await ajax.get(url);
    dispatch({
      type: LIST_FILE_DOWNLOADS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('listCredentialFileDownloadsWithoutAuthority'));
};

export const listUserFileDownloads = uuid => async dispatch => {
  dispatch(startLoading('listUserFileDownloads'));
  const url = `${process.env.REACT_APP_LIST_USER_FILE_DOWNLOAD}${uuid}/list`;

  try {
    const response = await ajax.get(url);
    dispatch({
      type: LIST_FILE_DOWNLOADS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('listUserFileDownloads'));
};

export const clearUserFileDownloads = () => async dispatch => {
  dispatch({
    type: CLEAR_FILE_UPLOADS
  });
};

export const deleteUserFileDownload = id => async dispatch => {
  dispatch(startLoading('deleteUserFileDownload'));
  const url = `${process.env.REACT_APP_DELETE_USER_FILE_UPLOAD}${id}`;

  try {
    await ajax.delete(url);
    dispatch({
      type: FILE_UPLOAD_DELETED,
      id
    });
  } catch (error) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: error
    });
  }
  dispatch(stopLoading('deleteUserFileDownload'));
};

import { IMPORT_STATUS } from '../assets/constants';

export const formInitialState = {
  sections: [],
  name: '',
  identifier: '',
  title: '',
  formStatusType: ''
};

export const userInitialState = {
  identifier: '',
  username: '',
  email: '',
  enabled: true,
  locked: false,
  title: '',
  firstName: '',
  middleName: '',
  lastName: '',
  phone: '',
  passwordResetTokenExpiryDate: '',
  role: {
    identifier: '',
    name: ''
  },
  dateOfBirth: ''
};

export const roleInitialState = {
  identifier: '',
  name: '',
  reppleOnly: false,
  permissions: []
};

export const organisationMemberInitialState = {
  identifier: '',
  user: {
    identifier: '',
    firstName: '',
    lastName: ''
  },
  startDate: ''
};

export const organisationCredentialCheckInitialState = {
  holderName: '',
  position: { latitude: '', longitude: '' },
  workPlaceName: '',
  credentialName: '',
  qualificationName: '',
  validatingHolderName: '',
  status: '',
  checkedCredentials: [],
  credentialBean: {
    documentId: '',
    formName: '',
    credentialTypeName: '',
    holderName: '',
    createDate: '',
    expiryDate: '',
    credentialRequestFormUuid: '',
    sections: []
  }
};

export const qualificationInitialState = {
  identifier: '',
  name: '',
  groups: []
};

export const workPlaceInitialState = {
  identifier: '',
  name: '',
  address: '',
  type: '',
  description: '',
  openHours: '',
  position: {
    latitude: '',
    longitude: ''
  },
  privateWorkplace: false,
  compulsoryCredentialTypes: [],
  compulsoryQualifications: [],
  admins: []
};

export const credentialRequestInitialState = {
  identifier: '',
  formId: '',
  controls: [],
  assignedTeam: null,
  assignedUser: null,
  priority: '',
  note: '',
  reason: '',
  status: '',
  holder: {
    name: '',
    identifier: ''
  },
  workPlace: {
    name: '',
    identifier: ''
  },
  issuer: {
    name: '',
    identifier: ''
  },
  form: {
    sections: []
  },
  expiryDate: '',
  credentialType: {
    name: '',
    identifier: ''
  },
  startDate: '',
  endDate: ''
};

export const credentialTypeInitialState = {
  identifier: '',
  name: '',
  priority: '',
  assignedUser: {
    identifier: '',
    name: ''
  },
  assignedTeam: {
    identifier: '',
    name: ''
  },
  requestForm: {
    identifier: '',
    name: ''
  },
  credentialForm: {
    identifier: '',
    name: ''
  },
  credentialRequestType: '',
  imageUrl: '',
  backgroundColor: '',
  categoryId: '',
  industries: [],
  verificationTime: '',
  expires: true,
  publicCredential: false,
  singleValidCredential: false,
  type: '',
  fieldOfStudy: '',
  sections: []
};

export const cpdInitialState = {
  identifier: '',
  requiredPoints: '',
  credentialTypeId: '',
  form: {
    identifier: '',
    name: ''
  }
};

export const organisationInitialState = {
  identifier: '',
  name: '',
  key: '',
  authType: '',
  tokenValidFor: 0,
  refreshTokenValidFor: 0,
  type: '',
  acceptableCredentialCheckDistance: 0,
  parentReportingId: '',
  childReportingOrganisations: [],
  currency: {
    identifier: '',
    name: ''
  },
  assurance: false,
  competence: false,
  training: false,
  member: false
};

export const incidentInitialState = {
  identifier: '',
  incidentType: '',
  relationshipType: '',
  incidentTime: '',
  workPlace: {
    identifier: '',
    name: ''
  },
  description: '',
  holder: {
    identifier: '',
    name: ''
  },
  treated: false,
  hospitalised: false,
  incidentPerson: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    phone: '',
    email: '',
    address: ''
  },
  cost: ''
};

export const emailInitialState = {
  identifier: '',
  subject: '',
  body: '',
  dispatchTime: '',
  toAddress: '',
  failureReason: '',
  status: ''
};

export const teamInitialState = {
  identifier: '',
  name: '',
  users: []
};

export const reasonInitialState = {
  identifier: '',
  name: '',
  deprecated: false
};

export const activityInitialState = {
  identifier: '',
  name: '',
  organisation: {
    identifier: '',
    name: ''
  },
  workPlace: {
    identifier: '',
    name: ''
  },
  activityType: '',
  startDate: '',
  endDate: ''
};

export const ruleInitialState = {
  identifier: '',
  checkIn: false,
  checkOut: false,
  perDay: false,
  perActivity: false,
  points: ''
};

export const importInitialState = {
  identifier: '',
  formName: '',
  status: IMPORT_STATUS.UPLOADED.id,
  creatorName: '',
  totalNumberRows: 0,
  numberProcessed: 0,
  createDate: '',
  headers: [],
  rowData: []
};

export const credentialVersionInitialState = {
  documentId: '',
  formName: '',
  holderName: '',
  createDate: '',
  expiryDate: '',
  credentialRequestFormUuid: '',
  sections: [],
  version: ''
};

export const recurringRequestInitialState = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false
};

export const holderCredentialInitialState = {
  issuer: '',
  documentId: '',
  formName: '',
  holderName: '',
  createDate: '',
  expiryDate: '',
  credentialRequestFormUuid: '',
  sections: []
};

export const credentialRequestBreakdownInitialState = {
  created: 0,
  assigned: 0,
  failed: 0,
  complete: 0
};

export const graphDataInitialState = {
  xaxisName: '',
  yaxisName: '',
  graphSeries: [],
  legendItems: []
};

export const courseInitialState = {
  identifier: '',
  title: '',
  duration: 0,
  internal: false,
  blurb: '',
  listImage: '',
  locationType: '',
  periodType: '',
  difficultyType: '',
  courseStatusType: '',
  cost: '',
  organisation: {
    identifier: '',
    name: ''
  },
  centre: {
    identifier: '',
    name: ''
  },
  description: '',
  keywords: [],
  url: '',
  courseSessions: []
};

export const centreInitialState = {
  identifier: '',
  name: '',
  location: '',
  address: '',
  position: {
    latitude: '',
    longitude: ''
  },
  organisation: {
    identifier: '',
    name: ''
  },
  uuid: ''
};

export const roomInitialState = {
  identifier: '',
  name: '',
  location: '',
  capacity: 0
};

export const scheduledCourseInitialState = {
  identifier: '',
  courseTitle: '',
  startTime: '',
  endTime: '',
  numberHolders: 0,
  capacity: 0
};

export const postInitialState = {
  identifier: '',
  name: '',
  content: '',
  postedBy: {
    identifier: '',
    name: ''
  },
  postedDate: '',
  privatePost: false
};

export const profileInitialState = {
  firstName: '',
  lastName: '',
  avatarKey: '',
  avatarUrl: '',
  phone: '',
  roleName: ''
};

export const observationInitialState = {
  identifier: '',
  title: '',
  description: '',
  raisedBy: {
    identifier: '',
    name: ''
  },
  workPlace: {
    identifier: '',
    name: ''
  }
};

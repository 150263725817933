import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { organisationCredentialCheckInitialState } from '../entityInitialStates';

export const listOrganisationCredentialCheckRequest = () => (dispatch, getState) => {
  if (getState().search.organisationCredentialChecks) {
    const { filters } = getState().search.organisationCredentialChecks;
    const credentialTypeNameFilter = filters.credentialTypeName || NO_SEARCH_VALUE;
    const validatingMemberNameFilter = filters.validatingMemberName || NO_SEARCH_VALUE;
    const qualificationNameFilter = filters.qualificationName || NO_SEARCH_VALUE;

    return {
      url:
        /* eslint-disable max-len */
        `${process.env.REACT_APP_LIST_ORGANISATION_CREDENTIAL_CHECK}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${credentialTypeNameFilter}` +
        `/${validatingMemberNameFilter}/${qualificationNameFilter}`,
      entityName: 'organisationCredentialChecks'
    };
  }
};

export const getOrganisationCredentialCheckRequest = id => ({
  url: `${process.env.REACT_APP_GET_ORGANISATION_CREDENTIAL_CHECK}${id}`,
  entityName: 'organisationCredentialCheck',
  initialState: organisationCredentialCheckInitialState
});

export const getCredentialCheckFileDownloadsRequest = (credentialCheckId, credentialId) => ({
  url: `${process.env.REACT_APP_LIST_CREDENTIAL_CHECK_FILE_DOWNLOAD}${credentialCheckId}/${credentialId}/list`,
  entityName: 'fileDownloads',
  initialState: []
});

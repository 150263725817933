import AuthenticationLayout from '../layouts/AuthenticationLayout';
import NewBaseLayout from '../layouts/NewBaseLayout';
import InformationLayout from '../layouts/InformationLayout';

export default [
  { path: '/login', component: AuthenticationLayout },
  { path: '/passwordReset', component: AuthenticationLayout },
  { path: '/forgotPassword', component: AuthenticationLayout },
  { path: '/googleAuth', component: AuthenticationLayout },
  { path: '/changePassword', component: NewBaseLayout, protected: true },
  { path: '/credential/inbox', component: NewBaseLayout, protected: true },
  { path: '/credential/credentialType', component: NewBaseLayout, protected: true },
  { path: '/organisation', component: NewBaseLayout, protected: true },
  { path: '/workplace/organisationCredentialCheck', component: NewBaseLayout, protected: true },
  { path: '/workplace/location', component: NewBaseLayout, protected: true },
  { path: '/workplace/incident', component: NewBaseLayout, protected: true },
  { path: '/workplace/attendance', component: NewBaseLayout, protected: true },
  { path: '/admin/crowdsource', component: NewBaseLayout, protected: true },
  { path: '/joinOrganisation', component: InformationLayout, protected: false },
  { path: '/', component: NewBaseLayout, protected: true }
];

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { getCredentialTypeUrl } from '../../utils/searchUrls';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { listCredentialTypeRequest } from '../../utils/entityRequests/credentialTypeRequests';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';
import { STANDARD_CREDENTIAL_TYPE } from '../../assets/constants';

const styles = {
  ...style,
  backgroundSwatch: {
    width: '20px',
    height: '15px',
    borderRadius: '2px'
  },
  swatchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  }
};

class CredentialTypeListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/credential/credentialType/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table, classes } = this.props;
    const items = getRecords(search, table);

    return items.map(credentialType => ({
      clickAction: () => this.actionNavigate(credentialType.identifier),
      cells: [
        {
          value: (
            <div className={classes.swatchContainer}>
              <div className={classes.backgroundSwatch} style={{ backgroundColor: credentialType.backgroundColor }} />
            </div>
          )
        },
        {
          value: credentialType.name
        },
        {
          value: STANDARD_CREDENTIAL_TYPE[credentialType.standardCredentialType].label
        },
        {
          value: credentialType.type || '---'
        },
        {
          value: credentialType.category ? credentialType.category.name : '---'
        },
        {
          value: credentialType.credentialsIssued
        }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[
          { value: '', class: 'smallCol' },
          { value: 'Name' },
          { value: 'Standard Credential Type' },
          { value: 'Type' },
          { value: 'Category' },
          { value: 'Credentials Issued' }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        overrideStyle={{ height: 'calc(100vh - 287px)' }}
        emptyMessage="No Credential Types Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, table, listEntities } = this.props;
    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="Credential Type"
              buttons={[
                {
                  text: 'Add credential type',
                  icon: <AddWhiteIcon />,
                  url: '/credential/credentialType/create',
                  primary: true
                }
              ]}
            />
            <ListingWithSearch
              name="credentialType"
              getSearchUrl={getCredentialTypeUrl}
              onList={listEntities}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listCredentialTypeRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

CredentialTypeListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  table: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-credentialType'),
  ...tableSelector(state, 'credentialType')
});

export default compose(withStyles(styles), connect(mapStateToProps, { listEntities }))(CredentialTypeListView);

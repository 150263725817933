import { profileInitialState } from '../entityInitialStates';

export const getProfileRequest = () => ({
  url: process.env.REACT_APP_PROFILE_GET,
  entityName: 'profile',
  initialState: profileInitialState
});

export const saveProfileRequest = entity => ({
  url: process.env.REACT_APP_PROFILE_GET,
  entityName: 'profile',
  entity,
  notification: 'The profile has been updated'
});

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '../../assets/constants';
import TopLevelButton from './TopLevelButton';

const style = {
  buttons: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '23px',
    height: '45px'
  },
  lineSpacer: {
    border: `solid 1px ${colors.spacerBlue}`,
    height: '45px'
  }
};

const TopLevelButtons = ({ classes, buttons }) => (
  <div className={classes.buttons}>
    {buttons.map((button, index) => {
      if (index === 0) {
        return (
          <TopLevelButton {...button} first={index === 0} last={index === buttons.length - 1} key={`button${index}`} />
        );
      }
      return (
        <Fragment key={`button${index}`}>
          <div className={classes.lineSpacer} />
          <TopLevelButton {...button} first={index === 0} last={index === buttons.length - 1} />
        </Fragment>
      );
    })}
  </div>
);

TopLevelButtons.propTypes = {
  classes: PropTypes.object.isRequired,
  buttons: PropTypes.array.isRequired
};

export default withStyles(style)(TopLevelButtons);

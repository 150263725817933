import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import uuid from 'uuid';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import ChangeUser from '../common/ChangeUser';
import { uploadDocuments, listUserFileDownloads, clearUserFileDownloads } from '../../store/actions/DocumentActions';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { FILE_UPLOAD_STATUS, FILE_UPLOAD_TYPE } from '../../assets/constants';
import { documentUploadsSelector } from '../../store/selectors/document';
import { userInitialState } from '../../utils/entityInitialStates';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { getHolderRequest, saveHolderRequest } from '../../utils/entityRequests/holderRequests';

const styles = () => ({
  ...style,
  ...layout
});

class ChangeUserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        ...userInitialState,
        uuid: uuid()
      }
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/admin/holder/view/${id}` : '/admin/holder';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity, listUserFileDownloads } = this.props;

    if (this.isEdit) {
      const user = await getEntity(getHolderRequest(match.params.id));
      await listUserFileDownloads(user.uuid);
      this.setState({ user });
    } else {
      this.setState({
        user: {
          ...userInitialState,
          uuid: uuid()
        }
      });
    }
  }

  componentWillUnmount() {
    const { clearUserFileDownloads } = this.props;
    this.setState({ user: userInitialState });
    clearUserFileDownloads();
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, fileUploads } = this.props;
    const { user } = this.state;
    const userFileUploads = [];
    const userModel = _.cloneDeep(user);

    if (fileUploads) {
      fileUploads
        .filter(
          item => item.status === FILE_UPLOAD_STATUS.CLEAN.id || item.status === FILE_UPLOAD_STATUS.RESIZED_THUMBNAIL.id
        )
        .forEach(item => {
          userFileUploads.push(item.identifier);
        });
    }
    userModel.facialRecognitionImages = userFileUploads;

    const userId = await saveEntity(saveHolderRequest(userModel));

    if (!userId) return;

    history.push(`/admin/holder/view/${userId}`);
  };

  handleDocumentChange = (id, file) => {
    const { uploadDocuments } = this.props;
    const { user } = this.state;
    uploadDocuments([file], id, user.uuid, FILE_UPLOAD_TYPE.DOCUMENT);
  };

  render() {
    const { isSaving, match, documentUploads } = this.props;
    const { user } = this.state;
    const { id } = match.params;
    const cancelUrl = id ? `/admin/holder/view/${id}` : '/admin/holder';

    return (
      <ChangeUser
        user={user}
        cancelUrl={cancelUrl}
        handleChange={this.handleChange}
        handleSave={this.submitForm}
        type="Holder"
        isSaving={isSaving}
        onDocumentChange={this.handleDocumentChange}
        documentUploads={documentUploads}
        handleEmailPhoneChange={this.handleChange}
        togglePermissionChange={() => {}}
        handleRoleChange={this.handleChange}
        handleNo={() => {}}
        handleYes={() => {}}
        checkLinkHolder={false}
        foundHolder={null}
        handleClose={() => {}}
      />
    );
  }
}

ChangeUserView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  uploadDocuments: PropTypes.func,
  clearUserFileDownloads: PropTypes.func,
  listUserFileDownloads: PropTypes.func,
  match: PropTypes.object,
  user: PropTypes.object,
  documentUploads: PropTypes.object,
  errors: PropTypes.object,
  roles: PropTypes.array,
  fileUploads: PropTypes.array,
  isSaving: PropTypes.bool
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'user'),
  ...documentUploadsSelector(state),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity, uploadDocuments, listUserFileDownloads, clearUserFileDownloads }),
  withRouter
)(ChangeUserView);

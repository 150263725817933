import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SENT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_VALIDATION_FAILURE,
  LOGIN_FAILURE,
  LOGIN_INITIALISED,
  LOGIN_REINITIALISE,
  LOGIN_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_VALIDATION_FAILURE,
  RESTART_LOGIN
} from '../actions/types';

const initialState = {
  user: {},
  authenticationDetails: {},
  userLoggedIn: false,
  loginInitialised: false,
  externalLogin: false,
  passwordReset: {
    userId: '',
    password: '',
    repeatedPassword: '',
    token: ''
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case RESTART_LOGIN:
      return {
        ...state,
        restartLogin: action.payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        userLoggedIn: true
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginFailure: action.payload
      };
    case LOGIN_INITIALISED:
      return {
        ...state,
        loginInitialised: true
      };
    case LOGIN_REINITIALISE:
      return {
        ...state,
        loginInitialised: false
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        goToLogin: true
      };
    case RESET_PASSWORD_VALIDATION_FAILURE:
      return {
        ...state,
        passwordReset: action.passwordReset,
        errors: action.errors
      };
    case FORGOT_PASSWORD:
      return {
        ...state
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true
      };
    case FORGOT_PASSWORD_SENT:
      return {
        ...state,
        sent: true
      };
    case FORGOT_PASSWORD_VALIDATION_FAILURE:
      return {
        ...state,
        errors: action.errors
      };
    default:
      return state;
  }
}

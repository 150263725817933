import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import classNames from 'classnames';
import logo from '../../images/logo.png';
import ErrorDialog from '../widgets/ErrorDialog';
import { forgotPassword } from '../../store/actions/AuthenticationActions';
import authStyles from '../../assets/authStyles';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import { loadingSelector } from '../../store/selectors/shared';
import NewValidatedField from '../widgets/NewValidatedField';
import NewActionButton from '../widgets/NewActionButton';
import { ReactComponent as RightArrowIcon } from '../../assets/images/icon-right-arrow.svg';

const styles = {
  ...authStyles
};

class ForgotPasswordView extends React.Component {
  state = {
    forgotPasswordModel: {
      username: ''
    }
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { forgotPasswordModel } = this.state;
    this.setState({
      forgotPasswordModel: {
        ...forgotPasswordModel,
        [name]: value
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { forgotPassword, history } = this.props;
    const { forgotPasswordModel } = this.state;
    const response = await forgotPassword(forgotPasswordModel);
    if (response) {
      if (response.value === 'LOGIN') history.push('/login?forgot=true');
      else history.push(`/passwordReset/${response.value}`);
    }
  };

  render() {
    const { classes, errors, isSaving } = this.props;
    const { forgotPasswordModel } = this.state;
    return (
      <Grid container justify="center" alignContent="center" alignItems="center" className={classes.container}>
        <Grid item xs={12} className={classes.minHeight}>
          <img src={logo} alt="Repple Logo" className={classes.logo} />
          <div className={classNames(classes.formContainer, classes.passwordContainer)}>
            {errors && errors.objectErrors && (
              <FormHelperText error>
                {errors.objectErrors.map(error => {
                  return error;
                })}
              </FormHelperText>
            )}

            <NewValidatedField
              id="username"
              name="username"
              label="Username"
              value={forgotPasswordModel.username}
              onChange={this.handleChange}
              validators={[VALIDATORS_BY_NAME.REQUIRED]}
              autoComplete="off"
              errorText={errors && errors.fieldErrors.username}
            />

            <div className={classes.bottomFields}>
              <Link to="/login" className={classes.cancelLink}>
                Cancel
              </Link>
              <NewActionButton
                onChange={this.handleSubmit}
                text="Reset Password"
                icon={<RightArrowIcon />}
                primary
                filled
                isSaving={isSaving}
                style={{ width: '234px' }}
              />
            </div>
          </div>
        </Grid>
        <ErrorDialog />
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.authentication.errors,
  isSaving: loadingSelector(state, 'forgotPassword')
});

ForgotPasswordView.propTypes = {
  classes: PropTypes.object,
  isSaving: PropTypes.bool,
  history: PropTypes.object,
  forgotPassword: PropTypes.func,
  errors: PropTypes.object
};

export default connect(mapStateToProps, { forgotPassword })(withStyles(styles)(ForgotPasswordView));

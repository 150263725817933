import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { entitySelector } from '../../store/selectors/entity';
import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';

const UserListItemBreadcrumb = ({ user, isLoading }) => (
  <GenericBreadcrumbItem mainText={`${user.firstName} ${user.lastName}`} subText="User" isLoading={isLoading} />
);

const mapStateToProps = state => ({
  ...entitySelector(state, 'user'),
  isLoading: loadingSelector(state, 'getEntity')
});

UserListItemBreadcrumb.propTypes = {
  user: PropTypes.object,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(UserListItemBreadcrumb);

import { NO_SEARCH_VALUE } from '../constants/global';
import { convertLocalDateStringToUtcDateString } from './utilities';

export const getUsersUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_USER}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getHoldersUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_HOLDER}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getSearchFormsUrl = ({ currentPage, pageSize, searchText, filters }) => {
  const statusFilter = filters.status || NO_SEARCH_VALUE;

  return `${process.env.REACT_APP_FORM_LIST}${currentPage}/${pageSize}/${statusFilter}?searchText=${searchText}`;
};

export const getSearchCredentialRequestsUrl = ({ currentPage, pageSize, searchText, filters }) => {
  const statusFilter = filters.status || NO_SEARCH_VALUE;
  const priorityFilter = filters.priority || NO_SEARCH_VALUE;

  /* eslint-disable max-len */
  return `${process.env.REACT_APP_CREDENTIAL_REQUEST_LIST}${currentPage}/${pageSize}/${statusFilter}/${priorityFilter}?searchText=${searchText}`;
  /* eslint-enable max-len */
};

export const getSearchCredentialsUrl = ({ currentPage, pageSize, searchText, filters }) => {
  /* eslint-disable max-len */
  const credentialTypeFilter = filters.credentialType || NO_SEARCH_VALUE;

  return `${process.env.REACT_APP_CREDENTIAL_LIST}${currentPage}/${pageSize}/${credentialTypeFilter}?searchText=${searchText}`;
  /* eslint-enable max-len */
};

export const getOrganisationMembersUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_ORGANISATION_MEMBER}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getWorkPlaceUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_WORK_PLACE}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

/* eslint-disable max-len */
export const getSearchCredentialTypesUrl = ({ currentPage, pageSize, searchText, filters }) => {
  const issuerFilter = filters.issuer || NO_SEARCH_VALUE;
  return `${process.env.REACT_APP_LIST_SEARCH_CREDENTIAL_TYPE}${currentPage}/${pageSize}/${issuerFilter}?searchText=${searchText}`;
};
/* eslint-disable max-len */

export const getQualificationUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_QUALIFICATION}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getCredentialTypeUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_CREDENTIAL_TYPE}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getCPDUrl = ({ currentPage, pageSize, searchText, filters }, additionalSearchParams) => {
  const organisationFilter = filters.organisation || NO_SEARCH_VALUE;

  let url = `${process.env.REACT_APP_GET_CREDENTIAL_TYPE}${additionalSearchParams.id}/cpd/list/${currentPage}/${pageSize}/${organisationFilter}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getEmailsUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_EMAIL}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getSearchAttendancesUrl = ({ currentPage, pageSize, searchText, filters }) => {
  const workPlaceFilter = filters.workPlace || NO_SEARCH_VALUE;
  const startDateFilter = filters.dates.startDate;
  const endDateFilter = filters.dates.endDate;
  const startDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', startDateFilter);
  const endDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', endDateFilter);

  return (
    `${process.env.REACT_APP_ATTENDANCE_LIST}${currentPage}/${pageSize}/${workPlaceFilter}?searchText=${searchText}` +
    `&startDate=${startDate}&endDate=${endDate}`
  );
};

export const getSearchCoursesUrl = ({ currentPage, pageSize, searchText, filters }) => {
  const statusFilter = filters.status || NO_SEARCH_VALUE;
  const internalFilter = filters.internal || NO_SEARCH_VALUE;
  const locationTypeFilter = filters.location || NO_SEARCH_VALUE;

  return (
    `${process.env.REACT_APP_COURSE_LIST}${currentPage}/${pageSize}` +
    `/${statusFilter}/${internalFilter}/${locationTypeFilter}?searchText=${searchText}`
  );
};

export const getCrowdsourceOrganisationUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_CROWDSOURCE_ORGANISATION}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getCrowdsourceCredentialTypeUrl = ({ currentPage, pageSize, searchText }) => {
  let url = `${process.env.REACT_APP_LIST_CROWDSOURCE_CREDENTIAL_TYPE}${currentPage}/${pageSize}`;

  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getWorkPlaceMemberUrl = ({ currentPage, pageSize, searchText }, additionalSearchParams) => {
  let url = `${process.env.REACT_APP_GET_WORK_PLACE}${additionalSearchParams.id}/member/list/${currentPage}/${pageSize}`;
  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

export const getObservationUrl = ({ currentPage, pageSize, searchText }, additionalSearchParams) => {
  let url = `${process.env.REACT_APP_GET_WORK_PLACE}list/${additionalSearchParams.id}/observation/${currentPage}/${pageSize}`;
  if (searchText) {
    url += `?searchText=${searchText}`;
  }

  return url;
};

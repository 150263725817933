import { createSelector } from 'reselect';

export const getAuthentication = state => state.authentication;

export const authenticationDetailsSelector = createSelector(
  getAuthentication,
  ({ authenticationDetails, error, externalLogin, loginFailure, restartLogin }) => ({
    authenticationDetails,
    error,
    externalLogin,
    loginFailure,
    restartLogin
  })
);

export const passwordResetSelector = createSelector(
  getAuthentication,
  ({ passwordReset, errors, success, sent, goToLogin }) => ({
    passwordReset,
    errors,
    success,
    sent,
    goToLogin
  })
);

export const userLoggedInSelector = createSelector(
  getAuthentication,
  ({ userLoggedIn, loginInitialised, externalLogin }) => ({
    userLoggedIn,
    loginInitialised,
    externalLogin
  })
);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { userLoggedInSelector } from '../../store/selectors/authentication';

class PrivateRoute extends Component {
  state = {
    loggedInNow: false
  };

  static getDerivedStateFromProps(props, prevProps) {
    const { userLoggedIn: previousUserLoggedIn } = prevProps;
    const { userLoggedIn } = props;
    if ((!previousUserLoggedIn && userLoggedIn) || (previousUserLoggedIn && !userLoggedIn)) {
      return { loggedInNow: userLoggedIn };
    }
    return props;
  }

  render() {
    const { location, loginInitialised, component: Component, ...rest } = this.props;
    const { loggedInNow } = this.state;

    return loginInitialised ? (
      <Route
        {...rest}
        /* eslint-disable no-confusing-arrow */
        render={props =>
          loggedInNow ? <Component {...props} /> : <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }
      />
    ) : (
      <div />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  location: PropTypes.object,
  loginInitialised: PropTypes.bool
};

const mapStateToProps = state => ({
  ...userLoggedInSelector(state)
});

export default compose(connect(mapStateToProps))(PrivateRoute);

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-dates';
import { START_DATE } from 'react-dates/constants';

import SelectWithOptions from '../control/SelectWithOptions';

import { border, sizes, colors, dateFilterPresets } from '../../assets/constants';
import { datePresets, today } from './dateRangePickerConfig';
import '../../assets/override.css';

const styles = {
  dateRangePickerContainer: {
    position: 'relative'
  },
  dateSelect: {
    margin: 0,
    width: '210px',
    position: 'relative',
    background: colors.white
  },
  dateRangePickerResult: {
    borderTop: border.style_1px,
    borderLeft: border.style_1px,
    width: '100%',
    top: '100%',
    padding: '3px 0',
    '& > div > div > div:first-of-type': {
      width: '102px'
    },
    '& > div > div > div:last-of-type': {
      width: '102px'
    }
  },
  selectedDays: {
    color: colors.fontGreyLight,
    fontSize: sizes.fontSmall,
    padding: '0 12px',
    textAlign: 'center'
  }
};

// Set the default date range to "TODAY"
const defaultDate = datePresets[0];
export const initialState = {
  focusedInput: null,
  startDate: defaultDate.start,
  endDate: defaultDate.end,
  preset: defaultDate.type
};

class PresetDateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    // Inherit startDate and endDate if passed to the component.
    const { startDate, endDate } = props;
    /* eslint-disable */
    const inheritedState =
      startDate && endDate
        ? {
            startDate: props.startDate,
            endDate: props.endDate,
            days: this.getDays(props.startDate, props.endDate),
            preset: this.getInitialPreset()
          }
        : {};
    /* eslint-enable */
    this.state = {
      ...initialState,
      ...inheritedState
    };
  }

  getInitialPreset = () => {
    const { startDate, endDate } = this.props;
    const preset = datePresets.find(item => {
      if (item.start && item.end) {
        return item.start.isSame(startDate, 'day') && item.end.isSame(endDate, 'day');
      }
      return false;
    });
    return preset ? preset.type : dateFilterPresets.CUSTOM_RANGE;
  };

  getDays = (startDate, endDate) => (endDate ? endDate.diff(startDate, 'days') + 1 : '');

  onDatesChange = ({ startDate, endDate, inputPreset }) => {
    const preset = inputPreset || this.state.preset;
    const days = this.getDays(startDate, endDate);
    this.setState({ startDate, endDate });
    if (startDate) {
      startDate = startDate.startOf('day');
    }
    if (endDate) {
      endDate = endDate.endOf('day');
    }

    if (!!startDate && !!endDate) {
      this.props.onSelectDate({
        startDate,
        endDate,
        preset,
        days
      });
    }
  };

  handleDatePickerFocusChange = input =>
    this.setState({
      focusedInput: input,
      preset: dateFilterPresets.CUSTOM_RANGE
    });

  handleChangePreset = event => {
    const { value } = event.target;

    if (value === dateFilterPresets.CUSTOM_RANGE) {
      this.setState({
        focusedInput: START_DATE,
        preset: value,
        startDate: null,
        endDate: null
      });

      return;
    }

    this.setState({
      preset: value
    });

    this.onDatesChange({
      startDate: datePresets.find(item => item.type === value).start,
      endDate: datePresets.find(item => item.type === value).end,
      inputPreset: value
    });
  };

  render() {
    const { classes, className } = this.props;
    const { preset, focusedInput, startDate, endDate } = this.state;

    return (
      <div className={classNames(classes.dateRangePickerContainer, className)}>
        <SelectWithOptions
          overrideClassName={classes.dateSelect}
          label="Date Range"
          value={preset}
          handleChange={this.handleChangePreset}
          values={datePresets}
          classes={classes.formControl}
          id="preset"
          optionName="text"
          optionValue="type"
          inputConfig={{ disableUnderline: true }}
          selectConfig={{ classes: { select: classes.select } }}
        />
        <div className={classes.dateRangePickerResult}>
          <DateRangePicker
            startDate={startDate}
            startDateId="state_date_element"
            endDate={endDate}
            endDateId="end_date_element"
            onDatesChange={this.onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={this.handleDatePickerFocusChange}
            displayFormat="DD-MM-YYYY"
            isOutsideRange={day => moment().diff(day) < 0}
            minimumNights={0}
            initialVisibleMonth={() => today.clone().subtract(1, 'month')}
            hideKeyboardShortcutsPanel
            noBorder
            small
            withPortal
          />
        </div>
      </div>
    );
  }
}

PresetDateRangePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  className: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object
};

export default withStyles(styles)(PresetDateRangePicker);

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { roleInitialState, userInitialState } from '../entityInitialStates';

export const listUserRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_USER}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'users'
  };
};

export const listAllUserRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_USER}`,
    entityName: 'users'
  };
};

export const listRoleRequest = () => {
  return {
    url: process.env.REACT_APP_ROLE_LIST,
    entityName: 'roles',
    initialState: []
  };
};

export const getUserRequest = id => ({
  url: `${process.env.REACT_APP_USER_GET}${id}`,
  entityName: 'user',
  initialState: userInitialState
});

export const saveUserRequest = (entity, currentHolder) => ({
  url: `${process.env.REACT_APP_USER_SAVE}?currentHolder=${currentHolder}`,
  entityName: 'user',
  entity
});

export const disableUserRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_USER}${id}/disable`,
  entityName: 'user',
  action: 'disabled'
});

export const enableUserRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_USER}${id}/enable`,
  entityName: 'user',
  action: 'enabled'
});

export const resetUserPasswordRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_USER}${id}/resetPassword`,
  entityName: 'user',
  notification: "The user's password has been reset"
});

export const resetFiledLoginAttemptRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_USER}${id}/resetLoginAttempts`,
  entityName: 'user',
  notification: 'The user login attempts has been reset'
});

export const getRoleRequest = id => ({
  url: `${process.env.REACT_APP_ROLE_GET}${id}`,
  entityName: 'role',
  initialState: roleInitialState
});

export const listPermissionRequest = () => {
  return {
    url: process.env.REACT_APP_PERMISSION_LIST,
    entityName: 'permissions',
    initialState: []
  };
};

export const deleteUserRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_USER}${id}`,
  entityName: 'user',
  action: 'deleted'
});

export const saveRoleRequest = entity => ({ url: process.env.REACT_APP_ROLE_SAVE, entityName: 'role', entity });

export const recreateCognitoUserRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_USER}${id}/cognito`,
  entityName: 'user',
  notification: 'The user has been recreated'
});

export const changePasswordRequest = entity => ({
  url: process.env.REACT_APP_CHANGE_PASSWORD,
  entityName: 'changePassword',
  action: 'password changed',
  entity
});

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { entitySelector } from '../../store/selectors/entity';

const CredentialHolderListItemBreadcrumb = ({ holderCredential, isLoading }) => {
  return (
    <GenericBreadcrumbItem
      mainText={holderCredential.credentialTypeName}
      subText={holderCredential.issuer}
      isLoading={isLoading}
    />
  );
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'holderCredential'),
  isLoading: loadingSelector(state, 'getEntity')
});

CredentialHolderListItemBreadcrumb.propTypes = {
  holderCredential: PropTypes.object,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(CredentialHolderListItemBreadcrumb);

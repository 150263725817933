//  Define styles for reoccurring elements in the app here
export const centerVertically = {
  top: '50%',
  transform: 'translateY(-50%)'
};

export const centerHorizontally = {
  left: '50%',
  transform: 'translateX(-50%)'
};

export const flexRowCentered = {
  display: 'flex',
  alignItems: 'center'
};

export const flexColCentered = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
};

import { createSelector } from 'reselect';

export const getDocument = state => state.document;

export const documentUploadsSelector = createSelector(getDocument, ({ documentUploads, fileUploads }) => ({
  documentUploads,
  fileUploads
}));

export const documentDownloadsSelector = createSelector(
  getDocument,
  ({ fileDownloads, credentialRequestFileDownloads }) => ({ fileDownloads, credentialRequestFileDownloads })
);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { downloadFile } from '../../store/actions/DocumentActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import DisplayCredential from '../credential/DisplayCredential';
import { CREDENTIAL_STATUS_TYPE } from '../../assets/constants';
import { convertUtcToLocal } from '../../utils/utilities';
import {
  getOrganisationCredentialCheckRequest,
  getCredentialCheckFileDownloadsRequest
} from '../../utils/entityRequests/credentialCheckRequests';
import { entityArraySelector, entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import NewSectionHeading from '../common/NewSectionHeading';

const styles = {
  ...layout,
  ...style,
  valid: {
    color: 'rgba(77, 167, 81)'
  },
  invalid: {
    color: 'rgba(239,83,80,1)'
  },
  expired: {
    color: 'rgba(255, 167, 38)'
  },
  credentialCheck: {
    marginRight: '4px'
  },
  pointer: {
    cursor: 'pointer'
  },
  credentialCheckContainer: {
    display: 'flex',
    marginTop: '8px',
    marginLeft: '12px',
    justifyContent: 'space-between',
    '& div': {
      display: 'flex'
    },
    '& div > div:last-of-type': {
      marginTop: '2px'
    }
  },
  marginTop: {
    marginTop: '20px'
  }
};

class OrganisationCredentialCheckView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getOrganisationCredentialCheckRequest(id));
  }

  renderMarkers = (map, maps, credentialCheck) => {
    const marker = new maps.Marker({
      position: {
        lat: parseFloat(credentialCheck.position.latitude),
        lng: parseFloat(credentialCheck.position.longitude)
      },
      map
    });
    return marker;
  };

  handleDownloadFile = (url, fileName, openInNewTab) => {
    const { downloadFile } = this.props;
    downloadFile(url, fileName, openInNewTab);
  };

  handleShowCredentialDetails = async credentialId => {
    const { organisationCredentialCheck, getEntity } = this.props;

    const credentialCheck = organisationCredentialCheck.checkedCredentials.find(
      item => item.credentialBean && item.credentialBean.documentId === credentialId
    );

    getEntity(
      getCredentialCheckFileDownloadsRequest(
        organisationCredentialCheck.identifier,
        credentialCheck.credentialBean.documentId
      )
    );

    this.setState({ showCredential: credentialCheck.credentialBean });
  };

  render() {
    const { classes, organisationCredentialCheck, fileDownloads } = this.props;
    const { showCredential } = this.state;

    return (
      Object.keys(organisationCredentialCheck).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <Grid item lg={6} md={12}>
            <NewHeaderViewActions header="Task Assessment Details" cancelUrl="/workplace/organisationCredentialCheck" />
            <DisplayField heading="Holder Name" value={organisationCredentialCheck.holderName} />
            <DisplayField heading="Validating Member Name" value={organisationCredentialCheck.validatingHolderName} />
            <DisplayField
              heading="Check Time"
              value={convertUtcToLocal(
                'DD-MM-YYYY HH:mm:ss',
                'DD-MM-YYYY HH:mm:ss',
                organisationCredentialCheck.createTime
              )}
            />
            <DisplayField heading="Latitude" value={organisationCredentialCheck.position.latitude || 'Not Set'} />
            <DisplayField heading="Longitude" value={organisationCredentialCheck.position.longitude || 'Not Set'} />
            <DisplayField
              heading="Status"
              value={
                organisationCredentialCheck.status
                  ? CREDENTIAL_STATUS_TYPE[organisationCredentialCheck.status].label
                  : ''
              }
            />
            {organisationCredentialCheck.qualificationName && (
              <DisplayField heading="Qualification" value={organisationCredentialCheck.qualificationName} />
            )}
            {organisationCredentialCheck.reasonName && (
              <DisplayField heading="Reason" value={organisationCredentialCheck.reasonName} />
            )}
            {organisationCredentialCheck.reference && (
              <DisplayField heading="Reference" value={organisationCredentialCheck.reference} />
            )}
            <NewSectionHeading heading="Check Results" />
            <div>
              {organisationCredentialCheck.checkedCredentials.map(item => (
                <div
                  key={`checkedCredential-${item.credentialTypeName}`}
                  className={classNames(classes.credentialCheckContainer, {
                    [classes.pointer]: item.status !== CREDENTIAL_STATUS_TYPE.IN_VALID.value
                  })}
                  onClick={() => {
                    if (item.status !== CREDENTIAL_STATUS_TYPE.IN_VALID.value) {
                      this.handleShowCredentialDetails(item.credentialBean.documentId);
                    }
                  }}
                >
                  <div>
                    <div className={classes.credentialCheck}>
                      <i
                        className={classNames('material-icons', {
                          [classes.valid]: item.status === CREDENTIAL_STATUS_TYPE.VERIFIED.value,
                          [classes.invalid]: item.status === CREDENTIAL_STATUS_TYPE.IN_VALID.value,
                          [classes.expired]: item.status === CREDENTIAL_STATUS_TYPE.EXPIRED.value
                        })}
                      >
                        {CREDENTIAL_STATUS_TYPE[item.status].icon}
                      </i>
                    </div>
                    <div className={classes.displayField}>{item.credentialTypeName}</div>
                  </div>
                  {item.status !== CREDENTIAL_STATUS_TYPE.IN_VALID.value && (
                    <i className={classNames('material-icons', classes.greyIcon)}>visibility</i>
                  )}
                </div>
              ))}
            </div>
            {showCredential && (
              <div className={classes.marginTop}>
                <NewSectionHeading heading={`Credential Details - ${showCredential.credentialTypeName}`} />
                <DisplayField heading="Expiry Date" value={showCredential.expiryDate || 'Not Set'} />
                <DisplayCredential
                  credential={showCredential}
                  fileDownloads={fileDownloads || []}
                  downloadFile={this.handleDownloadFile}
                />
              </div>
            )}
          </Grid>
          <Grid item lg={6} md={12}>
            {organisationCredentialCheck.position.latitude && organisationCredentialCheck.position.longitude && (
              <div className={classes.googleMap}>
                {/*<GoogleMapReact*/}
                {/*  bootstrapURLKeys={{ key: window.reppleEnv.GOOGLE_KEY }}*/}
                {/*  defaultCenter={{*/}
                {/*    lat: parseFloat(organisationCredentialCheck.position.latitude),*/}
                {/*    lng: parseFloat(organisationCredentialCheck.position.longitude)*/}
                {/*  }}*/}
                {/*  defaultZoom={15}*/}
                {/*  yesIWantToUseGoogleMapApiInternals*/}
                {/*  onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps, organisationCredentialCheck)}*/}
                {/*/>*/}
              </div>
            )}
          </Grid>
        </Grid>
      )
    );
  }
}

OrganisationCredentialCheckView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  downloadFile: PropTypes.func,
  organisationCredentialCheck: PropTypes.object,
  fileDownloads: PropTypes.array
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'organisationCredentialCheck'),
  ...entityArraySelector(state, 'fileDownloads')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, downloadFile })
)(OrganisationCredentialCheckView);

import { colors } from './constants';
import { commonButtonStyles } from './buttons';

export default {
  imageContainer: {
    width: '345px',
    height: '120px',
    border: 'dashed 1px rgba(135, 152, 173, 0.3)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.grey4,
    backgroundColor: colors.white,
    flexDirection: 'column',
    '& div': {
      textAlign: 'center'
    }
  },
  imageContainerActive: {
    fontWeight: 'bold'
  },
  imageSize: {
    fontSize: '9pt',
    marginTop: '5px'
  },
  container: {
    position: 'relative'
  },
  actionButton: {
    ...commonButtonStyles,
    color: colors.grey4,
    marginTop: '8px'
  },
  credentialField: {
    position: 'absolute',
    overflowX: 'hidden',
    fontSize: '10pt',
    height: '25px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  layout: {
    display: 'flex',
    flexDirection: 'column'
  },
  fieldProperties: {
    marginLeft: '20px',
    width: '90%'
  },
  fieldButtonContainer: {
    width: '300px',
    marginLeft: '20px',
    justifyContent: 'space-between',
    display: 'flex'
  },
  imageDeleteContainer: {
    display: 'flex',
    alignItems: 'flex-start'
    // '& > i': {
    //   marginLeft: '8px'
    // }
  },
  hidden: {
    display: 'none !important'
  },
  logoContainer: {
    width: '345px',
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3E4042',
    borderRadius: '5px 5px 0 0',
    marginTop: '15px'
  },
  marginTop: {
    marginTop: '15px',
    paddingTop: '15px'
  },
  imageSizeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
};

import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import ls from 'local-storage';
import { MuiThemeProvider } from '@material-ui/core/styles';
import configureStore from './store/Store';
import layoutRoutes from './routes/layoutRoutes';
import renderRoutes from './routes/renderRoutes';

import {
  FORGOT_PASSWORD_SENT,
  FORGOT_PASSWORD_VALIDATION_FAILURE,
  LOGIN_FAILURE,
  LOGIN_INITIALISED,
  LOGIN_SUCCESS,
  RESTART_LOGIN,
  UPDATE_SERVICE_WORKER
} from './store/actions/types';
// import registerServiceWorker from './registerServiceWorker';
import { isLoggedIn } from './utils/utilities';
import { customTheme } from './assets/constants';

const store = configureStore();

const onUpdate = () => {
  store.dispatch({ type: UPDATE_SERVICE_WORKER });
};

Auth.configure({
  Auth: {
    mandatorySignIn: true,
    region: window.reppleEnv.cognito.REGION,
    userPoolId: window.reppleEnv.cognito.USER_POOL_ID,
    userPoolWebClientId: window.reppleEnv.cognito.APP_CLIENT_ID,
    oauth: {
      redirectSignIn: window.reppleEnv.cognito.REDIRECT_SIGN_IN,
      domain: window.reppleEnv.cognito.DOMAIN,
      redirectSignOut: window.reppleEnv.cognito.REDIRECT_SIGN_OUT,
      responseType: 'code'
    }
  }
});

class App extends React.Component {
  state = {
    initialised: false
  };

  constructor(props) {
    super(props);

    Hub.listen('auth', async data => {
      // eslint-disable-next-line no-console
      console.log(`APP THE EVENT IS: ${data.payload.event}`);
      // eslint-disable-next-line no-console
      switch (data.payload.event) {
        case 'signIn':
          // eslint-disable-next-line no-console
          console.log('****************** SIGN IN ********************');
          Auth.currentSession().then(item => {
            const payload = item.idToken.payload;
            ls.remove('authenticating');
            if (payload.key) ls.set('key', payload.key);
            ls.set('userName', payload.email);
            ls.set('reppleOrganisation', payload.reppleOrganisation === 'true');
            ls.set('fullName', payload.fullName);
            if (payload.organisationName) ls.set('organisationName', payload.organisationName);

            if (payload.authorities) ls.set('authorities', payload.authorities.split(','));
            else {
              // ls.clear();
              store.dispatch({
                type: LOGIN_FAILURE,
                payload: 'Your user does not have access to this application'
              });
              return;
            }
            if (payload.homeKey) ls.set('homeKey', payload.homeKey);
            ls.set('competenceCapability', payload.competenceCapability === 'true');
            ls.set('trainingCapability', payload.trainingCapability === 'true');
            ls.set('assuranceCapability', payload.assuranceCapability === 'true');
            ls.set('token', item.idToken.jwtToken);
            ls.set('accessToken', item.accessToken.jwtToken);
            ls.set('refreshToken', item.refreshToken.token);
            ls.set('userId', payload.sub);
            ls.set('tokenExpiry', payload.exp * 1000);
            window.location.href = '/';
          });
          store.dispatch({
            type: LOGIN_FAILURE,
            payload: ''
          });
          store.dispatch({ type: RESTART_LOGIN, payload: false });
          // eslint-disable-next-line no-console
          console.log('*********************************************');
          break;
        case 'signOut':
          const usernameAuth = ls.get('usernameAuth');
          ls.clear();

          if (usernameAuth) {
            window.location.href = '/';
          }

          // eslint-disable-next-line no-console
          console.log('************************************* SIGN OUT');
          break;
        case 'oAuthSignOut':
          // eslint-disable-next-line no-console
          console.log('************************************* OAUTH SIGN OUT');
          // eslint-disable-next-line no-console
          console.log(data.payload);
          // eslint-disable-next-line no-console
          console.log('*********************************************');
          break;
        case 'signIn_failure':
          ls.remove('authenticating');
          // eslint-disable-next-line no-console
          console.log('************************************* SIGN IN FAILURE');
          // eslint-disable-next-line no-console
          console.log(data.payload);
          // eslint-disable-next-line no-console
          console.log('*****************************************************');
          if (data.payload.data.message.startsWith('Already+found+an+entry+for+username')) {
            store.dispatch({ type: RESTART_LOGIN, payload: true });
          } else {
            store.dispatch({
              type: LOGIN_FAILURE,
              payload: data.payload.data.message.replace(/PreSignUp\+failed\+with\+error\+/g, '').replace(/\+/g, ' ')
            });
          }
          break;
        case 'forgotPasswordSubmit_failure':
          store.dispatch({ type: FORGOT_PASSWORD_VALIDATION_FAILURE, error: data.payload.data.message });
          break;
        case 'forgotPasswordSubmit':
          store.dispatch({ type: FORGOT_PASSWORD_SENT });
          break;
        default:
          break;
      }
    });
  }

  async componentWillMount() {
    const loggedIn = await isLoggedIn();
    this.setState({ initialised: true });

    if (loggedIn) {
      store.dispatch({ type: LOGIN_SUCCESS });
    }
    store.dispatch({ type: LOGIN_INITIALISED });
  }

  render() {
    const { initialised } = this.state;
    // return initialised ? (
    return (
      <MuiThemeProvider theme={customTheme}>
        <Provider store={store}>
          <BrowserRouter>
            <Switch>{renderRoutes(layoutRoutes)}</Switch>
          </BrowserRouter>
        </Provider>
      </MuiThemeProvider>
    );
    // ) : null;
  }
}

// registerServiceWorker(onUpdate);

export default App;

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { organisationMemberInitialState } from '../entityInitialStates';

export const listOrganisationMemberRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_ORGANISATION_MEMBER}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'organisationMembers'
  };
};

export const getOrganisationMemberRequest = id => ({
  url: `${process.env.REACT_APP_GET_ORGANISATION_MEMBER}${id}`,
  entityName: 'organisationMember',
  initialState: organisationMemberInitialState
});

export const removeMemberFromOrganisationRequest = id => ({
  url: `${process.env.REACT_APP_GET_ORGANISATION_MEMBER}${id}`,
  entityName: 'organisationMember',
  notification: 'The member has been removed from the organisation'
});

export const searchOrganisationMemberRequest = searchText => ({
  url: `${process.env.REACT_APP_SEARCH_ORGANISATION_MEMBER}?searchText=${encodeURIComponent(searchText)}`,
  entityName: 'organisationMemberSearch',
  initialState: {
    identifier: '',
    name: ''
  }
});

export const inviteOrganisationMemberRequest = entity => ({
  url: process.env.REACT_APP_INVITE_ORGANISATION_MEMBER,
  entityName: 'organisationMember',
  entity,
  notification: 'The member has been invited'
});

export const listValidatingOrganisationMemberRequest = () => ({
  url: process.env.REACT_APP_LIST_ORGANISATION_MEMBER,
  entityName: 'validatingOrganisationMembers',
  initialState: []
});

export const joinOrganisationMemberRequest = uuid => ({
  url: `${process.env.REACT_APP_JOIN_ORGANISATION_MEMBER}${uuid}`,
  entityName: 'organisationMemberJoinResult'
});

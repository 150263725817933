import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { breadCrumbItemSelector } from '../../store/selectors/table';
import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';

const GenericListItemBreadcrumb = ({ item, isLoading, itemName, heading }) => (
  <GenericBreadcrumbItem mainText={_.get(item.breadcrumbItem, itemName, '')} subText={heading} isLoading={isLoading} />
);

const mapStateToProps = (state, props) => ({
  item: breadCrumbItemSelector(state, props.reducer, props.propertyName),
  isLoading: loadingSelector(state, props.functionName)
});

GenericListItemBreadcrumb.propTypes = {
  form: PropTypes.object,
  isLoading: PropTypes.bool,
  item: PropTypes.object,
  reducer: PropTypes.string.isRequired,
  functionName: PropTypes.string.isRequired,
  propertyName: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(GenericListItemBreadcrumb);

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { entitySelector } from '../../store/selectors/entity';

const OrganisationMemberListItemBreadcrumb = ({ organisationMember, isLoading }) => (
  <GenericBreadcrumbItem
    mainText={`${organisationMember.user && organisationMember.user.firstName} ${organisationMember.user &&
      organisationMember.user.lastName}`}
    subText="Organisation Member"
    isLoading={isLoading}
  />
);

const mapStateToProps = state => ({
  ...entitySelector(state, 'organisationMember'),
  isLoading: loadingSelector(state, 'getEntity')
});

OrganisationMemberListItemBreadcrumb.propTypes = {
  organisationMember: PropTypes.object,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(OrganisationMemberListItemBreadcrumb);

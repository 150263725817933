/* eslint-disable react/no-danger */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Chip, Button, Popover, Tooltip, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { loadingSelector } from '../../../store/selectors/shared';
import { getEntity } from '../../../store/actions/EntityActions';
import { style } from '../../../assets/style';
import {
  getCatalogCourseRequest,
  getCatalogCourseScheduleRequest
} from '../../../utils/entityRequests/catalogRequests';
import { entityArraySelector, entityWithErrorsSelector } from '../../../store/selectors/entity';
import { colors, LOCATION_TYPE, PERIOD_TYPE } from '../../../assets/constants';
import SectionHeading from '../../common/SectionHeading';
import { convertUtcToLocal } from '../../../utils/utilities';

const styles = {
  ...style,
  imageDetailsContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  courseDetails: {
    display: 'flex',
    flexDirection: 'column'
  },
  courseDetail: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    marginTop: '10px'
  },
  chip: {
    marginTop: '6px',
    borderRadius: '7px',
    marginLeft: '7px',
    backgroundColor: colors.orangeDark,
    color: colors.white,
    fontWeight: '500'
  },
  courseContent: {
    marginLeft: '18px',
    marginTop: '8px',
    fontSize: '15px'
  },
  scheduledCourseItem: {
    marginRight: '15px'
  },
  button: {
    marginTop: '18px',
    marginLeft: '18px'
  },
  popOverActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  popOverDetails: {
    minWidth: '900px',
    minHeight: '500px',
    flexDirection: 'column',
    display: 'flex'
  },
  popOverButtons: {
    marginBottom: '20px',
    marginRight: '15px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  scheduledSessions: {
    overflowX: 'auto'
  },
  rowSplit: {
    justifyContent: 'space-between'
  }
};

class CatalogCourseView extends React.Component {
  state = {
    selectedCourse: '',
    anchor: null
  };

  async componentWillMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    const course = await getEntity(getCatalogCourseRequest(id));

    if (course.locationType === LOCATION_TYPE.IN_CLASSROOM.id) {
      await getEntity(getCatalogCourseScheduleRequest(id));
    }
  }

  handlePopOverClose = () => {
    this.setState({
      selectedCourse: '',
      anchor: null
    });
  };

  handleSelectCourse = (scheduledCourse, event) => {
    this.setState({
      selectedCourse: scheduledCourse,
      anchor: event.currentTarget
    });
  };

  handleEnrolCourse = scheduledCourse => {
    const { history, match } = this.props;
    const { params } = match;
    const { id } = params;

    history.push(`/catalog/courseCatalog/view/${id}/enrol/${scheduledCourse.identifier}`);
  };

  render() {
    const { classes, course, scheduledCourses } = this.props;
    const { selectedCourse, anchor } = this.state;
    return (
      <Grid container className={classNames(classes.mainContentColumn)}>
        <Grid
          container
          className={`${classes.viewPage} ${classes.page}`}
          alignContent="flex-start"
          justify="flex-start"
        >
          <Grid item xs={6}>
            <div className={classes.imageDetailsContainer}>
              <div>
                <img src={course.imageUrl} width={450} height={300} alt="Course Display" />
              </div>
              <div className={classes.courseDetails}>
                <div className={classes.courseDetail}>
                  <div className={classNames(classes.displayLabel)}>Provider</div>
                  <div className={classNames(classes.detail, classes.displayField)}>
                    {course.organisation && course.organisation.name}
                  </div>
                </div>
                <div className={classes.courseDetail}>
                  <div className={classNames(classes.displayLabel)}>Study Method</div>
                  <div className={classNames(classes.detail, classes.displayField)}>
                    {course.locationType && LOCATION_TYPE[course.locationType].label}
                  </div>
                </div>
                <div className={classes.courseDetail}>
                  <div className={classNames(classes.displayLabel)}>Duration</div>
                  <div className={classNames(classes.detail, classes.displayField)}>
                    {course.periodType && `${course.duration} ${PERIOD_TYPE[course.periodType].label}`}
                  </div>
                </div>
                <div className={classes.courseDetail}>
                  <div className={classNames(classes.displayLabel)}>Cost</div>
                  <div className={classNames(classes.detail, classes.displayField)}>{course.cost}</div>
                </div>
                {course.keywords && course.keywords.length > 0 && (
                  <div className={classes.courseDetail}>
                    <div className={classNames(classes.displayLabel)}>Keywords</div>
                    <div className={classNames(classes.detail, classes.displayField)}>
                      {course.keywords &&
                        course.keywords.map(item => (
                          <Chip className={classes.chip} label={item.label} key={item.label} />
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={6} direction="column">
              <SectionHeading heading="Course Overview" underlined />
              <div className={classes.courseContent}>{course.blurb}</div>
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid container item xs={6} direction="column">
              <SectionHeading heading="Course Description" underlined />
              <div className={classes.courseContent} dangerouslySetInnerHTML={{ __html: course.description }} />
            </Grid>
          </Grid>
          {course.locationType === LOCATION_TYPE.IN_CLASSROOM.id && (
            <Grid container item xs={12}>
              <Grid container item xs={6} direction="column">
                <SectionHeading heading="Scheduled Courses" underlined />
                {(scheduledCourses || []).map(scheduledCourse => (
                  <div className={classes.scheduledCourse} key={`scheduledCourse-${scheduledCourse.identifier}`}>
                    <div className={classNames(classes.rowDirection, classes.rowSplit)}>
                      <div className={classes.rowDirection}>
                        <div className={classNames(classes.displayLabelAndField, classes.scheduledCourseItem)}>
                          <label className={classes.displayLabel}>Start Date</label>
                          <div className={classes.displayField}>
                            {convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledCourse.startTime)}
                          </div>
                        </div>
                        <div className={classNames(classes.displayLabelAndField, classes.scheduledCourseItem)}>
                          <label className={classes.displayLabel}>End Date</label>
                          <div className={classes.displayField}>
                            {convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledCourse.endTime)}
                          </div>
                        </div>
                        <div className={classNames(classes.displayLabelAndField, classes.scheduledCourseItem)}>
                          <label className={classes.displayLabel}>Remaining Places</label>
                          <div className={classes.displayField}>
                            {scheduledCourse.capacity - scheduledCourse.numberHolders}
                          </div>
                        </div>
                      </div>
                      <div className={classes.rowDirection}>
                        <div className={classNames(classes.scheduledCourseItem, classes.button)}>
                          <Button
                            data-qa="ok"
                            className={classes.submit}
                            variant="contained"
                            color="primary"
                            onClick={event => this.handleSelectCourse(scheduledCourse, event)}
                          >
                            Details
                          </Button>

                          <Button
                            data-qa="ok"
                            className={classes.submit}
                            variant="contained"
                            color="primary"
                            onClick={() => this.handleEnrolCourse(scheduledCourse)}
                          >
                            Enrol
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>
        {!!selectedCourse && (
          <Popover
            open={!!selectedCourse}
            onClose={this.handlePopOverClose}
            anchorEl={anchor}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            className={classes.popOverWidth}
          >
            <div className={classes.popOverDetails}>
              <div className={classes.popOverActions}>
                <Tooltip title="Close" aria-label="add" placement="bottom">
                  <IconButton onClick={this.handlePopOverClose}>
                    <i className="material-icons">clear</i>
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.sessionDetails}>
                <SectionHeading heading="Scheduled Course Sessions" underlined style={{ paddingTop: 0 }} />
              </div>
              <div className={classes.scheduledSessions}>
                {selectedCourse.scheduledSessions.map(session => (
                  <div className={classes.scheduledCourse} key={`scheduledSession-${session.identifier}`}>
                    <div className={classes.rowDirection}>
                      <div className={classNames(classes.displayLabelAndField, classes.scheduledCourseItem)}>
                        <label className={classes.displayLabel}>Start Date</label>
                        <div className={classes.displayField}>
                          {convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', session.sessionTime)}
                        </div>
                      </div>
                      <div className={classNames(classes.displayLabelAndField, classes.scheduledCourseItem)}>
                        <label className={classes.displayLabel}>End Date</label>
                        <div className={classes.displayField}>
                          {convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', session.sessionEndTime)}
                        </div>
                      </div>
                      <div className={classNames(classes.displayLabelAndField, classes.scheduledCourseItem)}>
                        <label className={classes.displayLabel}>Location</label>
                        <div className={classes.displayField}>{session.centre.name}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.popOverButtons}>
              <Button
                data-qa="ok"
                className={classes.submit}
                variant="contained"
                color="primary"
                onClick={this.handlePopOverClose}
              >
                Close
              </Button>
            </div>
          </Popover>
        )}
      </Grid>
    );
  }
}

CatalogCourseView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  course: PropTypes.object,
  scheduledCourses: PropTypes.array,
  match: PropTypes.object,
  params: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'course'),
  ...entityWithErrorsSelector(state, 'course'),
  ...entityArraySelector(state, 'scheduledCourses')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity
  })
)(CatalogCourseView);

import { colors } from './constants';

export default {
  expandIconContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    color: colors.grey4,
    opacity: 0
  },
  panelSummary: {
    backgroundColor: 'transparent !important',
    '& > div': {
      flexDirection: 'column',
      marginTop: 0,
      marginBottom: 0
    }
  },
  controlPanel: {
    boxShadow: 'none',
    '&:hover': {
      '& $expandIconContainer': {
        opacity: '1'
      }
    },
    '&:before': {
      backgroundColor: colors.white
    }
  },
  controlPanelSelected: {
    '& $expandIconContainer': {
      opacity: '1'
    },
    boxShadow: '0 -2px 2px 0 rgba(0,0,0,0.2), 0 6px 10px 0 rgba(0,0,0,0.3)',
    borderLeft: `3px solid ${colors.orangeDark}`,
    borderTopLeftRadius: '0 !important',
    borderTopRightRadius: '0 !important',
    marginLeft: '-2px !important'
  },
  sectionContainer: {
    width: '100%',
    boxShadow: '0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24)',
    marginBottom: '30px'
  },
  panelContainer: {},
  titleContainer: {
    padding: '0 0px 0 24px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  titleContainerSelected: {
    borderLeft: `3px solid ${colors.orangeDark}`,
    marginLeft: '-1px !important'
  },
  menuItem: {
    width: '40px',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'transparent !important'
    },
    '&:after': {
      backgroundColor: 'transparent !important'
    }
  },
  controlsContainer: {
    paddingRight: '24px',
    width: '100%'
  },
  controlLabelAndTypeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'baseline'
  },
  typeControl: {
    marginTop: '16px',
    marginBottom: '8px',
    marginLeft: '10px',
    width: '200px'
  },
  radioControl: {
    '& $checked': {
      '& > div > label > span > span > div > svg': {
        color: `${colors.orangeDark} !important`
      }
    }
  },
  optionLabel: {
    fontSize: '14px'
  },
  optionControl: {
    marginTop: '0 !important'
  }
};

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { emailInitialState } from '../entityInitialStates';

export const listEmailRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_EMAIL}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'emails'
  };
};

export const getEmailRequest = id => ({
  url: `${process.env.REACT_APP_GET_EMAIL}${id}`,
  entityName: 'email',
  initialState: emailInitialState
});

export const resendEmailRequest = id => ({
  url: `${process.env.REACT_APP_GET_EMAIL}${id}`,
  entityName: 'user',
  notification: 'The email has been resent'
});

import ajax from '../../components/http/AjaxUtil';
import {
  SEARCH,
  CLEAR_SEARCH,
  CHANGE_SEARCH_FILTER,
  GLOBAL_ERROR,
  CREATE_INITIAL_SEARCH,
  SET_INITIAL_SEARCH_FILTER,
  CREATE_INITIAL_TABLE
} from './types';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { startLoading, stopLoading } from './LoadingActions';

export const search = (
  { name, searchText, pageSize, currentPage },
  getSearchUrl = () => {},
  additionalSearchParams = null
) => async (dispatch, getState) => {
  try {
    if (currentPage === 0) {
      dispatch(startLoading(`search-${name}`));
    }

    const { filters } = getState().search[name];
    /**
     * TODO: REMOVE the 'additionalSearchParams' logic with far less boilerplate using function closure - e.g:
     * In a view where we implement <ListingWithSearch just add a function getSearchUrl which will return another
     * Function which will then return the url string.
     * In component just add generateSearchUrl = ({ searchText, pageSize, currentPage, filters }) => {
     ** Here we will have access to all component specific properties and remove the need of additionalSearchParams
     *  getUrlFunc(allTheNecessaryParams)
     * }
     * ! Also getSearchUrl could be uplifted in the ListingWithSearch component
     */

    const url = getSearchUrl({ searchText, pageSize, currentPage, filters }, additionalSearchParams);
    const response = await ajax.get(url);

    dispatch({
      type: SEARCH,
      payload: {
        ...response.data,
        name,
        searchText,
        pageSize,
        currentPage
      }
    });
  } catch (e) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: { error_description: e.message }
    });
  } finally {
    dispatch(stopLoading(`search-${name}`));
  }
};

export const clearSearch = name => ({
  type: CLEAR_SEARCH,
  payload: { name }
});

export const changeSearchFilter = (name, filterName, filter = NO_SEARCH_VALUE) => dispatch =>
  dispatch({ type: CHANGE_SEARCH_FILTER, payload: { name, filterName, filter } });

export const setInitialSearchFilter = (name, filters) => async dispatch =>
  dispatch({ type: SET_INITIAL_SEARCH_FILTER, payload: { name, filters } });

export const createInitialSearch = name => async dispatch =>
  dispatch({ type: CREATE_INITIAL_SEARCH, payload: { name } });

export const createInitialTable = name => async dispatch => dispatch({ type: CREATE_INITIAL_TABLE, entityName: name });

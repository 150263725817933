import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { credentialTypeInitialState, formInitialState } from '../entityInitialStates';

export const listCredentialTypeRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_CREDENTIAL_TYPE}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'credentialType'
  };
};

export const saveCredentialTypeRequest = entity => ({
  url: process.env.REACT_APP_SAVE_CREDENTIAL_TYPE,
  entityName: 'credential type',
  entity
});

export const listIssuerCredentialTypeReferenceRequest = () => ({
  url: process.env.REACT_APP_LIST_CREDENTIAL_TYPE,
  entityName: 'credentialTypes',
  initialState: []
});

export const getCredentialTypeRequest = id => ({
  url: `${process.env.REACT_APP_GET_CREDENTIAL_TYPE}${id}`,
  entityName: 'credentialType',
  initialState: credentialTypeInitialState
});

export const listIssuerCredentialTypeRequest = (searchText = '') => (dispatch, getState) => {
  if (getState().search.credentialType) {
    const { filters } = getState().search.credentialType;
    const issuerFilter = filters.issuer || NO_SEARCH_VALUE;

    return {
      /* eslint-disable max-len */
      url: `${process.env.REACT_APP_LIST_SEARCH_CREDENTIAL_TYPE}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${issuerFilter}?searchText=${searchText}`,
      entityName: 'credentialType'
    };
  }
};

export const getCredentialTypeCredentialFormRequest = id => ({
  url: `${process.env.REACT_APP_GET_CREDENTIAL_TYPE}${id}/form`,
  entityName: 'form',
  initialState: formInitialState
});

export const listCategoryRequest = () => {
  return {
    url: `${process.env.REACT_APP_GET_CREDENTIAL_TYPE}category/list`,
    entityName: 'categories'
  };
};

export const listIndustryRequest = () => {
  return {
    url: `${process.env.REACT_APP_GET_CREDENTIAL_TYPE}industry/list`,
    entityName: 'industries'
  };
};

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { colors, globalSpacing } from '../../assets/constants';
import { flexRowCentered } from '../../assets/mixins';
import logoIcon from '../../assets/images/repple-top-logo.png';

const styles = () => ({
  brand: {
    ...flexRowCentered,
    justifyContent: 'center',
    width: `${globalSpacing * 1.5}px`,
    marginTop: '24px'
  },
  reppleHeader: {
    textDecoration: 'none',
    marginLeft: '10px',
    fontSize: '24px',
    fontWeight: 600,
    color: colors.blueDark,
    fontFamily: 'Encode Sans'
  }
});

const Brand = ({ classes, menuOpen }) => {
  return (
    <div className={classes.brand} key="brand">
      <img src={logoIcon} alt="Repple logo" width={36} height={40} />
      {menuOpen && <h2 className={classes.reppleHeader}>Repple</h2>}
    </div>
  );
};

Brand.propTypes = {
  classes: PropTypes.object,
  menuOpen: PropTypes.bool
};

export default withStyles(styles)(Brand);

import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Tooltip, Modal, Slide, FormHelperText, IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import color from 'color';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads } from '../../store/actions/DocumentActions';
import { ReactComponent as LicensesAndCertificates } from '../../assets/images/licenses-certifications-icon.svg';
import { ReactComponent as Education } from '../../assets/images/education-icon.svg';
import { ReactComponent as PersonalDocument } from '../../assets/images/personal-documents-icon.svg';
import { ReactComponent as IconDelete } from '../../assets/images/icon-delete.svg';
import CreateIcon from '../../assets/images/create-icon.svg';
import { ReactComponent as Covid19 } from '../../assets/images/covid19-icon.svg';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit, isRepple, isHomeOrganisation } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { colors, CONTROL_TYPE, STANDARD_CREDENTIAL_TYPE, VALIDATORS_BY_NAME } from '../../assets/constants';
import UserAssignment from '../team/UserAssignment';
import {
  getCredentialTypeRequest,
  listCategoryRequest,
  listIndustryRequest,
  saveCredentialTypeRequest
} from '../../utils/entityRequests/credentialTypeRequests';
import { getFormRequest, getPublishedFormRequest } from '../../utils/entityRequests/formRequests';
import { listTeamRequest } from '../../utils/entityRequests/teamRequests';
import { credentialTypeInitialState } from '../../utils/entityInitialStates';
import { entityArraySelector, entitySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import ManageCredentialImage from './ManageCredentialImage';
import { documentUploadsSelector } from '../../store/selectors/document';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewSectionHeading from '../common/NewSectionHeading';
import NewValidatedField from '../widgets/NewValidatedField';
import CredentialPreview from './CredentialPreview';
import SaveButton from '../widgets/SaveButton';
import NewActionButton from '../widgets/NewActionButton';

const styles = () => ({
  ...style,
  ...layout,
  credentialTypeError: {
    marginLeft: '20px'
  },
  sectionSpacing: {
    marginTop: '30px'
  },
  paddingRight: {
    paddingRight: '20px'
  },
  alignRight: {
    textAlign: 'right',
    paddingRight: '6px'
  },
  credentialTypeButtons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  addSection: {
    cursor: 'pointer'
  },
  sectionContainer: {
    borderLeft: `solid 1px ${colors.borderBlue}`,
    borderTop: `solid 1px ${colors.borderBlue}`,
    borderRight: `solid 1px ${colors.borderBlue}`,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    marginRight: '20px',
    padding: '20px 0 20px 20px'
  },
  sectionFooter: {
    border: `solid 1px ${colors.borderBlue}`,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    backgroundColor: colors.white,
    marginRight: '20px',
    padding: '13px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px'
  },
  removeIconButton: {
    marginBottom: '16px',
    marginLeft: '6px',
    marginRight: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
  removeControlButton: {
    marginBottom: '16px',
    marginLeft: '6px',
    marginRight: '20px'
  },
  addControlButton: {
    marginLeft: '6px',
    fontSize: '18px',
    backgroundColor: colors.blue,
    '&:hover': {
      background: color(colors.backgroundBlue)
        .darken(0.1)
        .hex()
    }
  },
  addOption: {
    fontWeight: 'bold',
    fontSize: '14px',
    color: colors.blueDark,
    marginRight: '6px'
  },
  optionMargin: {
    marginBottom: '15px'
  }
});

class ChangeCredentialTypeView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentialType: credentialTypeInitialState,
      shouldDisplaySelectUser: false,
      controls: [],
      credentialDesign: {},
      imageUpload: {},
      categories: [],
      industries: []
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/credential/credentialType/view/${id}` : '/credential/credentialType';

    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;
    await getEntity(listTeamRequest());
    await getEntity(getPublishedFormRequest());
    const categories = await getEntity(listCategoryRequest());
    const industries = await getEntity(listIndustryRequest());

    if (this.isEdit) {
      const credentialType = await getEntity(getCredentialTypeRequest(match.params.id));
      // const form = await getEntity(getFormRequest(credentialType.credentialForm.identifier));
      this.setState({ credentialType, categories, industries });
    } else {
      this.setState({
        credentialType: credentialTypeInitialState,
        controls: [],
        categories,
        industries
      });
    }
  }

  componentWillUnmount() {
    const { clearUserFileDownloads } = this.props;
    this.setState({ credentialType: credentialTypeInitialState });
    clearUserFileDownloads();
  }

  handleImageUpload = imageUpload => {
    this.setState({
      imageUpload
    });
  };

  handleClearImageUpload = () => {
    const { credentialType } = this.state;
    const credentialTypeResult = _.cloneDeep(credentialType);
    credentialTypeResult.logoKey = '';
    credentialTypeResult.logoUrl = '';

    this.setState({
      credentialType: credentialTypeResult,
      imageUpload: {}
    });
  };

  handleChange = async event => {
    const { name, value } = event.target;
    const { credentialType, categories, industries } = this.state;
    const { getEntity } = this.props;
    const credentialTypeResult = _.cloneDeep(credentialType);
    _.set(credentialTypeResult, name, value);
    const newCategories = _.cloneDeep(categories);
    const newIndustries = _.cloneDeep(industries);
    if (name === 'category') {
      const category = newCategories.find(item => item.value === value);
      credentialTypeResult.category = {};

      if (!category) {
        newCategories.push({ id: value, label: value });
        credentialTypeResult.category.name = value;
      } else credentialTypeResult.category.identifier = value;
    }

    if (name === 'industries') {
      credentialTypeResult.industries = [];
      value.forEach(industry => {
        if (!industries.find(item => item.value === industry)) {
          newIndustries.push({ label: industry, value: industry });
          credentialTypeResult.industries.push({ name: industry });
        } else {
          credentialTypeResult.industries.push({
            identifier: industry,
            name: industries.find(item => item.value === industry).label
          });
        }
      });
    }

    if (name.indexOf('control.identifier') !== -1) {
      if (value === 'expiryDate') {
        _.set(credentialTypeResult, `${name.substring(0, name.indexOf(']') + 1)}.expiryDate`, true);
        _.set(credentialTypeResult, `${name.substring(0, name.indexOf(']') + 1)}.label`, false);
        _.set(credentialTypeResult, `${name.substring(0, name.indexOf(']') + 1)}.control.identifier`, null);
      } else if (value === 'label') {
        _.set(credentialTypeResult, `${name.substring(0, name.indexOf(']') + 1)}.expiryDate`, false);
        _.set(credentialTypeResult, `${name.substring(0, name.indexOf(']') + 1)}.label`, true);
        _.set(credentialTypeResult, `${name.substring(0, name.indexOf(']') + 1)}.control.identifier`, null);
      }
    }

    if (name.indexOf('credentialForm') === -1) {
      this.setState({
        credentialType: credentialTypeResult,
        categories: newCategories,
        industries: newIndustries
      });
    } else {
      const form = await getEntity(getFormRequest(value));
      this.setState({ credentialType: credentialTypeResult, controls: this.getFieldSelectOptions(form) });
    }
  };

  setCredentialDesignUpload = credentialDesign => {
    this.setState({
      credentialDesign
    });
  };

  handleClearCredentialDesign = () => {
    const { credentialType } = this.state;
    const credentialTypeResult = _.cloneDeep(credentialType);
    credentialTypeResult.imageUrl = '';
    credentialTypeResult.imageKey = '';

    this.setState({
      credentialType: credentialTypeResult,
      credentialDesign: {}
    });
  };

  handleClearAssignTeam = () => {
    this.handleChange({
      target: {
        name: 'assignedTeam',
        value: null
      }
    });
  };

  closeSelectUser = () => {
    this.setState({
      shouldDisplaySelectUser: false
    });
  };

  openSelectUser = () => {
    this.setState({
      shouldDisplaySelectUser: true
    });
  };

  handleAssignUser = user => {
    this.handleChange({
      target: {
        name: 'assignedUser',
        value: user
      }
    });

    this.closeSelectUser();
  };

  handleClearAssignUser = () => {
    this.handleChange({
      target: {
        name: 'assignedUser',
        value: null
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, uploadToS3, requestPublicImageUploadUrl } = this.props;
    const { credentialType, credentialDesign, imageUpload } = this.state;

    const credentialTypeResult = _.cloneDeep(credentialType);
    if (credentialDesign && credentialDesign.name && !credentialTypeResult.imageKey) {
      const upload = await requestPublicImageUploadUrl(credentialDesign);
      const response = await uploadToS3([{ url: upload.url, control: 'credentialTypeImage' }], [credentialDesign]);
      if (response[0].status === 200) credentialTypeResult.imageKey = upload.key;
    }

    if (imageUpload && imageUpload.name && !credentialTypeResult.logoKey) {
      const upload = await requestPublicImageUploadUrl(imageUpload);
      const response = await uploadToS3([{ url: upload.url, control: 'credentialTypeLogo' }], [imageUpload]);
      if (response[0].status === 200) credentialTypeResult.logoKey = upload.key;
    }

    const credentialTypeId = await saveEntity(saveCredentialTypeRequest(credentialTypeResult));

    if (credentialTypeId) history.push(`/credential/credentialType/view/${credentialTypeId}`);
  };

  getCategory = () => {
    const { categories, credentialType } = this.state;

    if (credentialType.category) {
      const value = categories.find(item => item.value === credentialType.category.identifier);
      return value || { value: credentialType.category.identifier, label: credentialType.category.identifier };
    }

    return null;
  };

  getIndustries = () => {
    const { industries, credentialType } = this.state;
    if (credentialType && credentialType.industries && industries) {
      const credentialIndustries = credentialType.industries.map(item => ({
        value: item.identifier,
        label: item.name
      }));

      return credentialIndustries;
    }

    return [];
  };

  handleChangeCredentialType = type => {
    const { credentialType } = this.state;

    const credentialTypeResult = _.cloneDeep(credentialType);
    credentialTypeResult.standardCredentialType = type;
    this.setState({
      credentialType: credentialTypeResult
    });
  };

  getImageUrl = () => {
    const { imageUpload, credentialType } = this.state;

    if (imageUpload.name) return URL.createObjectURL(imageUpload);
    if (credentialType.logoUrl) return credentialType.logoUrl;

    return null;
  };

  getBackgroundImageUrl = () => {
    const { credentialDesign, credentialType } = this.state;

    if (credentialDesign.name) return URL.createObjectURL(credentialDesign);
    if (credentialType.imageUrl) return credentialType.imageUrl;

    return null;
  };

  addSection = () => {
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);
    credentialTypeModel.sections.push({
      controls: [{ type: '', label: '', required: false, selectOptions: [] }],
      title: ''
    });
    this.setState({
      credentialType: credentialTypeModel
    });
  };

  handleChangeControlType = event => {
    const { name, value } = event.target;
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);
    _.set(credentialTypeModel, name, value);

    if (CONTROL_TYPE.TEXT.id === value || CONTROL_TYPE.DATE.id === value) {
      _.set(credentialTypeModel, `${name.replace('type', 'selectOptions')}`, []);
    } else {
      _.set(credentialTypeModel, `${name.replace('type', 'selectOptions')}`, [{ name: '' }]);
    }

    this.setState({
      credentialType: credentialTypeModel
    });
  };

  handleAddControlChildArrayItem = (path, element) => {
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);

    const items = _.get(credentialTypeModel, path);
    _.set(credentialTypeModel, `${path}[${items.length}]`, element);

    this.setState({
      credentialType: credentialTypeModel
    });
  };

  handleDeleteControlChildArrayItem = (path, index) => {
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);
    const items = _.get(credentialTypeModel, path);
    _.pullAt(items, index);
    this.setState({
      credentialType: credentialTypeModel
    });
  };

  handleDeleteControl = (path, index) => {
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);
    const items = _.get(credentialTypeModel, path);
    _.pullAt(items, index);
    this.setState({
      credentialType: credentialTypeModel
    });
  };

  handleAddControl = (id, path) => {
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);
    const controls = _.get(credentialTypeModel, `${path}.controls`);
    _.set(credentialTypeModel, `${path}.controls[${controls.length}]`, {
      type: '',
      label: '',
      required: false,
      selectOptions: []
    });

    this.setState({
      credentialType: credentialTypeModel
    });
  };

  handleDeleteSection = (path, index) => {
    const { credentialType } = this.state;
    const credentialTypeModel = _.cloneDeep(credentialType);
    const items = _.get(credentialTypeModel, path);
    _.pullAt(items, index);
    this.setState({
      credentialType: credentialTypeModel
    });
  };

  render() {
    const { classes, isSaving, documentUploads, errors, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { credentialType, shouldDisplaySelectUser, controls, categories, industries } = this.state;

    return (
      <Grid container className={classNames(classes.mainContentColumn)}>
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              declineButtonText="Back"
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header={`${id ? 'Edit' : 'New'} credential type`}
              isSaving={isSaving}
            />
          </Grid>
          <Grid item xs={12} container className={classes.formContent}>
            <Grid item lg={6} md={12} xs={12} style={{ borderRight: '1px solid #D3DBDF' }}>
              <NewSectionHeading heading="Credential Type Details" />
              <Grid container>
                <Grid item xs={3}>
                  <div
                    className={classNames(classes.credentialTypeButton, {
                      [classes.credentialTypeButtonSelected]:
                        credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id
                    })}
                    onClick={() => {
                      if (!credentialType.identifier || !credentialType.standardCredentialType) {
                        this.handleChangeCredentialType(STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id);
                      }
                    }}
                  >
                    <div>
                      <LicensesAndCertificates
                        fill={
                          credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id
                            ? colors.white
                            : colors.blue
                        }
                      />
                      {STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.label}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div
                    className={classNames(classes.credentialTypeButton, {
                      [classes.credentialTypeButtonSelected]:
                        credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id
                    })}
                    onClick={() => {
                      if (!credentialType.identifier || !credentialType.standardCredentialType) {
                        this.handleChangeCredentialType(STANDARD_CREDENTIAL_TYPE.EDUCATION.id);
                      }
                    }}
                  >
                    <div>
                      <Education
                        fill={
                          credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id
                            ? colors.white
                            : colors.blue
                        }
                      />
                      {STANDARD_CREDENTIAL_TYPE.EDUCATION.label}
                    </div>
                  </div>
                </Grid>
                {isRepple() && isHomeOrganisation() && (
                  <Fragment>
                    <Grid item xs={3}>
                      <div
                        className={classNames(classes.credentialTypeButton, {
                          [classes.credentialTypeButtonSelected]:
                            credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id
                        })}
                        onClick={() => {
                          if (!credentialType.identifier || !credentialType.standardCredentialType) {
                            this.handleChangeCredentialType(STANDARD_CREDENTIAL_TYPE.PASSPORT.id);
                          }
                        }}
                      >
                        <div>
                          <PersonalDocument
                            fill={
                              credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id
                                ? colors.white
                                : colors.blue
                            }
                          />
                          Personal Documents
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div
                        className={classNames(classes.credentialTypeButton, {
                          [classes.credentialTypeButtonSelected]:
                            credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.COVID19.id
                        })}
                        onClick={() => {
                          if (!credentialType.identifier || !credentialType.standardCredentialType) {
                            this.handleChangeCredentialType(STANDARD_CREDENTIAL_TYPE.COVID19.id);
                          }
                        }}
                      >
                        <div>
                          <Covid19
                            fill={
                              credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.COVID19.id
                                ? colors.white
                                : colors.blue
                            }
                          />
                          {STANDARD_CREDENTIAL_TYPE.COVID19.label}
                        </div>
                      </div>
                    </Grid>
                  </Fragment>
                )}
              </Grid>
              <Grid container>
                {errors && errors.fieldErrors && (
                  <FormHelperText error>{errors.fieldErrors['standardCredentialType']}</FormHelperText>
                )}
              </Grid>
              <NewSectionHeading heading="Standard information" className={classes.sectionSpacing} />
              <Grid container>
                <Grid item xs={6}>
                  <NewValidatedField
                    id="name"
                    label="Name"
                    name="name"
                    value={credentialType.name}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    className={classes.paddingRight}
                  />
                </Grid>
                <Grid item xs={6}>
                  {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id && (
                    <NewValidatedField
                      id="type"
                      label="Type"
                      name="type"
                      value={credentialType.type}
                      onChange={this.handleChange}
                      validators={[]}
                      className={classes.paddingRight}
                    />
                  )}
                  {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id && (
                    <NewValidatedField
                      id="fieldOfStudy"
                      label="Field of Study"
                      name="fieldOfStudy"
                      value={credentialType.fieldOfStudy}
                      onChange={this.handleChange}
                      validators={[]}
                      className={classes.paddingRight}
                    />
                  )}
                </Grid>
              </Grid>
              {credentialType.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id && (
                <Grid container>
                  <Grid item xs={6}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      key="category"
                      label="Category"
                      value={this.getCategory()}
                      onChange={this.handleChange}
                      items={categories}
                      id="category"
                      itemName="label"
                      itemValue="value"
                      validators={[]}
                      className={classes.paddingRight}
                      creatable
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      key="industries"
                      label="Industries"
                      value={this.getIndustries()}
                      onChange={this.handleChange}
                      items={industries}
                      id="industries"
                      itemName="label"
                      itemValue="value"
                      validators={[]}
                      className={classes.paddingRight}
                      multiple
                      creatable
                    />
                  </Grid>
                </Grid>
              )}
              <Grid container>
                <Grid item xs={4}>
                  <NewValidatedField
                    id="verificationTime"
                    label="Time for Verification"
                    name="verificationTime"
                    value={credentialType.verificationTime}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    className={classes.paddingRight}
                    helperText="Number of days"
                  />
                </Grid>
                {credentialType.standardCredentialType !== STANDARD_CREDENTIAL_TYPE.PASSPORT.id && (
                  <Fragment>
                    <Grid item xs={4}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.CHECKBOX}
                        id="expires"
                        label="The credential does not expire"
                        name="expires"
                        value={!credentialType.expires}
                        onChange={() => {
                          this.handleChange({ target: { name: 'expires', value: !credentialType.expires } });
                        }}
                        validators={[]}
                        className={classes.paddingRight}
                      />
                    </Grid>
                    <Grid item xs={4} className={classes.alignRight}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.CHECKBOX}
                        id="publicCredential"
                        label="Public Credential"
                        name="publicCredential"
                        value={credentialType.publicCredential}
                        labelPlacement="end"
                        onChange={() => {
                          this.handleChange({
                            target: { name: 'publicCredential', value: !credentialType.publicCredential }
                          });
                        }}
                        validators={[]}
                        className={classes.paddingRight}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <NewValidatedField
                    type={VALIDATED_FIELD_TYPES.CHECKBOX}
                    id="singleValidCredential"
                    label="Single valid credential"
                    name="singleValidCredential"
                    value={credentialType.singleValidCredential}
                    labelPlacement="end"
                    onChange={() => {
                      this.handleChange({
                        target: { name: 'singleValidCredential', value: !credentialType.singleValidCredential }
                      });
                    }}
                    validators={[]}
                    className={classes.paddingRight}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <NewSectionHeading heading="Additional Information">
                    <Tooltip title="Add section" placement="right" onClick={this.addSection}>
                      <img src={CreateIcon} className={classes.addSection} />
                    </Tooltip>
                  </NewSectionHeading>
                </Grid>
              </Grid>
              <Grid container>
                {credentialType.sections.map((section, sectionIndex) => (
                  <Grid item xs={12} key={`section-${sectionIndex}`}>
                    <div className={classes.sectionContainer}>
                      <NewValidatedField
                        id={`sections[${sectionIndex}].title`}
                        label="Section Title"
                        name={`sections[${sectionIndex}].title`}
                        value={credentialType.sections[sectionIndex].title}
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        className={classes.paddingRight}
                      />
                      {(section.controls || []).map((control, controlIndex) => (
                        <Fragment key={`section-${sectionIndex}-control-${controlIndex}`}>
                          <div className={classNames(classes.flexRowCentered)}>
                            <NewValidatedField
                              id={`sections[${sectionIndex}].controls[${controlIndex}].label`}
                              label="Control Name"
                              name={`sections[${sectionIndex}].controls[${controlIndex}].label`}
                              value={section.controls[controlIndex].label}
                              onChange={this.handleChange}
                              validators={[VALIDATORS_BY_NAME.REQUIRED]}
                              className={classes.paddingRight}
                            />
                            <NewValidatedField
                              type={VALIDATED_FIELD_TYPES.SELECT}
                              label="Control Type"
                              value={control.type ? CONTROL_TYPE[control.type] : null}
                              onChange={this.handleChangeControlType}
                              items={[
                                CONTROL_TYPE.TEXT,
                                CONTROL_TYPE.MULTIPLE_CHOICE,
                                CONTROL_TYPE.CHECKBOXES,
                                CONTROL_TYPE.DROP_DOWN,
                                CONTROL_TYPE.DATE
                              ]}
                              id={`sections[${sectionIndex}].controls[${controlIndex}].type`}
                              name={`sections[${sectionIndex}].controls[${controlIndex}].type`}
                              itemName="label"
                              itemValue="id"
                              validators={[VALIDATORS_BY_NAME.REQUIRED]}
                              className={classes.paddingRight}
                            />
                            <NewValidatedField
                              type={VALIDATED_FIELD_TYPES.CHECKBOX}
                              id={`sections[${sectionIndex}].controls[${controlIndex}].required`}
                              label="Required"
                              name={`sections[${sectionIndex}].controls[${controlIndex}].required`}
                              value={section.controls[controlIndex].required}
                              labelPlacement="end"
                              onChange={() => {
                                this.handleChange({
                                  target: {
                                    name: `sections[${sectionIndex}].controls[${controlIndex}].required`,
                                    value: !credentialType.sections[sectionIndex].controls[controlIndex].required
                                  }
                                });
                              }}
                              validators={[]}
                              className={classes.paddingRight}
                            />
                            {section.controls.length > 1 && (
                              <Tooltip title="Delete Control" aria-label="add" placement="right">
                                <IconButton
                                  variant="fab"
                                  className={classes.removeControlButton}
                                  onClick={() =>
                                    this.handleDeleteControl(`sections[${sectionIndex}].controls`, controlIndex)
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                          {control.selectOptions.map((selectOption, selectOptionIndex) => (
                            <Fragment
                              key={`section-${sectionIndex}-control-${controlIndex}-option-${selectOptionIndex}`}
                            >
                              <div className={classNames(classes.flexRowCentered)}>
                                {/* eslint-disable max-len */}
                                <NewValidatedField
                                  id={`sections[${sectionIndex}].controls[${controlIndex}].selectOptions[${selectOptionIndex}].name`}
                                  label={`Option ${selectOptionIndex}`}
                                  name={`sections[${sectionIndex}].controls[${controlIndex}].selectOptions[${selectOptionIndex}].name`}
                                  value={selectOption.name}
                                  onChange={this.handleChange}
                                  validators={[VALIDATORS_BY_NAME.REQUIRED]}
                                  className={classNames({ [classes.paddingRight]: selectOptionIndex === 0 })}
                                />
                                {/* eslint-enable max-len */}
                                {selectOptionIndex !== 0 && (
                                  <Tooltip title="Remove Option" aria-label="add" placement="right">
                                    <IconButton
                                      className={classes.removeIconButton}
                                      variant="fab"
                                      onClick={() =>
                                        this.handleDeleteControlChildArrayItem(
                                          `sections[${sectionIndex}].controls[${controlIndex}].selectOptions`,
                                          selectOptionIndex
                                        )
                                      }
                                    >
                                      <i className="material-icons">clear</i>
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </Fragment>
                          ))}
                          {/* eslint-disable indent */
                          control.type !== '' &&
                            control.type !== CONTROL_TYPE.TEXT.id &&
                            control.type !== CONTROL_TYPE.DATE.id && (
                              <div className={classNames(classes.flexRowCentered, classes.optionMargin)}>
                                <label className={classes.addOption}>Add option</label>
                                <Tooltip title="Add Option" aria-label="add" placement="right">
                                  <i
                                    className={classNames(['material-icons', classes.blueIcon])}
                                    onClick={() =>
                                      this.handleAddControlChildArrayItem(
                                        `sections[${sectionIndex}].controls[${controlIndex}].selectOptions`,
                                        { name: '' }
                                      )
                                    }
                                  >
                                    add_circle
                                  </i>
                                </Tooltip>
                              </div>
                            ) /* eslint-enable indent */}
                        </Fragment>
                      ))}
                    </div>
                    <div className={classes.sectionFooter}>
                      <NewActionButton
                        primary={false}
                        icon={<i className={classNames('material-icons', classes.blueIcon)}>add_circle</i>}
                        text="Add Control"
                        onChange={() => this.handleAddControl(id, `sections[${sectionIndex}]`)}
                        filled={false}
                        style={{ marginLeft: '10px' }}
                      />
                      <NewActionButton
                        primary={false}
                        icon={<IconDelete fill={colors.blue} />}
                        text="Delete section"
                        onChange={() => {
                          this.handleDeleteSection('sections', sectionIndex);
                        }}
                        filled={false}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
              {/* <Grid container> */}
              {/*  <Grid item xs={5}> */}
              {/*    <NewValidatedField */}
              {/*      id="controlLabel" */}
              {/*      label="Control Label" */}
              {/*      name="controlLabel" */}
              {/*      value="" */}
              {/*      onChange={this.handleChange} */}
              {/*      validators={[]} */}
              {/*      className={classes.paddingRight} */}
              {/*    /> */}
              {/*  </Grid> */}
              {/*  <Grid item xs={5}> */}
              {/*    <NewValidatedField */}
              {/*      type={VALIDATED_FIELD_TYPES.SELECT} */}
              {/*      key="controlType" */}
              {/*      label="Type" */}
              {/*      value={{ id: '', label: '' }} */}
              {/*      onChange={this.handleChange} */}
              {/*      items={[CONTROL_TYPE.TEXT]} */}
              {/*      id="controlType" */}
              {/*      itemName="label" */}
              {/*      itemValue="id" */}
              {/*      validators={[]} */}
              {/*      className={classes.paddingRight} */}
              {/*    /> */}
              {/*  </Grid> */}
              {/*  <Grid item xs={2}> */}
              {/*    Icon */}
              {/*  </Grid> */}
              {/* </Grid> */}
            </Grid>
            <Grid item lg="auto" md={6} xs={12} style={{ paddingLeft: '20px' }}>
              <NewSectionHeading heading="Credential design" />
              <ManageCredentialImage
                controls={controls}
                fields={credentialType.fields}
                backgroundColor={credentialType.backgroundColor}
                handleChange={this.handleChange}
                image={credentialType.imageUrl}
                imageLogo={credentialType.logoUrl}
                handleClearCredentialDesign={this.handleClearCredentialDesign}
                setCredentialDesignUpload={this.setCredentialDesignUpload}
                uploadStatus={
                  documentUploads && documentUploads.credentialTypeImage ? documentUploads.credentialTypeImage : []
                }
                isSaving={isSaving}
                clearImageUpload={this.handleClearImageUpload}
                handleImageUpload={this.handleImageUpload}
              />
            </Grid>
            <Grid item lg="auto" md={6} xs={12} style={{ paddingLeft: '20px' }}>
              <NewSectionHeading heading="Preview" />
              <CredentialPreview
                backgroundColor={credentialType.backgroundColor}
                name={credentialType.name}
                backgroundImage={this.getBackgroundImageUrl()}
                logoImage={this.getImageUrl()}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} isDisabled={false} onSubmit={this.submitForm} />
          </Grid>
          <Grid item xs={6}>
            {/* <Grid container> */}
            {/*  <Grid item xs={12}> */}
            {/*    <div className={classes.fieldContainer}> */}
            {/*      <ValidatedField */}
            {/*        type={VALIDATED_FIELD_TYPES.SELECT} */}
            {/*        key="priority" */}
            {/*        label="Priority" */}
            {/*        value={credentialType.priority} */}
            {/*        handleChange={this.handleChange} */}
            {/*        values={[FORM_PRIORITY.C_STANDARD, FORM_PRIORITY.B_URGENT, FORM_PRIORITY.A_CRITICAL]} */}
            {/*        id="priority" */}
            {/*        optionName="label" */}
            {/*        optionValue="value" */}
            {/*        validators={[]} */}
            {/*      /> */}
            {/*    </div> */}
            {/*  </Grid> */}
            {/*  <Grid item xs={12}> */}
            {/*    <div className={classNames(classes.fieldContainer, classes.headingAndAction)}> */}
            {/*      <ValidatedField */}
            {/*        type={VALIDATED_FIELD_TYPES.SELECT} */}
            {/*        key="assignedTeam" */}
            {/*        label="Assigned Team" */}
            {/*        value={(credentialType.assignedTeam && credentialType.assignedTeam.identifier) || ''} */}
            {/*        handleChange={this.handleChange} */}
            {/*        values={teams} */}
            {/*        id="assignedTeam.identifier" */}
            {/*        optionName="name" */}
            {/*        optionValue="identifier" */}
            {/*        validators={[]} */}
            {/*      /> */}
            {/*      <Tooltip title="Clear Assigned Team" placement="bottom"> */}
            {/*        <IconButton onClick={this.handleClearAssignTeam}> */}
            {/*          <i className={classNames('material-icons', classes.icon)}>clear</i> */}
            {/*        </IconButton> */}
            {/*      </Tooltip> */}
            {/*    </div> */}
            {/*  </Grid> */}
            {/*  <Grid item xs={12}> */}
            {/*    <div className={classNames(classes.fieldContainer, classes.headingAndAction)}> */}
            {/*      <ValidatedField */}
            {/*        id="assignedUser" */}
            {/*        label="Assigned User" */}
            {/*        name="assignedUser.identifier" */}
            {/*        value={(credentialType.assignedUser && credentialType.assignedUser.name) || ''} */}
            {/*        onClick={this.openSelectUser} */}
            {/*        validators={[]} */}
            {/*      /> */}
            {/*      <Tooltip title="Clear Assigned User" placement="bottom"> */}
            {/*        <IconButton onClick={this.handleClearAssignUser}> */}
            {/*          <i className={classNames('material-icons', classes.icon)}>clear</i> */}
            {/*        </IconButton> */}
            {/*      </Tooltip> */}
            {/*    </div> */}
            {/*  </Grid> */}
            {/* </Grid> */}
          </Grid>
          <Grid item xs={1} />
          <Modal open={shouldDisplaySelectUser} onClose={this.closeSelectUser}>
            <Slide direction="left" in={shouldDisplaySelectUser} mountOnEnter unmountOnExit>
              <Grid item xs={4} className={`${classes.modalContainer}`}>
                <UserAssignment
                  closeSelectUser={this.closeSelectUser}
                  addUser={this.handleAssignUser}
                  heading="Users"
                />
              </Grid>
            </Slide>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

ChangeCredentialTypeView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  uploadToS3: PropTypes.func.isRequired,
  clearUserFileDownloads: PropTypes.func.isRequired,
  requestPublicImageUploadUrl: PropTypes.func.isRequired,
  teams: PropTypes.array,
  publishedForms: PropTypes.array,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  documentUploads: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'credentialType'),
  ...entitySelector(state, 'form'),
  ...entityArraySelector(state, 'categories'),
  ...entityArraySelector(state, 'industries'),
  isSaving: loadingSelector(state, 'saveEntity') || loadingSelector(state, 'uploadToS3'),
  ...entityArraySelector(state, 'teams'),
  ...documentUploadsSelector(state)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity, uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads }),
  withRouter
)(ChangeCredentialTypeView);

import { createSelector } from 'reselect';

export const getEntity = (state, name) => ({ state: state.entity[name], name });
export const getErrors = state => state.entity.errors;

export const entityWithErrorsSelector = createSelector(getEntity, getErrors, (entity, errors) => {
  return { [entity.name]: { ...entity.state }, errors };
});

export const entitySelector = createSelector(getEntity, entity => {
  return { [entity.name]: { ...entity.state } };
});

export const entityArraySelector = createSelector(getEntity, entity => {
  return { [entity.name]: entity.state };
});

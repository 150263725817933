/* eslint-disable no-confusing-arrow */
import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { hasPermission, isAssurance, isCompetence, isHomeOrganisation, isRepple } from '../../utils/utilities';
import { toggleSetting } from '../../store/actions/SettingsActions';
import Brand from '../widgets/Brand';
import navStyles from '../../assets/LeftSideNavigationStyles';
import { ReactComponent as IconCredential } from '../../assets/images/navigation/icon-credential.svg';
import { ReactComponent as IconCredentialType } from '../../assets/images/navigation/icon-credential-type.svg';
import { ReactComponent as IconImport } from '../../assets/images/navigation/icon-import.svg';
import { ReactComponent as IconWorkforce } from '../../assets/images/navigation/icon-workforce.svg';
import { ReactComponent as IconSuperAdmin } from '../../assets/images/navigation/icon-super-admin.svg';
import { ReactComponent as IconOrganisationAdmin } from '../../assets/images/navigation/icon-organisation-admin.svg';
import { style } from '../../assets/style';
import { permissions } from '../../constants/global';
import { settingSelector } from '../../store/selectors/shared';
import { colors } from '../../assets/constants';

const styles = () => ({
  ...style,
  ...navStyles,
  leftSideNavTemporaryOpen: {
    position: 'absolute !important',
    backgroundColor: '#fff',
    height: '100%',
    width: '325px',
    zIndex: 30,
    transitionProperty: 'opacity, left, top, width',
    transitionDuration: '1s, .5s'
  },
  marginRight: {
    marginRight: '7px',
    '& svg': {
      fill: `${colors.fontLightGrey}`
    }
  }
});

export const navigationModel = [];

class LeftSideNavigation extends React.Component {
  state = {
    menuOpen: false,
    activeMenuItem: null
  };

  static getIndexForItem = path => {
    let foundIndex = null;
    navigationModel.forEach((item, index) => {
      const indexValue = LeftSideNavigation.getIndexForItemWithIndex(path, item, index.toString());

      if (indexValue) foundIndex = indexValue;
    });

    return foundIndex;
  };

  static getIndexForItemWithIndex = (path, item, index) => {
    if (!item.children) return path.startsWith(`${item.path}/`) || path === `${item.path}` ? index : null;

    let foundIndex = null;
    item.children.forEach((child, childIndex) => {
      const indexValue = LeftSideNavigation.getIndexForItemWithIndex(path, child, `${index},${childIndex}`);
      if (indexValue) foundIndex = indexValue;
    });

    return foundIndex;
  };

  static getDerivedStateFromProps(props, prevProps) {
    const { leftSideMenuPermanentlyOpen, menuOpen, history } = props;

    if (navigationModel.length === 0) {
      navigationModel.push({
        label: 'Credentials',
        icon: <IconCredential />,
        render: isCompetence() && hasPermission([permissions.CREDENTIAL_TYPE_ADMIN, permissions.CREDENTIAL_MANAGEMENT]),
        path: '/credential',
        children: [
          {
            label: 'Inbox',
            path: '/credential/inbox',
            icon: 'inbox',
            render: hasPermission([permissions.CREDENTIAL_MANAGEMENT]) && isCompetence()
          },
          {
            label: 'Credential type',
            path: '/credential/credentialType',
            icon: <IconCredentialType />,
            render: hasPermission([permissions.CREDENTIAL_TYPE_ADMIN]) && isCompetence()
          },
          {
            label: 'Import',
            path: '/credential/import',
            icon: <IconImport />,
            render: hasPermission([permissions.CREDENTIAL_MANAGEMENT]) && isCompetence()
          }
        ]
      });

      navigationModel.push({
        label: 'Workplaces',
        icon: 'location_city',
        render: isAssurance() && hasPermission([permissions.WORKPLACE_LOCATION_ADMIN, permissions.READ_DATA]),
        path: '/workplace',
        children: [
          {
            label: 'Task Assessment',
            path: '/workplace/organisationCredentialCheck',
            icon: 'contactless',
            render: hasPermission([permissions.READ_DATA]) && isAssurance()
          },
          {
            label: 'Locations',
            path: '/workplace/location',
            icon: 'home',
            render: hasPermission([permissions.WORKPLACE_LOCATION_ADMIN]) && isAssurance()
          },
          {
            label: 'Incidents',
            path: '/workplace/incident',
            icon: 'healing',
            render: hasPermission([permissions.READ_DATA]) && isAssurance()
          },
          {
            label: 'Attendance Checks',
            path: '/workplace/attendance',
            icon: 'access_time',
            render: hasPermission([permissions.READ_DATA]) && isAssurance()
          }
        ]
      });

      navigationModel.push({
        label: 'Workforce',
        icon: <IconWorkforce />,
        render: isAssurance() && hasPermission([permissions.TIMELINE_ADMIN]),
        path: '/workforce',
        children: [
          {
            label: 'Timeline Feed',
            path: '/workforce/post',
            icon: 'note_add',
            render: hasPermission([permissions.TIMELINE_ADMIN]) && isAssurance()
          },
          {
            label: 'Qualifications',
            path: '/workforce/qualification',
            icon: 'school',
            render: hasPermission([permissions.QUALIFICATION_ADMIN]) && isAssurance()
          },
          {
            label: 'Credential Check Reasons',
            path: '/workforce/reason',
            icon: 'rule',
            render: isAssurance() && hasPermission([permissions.QUALIFICATION_ADMIN])
          }
        ]
      });

      navigationModel.push({
        label: 'Organisation Admin',
        icon: <IconOrganisationAdmin />,
        render: hasPermission([permissions.USER_ADMIN]),
        path: '/organisation',
        children: [
          {
            label: 'User Management',
            path: '/organisation/user',
            icon: 'person',
            render: hasPermission([permissions.USER_ADMIN])
          },
          // {
          //   label: 'Team Management',
          //   path: '/organisation/team',
          //   icon: 'people',
          //   render: hasPermission([permissions.USER_ADMIN]) && isCompetence()
          // },
          {
            label: 'Members',
            path: '/organisation/organisationMember',
            icon: 'people_outline',
            render: hasPermission([permissions.ORGANISATION_HOLDER_ADMIN]) && isAssurance()
          },
          {
            label: 'Notifications',
            path: '/organisation/notification',
            icon: 'notifications',
            render: hasPermission([permissions.READ_DATA]) && (isAssurance() || isCompetence())
          }
        ]
      });

      navigationModel.push({
        label: 'Super Admin',
        icon: <IconSuperAdmin />,
        render:
          hasPermission([permissions.ORGANISATION_ADMIN, permissions.HOLDER_ADMIN]) &&
          isRepple() &&
          isHomeOrganisation(),
        path: '/admin',
        children: [
          {
            label: 'Organisations',
            path: '/admin/organisation',
            icon: 'account_balance',
            render: hasPermission([permissions.ORGANISATION_ADMIN])
          },
          {
            label: 'Holders',
            path: '/admin/holder',
            icon: 'person',
            render: hasPermission([permissions.HOLDER_ADMIN])
          },
          {
            label: 'Email',
            path: '/admin/email',
            icon: 'email',
            render: hasPermission([permissions.HOLDER_ADMIN])
          },
          {
            label: 'Timeline Feed',
            path: '/admin/post',
            icon: 'note_add',
            render: hasPermission([permissions.TIMELINE_ADMIN])
          },
          {
            label: 'User Role',
            path: '/admin/userRole',
            icon: 'accessibility_new',
            render: hasPermission([permissions.SYSTEM_ADMIN])
          },
          {
            label: 'Crowdsource Organisation',
            path: '/admin/crowdsource/organisation',
            icon: 'accessibility_new',
            render: hasPermission([permissions.SYSTEM_ADMIN])
          },
          {
            label: 'Crowdsource Credential Type',
            path: '/admin/crowdsource/credentialType',
            icon: 'accessibility_new',
            render: hasPermission([permissions.SYSTEM_ADMIN])
          }
        ]
      });

      // navigationModel.push({
      //   label: 'Training',
      //   icon: 'school',
      //   render: hasPermission([permissions.CATALOG_ADMIN]) && isTraining(),
      //   path: '/training',
      //   children: [
      //     {
      //       label: 'Courses',
      //       path: '/training/course',
      //       icon: 'menu_book',
      //       render: hasPermission([permissions.CATALOG_ADMIN])
      //     },
      //     {
      //       label: 'Scheduling',
      //       path: '/training/scheduling',
      //       icon: 'schedule',
      //       render: hasPermission([permissions.CATALOG_ADMIN])
      //     },
      //     {
      //       label: 'Centres',
      //       path: '/training/centre',
      //       icon: 'business',
      //       render: hasPermission([permissions.CATALOG_ADMIN])
      //     },
      //     {
      //       label: 'Scheduled Courses',
      //       path: '/training/scheduledCourse',
      //       icon: 'calendar_today',
      //       render: hasPermission([permissions.CATALOG_ADMIN])
      //     }
      //   ]
      // });
      //
      // navigationModel.push({
      //   label: 'Training Courses',
      //   icon: 'school',
      //   render: hasPermission([permissions.READ_DATA]) && isAssurance(),
      //   path: '/catalog',
      //   children: [
      //     {
      //       label: 'Catalog',
      //       path: '/catalog/courseCatalog',
      //       icon: 'menu_book',
      //       render: isAssurance()
      //     }
      //   ]
      // });
    }

    return {
      menuOpen: leftSideMenuPermanentlyOpen || menuOpen,
      activeMenuItem: LeftSideNavigation.getIndexForItem(history.location.pathname)
    };
  }

  navigateItem = (url, index) => {
    const { history } = this.props;
    this.setState({
      activeMenuItem: index
    });
    history.push(url);
  };

  getExpansionArray = index => {
    const results = [];
    if (index) {
      const indexArray = index.split(',');
      for (let i = 0; i < indexArray.length; i++) {
        results.push(indexArray.slice(0, i + 1).join(','));
      }
    }

    return results;
  };

  handleOpenMenu = () => {
    const { toggleSetting } = this.props;
    toggleSetting('menuOpen', true);
  };

  handleCloseMenu = () => {
    const { toggleSetting } = this.props;
    toggleSetting('menuOpen', false);
  };

  renderList = model => {
    const items = [];
    model.forEach((item, index) => item.render && items.push(this.renderItem(item, index.toString())));
    return items;
  };

  renderItem = (item, index) => {
    const { classes } = this.props;
    const { activeMenuItem } = this.state;

    if (!item.children && item.render) {
      /* eslint-disable react/jsx-wrap-multilines */
      return (
        <TreeItem
          className={classes.treeItem}
          label={
            <div className={classNames({ [classes.selectedItem]: index === activeMenuItem })}>
              <button
                type="button"
                onClick={() => this.navigateItem(item.path, index)}
                className={classes.treeItemContainer}
              >
                {typeof item.icon === 'string' && <i className="material-icons">{item.icon}</i>}
                {typeof item.icon !== 'string' && <div className={classes.marginRight}>{item.icon}</div>}
                <label>{item.label}</label>
              </button>
            </div>
          }
          key={`key${item.label}-child`}
          nodeId={`${index}`}
        />
      );
      /* eslint-enable react/jsx-wrap-multilines */
    }

    return (
      /* eslint-disable react/jsx-wrap-multilines */
      <TreeItem
        className={classes.treeItem}
        label={
          <div className={classes.treeItemContainer} style={{ alignItems: 'start' }}>
            {typeof item.icon === 'string' && <i className="material-icons">{item.icon}</i>}
            {typeof item.icon !== 'string' && (
              <div className={classes.marginRight} style={{ width: '24px', height: '24px' }}>
                {item.icon}
              </div>
            )}
            <label>{item.label}</label>
          </div>
        }
        nodeId={index}
        key={`${item.label}-item`}
      >
        {item.children &&
          item.children.map((child, childIndex) => {
            return child.render && this.renderItem(child, `${index},${childIndex}`);
          })}
      </TreeItem>
      /* eslint-enable react/jsx-wrap-multilines */
    );
  };

  render() {
    const { classes, leftSideMenuPermanentlyOpen } = this.props;
    const { menuOpen, activeMenuItem } = this.state;

    return (
      <Fragment>
        <div
          className={classNames(
            { [classes.leftSideNavContainer]: menuOpen && leftSideMenuPermanentlyOpen },
            { [classes.leftSideNavContainerClosed]: !menuOpen },
            { [classes.leftSideNavTemporaryOpen]: menuOpen && !leftSideMenuPermanentlyOpen }
          )}
        >
          {menuOpen && (
            <Fragment>
              <ul className={`${classes.navigation} ${classes.navigationVertical}`} onMouseLeave={this.handleCloseMenu}>
                <li className={classes.navigationItem}>
                  <NavLink to="/" exact className={classes.logo}>
                    <Brand menuOpen={menuOpen} />
                  </NavLink>
                </li>

                <li className={classNames(classes.navigationItem, classes.createMargin)} />
                <li>
                  <div className={classes.treeMenuContainer}>
                    <TreeView
                      defaultCollapseIcon={<ExpandLess />}
                      defaultExpandIcon={<ExpandMore />}
                      className={classes.treeMenu}
                      defaultExpanded={this.getExpansionArray(activeMenuItem)}
                    >
                      {this.renderList(navigationModel)}
                    </TreeView>
                  </div>
                </li>
              </ul>
            </Fragment>
          )}
          {!menuOpen && (
            <ul className={`${classes.navigation} ${classes.navigationVertical}`}>
              <li className={classes.navigationItem}>
                <NavLink to="/" exact className={classes.logo}>
                  <Brand />
                </NavLink>
              </li>

              <li className={classNames(classes.navigationItem, classes.createMargin)} />

              {navigationModel.map(
                (item, index) =>
                  item.render && (
                    <li className={classes.navigationItem} key={`navigation-menu-${index}`}>
                      <NavLink to={item.path} className={classes.navLink} activeClassName="selected">
                        <Tooltip title={item.label} aria-label="add" placement="right">
                          <Fragment>
                            {typeof item.icon === 'string' && (
                              <i
                                className={classNames('material-icons', { [classes.rotateIcon]: item.rotate })}
                                onMouseEnter={this.handleOpenMenu}
                              >
                                {item.icon}
                              </i>
                            )}
                            {typeof item.icon !== 'string' && <div onMouseEnter={this.handleOpenMenu}>{item.icon}</div>}
                          </Fragment>
                        </Tooltip>
                      </NavLink>
                    </li>
                  )
              )}
            </ul>
          )}
        </div>
      </Fragment>
    );
  }
}

LeftSideNavigation.defaultProps = {
  addNewUrl: ''
};

LeftSideNavigation.propTypes = {
  addNewUrl: PropTypes.string,
  history: PropTypes.object,
  leftSideMenuPermanentlyOpen: PropTypes.bool,
  menuOpen: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  toggleSetting: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  leftSideMenuPermanentlyOpen: settingSelector(state, 'leftSideMenuPermanentlyOpen'),
  menuOpen: settingSelector(state, 'menuOpen')
});

export default compose(withRouter, withStyles(styles), connect(mapStateToProps, { toggleSetting }))(LeftSideNavigation);

import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity, createInitialEntity } from '../../store/actions/EntityActions';
import { OBSERVATION_STATUS_TYPE } from '../../assets/constants';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { loadingSelector } from '../../store/selectors/shared';
import { entitySelector } from '../../store/selectors/entity';
import { getObservationRequest } from '../../utils/entityRequests/workPlaceRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import NewSectionHeading from '../common/NewSectionHeading';
import ReppleImageGallery from '../widgets/ReppleImageGallery';

const styles = {
  ...layout,
  ...style,
  displayLabelAndField: {
    marginTop: '6px'
  },
  qualifications: {
    marginTop: '20px'
  },
  galleryContainer: {
    marginBottom: '20px',
    '& div': {
      marginRight: '20px',
      cursor: 'pointer'
    }
  }
};

class ObservationView extends React.Component {
  state = {
    showGallery: false,
    thumbnailId: 0
  };

  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { observationId } = params;
    await getEntity(getObservationRequest(observationId));
  }

  openGallery = id => {
    this.setState({
      showGallery: true,
      thumbnailId: id
    });
  };

  closeGallery = () => {
    this.setState({
      showGallery: false,
      thumbnailId: null
    });
  };

  getGalleryImages = () => {
    const { observation } = this.props;
    return observation.images.map(item => ({ original: item.url, name: item.name, thumbnailId: item.identifier }));
  };

  render() {
    const { classes, match, observation } = this.props;
    const { showGallery, thumbnailId } = this.state;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(observation).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <NewHeaderViewActions
                header="Observation"
                cancelUrl={`/workplace/location/view/${id}/observation`}
                editUrl={`/workplace/location/view/${id}/observation/edit/${observation.identifier}`}
              />
            </Grid>
          </Grid>
          <Grid item lg={6} md={12}>
            <DisplayField heading="Title" value={observation.title} />
            {observation.raisedBy && <DisplayField heading="Raised By" value={observation.raisedBy.name} />}
            <DisplayField dangerousHtml value={observation.description} heading="Description" />
            <DisplayField heading="Status" value={OBSERVATION_STATUS_TYPE[observation.status].label} />
            {observation.images.length > 0 && (
              <Fragment>
                <NewSectionHeading heading="Images" />
                <div className={classNames(classes.rowDirection, classes.galleryContainer)}>
                  {observation.images.map(image => (
                    <div
                      onClick={() => {
                        this.openGallery(image.identifier);
                      }}
                    >
                      <img src={image.thumbnailUrl} />
                    </div>
                  ))}
                </div>
              </Fragment>
            )}
          </Grid>
          {showGallery && (
            <ReppleImageGallery
              gallery={this.getGalleryImages()}
              thumbnailId={thumbnailId}
              closeGallery={this.closeGallery}
              thumbnailIndex={observation.images.findIndex(item => item.identifier === thumbnailId)}
            />
          )}
        </Grid>
      )
    );
  }
}

ObservationView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  createInitialEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  observation: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'observation'),
  isLoading: loadingSelector(state, 'getEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, createInitialEntity })
)(ObservationView);

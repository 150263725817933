import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import color from 'color';
import { colors, commonFont } from '../../assets/constants';

const style = {
  submit: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.white,
    background: colors.orangeNew,
    minWidth: '164px',
    boxShadow: '0px 8px 20px rgba(246, 138, 26, 0.25)',
    borderRadius: '5px',
    height: '45px',
    textTransform: 'none',
    ...commonFont,
    fontWeight: 600,
    fontSize: '14px',
    '&:hover': {
      background: color(colors.orangeNew)
        .lighten(0.1)
        .hex()
    }
  }
};

const SaveButton = ({ classes, isSaving, isDisabled, onSubmit, text }) => (
  <Button
    data-qa="ok"
    className={classes.submit}
    variant="contained"
    color="primary"
    onClick={onSubmit}
    disabled={isSaving || isDisabled}
  >
    {isSaving ? <CircularProgress size={15} color="inherit" col /> : text}
  </Button>
);

SaveButton.defaultProps = {
  text: 'Save'
};

SaveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  text: PropTypes.string
};

export default withStyles(style)(SaveButton);

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { postInitialState } from '../entityInitialStates';

export const listPostRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_POST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'posts'
  };
};

export const getPostRequest = id => ({
  url: `${process.env.REACT_APP_POST_GET}${id}`,
  entityName: 'post',
  initialState: postInitialState
});

export const deletePostRequest = id => ({
  url: `${process.env.REACT_APP_POST_DELETE}${id}`,
  entityName: 'post'
});

export const savePostRequest = entity => ({ url: process.env.REACT_APP_POST_SAVE, entityName: 'post', entity });

export const getPostFeedbackRequest = id => ({
  url: `${process.env.REACT_APP_POST_GET}${id}/feedback`,
  entityName: 'feedbacks',
  initialState: []
});

export const savePostFeedbackRequest = (id, entity) => ({
  url: `${process.env.REACT_APP_POST_SAVE}/${id}/feedback`,
  entityName: 'post',
  entity
});

import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import WarningIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import { SnackbarContent, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import ReppleImageGallery from '../widgets/ReppleImageGallery';
import { convertUtcToLocal, hasPermission } from '../../utils/utilities';
import { permissions } from '../../constants/global';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import LinkButton from '../widgets/LinkButton';
import NewActionButton from '../widgets/NewActionButton';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style,
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  marginLeft: {
    marginLeft: '10px'
  },
  warning: {
    backgroundColor: '#ffa000'
  }
};

class ShowUser extends React.PureComponent {
  state = {
    galleryOpen: false,
    thumbnailIndex: 0
  };

  openGallery = index => {
    this.setState({
      galleryOpen: true,
      thumbnailIndex: index
    });
  };

  closeGallery = () => {
    this.setState({
      galleryOpen: false,
      thumbnailIndex: 0
    });
  };

  handleDeleteFileUpload = identifier => {
    const { thumbnailIndex } = this.state;
    const { deleteUserFileDownload } = this.props;
    deleteUserFileDownload(identifier);

    this.setState({
      thumbnailIndex: thumbnailIndex - 1 <= 0 ? 0 : thumbnailIndex - 1
    });
  };

  renderGallery = () => {
    const { documentDownloads, classes, deleteUserFileDownload } = this.props;
    const { galleryOpen, thumbnailIndex } = this.state;
    const images = documentDownloads.map(download => ({
      original: download.url,
      name: download.name,
      thumbnail: download.thumbnailUrl,
      identifier: download.identifier
    }));

    return images.length > 0 ? (
      <div>
        {galleryOpen && (
          <ReppleImageGallery
            gallery={images}
            thumbNailIndex={thumbnailIndex}
            closeGallery={this.closeGallery}
            deleteUserFileDownload={deleteUserFileDownload ? this.handleDeleteFileUpload : null}
          />
        )}
        {!galleryOpen && images.length > 0 && (
          <div className={classes.thumbnailContainer}>
            {images.map((image, index) => {
              return (
                <Tooltip title={image.name} placement="bottom" key={`image${image.thumbnail}`}>
                  <div onClick={() => this.openGallery(index)}>
                    <img alt="" src={image.thumbnail} className={classes.thumbnail} />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
      </div>
    ) : null;
  };

  render() {
    const {
      classes,
      user,
      updateUserStatus,
      resetPassword,
      editUrl,
      type,
      recreateCognitoUser,
      deleteUser
    } = this.props;

    const gallery = this.renderGallery();

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: false
        })}
      >
        <NewHeaderViewActions
          editUrl={editUrl}
          header="User Details"
          cancelUrl={type === 'Holder' ? '/admin/holder' : '/organisation/user'}
        >
          {type === 'Holder' && (
            <Fragment>
              <LinkButton
                url={`/admin/holder/view/${user.identifier}/reps`}
                primary={false}
                text="Reps"
                icon={<i className="material-icons">functions</i>}
              />
              <LinkButton
                url={`/admin/holder/view/${user.identifier}/credentials`}
                primary={false}
                text="Credentials"
                icon={<i className="material-icons">list_alt</i>}
              />
            </Fragment>
          )}
          {hasPermission([permissions.SYSTEM_ADMIN]) && (
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">support</i>}
              text="Recreate Cognito User"
              onChange={recreateCognitoUser}
              filled
            />
          )}
          {user.enabled ? (
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">power_off</i>}
              text={`Disable ${type}`}
              onChange={updateUserStatus}
              filled
            />
          ) : (
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">power</i>}
              text={`Enable ${type}`}
              onChange={updateUserStatus}
              filled
            />
          )}
          <NewActionButton
            primary={false}
            icon={<i className="material-icons">security</i>}
            text="Reset Password"
            onChange={resetPassword}
            filled
          />
          {type === 'User' && (
            <NewActionButton
              primary={false}
              icon={<i className="material-icons">delete</i>}
              text="Delete User"
              onChange={deleteUser}
              filled
            />
          )}
        </NewHeaderViewActions>
        <Grid item xs={9} lg={6} md={6}>
          {user.wrongEmail && (
            <SnackbarContent
              message={
                /* eslint-disable react/jsx-wrap-multilines */
                <span id="client-snackbar" className={classes.message}>
                  <WarningIcon className={classes.iconVariant} />
                  <div className={classes.marginLeft}>
                    The email address of this user cannot have email sent to it. Please update it.
                  </div>
                </span>
                /* eslint-enable react/jsx-wrap-multilines */
              }
              role="alert"
              className={classes.warning}
            />
          )}
          <DisplayField heading="Name" value={`${user.firstName} ${user.lastName}`} />
          <DisplayField heading="Phone" value={user.phone} />
          <DisplayField heading="Email" value={user.email} />
          {type === 'User' && <DisplayField heading="Role" value={user.role.name} />}
          {user.passwordResetTokenExpiryDate && (
            <DisplayField
              heading="Password Reset Expiry"
              value={convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', user.passwordResetTokenExpiryDate)}
            />
          )}
          <DisplayField
            heading="Enabled"
            value={user.enabled ? <i className="material-icons">done</i> : <i className="material-icons">clear</i>}
          />
          <DisplayField heading="Status" value={user.status} />
          {type === 'Holder' && <DisplayField heading="Reps" value={user.repPoints} />}
          {gallery && (
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Facial Recognition Images</label>
              <div className={classes.displayField}>{this.renderGallery()}</div>
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

ShowUser.defaultProps = {
  documentDownloads: []
};

ShowUser.propTypes = {
  classes: PropTypes.object.isRequired,
  updateUserStatus: PropTypes.func,
  updateUserLockStatus: PropTypes.func,
  lockUser: PropTypes.func,
  unlockUser: PropTypes.func,
  resetLoginAttempts: PropTypes.func,
  resetPassword: PropTypes.func,
  deleteUserFileDownload: PropTypes.func,
  recreateCognitoUser: PropTypes.func,
  deleteUser: PropTypes.func,
  user: PropTypes.object,
  documentDownloads: PropTypes.array,
  editUrl: PropTypes.string,
  overrideAuthType: PropTypes.string,
  type: PropTypes.string.isRequired,
  isLoading: PropTypes.bool
};

export default withStyles(styles)(ShowUser);

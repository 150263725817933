import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import color from 'color';
import { Button, CircularProgress, MuiThemeProvider } from '@material-ui/core';
import { sizes, colors, customTheme } from '../../assets/constants';
import { commonButtonStyles } from '../../assets/buttons';

const style = {
  submit: {
    ...commonButtonStyles,
    color: colors.white,
    background: colors.orangeDark,
    marginLeft: sizes.halfSpacing,
    '&:hover': {
      background: color(colors.orangeDark)
        .lighten(0.1)
        .hex()
    }
  }
};

const ActionButton = ({ classes, isActionOccurring, isDisabled, buttonText, onClick }) => (
  <Button
    data-qa="ok"
    className={classes.submit}
    variant="contained"
    color="primary"
    onClick={onClick}
    disabled={isActionOccurring || isDisabled}
  >
    {isActionOccurring ? (
      <MuiThemeProvider theme={customTheme}>
        <CircularProgress size={15} color="inherit" />
      </MuiThemeProvider>
    ) : (
      buttonText
    )}
  </Button>
);

ActionButton.propTypes = {
  classes: PropTypes.object.isRequired,
  isActionOccurring: PropTypes.bool,
  isDisabled: PropTypes.bool,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default withStyles(style)(ActionButton);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { documentDownloadsSelector } from '../../store/selectors/document';
import {
  listCredentialRequestFileDownloads,
  listCredentialFileDownloads,
  downloadFile
} from '../../store/actions/DocumentActions';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';

import layout from '../../assets/layout';
import { sizes } from '../../assets/constants';
import { convertUtcToLocal } from '../../utils/utilities';
import { getCredentialVersionRequest } from '../../utils/entityRequests/credentialRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style,
  overlayContainer: {
    backgroundColor: '#fff',
    opacity: 1,
    height: '100vh',
    zIndex: '100001',
    width: '100%',
    overflowY: 'auto',
    boxShadow: '-1px 0px 3px -1px rgba(0,0,0,0.49)'
  },
  closeButton: {
    marginLeft: '100px'
  },
  formPage: {
    padding: sizes.globalSide,
    boxShadow: 'none !important'
  }
};

class CredentialVersionView extends React.Component {
  async componentDidMount() {
    const { getEntity, match, listCredentialFileDownloads } = this.props;
    const { params } = match;
    const { id, transactionId } = params;
    await listCredentialFileDownloads(id);
    await getEntity(getCredentialVersionRequest(id, transactionId));
  }

  handleDownloadFile = (url, fileName, openInNewTab) => {
    const { downloadFile } = this.props;
    downloadFile(url, fileName, openInNewTab);
  };

  render() {
    const { classes, credentialVersion } = this.props;
    return (
      Object.keys(credentialVersion).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions header="Credential Version Details" cancelUrl="/credential/inbox" />

          <Grid item xs={6}>
            <DisplayField value={credentialVersion.credentialTypeName} heading="Credential Type" />
            <DisplayField value={credentialVersion.holderName} heading="Holder Name" />
            <DisplayField value={credentialVersion.createDate} heading="Create Date" />
            <DisplayField value={credentialVersion.expiryDate || 'Not Set'} heading="Expiry Date" />
            <DisplayField
              value={
                convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm', credentialVersion.revocationDate) ||
                'Not Set'
              }
              heading="Revocation Date"
            />
          </Grid>
        </Grid>
      )
    );
  }
}

CredentialVersionView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  listCredentialRequestFileDownloads: PropTypes.func,
  listCredentialFileDownloads: PropTypes.func,
  downloadFile: PropTypes.func,
  credentialVersion: PropTypes.object,
  credentialRequest: PropTypes.object,
  fileDownloads: PropTypes.array,
  credentialRequestFileDownloads: PropTypes.array
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'credentialRequest'),
  ...entitySelector(state, 'credentialVersion'),
  ...documentDownloadsSelector(state)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    listCredentialRequestFileDownloads,
    listCredentialFileDownloads,
    downloadFile,
    getEntity
  }),
  withRouter
)(CredentialVersionView);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities, putEntity, deleteEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { tableSelector } from '../../store/selectors/table';
import { deletePostRequest, listPostRequest } from '../../utils/entityRequests/postRequests';
import { convertUtcToLocal, getFirstPath } from '../../utils/utilities';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class PostListView extends React.Component {
  handleDelete = (event, id) => {
    const { deleteEntity, listEntities } = this.props;
    event.stopPropagation();
    event.preventDefault();
    deleteEntity(deletePostRequest(id)).then(() => {
      listEntities(0, 20, listPostRequest());
    });
  };

  actionNavigate = id => {
    const { history, location } = this.props;
    const { pathname } = location;
    history.push(`${getFirstPath(pathname)}/post/view/${id}`);
  };

  getRowConfigs = () => {
    const { table } = this.props;
    const items = table.payload ? table.payload.records : [];
    return items.map(post => ({
      clickAction: () => this.actionNavigate(post.identifier),
      actions: [
        {
          action: (
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={event => this.handleDelete(event, post.identifier)}>
                <i className="material-icons">delete</i>
              </IconButton>
            </Tooltip>
          )
        }
      ],
      cells: [
        { value: post.name },
        { value: post.postedBy.name },
        { value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', post.postedDate) }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Name' }, { value: 'Posted By' }, { value: 'Posted Date' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Posts Found"
        overrideHeight="calc(100vh - 140px)"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, table } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
          <NewHeaderActions
            header="Post"
            buttons={[
              {
                text: 'Add Post',
                icon: <AddWhiteIcon />,
                url: '/admin/post/create',
                primary: true
              }
            ]}
          />

          <ListingWithSearch
            name="posts"
            onList={listEntities}
            renderList={props => this.getListing(props)}
            list={table}
            // hideSearch
            additionalSearchParams={listPostRequest}
          />
        </Grid>
      </Grid>
    );
  }
}

PostListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  putEntity: PropTypes.func,
  deleteEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities'),
  ...tableSelector(state, 'posts')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listEntities, putEntity, deleteEntity })
)(PostListView);

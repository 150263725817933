import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { courseInitialState } from '../entityInitialStates';

export const listCatalogRequest = (organisationUuid, searchText) => {
  return {
    /* eslint-disable max-len */
    url: `${process.env.REACT_APP_CATALOG_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${organisationUuid}?searchText=${searchText}`,
    entityName: 'catalog'
  };
};

export const getCatalogCourseRequest = id => ({
  url: `${process.env.REACT_APP_CATALOG_COURSE_GET}${id}`,
  entityName: 'course',
  initialState: courseInitialState
});

export const getCatalogCourseScheduleRequest = id => ({
  url: `${process.env.REACT_APP_CATALOG_COURSE_GET}${id}/scheduledCourse/list`,
  entityName: 'scheduledCourses',
  initialState: []
});

export const getCatalogCourseScheduledCourseRequest = id => ({
  url: `${process.env.REACT_APP_CATALOG_SCHEDULED_COURSE_GET}${id}`,
  entityName: 'scheduledCourse',
  initialState: []
});

export const enrolScheduledCourseRequest = (scheduledCourseId, holderUuid) => ({
  url: `${process.env.REACT_APP_CATALOG_SCHEDULED_COURSE_ENROL_POST}${scheduledCourseId}/${holderUuid}`,
  entityName: 'enrolled'
});

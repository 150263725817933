import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  ExpansionPanelDetails,
  Grid,
  MenuList,
  MenuItem,
  Tooltip,
  Divider,
  ExpansionPanel,
  ExpansionPanelActions,
  FormControlLabel,
  Switch,
  IconButton,
  Menu,
  Paper,
  ExpansionPanelSummary
} from '@material-ui/core';
import { Delete, MoreVert } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import form from '../../assets/formStyles';
import { VALIDATORS_BY_NAME, CONTROL_TYPE } from '../../assets/constants';
import ValidatedField from '../widgets/ValidatedField';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { getFormRequest, saveFormRequest } from '../../utils/entityRequests/formRequests';
import { formInitialState } from '../../utils/entityInitialStates';

const styles = () => ({
  ...style,
  ...layout,
  ...form
});

class ChangeFormView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formModel: {
        ...formInitialState,
        formType: ''
      },
      expanded: 'section-0-control-0',
      anchorEl: {}
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/credential/credentialDefinition/form/view/${id}` : '/form';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const form = await getEntity(getFormRequest(match.params.id));
      this.setState({ formModel: form });
    } else {
      this.setState({
        formModel: formInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ formModel: formInitialState });
  }

  handlePanelChange = id => {
    const { formModel } = this.state;
    this.setState({
      ...formModel,
      expanded: id
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);
    _.set(form, name, value);

    this.setState({
      formModel: form
    });
  };

  handleAddControl = (id, path) => {
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);
    const controls = _.get(form, `${path}.controls`);
    _.set(form, `${path}.controls[${controls.length}]`, {
      type: CONTROL_TYPE.TEXT.id,
      label: '',
      required: false,
      selectOptions: []
    });

    this.setState({
      expanded: `${id}-control-${controls.length - 1}`,
      formModel: form
    });
  };

  handleDeleteControl = (id, path, index) => {
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);

    const items = _.get(form, path);
    _.pullAt(items, index);

    this.setState({
      expanded: id,
      formModel: form
    });
  };

  handleAddSection = () => {
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);
    const sections = _.get(form, 'sections');
    _.set(form, `sections[${sections.length}]`, {
      noColumns: 0,
      controls: [{ type: CONTROL_TYPE.TEXT.id, label: '', required: false, selectOptions: [] }],
      title: ''
    });

    this.setState({
      expanded: `section-${sections.length - 1}-control-0`,
      formModel: form
    });
  };

  getMenuList = (id, path) => {
    const { classes } = this.props;
    return (
      <Paper
        className={classes.paper}
        style={{
          right: '-60px',
          top: 0,
          width: '40px',
          position: 'absolute',
          justifyContent: 'center',
          display: 'flex'
        }}
      >
        <MenuList>
          <MenuItem className={classes.menuItem} onClick={() => this.handleAddControl(id, path)}>
            <Tooltip title="Add Control" aria-label="add" placement="right">
              <i className={classNames(['material-icons', classes.greyIcon])}>add_circle</i>
            </Tooltip>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.handleAddSection}>
            <Tooltip title="Add Section" aria-label="add" placement="right">
              <i className={classNames(['material-icons', classes.greyIcon])}>view_agenda</i>
            </Tooltip>
          </MenuItem>
        </MenuList>
      </Paper>
    );
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { formModel } = this.state;

    const formId = await saveEntity(saveFormRequest(formModel));

    if (!formId) return;

    history.push(`/credential/credentialDefinition/form/view/${formId}`);
  };

  handleClick = e => {
    const { formModel } = this.state;
    const { name } = e.target;
    const form = _.cloneDeep(formModel);
    const checked = _.get(form, name);
    this.handleChange({ target: { value: !checked, name } });
  };

  openMenu = (e, index) => {
    const { currentTarget } = e;
    this.setState(prevState => ({
      ...prevState,
      anchorEl: {
        ...prevState.anchorEl,
        [index]: currentTarget
      }
    }));
  };

  closeMenu = e => {
    this.setState(prevState => ({
      ...prevState,
      anchorEl: null
    }));
  };

  handleDeleteSection = (path, index) => {
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);
    const items = _.get(form, path);
    _.pullAt(items, index);
    this.setState({
      expanded: 'section-0',
      formModel: form,
      anchorEl: null
    });
  };

  handleDeleteControlChildArrayItem = (path, index) => {
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);
    const items = _.get(form, path);
    _.pullAt(items, index);
    this.setState({
      formModel: form
    });
  };

  handleAddControlChildArrayItem = (path, element) => {
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);

    const items = _.get(form, path);
    _.set(form, `${path}[${items.length}]`, element);

    this.setState({
      formModel: form
    });
  };

  handleChangeControlType = (event, path) => {
    const { name, value } = event.target;
    const { formModel } = this.state;
    const form = _.cloneDeep(formModel);
    _.set(form, name, value);
    _.set(form, `${path}.selectOptions`, []);

    this.setState({
      formModel: form
    });
  };

  render() {
    const { classes, isSaving, errors, templates } = this.props;
    const { formModel, expanded, anchorEl } = this.state;
    const { sections } = formModel;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={6}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header="Form Details"
            />
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.sectionHeadingContainer}>
                  <div className={classes.sectionHeading}>Form Details</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <ValidatedField
                    id="name"
                    label="Name"
                    name="name"
                    value={formModel.name}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.sectionHeadingContainer}>
                  <div className={classes.sectionHeading}>Sections</div>
                </div>
              </Grid>

              <Grid item xs={12} style={{ marginLeft: '2px' }}>
                {sections.map((section, sectionIndex) => (
                  <div className={classes.sectionContainer} key={`sectionContainer${sectionIndex}`}>
                    <div
                      className={classNames(
                        {
                          [classes.titleContainerSelected]: expanded === `section-${sectionIndex}`
                        },
                        classes.titleContainer
                      )}
                    >
                      {sections.length > 1 && (
                        <div>
                          <IconButton
                            aria-label="more"
                            aria-controls={`long-menu${sectionIndex}`}
                            aria-haspopup="true"
                            adam={sectionIndex}
                            onClick={e => this.openMenu(e, sectionIndex)}
                          >
                            <MoreVert />
                          </IconButton>
                          <Menu
                            id={`long-menu${sectionIndex}`}
                            anchorEl={anchorEl[sectionIndex]}
                            keepMounted
                            getContentAnchorEl={null}
                            anchorReference="anchorEl"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right'
                            }}
                            open={!!anchorEl[sectionIndex]}
                            onClose={this.closeMenu}
                          >
                            <MenuItem
                              key="deleteSection"
                              onClick={() => {
                                this.handleDeleteSection('sections', sectionIndex);
                              }}
                            >
                              Delete Section
                            </MenuItem>
                          </Menu>
                        </div>
                      )}
                      <div className={classes.controlsContainer}>
                        <ValidatedField
                          id={`sections[${sectionIndex}].title`}
                          label="Section Title"
                          name={`sections[${sectionIndex}].title`}
                          value={sections[sectionIndex].title}
                          onChange={this.handleChange}
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                          onClick={() => this.handlePanelChange(`section-${sectionIndex}`)}
                          errorText={errors && errors.fieldErrors[`sections[${sectionIndex}].title`]}
                        />
                        {expanded === `section-${sectionIndex}` &&
                          this.getMenuList(`section-${sectionIndex}`, `sections[${sectionIndex}]`)}
                      </div>
                    </div>

                    {section.controls.map((control, controlIndex) => (
                      <ExpansionPanel
                        key={`expansionPanel-${sectionIndex}-${controlIndex}`}
                        expanded={expanded === `section-${sectionIndex}-control-${controlIndex}`}
                        onChange={() => this.handlePanelChange(`section-${sectionIndex}-control-${controlIndex}`)}
                        className={classNames({
                          [classes.controlPanelSelected]:
                            expanded === `section-${sectionIndex}-control-${controlIndex}`,
                          [classes.controlPanel]: expanded !== `section-${sectionIndex}-control-${controlIndex}`
                        })}
                      >
                        <ExpansionPanelSummary className={classes.panelSummary}>
                          <div className={classes.expandIconContainer}>
                            <i className="material-icons">drag_handle</i>
                          </div>
                          <div
                            className={classes.controlLabelAndTypeContainer}
                            key={`section-sections[${sectionIndex}].controls[${controlIndex}]`}
                          >
                            <ValidatedField
                              id={`sections[${sectionIndex}].controls[${controlIndex}].label`}
                              label="Control Label"
                              name={`sections[${sectionIndex}].controls[${controlIndex}].label`}
                              value={sections[sectionIndex].controls[controlIndex].label}
                              onChange={this.handleChange}
                              validators={[VALIDATORS_BY_NAME.REQUIRED]}
                              onClick={() => this.handlePanelChange(`section-${sectionIndex}-control-${controlIndex}`)}
                            />
                            <ValidatedField
                              type={VALIDATED_FIELD_TYPES.SELECT}
                              label="Type"
                              value={sections[sectionIndex].controls[controlIndex].type}
                              handleChange={e =>
                                this.handleChangeControlType(e, `sections[${sectionIndex}].controls[${controlIndex}]`)
                              }
                              values={[
                                CONTROL_TYPE.TEXT,
                                CONTROL_TYPE.MULTIPLE_CHOICE,
                                CONTROL_TYPE.CHECKBOXES,
                                CONTROL_TYPE.DROP_DOWN,
                                CONTROL_TYPE.DATE,
                                CONTROL_TYPE.FILE
                              ]}
                              overrideClassName={classes.typeControl}
                              id={`sections[${sectionIndex}].controls[${controlIndex}].type`}
                              optionName="label"
                              optionValue="id"
                              validators={[VALIDATORS_BY_NAME.REQUIRED]}
                            />
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column' }}>
                          {/* eslint-disable max-len */ control.selectOptions.map((selectOption, selectOptionIndex) => (
                            <div
                              className={classes.controlLabelAndTypeContainer}
                              key={`selectOptions-sections[${sectionIndex}].controls[${controlIndex}].selectionOptions[${selectOptionIndex}]`}
                            >
                              <ValidatedField
                                key={`sections[${sectionIndex}].controls[${controlIndex}].selectOptions[${selectOptionIndex}].name`}
                                name={`sections[${sectionIndex}].controls[${controlIndex}].selectOptions[${selectOptionIndex}].name`}
                                label={`Option ${selectOptionIndex}`}
                                value={
                                  sections[sectionIndex].controls[controlIndex].selectOptions[selectOptionIndex].name
                                }
                                onChange={this.handleChange}
                                validators={[VALIDATORS_BY_NAME.REQUIRED]}
                              />
                              {control.selectOptions.length > 1 && (
                                <IconButton
                                  variant="fab"
                                  onClick={() =>
                                    this.handleDeleteControlChildArrayItem(
                                      `sections[${sectionIndex}].controls[${controlIndex}].selectOptions`,
                                      selectOptionIndex
                                    )
                                  }
                                >
                                  <i className="material-icons">clear</i>
                                </IconButton>
                              )}
                            </div>
                          )) /* eslint-disable max-len */}
                          {/* eslint-disable indent */ control.type !== CONTROL_TYPE.TEXT.id &&
                            control.type !== CONTROL_TYPE.DATE.id &&
                            control.type !== CONTROL_TYPE.FILE.id && (
                              <div style={{ display: 'flex' }}>
                                <Button
                                  size="small"
                                  variant="text"
                                  onClick={() =>
                                    this.handleAddControlChildArrayItem(
                                      `sections[${sectionIndex}].controls[${controlIndex}].selectOptions`,
                                      { name: '' }
                                    )
                                  }
                                >
                                  Add Option
                                </Button>
                              </div>
                            )}
                          {this.getMenuList(
                            `section-${sectionIndex}`,
                            `sections[${sectionIndex}]`
                          ) /* eslint-enable indent */}
                        </ExpansionPanelDetails>
                        <Divider />
                        <ExpansionPanelActions>
                          {sections[sectionIndex].controls.length > 1 && (
                            <Tooltip title="Delete Control" aria-label="add" placement="right">
                              <IconButton
                                variant="fab"
                                onClick={() =>
                                  this.handleDeleteControl(
                                    `section-${sectionIndex}`,
                                    `sections[${sectionIndex}].controls`,
                                    controlIndex
                                  )
                                }
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          )}
                          <div className={classes.actionSeparator}>&nbsp;</div>
                          <FormControlLabel
                            classes={{ label: classNames(classes.label, classes.checkboxLabel) }}
                            control={
                              /* eslint-disable react/jsx-wrap-multilines */
                              <Switch
                                classes={{
                                  switchBase: classes.switchBase,
                                  track: classes.track,
                                  checked: classes.checked
                                }}
                                checked={sections[sectionIndex].controls[controlIndex].required}
                                name={`sections[${sectionIndex}].controls[${controlIndex}].required`}
                                onClick={this.handleClick}
                              />
                              /* eslint-enable react/jsx-wrap-multilines */
                            }
                            label="Required"
                            labelPlacement="start"
                          />
                        </ExpansionPanelActions>
                      </ExpansionPanel>
                    ))}
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeFormView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  templates: PropTypes.array,
  formModel: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'form'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeFormView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { entityArraySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import ChangeUser from '../common/ChangeUser';
import { userInitialState } from '../../utils/entityInitialStates';
import { searchOrganisationMemberRequest } from '../../utils/entityRequests/organisationMemberRequests';

import {
  getUserRequest,
  listRoleRequest,
  listPermissionRequest,
  saveUserRequest
} from '../../utils/entityRequests/userAdminRequests';
import _ from 'lodash';

const styles = () => ({
  ...style,
  ...layout
});

class ChangeUserView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: userInitialState,
      findUserTimeout: null,
      checkAddHolder: false,
      addHolder: false,
      foundHolder: null
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/organisation/user/view/${id}` : '/organisation/user';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const user = await getEntity(getUserRequest(match.params.id));
      this.setState({ user });
    } else {
      this.setState({
        user: userInitialState
      });
    }

    getEntity(listRoleRequest());
    getEntity(listPermissionRequest());
  }

  componentWillUnmount() {
    this.setState({ user: userInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        [name]: value
      }
    }));
  };

  handleClose = () => {
    this.setState({
      checkAddUser: false
    });
  };

  handleEmailPhoneChange = async event => {
    const { name, value } = event.target;
    const { user, findUserTimeout } = this.state;
    const { getEntity } = this.props;
    const userResult = _.cloneDeep(user);
    _.set(userResult, name, value);

    if (!user.identifier) {
      clearTimeout(findUserTimeout);

      this.setState({
        user: userResult,
        findUserTimeout: setTimeout(async () => {
          const foundHolder = await getEntity(searchOrganisationMemberRequest(value));
          if (foundHolder.uuid) {
            this.setState({
              foundHolder,
              checkAddHolder: true
            });
          }
        }, 1000)
      });
    }

    this.setState({
      user: userResult
    });
  };

  togglePermissionChange = event => {
    const { target } = event;
    const { name } = target;
    const { user } = this.state;
    const permissions = user.permissions.slice();

    if (permissions.indexOf(name) === -1) permissions.push(name);
    else permissions.splice(permissions.indexOf(name), 1);

    this.setState({
      user: {
        ...user,
        permissions
      }
    });
  };

  handleRoleChange = event => {
    const { value } = event.target;
    const { roles } = this.props;

    const role = roles.find(item => item.identifier === value);

    this.setState(prevState => ({
      ...prevState,
      user: {
        ...prevState.user,
        role,
        permissions: role.permissions.map(item => item.identifier)
      }
    }));
  };

  handleNo = () => {
    this.setState({
      checkAddHolder: false,
      addHolder: false
    });
  };

  handleYes = () => {
    const { user, foundHolder } = this.state;
    this.setState({
      checkAddHolder: false,
      addHolder: true,
      user: {
        ...user,
        firstName: foundHolder.name.split(' ')[0],
        lastName: foundHolder.name.split(' ').length > 1 ? foundHolder.name.split(' ')[1] : '',
        phone: foundHolder.phone,
        email: foundHolder.email,
        identifier: foundHolder.identifier
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { user, addHolder } = this.state;

    const userId = await saveEntity(saveUserRequest(user, addHolder));

    if (!userId) return;

    history.push(`/organisation/user/view/${userId}`);
  };

  render() {
    const { isSaving, roles, match, permissions, errors } = this.props;
    const { user, foundHolder, checkAddHolder, addHolder } = this.state;
    const { id } = match.params;
    const cancelUrl = id ? `/organisation/user/view/${id}` : '/organisation/user';

    return (
      <ChangeUser
        roles={roles}
        permissions={permissions}
        user={user}
        cancelUrl={cancelUrl}
        handleChange={this.handleChange}
        handleEmailPhoneChange={this.handleEmailPhoneChange}
        togglePermissionChange={this.togglePermissionChange}
        handleRoleChange={this.handleRoleChange}
        handleNo={this.handleNo}
        handleYes={this.handleYes}
        checkLinkHolder={checkAddHolder}
        handleSave={this.submitForm}
        type="User"
        isSaving={isSaving}
        foundHolder={addHolder ? foundHolder : null}
        handleClose={this.handleClose}
        errors={errors}
      />
    );
  }
}

ChangeUserView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
  errors: PropTypes.object,
  roles: PropTypes.array,
  isSaving: PropTypes.bool
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'user'),
  isSaving: loadingSelector(state, 'saveEntity'),
  ...entityArraySelector(state, 'roles'),
  ...entityArraySelector(state, 'permissions')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeUserView);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import authStyles from '../../assets/authStyles';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import ValidatedField from '../widgets/ValidatedField';
import { passwordResetSelector } from '../../store/selectors/authentication';
import { loadingSelector } from '../../store/selectors/shared';
import NewActionButton from '../widgets/NewActionButton';

const styles = {
  ...authStyles
};

class ChangePasswordView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changePasswordModel: {
        password: '',
        repeatedPassword: ''
      }
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { changePasswordModel } = this.state;
    this.setState({
      changePasswordModel: {
        ...changePasswordModel,
        [name]: value
      }
    });
  };

  handleSubmit = async () => {
    const { changePassword } = this.props;
    const { changePasswordModel } = this.state;
    changePassword(changePasswordModel.password);
  };

  render() {
    const { classes, cancelChangePassword, isSaving } = this.props;
    const { changePasswordModel } = this.state;
    const { password, repeatedPassword } = changePasswordModel;
    const commonProps = {
      onChange: this.handleChange,
      validators: [VALIDATORS_BY_NAME.REQUIRED],
      autoComplete: 'off'
    };

    return (
      <div className={classNames(classes.formContainer, classes.passwordContainer)}>
        <ValidatedField
          id="password"
          name="password"
          label="Password"
          value={password}
          type="PASSWORD"
          {...commonProps}
        />

        <ValidatedField
          id="repeatedPassword"
          name="repeatedPassword"
          label="Confirm Password"
          value={repeatedPassword}
          type="PASSWORD"
          {...commonProps}
        />
        <div className={classes.bottomFields}>
          <Link
            to="/login"
            className={classes.cancelLink}
            onClick={() => {
              cancelChangePassword();
            }}
          >
            Cancel
          </Link>
          <NewActionButton
            onChange={this.handleSubmit}
            text="Change Password"
            primary
            filled
            className={classes.loginButton}
            isSaving={isSaving}
            style={{ width: '234px' }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...passwordResetSelector(state),
  isSaving: loadingSelector(state, 'completeNewPassword')
});

ChangePasswordView.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  changePassword: PropTypes.func,
  isSaving: PropTypes.bool,
  cancelChangePassword: PropTypes.func
};

export default connect(mapStateToProps, {})(withStyles(styles)(ChangePasswordView));

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { style } from '../../assets/style';
import { IMPORT_STATUS } from '../../assets/constants';
import ScrollableTable from '../table/ScrollableTable';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { convertUtcToLocal, getRecords } from '../../utils/utilities';
import { tableSelector } from '../../store/selectors/table';
import { listIssuerCredentialTypeReferenceRequest } from '../../utils/entityRequests/credentialTypeRequests';
import { listImportRequest } from '../../utils/entityRequests/importRequests';
import { entityArraySelector } from '../../store/selectors/entity';
import NewHeaderActions from '../header/NewHeaderActions';

const styles = {
  ...style
};

class ImportListView extends React.Component {
  async componentDidMount() {
    const { getEntity } = this.props;

    await getEntity(listIssuerCredentialTypeReferenceRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/credential/import/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(item => ({
      clickAction: () => this.actionNavigate(item.identifier),
      cells: [
        {
          value: item.credentialTypeName
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', item.createDate)
        },
        {
          value: item.creatorName
        },
        {
          value: item.totalNumberRows
        },
        {
          value: IMPORT_STATUS[item.status].label
        }
      ]
    }));
  };

  getListing = props => {
    const { search, isLoading, credentialTypes } = this.props;
    const credentialTypeFilterValue =
      search && search.filters.credentialType ? search.filters.credentialType : NO_SEARCH_VALUE;
    const statusFilterValue = search && search.filters.status ? search.filters.status : NO_SEARCH_VALUE;

    return (
      <ScrollableTable
        headers={[
          {
            value: 'Credential Type',
            filter: {
              value: credentialTypeFilterValue,
              options: credentialTypes || [],
              onChange: event => props.handleFilterChange(event, 'credentialType')
            }
          },
          {
            value: 'Create Date'
          },
          {
            value: 'Creator'
          },
          {
            value: 'Number Rows'
          },
          {
            value: 'Status',
            filter: {
              value: statusFilterValue,
              options: [
                { label: IMPORT_STATUS.UPLOADED.label, value: IMPORT_STATUS.UPLOADED.id },
                { label: IMPORT_STATUS.IN_PROGRESS.label, value: IMPORT_STATUS.IN_PROGRESS.id },
                { label: IMPORT_STATUS.FAILED.label, value: IMPORT_STATUS.FAILED.id },
                { label: IMPORT_STATUS.COMPLETE.label, value: IMPORT_STATUS.COMPLETE.id }
              ],
              onChange: event => props.handleFilterChange(event, 'status')
            }
          }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Imports Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, credentialTypes, table, listImportRequest } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions header="Imports" buttons={[]} />

            <ListingWithSearch
              name="import"
              onList={listEntities}
              filtersList={{
                credentialType: credentialTypes,
                status: [
                  { label: IMPORT_STATUS.UPLOADED.label, value: IMPORT_STATUS.UPLOADED.id },
                  { label: IMPORT_STATUS.IN_PROGRESS.label, value: IMPORT_STATUS.IN_PROGRESS.id },
                  { label: IMPORT_STATUS.FAILED.label, value: IMPORT_STATUS.FAILED.id },
                  { label: IMPORT_STATUS.COMPLETE.label, value: IMPORT_STATUS.COMPLETE.id }
                ]
              }}
              renderList={props => this.getListing(props)}
              list={table}
              hideSearch
              additionalSearchParams={listImportRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ImportListView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  listEntities: PropTypes.func.isRequired,
  listImportRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  credentialTypes: PropTypes.array,
  table: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-import'),
  ...entityArraySelector(state, 'credentialTypes'),
  ...tableSelector(state, 'import')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    listEntities,
    listImportRequest
  })
)(ImportListView);

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { workPlaceInitialState } from '../entityInitialStates';

export const listWorkplaceRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_WORK_PLACE}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'workPlaces'
  };
};

export const getWorkPlaceRequest = id => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}${id}`,
  entityName: 'workPlace',
  initialState: workPlaceInitialState
});

export const saveWorkPlaceRequest = entity => ({
  url: process.env.REACT_APP_SAVE_WORK_PLACE,
  entityName: 'workPlace',
  entity
});

export const listOrganisationWorkplaceRequest = () => {
  return {
    url: process.env.REACT_APP_LIST_WORK_PLACE,
    entityName: 'organisationWorkPlaces',
    initialState: []
  };
};

export const listWorkplaceMemberRequest = id => {
  return {
    url: `${process.env.REACT_APP_GET_WORK_PLACE}${id}/member/list/${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'workPlaceMembers'
  };
};

export const getWorkPlaceMemberRequest = id => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}member/${id}`,
  entityName: 'workPlaceMember',
  initialState: {}
});

export const addWorkPlaceMemberRequest = (id, entity) => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}${id}/member`,
  entityName: 'workPlaceMember',
  entity
});

export const removeWorkPlaceMemberRequest = id => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}member/${id}`,
  entityName: 'workPlaceMember'
});

export const declineWorkPlaceMemberRequest = id => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}member/${id}/decline`,
  entityName: 'workPlaceMember'
});

export const acceptWorkPlaceMemberRequest = id => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}member/${id}/accept`,
  entityName: 'workPlaceMember'
});

export const listObservationRequest = workPlaceId => {
  return {
    url: `${process.env.REACT_APP_LIST_WORK_PLACE}${workPlaceId}/observation/${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'observations'
  };
};

export const getObservationRequest = id => ({
  url: `${process.env.REACT_APP_GET_WORK_PLACE}observation/${id}`,
  entityName: 'observation',
  initialState: {}
});

export const saveObservationRequest = entity => ({
  url: `${process.env.REACT_APP_SAVE_WORK_PLACE}/observation`,
  entityName: 'observation',
  entity
});

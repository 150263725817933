import { formInitialState } from '../entityInitialStates';
import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';

export const getFormRequest = id => ({
  url: `${process.env.REACT_APP_FORM_GET}${id}`,
  entityName: 'form',
  initialState: formInitialState
});

export const saveFormRequest = entity => ({ url: process.env.REACT_APP_FORM_SAVE, entityName: 'form', entity });

export const deprecateFormRequest = id => ({
  url: `${process.env.REACT_APP_FORM_DEPRECATE}${id}`,
  entityName: 'form',
  action: 'deprecated'
});

export const deleteFormRequest = id => ({
  url: `${process.env.REACT_APP_FORM_DEPRECATE}${id}`,
  entityName: 'form',
  action: 'deleted'
});

export const publishFormRequest = id => ({
  url: `${process.env.REACT_APP_FORM_PUBLISH}${id}`,
  entityName: 'form',
  action: 'published'
});

export const listFormRequest = () => (dispatch, getState) => {
  if (getState().search.form) {
    const { filters } = getState().search.form;
    const statusFilter = filters.status || NO_SEARCH_VALUE;
    return {
      url: `${process.env.REACT_APP_FORM_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${statusFilter}`,
      entityName: 'form'
    };
  }
};

export const getPublishedFormRequest = () => ({
  url: process.env.REACT_APP_FORM_PUBLISHED_LIST,
  entityName: 'publishedForms',
  initialState: []
});

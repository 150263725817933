import repplePageRoutes from './repplePageRoutes';
import trainingPageRoutes from './trainingPageRoutes';
import organisationPageRoutes from './organisationPageRoutes';

// Dashboard
import DashboardView from '../components/dashboard/DashboardView';
import credentialDefinitionPageRoutes from './credentialDefinitionPageRoutes';
import CredentialDefinitionView from '../components/view/CredentialDefinitionView';
import WorkplaceAdminView from '../components/view/WorkplaceAdminView';
import WorkforceView from '../components/view/WorkforceView';
import workplacePageRoutes from './workplacePageRoutes';
import workforcePageRoutes from './workforcePageRoutes';
import OrganisationAdminView from '../components/view/OrganisationAdminView';
import SuperAdminView from '../components/view/SuperAdminView';
import TrainingView from '../components/view/TrainingView';
import courseRoutes from './courseRoutes';
import CatalogView from '../components/view/CatalogView';
import ChangeProfileView from '../components/userAdmin/ChangeProfileView';
import ChangePasswordView from '../components/userAdmin/ChangePasswordView';
import ProfileView from '../components/userAdmin/ProfileView';

export default [
  ...repplePageRoutes,
  ...credentialDefinitionPageRoutes,
  ...workplacePageRoutes,
  ...workforcePageRoutes,
  ...trainingPageRoutes,
  ...organisationPageRoutes,
  ...courseRoutes,
  { path: '/credential', component: CredentialDefinitionView, breadcrumb: null, protected: true },
  { path: '/workplace', component: WorkplaceAdminView, breadcrumb: null, protected: true },
  { path: '/workforce', component: WorkforceView, breadcrumb: null, protected: true },
  { path: '/organisation', component: OrganisationAdminView, breadcrumb: null, protected: true },
  { path: '/admin', component: SuperAdminView, breadcrumb: null, protected: true },
  { path: '/training', component: TrainingView, breadcrumb: null, protected: true },
  { path: '/catalog', component: CatalogView, breadcrumb: null, protected: true },
  { path: '/profile/edit', component: ChangeProfileView, breadcrumb: null, protected: true },
  { path: '/profile/view', component: ProfileView, breadcrumb: null, protected: true },
  { path: '/changePassword', component: ChangePasswordView, breadcrumb: null, protected: true },

  // dashboard routes
  { path: '/', component: DashboardView, breadcrumb: null, protected: true }
];

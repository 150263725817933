import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import BackIcon from '../../assets/images/back-icon.svg';
import { toggleSetting } from '../../store/actions/SettingsActions';
import { settingSelector } from '../../store/selectors/shared';
import { colors, commonFont } from '../../assets/constants';
import SaveButton from '../widgets/SaveButton';

const styles = {
  cancel: {
    ...commonFont,
    fontSize: '14px',
    color: colors.blue,
    '& img': {
      marginRight: '8px'
    },
    marginRight: '22px'
  },
  lineSpacer: {
    borderLeft: `solid 1px ${colors.borderBlue}`,
    height: '35px'
  },
  header: {
    marginLeft: '19px',
    ...commonFont,
    fontSize: '28px',
    fontWeight: 600,
    color: colors.blueDark
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: '22px'
  },
  cancelAndHeader: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  }
};

class NewHeaderEditActions extends React.Component {
  componentDidMount() {
    const { toggleSetting, name } = this.props;

    toggleSetting(name, true);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    const { toggleSetting, name, preserveMode } = this.props;
    if (preserveMode) return;

    toggleSetting(name, false);
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  isCancelUrlFunction = () => typeof this.props.cancelUrl === 'function';

  handleKeyDown = e => {
    const { cancelUrl, history } = this.props;

    if (e.key === 'Escape') {
      if (this.isCancelUrlFunction()) {
        cancelUrl(e);
      } else {
        history.push(cancelUrl);
      }
    }
  };

  renderContent = () => {
    const { onSubmit, isSaving, isDisabled, submitButtonText, hasSubmitBtn } = this.props;

    return (
      <div>
        {hasSubmitBtn && (
          <SaveButton isSaving={isSaving} isDisabled={isDisabled} onSubmit={onSubmit} text={submitButtonText} />
        )}
      </div>
    );
  };

  render() {
    const { cancelUrl, header, classes, declineButtonText } = this.props;
    return (
      <div className={classes.headerContainer}>
        <div className={classNames(classes.cancelAndHeader, classes.flexRowCentered)}>
          {cancelUrl && (
            <Fragment>
              <Link to={cancelUrl} style={{ textDecoration: 'none' }} className={classes.cancel}>
                <img src={BackIcon} /> {declineButtonText}
              </Link>
              <div className={classes.lineSpacer} />
            </Fragment>
          )}
          <div className={classes.header}>{header}</div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

NewHeaderEditActions.defaultProps = {
  submitButtonText: 'Save',
  declineButtonText: 'Back',
  renderAsPortal: true,
  preserveMode: false,
  isDisabled: false,
  hasSubmitBtn: true,
  buttonClassName: ''
};

NewHeaderEditActions.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  cancelUrl: PropTypes.any,
  toggleSetting: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  isSaving: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  editMode: PropTypes.bool,
  hasSubmitBtn: PropTypes.bool,
  renderAsPortal: PropTypes.bool,
  preserveMode: PropTypes.bool,
  header: PropTypes.string,
  name: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string,
  declineButtonText: PropTypes.string,
  buttonClassName: PropTypes.string
};

const mapStateToProps = (state, ownProps) => ({
  editMode: settingSelector(state, `${ownProps.name}`)
});

export default compose(
  connect(mapStateToProps, { toggleSetting }),
  withStyles(styles),
  withRouter
)(NewHeaderEditActions);

export const NO_SEARCH_VALUE = 'ALL';
export const ALL = 'ALL';
export const NO_SEARCH_LABEL = 'All';
export const OPEN_VALUE = 'OPEN';
export const OPEN_LABEL = 'Open';
export const PAGE_TOKEN = '%%PAGE%%';
export const PAGE_SIZE_TOKEN = '%%PAGESIZE%%';

export const VALIDATED_FIELD_TYPES = {
  TEXT: 'TEXT',
  PASSWORD: 'PASSWORD',
  SELECT: 'SELECT',
  RADIO_GROUP: 'RADIO_GROUP',
  SELECT_WITH_INPUT: 'SELECT_WITH_INPUT',
  NUMBER: 'NUMBER',
  CHECKBOXES: 'CHECKBOXES',
  CHECKBOX: 'CHECKBOX',
  DATE: 'DATE',
  DATE_TIME: 'DATE_TIME',
  PHONE: 'PHONE',
  FILE: 'FILE'
};

export const permissions = {
  QUALIFICATION_ADMIN: 'QUALIFICATION_ADMIN',
  READ_DATA: 'READ_DATA',
  USER_ADMIN: 'USER_ADMIN',
  HOLDER_ADMIN: 'HOLDER_ADMIN',
  CATALOG_ADMIN: 'CATALOG_ADMIN',
  ORGANISATION_HOLDER_ADMIN: 'ORGANISATION_HOLDER_ADMIN',
  CREDENTIAL_MANAGEMENT: 'CREDENTIAL_MANAGEMENT',
  CREDENTIAL_TYPE_ADMIN: 'CREDENTIAL_TYPE_ADMIN',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  CHANGE_ORGANISATION: 'CHANGE_ORGANISATION',
  ORGANISATION_ADMIN: 'ORGANISATION_ADMIN',
  WORKPLACE_LOCATION_ADMIN: 'WORKPLACE_LOCATION_ADMIN',
  TIMELINE_ADMIN: 'TIMELINE_ADMIN'
};

export const sortOrder = {
  asc: 'ASC',
  desc: 'DESC'
};

export const ADD_NEW = 'Add New';
export const USER_ADMIN = 'User Administration';

export const pageSize = 20;
export const initialCurrentPage = 0;

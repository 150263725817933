import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import MuiPhoneInput from 'material-ui-phone-number';
import {
  FormHelperText,
  TextField,
  LinearProgress,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
  MuiThemeProvider
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { createField, clearError, validateField } from '../../store/actions/ValidationActions';
import { fieldErrorSelector } from '../../store/selectors/validationField';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import SelectWithOptions from '../control/SelectWithOptions';
import RadioButtons from '../control/RadioButtons';
import CheckBoxes from '../control/CheckBoxes';
import { style } from '../../assets/style';
import { colors, FILE_UPLOAD_STATUS, customTheme } from '../../assets/constants';
import { convertMomentToUtc } from '../../utils/utilities';
import SelectWithInput from '../control/SelectWithInput';

const styles = {
  ...style,
  dateControl: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '0 !important',
    '& > input': {
      marginLeft: '14px'
    },
    '& > div  > div': {
      '&:before': {
        borderBottom: '1px solid #999 !important'
      },
      '&:after': {
        borderBottom: '1px solid #999 !important'
      }
    }
  },
  dateControlError: {
    '& > div  > div': {
      '&:before': {
        borderBottom: '2px solid rgba(0, 0, 0, 0.42) !important'
      }
    }
  },
  formControl: {
    width: '100%',
    marginTop: '16px',
    marginBottom: '8px'
  },
  formDateControl: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '6px'
  },
  formControlError: {
    '& > div > div': {
      borderBottom: 'solid 2px rgba(0, 0, 0, 0.42) !important'
    }
  },
  formError: {
    margin: '0 14px 0'
  },
  formUploadContainerWithError: {
    '& > div': {
      borderWidth: '2px !important'
    }
  },
  formUploadContainer: {
    marginTop: '20px',
    marginBottom: '10px',
    '& > div': {
      height: '56px',
      minHeight: '19px',
      borderLeft: 0,
      borderRight: 0,
      borderTop: 0,
      backgroundColor: '#fafafa',
      borderBottom: 'solid 1px #999',
      marginBottom: '8px'
    },
    '& > div > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& > div > div > svg': {
      width: '30px',
      height: '30px',
      marginRight: '12px'
    },
    '& > div > div > p': {
      fontSize: '16px',
      color: 'rgba(0, 0, 0, 0.54)',
      paddingLeft: '12px'
    }
  },
  fileErrorMessage: {
    color: colors.red,
    margin: '0px 5px 5px 10px'
  },
  fileUpload: {
    marginBottom: '15px',
    '& > div': {
      display: 'flex',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.8)'
    }
  },
  uploadError: {
    backgroundColor: colors.red,
    '& div': {
      backgroundColor: colors.red,
      margin: '10px'
    }
  },
  fileName: {
    marginBottom: '10px'
  },
  upload: {
    backgroundColor: colors.orangeDarkHalf,
    '& > div': {
      backgroundColor: colors.orangeDark
    }
  },
  fileStatusMessage: {
    marginLeft: '6px',
    display: 'flex',
    justifyContent: 'center'
  },
  circularProgress: {
    marginLeft: '10px',
    color: colors.orangeDark
  },
  error: {
    backgroundColor: '#d32f2f'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '10px'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  fileErrorMessages: {
    display: 'flex',
    flexDirection: 'column'
  }
};

class ValidatedField extends React.Component {
  typingTimeout = null;

  typingTimeoutDuration = 500;

  state = {
    errorMessages: []
  };

  componentDidMount() {
    const { createField, id, label, validators, value, ignoreValidation } = this.props;

    createField({ id, label, validators, text: value, ignoreValidation });
  }

  componentDidUpdate(prevProps) {
    const { createField, id, validators, value, label, ignoreValidation } = this.props;

    if (
      (!Array.isArray(value) && !prevProps.value && value) ||
      (Array.isArray(value) && prevProps.value.length !== value.length) ||
      prevProps.ignoreValidation !== ignoreValidation
    ) {
      createField({ id, validators, label, text: value, ignoreValidation });
    }
  }

  componentWillUnmount() {
    const { id, clearError } = this.props;

    clearError(id);
  }

  validate = text => {
    const { validators, id, ignoreValidation, label } = this.props;

    if (ignoreValidation) return;

    this.props.validateField({ text, id, label, validators, ignoreValidation });
  };

  handleChange = event => {
    const { id, error, onChange, clearError, ignoreValidation } = this.props;

    if (error && !ignoreValidation) clearError(id);
    onChange(event);
    const { value } = event.target;
    clearTimeout(this.typingTimeout);

    this.typingTimeout = setTimeout(() => {
      this.validate(value);
    }, this.typingTimeoutDuration);
  };

  handleDocumentChange = (id, event) => {
    const { error, onDocumentChange, clearError, ignoreValidation } = this.props;

    if (error && !ignoreValidation) clearError(id);

    onDocumentChange(id, event);
  };

  handleDateChange = (name, date) => {
    const { id, error, clearError, onChange, ignoreValidation } = this.props;
    const dateStr = format(date, 'dd-MM-yyyy');
    if (error && !ignoreValidation) clearError(id);
    onChange({
      target: {
        name,
        value: dateStr
      }
    });

    this.validate(dateStr);
  };

  handleDateTimeChange = (name, date) => {
    const { id, error, clearError, onChange, ignoreValidation } = this.props;
    const dateStr = format(date, 'dd-MM-yyyy HH:mm');

    if (error && !ignoreValidation) clearError(id);
    onChange({
      target: {
        name,
        value: convertMomentToUtc('DD-MM-YYYY HH:mm', moment(dateStr, 'DD-MM-YYYY HH:mm'))
      }
    });

    this.validate(dateStr);
  };

  handleCheckBoxChange = (name, event) => {
    const { id, error, clearError, onChange, ignoreValidation } = this.props;

    if (error && !ignoreValidation) clearError(id);

    onChange({
      target: {
        name,
        value: event
      }
    });

    this.validate(event);
  };

  handleClick = event => {
    const { onClick } = this.props;
    onClick(event);
  };

  handleCloseSnackbar = event => {
    this.setState({ errorMessages: [] });
  };

  render() {
    // Destruct unused props so that they are not passed down to TextField and Select components
    /* eslint-disable */

    const {
      error,
      errorText,
      type,
      createField,
      onChange,
      clearError,
      validateField,
      ignoreValidation,
      name,
      classes,
      inputTextProps,
      uploadMimeTypes,
      ...rest
    } = this.props;
    const { errorMessages } = this.state;

    /* eslint-enable */
    const helperText = error || errorText || rest.helperText || '';
    const errorProp = error || errorText || false;
    switch (type) {
      case VALIDATED_FIELD_TYPES.TEXT:
        return (
          <TextField
            {...rest}
            onChange={this.handleChange}
            helperText={helperText}
            error={!!errorProp}
            onClick={this.handleClick}
            name={name}
            InputProps={{ className: classNames(classes.textFieldContainer, classes.underline), ...inputTextProps }}
            InputLabelProps={{
              className: classes.label
            }}
            className={classes.textField}
          />
        );
      case VALIDATED_FIELD_TYPES.PHONE:
        return (
          <MuiPhoneInput
            countryCodeEditable={false}
            inputClass={classes.textField}
            defaultCountry="ie"
            onChange={value => this.handleChange({ target: { name, value } })}
            {...rest}
            name={name}
            helperText={helperText}
            error={!!errorProp}
            InputProps={{ className: classNames(classes.textFieldContainer, classes.underline), ...inputTextProps }}
            InputLabelProps={{
              className: classes.label
            }}
            className={classes.textField}
          />
        );
      case VALIDATED_FIELD_TYPES.DATE:
        return (
          <MuiThemeProvider theme={customTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl
                className={classNames(classes.dateControl, classes.formDateControl, {
                  [classes.dateControlError]: !!errorProp
                })}
              >
                <KeyboardDatePicker
                  {...rest}
                  margin="normal"
                  format="dd-MM-yyyy"
                  name={name}
                  onChange={date => this.handleDateChange(name, date)}
                  value={moment(rest.value, 'DD-MM-yyyy').toDate()}
                  InputProps={{
                    className: classNames(
                      classes.textFieldContainer,
                      classes.underline,
                      classes.dateControl,
                      'MuiFilledInput-root'
                    ),
                    value: rest.value,
                    disabled: true,
                    ...inputTextProps
                  }}
                  InputLabelProps={{
                    className: classNames(classes.label, classes.dateLabel, 'MuiInputLabel-filled')
                  }}
                  className={classNames(classes.formDateControl, classes.textField)}
                />
                {!!errorProp && (
                  <FormHelperText error className={classes.formError}>
                    {errorProp}
                  </FormHelperText>
                )}
              </FormControl>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        );
      case VALIDATED_FIELD_TYPES.DATE_TIME:
        return (
          <MuiThemeProvider theme={customTheme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <FormControl
                className={classNames(classes.dateControl, classes.formDateControl, {
                  [classes.dateControlError]: !!errorProp
                })}
              >
                <KeyboardDateTimePicker
                  {...rest}
                  margin="normal"
                  format="dd-MM-yyyy HH:mm"
                  name={name}
                  onChange={date => this.handleDateTimeChange(name, date)}
                  value={moment(rest.value, 'DD-MM-yyyy HH:mm').toDate()}
                  InputProps={{
                    className: classNames(
                      classes.textFieldContainer,
                      classes.underline,
                      classes.dateControl,
                      'MuiFilledInput-root'
                    ),
                    value: rest.value,
                    disabled: true,
                    ...inputTextProps
                  }}
                  InputLabelProps={{
                    className: classNames(classes.label, classes.dateLabel, 'MuiInputLabel-filled')
                  }}
                  className={classNames(classes.formDateControl, classes.textField)}
                />
                {!!errorProp && (
                  <FormHelperText error className={classes.formError}>
                    {errorProp}
                  </FormHelperText>
                )}
              </FormControl>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        );
      case VALIDATED_FIELD_TYPES.SELECT:
        return (
          <SelectWithOptions
            {...rest}
            onChange={this.handleChange}
            error={errorProp}
            onClick={this.handleClick}
            name={name}
          />
        );
      case VALIDATED_FIELD_TYPES.SELECT_WITH_INPUT:
        return <SelectWithInput {...rest} name={name} handleChange={this.handleChange} error={errorProp} />;
      case VALIDATED_FIELD_TYPES.RADIO_GROUP:
        return (
          <RadioButtons
            {...rest}
            handleChange={this.handleChange}
            error={errorProp}
            // onClick={this.handleClick}
            name={name}
          />
        );
      case VALIDATED_FIELD_TYPES.CHECKBOXES:
        return (
          <CheckBoxes
            {...rest}
            handleChange={this.handleCheckBoxChange}
            error={errorProp}
            onClick={this.handleClick}
            name={name}
          />
        );
      case VALIDATED_FIELD_TYPES.PASSWORD:
        return (
          <TextField
            {...rest}
            onChange={this.handleChange}
            onClick={this.handleClick}
            type="password"
            InputProps={{ className: classNames(classes.textFieldContainer, classes.underline), ...inputTextProps }}
            InputLabelProps={{
              className: classes.label
            }}
            name={name}
            helperText={helperText}
            error={!!errorProp}
          />
        );
      case VALIDATED_FIELD_TYPES.FILE:
        return (
          <div>
            <FormControl
              className={classNames(classes.formUploadContainer, classes.formControl, {
                [classes.formUploadContainerWithError]: !!errorProp
              })}
            >
              <DropzoneArea
                filesLimit={10}
                onDrop={e => this.handleDocumentChange(name, e)}
                dropzoneText={`${rest.label} - Click or drop file`}
                showPreviews={false}
                showPreviewsInDropzone={false}
                showAlerts={false}
                onDropRejected={(rejectedFiles, evt) => {
                  const errorMessages = [];
                  rejectedFiles.forEach(item => {
                    let errorMessage = `File ${item.name} was rejected.`;

                    if (uploadMimeTypes.indexOf(item.type) === -1) {
                      errorMessage += ' File type is not supported.';
                    }
                    if (item.size > 20 * 1024 * 1000) {
                      errorMessage += ' File is too big. Size limit is 20MB';
                    }
                    errorMessages.push(errorMessage);
                  });
                  this.setState({ errorMessages });
                }}
                maxFileSize={20 * 1024 * 1000}
                acceptedFiles={uploadMimeTypes}
              />
              {!!errorProp && (
                <FormHelperText error className={classes.formError}>
                  {errorProp}
                </FormHelperText>
              )}
              <Snackbar
                open={errorMessages.length > 0}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <SnackbarContent
                  aria-describedby="client-snackbar"
                  className={classes.error}
                  message={
                    /* eslint-disable react/jsx-wrap-multilines */
                    <span id="client-snackbar" className={classes.message}>
                      <ErrorIcon className={classes.iconVariant} />
                      <div className={classes.fileErrorMessages}>
                        {errorMessages.map(item => (
                          <div>{item}</div>
                        ))}
                      </div>
                    </span>
                    /* eslint-enable react/jsx-wrap-multilines */
                  }
                  action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                      <CloseIcon className={classes.icon} />
                    </IconButton>
                  ]}
                />
              </Snackbar>
            </FormControl>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {rest.value.map((item, index) => {
                return (
                  <div className={classes.fileUpload} key={`fileupload-${index}`}>
                    <div>
                      <div className={classes.fileName}>{item.name.substring(item.name.indexOf('_') + 1)}</div>
                      {item.error && <div className={classes.fileErrorMessage}>{item.error}</div>}
                      {!item.error && !item.status && <div className={classes.fileStatusMessage}>- Uploading File</div>}
                      {!item.error && item.status && item.status === FILE_UPLOAD_STATUS.UPLOADED.id && (
                        <div className={classes.fileStatusMessage}>
                          - Scanning for viruses
                          <MuiThemeProvider theme={customTheme}>
                            <CircularProgress size={20} className={classes.circularProgress} />
                          </MuiThemeProvider>
                        </div>
                      )}
                      {/* eslint-disable indent */ !item.error &&
                        item.status &&
                        item.status === FILE_UPLOAD_STATUS.CLEAN.id &&
                        item.mimeType.startsWith('image') && (
                          <div className={classes.fileStatusMessage}>
                            - Resizing Image & Creating Thumbnail
                            <MuiThemeProvider theme={customTheme}>
                              <CircularProgress size={20} className={classes.circularProgress} />
                            </MuiThemeProvider>
                          </div>
                        )}
                      {!item.error && item.status && item.status === FILE_UPLOAD_STATUS.RESIZED_THUMBNAIL.id && (
                        <div className={classes.fileStatusMessage}>- File Uploaded</div>
                      )}
                      {!item.error &&
                        item.status &&
                        item.status === FILE_UPLOAD_STATUS.CLEAN.id &&
                        !item.mimeType.startsWith('image') && (
                          <div className={classes.fileStatusMessage}>- File Uploaded</div>
                        )}
                      {!item.error && item.status && item.status === FILE_UPLOAD_STATUS.INFECTED.id && (
                        <div className={classNames(classes.fileStatusMessage, classes.fileErrorMessage)}>
                          - Virus detected. Unable to upload file
                        </div>
                      ) /* eslint-enable indent */}
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={item.completeness}
                      className={classNames(classes.upload, {
                        [classes.uploadError]: item.error
                      })}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}

ValidatedField.defaultProps = {
  type: VALIDATED_FIELD_TYPES.TEXT,
  variant: 'filled',
  margin: 'normal',
  className: '',
  helperText: '',
  validators: '',
  values: [],
  error: '',
  errorText: '',
  InputProps: null,
  onChange: () => {},
  onClick: () => {},
  disabled: false,
  ignoreValidation: false,
  inputTextProps: {},
  uploadMimeTypes: []
};

ValidatedField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  createField: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onDocumentChange: PropTypes.func,
  onClick: PropTypes.func,
  validators: PropTypes.array,
  variant: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.array,
  type: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  errorText: PropTypes.string,
  InputProps: PropTypes.object,
  inputTextProps: PropTypes.object,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  ignoreValidation: PropTypes.bool,
  uploadMimeTypes: PropTypes.array
};

const mapStateToProps = (state, ownProps) => fieldErrorSelector(state, ownProps.id);

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    createField,
    clearError,
    validateField
  })
)(ValidatedField);

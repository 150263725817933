import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import classNames from 'classnames';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import dashboardStyle from '../../assets/dashboardStyles';
import { loadingSelector } from '../../store/selectors/shared';
import {
  getCompetenceCredentialCheckCountRequest,
  getCompetenceCredentialCheckIssuerRequest,
  getCompetenceCredentialCheckOrganisationRequest,
  getCompetenceCredentialCheckValidatingHolderRequest,
  getCompetenceCredentialCheckWorkPlaceRequest,
  getCompetenceCredentialRequestCountRequest,
  getCompetenceCredentialRequestFormRequest,
  getCompetenceCredentialRequestPriorityRequest,
  getCompetenceCredentialRequestStatusRequest,
  getCompetenceCredentialRequestTeamRequest
} from '../../utils/entityRequests/dashboardRequests';
import { entitySelector } from '../../store/selectors/entity';
import { isAssurance, isCompetence } from '../../utils/utilities';
import { CREDENTIAL_REQUEST_STATUS_TYPE } from '../../assets/constants';
import LineChart from '../widgets/chart/LineChart';
import { graphDataInitialState } from '../../utils/entityInitialStates';

const styles = {
  ...style,
  ...dashboardStyle
};

class DashboardView extends PureComponent {
  componentDidMount() {
    const { getEntity } = this.props;

    if (isCompetence()) {
      getEntity(getCompetenceCredentialRequestCountRequest());
      getEntity(getCompetenceCredentialRequestStatusRequest());
      getEntity(getCompetenceCredentialRequestTeamRequest());
      getEntity(getCompetenceCredentialRequestFormRequest());
      getEntity(getCompetenceCredentialRequestPriorityRequest());
      getEntity(getCompetenceCredentialCheckOrganisationRequest());
    } else if (isAssurance()) {
      getEntity(getCompetenceCredentialCheckCountRequest());
      getEntity(getCompetenceCredentialCheckIssuerRequest());
      getEntity(getCompetenceCredentialCheckValidatingHolderRequest());
      getEntity(getCompetenceCredentialCheckWorkPlaceRequest());
    }
  }

  render() {
    const {
      classes,
      credentialRequestBreakdown,
      credentialRequestStatusBreakdownGraph,
      credentialRequestTeamBreakdownGraph,
      credentialRequestPriorityBreakdownGraph,
      credentialRequestFormBreakdownGraph,
      credentialCheckOrganisationGraph,
      credentialCheckNumberGraph,
      credentialCheckIssuerGraph,
      credentialCheckValidatingHolderGraph,
      credentialCheckWorkPlaceGraph
    } = this.props;

    const { created, assigned, complete, failed } = credentialRequestBreakdown;

    return (
      <Grid container>
        <Grid item xs={12} className={classNames(classes.noPaddingLeftRightGrid, classes.dashboardBackground)}>
          {isCompetence() && (
            <Grid className={classes.statsContainer}>
              <Card className={classes.dashboardStatContainer}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => this.handleStatusLink(CREDENTIAL_REQUEST_STATUS_TYPE.CREATED.value)}
                >
                  <div className={classNames(classes.iconContainer, classes.createdIconContainer)}>
                    <i className={classNames('material-icons', classes.whiteIcon, classes.statIcon)}>assignment</i>
                  </div>
                  <div className={classes.statTitle}>
                    Received
                    <div>{created}</div>
                  </div>
                </CardContent>
              </Card>
              <Card className={classes.dashboardStatContainer}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => this.handleStatusLink(CREDENTIAL_REQUEST_STATUS_TYPE.ASSIGNED.value)}
                >
                  <div className={classNames(classes.iconContainer, classes.assignedIconContainer)}>
                    <i className={classNames('material-icons', classes.whiteIcon, classes.statIcon)}>assignment_ind</i>
                  </div>
                  <div className={classes.statTitle}>
                    Assigned
                    <div>{assigned}</div>
                  </div>
                </CardContent>
              </Card>
              <Card className={classes.dashboardStatContainer}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => this.handleStatusLink(CREDENTIAL_REQUEST_STATUS_TYPE.COMPLETE.value)}
                >
                  <div className={classNames(classes.iconContainer, classes.completedIconContainer)}>
                    <i className={classNames('material-icons', classes.whiteIcon, classes.statIcon)}>
                      assignment_turned_in
                    </i>
                  </div>
                  <div className={classes.statTitle}>
                    Completed
                    <div>{complete}</div>
                  </div>
                </CardContent>
              </Card>
              <Card className={classes.dashboardStatContainer}>
                <CardContent
                  className={classes.cardContent}
                  onClick={() => this.handleStatusLink(CREDENTIAL_REQUEST_STATUS_TYPE.FAILED.value)}
                >
                  <div className={classNames(classes.iconContainer, classes.failedIconContainer)}>
                    <i className={classNames('material-icons', classes.whiteIcon, classes.statIcon)}>assignment_late</i>
                  </div>
                  <div className={classes.statTitle}>
                    Rejected
                    <div>{failed}</div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          )}
          {isCompetence() && (
            <Grid className={classes.statsContainer}>
              <Card className={classes.dashboardGraphContainer}>
                <CardHeader title="Last 7 Days - Requests By Status" className={classes.graphTitle} />
                <CardContent>
                  <LineChart
                    graphData={
                      Object.keys(credentialRequestStatusBreakdownGraph).length > 0
                        ? credentialRequestStatusBreakdownGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', left: '30px', bottom: '-30px' }}
                    legendOrientation="horizontal"
                  />
                </CardContent>
              </Card>
              <Card className={classes.extendedGraphContainer}>
                <CardHeader title="Last 7 Days - Requests By Team" className={classes.graphTitle} />
                <CardContent className={classes.extendedGraphCardContent}>
                  <LineChart
                    graphData={
                      Object.keys(credentialRequestTeamBreakdownGraph).length > 0
                        ? credentialRequestTeamBreakdownGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', right: '-160px', top: '10px' }}
                    legendOrientation="vertical"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {isCompetence() && (
            <Grid className={classes.statsContainer}>
              <Card className={classes.dashboardGraphContainer}>
                <CardHeader title="Last 7 Days - Requests By Priority" className={classes.graphTitle} />
                <CardContent>
                  <LineChart
                    graphData={
                      Object.keys(credentialRequestPriorityBreakdownGraph).length > 0
                        ? credentialRequestPriorityBreakdownGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', left: '30px', bottom: '-30px' }}
                    legendOrientation="horizontal"
                  />
                </CardContent>
              </Card>
              <Card className={classes.extendedGraphContainer}>
                <CardHeader title="Last 7 Days - Requests By Form" className={classes.graphTitle} />
                <CardContent className={classes.extendedGraphCardContent}>
                  <LineChart
                    graphData={
                      Object.keys(credentialRequestFormBreakdownGraph).length > 0
                        ? credentialRequestFormBreakdownGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', right: '-160px', top: '10px' }}
                    legendOrientation="vertical"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {isCompetence() && (
            <Grid className={classes.statsContainer}>
              <Card className={classes.extendedGraphContainer}>
                <CardHeader title="Last 7 Days - Checks By Organisation" className={classes.graphTitle} />
                <CardContent className={classes.extendedGraphCardContent}>
                  <LineChart
                    graphData={
                      Object.keys(credentialCheckOrganisationGraph).length > 0
                        ? credentialCheckOrganisationGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', right: '-160px', top: '10px' }}
                    legendOrientation="vertical"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {isAssurance() && (
            <Grid className={classes.statsContainer}>
              <Card className={classes.dashboardGraphContainer}>
                <CardHeader title="Last 7 Days - Number Checked" className={classes.graphTitle} />
                <CardContent>
                  <LineChart
                    graphData={
                      Object.keys(credentialCheckNumberGraph).length > 0
                        ? credentialCheckNumberGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', left: '30px', bottom: '-30px' }}
                    legendOrientation="horizontal"
                  />
                </CardContent>
              </Card>
              <Card className={classes.extendedGraphContainer}>
                <CardHeader title="Last 7 Days - By Issuer" className={classes.graphTitle} />
                <CardContent className={classes.extendedGraphCardContent}>
                  <LineChart
                    graphData={
                      Object.keys(credentialCheckIssuerGraph).length > 0
                        ? credentialCheckIssuerGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', right: '-160px', top: '10px' }}
                    legendOrientation="vertical"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
          {isAssurance() && (
            <Grid className={classes.statsContainer}>
              <Card className={classes.extendedGraphContainer}>
                <CardHeader title="Last 7 Days - By Validating Holder" className={classes.graphTitle} />
                <CardContent className={classes.extendedGraphCardContent}>
                  <LineChart
                    graphData={
                      Object.keys(credentialCheckValidatingHolderGraph).length > 0
                        ? credentialCheckValidatingHolderGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', right: '-160px', top: '10px' }}
                    legendOrientation="vertical"
                  />
                </CardContent>
              </Card>
              <Card className={classes.extendedGraphContainer}>
                <CardHeader title="Last 7 Days - By Work Place" className={classes.graphTitle} />
                <CardContent className={classes.extendedGraphCardContent}>
                  <LineChart
                    graphData={
                      Object.keys(credentialCheckWorkPlaceGraph).length > 0
                        ? credentialCheckWorkPlaceGraph
                        : graphDataInitialState
                    }
                    width={500}
                    height={300}
                    legendStyle={{ position: 'absolute', right: '-160px', top: '10px' }}
                    legendOrientation="vertical"
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

DashboardView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  credentialRequestBreakdown: PropTypes.object,
  credentialRequestStatusBreakdownGraph: PropTypes.object,
  credentialRequestTeamBreakdownGraph: PropTypes.object,
  credentialRequestPriorityBreakdownGraph: PropTypes.object,
  credentialRequestFormBreakdownGraph: PropTypes.object,
  credentialCheckOrganisationGraph: PropTypes.object,
  credentialCheckNumberGraph: PropTypes.object,
  credentialCheckIssuerGraph: PropTypes.object,
  credentialCheckValidatingHolderGraph: PropTypes.object,
  credentialCheckWorkPlaceGraph: PropTypes.object,
  isLoading: PropTypes.bool,
  history: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  ...entitySelector(state, 'credentialRequestBreakdown'),
  ...entitySelector(state, 'credentialRequestTeamBreakdownGraph'),
  ...entitySelector(state, 'credentialRequestFormBreakdownGraph'),
  ...entitySelector(state, 'credentialRequestPriorityBreakdownGraph'),
  ...entitySelector(state, 'credentialCheckOrganisationGraph'),
  ...entitySelector(state, 'credentialRequestStatusBreakdownGraph'),
  ...entitySelector(state, 'credentialCheckNumberGraph'),
  ...entitySelector(state, 'credentialCheckIssuerGraph'),
  ...entitySelector(state, 'credentialCheckValidatingHolderGraph'),
  ...entitySelector(state, 'credentialCheckWorkPlaceGraph')
});

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, {
    getEntity
  })
)(DashboardView);

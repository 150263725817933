import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { entitySelector } from '../../store/selectors/entity';

const OrganisationCredentialCheckListItemBreadcrumb = ({ organisationCredentialCheck, isLoading }) => (
  <GenericBreadcrumbItem
    mainText={organisationCredentialCheck.credentialTypeName || organisationCredentialCheck.qualificationName}
    subText={organisationCredentialCheck.holderName}
    isLoading={isLoading}
  />
);

const mapStateToProps = state => ({
  ...entitySelector(state, 'organisationCredentialCheck'),
  isLoading: loadingSelector(state, 'getEntity')
});

OrganisationCredentialCheckListItemBreadcrumb.propTypes = {
  organisationCredentialCheck: PropTypes.object,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(OrganisationCredentialCheckListItemBreadcrumb);

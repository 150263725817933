import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import ls from 'local-storage';
import rootReducer from './reducers/index';

import validation from './middlewares/validation';

const middleware = [thunk, validation];

const initialState = undefined; // getState();

export default function configureStore() {
  const store = createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));
  return store;
}

// store.subscribe(() => {
//   const str = JSON.stringify(store.getState());
//   ls.set('state', str);
// });
//
// export default store;

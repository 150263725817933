import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';

import NewHeaderEditActions from '../header/NewHeaderEditActions';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import NewValidatedField from '../widgets/NewValidatedField';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { reasonInitialState } from '../../utils/entityInitialStates';
import { getReasonRequest, saveReasonRequest } from '../../utils/entityRequests/reasonRequests';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout
});

class ChangeReasonView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reason: reasonInitialState
    };

    this.cancelUrl = '/workforce/reason';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const reason = await getEntity(getReasonRequest(match.params.id));
      this.setState({ reason });
    } else {
      this.setState({
        reason: reasonInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ reason: reasonInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { reason } = this.state;

    this.setState({
      reason: {
        ...reason,
        [name]: value
      }
    });
  };

  handleDeprecateChange = () => {
    this.setState(prevState => ({
      reason: {
        ...prevState.reason,
        deprecated: !prevState.reason.deprecated
      }
    }));
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { reason } = this.state;

    const reasonId = await saveEntity(saveReasonRequest(reason));

    if (!reasonId) return;

    history.push('/workforce/reason/list');
  };

  render() {
    const { classes, isSaving } = this.props;
    const { reason } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={classNames(classes.viewPage, classes.page)} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="editReasonForm"
              header="Reason Details"
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={reason.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer} style={{ marginLeft: '10px' }}>
                    <FormControlLabel
                      control={
                        /* eslint-disable react/jsx-wrap-multilines */
                        <Checkbox
                          checked={reason.deprecated}
                          onClick={this.handleDeprecateChange}
                          className={classNames(classes.radio, {
                            [classes.selected]: reason.deprecated
                          })}
                        />
                        /* eslint-enable react/jsx-wrap-multilines */
                      }
                      label="Deprecated"
                      className={classNames(
                        classes.checkboxContainer,
                        classes.radioOptionLabel,
                        classes.independentLabel,
                        {
                          [classes.checkboxContainerSelected]: reason.deprecated
                        }
                      )}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeReasonView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  reason: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'reason'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeReasonView);

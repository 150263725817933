import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { tableSelector } from '../../store/selectors/table';

class GenericListBreadcrumb extends PureComponent {
  render() {
    const { table, search, isLoading, heading } = this.props;
    const totalRecords = table && table.payload ? table.payload.totalRecords : 0;
    const subText =
      search && search.searchText ? `${search.totalRecords} of ${totalRecords} (filtered)` : `${totalRecords} in total`;

    return <GenericBreadcrumbItem mainText={heading} subText={subText} isLoading={isLoading} />;
  }
}

const mapStateToProps = (state, props) => ({
  ...tableSelector(state, props.tableName),
  isLoading: loadingSelector(state, props.functionName)
});

GenericListBreadcrumb.propTypes = {
  table: PropTypes.object,
  search: PropTypes.object,
  isLoading: PropTypes.bool,
  tableName: PropTypes.string.isRequired,
  functionName: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(GenericListBreadcrumb);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Button, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities, getEntity } from '../../store/actions/EntityActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getSearchCredentialTypesUrl } from '../../utils/searchUrls';
import ScrollableTable from '../table/ScrollableTable';
import { colors } from '../../assets/constants';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { entityArraySelector } from '../../store/selectors/entity';
import { listIssuerCredentialTypeRequest } from '../../utils/entityRequests/credentialTypeRequests';
import { listCompetenceOrganisationRequest } from '../../utils/entityRequests/referenceDataRequests';
import NewActionButton from '../widgets/NewActionButton';

const styles = () => ({
  ...style,
  textField: {
    width: '100%'
  },
  secondaryHeading: {
    marginTop: '20px',
    borderBottom: 'none !important'
  },
  searchContainer: {
    padding: '10px',
    border: 'none'
  },
  scrollTableContainer: {
    overflowY: 'auto'
  },
  sliderBody: {
    ...layout.sliderBody,
    padding: 0
  },
  radio: {
    '& > span > svg': {
      height: '0.75em',
      width: '0.75em'
    }
  },
  selected: {
    '& > span > svg': {
      color: colors.orangeDark
    }
  }
});

class CredentialTypeList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCredentialTypes: []
    };
  }

  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(listCompetenceOrganisationRequest());
  }

  toggleCredentialTypeSelection = event => {
    const { target } = event;
    const { value, name } = target;
    const { selectedCredentialTypes } = this.state;
    const selectedCredentialType = selectedCredentialTypes.find(item => item.identifier === value);

    if (!selectedCredentialType) {
      const credentialTypes = selectedCredentialTypes.slice();
      credentialTypes.push({ name, identifier: value });
      this.setState({
        selectedCredentialTypes: credentialTypes
      });
    } else {
      this.setState({
        selectedCredentialTypes: selectedCredentialTypes.slice().filter(item => item.identifier !== value)
      });
    }
  };

  getRowConfigs = () => {
    const { search, table, classes } = this.props;
    const { selectedCredentialTypes } = this.state;
    const items = getRecords(search, table);
    return items.map((credentialType, index) => ({
      cells: [
        {
          value: credentialType.issuer.name
        },
        { value: credentialType.name },
        {
          value: (
            <Checkbox
              onClick={this.toggleCredentialTypeSelection}
              value={credentialType.identifier}
              name={credentialType.name}
              id={`checkBox${index}`}
              checked={!!selectedCredentialTypes.find(item => item.identifier === credentialType.identifier)}
              className={classNames(classes.radio, {
                [classes.selected]: !!selectedCredentialTypes.find(
                  item => item.identifier === credentialType.identifier
                )
              })}
            />
          )
        }
      ]
    }));
  };

  getListing = props => {
    const { isLoading, search, organisations } = this.props;
    const issuerFilterValue = search && search.filters.issuer ? search.filters.issuer : NO_SEARCH_VALUE;

    return (
      organisations && (
        <ScrollableTable
          headers={[
            {
              value: 'Issuer',
              filter: {
                value: issuerFilterValue,
                options: organisations,
                onChange: event => props.handleFilterChange(event, 'issuer')
              }
            },
            { value: 'Credential Type' },
            { value: '', class: 'smallCol' }
          ]}
          rowConfigs={this.getRowConfigs()}
          complete={!isLoading}
          emptyMessage="No Credential Types Found"
          {...props}
        />
      )
    );
  };

  handleSelectCredentialTypes = () => {
    const { selectCredentialTypes } = this.props;
    const { selectedCredentialTypes } = this.state;
    selectCredentialTypes(selectedCredentialTypes);
  };

  render() {
    const {
      classes,
      table,
      closeDisplaySelectCredentialType,
      organisations,
      listEntities,
      listIssuerCredentialTypeRequest
    } = this.props;

    return (
      <div className={classes.overlayContainer}>
        <Grid container justify="space-between" className={classes.overLayHeadingActionContainer}>
          <Grid container alignItems="center" item xs={6}>
            <div>
              <div className={classes.pageHeading}>Credential Types</div>
            </div>
          </Grid>
          <Grid container item xs={6} justify="flex-end" direction="row" alignItems="center">
            <Button onClick={closeDisplaySelectCredentialType} className={classNames(classes.cancel)} data-qa="cancel">
              Close
            </Button>

            <NewActionButton
              primary
              icon={<i className="material-icons">add</i>}
              text="Select Credential Types"
              onChange={this.handleSelectCredentialTypes}
              filled
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.formPage, classes.sliderBody)}>
          <Grid container item xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <ListingWithSearch
                name="credentialType"
                getSearchUrl={getSearchCredentialTypesUrl}
                onList={listEntities}
                filtersList={{
                  issuer: organisations
                }}
                renderList={props => this.getListing(props)}
                list={table}
                additionalSearchParams={listIssuerCredentialTypeRequest}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

CredentialTypeList.propTypes = {
  classes: PropTypes.object,
  closeSelectHolder: PropTypes.func,
  listIssuerCredentialTypeRequest: PropTypes.func,
  listEntities: PropTypes.func,
  getEntity: PropTypes.func,
  selectCredentialTypes: PropTypes.func,
  closeDisplaySelectCredentialType: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  organisations: PropTypes.array,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-credentialType'),
  ...entityArraySelector(state, 'organisations'),
  ...tableSelector(state, 'credentialType')
});

export default withStyles(styles)(
  connect(mapStateToProps, { listEntities, getEntity, listIssuerCredentialTypeRequest })(CredentialTypeList)
);

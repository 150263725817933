import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { CREDENTIAL_STATUS_TYPE, CHECK_TYPE } from '../../assets/constants';
import { convertUtcToLocal, getRecords } from '../../utils/utilities';
import { tableSelector } from '../../store/selectors/table';
import { listOrganisationCredentialCheckRequest } from '../../utils/entityRequests/credentialCheckRequests';
import { listOrganisationQualificationRequest } from '../../utils/entityRequests/qualificationRequests';
import { listAllCredentialTypeRequest } from '../../utils/entityRequests/referenceDataRequests';
import { listValidatingOrganisationMemberRequest } from '../../utils/entityRequests/organisationMemberRequests';
import { entityArraySelector } from '../../store/selectors/entity';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class OrganisationCredentialCheckListView extends React.Component {
  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(listAllCredentialTypeRequest());
    await getEntity(listValidatingOrganisationMemberRequest());
    await getEntity(listOrganisationQualificationRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/workplace/organisationCredentialCheck/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(organisationCredentialCheck => ({
      clickAction: () => this.actionNavigate(organisationCredentialCheck.identifier),
      cells: [
        { value: organisationCredentialCheck.qualificationName },
        { value: organisationCredentialCheck.credentialTypeName },
        { value: organisationCredentialCheck.workPlaceName },
        { value: organisationCredentialCheck.holderName },
        {
          value:
            organisationCredentialCheck.holderName !== organisationCredentialCheck.validatingHolderName
              ? organisationCredentialCheck.validatingHolderName
              : ''
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', organisationCredentialCheck.createTime)
        },
        { value: CREDENTIAL_STATUS_TYPE[organisationCredentialCheck.status].label },
        { value: CHECK_TYPE[organisationCredentialCheck.credentialCheckType].label }
      ]
    }));
  };

  getListing = props => {
    const {
      isLoading,
      search,
      allCredentialTypes,
      validatingOrganisationMembers,
      organisationQualifications
    } = this.props;
    const credentialTypeFilterValue =
      search && search.filters.credentialTypeName ? search.filters.credentialTypeName : NO_SEARCH_VALUE;
    const validatingMemberFilterValue =
      search && search.filters.validatingMemberName ? search.filters.validatingMemberName : NO_SEARCH_VALUE;
    const qualificationFilterValue =
      search && search.filters.qualificationName ? search.filters.qualificationName : NO_SEARCH_VALUE;

    return (
      allCredentialTypes &&
      organisationQualifications &&
      validatingOrganisationMembers && (
        <ScrollableTable
          headers={[
            {
              value: 'Qualification',
              filter: {
                value: qualificationFilterValue,
                options: organisationQualifications,
                onChange: event => props.handleFilterChange(event, 'qualificationName')
              }
            },
            {
              value: 'Credential Type',
              filter: {
                value: credentialTypeFilterValue,
                options: allCredentialTypes,
                onChange: event => props.handleFilterChange(event, 'credentialTypeName')
              }
            },
            { value: 'Work Place' },
            { value: 'Holder Name' },
            {
              value: 'Validating Name',
              filter: {
                value: validatingMemberFilterValue,
                options: validatingOrganisationMembers,
                onChange: event => props.handleFilterChange(event, 'validatingMemberName')
              }
            },
            { value: 'Check Time' },
            { value: 'Status' },
            { value: 'Check Type' }
          ]}
          rowConfigs={this.getRowConfigs()}
          complete={!isLoading}
          emptyMessage="No Credential Checks Found"
          {...props}
        />
      )
    );
  };

  render() {
    const {
      classes,
      listOrganisationCredentialCheckRequest,
      table,
      allCredentialTypes,
      validatingOrganisationMembers,
      organisationQualifications,
      listEntities
    } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions header="Task Assessment" buttons={[]} />
            <ListingWithSearch
              name="organisationCredentialChecks"
              onList={listEntities}
              filtersList={{
                credentialTypeName: allCredentialTypes,
                validatingMemberName: validatingOrganisationMembers,
                qualificationName: organisationQualifications
              }}
              renderList={props => this.getListing(props)}
              list={table}
              hideSearch
              additionalSearchParams={listOrganisationCredentialCheckRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

OrganisationCredentialCheckListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listOrganisationCredentialCheckRequest: PropTypes.func,
  getEntity: PropTypes.func,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object,
  allCredentialTypes: PropTypes.array,
  organisationQualifications: PropTypes.array,
  validatingOrganisationMembers: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-organisationCredentialChecks'),
  ...tableSelector(state, 'organisationCredentialChecks'),
  ...entityArraySelector(state, 'allCredentialTypes'),
  ...entityArraySelector(state, 'validatingOrganisationMembers'),
  ...entityArraySelector(state, 'organisationQualifications')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    listOrganisationCredentialCheckRequest,
    getEntity,
    listEntities
  })
)(OrganisationCredentialCheckListView);

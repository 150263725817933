import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import Modal from '@material-ui/core/Modal/Modal';
import Slide from '@material-ui/core/Slide/Slide';

import FormHelperText from '@material-ui/core/FormHelperText';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import ValidatedField from '../widgets/ValidatedField';
import UserAssignment from './UserAssignment';
import { teamInitialState } from '../../utils/entityInitialStates';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { getTeamRequest, saveTeamRequest } from '../../utils/entityRequests/teamRequests';

const styles = () => ({
  ...style,
  ...layout
});

class ChangeTeamView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      team: teamInitialState,
      shouldDisplaySelectUser: false
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/organisation/team/view/${id}` : '/organisation/team';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const team = await getEntity(getTeamRequest(match.params.id));
      this.setState({ team });
    } else {
      this.setState({
        team: teamInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ team: teamInitialState });
  }

  closeSelectUser = () => {
    this.setState({
      shouldDisplaySelectUser: false
    });
  };

  openSelectUser = () => {
    this.setState({
      shouldDisplaySelectUser: true
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { team } = this.state;

    this.setState({
      team: {
        ...team,
        [name]: value
      }
    });
  };

  handleAddUser = user => {
    const { team } = this.state;
    const teamModel = _.cloneDeep(team);

    if (!teamModel.users.find(item => item.identifier === user.identifier)) {
      teamModel.users.push(user);
      this.setState({
        team: teamModel
      });
    }
  };

  handleDeleteUser = identifier => {
    const { team } = this.state;
    const teamModel = _.cloneDeep(team);
    const users = teamModel.users.filter(item => item.identifier !== identifier);

    this.setState({
      team: {
        ...teamModel,
        users
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { team } = this.state;

    const teamId = await saveEntity(saveTeamRequest(team));

    if (!teamId) return;

    history.push(`/organisation/team/view/${teamId}`);
  };

  render() {
    const { classes, isSaving, errors } = this.props;
    const { team, shouldDisplaySelectUser } = this.state;
    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={6}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="userSelect"
              header="Team Details"
            />
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.sectionHeadingContainer}>
                  <div className={classes.sectionHeading}>Team Details</div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <ValidatedField
                    id="name"
                    label="Name"
                    name="name"
                    value={team.name}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                  />
                </div>
                <Grid item xs={12}>
                  <div className={classes.sectionHeadingContainer}>
                    <div className={classNames(classes.sectionHeading, classes.headingAndAction)}>
                      Members
                      <Tooltip title="Add Member" aria-label="add" placement="bottom">
                        <IconButton
                          aria-owns="menu-appbar"
                          onClick={this.openSelectUser}
                          color="inherit"
                          key="openSelectUser"
                        >
                          <i className={`material-icons ${classes.icon}`}>add</i>
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                  {errors && errors.fieldErrors.users && (
                    <FormHelperText error>{errors.fieldErrors.users}</FormHelperText>
                  )}
                  {team.users.map(user => (
                    <div className={classes.displayLabelAndField} key={`team-user-${user.identifier}`}>
                      <div className={classNames(classes.displayField, classes.headingAndAction)}>
                        {user.name}
                        <Tooltip title="Delete Member" aria-label="add" placement="bottom">
                          <IconButton
                            aria-owns="menu-appbar"
                            onClick={() => this.handleDeleteUser(user.identifier)}
                            color="inherit"
                            key={`delete-user-${user.identifier}`}
                          >
                            <i className={`material-icons ${classes.icon}`}>delete</i>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Modal open={shouldDisplaySelectUser} onClose={this.closeSelectUser}>
            <Slide direction="left" in={shouldDisplaySelectUser} mountOnEnter unmountOnExit>
              <Grid item xs={4} className={`${classes.modalContainer}`}>
                <UserAssignment
                  closeSelectUser={this.closeSelectUser}
                  addUser={this.handleAddUser}
                  heading="Team Members"
                />
              </Grid>
            </Slide>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

ChangeTeamView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  team: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'team'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeTeamView);

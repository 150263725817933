import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { style } from '../assets/style';
import LeftSideNavigation from '../components/navigation/LeftSideNavigation';
import NewHeader from '../components/header/NewHeader';
import NewBreadcrumbs from '../components/breadcrumbs/NewBreadcrumbs';
import renderRoutes from '../routes/renderRoutes';
import pageRoutes from '../routes/pageRoutes';
import { colors } from '../assets/constants';

const styles = () => ({
  ...style,
  baseLayoutContainer: {
    display: 'flex',
    height: '100vh',
    flexDirection: 'row'
  },
  baseLayoutContentContainer: {
    ...style.container,
    flexDirection: 'column'
  },
  pageContainer: {
    paddingTop: '0 !important',
    paddingRight: '0 !important',
    // display: 'flex',
    overflowY: 'auto',
    backgroundColor: colors.backgroundBlue,
    height: '100%'
  }
});

class NewBaseLayout extends React.Component {
  state = {};

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.baseLayoutContainer}>
        <div className={classes.leftHandNavigation}>
          <LeftSideNavigation />
        </div>
        <div className={classes.baseLayoutContentContainer}>
          <NewHeader />
          <NewBreadcrumbs />
          <div className={classes.pageContainer}>
            <Switch>{renderRoutes(pageRoutes)}</Switch>
          </div>
        </div>
      </div>
    );
  }
}

NewBaseLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(withStyles(styles), withRouter)(NewBaseLayout);

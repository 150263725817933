import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { credentialRequestInitialState } from '../entityInitialStates';

export const listCredentialRequest = (searchText = '') => (dispatch, getState) => {
  if (getState().search.credentials) {
    const { filters } = getState().search.credentials;
    const credentialTypeFilter = filters.credentialType || NO_SEARCH_VALUE;

    return {
      /* eslint-disable max-len */
      url: `${process.env.REACT_APP_CREDENTIAL_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${credentialTypeFilter}`,
      entityName: 'credentials'
    };
  }
};

export const getCredentialRequestByCredentialRequest = id => ({
  url: `${process.env.REACT_APP_CREDENTIAL_REQUEST_GET}credentialId/${id}`,
  entityName: 'credentialRequest',
  initialState: credentialRequestInitialState
});

export const listCredentialRequestRequest = (searchText = '') => (dispatch, getState) => {
  if (getState().search.credentialRequests) {
    const { filters } = getState().search.credentialRequests;
    const statusFilter = filters.status || NO_SEARCH_VALUE;
    const priorityFilter = filters.priority || NO_SEARCH_VALUE;

    return {
      /* eslint-disable max-len */
      url: `${process.env.REACT_APP_CREDENTIAL_REQUEST_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${statusFilter}/${priorityFilter}?searchText=${searchText}`,
      entityName: 'credentialRequests'
    };
  }
};

export const rejectCredentialRequest = (id, reason) => ({
  url: `${process.env.REACT_APP_CREDENTIAL_REQUEST_REJECT}${id}`,
  entityName: 'credential request',
  action: 'rejected',
  entity: reason
});

export const saveCredentialRequestRequest = entity => ({
  url: process.env.REACT_APP_CREDENTIAL_REQUEST_SAVE,
  entityName: 'credential request',
  entity,
  action: 'updated'
});

export const saveTestCredentialRequest = (holderId, entity) => ({
  url: `${process.env.REACT_APP_TEST_SAVE_CREDENTIAL}/${holderId}`,
  entityName: 'credential request',
  entity,
  action: 'created'
});

export const getCredentialRequestRequest = id => ({
  url: `${process.env.REACT_APP_CREDENTIAL_REQUEST_GET}${id}`,
  entityName: 'credentialRequest',
  initialState: credentialRequestInitialState
});

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid/Grid';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import ImageGallery from 'react-image-gallery';
import '../../assets/override.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import { withStyles } from '@material-ui/core/styles';
import Portal from './Portal';
import { style } from '../../assets/style';
import { colors, sizes, globalSpacingPixels, globalDoubleSpacingPixels } from '../../assets/constants';

const styles = {
  ...style,
  imageGalleryContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    display: 'flex',
    zIndex: '22000',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    left: 0
  },
  imageHeader: {
    fontSize: sizes.fontLarge,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 0.89,
    backgroundColor: colors.white,
    padding: '22px 29px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  galleryControls: {
    color: colors.grey3,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center '
  },
  galleryNavigation: {
    width: globalDoubleSpacingPixels,
    marginRight: globalSpacingPixels,
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteButton: {
    marginRight: '15px'
  }
};

class ReppleImageGallery extends React.Component {
  state = {
    imageName: '',
    thumbnailId: 0
  };

  static getDerivedStateFromProps(props, prevProps) {
    const { thumbnailId: previousThumbnailId } = prevProps;
    const { thumbnailId } = props;
    if (thumbnailId !== previousThumbnailId) {
      return {
        thumbnailId
      };
    }
    return props;
  }

  componentDidMount() {
    const { thumbnailId, gallery } = this.props;
    this.handleSlide(gallery.findIndex(item => item.thumbnailId === thumbnailId));
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    const { closeGallery } = this.props;
    if (e.key === 'Escape') closeGallery();
  };

  handleCloseGallery = () => {
    const { closeGallery } = this.props;
    closeGallery();
  };

  handleDeleteGalleryItem = () => {
    const { deleteUserFileDownload, gallery } = this.props;
    const { index } = this.state;
    deleteUserFileDownload(gallery[index].identifier);

    if (gallery.length === 1) this.handleCloseGallery();
  };

  handleSlide = index => {
    const { gallery } = this.props;
    const image = gallery[index];
    this.setState({
      imageName: image.name,
      thumbnailId: image.thumbnailId
    });
  };

  renderLeftNav = (onClick, disabled) => {
    const { classes } = this.props;
    return (
      <Portal elementId="galleryControlLeft">
        <div className="image-gallery-custom-left-nav" disabled={disabled} onClick={onClick}>
          <i className={classNames('material-icons', classes.icon)}>keyboard_arrow_left</i>
        </div>
      </Portal>
    );
  };

  renderRightNav = (onClick, disabled) => {
    const { classes } = this.props;
    return (
      <Portal elementId="galleryControlRight">
        <div className="image-gallery-custom-left-nav" disabled={disabled} onClick={onClick}>
          <i className={classNames('material-icons', classes.icon)}>keyboard_arrow_right</i>
        </div>
      </Portal>
    );
  };

  render() {
    const { classes, gallery, thumbnailIndex, deleteUserFileDownload } = this.props;
    const { imageName } = this.state;

    return (
      <div className={classes.imageGalleryContainer}>
        <Grid
          container
          item
          xs={12}
          justify="center"
          alignContent="center"
          alignItems="center"
          style={{ position: 'relative' }}
        >
          <Grid item xs={2} />
          <ClickAwayListener onClickAway={this.handleCloseGallery}>
            <Grid item xs={8}>
              <div className={classes.imageHeader}>
                <div>{imageName}</div>
                <div className={classes.galleryControls}>
                  <div className={classes.galleryNavigation} id="galleryControls">
                    <div id="galleryControlLeft" />
                    <div id="galleryControlRight" />
                  </div>
                  {deleteUserFileDownload && (
                    <IconButton onClick={this.handleDeleteGalleryItem}>
                      <i className="material-icons">delete</i>
                    </IconButton>
                  )}
                  <IconButton onClick={this.handleCloseGallery}>
                    <i className="material-icons">clear</i>
                  </IconButton>
                </div>
              </div>
              <ImageGallery
                items={gallery}
                infinte
                showThumbnails={false}
                showFullscreenButton={false}
                startIndex={thumbnailIndex}
                onSlide={this.handleSlide}
                showPlayButton={false}
                renderLeftNav={this.renderLeftNav}
                renderRightNav={this.renderRightNav}
              />
            </Grid>
          </ClickAwayListener>

          <Grid item xs={2} />
        </Grid>
      </div>
    );
  }
}

ReppleImageGallery.propTypes = {
  classes: PropTypes.object.isRequired,
  gallery: PropTypes.array,
  closeGallery: PropTypes.func,
  thumbnailIndex: PropTypes.number,
  deleteUserFileDownload: PropTypes.func,
  thumbnailId: PropTypes.string
};

export default compose(withStyles(styles))(ReppleImageGallery);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { convertUtcToLocal } from '../../utils/utilities';
import { entityArraySelector } from '../../store/selectors/entity';
import { listHolderCredentialRequest } from '../../utils/entityRequests/holderRequests';
import { CREDENTIAL_STATUS_TYPE } from '../../assets/constants';

const styles = {
  ...style
};

class HolderCredentialListView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;

    getEntity(listHolderCredentialRequest(params.id));
  }

  actionNavigate = id => {
    const { history, match } = this.props;
    const { params } = match;
    history.push(`/admin/holder/view/${params.id}/credentials/view/${id}`);
  };

  getRowConfigs = () => {
    const { holderCredentials } = this.props;
    const items = holderCredentials || [];
    return items.map(credential => ({
      clickAction: () => this.actionNavigate(credential.documentId),
      cells: [
        {
          value: credential.issuerName
        },
        {
          value: credential.credentialTypeName
        },
        {
          value: CREDENTIAL_STATUS_TYPE[credential.credentialStatus].label
        },
        {
          value: credential.createDate
        },
        {
          value: credential.expiryDate
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm', credential.revocationDate)
        }
      ]
    }));
  };

  render() {
    const { classes, isLoading } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <ScrollableTable
              headers={[
                { value: 'Issuer' },
                { value: 'Credential Type' },
                { value: 'Status' },
                { value: 'Create Date' },
                { value: 'Expiry Date' },
                { value: 'Revocation Date' }
              ]}
              rowConfigs={this.getRowConfigs()}
              complete={!isLoading}
              emptyMessage="No Credentials Found"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

HolderCredentialListView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  holderCredentials: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  ...entityArraySelector(state, 'holderCredentials')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(HolderCredentialListView);

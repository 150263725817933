import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import moment from 'moment';
import { colors, commonFont } from '../../assets/constants';
import CircleIconGreen from '../../assets/images/circle-icon-green.svg';
import CircleIconYellow from '../../assets/images/circle-icon-yellow.svg';
import CircleIconRed from '../../assets/images/circle-icon-red.svg';
import CircleIconBlue from '../../assets/images/circle-icon-blue.svg';
import CircleIconWhite from '../../assets/images/circle-icon-white.svg';
import { getCredentialStatusColoredIcon } from '../../utils/utilities';

const style = {
  timeline: {
    width: '320px',
    display: 'flex',
    flexDirection: 'row',
    height: '45px',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginRight: '20px'
  },
  timelineBar: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginLeft: '15px',
    marginRight: '15px'
  },
  pending: {
    borderRadius: '5px',
    backgroundColor: '#FEF3E7',
    '& $request > div:first-of-type, $response > div:first-of-type': {
      color: '#FEAA01',
      ...commonFont,
      fontWeight: 600,
      fontSize: '12px'
    },
    '& $request > div:last-of-type, $response > div:last-of-type': {
      color: '#FEAA01',
      ...commonFont,
      fontWeight: 'normal',
      fontSize: '10px'
    },
    '& $fullLine': {
      border: 'solid 1px #FEE4B9'
    },
    '& $progress': {
      border: '3px solid #FEAA01'
    },
    '& $status > div': {
      color: '#FEAA01'
    }
  },
  verified: {
    borderRadius: '5px',
    backgroundColor: colors.lighterGreen,
    '& $request > div:first-of-type, $response > div:first-of-type': {
      color: colors.lightGreen,
      ...commonFont,
      fontWeight: 600,
      fontSize: '12px'
    },
    '& $request > div:last-of-type, $response > div:last-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 'normal',
      fontSize: '10px'
    },
    '& $fullLine': {
      border: `solid 3px ${colors.lightGreen}`,
      display: 'flex',
      alignItems: 'center'
    },
    '& $status > div': {
      color: colors.lightGreen
    }
  },
  rejected: {
    borderRadius: '5px',
    backgroundColor: colors.backgroundBlue,
    '& $request > div:first-of-type, $response > div:first-of-type': {
      color: colors.blueDark,
      ...commonFont,
      fontWeight: 600,
      fontSize: '12px'
    },
    '& $request > div:last-of-type, $response > div:last-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 'normal',
      fontSize: '10px'
    },
    '& $fullLine': {
      border: `solid 3px ${colors.blueDark}`,
      display: 'flex',
      alignItems: 'center'
    },
    '& $status > div': {
      color: colors.blueDark
    }
  },
  expired: {
    borderRadius: '5px',
    backgroundColor: '#FFDDDF',
    '& $request > div:first-of-type, $response > div:first-of-type': {
      color: colors.expiredRed,
      ...commonFont,
      fontWeight: 600,
      fontSize: '12px'
    },
    '& $request > div:last-of-type, $response > div:last-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 'normal',
      fontSize: '10px'
    },
    '& $fullLine': {
      border: `solid 3px ${colors.expiredRed}`,
      display: 'flex',
      alignItems: 'center'
    },
    '& $status > div': {
      color: colors.expiredRed
    }
  },
  revoked: {
    borderRadius: '5px',
    backgroundColor: colors.blueDark,
    '& $request > div:first-of-type, $response > div:first-of-type': {
      color: colors.white,
      ...commonFont,
      fontWeight: 600,
      fontSize: '12px'
    },
    '& $request > div:last-of-type, $response > div:last-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 'normal',
      fontSize: '10px'
    },
    '& $fullLine': {
      border: `solid 3px ${colors.white}`,
      display: 'flex',
      alignItems: 'center'
    },
    '& $status > div': {
      color: colors.white
    }
  },
  request: {
    minWidth: '58px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '10px'
  },
  response: {
    width: '84px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: '10px'
  },
  fullLine: {},
  progress: {},
  timelineBarItems: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& div': {
      width: '100%'
    },
    '& img:first-of-type': {
      marginRight: '-2px'
    },
    '& img:last-of-type': {
      marginLeft: '-2px'
    }
  },
  status: {
    ...commonFont,
    fontSize: '9px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
    '& div': {
      marginLeft: '6px'
    },
    '& img': {
      width: '14px',
      height: '14px'
    }
  }
};

const getWidth = (requestDate, responseDate) => {
  return Math.round(
    100 / moment.duration(moment(responseDate, 'DD-MM-YYYY').diff(moment(requestDate, 'DD-MM-YYYY'))).asHours()
  );
};

const getLeftOverWidth = (requestDate, responseDate) => {
  return (
    100 -
    Math.round(
      100 / moment.duration(moment(responseDate, 'DD-MM-YYYY').diff(moment(requestDate, 'DD-MM-YYYY'))).asHours()
    )
  );
};

const getResponseName = status => {
  if (status === 'PENDING') return 'RESPONSE';
  if (status === 'VERIFIED') return 'RESPONSE';
  if (status === 'EXPIRED') return 'EXPIRED';
  if (status === 'REJECTED') return 'REJECT';
  if (status === 'REVOKED') return 'REVOKED';
};
const getCircleForStatus = status => {
  if (status === 'PENDING') return CircleIconYellow;
  if (status === 'VERIFIED') return CircleIconGreen;
  if (status === 'EXPIRED') return CircleIconRed;
  if (status === 'REJECTED') return CircleIconBlue;
  if (status === 'REVOKED') return CircleIconWhite;
};

const CredentialTimeline = ({ classes, requestDate, responseDate, status }) => {
  return (
    <div
      className={classNames(
        classes.timeline,
        { [classes.pending]: status === 'PENDING' },
        { [classes.verified]: status === 'VERIFIED' },
        { [classes.expired]: status === 'EXPIRED' },
        { [classes.revoked]: status === 'REVOKED' },
        { [classes.rejected]: status === 'REJECTED' }
      )}
    >
      <div className={classes.request}>
        <div>REQUEST</div>
        <div>{requestDate}</div>
      </div>
      <div className={classes.timelineBar}>
        <div className={classes.status}>
          <img src={getCredentialStatusColoredIcon(status)} />
          <div>{status}</div>
        </div>
        <div className={classes.timelineBarItems}>
          <img src={getCircleForStatus(status)} />
          {status === 'PENDING' && (
            <div className={classes.progress} style={{ width: `${getWidth(requestDate, responseDate)}%` }} />
          )}
          <div className={classes.fullLine} style={{ width: `${getLeftOverWidth(requestDate, responseDate)}%` }} />
          <img src={getCircleForStatus(status)} />
        </div>
      </div>
      <div className={classes.response}>
        <div>{getResponseName(status)}</div>
        <div>{responseDate}</div>
      </div>
    </div>
  );
};

CredentialTimeline.propTypes = {
  classes: PropTypes.object.isRequired,
  requestDate: PropTypes.string.isRequired,
  responseDate: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default withStyles(style)(CredentialTimeline);

import ls from 'local-storage';
import moment from 'moment';
import { Auth } from 'aws-amplify';
import React from 'react';
import { capitalize } from 'lodash';
import { FILE_UPLOAD_STATUS } from '../assets/constants';
import PendingCredentialTickOrangeIcon from '../assets/images/pending-credential-tick-orange.svg';
import VerifiedCredentialTickGreenIcon from '../assets/images/verified-tick-icon-green.svg';
import RejectedExclamationBlueIcon from '../assets/images/rejected-exclamation-icon-blue.svg';
import ExpiredCrossRedIcon from '../assets/images/expired-cross-icon-red.svg';
import RevokedBlueIcon from '../assets/images/revoked-dark-blue-icon.svg';

export const hasPermission = perms => {
  const authorities = ls.get('authorities');
  let allowed = false;
  if (authorities) {
    perms.forEach(item => {
      allowed = !allowed ? authorities.indexOf(item) !== -1 : allowed;
    });
  }
  return allowed;
};

export const sortByKeyAlphabetically = key => (a, b) => a[key].localeCompare(b[key]);
export const sortByBoolean = boolVal => (a, b) => !!b[boolVal] - !!a[boolVal];

export const displayBoolAsString = bool => (bool ? 'Yes' : 'No');

export const limitDigits = (num, digitsAfterDecimal = 2) => {
  if (typeof num !== 'number') return num;

  return parseFloat(num.toFixed(digitsAfterDecimal));
};

export const grepItemBy = ({ keyName, targetValue, collection }) => collection.find(q => q[keyName] === targetValue);

export const isHomeOrganisation = () => {
  const homeKey = ls.get('homeKey');
  const key = ls.get('key');

  return homeKey && key && homeKey === key;
};

export const isRepple = () => {
  return !!ls.get('reppleOrganisation');
};

export const isAssurance = () => {
  return ls.get('assuranceCapability');
};

export const isTraining = () => {
  return ls.get('trainingCapability');
};

export const isCompetence = () => {
  return ls.get('competenceCapability');
};

export const isEdit = pathname => pathname.indexOf('/edit') > -1;

export const convertUtcToLocalMoment = (sourceFormat, date) => {
  return date ? moment.utc(date, sourceFormat).local() : null;
};

export const convertUtcToLocal = (sourceFormat, format, date) => {
  /* eslint-disable indent */
  return date
    ? moment
        .utc(date, sourceFormat)
        .local()
        .format(format)
    : '';
  /* eslint-enable indent */
};

export const convertMomentToUtc = (format, date) => {
  /* eslint-disable indent */
  return date
    ? date
        .local()
        .utc()
        .format(format)
    : '';
  /* eslint-enable indent */
};

export const convertLocalDateStringToUtcDateString = (format, date) => {
  return convertMomentToUtc(format, moment(date, format));
};

export const getRecords = (search, table) => {
  if (search && search.searchText) return search.records;

  return table.payload ? table.payload.records : [];
};

export const isLoggedIn = async () => {
  try {
    return await Auth.currentAuthenticatedUser().then(response => {
      return true;
    });
  } catch (e) {
    return false;
  }
};

export const getFirstPath = pathname => {
  return pathname.substring(0, pathname.substring(1).indexOf('/') + 1);
};

export const isUploadingCompleted = uploads => {
  let noInComplete = 0;

  Object.keys(uploads).forEach(upload => {
    noInComplete += uploads[upload].filter(
      item =>
        !item.status ||
        item.status === FILE_UPLOAD_STATUS.UPLOADED.id ||
        (item.status === FILE_UPLOAD_STATUS.CLEAN.id && item.mimeType.startsWith('image'))
    ).length;
  });

  return noInComplete === 0;
};

export const getUserInitials = (avatarUrl, name, width = '40px') => {
  if (avatarUrl) {
    return <img src={avatarUrl} alt="avatar" width={width} />;
  }
  if (name !== null) {
    let initials = name.charAt(0).toUpperCase();
    const spaceIndex = name.lastIndexOf(' ');
    initials += spaceIndex !== -1 ? name.charAt(spaceIndex + 1).toUpperCase() : name.charAt(1).toUpperCase();
    return initials;
  }

  return '';
};

export const getCredentialStatusColoredIcon = status => {
  if (status === 'PENDING' || status === 'UNVERIFIED') return PendingCredentialTickOrangeIcon;
  if (status === 'VERIFIED') return VerifiedCredentialTickGreenIcon;
  if (status === 'EXPIRED') return ExpiredCrossRedIcon;
  if (status === 'REVOKED') return RevokedBlueIcon;
  if (status === 'REJECTED') return RejectedExclamationBlueIcon;
};

export const getCredentialStatus = status => {
  if (status === 'UNVERIFIED') return 'PENDING';
  return status;
};

export const getCredentialFilterStatus = status => {
  if (status === 'UNVERIFIED' || status === 'PENDING') return 'Open';
  return capitalize(status);
};

export const getCredentialStatusFromFrontEnd = status => {
  if (status === 'PENDING') return 'UNVERIFIED';
  return status;
};

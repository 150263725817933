import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import ls from 'local-storage';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import NewSectionHeading from '../common/NewSectionHeading';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import { getQualificationRequest } from '../../utils/entityRequests/qualificationRequests';
import { entitySelector } from '../../store/selectors/entity';

const styles = {
  ...layout,
  ...style,
  displayLabelAndField: {
    marginTop: '13px'
  },
  chipContainer: {
    backgroundColor: '#fafafa !important',
    padding: '20px 0px 10px 13px',
    cursor: 'pointer',
    marginBottom: '13px'
  }
};

class QualificationView extends React.Component {
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getQualificationRequest(id));
  }

  render() {
    const { classes, match, qualification } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(qualification).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={ls.get('key') === qualification.key ? `/workforce/qualification/edit/${id}` : ''}
            cancelUrl="/workforce/qualification"
            header="Qualifications"
          />
          <Grid item xs={6}>
            <DisplayField heading="Name" value={qualification.name} />
            <NewSectionHeading heading="Qualifying Credential Type Group" />
            <div className={classNames(classes.displayLabelAndField)}>
              {qualification.groups.map(group => (
                <div key={`group-${group.identifier}`} className={classes.chipContainer} style={{ cursor: 'default' }}>
                  {group.credentialTypes.map(credentialType => (
                    <Chip
                      key={`chip-${credentialType.identifier}`}
                      label={credentialType.name}
                      className={classes.chipItem}
                    />
                  ))}
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      )
    );
  }
}

QualificationView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  qualification: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'qualification')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(QualificationView);

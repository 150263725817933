import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { tableSelector } from '../../../store/selectors/table';
import { loadingSelector } from '../../../store/selectors/shared';
import { getEntity, listEntities } from '../../../store/actions/EntityActions';
import { setInitialSearchFilter, changeSearchFilter } from '../../../store/actions/SearchActions';
import ListingWithSearch from '../../widgets/ListingWithSearch';
import { style } from '../../../assets/style';
import ScrollableTable from '../../table/ScrollableTable';
import { NO_SEARCH_VALUE } from '../../../constants/global';
import { convertUtcToLocal, getRecords } from '../../../utils/utilities';
import { listAttendanceRequest, getAttendanceStatsRequest } from '../../../utils/entityRequests/attendanceRequests';
import { entityArraySelector } from '../../../store/selectors/entity';
import { listCourseSelectRequest, listScheduledCourseRequest } from '../../../utils/entityRequests/trainingRequests';
import FuturePresetDateRangePicker from '../../widgets/FuturePresetDateRangePicker';

const styles = {
  ...style
};

class ScheduledCourseListView extends React.Component {
  async componentDidMount() {
    const { setInitialSearchFilter, getEntity } = this.props;
    await setInitialSearchFilter('scheduleCourses', {
      course: NO_SEARCH_VALUE,
      dates: {
        startDate: moment()
          .local()
          .startOf('day')
          .format('DD-MM-YYYY HH:mm:ss'),
        endDate: moment()
          .local()
          .endOf('day')
          .add(6, 'day')
          .format('DD-MM-YYYY HH:mm:ss')
      }
    });

    await getEntity(listCourseSelectRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/training/scheduledCourse/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(scheduledCourse => ({
      clickAction: () => this.actionNavigate(scheduledCourse.identifier),
      cells: [
        {
          value: scheduledCourse.courseTitle
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledCourse.startTime)
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', scheduledCourse.endTime)
        },
        {
          value: scheduledCourse.numberHolders
        },
        {
          value: scheduledCourse.capacity
        }
      ]
    }));
  };

  getListing = props => {
    const { search, isLoading, courses } = this.props;
    const courseFilterValue = search && search.filters.course ? search.filters.course : NO_SEARCH_VALUE;

    return (
      <ScrollableTable
        headers={[
          {
            value: 'Course',
            filter: {
              value: courseFilterValue,
              options: courses || [],
              onChange: event => props.handleFilterChange(event, 'course')
            }
          },
          {
            value: 'Start Time'
          },
          {
            value: 'End Time'
          },
          {
            value: 'Number Attendees'
          },
          {
            value: 'Capacity'
          }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Scheduled Courses Found"
        {...props}
      />
    );
  };

  handleSelectDate = async model => {
    const { changeSearchFilter, listEntities, listScheduledCourseRequest } = this.props;
    await changeSearchFilter('scheduleCourses', 'dates', {
      startDate: model.startDate,
      endDate: model.endDate
    });

    listEntities(0, 20, listScheduledCourseRequest());
  };

  render() {
    const { classes, listEntities, courses, table, listScheduledCourseRequest } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <FuturePresetDateRangePicker
              className={classes.datepicker}
              startDate={moment()
                .local()
                .startOf('day')}
              endDate={moment()
                .local()
                .endOf('day')
                .add(6, 'day')}
              onSelectDate={this.handleSelectDate}
            />

            <ListingWithSearch
              name="scheduleCourses"
              getSearchUrl={this.getSearchUrl}
              onList={listEntities}
              filtersList={{
                course: courses
              }}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listScheduledCourseRequest}
              hideSearch
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ScheduledCourseListView.propTypes = {
  classes: PropTypes.object.isRequired,
  setInitialSearchFilter: PropTypes.func.isRequired,
  getEntity: PropTypes.func.isRequired,
  listEntities: PropTypes.func.isRequired,
  listScheduledCourseRequest: PropTypes.func.isRequired,
  changeSearchFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  table: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  courses: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities'),
  ...tableSelector(state, 'scheduleCourses'),
  ...entityArraySelector(state, 'courses')
});

export default compose(
  connect(mapStateToProps, {
    setInitialSearchFilter,
    changeSearchFilter,
    getEntity,
    listEntities,
    listAttendanceRequest,
    getAttendanceStatsRequest,
    listScheduledCourseRequest
  }),
  withStyles(styles)
)(ScheduledCourseListView);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { colors, commonFont } from '../../assets/constants';
import { flexColCentered, flexRowCentered } from '../../assets/mixins';

const style = {
  headingContainer: {
    ...flexColCentered
  },
  heading: {
    ...commonFont,
    fontSize: '28px',
    fontWeight: 600,
    color: colors.blueDark
  },
  headingAndSideElements: {
    ...flexRowCentered
  }
};

const Heading = ({ classes, className, heading, children, fontSize, sideChildren, ...rest }) => (
  <div className={classNames(classes.headingContainer, className)} {...rest}>
    <div className={classes.headingAndSideElements}>
      <div className={classes.heading} style={{ fontSize }}>
        {heading}
      </div>
      {sideChildren && sideChildren}
    </div>
    {children}
  </div>
);

Heading.defaultProps = {
  className: null
};

Heading.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  fontSize: PropTypes.string,
  children: PropTypes.any,
  sideChildren: PropTypes.any,
  className: PropTypes.string
};

export default withStyles(style)(Heading);

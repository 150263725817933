import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { documentDownloadsSelector } from '../../store/selectors/document';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import { listCredentialFileDownloadsWithoutAuthority, downloadFile } from '../../store/actions/DocumentActions';
import layout from '../../assets/layout';
import { getHolderCredentialRequest } from '../../utils/entityRequests/holderRequests';
import { entitySelector } from '../../store/selectors/entity';
import { convertUtcToLocal } from '../../utils/utilities';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style
};

class HolderCredentialView extends React.Component {
  async componentDidMount() {
    const { getEntity, match, listCredentialFileDownloadsWithoutAuthority } = this.props;
    const { params } = match;
    const { id, credentialId } = params;
    await listCredentialFileDownloadsWithoutAuthority(credentialId);
    await getEntity(getHolderCredentialRequest(id, credentialId));
  }

  handleDownloadFile = (url, fileName, openInNewTab) => {
    const { downloadFile } = this.props;
    downloadFile(url, fileName, openInNewTab);
  };

  render() {
    const { classes, holderCredential } = this.props;

    return (
      Object.keys(holderCredential).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <NewHeaderViewActions header="Credential Details" />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Grid item xs={6}>
                <DisplayField heading="Issuer" value={holderCredential.issuer} />
                <DisplayField heading="Credential Type" value={holderCredential.credentialTypeName} />
                <DisplayField heading="Holder Name" value={holderCredential.holderName} />
                <DisplayField heading="Create Date" value={holderCredential.createDate} />
                <DisplayField heading="Expiry Date" value={holderCredential.expiryDate || 'Not Set'} />
                <DisplayField
                  heading="Revocation Date"
                  value={
                    convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm', holderCredential.revocationDate) ||
                    'Not Set'
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    );
  }
}

HolderCredentialView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  listCredentialFileDownloadsWithoutAuthority: PropTypes.func,
  downloadFile: PropTypes.func,
  holderCredential: PropTypes.object,
  fileDownloads: PropTypes.array
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'holderCredential'),
  ...documentDownloadsSelector(state)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, listCredentialFileDownloadsWithoutAuthority, downloadFile }),
  withRouter
)(HolderCredentialView);

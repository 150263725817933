/* eslint-disable react/no-danger */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress, Fade, MuiThemeProvider } from '@material-ui/core';

import breadcrumbStyles from '../../assets/Breadcrumbs';
import { customTheme } from '../../assets/constants';

class GenericBreadcrumbItem extends PureComponent {
  onHeaderTextClick = event => {
    const { disableLink } = this.props;

    if (disableLink) {
      event.preventDefault();
    }
  };

  render() {
    const { classes, mainText, subText, isLoading } = this.props;

    return (
      <div className={classes.flexRowCentered}>
        <div className={classes.breadcrumbItem} onClick={this.onHeaderTextClick}>
          <div>
            <Fade in={isLoading}>
              <div className={classes.loader}>
                <MuiThemeProvider theme={customTheme}>
                  <CircularProgress size={12} />
                </MuiThemeProvider>
              </div>
            </Fade>
            <Fade in={!isLoading}>
              <div>{mainText}</div>
            </Fade>
          </div>
          {subText && (
            <div className={classes.headerSubText}>
              <Fade in={isLoading}>
                <div className={classes.loader}>
                  <MuiThemeProvider theme={customTheme}>
                    <CircularProgress size={12} />
                  </MuiThemeProvider>
                </div>
              </Fade>
              <Fade in={!isLoading}>
                <div>{subText}</div>
              </Fade>
            </div>
          )}
        </div>
      </div>
    );
  }
}

GenericBreadcrumbItem.defaultProps = {
  mainText: '',
  disableLink: false,
  isLoading: false,
  subText: '&nbsp;'
};

GenericBreadcrumbItem.propTypes = {
  avatar: PropTypes.object,
  classes: PropTypes.object.isRequired,
  mainText: PropTypes.string,
  disableLink: PropTypes.bool,
  isLoading: PropTypes.bool,
  subText: PropTypes.string
};

export default withStyles(breadcrumbStyles)(GenericBreadcrumbItem);

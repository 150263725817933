/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { getCrowdsourceOrganisationRequest } from '../../utils/entityRequests/crowdsourceRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...layout,
  ...style
};

class CrowdsourceOrganisationView extends React.Component {
  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getCrowdsourceOrganisationRequest(id));
  }

  render() {
    const { classes, crowdOrganisation } = this.props;

    return (
      Object.keys(crowdOrganisation).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions header="Crowdsource Organisation" />
          <Grid item xs={6}>
            <DisplayField heading="Name" value={crowdOrganisation.name} />
            <DisplayField heading="Holder" value={crowdOrganisation.holderName} />
            <DisplayField heading="Contact Name" value={crowdOrganisation.contactName} />
            <DisplayField heading="Contact Email" value={crowdOrganisation.contactEmail} />
            <DisplayField heading="Contact Phone" value={crowdOrganisation.contactPhone} />
            <DisplayField heading="Contact Mobile" value={crowdOrganisation.contactMobile} />
            <DisplayField heading="Website" value={crowdOrganisation.website} />
            <DisplayField heading="Address" value={crowdOrganisation.address} />
          </Grid>
        </Grid>
      )
    );
  }
}

CrowdsourceOrganisationView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  crowdOrganisation: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'crowdOrganisation')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(CrowdsourceOrganisationView);

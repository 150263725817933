import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import MuiPhoneInput from 'material-ui-phone-number';
import {
  FormHelperText,
  TextField,
  LinearProgress,
  CircularProgress,
  Snackbar,
  SnackbarContent,
  IconButton,
  MuiThemeProvider,
  Checkbox
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { connect } from 'react-redux';
import { compose } from 'redux';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import { createField, clearError, validateField } from '../../store/actions/ValidationActions';
import { fieldErrorSelector } from '../../store/selectors/validationField';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import RadioButtons from '../control/RadioButtons';
import { style } from '../../assets/style';
import { colors, FILE_UPLOAD_STATUS, customTheme, commonFont } from '../../assets/constants';
import { convertMomentToUtc } from '../../utils/utilities';
import SelectWithSearch from '../control/SelectWithSearch';
import { ReactComponent as CheckedIcon } from '../../assets/images/checked-icon.svg';

const styles = {
  ...style,
  root: {},
  dateControl: {
    display: 'flex',
    marginTop: '0 !important',
    marginBottom: '16px'
  },
  dateControlError: {
    '& > div  > div': {
      '&:before': {
        borderBottom: '2px solid rgba(0, 0, 0, 0.42) !important'
      }
    },
    '& > div': {
      border: `solid 1px ${colors.errorRed}`
    },
    '& .Mui-error': {
      color: `${colors.errorRed} !important`,
      marginLeft: '0 !important'
    },
    '& p.Mui-error': {
      marginTop: '5px !important'
    }
  },
  formControl: {
    width: '100%',
    // marginTop: '16px',
    marginBottom: '16px'
  },
  formDateControl: {
    width: '100%',
    marginTop: '0px',
    paddingBottom: '0px',
    borderRadius: '4px',
    '& button': {
      padding: 0
    }
  },
  formControlError: {
    '& > div > div': {
      borderBottom: 'solid 2px rgba(0, 0, 0, 0.42) !important'
    }
  },
  formError: {
    margin: '0 14px 0'
  },
  formUploadContainerWithError: {
    '& > div': {
      borderWidth: '2px !important'
    }
  },
  formUploadContainer: {
    // marginTop: '10px',
    // marginBottom: '8px',
    '& > div': {
      height: '56px',
      minHeight: '56px',
      backgroundColor: colors.white,
      border: 'solid 1px rgba(135, 152, 173, 0.3)',
      borderRadius: '4px'
    },
    '& > div > div': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    '& > div > div > svg': {
      width: '30px',
      height: '30px',
      marginRight: '12px',
      color: colors.orangeNew
    },
    '& > div > div > p': {
      fontSize: '16px',
      color: colors.fontLightGrey,
      paddingLeft: '12px',
      ...commonFont
    }
  },
  fileErrorMessage: {
    color: colors.red,
    margin: '0px 5px 5px 10px'
  },
  fileUpload: {
    marginBottom: '15px',
    '& > div': {
      display: 'flex',
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.8)'
    }
  },
  uploadError: {
    backgroundColor: colors.red,
    '& div': {
      backgroundColor: colors.red,
      margin: '10px'
    }
  },
  fileName: {
    marginBottom: '10px'
  },
  upload: {
    backgroundColor: colors.orangeDarkHalf,
    '& > div': {
      backgroundColor: colors.orangeDark
    }
  },
  fileStatusMessage: {
    marginLeft: '6px',
    display: 'flex',
    justifyContent: 'center'
  },
  circularProgress: {
    marginLeft: '10px',
    color: colors.orangeDark
  },
  error: {
    backgroundColor: '#d32f2f'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '10px'
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  fileErrorMessages: {
    display: 'flex',
    flexDirection: 'column'
  },
  noMarginTop: {
    marginTop: '0px !important'
  },
  textField: {
    marginBottom: '16px',
    width: '100%',
    '& .MuiFormLabel-root': {
      fontWeight: 'normal',
      ...commonFont
    },
    '& .MuiFormHelperText-root': {
      ...commonFont,
      fontSize: '11px',
      color: colors.blue,
      marginLeft: 0,
      marginTop: '5px'
    },
    '& .Mui-error': {
      color: `${colors.errorRed} !important`
    },
    '& svg': {
      color: colors.orangeNew
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.white,
      '& fieldset': {
        borderColor: 'rgba(135, 152, 173, 0.3)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(135, 152, 173, 0.3)'
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.orangeNew
      }
    }
  },
  dateField: {
    marginBottom: '0',
    width: '100%',
    '& .MuiFormLabel-root': {
      fontWeight: 'normal',
      ...commonFont
    },
    '& .MuiFormHelperText-root': {
      ...commonFont,
      fontSize: '11px',
      color: colors.blue,
      marginLeft: 0,
      marginTop: '5px'
    },
    '& svg': {
      color: colors.orangeNew
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: colors.white,
      '& fieldset': {
        borderColor: 'rgba(135, 152, 173, 0.3)'
      },
      '&:hover fieldset': {
        borderColor: 'rgba(135, 152, 173, 0.3)'
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.orangeNew
      }
    }
  },
  radioOptionLabel: {
    minHeight: '56px',
    '& > span.MuiFormControlLabel-label': {
      fontSize: '14px',
      color: colors.blueDark,
      ...commonFont,
      fontWeight: 600
    },
    marginBottom: '16px'
  },
  radio: {
    marginLeft: '10px',
    '& > span > svg': {
      width: '20px',
      height: '20px',
      borderRadius: '4px',
      color: colors.white,
      backgroundColor: colors.white,
      border: '1px solid #D1D5E0'
    }
  },
  selected: {
    '& > span > svg': {
      borderColor: `${colors.orangeDark} !important`,
      backgroundColor: `${colors.orangeDark} !important`,
      stroke: colors.white
    }
  },
  dateLabel: {
    backgroundColor: 'transparent !important',
    zIndex: 1
  },
  checkBoxLabel: {
    fontSize: '10px'
  },
  checkBoxControl: {
    padding: '10px 5px',
    borderRadius: '5px',
    border: 'solid 1px rgba(135, 152, 173, 0.3)',
    '& > legend': {
      backgroundColor: colors.white,
      paddingLeft: '6px',
      paddingRight: '6px'
    }
  },
  outsideFormError: {
    margin: '5px 0px 16px 0px'
  },
  checkboxError: {
    marginBottom: '0px !important',
    border: `solid 1px ${colors.errorRed} !important`
  }
};

class NewValidatedField extends React.Component {
  typingTimeout = null;

  typingTimeoutDuration = 500;

  state = {
    errorMessages: []
  };

  componentDidMount() {
    const { createField, id, label, validators, value, ignoreValidation, type, options } = this.props;

    if (type !== VALIDATED_FIELD_TYPES.CHECKBOXES) {
      createField({ id, label, validators, text: value, ignoreValidation });
    } else {
      const option = options.find(item => item.checked);
      createField({ id, label, validators, text: option ? option.value : '', ignoreValidation });
    }
  }

  componentDidUpdate(prevProps) {
    const { createField, id, validators, value, label, ignoreValidation, type, options } = this.props;

    if (
      (!Array.isArray(value) && !prevProps.value && value) ||
      (Array.isArray(value) && prevProps.value.length !== value.length) ||
      prevProps.ignoreValidation !== ignoreValidation
    ) {
      if (type !== VALIDATED_FIELD_TYPES.CHECKBOXES) {
        createField({ id, label, validators, text: value, ignoreValidation });
      } else {
        const option = options.find(item => item.checked);
        createField({ id, label, validators, text: option ? option.value : '', ignoreValidation });
      }
    }
  }

  componentWillUnmount() {
    const { id, clearError } = this.props;

    clearError(id);
  }

  validate = text => {
    const { validators, id, ignoreValidation, label } = this.props;

    if (ignoreValidation) return;

    this.props.validateField({ text, id, label, validators, ignoreValidation });
  };

  handleChange = event => {
    const { id, error, onChange, clearError, ignoreValidation, options, type } = this.props;
    if (error && !ignoreValidation) clearError(id);

    onChange(event);
    const { value } = event.target;
    clearTimeout(this.typingTimeout);

    if (type === VALIDATED_FIELD_TYPES.CHECKBOXES) {
      const newValue =
        options.filter(item => (item.value === value && !item.checked) || (item.value !== value && item.checked))
          .length > 0
          ? value
          : '';
      this.typingTimeout = setTimeout(() => {
        this.validate(newValue);
      }, this.typingTimeoutDuration);
    } else {
      this.typingTimeout = setTimeout(() => {
        this.validate(value);
      }, this.typingTimeoutDuration);
    }
  };

  handleClick = event => {
    const { onClick } = this.props;
    onClick(event);
  };

  handleDateChange = date => {
    const { id, error, clearError, onChange, ignoreValidation } = this.props;
    const dateStr = format(date, 'dd-MM-yyyy');
    if (error && !ignoreValidation) clearError(id);
    onChange({
      target: {
        name: id,
        value: dateStr
      }
    });

    this.validate(dateStr);
  };

  handleDateTimeChange = (name, date) => {
    const { id, error, clearError, onChange, ignoreValidation } = this.props;
    const dateStr = format(date, 'dd-MM-yyyy HH:mm');

    if (error && !ignoreValidation) clearError(id);
    onChange({
      target: {
        name,
        value: convertMomentToUtc('DD-MM-YYYY HH:mm', moment(dateStr, 'DD-MM-YYYY HH:mm'))
      }
    });

    this.validate(dateStr);
  };

  handleDocumentChange = (id, event) => {
    const { error, onDocumentChange, clearError, ignoreValidation } = this.props;

    if (error && !ignoreValidation) clearError(id);

    onDocumentChange(id, event);
  };

  render() {
    // Destruct unused props so that they are not passed down to TextField and Select components
    /* eslint-disable */

    const {
      error,
      errorText,
      type,
      createField,
      onChange,
      clearError,
      validateField,
      ignoreValidation,
      name,
      classes,
      inputTextProps,
      uploadMimeTypes,
      icon,
      id,
      label,
      maxNoFiles,
      value,
      displayClear,
      className,
      labelPlacement,
      options,
      ...rest
    } = this.props;

    const { errorMessages } = this.state;
    const helperText = error || errorText || rest.helperText || '';
    const errorProp = error || errorText || false;
    switch (type) {
      case VALIDATED_FIELD_TYPES.PHONE:
        return (
          <MuiPhoneInput
            countryCodeEditable={true}
            inputClass={classNames(classes.textField)}
            onChange={value => this.handleChange({ target: { name, value } })}
            {...rest}
            label={label}
            value={value}
            name={name}
            helperText={helperText}
            error={!!errorProp}
            InputProps={{ className: classNames(classes.textFieldContainer), ...inputTextProps }}
            variant="outlined"
            defaultCountry="ie"
            InputLabelProps={{
              className: classes.label
            }}
            className={classNames(classes.textField, classes.noMarginTop)}
          />
        );
      case VALIDATED_FIELD_TYPES.FILE:
        return (
          <div>
            <FormControl
              variant="outlined"
              component="fieldset"
              fullWidth={true}
              style={{ backgroundColor: 'white' }}
              className={classNames(classes.formUploadContainer, classes.formControl, {
                [classes.formUploadContainerWithError]: !!errorProp
              })}
            >
              <DropzoneArea
                filesLimit={maxNoFiles}
                onDrop={e => this.handleDocumentChange(id, e)}
                dropzoneText={`${label} - Click or drop file`}
                showPreviews={false}
                showPreviewsInDropzone={false}
                showAlerts={false}
                onDropRejected={(rejectedFiles, evt) => {
                  const errorMessages = [];
                  rejectedFiles.forEach(item => {
                    let errorMessage = `File ${item.name} was rejected.`;

                    if (uploadMimeTypes.indexOf(item.type) === -1) {
                      errorMessage += ' File type is not supported.';
                    }
                    if (item.size > 20 * 1024 * 1000) {
                      errorMessage += ' File is too big. Size limit is 20MB';
                    }
                    errorMessages.push(errorMessage);
                  });
                  this.setState({ errorMessages });
                }}
                maxFileSize={20 * 1024 * 1000}
                acceptedFiles={uploadMimeTypes}
              />
              {!!errorProp && (
                <FormHelperText error className={classes.formError}>
                  {errorProp}
                </FormHelperText>
              )}
              <Snackbar
                open={errorMessages.length > 0}
                autoHideDuration={5000}
                onClose={this.handleCloseSnackbar}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
              >
                <SnackbarContent
                  aria-describedby="client-snackbar"
                  className={classes.error}
                  message={
                    /* eslint-disable react/jsx-wrap-multilines */
                    <span id="client-snackbar" className={classes.message}>
                      <ErrorIcon className={classes.iconVariant} />
                      <div className={classes.fileErrorMessages}>
                        {errorMessages.map(item => (
                          <div>{item}</div>
                        ))}
                      </div>
                    </span>
                    /* eslint-enable react/jsx-wrap-multilines */
                  }
                  action={[
                    <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleCloseSnackbar}>
                      <CloseIcon className={classes.icon} />
                    </IconButton>
                  ]}
                />
              </Snackbar>
            </FormControl>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {value.map((item, index) => {
                return (
                  <div className={classes.fileUpload} key={`fileupload-${index}`}>
                    <div>
                      <div className={classes.fileName}>{item.name.substring(item.name.indexOf('_') + 1)}</div>
                      {item.error && <div className={classes.fileErrorMessage}>{item.error}</div>}
                      {!item.error && !item.status && <div className={classes.fileStatusMessage}>- Uploading File</div>}
                      {!item.error && item.status && item.status === FILE_UPLOAD_STATUS.UPLOADED.id && (
                        <div className={classes.fileStatusMessage}>
                          - Scanning for viruses
                          <MuiThemeProvider theme={customTheme}>
                            <CircularProgress size={20} className={classes.circularProgress} />
                          </MuiThemeProvider>
                        </div>
                      )}
                      {/* eslint-disable indent */ !item.error &&
                        item.status &&
                        item.status === FILE_UPLOAD_STATUS.CLEAN.id &&
                        item.mimeType.startsWith('image') && (
                          <div className={classes.fileStatusMessage}>
                            - Resizing Image & Creating Thumbnail
                            <MuiThemeProvider theme={customTheme}>
                              <CircularProgress size={20} className={classes.circularProgress} />
                            </MuiThemeProvider>
                          </div>
                        )}
                      {!item.error && item.status && item.status === FILE_UPLOAD_STATUS.RESIZED_THUMBNAIL.id && (
                        <div className={classes.fileStatusMessage}>- File Uploaded</div>
                      )}
                      {!item.error &&
                        item.status &&
                        item.status === FILE_UPLOAD_STATUS.CLEAN.id &&
                        !item.mimeType.startsWith('image') && (
                          <div className={classes.fileStatusMessage}>- File Uploaded</div>
                        )}
                      {!item.error && item.status && item.status === FILE_UPLOAD_STATUS.INFECTED.id && (
                        <div className={classNames(classes.fileStatusMessage, classes.fileErrorMessage)}>
                          - Virus detected. Unable to upload file
                        </div>
                      ) /* eslint-enable indent */}
                    </div>
                    <LinearProgress
                      variant="determinate"
                      value={item.completeness}
                      className={classNames(classes.upload, {
                        [classes.uploadError]: item.error
                      })}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      case VALIDATED_FIELD_TYPES.TEXT:
        return (
          <TextField
            label={label}
            onChange={this.handleChange}
            helperText={helperText}
            error={!!errorProp}
            onClick={this.handleClick}
            name={id}
            value={value}
            InputProps={{
              // className: classNames(classes.textField),
              ...inputTextProps
            }}
            InputLabelProps={
              {
                // className: classes.label
              }
            }
            className={classNames(classes.textField, className)}
            variant="outlined"
          />
        );
      case VALIDATED_FIELD_TYPES.PASSWORD:
        return (
          <TextField
            type="password"
            label={label}
            onChange={this.handleChange}
            helperText={helperText}
            error={!!errorProp}
            onClick={this.handleClick}
            name={id}
            value={value}
            InputProps={{
              // className: classNames(classes.textField),
              ...inputTextProps
            }}
            InputLabelProps={
              {
                // className: classes.label
              }
            }
            className={classNames(classes.textField, className)}
            variant="outlined"
          />
        );
      case VALIDATED_FIELD_TYPES.SELECT:
        return (
          <SelectWithSearch
            {...rest}
            name={name}
            handleChange={this.handleChange}
            error={errorProp}
            icon={icon}
            label={label}
            id={id}
            value={value}
            displayClear={displayClear}
            className={className}
          />
        );
      case VALIDATED_FIELD_TYPES.CHECKBOXES:
        return (
          <Fragment>
            <FormControl
              className={classNames(classes.textField, classes.checkBoxControl, {
                [classes.checkboxError]: !!errorProp
              })}
              variant="outlined"
              component="fieldset"
              fullWidth={true}
              style={{ backgroundColor: 'white' }}
            >
              <InputLabel shrink={true} component="legend">
                {label}
              </InputLabel>

              {options.map(item => (
                <FormControlLabel
                  control={
                    /* eslint-disable react/jsx-wrap-multilines */
                    <Checkbox
                      checked={item.checked}
                      name={item.name}
                      id={item.name}
                      onChange={this.handleChange}
                      value={item.value}
                      className={classNames(classes.radio, {
                        [classes.selected]: item.checked
                      })}
                      checkedIcon={<CheckedIcon />}
                    />
                    /* eslint-enable react/jsx-wrap-multilines */
                  }
                  label={item.label}
                  labelPlacement="end"
                  className={classes.checkBoxLabel}
                  key={`${item.name}-${item.identifier}`}
                />
              ))}
            </FormControl>
            {!!errorProp && (
              <FormHelperText error className={classes.outsideFormError}>
                {errorProp}
              </FormHelperText>
            )}
          </Fragment>
        );
      case VALIDATED_FIELD_TYPES.RADIO_GROUP:
        return (
          <RadioButtons
            {...rest}
            label={label}
            overrideClassName={classes.textField}
            handleChange={this.handleChange}
            error={errorProp}
            // onClick={this.handleClick}
            name={name}
          />
        );
      case VALIDATED_FIELD_TYPES.CHECKBOX:
        return (
          <Fragment>
            <FormControlLabel
              control={
                /* eslint-disable react/jsx-wrap-multilines */
                <Checkbox
                  checked={value}
                  onChange={this.handleChange}
                  className={classNames(classes.radio, {
                    [classes.selected]: value
                  })}
                  checkedIcon={<CheckedIcon />}
                />
                /* eslint-enable react/jsx-wrap-multilines */
              }
              label={label}
              labelPlacement={labelPlacement}
              className={classes.radioOptionLabel}
            />
            {errorProp && (
              <FormHelperText className={classes.fieldHelperText} style={{ marginLeft: 0 }}>
                {errorProp}
              </FormHelperText>
            )}
          </Fragment>
        );
      case VALIDATED_FIELD_TYPES.DATE_TIME:
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl
              className={classNames(classes.textField, {
                [classes.dateControlError]: !!errorProp
              })}
            >
              <KeyboardDateTimePicker
                format="dd-MM-yyyy HH:mm"
                name={name}
                onChange={date => this.handleDateTimeChange(name, date)}
                value={value ? moment(value, 'DD-MM-yyyy HH:mm').toDate() : null}
                inputVariant="outlined"
                label={label}
                InputProps={{
                  className: classNames(classes.dateField, 'MuiFilledInput-root', 'NotchedOutline-root'),
                  value: value,
                  disabled: true,
                  error: !!errorProp,
                  ...inputTextProps
                }}
                InputLabelProps={{
                  className: classNames(classes.dateLabel, 'MuiInputLabel-filled')
                }}
                className={classNames(classes.formDateControl)}
              />
              {!!errorProp && (
                <FormHelperText error className={classes.formError}>
                  {errorProp}
                </FormHelperText>
              )}
            </FormControl>
          </MuiPickersUtilsProvider>
        );
      case VALIDATED_FIELD_TYPES.DATE:
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <FormControl
              className={classNames(classes.dateControl, {
                [classes.dateControlError]: !!errorProp
              })}
              variant="outlined"
            >
              <KeyboardDatePicker
                {...rest}
                label={label}
                format="dd-MM-yyyy"
                name={id}
                onChange={date => this.handleDateChange(date)}
                value={moment(value, 'DD-MM-yyyy').toDate()}
                inputVariant="outlined"
                InputProps={{
                  // disableUnderline: true,
                  className: classNames(classes.dateField, 'MuiFilledInput-root', 'NotchedOutline-root'),
                  value: value,
                  disabled: true,
                  variant: 'outlined',
                  error: !!errorProp,
                  ...inputTextProps
                }}
                InputLabelProps={{
                  className: classNames(classes.dateLabel, 'MuiInputLabel-filled')
                }}
                className={classNames(classes.formDateControl, classes.dateField)}
              />
              {!!errorProp && (
                <FormHelperText error className={classes.formError}>
                  {errorProp}
                </FormHelperText>
              )}
            </FormControl>
          </MuiPickersUtilsProvider>
        );
      default:
        return null;
    }
  }
}

NewValidatedField.defaultProps = {
  type: VALIDATED_FIELD_TYPES.TEXT,
  variant: 'filled',
  margin: 'normal',
  className: '',
  helperText: '',
  validators: '',
  values: [],
  error: '',
  errorText: '',
  InputProps: null,
  onChange: () => {},
  onClick: () => {},
  disabled: false,
  creatable: false,
  ignoreValidation: false,
  inputTextProps: {},
  uploadMimeTypes: [],
  labelPlacement: 'start'
};

NewValidatedField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  createField: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onDocumentChange: PropTypes.func,
  onClick: PropTypes.func,
  validators: PropTypes.array,
  variant: PropTypes.string,
  value: PropTypes.any,
  values: PropTypes.array,
  type: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  errorText: PropTypes.string,
  InputProps: PropTypes.object,
  inputTextProps: PropTypes.object,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  ignoreValidation: PropTypes.bool,
  displayClear: PropTypes.bool,
  creatable: PropTypes.bool,
  uploadMimeTypes: PropTypes.array,
  options: PropTypes.array,
  children: PropTypes.any
};

const mapStateToProps = (state, ownProps) => fieldErrorSelector(state, ownProps.id);

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    createField,
    clearError,
    validateField
  })
)(NewValidatedField);

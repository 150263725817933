import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import { MuiThemeProvider } from '@material-ui/core';
import { style } from '../../assets/style';
import { customTheme } from '../../assets/constants';

const styles = () => ({
  ...style,
  actionButton: {
    paddingLeft: '0',
    '&:hover': {
      backgroundColor: '#fff'
    }
  },
  confirmText: {
    color: 'rgba(0, 0, 0, 0.54)'
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)'
  }
});

class IconButtonActionConfirmationDialog extends React.Component {
  state = {
    open: false
  };

  handleClick = event => {
    event.stopPropagation();
  };

  handleClickOpen = event => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ open: true });
  };

  handleClose = e => {
    this.setState({ open: false });
    this.props.closeContextMenu(e);
  };

  handleConfirmClick = e => {
    this.props.deleteFunction(e);
    this.handleClose(e);
  };

  render() {
    const {
      classes,
      fullScreen,
      icon,
      title,
      body,
      bodyObject,
      buttonText,
      materialIcon,
      isIconDisabled,
      text,
      textStyle,
      tooltipTitle,
      tooltipPlacement
    } = this.props;
    const disabledClass = classes.disabled;

    return (
      <span>
        {text && (
          <div onClick={this.handleClickOpen} style={textStyle}>
            {text}
          </div>
        )}
        {buttonText && (
          <Button
            onClick={this.handleClickOpen}
            variant="text"
            className={`${classes.noTextTransform} ${classes.actionButton}`}
          >
            <i className={`material-icons ${classes.icon}`}>{materialIcon}</i>
            {buttonText}
          </Button>
        )}
        {icon && (
          <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
            <IconButton variant="fab" onClick={this.handleClickOpen} disabled={isIconDisabled}>
              {icon}
            </IconButton>
          </Tooltip>
        )}
        {!buttonText && materialIcon && (
          <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
            <IconButton onClick={this.handleClickOpen} disabled={isIconDisabled}>
              <i className={classnames('material-icons', classes.icon, { [disabledClass]: isIconDisabled })}>
                {this.props.materialIcon}
              </i>
            </IconButton>
          </Tooltip>
        )}
        <MuiThemeProvider theme={customTheme}>
          <Dialog
            fullScreen={fullScreen}
            open={this.state.open}
            disableBackdropClick
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
            onClick={event => this.handleClick(event)}
          >
            <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
            <DialogContent className={classes.confirmText}>
              {body && <DialogContentText>{body}</DialogContentText>}
              {bodyObject || ''}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.handleConfirmClick} color="primary" autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </span>
    );
  }
}

IconButtonActionConfirmationDialog.propTypes = {
  deleteFunction: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  body: PropTypes.any,
  buttonText: PropTypes.string,
  bodyObject: PropTypes.object,
  title: PropTypes.string,
  icon: PropTypes.object,
  classes: PropTypes.object.isRequired,
  materialIcon: PropTypes.string,
  isIconDisabled: PropTypes.bool,
  text: PropTypes.string,
  closeContextMenu: PropTypes.func,
  textStyle: PropTypes.object,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.string
};

IconButtonActionConfirmationDialog.defaultProps = {
  closeContextMenu: () => {},
  title: 'Delete Confirmation',
  tooltipPlacement: 'top',
  tooltipTitle: 'Delete'
};

export default withStyles(styles)(IconButtonActionConfirmationDialog);

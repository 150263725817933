import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, IconButton, Popover, Tooltip } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import DatePicker from 'react-datepicker';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Link } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { entityArraySelector } from '../../../store/selectors/entity';
import { getEntity, createInitialEntity, putEntity, saveEntity } from '../../../store/actions/EntityActions';
import { dispatchGlobalError } from '../../../store/actions/GlobalErrorActions';
import { style } from '../../../assets/style';
import { loadingSelector } from '../../../store/selectors/shared';
import {
  getScheduledCourseRequest,
  listCentreRoomSelectRequest,
  listCentreSelectRequest,
  listCourseSelectRequest,
  listScheduledSessionRequest,
  saveScheduledSessionRequest
} from '../../../utils/entityRequests/trainingRequests';
import ValidatedField from '../../widgets/ValidatedField';
import { VALIDATED_FIELD_TYPES, ALL } from '../../../constants/global';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { convertMomentToUtc, convertUtcToLocalMoment } from '../../../utils/utilities';
import { colors, PERIOD_TYPE } from '../../../assets/constants';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import SectionHeading from '../../common/SectionHeading';

const DragAndDropCalendar = withDragAndDrop(Calendar);

const styles = {
  ...style,
  filters: {
    display: 'flex',
    '& > div': {
      marginLeft: '20px'
    },
    alignItems: 'center'
  },
  calendar: {
    '& .rbc-row-resource': {
      backgroundColor: colors.grey2
    },
    '& .rbc-time-header-cell': {
      backgroundColor: `${colors.grey3} !important`
    },
    '& .rbc-time-header-cell .rbc-today': {
      backgroundColor: `${colors.grey3} !important`
    },
    '& .rbc-time-gutter': {
      backgroundColor: `${colors.grey3} !important`
    },
    '& .rbc-time-header-gutter': {
      backgroundColor: `${colors.grey3} !important`
    },
    '& .rbc-event': {
      '& .rbc-event-label': {
        fontSize: '10px'
      },
      '& .rbc-event-content': {
        fontSize: '13px'
      },
      backgroundColor: `${colors.orangeDark} !important`
    }
  },
  monthCalendar: {
    height: 'calc(100vh - 110px)'
  },
  helpText: {
    marginTop: 0,
    marginBottom: '8px'
  },
  sessionDetails: {
    paddingLeft: '25px',
    paddingBottom: '25px',
    paddingRight: '60px'
  },
  popOverActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  popOverDetails: {
    minWidth: '400px',
    minHeight: '400px',
    flexDirection: 'column',
    display: 'flex'
  },
  popOverButtons: {
    marginBottom: '20px',
    marginRight: '15px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  calendarContainer: {
    overflowX: 'auto'
  },
  schedulingViewContainer: {
    width: 'calc(100vw - 160px)',
    overflowY: 'auto',
    overflowX: 'auto'
  }
};

class TrainingScheduleComponent extends React.Component {
  constructor(props) {
    super(props);
    const { trainingScheduleConfig } = props;
    this.state = {
      filter: {
        course: ALL,
        centre: trainingScheduleConfig.centreId ? trainingScheduleConfig.centreId : ALL,
        room: ALL
      },
      rooms: [],
      resourceMap: [],
      scheduledSessions: [],
      eventCreated: false,
      selectedSession: null,
      editPopOver: false
    };

    this.handleMoveEvent = this.handleMoveEvent.bind(this);
    this.handleNewEvent = this.handleNewEvent.bind(this);
  }

  async componentDidMount() {
    const { getEntity, trainingScheduleConfig } = this.props;
    const { filter } = this.state;
    const courses = await getEntity(listCourseSelectRequest());
    const centres = await getEntity(listCentreSelectRequest());
    courses.unshift({ label: 'All', value: ALL });
    centres.unshift({ label: 'All', value: ALL });
    const startDate = moment(trainingScheduleConfig.scrollToTime)
      .startOf('week')
      .toDate();
    const endDate = moment(trainingScheduleConfig.scrollToTime)
      .endOf('week')
      .toDate();

    this.setState({
      courses,
      centres,
      resourceMap: centres.filter(centre => centre.value !== ALL).map(item => ({ id: item.value, title: item.label })),
      startDate,
      endDate,
      scheduledSessions: await this.fetchScheduledEvents(filter.course, filter.centre, filter.room, startDate, endDate)
    });

    this.handleChange({ target: { name: 'centre', value: filter.centre } });
  }

  componentWillUnmount() {
    const { getEntity, trainingScheduleConfig } = this.props;

    if (trainingScheduleConfig.scheduledCourseId) {
      getEntity(getScheduledCourseRequest(trainingScheduleConfig.scheduledCourseId));
    }
  }

  getView = () => {
    const { trainingScheduleConfig } = this.props;
    if (trainingScheduleConfig.allDay) return Views.MONTH;
    return Views.WEEK;
  };

  getViews = () => {
    const { trainingScheduleConfig } = this.props;
    if (!trainingScheduleConfig.modal) return ['day', 'week', 'month'];
    if (trainingScheduleConfig.allDay) return ['week', 'month'];
    return ['day', 'week'];
  };

  handleChange = async event => {
    const { getEntity } = this.props;
    const { name, value } = event.target;
    const { filter, centres, startDate, endDate, rooms } = this.state;
    const filterModel = _.cloneDeep(filter);
    _.set(filterModel, name, value);

    if (name === 'centre') {
      const fetchedRooms = value === ALL ? [] : await getEntity(listCentreRoomSelectRequest(value));
      fetchedRooms.unshift({ label: 'All', value: ALL });
      this.setState({
        filter: filterModel,
        rooms: fetchedRooms,
        resourceMap:
          value === ALL
            ? centres.filter(centre => centre.value !== ALL).map(item => ({ id: item.value, title: item.label }))
            : fetchedRooms.filter(room => room.value !== ALL).map(item => ({ id: item.value, title: item.label })),
        scheduledSessions: await this.fetchScheduledEvents(
          filterModel.course,
          filterModel.centre,
          filterModel.room,
          startDate,
          endDate
        )
      });
    } else if (name === 'room') {
      this.setState({
        filter: filterModel,
        resourceMap:
          value === ALL
            ? rooms.filter(room => room.value !== ALL).map(item => ({ id: item.value, title: item.label }))
            : rooms.filter(room => room.value === value).map(item => ({ id: item.value, title: item.label })),
        scheduledSessions: await this.fetchScheduledEvents(
          filterModel.course,
          filterModel.centre,
          filterModel.room,
          startDate,
          endDate
        )
      });
    } else {
      this.setState({
        filter: filterModel,
        scheduledSessions: await this.fetchScheduledEvents(
          filterModel.course,
          filterModel.centre,
          filterModel.room,
          startDate,
          endDate
        )
      });
    }
  };

  handleSelectedSessionChange = async event => {
    const { name, value } = event.target;
    const { selectedSession } = this.state;
    const selectedSessionModel = _.cloneDeep(selectedSession);
    _.set(selectedSessionModel, name, value);
    this.setState({
      selectedSession: selectedSessionModel
    });
  };

  handleRangeChange = async event => {
    const { filter } = this.state;
    if (Array.isArray(event)) {
      if (event.length === 7) {
        const startDate = moment(event[0]).toDate();
        const endDate = moment(event[0])
          .add(7, 'days')
          .toDate();
        this.setState({
          startDate,
          endDate,
          scheduledSessions: await this.fetchScheduledEvents(
            filter.course,
            filter.centre,
            filter.room,
            startDate,
            endDate
          )
        });
      } else if (event.length === 1) {
        const startDate = moment(event[0]).toDate();
        const endDate = moment(event[0])
          .endOf('day')
          .toDate();
        this.setState({
          startDate,
          endDate,
          scheduledSessions: await this.fetchScheduledEvents(
            filter.course,
            filter.centre,
            filter.room,
            startDate,
            endDate
          )
        });
      }
    } else {
      const startDate = moment(event.start).toDate();
      const endDate = moment(event.end)
        .endOf('month')
        .toDate();
      this.setState({
        startDate,
        endDate,
        scheduledSessions: await this.fetchScheduledEvents(
          filter.course,
          filter.centre,
          filter.room,
          startDate,
          endDate
        )
      });
    }
  };

  fetchScheduledEvents = async (course, centre, room, startDate, endDate) => {
    const { getEntity } = this.props;
    const events = await getEntity(listScheduledSessionRequest(course, centre, room, startDate, endDate));
    return this.getScheduledSessions(events, centre);
  };

  getScheduledSessions = (events, centre) => {
    return (events || []).map(item => ({
      id: item.identifier,
      title: item.course.name,
      start: convertUtcToLocalMoment('DD-MM-YYYY HH:mm', item.sessionTime).toDate(),
      end: convertUtcToLocalMoment('DD-MM-YYYY HH:mm', item.sessionEndTime).toDate(),
      resourceId: centre === ALL ? item.centre.identifier : item.room.identifier,
      centerId: item.centre.identifier,
      centreName: item.centre.name,
      roomId: item.room.identifier,
      roomName: item.room.name,
      scheduledCourseId: item.scheduledCourseId
    }));
  };

  checkSessionOverlap = entity => {
    const { scheduledSessions } = this.state;
    const { dispatchGlobalError } = this.props;

    if (
      scheduledSessions.filter(
        item =>
          ((convertUtcToLocalMoment('DD-MM-YYYY HH:mm', entity.sessionTime).isBefore(moment(item.start)) &&
            convertUtcToLocalMoment('DD-MM-YYYY HH:mm', entity.sessionEndTime).isAfter(moment(item.start))) ||
            (convertUtcToLocalMoment('DD-MM-YYYY HH:mm', entity.sessionTime).isBefore(moment(item.end)) &&
              convertUtcToLocalMoment('DD-MM-YYYY HH:mm', entity.sessionEndTime).isAfter(moment(item.end))) ||
            (convertUtcToLocalMoment('DD-MM-YYYY HH:mm', entity.sessionTime).isAfter(moment(item.start)) &&
              convertUtcToLocalMoment('DD-MM-YYYY HH:mm', entity.sessionEndTime).isBefore(moment(item.end)))) &&
          entity.room.identifier === item.resourceId &&
          entity.identifier !== item.id
      ).length !== 0
    ) {
      dispatchGlobalError('Scheduled Session', 'There is a conflict on the scheduled resource');
      return false;
    }

    return true;
  };

  handleSelectEvent = (event, b) => {
    const { scheduledSessions } = this.state;
    this.setState({
      selectedSession: {
        session: scheduledSessions.find(item => item.id === event.id),
        left: b.clientX,
        top: b.clientY,
        start: event.start,
        end: event.end
      }
    });
  };

  handlePopOverClose = () => {
    this.setState({
      selectedSession: null,
      editPopOver: false
    });
  };

  handlePopOverEdit = () => {
    this.setState({
      editPopOver: true
    });
  };

  handleResizeEvent = event => {
    const { trainingScheduleConfig } = this.props;
    const { scheduledSessions } = this.state;
    const scheduledSessionsModel = _.cloneDeep(scheduledSessions);
    const session = scheduledSessionsModel.find(item => item.id === event.event.id);
    session.start = event.start;
    session.end = event.end;

    const entity = {
      identifier: event.event.id,
      room: { identifier: event.event.resourceId },
      sessionTime: convertMomentToUtc('DD-MM-YYYY HH:mm', moment(event.start)),
      sessionEndTime: convertMomentToUtc('DD-MM-YYYY HH:mm', moment(event.end)),
      courseSessionId: trainingScheduleConfig.courseSessionId,
      allDay: false
    };

    if (this.checkSessionOverlap(entity)) {
      if (moment(event.start).isAfter(moment()) && moment(event.end).isAfter(moment())) {
        this.setState(
          {
            scheduledSessions: scheduledSessionsModel
          },
          () => this.handleSaveScheduledSession(trainingScheduleConfig.scheduledCourseId, entity)
        );
      } else dispatchGlobalError('Scheduled Session', 'Scheduled sessions must be made in the future');
    }
  };

  handleNewEvent = async event => {
    const { trainingScheduleConfig, dispatchGlobalError } = this.props;
    const { filter } = this.state;
    const entity = {
      room: { identifier: trainingScheduleConfig.allDay ? filter.room : event.resourceId },
      sessionTime: convertMomentToUtc('DD-MM-YYYY HH:mm', moment(event.start)),
      sessionEndTime: convertMomentToUtc('DD-MM-YYYY HH:mm', moment(event.end)),
      courseSessionId: trainingScheduleConfig.courseSessionId,
      allDay: trainingScheduleConfig.allDay
    };

    if (this.checkSessionOverlap(entity)) {
      if (moment(event.start).isAfter(moment()) && moment(event.end).isAfter(moment())) {
        this.handleSaveScheduledSession(trainingScheduleConfig.scheduledCourseId, entity);
      } else dispatchGlobalError('Scheduled Session', 'Scheduled sessions must be made in the future');
    }
  };

  handleSaveScheduledSession = async (scheduledCourseId, entity) => {
    const { filter, startDate, endDate } = this.state;
    const { saveEntity } = this.props;

    const scheduledSessionId = await saveEntity(saveScheduledSessionRequest(scheduledCourseId, entity));

    if (scheduledSessionId) {
      this.setState({
        eventCreated: true,
        scheduledSessions: await this.fetchScheduledEvents(
          filter.course,
          filter.centre,
          filter.room,
          startDate,
          endDate
        )
      });
    }
  };

  handleUpdateScheduledSession = () => {
    const { selectedSession } = this.state;

    this.handleMoveEvent({
      event: { id: selectedSession.session.id },
      start: selectedSession.session.start,
      end: selectedSession.session.end,
      resourceId: selectedSession.session.resourceId,
      isAllDay:
        moment(selectedSession.session.start).isSame(moment(selectedSession.session.start).startOf('day')) &&
        moment(selectedSession.session.end).isSame(moment(selectedSession.session.start).startOf('end'))
    });

    this.handlePopOverClose();
  };

  handleMoveEvent({ event, start, end, resourceId, isAllDay: droppedOnAllDaySlot }) {
    const { trainingScheduleConfig, dispatchGlobalError } = this.props;
    const { filter, scheduledSessions } = this.state;
    const scheduledSessionsModel = _.cloneDeep(scheduledSessions);
    const session = scheduledSessionsModel.find(item => item.id === event.id);

    session.start = start;
    session.end = end;
    session.resourceId = trainingScheduleConfig.allDay ? filter.room : resourceId;
    /* eslint-disable indent */
    if (!droppedOnAllDaySlot) {
      const hours = moment.duration(moment(end).diff(start)).asHours();
      session.end =
        hours === 0 || hours % 24 === 0
          ? moment(session.start)
              .add(1, 'hours')
              .toDate()
          : end;
    }

    const entity = {
      identifier: event.id,
      room: { identifier: trainingScheduleConfig.allDay ? filter.room : resourceId },
      sessionTime: droppedOnAllDaySlot
        ? convertMomentToUtc('DD-MM-YYYY HH:mm', moment(start).startOf('day'))
        : convertMomentToUtc('DD-MM-YYYY HH:mm', moment(start)),
      sessionEndTime: droppedOnAllDaySlot
        ? convertMomentToUtc(
            'DD-MM-YYYY HH:mm',
            moment(start)
              .startOf('day')
              .add(1, 'day')
          )
        : convertMomentToUtc('DD-MM-YYYY HH:mm', moment(session.end)),
      courseSessionId: trainingScheduleConfig.courseSessionId,
      allDay: !!droppedOnAllDaySlot
    };

    if (this.checkSessionOverlap(entity)) {
      if (moment(start).isAfter(moment()) && moment(end).isAfter(moment())) {
        this.setState(
          {
            scheduledSessions: scheduledSessionsModel
          },
          () => this.handleSaveScheduledSession(trainingScheduleConfig.scheduledCourseId, entity)
        );
      } else dispatchGlobalError('Scheduled Session', 'Scheduled sessions must be made in the future');
    }
    /* eslint-enable indent */
  }

  render() {
    const { classes, closeView, trainingScheduleConfig } = this.props;
    const {
      filter,
      courses,
      centres,
      rooms,
      resourceMap,
      scheduledSessions,
      eventCreated,
      selectedSession,
      editPopOver
    } = this.state;

    return (
      <div
        className={classNames([
          { [classes.overlayContainer]: trainingScheduleConfig.modal },
          { [classes.schedulingViewContainer]: !trainingScheduleConfig.modal }
        ])}
      >
        <Grid
          container
          className={classNames(classes.mainContentColumn, classes.flexNowrap, {
            [classes.editMode]: false
          })}
          direction="column"
        >
          <Grid item xs={12} className={classNames(classes.columnDirection)}>
            <Grid item xs={12} className={classNames(classes.noFlexBasis, classes.rowDirection, classes.filters)}>
              {trainingScheduleConfig.showCourseFilter && (
                <Grid item xs={4}>
                  <div className={classes.displayField}>
                    <ValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      id="course"
                      label="Course"
                      name="course"
                      value={filter ? filter.course : ALL}
                      handleChange={this.handleChange}
                      validators={[]}
                      values={courses}
                      optionName="label"
                      optionValue="value"
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={4}>
                <div className={classes.displayField}>
                  <ValidatedField
                    type={VALIDATED_FIELD_TYPES.SELECT}
                    id="centre"
                    label="Centre"
                    name="centre"
                    value={filter ? filter.centre : ALL}
                    handleChange={this.handleChange}
                    validators={[]}
                    values={centres}
                    optionName="label"
                    optionValue="value"
                  />
                </div>
              </Grid>
              {rooms && rooms.length > 1 && (
                <Grid item xs={3}>
                  <div className={classes.displayField}>
                    <ValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      id="room"
                      label="Room"
                      name="room"
                      value={filter ? filter.room : ALL}
                      handleChange={this.handleChange}
                      validators={[]}
                      values={rooms}
                      optionName="label"
                      optionValue="value"
                    />
                  </div>
                </Grid>
              )}
              {trainingScheduleConfig.modal && (
                <Grid item xs={1}>
                  <Button
                    data-qa="ok"
                    className={classes.submit}
                    variant="contained"
                    color="primary"
                    onClick={closeView}
                  >
                    Close
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '20px' }}>
              {trainingScheduleConfig.allDay && (
                <FormHelperText className={classes.helpText}>
                  Select a centre and a room to schedule a session
                </FormHelperText>
              )}
              {!trainingScheduleConfig.allDay && (
                <FormHelperText className={classes.helpText}>
                  Select a centre to schedule a session in a room
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.calendarContainer}>
            {filter.centre !== ALL && trainingScheduleConfig.modal && (
              <DragAndDropCalendar
                selectable={!eventCreated && !trainingScheduleConfig.scheduledSessionId}
                events={scheduledSessions}
                localizer={momentLocalizer(moment)}
                defaultDate={trainingScheduleConfig.scrollToTime}
                defaultView={this.getView()}
                views={this.getViews()}
                resources={resourceMap}
                resourceIdAccessor="id"
                resourceTitleAccessor="title"
                onSelectEvent={this.handleSelectEvent}
                onEventDrop={this.handleMoveEvent}
                onEventResize={this.handleResizeEvent}
                onSelectSlot={this.handleNewEvent}
                step={15}
                onRangeChange={this.handleRangeChange}
                // onView={this.handleRangeChange}
                scrollToTime={moment()
                  .startOf('day')
                  .add(8, 'hours')
                  .toDate()}
                className={classNames(classes.calendar, {
                  [classes.monthCalendar]: trainingScheduleConfig.periodType === PERIOD_TYPE.id
                })}
              />
            )}
            {(filter.centre === ALL || !trainingScheduleConfig.modal) && (
              <Calendar
                events={scheduledSessions}
                localizer={momentLocalizer(moment)}
                onSelectEvent={this.handleSelectEvent}
                defaultDate={moment().toDate()}
                defaultView={this.getView()}
                views={this.getViews()}
                resources={resourceMap}
                resourceIdAccessor="id"
                resourceTitleAccessor="title"
                scrollToTime={moment()
                  .startOf('day')
                  .add(8, 'hours')
                  .toDate()}
                step={15}
                onRangeChange={this.handleRangeChange}
                onView={this.handleRangeChange}
                className={classNames(classes.calendar, {
                  [classes.monthCalendar]: trainingScheduleConfig.periodType === PERIOD_TYPE.id
                })}
              />
            )}
            {!!selectedSession && (
              <Popover
                open={!!selectedSession}
                onClose={this.handlePopOverClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: selectedSession.top, left: selectedSession.left }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left'
                }}
              >
                <div className={classes.popOverDetails}>
                  <div className={classes.popOverActions}>
                    {!editPopOver && trainingScheduleConfig.modal && (
                      <Tooltip title="Edit" aria-label="add" placement="bottom">
                        <IconButton onClick={this.handlePopOverEdit}>
                          <i className="material-icons">edit</i>
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="Close" aria-label="add" placement="bottom">
                      <IconButton onClick={this.handlePopOverClose}>
                        <i className="material-icons">clear</i>
                      </IconButton>
                    </Tooltip>
                  </div>
                  {/* eslint-disable react/jsx-wrap-multilines */}
                  <div className={classes.sessionDetails}>
                    <SectionHeading heading="Session Details" underlined style={{ paddingTop: 0 }} />
                    <div className={classes.displayLabelAndField}>
                      <label className={classes.displayLabel}>Start Time</label>
                      <div className={classNames(classes.displayField, classes.flex)}>
                        {!editPopOver && moment(selectedSession.session.start).format('DD-MM-YYYY HH:mm')}
                        {editPopOver && (
                          <>
                            <DatePicker
                              selected={moment(selectedSession.session.start).toDate()}
                              dateFormat="dd-MM-yyyy"
                              onChange={date => {
                                this.handleSelectedSessionChange({ target: { name: 'session.start', value: date } });
                              }}
                              customInput={
                                <div className={classes.pointer}>
                                  {moment(selectedSession.session.start).format('DD-MM-YYYY')}
                                </div>
                              }
                            />
                            &nbsp;
                            <DatePicker
                              selected={moment(selectedSession.session.start).toDate()}
                              onChange={date => {
                                this.handleSelectedSessionChange({ target: { name: 'session.start', value: date } });
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              customInput={
                                <div className={classes.pointer}>
                                  {moment(selectedSession.session.start).format('HH:mm')}
                                </div>
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classes.displayLabelAndField}>
                      <label className={classes.displayLabel}>End Time</label>
                      <div className={classNames(classes.displayField, classes.flex)}>
                        {!editPopOver && moment(selectedSession.session.end).format('DD-MM-YYYY HH:mm')}
                        {editPopOver && (
                          <>
                            <DatePicker
                              selected={moment(selectedSession.session.end).toDate()}
                              dateFormat="dd-MM-yyyy"
                              onChange={date => {
                                this.handleSelectedSessionChange({ target: { name: 'session.end', value: date } });
                              }}
                              customInput={
                                <div className={classes.pointer}>
                                  {moment(selectedSession.session.end).format('DD-MM-YYYY')}
                                </div>
                              }
                            />
                            &nbsp;
                            <DatePicker
                              selected={moment(selectedSession.session.start).toDate()}
                              onChange={date => {
                                this.handleSelectedSessionChange({ target: { name: 'session.end', value: date } });
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              dateFormat="HH:mm"
                              customInput={
                                <div className={classes.pointer}>
                                  {moment(selectedSession.session.end).format('HH:mm')}
                                </div>
                              }
                            />
                          </>
                        )}
                      </div>
                    </div>
                    <div className={classes.displayLabelAndField}>
                      <label className={classes.displayLabel}>Course</label>
                      <div className={classes.displayField}>{selectedSession.session.title}</div>
                    </div>
                    <div className={classes.displayLabelAndField}>
                      <label className={classes.displayLabel}>Centre</label>
                      <div className={classes.displayField}>{selectedSession.session.centreName}</div>
                    </div>
                    <div className={classes.displayLabelAndField}>
                      <label className={classes.displayLabel}>Room</label>
                      <div className={classes.displayField}>{selectedSession.session.roomName}</div>
                    </div>
                  </div>
                  {/* eslint-enable react/jsx-wrap-multilines */}
                </div>
                <div className={classes.popOverButtons}>
                  <Link
                    to={`/scheduledCourse/view/${selectedSession.session.scheduledCourseId}`}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button className={classes.cancel} data-qa="cancel">
                      Scheduled Course
                    </Button>
                  </Link>
                  {editPopOver && (
                    <>
                      <Button onClick={this.handlePopOverClose} className={classNames(classes.cancel)} data-qa="cancel">
                        Cancel
                      </Button>

                      <Button
                        data-qa="ok"
                        className={classes.submit}
                        variant="contained"
                        color="primary"
                        onClick={this.handleUpdateScheduledSession}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </div>
              </Popover>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

TrainingScheduleComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  saveEntity: PropTypes.func,
  createInitialEntity: PropTypes.func,
  putEntity: PropTypes.func,
  dispatchGlobalError: PropTypes.func,
  isSaving: PropTypes.bool,
  form: PropTypes.object,
  closeView: PropTypes.func,
  trainingScheduleConfig: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  ...entityArraySelector(state, 'scheduledSessions'),
  ...entityArraySelector(state, 'rooms'),
  ...entityArraySelector(state, 'courses'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    createInitialEntity,
    putEntity,
    saveEntity,
    dispatchGlobalError
  })
)(TrainingScheduleComponent);

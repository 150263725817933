import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import { setInitialSearchFilter, changeSearchFilter } from '../../store/actions/SearchActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { getSearchCredentialsUrl } from '../../utils/searchUrls';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { listIssuerCredentialTypeReferenceRequest } from '../../utils/entityRequests/credentialTypeRequests';
import { listCredentialRequest } from '../../utils/entityRequests/credentialRequestRequests';
import { entityArraySelector } from '../../store/selectors/entity';

const styles = {
  ...style
};

class CredentialListView extends React.Component {
  async componentDidMount() {
    const { getEntity } = this.props;

    await getEntity(listIssuerCredentialTypeReferenceRequest());
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/credential/credentialDefinition/credentialLifeCycle/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(credential => ({
      clickAction: () => this.actionNavigate(credential.documentId),
      cells: [
        {
          value: credential.credentialTypeName
        },
        {
          value: credential.holderName
        },
        {
          value: credential.createDate
        },
        {
          value: credential.expiryDate
        },
        {
          value: credential.revocationDate
        }
      ]
    }));
  };

  getListing = props => {
    const { search, isLoading, credentialTypes } = this.props;
    const credentialTypeFilterValue =
      search && search.filters.credentialType ? search.filters.credentialType : NO_SEARCH_VALUE;

    return (
      <ScrollableTable
        headers={[
          {
            value: 'Credential Type',
            filter: {
              value: credentialTypeFilterValue,
              options: credentialTypes || [],
              onChange: event => props.handleFilterChange(event, 'credentialType')
            }
          },
          { value: 'Holder Name' },
          {
            value: 'Create Date'
          },
          {
            value: 'Expiry Date'
          },
          {
            value: 'Revocation Date'
          }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Credentials Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, table, credentialTypes, listCredentialRequest, listEntities } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <ListingWithSearch
              name="credentials"
              getSearchUrl={getSearchCredentialsUrl}
              onList={listEntities}
              filtersList={{
                credentialType: credentialTypes
              }}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listCredentialRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

CredentialListView.propTypes = {
  classes: PropTypes.object.isRequired,
  setInitialSearchFilter: PropTypes.func.isRequired,
  getEntity: PropTypes.func.isRequired,
  listCredentialRequest: PropTypes.func.isRequired,
  listEntities: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  credentialTypes: PropTypes.array,
  table: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-credentials'),
  ...entityArraySelector(state, 'credentialTypes'),
  ...tableSelector(state, 'credentials')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setInitialSearchFilter,
    changeSearchFilter,
    getEntity,
    listEntities,
    listCredentialRequest
  })
)(CredentialListView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Auth } from 'aws-amplify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getEntity } from '../../store/actions/EntityActions';
import { entitySelector } from '../../store/selectors/entity';
import { getProfileRequest } from '../../utils/entityRequests/userRequests';
import { commonFont, colors } from '../../assets/constants';
import { getUserInitials } from '../../utils/utilities';

const styles = () => ({
  avatar: {
    cursor: 'pointer',
    margin: '10px auto',
    fontSize: '13px',
    backgroundColor: 'rgba(136,135,135)',
    width: '35px',
    height: '35px'
  },
  dropDownMenu: {
    marginTop: '40px'
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '0'
  },
  avatarImage: {
    borderRadius: '75px'
  },
  personName: {
    ...commonFont,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.fontLightGrey,
    marginRight: '12px'
  },
  role: {
    ...commonFont,
    fontWeight: 300,
    fontSize: '12px',
    color: colors.fontLightGrey,
    marginRight: '12px'
  },
  nameAndRole: {
    flexDirection: 'column'
  }
});

class NewUserProfile extends Component {
  state = {
    anchorEl: null
  };

  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(getProfileRequest());
  }

  handleNavigateProfile = () => {
    const { history } = this.props;
    history.push('/profile/view');
  };

  handleNavigateChangePassword = () => {
    const { history } = this.props;
    history.push('/changePassword');
  };

  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logout = async () => {
    await Auth.signOut();
  };

  render() {
    const { anchorEl } = this.state;
    const { classes, profile } = this.props;

    return (
      <div className={classes.avatarContainer}>
        <div className={classes.nameAndRole}>
          <div className={classes.personName}>
            {profile.firstName ? `${profile.firstName} ${profile.lastName}` : ''}
          </div>
          <div className={classes.role}>{profile.roleName}</div>
        </div>
        <Avatar className={classes.avatar} onClick={this.handleMenuOpen}>
          {getUserInitials(profile.avatarUrl, `${profile.firstName} ${profile.lastName}`)}
        </Avatar>
        <Menu
          id="profileMenu"
          anchorEl={anchorEl}
          anchorReference="anchorEl"
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          className={classes.dropDownMenu}
        >
          <MenuItem onClick={this.handleNavigateProfile}>Profile</MenuItem>
          <MenuItem onClick={this.handleNavigateChangePassword}>Change Password</MenuItem>
          <MenuItem onClick={this.logout}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

NewUserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  profile: PropTypes.object,
  getEntity: PropTypes.func
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'profile')
});

export default compose(withRouter, withStyles(styles), connect(mapStateToProps, { getEntity }))(NewUserProfile);

import React, { Fragment } from 'react';
import { Grid, Table, TableRow, TableBody, TableHead, TableCell, Tooltip } from '@material-ui/core';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import { loadingSelector } from '../../store/selectors/shared';
import { documentDownloadsSelector } from '../../store/selectors/document';
import { getEntity, putEntity, listEntities, clearEntity } from '../../store/actions/EntityActions';
import {
  listCredentialFileDownloads,
  downloadFile,
  listCredentialRequestFileDownloads
} from '../../store/actions/DocumentActions';
import { toggleSetting } from '../../store/actions/SettingsActions';
import { createInitialTable } from '../../store/actions/SearchActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { CHECK_TYPE, colors, commonFont, CONTROL_TYPE, STANDARD_CREDENTIAL_TYPE } from '../../assets/constants';
import { entityArraySelector, entitySelector } from '../../store/selectors/entity';
import CredentialInbox from './CredentialInbox';
import {
  getUserInitials,
  getCredentialStatus,
  getCredentialStatusColoredIcon,
  convertUtcToLocal
} from '../../utils/utilities';
import PendingCredentialTickOrangeIcon from '../../assets/images/pending-credential-tick-orange.svg';
import { ReactComponent as VerifiedCredentialTickGreenIcon } from '../../assets/images/verified-tick-icon-green.svg';
import { ReactComponent as RejectedExclamationRedIcon } from '../../assets/images/rejected-exclamation-icon-red.svg';
import { ReactComponent as RevokeOrangeIcon } from '../../assets/images/revoke-credential-icon-orange.svg';
import MessagingComponent from '../common/MessagingComponent';
import TopLevelButton from '../widgets/TopLevelButton';
import TopLevelButtons from '../widgets/TopLevelButtons';
import CredentialTimeline from './CredentialTimeline';
import CredentialCard from './CredentialCard';
import CredentialProfile from './CredentialProfile';
import CredentialExpanderSection from './CredentialExpanderSection';
import {
  assignCredentialRequest,
  getCredentialMessagesRequest,
  getCredentialRequest,
  listCredentialVersionRequest,
  listOtherCredentialRequest,
  rejectCredentialRequest,
  revokeCredentialRequest,
  sendCredentialMessageRequest,
  verifyCredentialRequest,
  getCredentialLogBookRequest
} from '../../utils/entityRequests/credentialRequests';
import { getCredentialTypeRequest } from '../../utils/entityRequests/credentialTypeRequests';
import { tableSelector } from '../../store/selectors/table';
import ReppleImageGallery from '../widgets/ReppleImageGallery';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import NewValidatedField from '../widgets/NewValidatedField';
import { listAllUserRequest } from '../../utils/entityRequests/userAdminRequests';

const styles = {
  ...layout,
  ...style,
  pageLayout: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%'
  },
  inboxContainer: {
    height: '100%'
  },
  paddingLeft: {
    paddingLeft: '38px'
  },
  headerActions: {
    height: '73px',
    borderBottom: `solid 1px ${colors.borderBlue}`
  },
  avatar: {
    fontSize: '13px',
    backgroundColor: 'rgba(136,135,135)',
    borderRadius: '17px',
    width: '34px',
    height: '34px'
  },
  assigneeAvatar: {
    fontSize: '13px',
    backgroundColor: 'rgba(136,135,135)',
    borderRadius: '23px',
    width: '46px',
    height: '46px'
  },
  assigneeContainer: {
    marginTop: '11px',
    marginLeft: '24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  selected: {
    borderBottom: `5px solid ${colors.orangeNew}`,
    '& div > div': {
      fontWeight: 600,
      color: colors.blueDark
    },
    '& $crossTxtContainer': {
      backgroundColor: colors.white
    }
  },
  crossTxtContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '65px',
    width: '65px',
    borderRadius: '5px',
    alignItems: 'center'
  },
  search: {
    backgroundColor: colors.white,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  textField: {
    width: '100%',
    marginBottom: 0
  },
  attachmentCardContainer: {
    backgroundColor: colors.backgroundBlue,
    borderRadius: '5px',
    padding: '20px 25px 26px 4px',
    margin: '22px 24px 21px 26px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  attachmentLabel: {
    ...commonFont,
    fontWeight: 'normal',
    fontSize: '12px',
    color: colors.fontLightGrey,
    textTransform: 'uppercase'
  },
  attachment: {
    marginTop: '7px',
    height: '100px',
    // width: '100px',
    marginRight: '10px',
    // border: '1px solid #ababab',
    borderRadius: '10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    // backgroundColor: colors.white
  },
  attachments: {
    marginLeft: '28px'
  },
  rightColumn: {
    width: '78px',
    borderLeft: `solid 1px ${colors.borderBlue}`,
    height: '100%',
    transitionProperty: 'opacity, right, top, width',
    transitionDuration: '2s, 1s'
  },
  rightColumnOpen: {
    width: '338px',
    maxWidth: '338px',
    transitionProperty: 'opacity, left, top, width',
    transitionDuration: '2s, 1s'
  },
  middleColumn: {
    width: '100%',
    backgroundColor: colors.white
  },
  container: {
    display: 'flex',
    overflowY: 'auto'
  },
  backIcon: {
    color: colors.blue,
    fontSize: '36px',
    cursor: 'pointer',
    marginLeft: '-6px'
  },
  credentialProfileOpenClose: {
    minWidth: '46px',
    height: '45px',
    backgroundColor: colors.backgroundBlue,
    borderRadius: '0px 50px 50px 0px',
    marginTop: '19px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  credentialProfile: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '30px',
    marginRight: '40px'
  },
  credentialProfileContainer: {
    borderBottom: `solid 1px ${colors.borderBlue}`,
    paddingBottom: '30px',
    minWidth: '338px'
  },
  credentialText: {
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: '5px',
    marginRight: '5px',
    ...commonFont,
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '4px'
  },
  credentialMarginTop: {
    marginTop: '8px',
    cursor: 'pointer'
  },
  noCredentialContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: colors.blueDark,
    ...commonFont,
    fontSize: '28px',
    fontWeight: 600,
    flexDirection: 'column'
  },
  noCredentialSubText: {
    color: colors.blue,
    ...commonFont,
    fontSize: '18px',
    fontWeight: 'normal',
    marginTop: '13px',
    marginBottom: '30px'
  },
  inboxIcon: {
    color: colors.borderBlue,
    fontSize: '109px'
  },
  chooseCredential: {
    marginTop: '30px',
    width: '537px',
    textAlign: 'center'
  },
  attachmentsDiv: {
    flexWrap: 'wrap'
  },
  attachmentInfoContainer: {
    overflowY: 'scroll',
    height: '184px'
  },
  extraFields: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  heading: {
    ...commonFont,
    fontSize: '12px',
    color: colors.fontLightGrey,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginTop: '5px',
    marginBottom: '2px'
  },
  data: {
    ...commonFont,
    fontSize: '12px',
    color: colors.black,
    fontWeight: 600
  },
  extraInfo: {
    marginRight: '50px',
    marginTop: '20px'
  },
  assignee: {
    width: '300px',
    marginTop: 0,
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none'
    },
    '& input': {
      ...commonFont,
      fontWeight: '600 !important',
      fontSize: '16px !important',
      color: `${colors.blueDark} !important`
    }
  },
  imageOption: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    '& > div:last-of-type': {
      marginLeft: '10px'
    }
  },
  assigneeAvatarDropDown: {
    height: '45px',
    width: '45px',
    zIndex: 10
  },
  assigneeLabel: {
    ...commonFont,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.blueDark
  },
  white: {
    ...commonFont,
    fontWeight: 500,
    fontSize: '14px',
    color: colors.white
  },
  status: {
    marginLeft: '5px',
    marginBottom: '12px'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  },
  otherCredentialStatus: {
    width: '70px',
    height: '20px',
    borderRadius: '3px',
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '9px',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '6px'
  },
  versionInfo: {
    ...commonFont,
    fontSize: '14px',
    fontWeight: 'normal',
    color: colors.blue,
    width: '100%'
  },
  versionHeader: {
    ...commonFont,
    color: colors.blueDark,
    fontSize: '14px',
    fontWeight: '500'
  },
  versionTable: {
    width: '300px',
    marginLeft: '20px',
    marginRight: '20px',
    borderCollapse: 0,
    border: `solid 1px ${colors.borderBlue}`,
    '& td, & th': {
      border: `solid 1px ${colors.borderBlue}`,
      padding: '5px'
    },
    tableLayout: 'fixed',
    '& th:first-of-type': {
      width: '90px'
    },
    '& tbody tr:hover': {
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  logBookContainer: {
    padding: '0 20px 0 20px',
    '& div:last-of-type': {
      borderBottom: 'none'
    }
  },
  logbookEntry: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '9px',
    padding: '10px 0 16px 0',
    borderBottom: `solid 1px ${colors.borderBlue}`
  },
  reasonLocation: {
    display: 'flex',
    flexDirection: 'column',
    '& div:first-of-type': {
      color: colors.blueDark,
      ...commonFont,
      fontWeight: 600,
      fontSize: '12px'
    },
    '& div:last-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 500,
      fontSize: '12px',
      marginTop: '3px'
    }
  },
  logbookDateTime: {
    display: 'flex',
    flexDirection: 'column',
    '& div:first-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 700,
      fontSize: '10px'
    },
    '& div:last-of-type': {
      color: colors.blue,
      ...commonFont,
      fontWeight: 400,
      fontSize: '10px',
      marginTop: '3px'
    }
  }
};

class InboxCredentialView extends React.Component {
  state = {
    rejecting: false,
    verifying: false,
    revoking: false,
    rightSideOpen: true,
    showGallery: false,
    thumbnailId: 0
  };

  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(listAllUserRequest());
  }

  versionNavigate = (id, transactionId) => {
    const { history } = this.props;
    history.push(`/credential/inbox/${id}/view/${transactionId}`);
  };

  toggleRightSide = () => {
    const { rightSideOpen } = this.state;
    this.setState({ rightSideOpen: !rightSideOpen });
  };

  getCredential = async documentId => {
    const { getEntity } = this.props;
    const credential = await getEntity(getCredentialRequest(documentId));
    await getEntity(getCredentialLogBookRequest(documentId));
    await getEntity(getCredentialTypeRequest(credential.credentialBean.credentialTypeUuid));
    await getEntity(getCredentialMessagesRequest(credential.credentialBean.documentId));
    await getEntity(
      listOtherCredentialRequest(credential.credentialBean.holderUuid, credential.credentialBean.documentId)
    );
    await getEntity(listCredentialVersionRequest(credential.credentialBean.documentId));
  };

  handleVerifyCredential = async () => {
    const { putEntity, credential } = this.props;
    const id = credential.credentialBean.documentId;
    this.setState({ verifying: true }, async () => {
      await putEntity(verifyCredentialRequest({ ids: [id] })).then(() => {
        this.getCredential(id);
        this.setState({ verifying: false });
      });
    });
  };

  handleRejectCredential = async () => {
    const { putEntity, credential } = this.props;
    const id = credential.credentialBean.documentId;
    this.setState({ rejecting: true }, async () => {
      await putEntity(rejectCredentialRequest({ ids: [id] })).then(() => {
        this.getCredential(id);
        this.setState({ rejecting: false });
      });
    });
  };

  handleRevokeCredential = async () => {
    const { putEntity, credential } = this.props;
    const id = credential.credentialBean.documentId;
    this.setState({ revoking: true }, async () => {
      await putEntity(revokeCredentialRequest({ ids: [id] })).then(() => {
        this.getCredential(id);
        this.setState({ revoking: false });
      });
    });
  };

  getEndDate = credentialBean => {
    const { credentialType } = this.props;
    if (credentialBean.credentialStatus === 'VERIFIED') return credentialBean.verifiedDate;
    if (credentialBean.credentialStatus === 'REJECTED') return credentialBean.rejectedDate;
    if (credentialBean.credentialStatus === 'REVOKED') return credentialBean.revocationDate;

    return moment(credentialBean.createDate, 'DD-MM-yyyy')
      .add(credentialType.verificationTime, 'day')
      .format('DD-MM-yyyy');
  };

  handleSendMessage = async message => {
    const { putEntity, getEntity, credential } = this.props;

    if (message) {
      return putEntity(sendCredentialMessageRequest(credential.credentialBean.documentId, message)).then(async () => {
        return getEntity(getCredentialMessagesRequest(credential.credentialBean.documentId));
      });
    }
  };

  openGallery = id => {
    this.setState({
      showGallery: true,
      thumbnailId: id
    });
  };

  closeGallery = () => {
    this.setState({
      showGallery: false,
      thumbnailId: null
    });
  };

  renderAttachment = (name, attachment) => {
    const { classes } = this.props;

    return (
      <Tooltip title={name} aria-label="edit" placement="bottom" key={`attachment-${attachment.identifier}`}>
        <div className={classes.attachment}>
          {attachment.mimeType.startsWith('image') && (
            <div
              onClick={() => {
                this.openGallery(attachment.identifier);
              }}
            >
              <img src={attachment.thumbnailUrl} />
            </div>
          )}
          {attachment.mimeType === 'application/pdf' && (
            <a href={attachment.url} target="_blank" rel="noreferrer">
              <img src="/pdficon.jpeg" alt="PDF Attachment" className={classes.logo} width="100px" />
            </a>
          )}
        </div>
      </Tooltip>
    );
  };

  getGalleryImages = () => {
    const { credential } = this.props;
    const galleryImages = credential.credentialBean.attachments
      .filter(item => item.mimeType.startsWith('image'))
      .map(item => ({ original: item.url, name: item.name, thumbnailId: item.identifier }));

    if (
      credential.credentialBean.certificateCopy &&
      credential.credentialBean.certificateCopy.mimeType.startsWith('image')
    ) {
      galleryImages.push({
        original: credential.credentialBean.certificateCopy.url,
        name: credential.credentialBean.certificateCopy.name,
        thumbnailId: credential.credentialBean.certificateCopy.identifier
      });
    }
    return galleryImages;
  };

  getAssignee = () => {
    const { credential } = this.props;
    if (credential && credential.assignee) return credential.assignee.identifier;
    return '';
  };

  handleAssignmentChange = async event => {
    const { putEntity, credential } = this.props;
    const { target } = event;
    const { value } = target;
    await putEntity(assignCredentialRequest(value, [credential.credentialBean.documentId])).then(() => {
      this.getCredential(credential.credentialBean.documentId);
    });
  };

  handleClearFilter = () => {
    const { toggleSetting } = this.props;
    toggleSetting('clearFilter', true);
  };

  getControlValue = control => {
    if (control.type === CONTROL_TYPE.TEXT.id || control.type === CONTROL_TYPE.DATE.id) {
      return control.values[0].value;
    }
    if (control.type === CONTROL_TYPE.MULTIPLE_CHOICE.id) {
      return control.values[0].name;
    }
    if (control.type === CONTROL_TYPE.CHECKBOXES.id) {
      return control.values.map(item => item.name).join(', ');
    }
  };

  getExtraFields = () => {
    const { credential } = this.props;
    const fields = [];

    if (credential.credentialBean.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id) {
      this.addExtraFieldIfPresent('Start Year', credential.credentialBean.startYear, fields);
      this.addExtraFieldIfPresent('End Year', credential.credentialBean.endYear, fields);
      this.addExtraFieldIfPresent('Field of Study', credential.credentialBean.fieldOfStudy, fields);
      this.addExtraFieldIfPresent('Credential URL', credential.credentialBean.credentialUrl, fields);
    } else if (
      credential.credentialBean.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id
    ) {
      this.addExtraFieldIfPresent('Credential URL', credential.credentialBean.credentialUrl, fields);
    } else if (credential.credentialBean.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id) {
      this.addExtraFieldIfPresent('Given Names', credential.credentialBean.givenName, fields);
      this.addExtraFieldIfPresent('Surname', credential.credentialBean.surname, fields);
      this.addExtraFieldIfPresent('Date of Birth', credential.credentialBean.dateOfBirth, fields);
      this.addExtraFieldIfPresent('Issuing Country Code', credential.credentialBean.issuingCountryCode, fields);
      this.addExtraFieldIfPresent('Place of Birth', credential.credentialBean.placeOfBirth, fields);
      this.addExtraFieldIfPresent('Issue Date', credential.credentialBean.issueDate, fields);
      this.addExtraFieldIfPresent('Expiration Date', credential.credentialBean.expiryDate, fields);
      this.addExtraFieldIfPresent('Passport No', credential.credentialBean.credentialId, fields);
    } else if (credential.credentialBean.standardCredentialType === STANDARD_CREDENTIAL_TYPE.COVID19.id) {
      this.addExtraFieldIfPresent('Issue Date', credential.credentialBean.issueDate, fields);
      this.addExtraFieldIfPresent('Country', credential.credentialBean.country, fields);
      this.addExtraFieldIfPresent('Laboratory', credential.credentialBean.laboratory, fields);
      fields.push({ name: 'Positive', value: credential.credentialBean.positive ? 'Yes' : 'No' });
      this.addExtraFieldIfPresent('Comment', credential.credentialBean.comment, fields);
    }

    return fields;
  };

  addExtraFieldIfPresent = (name, value, fields) => {
    if (value) fields.push({ name, value });
  };

  getWorkplaceLogbookName = logbookEntry => {
    if (logbookEntry.credentialCheckType === CHECK_TYPE.CHECK_IN.value) return 'Workplace checkin';
    else if (logbookEntry.reasonName) return logbookEntry.reasonName;
    else return 'Scanned Credential';
  };

  render() {
    const {
      classes,
      table,
      credential,
      credentialType,
      messages,
      otherCredentials,
      users,
      credentialVersions,
      credentialLogBook
    } = this.props;
    const { rightSideOpen, rejecting, verifying, revoking, showGallery, thumbnailId } = this.state;
    const items = messages ? messages.messages : [];
    const userList = (users || []).map(item => ({
      label: `${item.firstName} ${item.lastName}`,
      id: item.identifier,
      avatarUrl: item.avatarUrl
    }));

    return (
      <Grid container className={classNames(classes.pageLayout)}>
        <Grid item xs={3} className={classes.inboxContainer}>
          <CredentialInbox userList={userList} />
        </Grid>
        {!credential.credentialBean && table && table.payload && table.payload.records.length === 0 && (
          <Grid item xs={9} className={classes.noCredentialContainer}>
            <i className={classNames('material-icons-outlined', 'material-icons', classes.inboxIcon)}>search</i>
            <div className={classes.chooseCredential}>No conversations match your current search or filters.</div>
            <div className={classes.noCredentialSubText}>Check your search spelling or filters and try again</div>
            <TopLevelButton
              isActionOccurring={false}
              isDisabled={false}
              buttonColor={colors.blueDark}
              buttonText="Clear Filters"
              // buttonIcon={}
              onClick={this.handleClearFilter}
            />
          </Grid>
        )}
        {!credential.credentialBean && table && table.payload && table.payload.records.length !== 0 && (
          <Grid item xs={9} className={classes.noCredentialContainer}>
            <i className={classNames('material-icons-outlined', 'material-icons', classes.inboxIcon)}>inbox</i>
            <div className={classes.chooseCredential}>Please choose a credential</div>
          </Grid>
        )}
        {credential.credentialBean && (
          <Grid item xs={9} className={classes.container}>
            <div className={classes.middleColumn}>
              <div className={classes.headerActions}>
                <div className={classes.assigneeContainer}>
                  <div className={classes.rowDirection}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      key="assignee"
                      label={this.getAssignee() ? '' : 'Assignee'}
                      value={userList.find(item => item.id === this.getAssignee()) || null}
                      className={classes.assignee}
                      onChange={this.handleAssignmentChange}
                      items={userList}
                      id="assignee"
                      itemName="label"
                      itemValue="id"
                      renderOption={option => (
                        <Fragment>
                          <div className={classes.imageOption}>
                            <Avatar className={classes.assigneeAvatarDropDown}>
                              {getUserInitials(option.avatarUrl, option.label, '45px')}
                            </Avatar>
                            <div className={classes.assigneeLabel}>{option.label}</div>
                          </div>
                        </Fragment>
                      )}
                      validators={[]}
                      icon={{
                        startAdornment: userList.find(item => item.id === this.getAssignee()) && (
                          <InputAdornment position="start">
                            <Avatar className={classes.assigneeAvatarDropDown}>
                              {getUserInitials(
                                userList.find(item => item.id === this.getAssignee()).avatarUrl,
                                userList.find(item => item.id === this.getAssignee()).label,
                                '45px'
                              )}
                            </Avatar>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                  <div className={classNames(classes.rowDirection, classes.flexRowCentered)}>
                    <CredentialTimeline
                      icon={PendingCredentialTickOrangeIcon}
                      requestDate={credential.credentialBean.createDate}
                      responseDate={this.getEndDate(credential.credentialBean)}
                      status={getCredentialStatus(credential.credentialBean.credentialStatus)}
                    />
                    {getCredentialStatus(credential.credentialBean.credentialStatus) === 'PENDING' && (
                      <TopLevelButtons
                        buttons={[
                          {
                            isActionOccurring: verifying,
                            isDisabled: false,
                            buttonColor: colors.lightGreen,
                            buttonText: 'VERIFY',
                            buttonIcon: <VerifiedCredentialTickGreenIcon />,
                            onClick: this.handleVerifyCredential
                          },
                          {
                            isActionOccurring: rejecting,
                            isDisabled: rejecting,
                            buttonColor: colors.expiredRed,
                            buttonText: 'REJECT',
                            buttonIcon: <RejectedExclamationRedIcon />,
                            onClick: this.handleRejectCredential
                          }
                        ]}
                      />
                    )}
                    {getCredentialStatus(credential.credentialBean.credentialStatus) === 'VERIFIED' && (
                      <TopLevelButtons
                        buttons={[
                          {
                            isActionOccurring: revoking,
                            isDisabled: false,
                            buttonColor: '#F78B0E',
                            buttonText: 'REVOKE',
                            buttonIcon: <RevokeOrangeIcon />,
                            onClick: this.handleRevokeCredential
                          }
                        ]}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={classes.attachmentCardContainer}>
                <div className={classNames(classes.columnDirection, classes.attachments)}>
                  {credential.credentialBean && (
                    <Fragment>
                      {(credential.credentialBean.certificateCopy ||
                        credential.credentialBean.attachments.length !== 0) && (
                        <div className={classes.attachmentLabel}>Attachments</div>
                      )}
                      <div className={classNames(classes.columnDirection, classes.attachmentInfoContainer)}>
                        <div className={classNames(classes.rowDirection, classes.attachmentsDiv)}>
                          {credential.credentialBean.attachments.map(item => this.renderAttachment(item.name, item))}
                        </div>
                        <div className={classes.extraFields}>
                          {this.getExtraFields().map(item => (
                            <div className={classes.extraInfo} key={`extra-${item.name}`}>
                              <div className={classes.heading}>{item.name}</div>
                              <div className={classes.data}>{item.value}</div>
                            </div>
                          ))}
                          {credential.credentialBean.sections.length > 0 &&
                            credential.credentialBean.sections.map(item =>
                              item.controls.map(control => (
                                <div className={classes.extraInfo} key={`control-${control.name}`}>
                                  <div className={classes.heading}>{control.name}</div>
                                  <div className={classes.data}>{this.getControlValue(control)}</div>
                                </div>
                              ))
                            )}
                        </div>
                      </div>
                    </Fragment>
                  )}
                </div>
                <CredentialCard
                  icon={getCredentialStatusColoredIcon(credential.credentialBean.credentialStatus)}
                  status={getCredentialStatus(credential.credentialBean.credentialStatus)}
                  credentialName={credentialType.name}
                  issueDate={credential.credentialBean.issueDate}
                  expiryDate={credential.credentialBean.expiryDate}
                  startYear={credential.credentialBean.startYear}
                  endYear={credential.credentialBean.endYear}
                  holderName={credential.credentialBean.holderName}
                  credentialId={credential.credentialBean.credentialId}
                  backgroundImage={credentialType.imageUrl}
                  logoImage={credentialType.logoUrl}
                  backgroundColor={credentialType.backgroundColor}
                  type={credentialType.type}
                  standardCredentialType={credential.credentialBean.standardCredentialType}
                  fieldOfStudy={credential.credentialBean.fieldOfStudy}
                />
              </div>
              <MessagingComponent isSending={false} messages={items} sendMessage={this.handleSendMessage} />
            </div>
            <div className={classNames(classes.rightColumn, { [classes.rightColumnOpen]: rightSideOpen })}>
              <div
                className={classNames(classes.rowDirection, { [classes.credentialProfileContainer]: rightSideOpen })}
              >
                <div className={classes.credentialProfileOpenClose}>
                  <i className={`material-icons ${classes.backIcon}`} onClick={this.toggleRightSide}>
                    {rightSideOpen ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}
                  </i>
                </div>
                {rightSideOpen && (
                  <Fragment>
                    <div className={classes.credentialProfile}>
                      <CredentialProfile
                        holder={{
                          name: credential.credentialBean.holderName,
                          email: credential.credentialBean.holderEmail,
                          phone: credential.credentialBean.holderPhone
                        }}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
              {rightSideOpen && (
                <Fragment>
                  <div className={classes.credentialProfile}>
                    {otherCredentials && otherCredentials.length > 0 && (
                      <CredentialExpanderSection
                        header="Other Credentials"
                        subHeading="Other credentials associated with holder"
                        open
                        name="otherCredentials"
                      >
                        <div className={classes.columnDirection}>
                          {otherCredentials.map((item, index) => (
                            <div
                              className={classNames(classes.rowDirection, classes.credentialMarginTop)}
                              key={`credential-${index}`}
                              onClick={() => {
                                this.getCredential(item.documentId);
                              }}
                            >
                              <div
                                className={classes.credentialBackground}
                                style={{ backgroundColor: item.credentialTypeBackgroundColor }}
                              >
                                &nbsp;
                              </div>
                              <div className={classes.credentialText} title={item.credentialTypeName}>
                                {item.credentialTypeName}
                              </div>
                              <div
                                className={classNames(
                                  classes.otherCredentialStatus,
                                  {
                                    [classes.pending]:
                                      item.credentialStatus === 'PENDING' || item.credentialStatus === 'UNVERIFIED'
                                  },
                                  { [classes.rejected]: item.credentialStatus === 'REJECTED' },
                                  { [classes.verified]: item.credentialStatus === 'VERIFIED' },
                                  { [classes.expired]: item.credentialStatus === 'EXPIRED' }
                                )}
                              >
                                <img
                                  src={getCredentialStatusColoredIcon(item.credentialStatus)}
                                  alt="Credential Tick"
                                  className={classes.credentialTick}
                                />
                                {getCredentialStatus(item.credentialStatus)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </CredentialExpanderSection>
                    )}
                  </div>
                  <div className={classes.credentialProfile}>
                    <CredentialExpanderSection header="Log book" subHeading="" open name="logBook">
                      <div className={classes.logBookContainer}>
                        {(credentialLogBook || []).map(item => (
                          <div className={classes.logbookEntry}>
                            <div className={classes.reasonLocation}>
                              <div>{this.getWorkplaceLogbookName(item)}</div>
                              <div>{item.workPlaceName}</div>
                            </div>
                            <div className={classes.logbookDateTime}>
                              <div>{convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'D MMM', item.createTime)}</div>
                              <div>{convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'h:mm A', item.createTime)}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </CredentialExpanderSection>
                  </div>
                  <div className={classes.credentialProfile}>
                    <CredentialExpanderSection header="Versions" subHeading="" open name="versions">
                      <Table className={classes.versionTable}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={classes.versionHeader}>Version</TableCell>
                            <TableCell className={classes.versionHeader}>Date</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {(credentialVersions || []).map(item => (
                            <TableRow
                              key={`version-${item.version}`}
                              onClick={() => {
                                this.versionNavigate(credential.credentialBean.documentId, item.transactionId);
                              }}
                            >
                              <TableCell className={classes.versionInfo}>{item.version}</TableCell>
                              <TableCell className={classes.versionInfo}>{item.transactionTime}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </CredentialExpanderSection>
                  </div>
                </Fragment>
              )}
            </div>
          </Grid>
        )}
        {showGallery && (
          <ReppleImageGallery
            gallery={this.getGalleryImages()}
            thumbnailId={thumbnailId}
            closeGallery={this.closeGallery}
          />
        )}
      </Grid>
    );
  }
}

InboxCredentialView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  listEntities: PropTypes.func,
  toggleSetting: PropTypes.func,
  downloadFile: PropTypes.func,
  listCredentialFileDownloads: PropTypes.func,
  listCredentialRequestFileDownloads: PropTypes.func,
  credential: PropTypes.object,
  credentialType: PropTypes.object,
  otherCredentials: PropTypes.array,
  credentialVersions: PropTypes.array,
  messages: PropTypes.array,
  users: PropTypes.array,
  credentialRequest: PropTypes.object,
  table: PropTypes.object,
  fileDownloads: PropTypes.array,
  credentialRequestFileDownloads: PropTypes.array
};

const mapStateToProps = state => ({
  ...tableSelector(state, 'credentials'),
  ...documentDownloadsSelector(state),
  ...entitySelector(state, 'credential'),
  ...entitySelector(state, 'credentialType'),
  ...entityArraySelector(state, 'credentialLogBook'),
  ...entityArraySelector(state, 'messages'),
  ...entityArraySelector(state, 'otherCredentials'),
  ...entityArraySelector(state, 'credentialVersions'),
  ...entityArraySelector(state, 'users'),
  isSaving: loadingSelector(state, 'putEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    listCredentialFileDownloads,
    listCredentialRequestFileDownloads,
    downloadFile,
    getEntity,
    putEntity,
    listEntities,
    createInitialTable,
    clearEntity,
    toggleSetting
  }),
  withRouter
)(InboxCredentialView);

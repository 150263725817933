import { credentialRequestBreakdownInitialState, graphDataInitialState } from '../entityInitialStates';

export const getCompetenceCredentialRequestCountRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_REQUEST_COUNT,
  entityName: 'credentialRequestBreakdown',
  initialState: credentialRequestBreakdownInitialState
});

export const getCompetenceCredentialRequestStatusRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_REQUEST_STATUS,
  entityName: 'credentialRequestStatusBreakdownGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialRequestTeamRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_REQUEST_TEAM,
  entityName: 'credentialRequestTeamBreakdownGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialRequestFormRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_REQUEST_FORM,
  entityName: 'credentialRequestFormBreakdownGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialRequestPriorityRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_REQUEST_PRIORITY,
  entityName: 'credentialRequestPriorityBreakdownGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialCheckOrganisationRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_CHECK_ORGANISATION,
  entityName: 'credentialCheckOrganisationGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialCheckCountRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_CHECK,
  entityName: 'credentialCheckNumberGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialCheckIssuerRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_CHECK_ISSUER_ORGANISATION,
  entityName: 'credentialCheckIssuerGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialCheckValidatingHolderRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_CHECK_VALIDATING_HOLDER,
  entityName: 'credentialCheckValidatingHolderGraph',
  initialState: graphDataInitialState
});

export const getCompetenceCredentialCheckWorkPlaceRequest = () => ({
  url: process.env.REACT_APP_DASHBOARD_CREDENTIAL_CHECK_WORK_PLACE,
  entityName: 'credentialCheckWorkPlaceGraph',
  initialState: graphDataInitialState
});

import _ from 'lodash';

import { START_LOADING, STOP_LOADING } from '../actions/types';

export default function(state = {}, action) {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        [action.payload]: true
      };
    case STOP_LOADING:
      return _.omit(state, action.payload);
    default:
      return state;
  }
}

import {
  SEARCH,
  CLEAR_SEARCH,
  CHANGE_SEARCH_FILTER,
  CREATE_INITIAL_SEARCH,
  SET_INITIAL_SEARCH_FILTER
} from '../actions/types';
import { pageSize } from '../../constants/global';

export const initialState = {
  searchText: '',
  pageSize,
  records: [],
  totalPages: 0,
  totalRecords: 0,
  filters: {}
};

export default function(state = {}, action) {
  switch (action.type) {
    case CREATE_INITIAL_SEARCH:
      return {
        ...state,
        [action.payload.name]: initialState
      };
    case SEARCH: {
      const { name, totalRecords, records, ...restProps } = action.payload;
      const currentStateRecords = state[name].records;
      const newRecords = currentStateRecords.length < totalRecords ? [...currentStateRecords, ...records] : records;
      return {
        ...state,
        [name]: {
          ...state[name],
          records: [...newRecords],
          totalRecords,
          ...restProps
        }
      };
    }

    case CHANGE_SEARCH_FILTER: {
      const { name, filterName, filter } = action.payload;
      return {
        ...state,
        [name]: {
          ...state[name],
          filters: {
            ...state[name].filters,
            [filterName]: filter
          }
        }
      };
    }

    case SET_INITIAL_SEARCH_FILTER: {
      const { name, filters } = action.payload;
      return {
        ...state,
        [name]: {
          ...initialState,
          filters
        }
      };
    }

    case CLEAR_SEARCH: {
      const { name } = action.payload;

      return {
        ...state,
        [name]: {
          ...initialState,
          // Keep filter
          filters: state[name].filters
        }
      };
    }

    default:
      return state;
  }
}

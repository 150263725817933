import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { entitySelector } from '../../store/selectors/entity';
import { getOrganisationRequest } from '../../utils/entityRequests/organisationRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import NewSectionHeading from '../common/NewSectionHeading';

const styles = {
  ...layout,
  ...style,
  avatar: {
    borderRadius: '75px'
  }
};

class OrganisationView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getOrganisationRequest(id));
  }

  render() {
    const { classes, match, organisation } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(organisation).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={`/admin/organisation/edit/${id}`}
            header="Organisation Details"
            cancelUrl="/admin/organisation"
          />
          <Grid item xs={6}>
            <DisplayField heading="Name" value={organisation.name} />
            <DisplayField heading="Key" value={organisation.key} />
            <DisplayField heading="Currency" value={organisation.currency && organisation.currency.name} />
            <DisplayField
              heading="Acceptable Credential Check Distance (Metres)"
              value={organisation.acceptableCredentialCheckDistance}
            />
            {organisation.parentReportingId && (
              <DisplayField heading="Reporting Parent Organisation" value={organisation.parentReportingName} />
            )}
            {organisation.childReportingOrganisations && organisation.childReportingOrganisations.length > 0 && (
              <DisplayField
                heading="Reporting Child Organisations"
                values={organisation.childReportingOrganisations.map(item => item.name)}
              />
            )}
            {organisation.imageUrl && (
              <DisplayField
                heading="Logo"
                value={<img src={organisation.imageUrl} alt="Uploaded" className={classes.avatar} />}
              />
            )}

            <NewSectionHeading heading="Capabilities" />
            <DisplayField
              heading="Assurance"
              value={
                organisation.assurance ? (
                  <i className="material-icons">done</i>
                ) : (
                  <i className="material-icons">clear</i>
                )
              }
            />
            <DisplayField
              heading="Competence"
              value={
                organisation.competence ? (
                  <i className="material-icons">done</i>
                ) : (
                  <i className="material-icons">clear</i>
                )
              }
            />
            <DisplayField
              heading="Training"
              value={
                organisation.training ? <i className="material-icons">done</i> : <i className="material-icons">clear</i>
              }
            />
            <DisplayField
              heading="Member"
              value={
                organisation.member ? <i className="material-icons">done</i> : <i className="material-icons">clear</i>
              }
            />
          </Grid>
        </Grid>
      )
    );
  }
}

OrganisationView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  organisation: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'organisation')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(OrganisationView);

import { startLoading, stopLoading } from './LoadingActions';
import ajax from '../../components/http/AjaxUtil';
import {
  CLEAR_ENTITY,
  CLEAR_VALIDATION_FAILURE,
  CREATE_INITIAL_ENTITY,
  GET_ENTITY,
  GLOBAL_ERROR,
  LIST_TABLE,
  MIDDLEWARE_VALIDATE,
  NOTIFICATION,
  SAVE_ENTITY_SUCCESS,
  SAVE_ENTITY_VALIDATION_FAILURE
} from './types';
import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';

export const getEntity = request => async dispatch => {
  dispatch(startLoading('getEntity'));
  try {
    return await ajax.get(request.url).then(response => {
      dispatch({
        type: GET_ENTITY,
        entityName: request.entityName,
        payload: response.data
      });
      return response.data;
    });
  } catch (e) {
    if (e.status !== 404) {
      dispatch({
        type: GLOBAL_ERROR,
        payload: { error_description: e.message }
      });
    } else {
      dispatch({
        type: GLOBAL_ERROR,
        payload: { error_description: e.data.error_description }
      });
    }
  } finally {
    dispatch(stopLoading('getEntity'));
  }
};

export const clearEntity = name => async dispatch => {
  dispatch(startLoading('clearEntity'));
  try {
    dispatch({
      type: CLEAR_ENTITY,
      entityName: name
    });
  } catch (e) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: { error_description: e.data.error_description }
    });
  } finally {
    dispatch(stopLoading('clearEntity'));
  }
};

export const saveEntity = request => async dispatch => {
  if (!dispatch({ type: MIDDLEWARE_VALIDATE })) return;

  dispatch(startLoading('saveEntity'));

  try {
    const response = await ajax.post(request.url, request.entity);
    const { id } = response.data;
    if (!request.dispatchOverride) {
      dispatch({
        type: SAVE_ENTITY_SUCCESS,
        id
      });
      dispatch({
        type: NOTIFICATION,
        notification: request.notification ? request.notification : `The ${request.entityName} is now updated`
      });
    } else {
      dispatch(request.dispatchOverride);
    }
    return id;
  } catch (e) {
    if (e.status === 400) {
      dispatch({
        type: SAVE_ENTITY_VALIDATION_FAILURE,
        errors: e.data,
        [request.entityName]: request.entity
      });
    } else {
      dispatch({
        type: GLOBAL_ERROR,
        payload: { error_description: e.message }
      });
    }
  } finally {
    dispatch(stopLoading('saveEntity'));
  }
};

export const putEntity = request => async dispatch => {
  dispatch(startLoading('putEntity'));
  if (!dispatch({ type: MIDDLEWARE_VALIDATE })) return;

  try {
    const response = await ajax.put(request.url, request.entity || null);

    dispatch({
      type: NOTIFICATION,
      notification: request.notification ? request.notification : `The ${request.entityName} has been ${request.action}`
    });

    return response;
  } catch (e) {
    if (e.status === 400) {
      dispatch({
        type: SAVE_ENTITY_VALIDATION_FAILURE,
        errors: e.data,
        [request.entityName]: request.entity
      });
    } else {
      dispatch({
        type: GLOBAL_ERROR,
        payload: { error_description: e.message }
      });
    }
  } finally {
    dispatch(stopLoading('putEntity'));
  }
};

export const listEntities = (page, pageSize, request) => async dispatch => {
  dispatch(startLoading('listEntities'));

  try {
    const url = request.url.replace(PAGE_TOKEN, page).replace(PAGE_SIZE_TOKEN, pageSize);
    await ajax.get(url).then(response => {
      dispatch({
        type: LIST_TABLE,
        payload: response.data,
        entityName: request.entityName,
        pageSize,
        currentPage: page
      });
    });
  } catch (e) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: { error_description: e.message }
    });
  } finally {
    dispatch(stopLoading('listEntities'));
  }
};

export const deleteEntity = request => async dispatch => {
  dispatch(startLoading('deleteEntity'));

  try {
    const response = await ajax.delete(request.url);

    dispatch({
      type: NOTIFICATION,
      notification: request.notification ? request.notification : `The ${request.entityName} has been deleted`
    });
    return response;
  } catch (e) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: { error_description: e.message }
    });
  } finally {
    dispatch(stopLoading('deleteEntity'));
  }
};

export const createInitialEntity = request => async dispatch =>
  dispatch({ type: CREATE_INITIAL_ENTITY, entityName: request.entityName, initialState: request.initialState });

export const clearValidationError = () => async dispatch => dispatch({ type: CLEAR_VALIDATION_FAILURE });

export const putEntityWithoutValidation = request => async dispatch => {
  dispatch(startLoading('putEntity'));

  try {
    const response = await ajax.put(request.url, request.entity || null);

    dispatch({
      type: NOTIFICATION,
      notification: request.notification ? request.notification : `The ${request.entityName} has been ${request.action}`
    });

    return response;
  } catch (e) {
    dispatch({
      type: GLOBAL_ERROR,
      payload: { error_description: e.message }
    });
  } finally {
    dispatch(stopLoading('putEntity'));
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { IconButton, LinearProgress } from '@material-ui/core';
import { style } from '../../assets/style';
import credentialDesignStyles from '../../assets/credentialDesignStyles';
import { colors } from '../../assets/constants';

const styles = () => ({
  ...style,
  ...credentialDesignStyles,
  upload: {
    width: '299px',
    marginTop: '5px',
    backgroundColor: colors.orangeDarkHalf,
    '& > div': {
      backgroundColor: colors.orangeDark
    }
  },
  imageHelpContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    height: 184,
    paddingBottom: '20px',
    justifyContent: 'space-between',
    '& > div:last-of-type': {
      paddingLeft: '20px'
    }
  },
  helpText: {
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '12px',
    color: '#777',
    marginBottom: '5px'
  },
  helpContainer: {
    display: 'flex',
    alignItems: 'center'
  }
});

class ImageUpload extends Component {
  state = { isDragging: false, file: null };

  dropRef = React.createRef();

  componentDidMount() {
    const div = this.dropRef.current;
    div.addEventListener('dragenter', this.handleDragIn);
    div.addEventListener('dragleave', this.handleDragOut);
    div.addEventListener('dragover', this.handleDrag);
    div.addEventListener('drop', this.handleDrop);
    div.addEventListener('hover', this.handleStopPropagate);
    div.addEventListener('mousemove', this.handleStopPropagate);
  }

  componentWillUnmount() {
    if (this.dropRef.current) {
      const div = this.dropRef.current;
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.handleDrag);
      div.removeEventListener('drop', this.handleDrop);
      div.removeEventListener('hover', this.handleStopPropagate);
      div.removeEventListener('mousemove', this.handleStopPropagate);
    }
  }

  handleStopPropagate = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ isDragging: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();

    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ isDragging: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    const { handleImageUpload } = this.props;

    this.setState({ isDragging: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.setState({ file: URL.createObjectURL(e.dataTransfer.files[0]) });
      e.dataTransfer.clearData();
      this.dragCounter = 0;
      handleImageUpload(e.dataTransfer.files[0]);
    }
  };

  clearImage = () => {
    const { clearImageUpload } = this.props;
    this.setState({
      file: null
    });

    clearImageUpload();
  };

  render() {
    const { classes, image, uploadStatus, width, height } = this.props;
    const { isDragging, file } = this.state;

    return (
      <div className={classes.layout}>
        <div>
          <div className={classes.container}>
            <div
              className={classNames(classes.imageContainer, {
                [classes.imageContainerActive]: isDragging,
                [classes.hidden]: file || image
              })}
              style={{ width, height }}
              ref={this.dropRef}
            >
              <div>Drop the image here</div>
              {height && width && (
                <div className={classes.imageSize}>
                  {width} x {height}
                </div>
              )}
            </div>
            {(file || image) && (
              <div>
                <div className={classes.imageDeleteContainer}>
                  <img src={file || image} width={width} height={height} alt="Uploaded" />
                  <div className={classes.imageHelpContainer}>
                    <div>
                      <IconButton onClick={this.clearImage}>
                        <i className={classNames('material-icons', classes.da)}>delete</i>
                      </IconButton>
                    </div>
                  </div>
                </div>
                {uploadStatus.length > 0 && (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <LinearProgress
                      variant="determinate"
                      value={uploadStatus[0].completeness}
                      className={classNames(classes.upload, {
                        [classes.uploadError]: uploadStatus[0].error
                      })}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
ImageUpload.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
  fields: PropTypes.array,
  uploadStatus: PropTypes.array,
  handleChange: PropTypes.func,
  handleImageUpload: PropTypes.func,
  clearImageUpload: PropTypes.func,
  isSaving: PropTypes.bool,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
};

export default withStyles(styles)(ImageUpload);

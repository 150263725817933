import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getHoldersUrl } from '../../utils/searchUrls';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { listHolderRequest } from '../../utils/entityRequests/holderRequests';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class HolderListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/admin/holder/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table, classes } = this.props;
    const items = getRecords(search, table);
    return items.map(holder => ({
      clickAction: () => this.actionNavigate(holder.identifier),
      cells: [
        {
          value: (
            <div>
              <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar}>
                  {holder.firstName.charAt(0).toUpperCase() + holder.lastName.charAt(0).toUpperCase()}
                </Avatar>
              </div>
              {`${holder.firstName} ${holder.lastName}`}
            </div>
          )
        },
        { value: holder.email, hiddenBelow: 'md' },
        { value: holder.enabled ? 'Yes' : 'No', hiddenBelow: 'lg' },
        { value: holder.locked ? 'Yes' : 'No', hiddenBelow: 'lg' }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Name' }, { value: 'Email' }, { value: 'Enabled' }, { value: 'Locked' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No holders Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, table } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="Holder"
              buttons={[
                {
                  text: 'Add Holder',
                  icon: <AddWhiteIcon />,
                  url: '/admin/holder/create',
                  primary: true
                }
              ]}
            />

            <ListingWithSearch
              name="holders"
              getSearchUrl={getHoldersUrl}
              onList={listEntities}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listHolderRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

HolderListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-holders'),
  ...tableSelector(state, 'holders')
});

export default compose(withStyles(styles), connect(mapStateToProps, { listEntities }))(HolderListView);

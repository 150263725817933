import {
  commonStyles,
  colors,
  border,
  sizes,
  tableErrorBorder,
  globalHalfSpacingPixels,
  textEllipsis,
  globalQuarterSpacingPixels,
  globalSpacing,
  commonFont
} from './constants';
import { centerVertically } from './mixins';

export const indentedTableBorder = '1px solid #e0dfe0';
export const cellTopBottomPadding = `${globalSpacing / 8}px`;
export const cellMinHeight = '50px';
export const cellDimensions = {
  padding: `${cellTopBottomPadding} ${globalHalfSpacingPixels}`,
  height: cellMinHeight
};
export const clickableCellBackground = 'rgba(255,193,7,0.1)';
export const tableSelectValue = {
  selectValue: {
    backgroundColor: clickableCellBackground,
    cursor: 'pointer'
  },
  selectedValue: {
    backgroundColor: 'rgba(38,166,154,0.1) !important',
    cursor: 'pointer'
  },
  select: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 56px 4px 24px',
    boxSizing: 'border-box'
  }
};

export const tableStyles = {
  ...tableSelectValue,
  tableContainer: {
    padding: sizes.globalSide,
    '& $sectionHeading': {
      borderBottom: 'none'
    }
  },
  tableRow: {
    '& td': cellDimensions,
    '&:hover': {
      boxShadow: '0px 3px 8px 0 rgba(0, 0, 0, 0.3)',
      backgroundColor: colors.white,
      '& $tableCellAction': {
        opacity: '0.5'
      },
      '& $tableAction': {
        zIndex: 80
      },
      '& $tableActionItem': {
        opacity: 1
      },
      cursor: 'pointer',
      '& .select-menu-icon-table': {
        display: 'none'
      },
      '& $tableSectionHeading': {
        color: 'red'
      }
    },
    '& td:last-of-type': {
      borderRight: 'none',
      position: 'relative'
    },
    '& td:first-of-type': {
      borderLeft: 'none'
    }
  },
  tableRowHasDepth2: {
    background: colors.purpleTransparent,
    '&:hover': {
      boxShadow: 'none',
      cursor: 'initial'
    },
    '& td:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: globalQuarterSpacingPixels,
      height: '100%',
      top: 0,
      background: colors.grey1,
      borderRight: indentedTableBorder
    }
  },
  hasDepth: {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: globalQuarterSpacingPixels,
      height: '100%',
      top: 0,
      background: colors.grey1,
      borderRight: indentedTableBorder
    }
  },
  hasDepth2: {
    '&:after': {
      content: '""',
      position: 'absolute',
      left: globalQuarterSpacingPixels,
      width: globalQuarterSpacingPixels,
      height: '100%',
      top: 0,
      background: 'rgba(103, 58, 183, 0.06)',
      borderRight: indentedTableBorder
    }
  },
  tableRowActive: {
    boxShadow: '0px 3px 8px 0 rgba(0, 0, 0, 0.3)'
  },
  tableRowFaded: {
    opacity: '0.3'
  },
  tableRowHeading: {
    borderTop: `3px solid ${colors.borderBlue}`,
    background: colors.dirtyWhiteTransparent,
    '&:hover': {
      boxShadow: 'none',
      cursor: 'initial'
    }
  },
  tableCell: {
    ...textEllipsis,
    color: `${colors.blue} !important`,
    position: 'relative',
    // borderRight: `solid 1px ${colors.borderBlue}`,
    ...commonFont,
    fontSize: '16px !important',
    fontWeight: '500 !important',
    padding: `4px ${globalHalfSpacingPixels}`,
    '&:first-of-type': {
      paddingLeft: `${globalSpacing}px`
    }
  },
  tableCellAction: {
    minWidth: '65px',
    border: 'none',
    padding: '0 !important',
    color: '#fff',
    zIndex: 5,
    opacity: 0,
    textAlign: 'center',
    cursor: 'pointer',
    transition: commonStyles.transition,
    '&:hover': {
      opacity: '1 !important'
    }
  },
  delete: {
    backgroundColor: colors.red
  },
  clear: {
    backgroundColor: colors.yellow
  },
  deleteIcon: {
    height: '24px',
    width: '24px',
    cursor: 'pointer'
  },
  deleteTableHeadCell: {
    backgroundColor: '#fff',
    border: 'none !important',
    width: '45px',
    padding: '12px !important'
  },
  tableCellEdit: {
    padding: '0 !important',
    height: '49px',
    position: 'relative'
  },
  tableCellEditText: {
    padding: `4px ${globalHalfSpacingPixels} !important`
  },
  tableCellEditField: {
    border: 'none',
    width: '100%',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: cellDimensions.padding,
    '&:focus': {
      outlineWidth: '0'
    }
  },
  tableCellEditFieldPopulated: {
    backgroundColor: 'rgba(33,150,243,0.1)'
  },
  table: {
    borderCollapse: 'collapse',
    fontSize: '15px',
    color: colors.fontGreyDark,
    fontWeight: 'normal',
    '& tr th:last-of-type': {
      borderRight: 'none'
    }
  },
  tableBody: {
    backgroundColor: colors.backgroundBlue
  },
  tableHeader: {
    // borderRight: `solid 1px ${colors.borderBlue}`,
    borderLeft: 'none',
    // borderTop: border.style_1px,
    borderBottom: border.style_1px,
    backgroundColor: colors.backgroundBlue,
    position: 'relative',
    ...cellDimensions,
    ...commonFont,
    color: colors.blueDark,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    '&:first-of-type': {
      paddingLeft: `${globalSpacing}px`
    }
  },
  tableAction: {
    display: 'flex',
    zIndex: 70,
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    transition: commonStyles.transition,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  tableActionItem: {
    opacity: 0,
    transition: commonStyles.transition
  },
  tableActionItemBelowRow: {
    position: 'relative',
    top: '50px'
  },
  lessImportant: {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '12px',
    paddingTop: '3px'
  },
  tableHeaderWithAction: {
    display: 'inline',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  tableHeaderLinkContainer: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    fontSize: '12px',
    fontWeight: 'normal',
    color: colors.blueLight,
    textDecoration: 'none',
    cursor: 'pointer',
    marginRight: '15px'
  },
  itemValue: {
    fontSize: '14px'
  },
  selectItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    marginBottom: '-1px',
    '&:before': {
      borderBottom: 'none',
      content: '""',
      position: 'relative'
    },
    '&:after': {
      borderBottom: 'none'
    },
    '&:hover': {
      borderBottom: 'none'
    }
  },
  selectUnit: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
    marginBottom: '-1px',
    '&:before': {
      borderBottom: 'none'
    }
  },
  selectContainer: {
    width: '100%',
    height: '100%'
  },
  selectParent: {
    width: '100%',
    height: '100%'
  },
  arrowDropdownIcon: {
    position: 'absolute',
    ...centerVertically,
    right: '10px'
  },
  tableError: {
    ...tableErrorBorder
  },
  tableCellInputDiv: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '4px 56px 4px 24px',
    display: 'flex',
    alignItems: 'center'
  },
  tableSectionHeading: {
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '18px',
    fontWeight: 300,
    color: '#9e9e9e',
    textTransform: 'uppercase',
    lineHeight: 0.89,
    paddingBottom: globalQuarterSpacingPixels,
    paddingLeft: '14px'
  },
  datepicker: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: '10px'
  },
  checkBoxColumn: {
    width: '40px',
    textAlign: 'center',
    padding: '0 !important',
    // The markup with the div is located in the table header,
    // whereas the one without is in the table body.
    '& > span, & > div > span': {
      padding: '0 !important'
    }
  },
  checkBoxColumnChecked: {
    // Same here
    '& > span, & > div > span': {
      color: `${colors.green} !important`
    }
  },
  checkBoxHighlightRow: {
    backgroundColor: 'rgb(38,166,154, 0.05)'
  },
  hidden: {
    display: 'none'
  },
  addNewButton: {
    color: colors.blueLight,
    fontSize: sizes.fontSmall,
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.3
  }
};

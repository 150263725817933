// User Admin
import UserListView from '../components/userAdmin/UserListView';
import UserView from '../components/userAdmin/UserView';
import ChangeUserView from '../components/userAdmin/ChangeUserView';
import UserListItemBreadcrumb from '../components/breadcrumbs/UserListItemBreadcrumb';
import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';
import ChangeTeamView from '../components/team/ChangeTeamView';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';
import TeamView from '../components/team/TeamView';
import TeamListView from '../components/team/TeamListView';
import TeamListBreadcrumb from '../components/breadcrumbs/TeamListBreadcrumb';
import InviteOrganisationMemberView from '../components/organisationMember/InviteOrganisationMemberView';
import OrganisationMemberView from '../components/organisationMember/OrganisationMemberView';
import OrganisationMemberListItemBreadcrumb from '../components/breadcrumbs/OrganisationMemberListItemBreadcrumb';
import OrganisationMemberListView from '../components/organisationMember/OrganisationMemberListView';
import NotificationListView from '../components/notification/NotificationListView';

export default [
  // userAdmin routes
  { path: '/organisation/user/create', component: ChangeUserView, breadcrumb: null, protected: true },
  {
    path: '/organisation/user/edit/:id',
    component: ChangeUserView,
    breadcrumb: UserListItemBreadcrumb,
    protected: true
  },
  { path: '/organisation/user/view/:id', component: UserView, breadcrumb: UserListItemBreadcrumb, protected: true },
  {
    path: '/organisation/user',
    component: UserListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'users',
    functionName: 'listEntities',
    heading: 'User'
  },
  // team routes
  {
    path: '/organisation/team/edit/:id',
    component: ChangeTeamView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'team',
    itemName: 'name',
    heading: 'Team'
  },
  { path: '/organisation/team/create', component: ChangeTeamView, breadcrumb: null, protected: true },
  {
    path: '/organisation/team/view/:id',
    component: TeamView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'team',
    itemName: 'name',
    heading: 'Team'
  },
  { path: '/organisation/team', component: TeamListView, breadcrumb: TeamListBreadcrumb, protected: true },

  // Organisation Member routes
  {
    path: '/organisation/organisationMember/create',
    component: InviteOrganisationMemberView,
    breadcrumb: null,
    protected: true
  },
  {
    path: '/organisation/organisationMember/view/:id',
    component: OrganisationMemberView,
    breadcrumb: OrganisationMemberListItemBreadcrumb,
    protected: true
  },
  {
    path: '/organisation/organisationMember',
    component: OrganisationMemberListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    functionName: 'listEntities',
    tableName: 'organisationMembers',
    heading: 'Organisation Members'
  },
  {
    path: '/organisation/notification',
    component: NotificationListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'notifications',
    functionName: 'listEntities',
    heading: 'Notification'
  }
];

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';

export const listNotificationRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_NOTIFICATION}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'notifications'
  };
};

export const notificationViewedRequest = id => ({
  url: `${process.env.REACT_APP_NOTIFICATION_VIEWED}${id}/viewed`,
  entityName: 'notification',
  action: 'viewed'
});

export const notificationDeleteRequest = id => ({
  url: `${process.env.REACT_APP_NOTIFICATION_VIEWED}${id}`,
  entityName: 'notification'
});

/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { getEntity, putEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import NewSectionHeading from '../common/NewSectionHeading';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import { capitalise } from '../../utils/string';
import { colors } from '../../assets/constants';
import { convertUtcToLocal } from '../../utils/utilities';
import { getEmailRequest, resendEmailRequest } from '../../utils/entityRequests/emailRequests';
import { entitySelector } from '../../store/selectors/entity';

const styles = {
  ...layout,
  ...style,
  error: {
    color: colors.red
  }
};

class EmailView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getEmailRequest(id));
  }

  render() {
    const { classes, email, putEntity } = this.props;

    return (
      Object.keys(email).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions>
            {!email.userEmailWrong && (
              <Tooltip title="Resend Email" aria-label="add" placement="bottom">
                <IconButton onClick={() => putEntity(resendEmailRequest(email.identifier))}>
                  <i className="material-icons">loop</i>
                </IconButton>
              </Tooltip>
            )}
          </NewHeaderViewActions>
          <Grid item xs={6}>
            <NewSectionHeading heading="Email Details" />
            {email.name && (
              <div className={classes.displayLabelAndField}>
                <label className={classes.displayLabel}>Name</label>
                <div className={classes.displayField}>{email.name}</div>
              </div>
            )}
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Date</label>
              <div className={classes.displayField}>
                {convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', email.dispatchTime)}
              </div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Status</label>
              <div className={classes.displayField}>{capitalise(email.status)}</div>
            </div>
            {email.failureReason && (
              <div className={classNames(classes.displayLabelAndField, classes.error)}>
                <label className={classes.displayLabel}>Failure Reason</label>
                <div className={classNames(classes.displayField, classes.error)}>{email.failureReason}</div>
              </div>
            )}
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>From</label>
              <div className={classes.displayField}>{email.fromAddress}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>To</label>
              <div className={classes.displayField}>{email.toAddress}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Subject</label>
              <div className={classes.displayField}>{email.subject}</div>
            </div>
            <div className={classes.displayLabelAndField}>
              <div className={classes.displayField} dangerouslySetInnerHTML={{ __html: email.body }} />
            </div>
          </Grid>
        </Grid>
      )
    );
  }
}

EmailView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  email: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'email')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity, putEntity }))(EmailView);

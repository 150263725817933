export const getNumberAtEndOfString = str => {
  const numberMatch = str.match(/\d+$/);
  if (numberMatch) {
    return parseInt(numberMatch[0], 10);
  }
  return numberMatch;
};

export const trimSingleUnderscore = str => {
  let result = str;
  if (result.startsWith('_')) {
    result = result.substring(1, result.length);
  }
  if (result.endsWith('_')) {
    result = result.substring(0, result.length - 1);
  }
  return result;
};

export const createDeleteMessage = resourceName => `Are you sure you want to delete this ${resourceName}?`;

export const isString = value => typeof value === 'string';
export const getFirstValueWhenString = array => array.find(value => isString(value));
export const isNotEmpty = value => /\S/.test(value);
export const capitalise = value => {
  /* eslint-disable indent */
  return value
    ? value
        .replace(/_/g, ' ')
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, a => {
          return a.toUpperCase();
        })
    : '';
  /* eslint-enable indent */
};

import { createSelector } from 'reselect';

// *** Loading selectors Start *** //
export const getLoading = (state, name) => !!state.loading[name];
export const loadingSelector = createSelector(getLoading, loading => loading);
// *** Loading selectors End *** //

// *** Settings selectors Start *** //
export const getSetting = (state, name) => !!state.settings[name];
export const getSettingWithPayload = (state, name) => state.settings[name];
export const settingSelector = createSelector(getSetting, setting => setting);
// *** Settings selectors End *** //

// *** Global Errors selectors Start *** //
export const getGlobalError = state => state.globalError;
export const globalErrorSelector = createSelector(getGlobalError, exception => exception);
// *** Global Errors selectors End *** //

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import { Card, CardContent } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import { withStyles } from '@material-ui/core/styles';
import { tableSelector } from '../../store/selectors/table';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import { setInitialSearchFilter, changeSearchFilter } from '../../store/actions/SearchActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { getSearchAttendancesUrl } from '../../utils/searchUrls';
import { EMOTION_TYPE } from '../../assets/constants';
import dashboardStyle from '../../assets/dashboardStyles';
import { convertUtcToLocal, getRecords } from '../../utils/utilities';
import PresetDateRangePicker from '../widgets/PresetDateRangePicker';
import { listAttendanceRequest, getAttendanceStatsRequest } from '../../utils/entityRequests/attendanceRequests';
import { listOrganisationWorkplaceRequest } from '../../utils/entityRequests/workPlaceRequests';
import { entityArraySelector, entitySelector } from '../../store/selectors/entity';

const styles = {
  ...style,
  happy: {
    color: '#1e7c00'
  },
  neutral: {
    color: '#e38400'
  },
  sad: {
    color: '#a90303'
  },
  statsEmotion: {
    fontSize: '2.9rem',
    color: 'white'
  },
  ...dashboardStyle
};

class AttendanceListView extends React.Component {
  async componentDidMount() {
    const { getEntity, setInitialSearchFilter, getAttendanceStatsRequest } = this.props;

    await setInitialSearchFilter('attendance', {
      workPlace: NO_SEARCH_VALUE,
      dates: {
        startDate: moment()
          .local()
          .startOf('day')
          .format('DD-MM-YYYY HH:mm:ss'),
        endDate: moment()
          .local()
          .endOf('day')
          .format('DD-MM-YYYY HH:mm:ss')
      }
    });

    await getEntity(listOrganisationWorkplaceRequest());
    await getEntity(getAttendanceStatsRequest(''));
  }

  getEmotionIcon = emotion => {
    const { classes } = this.props;
    if (emotion === EMOTION_TYPE.HAPPY.id) return <SentimentVerySatisfiedIcon className={classes.happy} />;
    if (emotion === EMOTION_TYPE.NEUTRAL.id) return <SentimentSatisfiedIcon className={classes.neutral} />;
    if (emotion === EMOTION_TYPE.SAD.id) return <SentimentVeryDissatisfiedIcon className={classes.sad} />;
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(attendance => ({
      cells: [
        {
          value: attendance.holderName
        },
        {
          value: attendance.workPlaceName
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', attendance.checkInTime)
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', attendance.checkOutTime)
        },
        {
          value: this.getEmotionIcon(attendance.feeling)
        }
      ]
    }));
  };

  filterChange = (handleFilterChange, event) => {
    const { getAttendanceStatsRequest, getEntity } = this.props;
    handleFilterChange(event, 'workPlace');
    getEntity(getAttendanceStatsRequest(''));
  };

  getSearchUrl = ({ currentPage, pageSize, searchText, filters }) => {
    const { getEntity, getAttendanceStatsRequest } = this.props;
    getEntity(getAttendanceStatsRequest(searchText));

    return getSearchAttendancesUrl({ currentPage, pageSize, searchText, filters });
  };

  clearSearch = () => {
    const { getEntity, getAttendanceStatsRequest } = this.props;
    getEntity(getAttendanceStatsRequest(''));
  };

  getListing = props => {
    const { search, isLoading, organisationWorkPlaces } = this.props;
    const workPlaceFilterValue = search && search.filters.workPlace ? search.filters.workPlace : NO_SEARCH_VALUE;
    return (
      <ScrollableTable
        headers={[
          { value: 'Holder Name' },
          {
            value: 'Workplace Name',
            filter: {
              value: workPlaceFilterValue,
              options: organisationWorkPlaces || [],
              onChange: event => this.filterChange(props.handleFilterChange, event)
            }
          },
          {
            value: 'Check In Time'
          },
          {
            value: 'Check Out Time'
          },
          {
            value: 'Feeling'
          }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Attendances Found"
        {...props}
      />
    );
  };

  getPercentage = (numerator, denominator) => {
    return numerator !== 0 ? ` (${Number.parseInt((numerator / denominator) * 100)}%)` : ' (0%)';
  };

  handleSelectDate = async model => {
    const {
      changeSearchFilter,
      getEntity,
      listEntities,
      listAttendanceRequest,
      getAttendanceStatsRequest
    } = this.props;

    await changeSearchFilter('attendance', 'dates', {
      startDate: model.startDate,
      endDate: model.endDate
    });

    getEntity(getAttendanceStatsRequest(''));
    listEntities(0, 20, listAttendanceRequest());
  };

  render() {
    const { classes, listEntities, organisationWorkPlaces, attendanceStats, table, listAttendanceRequest } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <Grid className={classes.statsContainer}>
              <Card className={classes.dashboardStatContainer}>
                <CardContent className={classes.cardContent}>
                  <div className={classNames(classes.iconContainer, classes.completedIconContainer)}>
                    <SentimentVerySatisfiedIcon className={classes.statsEmotion} />
                  </div>
                  <div className={classes.statTitle}>
                    Happy
                    <div>
                      {attendanceStats.numberHappy} / {attendanceStats.numberCheckIns}
                      {this.getPercentage(attendanceStats.numberHappy, attendanceStats.numberCheckIns)}
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className={classes.dashboardStatContainer}>
                <CardContent className={classes.cardContent}>
                  <div className={classNames(classes.iconContainer, classes.assignedIconContainer)}>
                    <SentimentSatisfiedIcon className={classes.statsEmotion} />
                  </div>
                  <div className={classes.statTitle}>
                    Neutral
                    <div>
                      {attendanceStats.numberNeutral} / {attendanceStats.numberCheckIns}
                      {this.getPercentage(attendanceStats.numberNeutral, attendanceStats.numberCheckIns)}
                    </div>
                  </div>
                </CardContent>
              </Card>
              <Card className={classes.dashboardStatContainer}>
                <CardContent className={classes.cardContent}>
                  <div className={classNames(classes.iconContainer, classes.failedIconContainer)}>
                    <SentimentVeryDissatisfiedIcon className={classes.statsEmotion} />
                  </div>
                  <div className={classes.statTitle}>
                    Sad
                    <div>
                      {attendanceStats.numberSad} / {attendanceStats.numberCheckIns}
                      {this.getPercentage(attendanceStats.numberSad, attendanceStats.numberCheckIns)}
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <PresetDateRangePicker
              className={classes.datepicker}
              startDate={moment()
                .local()
                .startOf('day')}
              endDate={moment()
                .local()
                .endOf('day')}
              onSelectDate={this.handleSelectDate}
            />

            <ListingWithSearch
              name="attendance"
              getSearchUrl={this.getSearchUrl}
              onList={listEntities}
              filtersList={{
                workPlace: organisationWorkPlaces
              }}
              renderList={props => this.getListing(props)}
              list={table}
              onClearSearch={this.clearSearch}
              additionalSearchParams={listAttendanceRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

AttendanceListView.propTypes = {
  classes: PropTypes.object.isRequired,
  setInitialSearchFilter: PropTypes.func.isRequired,
  getEntity: PropTypes.func.isRequired,
  listEntities: PropTypes.func.isRequired,
  changeSearchFilter: PropTypes.func,
  getAttendanceStatsRequest: PropTypes.func,
  listAttendanceRequest: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  attendanceStats: PropTypes.object,
  attendance: PropTypes.object,
  table: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  organisationWorkPlaces: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-attendance'),
  ...entitySelector(state, 'attendanceStats'),
  ...tableSelector(state, 'attendance'),
  ...entityArraySelector(state, 'organisationWorkPlaces')
});

export default compose(
  connect(mapStateToProps, {
    setInitialSearchFilter,
    changeSearchFilter,
    getEntity,
    listEntities,
    listAttendanceRequest,
    getAttendanceStatsRequest
  }),
  withStyles(styles)
)(AttendanceListView);

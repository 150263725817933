import _ from 'lodash';
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, IconButton, Tooltip, Modal, Slide } from '@material-ui/core';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { uploadDocuments } from '../../store/actions/DocumentActions';
import { documentUploadsSelector } from '../../store/selectors/document';
import { loadingSelector } from '../../store/selectors/shared';
import { style } from '../../assets/style';
import form from '../../assets/formStyles';
import layout from '../../assets/layout';
import {
  CONTROL_TYPE,
  FILE_UPLOAD_STATUS,
  FILE_UPLOAD_TYPE,
  STANDARD_CREDENTIAL_TYPE,
  VALIDATORS_BY_NAME
} from '../../assets/constants';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import HolderAssignment from './HolderAssignment';
import {
  getCredentialTypeRequest,
  listIssuerCredentialTypeReferenceRequest
} from '../../utils/entityRequests/credentialTypeRequests';
import { saveTestCredentialRequest } from '../../utils/entityRequests/credentialRequestRequests';
import { getCreateCredentialRequest } from '../../utils/entityRequests/credentialRequests';
import { entityArraySelector, entitySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import NewValidatedField from '../widgets/NewValidatedField';
import DisplayAdditionalInformation from './DisplayAdditionalInformation';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout,
  ...form
});

class TestWriteHolderCredentialView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      holder: {
        name: '',
        identifier: ''
      },
      credential: {
        credentialTypeUuid: '',
        standardCredentialType: '',
        controls: []
      },
      shouldDisplaySelectHolder: false
    };

    this.cancelUrl = '/credential/inbox';
  }

  async componentDidMount() {
    const { getEntity } = this.props;
    await getEntity(listIssuerCredentialTypeReferenceRequest());
  }

  handleCredentialTypeChange = async event => {
    const { getEntity } = this.props;
    const { value } = event.target;
    const credentialType = await getEntity(getCredentialTypeRequest(value));
    const credential = await getEntity(getCreateCredentialRequest(credentialType.uuid));

    if (credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id) {
      credential.issueDate = '';
      credential.credentialId = '';
    } else if (credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id) {
      credential.expiryDate = '';
    }

    credential.controls = [];

    this.setState({
      credential
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { credential } = this.state;
    const credentialModel = _.cloneDeep(credential);
    _.set(credentialModel, name, value);
    this.setState({
      credential: credentialModel
    });
  };

  handleControlChange = event => {
    const { name, value } = event.target;
    const { credential } = this.state;
    const credentialModel = _.cloneDeep(credential);
    let foundControl;

    for (let i = 0; i < credential.sections.length; i++) {
      const temp = credential.sections[i].controls.find(controlItem => controlItem.identifier === name);
      if (temp) foundControl = temp;
    }
    const control = credentialModel.controls.find(item => item.identifier === name);
    if (!control) {
      credentialModel.controls.push({ identifier: name, values: [this.getValue(foundControl, name, value)] });
    } else control.values = [this.getValue(foundControl, name, value)];

    this.setState({
      credential: credentialModel
    });
  };

  handleCheckboxControlChange = event => {
    const { name, value } = event.target;
    const { credential } = this.state;
    const credentialModel = _.cloneDeep(credential);
    let foundControl;

    for (let i = 0; i < credential.sections.length; i++) {
      const temp = credential.sections[i].controls.find(controlItem => controlItem.identifier === name);
      if (temp) foundControl = temp;
    }

    const control = credentialModel.controls.find(item => item.identifier === name);

    if (!control) {
      credentialModel.controls.push({ identifier: name, values: [this.getValue(foundControl, name, value)] });
    } else {
      const controlValue = control.values.find(item => item.value === value);
      if (!controlValue) control.values.push(this.getValue(foundControl, name, value));
      else control.values = control.values.filter(item => item.value !== value);
    }
    this.setState({
      credential: credentialModel
    });
  };

  getValue = (foundControl, name, value) => {
    if (foundControl.type === CONTROL_TYPE.DATE.id || foundControl.type === CONTROL_TYPE.TEXT.id) {
      return { value, name: '' };
    }

    if (
      foundControl.type === CONTROL_TYPE.MULTIPLE_CHOICE.id ||
      foundControl.type === CONTROL_TYPE.DROP_DOWN.id ||
      foundControl.type === CONTROL_TYPE.CHECKBOXES.id
    ) {
      const selectOption = foundControl.selectOptions.find(item => item.identifier === value);
      if (selectOption) return { value: selectOption.identifier, name: selectOption.name };

      return { value: '', name: '' };
    }
  };

  closeSelectHolder = () => {
    this.setState({
      shouldDisplaySelectHolder: false
    });
  };

  openSelectHolder = () => {
    this.setState({
      shouldDisplaySelectHolder: true
    });
  };

  handleClearHolder = () => {
    this.setState({
      holder: {
        name: '',
        identifier: ''
      }
    });
  };

  handleSelectHolder = user => {
    this.setState({
      holder: {
        name: user.name,
        identifier: user.uuid
      }
    });

    this.closeSelectHolder();
  };

  handleDocumentChange = (id, file) => {
    const { uploadDocuments } = this.props;
    const { credential } = this.state;
    uploadDocuments([file], id, credential.uuid, FILE_UPLOAD_TYPE.DOCUMENT);
  };

  isUploadingCompleted = bob => {
    let noInComplete = 0;

    Object.keys(bob).forEach(upload => {
      noInComplete += bob[upload].filter(item => !item.status || item.status === FILE_UPLOAD_STATUS.UPLOADED.id).length;
    });

    return noInComplete === 0;
  };

  submitForm = async e => {
    e.preventDefault();
    const { history, saveEntity, fileUploads } = this.props;
    const { credential, holder } = this.state;

    const credentialModel = _.cloneDeep(credential);

    if (fileUploads) {
      fileUploads
        .filter(item => item.status === FILE_UPLOAD_STATUS.LINKED.id)
        .forEach(item => {
          if (item.controlName === 'additionalPaperwork') {
            if (!credentialModel.attachmentsFileIds) credentialModel.attachmentsFileIds = [];
            credentialModel.attachmentsFileIds.push(item.identifier);
          }
        });
    }
    const credentialRequestId = await saveEntity(saveTestCredentialRequest(holder.identifier, credentialModel));

    if (!credentialRequestId) return;

    history.push('/credential/inbox');
  };

  render() {
    const { classes, isSaving, credentialTypes, documentUploads, credentialType } = this.props;
    const { credential, shouldDisplaySelectHolder, holder } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              declineButtonText="Back"
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header="Test Credential Details"
              isSaving={isSaving}
              isDisabled={!this.isUploadingCompleted(documentUploads)}
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      key="credentialTypeUuid"
                      label="Credential Type"
                      value={(credentialTypes || []).find(item => item.value === credential.credentialTypeUuid) || null}
                      onChange={this.handleCredentialTypeChange}
                      items={credentialTypes || []}
                      id="credentialTypeUuid"
                      itemName="label"
                      itemValue="value"
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classNames(classes.fieldContainer, classes.headingAndAction)}>
                    <NewValidatedField
                      id="holder"
                      label="Holder"
                      name="holder"
                      value={holder.name || ''}
                      onClick={this.openSelectHolder}
                      validators={[]}
                    />
                    <Tooltip title="Clear Holder" placement="bottom">
                      <IconButton onClick={this.handleClearHolder}>
                        <i className={classNames('material-icons', classes.icon)}>clear</i>
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                {credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id && (
                  <Fragment>
                    <NewValidatedField
                      key="givenName"
                      label="Given Names"
                      value={credential.givenName || ''}
                      onChange={this.handleChange}
                      id="givenName"
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                    <NewValidatedField
                      key="surname"
                      label="Surname"
                      value={credential.surname || ''}
                      onChange={this.handleChange}
                      id="surname"
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.DATE}
                      key="dateOfBirth"
                      label="Date of Birth"
                      value={credential.dateOfBirth || ''}
                      onChange={this.handleChange}
                      id="dateOfBirth"
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                    <NewValidatedField
                      key="issuingCountryCode"
                      label="Issuing Country Code"
                      value={credential.issuingCountryCode || ''}
                      onChange={this.handleChange}
                      id="issuingCountryCode"
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                    <NewValidatedField
                      key="placeOfBirth"
                      label="Place of Birth"
                      value={credential.placeOfBirth || ''}
                      onChange={this.handleChange}
                      id="placeOfBirth"
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </Fragment>
                )}
                {credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id && (
                  <Fragment>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          key="startYear"
                          label="Start Year"
                          value={credential.startYear || ''}
                          onChange={this.handleChange}
                          id="startYear"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          key="endYear"
                          label="End Year"
                          value={credential.endYear || ''}
                          onChange={this.handleChange}
                          id="endYear"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          key="fieldOfStudy"
                          label="Field of Study"
                          value={credential.fieldOfStudy || ''}
                          onChange={this.handleChange}
                          id="fieldOfStudy"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                  </Fragment>
                )}
                {credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.COVID19.id && (
                  <Fragment>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          key="country"
                          label="Country"
                          value={credential.country || ''}
                          onChange={this.handleChange}
                          id="country"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          key="laboratory"
                          label="Laboratory"
                          value={credential.laboratory || ''}
                          onChange={this.handleChange}
                          id="laboratory"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.CHECKBOX}
                          id="positive"
                          label="Positive"
                          name="positive"
                          value={credential.positive || false}
                          labelPlacement="end"
                          onChange={() => {
                            this.handleChange({
                              target: { name: 'positive', value: !credential.positive }
                            });
                          }}
                          validators={[]}
                          className={classes.paddingRight}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.DATE}
                          key="issueDate"
                          label="Issue Date"
                          value={credential.issueDate || ''}
                          onChange={this.handleChange}
                          id="issueDate"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          key="comment"
                          label="Comment"
                          value={credential.comment || ''}
                          onChange={this.handleChange}
                          id="comment"
                          validators={[]}
                        />
                      </div>
                    </Grid>
                  </Fragment>
                )}
                {(credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id ||
                  credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.PASSPORT.id) && (
                  <Fragment>
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.DATE}
                          key="issueDate"
                          label="Issue Date"
                          value={credential.issueDate || ''}
                          onChange={this.handleChange}
                          id="issueDate"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                    {credentialType.expires && (
                      <Grid item xs={12}>
                        <div className={classes.fieldContainer}>
                          <NewValidatedField
                            type={VALIDATED_FIELD_TYPES.DATE}
                            key="expiryDate"
                            label="Expiration Date"
                            value={credential.expiryDate || ''}
                            onChange={this.handleChange}
                            id="expiryDate"
                            validators={[VALIDATORS_BY_NAME.REQUIRED]}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.TEXT}
                          key="credentialId"
                          label="Credential ID"
                          value={credential.credentialId || ''}
                          onChange={this.handleChange}
                          id="credentialId"
                          validators={[VALIDATORS_BY_NAME.REQUIRED]}
                        />
                      </div>
                    </Grid>
                  </Fragment>
                )}
                {credential.uuid && (
                  <Fragment>
                    {(credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.LICENSE_AND_CERTIFICATE.id ||
                      credential.standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id) && (
                      <Grid item xs={12}>
                        <div className={classes.fieldContainer}>
                          <NewValidatedField
                            type={VALIDATED_FIELD_TYPES.TEXT}
                            key="credentialUrl"
                            label="Credential URL"
                            value={credential.credentialUrl || ''}
                            onChange={this.handleChange}
                            id="credentialUrl"
                            validators={[]}
                          />
                        </div>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <div className={classes.fieldContainer}>
                        <NewValidatedField
                          type={VALIDATED_FIELD_TYPES.FILE}
                          label="Additional Paperwork"
                          maxNoFiles={5}
                          value={(documentUploads && documentUploads[`${credential.uuid}-additionalPaperwork`]) || []}
                          id="additionalPaperwork"
                          validators={[]}
                          onDocumentChange={this.handleDocumentChange}
                          uploadMimeTypes={['image/*', 'application/*', 'text/plain']}
                        />
                      </div>
                    </Grid>
                    {credential.sections && credential.sections.length > 0 && (
                      <DisplayAdditionalInformation
                        sections={credential.sections}
                        onChange={this.handleControlChange}
                        onCheckboxChange={this.handleCheckboxControlChange}
                        credential={credential}
                      />
                    )}
                  </Fragment>
                )}
                {/* <Grid item xs={12}> */}
                {/*  <div className={classNames(classes.fieldContainer, classes.headingAndAction)}> */}
                {/*    <ValidatedField */}
                {/*      id="holder" */}
                {/*      label="Holder" */}
                {/*      name="holder" */}
                {/*      value={holder.name || ''} */}
                {/*      onClick={this.openSelectHolder} */}
                {/*      validators={[]} */}
                {/*    /> */}
                {/*    <Tooltip title="Clear Holder" placement="bottom"> */}
                {/*      <IconButton onClick={this.handleClearHolder}> */}
                {/*        <i className={classNames('material-icons', classes.icon)}>clear</i> */}
                {/*      </IconButton> */}
                {/*    </Tooltip> */}
                {/*  </div> */}
                {/* </Grid> */}
                {/* {credentialRequest.formId && ( */}
                {/* )} */}
                {/* </Grid> */}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton
                isSaving={isSaving}
                isDisabled={!this.isUploadingCompleted(documentUploads)}
                onSubmit={this.submitForm}
              />
            </Grid>
          </Grid>
          <Modal open={shouldDisplaySelectHolder} onClose={this.closeSelectHolder}>
            <Slide direction="left" in={shouldDisplaySelectHolder} mountOnEnter unmountOnExit>
              <Grid item xs={4} className={`${classes.modalContainer}`}>
                <HolderAssignment
                  closeSelectHolder={this.closeSelectHolder}
                  addHolder={this.handleSelectHolder}
                  heading="Holders"
                />
              </Grid>
            </Slide>
          </Modal>
        </Grid>
      </Grid>
    );
  }
}

TestWriteHolderCredentialView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  match: PropTypes.object.isRequired,
  form: PropTypes.object,
  credentialType: PropTypes.object,
  organisations: PropTypes.array,
  credentialTypes: PropTypes.array,
  isSaving: PropTypes.bool.isRequired,
  uploadDocuments: PropTypes.func,
  fileUploads: PropTypes.array,
  documentUploads: PropTypes.object,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'credentialRequest'),
  ...entitySelector(state, 'form'),
  ...entityArraySelector(state, 'credentialTypes'),
  ...entitySelector(state, 'credentialType'),
  ...documentUploadsSelector(state),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    uploadDocuments,
    getEntity,
    saveEntity
  }),
  withRouter
)(TestWriteHolderCredentialView);

import { PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { holderCredentialInitialState, userInitialState } from '../entityInitialStates';

export const listHolderRequest = () => {
  return {
    url: `${process.env.REACT_APP_LIST_HOLDER}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'holders'
  };
};

export const getHolderRequest = id => ({
  url: `${process.env.REACT_APP_GET_HOLDER}${id}`,
  entityName: 'user',
  initialState: userInitialState
});

export const disableHolderRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/disable`,
  entityName: 'user',
  action: 'disabled'
});

export const enableHolderRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/enable`,
  entityName: 'user',
  action: 'enabled'
});

export const resetHolderPasswordRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/resetPassword`,
  entityName: 'user',
  notification: "The user's password has been reset"
});

export const unlockHolderRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/unlock`,
  entityName: 'user',
  action: 'unlocked'
});

export const lockHolderRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/lock`,
  entityName: 'user',
  action: 'locked'
});

export const resetHolderLoginAttemptRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/resetLoginAttempts`,
  entityName: 'user',
  notification: 'The user login attempts has been reset'
});

export const saveHolderRequest = entity => ({ url: process.env.REACT_APP_HOLDER_SAVE, entityName: 'user', entity });

export const listHolderCredentialRequest = id => {
  return {
    url: `${process.env.REACT_APP_GET_HOLDER}${id}/credentials/list`,
    entityName: 'holderCredentials'
  };
};

export const getHolderCredentialRequest = (holderId, credentialId) => ({
  url: `${process.env.REACT_APP_GET_HOLDER}${holderId}/credential/${credentialId}`,
  entityName: 'holderCredential',
  initialState: holderCredentialInitialState
});

export const changeOrganisationRequest = key => ({
  url: `${process.env.REACT_APP_CHANGE_ORGANISATION}${key}`,
  entityName: 'user',
  notification: 'The organisation is being changed'
});

export const recreateCognitoHolderRequest = id => ({
  url: `${process.env.REACT_APP_LOCK_HOLDER}${id}/cognito`,
  entityName: 'holder',
  notification: 'The holder has been recreated'
});

export const listHolderRepRequest = holderId => {
  return {
    url: `${process.env.REACT_APP_LIST_HOLDER}rep/${holderId}/${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}`,
    entityName: 'reps'
  };
};

import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { convertLocalDateStringToUtcDateString } from '../utilities';

export const listAttendanceRequest = () => (dispatch, getState) => {
  if (getState().search.attendance) {
    const { filters } = getState().search.attendance;
    const workPlaceFilter = filters.workPlace || NO_SEARCH_VALUE;
    const datesFilter = filters.dates || { startDate: '', endDate: '' };
    const startDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.startDate);
    const endDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.endDate);

    return {
      url:
        `${process.env.REACT_APP_ATTENDANCE_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${workPlaceFilter}` +
        `?startDate=${startDate}&endDate=${endDate}`,
      entityName: 'attendance'
    };
  }
};

export const getAttendanceStatsRequest = (searchText = '') => (dispatch, getState) => {
  if (getState().search.attendance) {
    const { filters } = getState().search.attendance;
    const workPlaceFilter = filters.workPlace || NO_SEARCH_VALUE;
    const datesFilter = filters.dates || { startDate: '', endDate: '' };
    const startDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.startDate);
    const endDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.endDate);

    return {
      url:
        `${process.env.REACT_APP_ATTENDANCE_STATS}${workPlaceFilter}?searchText=${searchText}` +
        `&startDate=${startDate}&endDate=${endDate}`,
      entityName: 'attendanceStats'
    };
  }
};

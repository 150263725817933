import LoginView from '../components/authentication/LoginView';
import PasswordResetView from '../components/authentication/PasswordResetView';
import ForgotPasswordView from '../components/authentication/ForgotPasswordView';

const authenticationRoute = [
  {
    path: '/login',
    component: LoginView,
    exact: true,
    protected: false
  },
  {
    path: '/passwordReset/:userId/:codeParam',
    component: PasswordResetView,
    exact: true,
    protected: false
  },
  {
    path: '/passwordReset/:userId',
    component: PasswordResetView,
    exact: true,
    protected: false
  },
  {
    path: '/forgotPassword',
    component: ForgotPasswordView,
    exact: true,
    protected: false
  }
];

export default authenticationRoute;

import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import SectionHeading from '../common/SectionHeading';
import { CONTROL_TYPE } from '../../assets/constants';
import ReppleImageGallery from '../widgets/ReppleImageGallery';

const styles = {
  ...layout,
  ...style,
  section: {
    boxShadow: '0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
    padding: '0 40px 40px 40px',
    marginTop: '20px'
  },
  credentialRequestContainer: {
    marginRight: '4px'
  }
};

class DisplayCredential extends React.PureComponent {
  state = {
    galleryOpen: false,
    thumbnailIndex: 0
  };

  openGallery = index => {
    this.setState({
      galleryOpen: true,
      thumbnailIndex: index
    });
  };

  closeGallery = () => {
    this.setState({
      galleryOpen: false,
      thumbnailIndex: 0
    });
  };

  getControlValue = (control, values, fileDownloads) => {
    const { downloadFile, classes } = this.props;
    const { galleryOpen, thumbnailIndex } = this.state;

    if (values) {
      if (control.type === CONTROL_TYPE.DATE.id || control.type === CONTROL_TYPE.TEXT.id) {
        return values[0].value;
      }
      if (control.type === CONTROL_TYPE.MULTIPLE_CHOICE.id || control.type === CONTROL_TYPE.DROP_DOWN.id) {
        return values[0].name;
      }
      if (control.type === CONTROL_TYPE.FILE.id) {
        const images = [];
        const others = [];
        values.forEach(item => {
          const download = fileDownloads.find(download => download.identifier === item.value);

          if (download) {
            if (download.mimeType.startsWith('image')) {
              images.push({ original: download.url, name: download.name, thumbnail: download.thumbnailUrl });
            } else others.push(download);
          }
        });
        return (
          <div>
            {galleryOpen && (
              <ReppleImageGallery gallery={images} thumbNailIndex={thumbnailIndex} closeGallery={this.closeGallery} />
            )}
            {!galleryOpen && images.length > 0 && (
              <div className={classes.thumbnailContainer}>
                {images.map((image, index) => {
                  return (
                    <Tooltip title={image.name} placement="bottom" key={`image${image.thumbnail}`}>
                      <div onClick={() => this.openGallery(index)}>
                        <img src={image.thumbnail} alt="adam" className={classes.thumbnail} />
                      </div>
                    </Tooltip>
                  );
                })}
              </div>
            )}
            {others.map(fileDownload => {
              return (
                <div key={`downloadContainer${fileDownload.identifier}`}>
                  <div>
                    <IconButton
                      key={`download${fileDownload.identifier}`}
                      style={{ marginRight: '10px' }}
                      onClick={() => {
                        downloadFile(fileDownload.url, fileDownload.name, fileDownload.mimeType === 'application/pdf');
                      }}
                    >
                      <i className="material-icons">cloud_download</i>
                    </IconButton>
                    {fileDownload.name}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }
      return values.map(item => item.name).join();
    }
    return '';
  };

  renderControlValue = control => {
    const { classes, fileDownloads } = this.props;

    return (
      <div className={classes.displayLabelAndField} key={`control-${control.name}`}>
        <label className={classes.displayLabel}>{control.name}</label>
        <div className={classes.displayField}>
          {this.getControlValue(control, control.values, fileDownloads) || 'Not Set'}
        </div>
      </div>
    );
  };

  render() {
    const { classes, credential } = this.props;
    const { sections } = credential;

    return (
      <Grid container className={classes.credentialRequestContainer}>
        {sections.map((section, index) => (
          <Grid item xs={12} className={classes.section} key={`section-${index}`}>
            <div>
              <SectionHeading heading={section.sectionTitle} underlined />
              {section.controls.map(control => this.renderControlValue(control))}
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

DisplayCredential.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  sections: PropTypes.array,
  credential: PropTypes.object,
  downloadFile: PropTypes.func,
  fileDownloads: PropTypes.array
};

export default withStyles(styles)(DisplayCredential);

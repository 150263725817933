import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { colors, commonFont } from '../../assets/constants';
import { getUserInitials } from '../../utils/utilities';
import { style } from '../../assets/style';
import EmailIcon from '../../assets/images/email-icon-blue.svg';
import PhoneIcon from '../../assets/images/phone-icon-blue.svg';

const styles = {
  ...style,
  credentialProfile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avatar: {
    margin: '10px auto',
    fontSize: '30px',
    backgroundColor: 'rgba(136,135,135)',
    width: '90px',
    height: '90px'
  },
  holderName: {
    ...commonFont,
    fontSize: '20px',
    fontWeight: 600,
    color: colors.blueDark,
    marginTop: '5px'
  },
  contactInfo: {
    ...commonFont,
    fontSize: '14px',
    fontWeight: 'normal',
    color: colors.blue,
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'center',
    '& img': {
      marginRight: '8px'
    },
    width: '100%'
  }
};

const formatPhoneNumber = phone => {
  const phoneNumber = parsePhoneNumberFromString(phone);
  return phoneNumber.formatInternational();
};

const CredentialProfile = ({ classes, holder }) => {
  return (
    <div className={classNames(classes.credentialProfile)}>
      <Avatar className={classes.avatar}>{getUserInitials(holder.avatarUrl, holder.name)}</Avatar>
      <div className={classes.holderName}>{holder.name}</div>
      {holder.email && (
        <div className={classes.contactInfo}>
          <img src={EmailIcon} /> {holder.email}
        </div>
      )}
      {holder.phone && (
        <div className={classes.contactInfo}>
          <img src={PhoneIcon} /> {formatPhoneNumber(holder.phone)}
        </div>
      )}
    </div>
  );
};

CredentialProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  holder: PropTypes.object.isRequired
};

export default withStyles(styles)(CredentialProfile);

import { GLOBAL_ERROR, CLEAR_GLOBAL_ERROR } from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case GLOBAL_ERROR:
      return {
        ...state,
        exception:
          /* eslint-disable indent */
          action.payload === undefined
            ? { errorDescription: 'Unable to contact server.  Please check your connection', error: '' }
            : {
                errorDescription: action.payload.error_description,
                error: action.payload.error,
                errorTitle: action.payload.errorTitle
              }
        /* eslint-enable indent */
      };
    case CLEAR_GLOBAL_ERROR: {
      return initialState;
    }
    default:
      return state;
  }
}

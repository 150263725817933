import { NO_SEARCH_VALUE, PAGE_SIZE_TOKEN, PAGE_TOKEN } from '../../constants/global';
import { convertLocalDateStringToUtcDateString } from '../utilities';
import { incidentInitialState } from '../entityInitialStates';

export const listIncidentRequest = () => (dispatch, getState) => {
  if (getState().search.incidents) {
    const { filters } = getState().search.incidents;
    const workPlaceFilter = filters.workPlace || NO_SEARCH_VALUE;
    const datesFilter = filters.dates || { startDate: '', endDate: '' };
    const startDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.startDate);
    const endDate = convertLocalDateStringToUtcDateString('DD-MM-YYYY HH:mm:ss', datesFilter.endDate);

    return {
      /* eslint-disable max-len */
      url: `${process.env.REACT_APP_INCIDENT_LIST}${PAGE_TOKEN}/${PAGE_SIZE_TOKEN}/${workPlaceFilter}?startDate=${startDate}&endDate=${endDate}`,
      entityName: 'incidents'
    };
  }
};

export const getIncidentRequest = id => ({
  url: `${process.env.REACT_APP_INCIDENT_GET}${id}`,
  entityName: 'incident',
  initialState: incidentInitialState
});

export const saveIncidentRequest = entity => ({
  url: process.env.REACT_APP_INCIDENT_SAVE,
  entityName: 'Incident',
  entity
});

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getEmailsUrl } from '../../utils/searchUrls';
import { capitalise } from '../../utils/string';
import { convertUtcToLocal, getRecords } from '../../utils/utilities';
import { tableSelector } from '../../store/selectors/table';
import { listEmailRequest } from '../../utils/entityRequests/emailRequests';
import NewHeaderActions from '../header/NewHeaderActions';

const styles = {
  ...style
};

class EmailListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/admin/email/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(email => ({
      clickAction: () => this.actionNavigate(email.identifier),
      cells: [
        { value: email.name },
        { value: email.subject },
        { value: email.toAddress },
        { value: convertUtcToLocal('DD-MM-YYYY HH:mm:ss', 'DD-MM-YYYY HH:mm:ss', email.dispatchTime) },
        { value: capitalise(email.status) }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[
          { value: 'Name' },
          { value: 'Subject' },
          { value: 'To' },
          { value: 'Dispatch Time' },
          { value: 'Status' }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No emails Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, listEntities, table } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions header="Email" buttons={[]} />

            <ListingWithSearch
              name="emails"
              getSearchUrl={getEmailsUrl}
              onList={listEntities}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listEmailRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

EmailListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-emails'),
  ...tableSelector(state, 'emails')
});

export default compose(withStyles(styles), connect(mapStateToProps, { listEntities }))(EmailListView);

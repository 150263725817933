import EnrolCatalogCourseView from '../components/training/catalog/EnrolCatalogCourseView';
import GenericHeadingOnlyBreadcrumb from '../components/breadcrumbs/GenericHeadingOnlyBreadcrumb';
import CatalogCourseView from '../components/training/catalog/CatalogCourseView';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';
import CatalogCourseListView from '../components/training/catalog/CatalogCourseListView';
import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';

export default [
  {
    path: '/catalog/courseCatalog/view/:id/enrol/:scheduleCourseId',
    component: EnrolCatalogCourseView,
    breadcrumb: GenericHeadingOnlyBreadcrumb,
    protected: true,
    subText: '&nbsp;',
    heading: 'Enrolment'
  },
  {
    path: '/catalog/courseCatalog/view/:id',
    component: CatalogCourseView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'course',
    itemName: 'title',
    heading: 'Course'
  },
  {
    path: '/catalog/courseCatalog',
    component: CatalogCourseListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'catalog',
    functionName: 'listEntities',
    heading: 'Catalog'
  }
];

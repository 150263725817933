/* eslint-disable react/no-danger */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { IconButton, Tooltip } from '@material-ui/core';
import { getEntity, putEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import {
  deleteCourseRequest,
  deprecateCourseRequest,
  getCourseRequest,
  publishCourseRequest
} from '../../utils/entityRequests/trainingRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import SectionHeading from '../common/SectionHeading';
import { entitySelector } from '../../store/selectors/entity';
import CourseDisplay from './catalog/CourseDisplay';
import { COURSE_STATUS_TYPE, PERIOD_TYPE } from '../../assets/constants';
import ScrollableTable from '../table/ScrollableTable';

const styles = {
  ...style,
  borderTop: {
    borderTop: 'solid 1px #e0e0e0'
  },
  editorContent: {
    lineHeight: '3px'
  }
};

class CourseView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getCourseRequest(id));
  }

  publishCourse = () => {
    const { match, getEntity, putEntity } = this.props;
    const { params } = match;
    const { id } = params;

    putEntity(publishCourseRequest(id)).then(() => getEntity(getCourseRequest(id)));
  };

  deprecateCourse = () => {
    const { match, getEntity, course, history, putEntity } = this.props;
    const { params } = match;
    const { id } = params;

    if (course.courseStatusType === COURSE_STATUS_TYPE.DRAFT.value) {
      putEntity(deleteCourseRequest(id)).then(() => history.push('/training/course/list'));
    } else {
      putEntity(deprecateCourseRequest(id)).then(() => getEntity(getCourseRequest(id)));
    }
  };

  getRowConfigs = () => {
    const { course } = this.props;

    const items = course.courseSessions || [];
    return items.map(courseSession => ({
      cells: [
        {
          value: courseSession.duration
        },
        {
          value: PERIOD_TYPE[courseSession.periodType].label
        }
      ]
    }));
  };

  render() {
    const { match, classes, course, isLoading } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(course).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, classes.flexNowrap, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={
              course.courseStatusType !== COURSE_STATUS_TYPE.DEPRECATED.value ? `/training/course/edit/${id}` : ''
            }
            header="Course Details"
            cancelUrl="/training/course"
          >
            {(course.courseStatusType === COURSE_STATUS_TYPE.DRAFT.value ||
              course.courseStatusType === COURSE_STATUS_TYPE.DEPRECATED.value) && (
              <Tooltip title="Publish Course" aria-label="edit" placement="bottom">
                <IconButton onClick={this.publishCourse}>
                  <i className="material-icons">refresh</i>
                </IconButton>
              </Tooltip>
            )}
            {(course.courseStatusType === COURSE_STATUS_TYPE.PUBLISHED.value ||
              course.courseStatusType === COURSE_STATUS_TYPE.DRAFT.value) && (
              <Tooltip
                title={
                  course.courseStatusType === COURSE_STATUS_TYPE.DRAFT.value ? 'Delete Course' : 'Deprecate Course'
                }
                aria-label="edit"
                placement="bottom"
              >
                <IconButton onClick={this.deprecateCourse}>
                  <i className="material-icons">delete</i>
                </IconButton>
              </Tooltip>
            )}
          </NewHeaderViewActions>
          <Grid item xs={12}>
            <CourseDisplay course={course} />
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Description</label>
              <div className={classes.editorContent} dangerouslySetInnerHTML={{ __html: course.description }} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <SectionHeading heading="Training Sessions" />
            <div className={classes.displayLabelAndField}>
              <div>
                <ScrollableTable
                  headers={[{ value: 'Duration' }, { value: 'Period Type' }]}
                  rowConfigs={this.getRowConfigs()}
                  complete={!isLoading}
                  emptyMessage="No training sessions Found"
                />
              </div>
            </div>
          </Grid>
        </Grid>
      )
    );
  }
}

CourseView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  match: PropTypes.object,
  course: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'course')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity, putEntity }))(CourseView);

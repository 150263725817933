import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { colors, commonFont, STANDARD_CREDENTIAL_TYPE } from '../../assets/constants';
import { getUserInitials, getCredentialStatusColoredIcon } from '../../utils/utilities';
import { style } from '../../assets/style';

const styles = {
  ...style,
  credentialCard: {
    minWidth: '299px',
    maxWidth: '299px',
    width: '299px',
    height: '184px',
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    borderRadius: '5px'
  },
  credentialHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  holderDetails: {
    display: 'flex',
    flexDirection: 'row',
    '& > div:first-of-type': {
      marginRight: '16px'
    },
    alignItems: 'center'
  },
  statusAndName: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  credentialTimeline: {
    display: 'flex',
    flexDirection: 'row',
    color: colors.white,
    alignItems: 'center',
    ...commonFont,
    fontWeight: 600,
    fontSize: '10px',
    '& > div:first-of-type': {
      width: '60px',
      marginRight: '13px'
    },
    '& > div:last-of-type': {
      width: '60px',
      marginLeft: '13px'
    }
  },
  bar: {
    height: '6px',
    backgroundColor: colors.white,
    width: '100%',
    borderRadius: '6px'
  },
  progress: {
    height: '6px',
    backgroundColor: colors.lightGreen,
    borderRadius: '6px'
  },
  status: {
    color: colors.white,
    ...commonFont,
    borderRadius: '3px',
    fontSize: '9px',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '3px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    marginBottom: '4px',
    '& div': {
      marginLeft: '6px'
    },
    width: '80px',
    '& img': {
      width: '14px',
      height: '14px'
    }
  },
  pending: {
    backgroundColor: colors.orangeBackground
  },
  rejected: {
    backgroundColor: colors.blueDark
  },
  revoked: {
    backgroundColor: colors.blueDark
  },
  expired: {
    backgroundColor: '#F9C0C3'
  },
  verified: {
    backgroundColor: colors.lightGreen,
    color: colors.white
  },
  credentialName: {
    color: colors.white,
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '12px',
    marginTop: '9px',
    marginBottom: '15px'
  },
  lineSpacer: {
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    margin: '21px -15px 12px -15px'
  },
  avatar: {
    // margin: '10px auto',
    fontSize: '13px',
    backgroundColor: 'rgba(136,135,135)',
    width: '40px',
    height: '40px'
  },
  name: {
    color: colors.white,
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '12px'
  },
  label: {
    color: '#F9F9F9',
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '10px',
    opacity: 0.5,
    marginRight: '6px'
  },
  value: {
    color: colors.white,
    ...commonFont,
    fontWeight: 'normal',
    fontSize: '10px',
    marginRight: '12px'
  },
  marginTop: {
    marginTop: '3px'
  },
  date: {
    width: '70px !important'
  }
};

const getWidth = (issueDate, expiryDate) => {
  if (!expiryDate) return 0;

  return Math.round(
    100 / moment.duration(moment(expiryDate, 'DD-MM-YYYY').diff(moment(issueDate, 'DD-MM-YYYY'))).asHours()
  );
};

const getBackgroundStyle = (backgroundColor, backgroundImageUrl) => {
  if (backgroundImageUrl) return { backgroundImage: `url('${backgroundImageUrl}')` };
  return { backgroundColor };
};

const getTextStyle = (backgroundColor, backgroundImageUrl) => {
  if (backgroundImageUrl) return { color: 'white' };
  if (!backgroundColor) return { color: 'white' };
  const hexCode = backgroundColor.charAt(0) === '#' ? backgroundColor.substr(1, 6) : backgroundColor;

  const hexR = parseInt(hexCode.substr(0, 2), 16);
  const hexG = parseInt(hexCode.substr(2, 2), 16);
  const hexB = parseInt(hexCode.substr(4, 2), 16);
  // Gets the average value of the colors
  const contrastRatio = (hexR + hexG + hexB) / (255 * 3);

  return contrastRatio < 0.5 ? { color: 'white' } : { color: 'black' };
};

const CredentialCard = ({
  classes,
  holderName,
  status,
  credentialName,
  credentialId,
  issueDate,
  expiryDate,
  backgroundImage,
  logoImage,
  backgroundColor,
  type,
  standardCredentialType,
  startYear,
  endYear,
  fieldOfStudy
}) => {
  return (
    <div className={classNames(classes.credentialCard)} style={getBackgroundStyle(backgroundColor, backgroundImage)}>
      <div className={classes.credentialHeader}>
        <div className={classes.credentialImage}>{logoImage && <img src={logoImage} height={50} />}</div>
        <div className={classes.statusAndName}>
          <div
            className={classNames(
              classes.status,
              { [classes.pending]: status === 'PENDING' },
              { [classes.rejected]: status === 'REJECTED' },
              { [classes.verified]: status === 'VERIFIED' },
              { [classes.revoked]: status === 'REVOKED' },
              { [classes.expired]: status === 'EXPIRED' }
            )}
          >
            <img src={getCredentialStatusColoredIcon(status)} />
            <div
              className={classNames(
                { [classes.pending]: status === 'PENDING' },
                { [classes.rejected]: status === 'REJECTED' },
                { [classes.verified]: status === 'VERIFIED' },
                { [classes.revoked]: status === 'REVOKED' },
                { [classes.expired]: status === 'EXPIRED' }
              )}
            >
              {status}
            </div>
          </div>
          <div className={classes.credentialName} style={getTextStyle(backgroundColor, backgroundImage)}>
            {credentialName}
          </div>
        </div>
      </div>
      {standardCredentialType !== STANDARD_CREDENTIAL_TYPE.EDUCATION.id && (
        <div className={classes.credentialTimeline} style={getTextStyle(backgroundColor, backgroundImage)}>
          <div className={classes.date}>{moment(issueDate, 'DD-MM-YYYY').format('MMM YYYY')}</div>
          <div className={classes.bar}>
            <div className={classes.progress} style={{ width: `${getWidth(issueDate, expiryDate)}%` }} />
          </div>
          <div className={classes.date}>
            {expiryDate ? moment(expiryDate, 'DD-MM-YYYY').format('MMM YYYY') : 'Never'}
          </div>
        </div>
      )}
      {standardCredentialType === STANDARD_CREDENTIAL_TYPE.EDUCATION.id && (
        <div className={classes.credentialTimeline} style={getTextStyle(backgroundColor, backgroundImage)}>
          <div className={classes.date}>{startYear}</div>
          <div className={classes.bar}>
            <div className={classes.progress} style={{ width: `${getWidth(issueDate, expiryDate)}%` }} />
          </div>
          <div className={classes.date}>{endYear}</div>
        </div>
      )}
      <div className={classes.lineSpacer} />
      <div className={classes.holderDetails}>
        <div>
          <Avatar className={classes.avatar}>{getUserInitials(null, holderName)}</Avatar>
        </div>
        <div className={classes.columnDirection} style={getTextStyle(backgroundColor, backgroundImage)}>
          <div className={classes.name} style={getTextStyle(backgroundColor, backgroundImage)}>
            {holderName}
          </div>
          <div className={classNames(classes.rowDirection, classes.marginTop)}>
            {credentialId && (
              <Fragment>
                <div className={classes.label} style={getTextStyle(backgroundColor, backgroundImage)}>
                  ID:
                </div>
                <div className={classes.value} style={getTextStyle(backgroundColor, backgroundImage)}>
                  {credentialId}
                </div>
              </Fragment>
            )}
            {type && (
              <Fragment>
                <div className={classes.label} style={getTextStyle(backgroundColor, backgroundImage)}>
                  TYPE:
                </div>
                <div className={classes.value} style={getTextStyle(backgroundColor, backgroundImage)}>
                  {type}
                </div>
              </Fragment>
            )}
            {fieldOfStudy && (
              <Fragment>
                <div className={classes.label} style={getTextStyle(backgroundColor, backgroundImage)}>
                  FIELD:
                </div>
                <div className={classes.value} style={getTextStyle(backgroundColor, backgroundImage)}>
                  {fieldOfStudy}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CredentialCard.propTypes = {
  classes: PropTypes.object.isRequired,
  issueDate: PropTypes.string,
  expiryDate: PropTypes.string,
  startYear: PropTypes.string,
  endYear: PropTypes.string,
  status: PropTypes.string.isRequired,
  credentialName: PropTypes.string,
  credentialId: PropTypes.string,
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.string,
  fieldOfStudy: PropTypes.string,
  holderName: PropTypes.string.isRequired,
  standardCredentialType: PropTypes.string.isRequired
};

export default withStyles(styles)(CredentialCard);

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { withStyles } from '@material-ui/core/styles';
import { MuiThemeProvider } from '@material-ui/core';
import { clearGlobalError } from '../../store/actions/GlobalErrorActions';
import { customTheme } from '../../assets/constants';
import { globalErrorSelector } from '../../store/selectors/shared';

const styles = () => ({});

class ErrorDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  static getDerivedStateFromProps(props, prevProps) {
    const { exception: previousException } = prevProps;
    const { exception } = props;
    if (exception && (!previousException || previousException !== exception)) {
      return { open: true };
    }

    return props;
  }

  handleClose = () => {
    const { clearGlobalError, redirectUrl, history } = this.props;

    this.setState({
      open: false
    });

    clearGlobalError();

    if (redirectUrl) {
      history.push(redirectUrl);
    }
  };

  render() {
    const { open } = this.state;
    const { exception } = this.props;

    return (
      <div>
        <MuiThemeProvider theme={customTheme}>
          <Dialog
            fullScreen={false}
            open={open}
            disableBackdropClick
            onClose={this.handleClose}
            aria-labelledby="responsive-dialog-title"
          >
            {exception && !exception.errorTitle && (
              <DialogTitle id="responsive-dialog-title">An Error has Occurred</DialogTitle>
            )}
            {exception && exception.errorTitle && (
              <DialogTitle id="responsive-dialog-title">{exception.errorTitle}</DialogTitle>
            )}
            <DialogContent>
              <DialogContentText>
                {exception && exception.errorDescription}
                {exception && exception.message}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.handleClose();
                }}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </MuiThemeProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  ...globalErrorSelector(state)
});

ErrorDialog.propTypes = {
  exception: PropTypes.object,
  redirectUrl: PropTypes.string,
  history: PropTypes.object,
  clearGlobalError: PropTypes.func
};

export default compose(connect(mapStateToProps, { clearGlobalError }), withRouter, withStyles(styles))(ErrorDialog);

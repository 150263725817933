import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { CircularProgress, Grid, MenuItem, MuiThemeProvider, Paper } from '@material-ui/core';
// import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import PlacesAutocomplete, { geocodeByPlaceId, getLatLng } from 'react-places-autocomplete';
import NewHeaderEditActions from '../../header/NewHeaderEditActions';
import { getEntity, saveEntity } from '../../../store/actions/EntityActions';
import { loadingSelector } from '../../../store/selectors/shared';
import { isEdit } from '../../../utils/utilities';
import { style } from '../../../assets/style';
import layout from '../../../assets/layout';
import { VALIDATORS_BY_NAME, customTheme } from '../../../assets/constants';
import ValidatedField from '../../widgets/ValidatedField';
import { entityWithErrorsSelector } from '../../../store/selectors/entity';
import { centreInitialState } from '../../../utils/entityInitialStates';
import { getCentreRequest, saveCentreRequest } from '../../../utils/entityRequests/trainingRequests';
import SaveButton from '../../widgets/SaveButton';
import NewValidatedField from "../../widgets/NewValidatedField";

const styles = () => ({
  ...style,
  ...layout
});

class ChangeCentreView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centre: centreInitialState
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/training/centre/view/${id}` : '/training/centre';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const centre = await getEntity(getCentreRequest(match.params.id));

      this.setState({ centre });
    } else {
      this.setState({
        centre: centreInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      centre: centreInitialState
    });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { centre } = this.state;

    const centreResult = _.cloneDeep(centre);
    _.set(centreResult, name, value);

    this.setState({
      centre: centreResult
    });
  };

  // handleSelect = async (address, place) => {
  //   const { centre } = this.state;
  //
  //   const result = await geocodeByPlaceId(place);
  //   const latLong = await getLatLng(result[0]);
  //
  //   const centreResult = _.cloneDeep(centre);
  //   centreResult.address = address;
  //   centreResult.position = { latitude: latLong.lat, longitude: latLong.lng };
  //
  //   this.setState({
  //     centre: centreResult
  //   });
  // };
  //
  // handleAddressChange = address => {
  //   this.setState(prevState => ({
  //     ...prevState,
  //     centre: {
  //       ...prevState.centre,
  //       address,
  //       position: {
  //         latitude: '',
  //         longitude: ''
  //       }
  //     }
  //   }));
  // };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { centre } = this.state;

    const centreId = await saveEntity(saveCentreRequest(centre));

    if (!centreId) return;

    history.push(`/training/centre/view/${centreId}`);
  };

  render() {
    const { classes, isSaving, errors } = this.props;
    const { centre } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={classNames(classes.viewPage, classes.page)} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header="Centre Details"
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={centre.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        id="address"
                        label="Address"
                        value={centre.address}
                        name="address"
                        onChange={this.handleChange}
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />

                      {/*<PlacesAutocomplete*/}
                      {/*  value={centre.address}*/}
                      {/*  onChange={this.handleAddressChange}*/}
                      {/*  onSelect={this.handleSelect}*/}
                      {/*  debounce={1000}*/}
                      {/*>*/}
                      {/*  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (*/}
                      {/*    <div>*/}
                      {/*      <ValidatedField*/}
                      {/*        id="address"*/}
                      {/*        label="Address"*/}
                      {/*        value={centre.address}*/}
                      {/*        name="address"*/}
                      {/*        validators={[VALIDATORS_BY_NAME.REQUIRED]}*/}
                      {/*        className="location-search-input"*/}
                      {/*        inputTextProps={{ ...getInputProps({}) }}*/}
                      {/*        errorText={errors && errors.fieldErrors.position}*/}
                      {/*      />*/}
                      {/*      <div>*/}
                      {/*        {loading && (*/}
                      {/*          <MuiThemeProvider theme={customTheme}>*/}
                      {/*            <CircularProgress size={12} />*/}
                      {/*          </MuiThemeProvider>*/}
                      {/*        )}*/}
                      {/*        <Paper square>*/}
                      {/*          {suggestions.map(suggestion => {*/}
                      {/*            return (*/}
                      {/*              <MenuItem*/}
                      {/*                selected={suggestion.active}*/}
                      {/*                component="div"*/}
                      {/*                onClick={this.handleSelect}*/}
                      {/*                key={suggestion.placeId}*/}
                      {/*                {...getSuggestionItemProps(suggestion)}*/}
                      {/*              >*/}
                      {/*                {suggestion.description}*/}
                      {/*              </MenuItem>*/}
                      {/*            );*/}
                      {/*          })}*/}
                      {/*        </Paper>*/}
                      {/*      </div>*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</PlacesAutocomplete>*/}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeCentreView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'centre'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeCentreView);

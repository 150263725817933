import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { style } from '../../assets/style';
import form from '../../assets/formStyles';
import layout from '../../assets/layout';
import { CONTROL_TYPE, VALIDATORS_BY_NAME } from '../../assets/constants';
import ValidatedField from '../widgets/ValidatedField';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import NewValidatedField from '../widgets/NewValidatedField';
import NewSectionHeading from '../common/NewSectionHeading';

const styles = {
  ...layout,
  ...style,
  ...form,
  section: {
    marginBottom: '20px'
  }
};

class DisplayAdditionalInformation extends React.PureComponent {
  renderControl = control => {
    switch (control.type) {
      case CONTROL_TYPE.TEXT.id:
        return this.renderTextBox(control);
      case CONTROL_TYPE.DROP_DOWN.id:
        return this.renderDropDown(control);
      case CONTROL_TYPE.MULTIPLE_CHOICE.id:
        return this.renderMultipleChoice(control);
      case CONTROL_TYPE.CHECKBOXES.id:
        return this.renderCheckBoxes(control);
      case CONTROL_TYPE.DATE.id:
        return this.renderDate(control);
      default:
        return null;
    }
  };

  renderTextBox = control => {
    const { credential, onChange } = this.props;
    const validators = [];

    if (control.required) validators.push(VALIDATORS_BY_NAME.REQUIRED);
    const value = credential.controls.find(item => item.identifier === control.identifier);

    return (
      <NewValidatedField
        key={`control-${control.identifier}`}
        label={control.label}
        value={(value && value.values && value.values[0].value) || ''}
        validators={validators}
        id={control.identifier}
        onChange={onChange}
      />
    );
  };

  renderDropDown = control => {
    const { credential, onChange } = this.props;
    const validators = [];

    if (control.required) validators.push(VALIDATORS_BY_NAME.REQUIRED);
    const value = credential.controls.find(item => item.identifier === control.identifier);
    const valueString = (value && value.values && value.values[0].value) || '';
    const selectedValue = control.selectOptions.find(item => item.identifier === valueString) || null;
    return (
      <NewValidatedField
        key={`control-${control.identifier}`}
        type={VALIDATED_FIELD_TYPES.SELECT}
        label={control.label}
        items={control.selectOptions}
        id={control.identifier}
        itemName="name"
        itemValue="identifier"
        validators={validators}
        name={control.identifier}
        onChange={onChange}
        value={selectedValue}
      />
    );
  };

  renderMultipleChoice = control => {
    const { classes, credential, onChange } = this.props;
    const validators = [];

    if (control.required) validators.push(VALIDATORS_BY_NAME.REQUIRED);
    const value = credential.controls.find(item => item.identifier === control.identifier);

    return (
      <ValidatedField
        key={`control-${control.identifier}`}
        type={VALIDATED_FIELD_TYPES.RADIO_GROUP}
        label={control.label}
        values={control.selectOptions}
        overrideClassName={classNames(classes.fullWidth, classes.radioControl)}
        id={control.identifier}
        optionName="name"
        optionValue="identifier"
        validators={validators}
        name={control.identifier}
        value={(value && value.values && value.values[0].value) || ''}
        onChange={onChange}
      />
    );
  };

  renderCheckBoxes = control => {
    const { classes, onCheckboxChange, credential } = this.props;
    const validators = [];

    if (control.required) validators.push(VALIDATORS_BY_NAME.REQUIRED);
    const value = credential.controls.find(item => item.identifier === control.identifier);
    const values = (value && value.values.map(item => item.value)) || [];

    const options = control.selectOptions.map(item => {
      return {
        label: item.name,
        name: control.identifier,
        checked: values.indexOf(item.identifier) !== -1,
        value: item.identifier
      };
    });

    return (
      <NewValidatedField
        key={`control-${control.identifier}`}
        type={VALIDATED_FIELD_TYPES.CHECKBOXES}
        label={control.label}
        overrideClassName={classes.fullWidth}
        id={control.identifier}
        name={control.identifier}
        validators={validators}
        onChange={onCheckboxChange}
        options={options}
      />
    );
  };

  renderDate = control => {
    const { onChange, credential } = this.props;
    const validators = [];
    if (control.required) validators.push(VALIDATORS_BY_NAME.REQUIRED);

    const value = credential.controls.find(item => item.identifier === control.identifier);

    return (
      <NewValidatedField
        key={`control-${control.identifier}`}
        type={VALIDATED_FIELD_TYPES.DATE}
        label={control.label}
        name={control.identifier}
        value={(value && value.values && value.values[0].value) || ''}
        id={control.identifier}
        validators={validators}
        onChange={onChange}
      />
    );
  };

  render() {
    const { sections, classes } = this.props;

    return (
      <Grid container>
        {sections.map((section, index) => (
          <Grid item xs={12} className={classes.section} key={`section-${index}`}>
            <div>
              <NewSectionHeading heading={section.title} />
              {section.controls.map(control => this.renderControl(control))}
            </div>
          </Grid>
        ))}
      </Grid>
    );
  }
}

DisplayAdditionalInformation.defaultProps = {
  onChange: () => {}
};

DisplayAdditionalInformation.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  sections: PropTypes.array,
  credential: PropTypes.object,
  onChange: PropTypes.func,
  onCheckboxChange: PropTypes.func
};

export default withStyles(styles)(DisplayAdditionalInformation);

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import { Button } from '@material-ui/core';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getUsersUrl } from '../../utils/searchUrls';
import ScrollableTable from '../table/ScrollableTable';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { listUserRequest } from '../../utils/entityRequests/userAdminRequests';

const styles = () => ({
  ...style,
  textField: {
    width: '100%'
  },
  secondaryHeading: {
    marginTop: '20px',
    borderBottom: 'none !important'
  },
  searchContainer: {
    padding: '10px',
    border: 'none'
  },
  scrollTableContainer: {
    overflowY: 'auto'
  },
  sliderBody: {
    ...layout.sliderBody,
    padding: 0
  }
});

class UserAssignment extends React.Component {
  componentDidMount() {
    const { listEntities } = this.props;
    listEntities(0, 20, listUserRequest());
  }

  getRowConfigs = () => {
    const { search, table, classes, addUser } = this.props;
    const items = getRecords(search, table);
    return items.map(user => ({
      clickAction: () => addUser({ identifier: user.identifier, name: `${user.firstName} ${user.lastName}` }),
      cells: [
        {
          value: (
            <div>
              <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar}>
                  {user.firstName.charAt(0).toUpperCase() + user.lastName.charAt(0).toUpperCase()}
                </Avatar>
              </div>
              {`${user.firstName} ${user.lastName}`}
            </div>
          )
        },
        { value: user.email, hiddenBelow: 'md' }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Name' }, { value: 'Email' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Users Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, closeSelectUser, table, heading } = this.props;

    return (
      <div className={classes.overlayContainer}>
        <Grid container justify="space-between" className={classes.overLayHeadingActionContainer}>
          <Grid container alignItems="center" item xs={6}>
            <div>
              <div className={classes.pageHeading}>{heading}</div>
            </div>
          </Grid>
          <Grid container item xs={6} justify="flex-end" direction="row" alignItems="center">
            <Button onClick={closeSelectUser} className={classNames(classes.cancel)} data-qa="cancel">
              Close
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.formPage, classes.sliderBody)}>
          <Grid container item xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <ListingWithSearch
                name="users"
                getSearchUrl={getUsersUrl}
                onList={listEntities}
                // In case of multiple filters this would accept event and name params
                // based on which it would determine filterValue
                renderList={props => this.getListing(props)}
                list={table}
                additionalSearchParams={listUserRequest}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

UserAssignment.propTypes = {
  classes: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  closeSelectUser: PropTypes.func,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-users'),
  ...tableSelector(state, 'users')
});

export default withStyles(styles)(connect(mapStateToProps, { listEntities })(UserAssignment));

// Common
import GenericHeadingOnlyBreadcrumb from '../components/breadcrumbs/GenericHeadingOnlyBreadcrumb';
import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';

// Catalog
import CourseListView from '../components/training/CourseListView';
import CourseView from '../components/training/CourseView';
import ChangeCourseView from '../components/training/ChangeCourseView';

// Training Centres
import CentreListView from '../components/training/centre/CentreListView';
import ChangeCentreView from '../components/training/centre/ChangeCentreView';
import ChangeRoomView from '../components/training/centre/ChangeRoomView';
import CentreView from '../components/training/centre/CentreView';
import RoomView from '../components/training/centre/RoomView';

// Scheduled Courses
import ScheduledCourseListView from '../components/training/schedule/ScheduledCourseListView';
import ScheduledCourseView from '../components/training/schedule/ScheduledCourseView';
import SchedulingView from '../components/training/schedule/SchedulingView';

export default [
  // course routes
  {
    path: '/training/course/edit/:id',
    component: ChangeCourseView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'course',
    itemName: 'title',
    heading: 'Course'
  },
  { path: '/training/course/create', component: ChangeCourseView, breadcrumb: null, protected: true },
  {
    path: '/training/course/view/:id',
    component: CourseView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'course',
    itemName: 'title',
    heading: 'Course'
  },
  {
    path: '/training/course',
    component: CourseListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'courses',
    functionName: 'listEntities',
    heading: 'Course'
  },

  // training centre routes
  {
    path: '/training/centre/view/:id/room/edit/:roomId',
    component: ChangeRoomView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'room',
    itemName: 'name',
    heading: 'Room'
  },
  { path: '/training/centre/view/:id/room/create', component: ChangeRoomView, breadcrumb: null, protected: true },
  {
    path: '/training/centre/view/:id/room/view/:roomId',
    component: RoomView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'room',
    itemName: 'name',
    heading: 'Room'
  },
  {
    path: '/training/centre/edit/:id',
    component: ChangeCentreView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'centre',
    itemName: 'name',
    heading: 'Centre'
  },
  { path: '/training/centre/create', component: ChangeCentreView, breadcrumb: null, protected: true },
  {
    path: '/training/centre/view/:id',
    component: CentreView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'centre',
    itemName: 'name',
    heading: 'Centre'
  },
  {
    path: '/training/centre',
    component: CentreListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'centres',
    functionName: 'listEntities',
    heading: 'Centre'
  },

  // Scheduling
  {
    path: '/training/scheduling',
    component: SchedulingView,
    breadcrumb: GenericHeadingOnlyBreadcrumb,
    protected: true,
    heading: 'Scheduling',
    subText: '&nbsp;'
  },

  // Scheduled Course Routes
  {
    path: '/training/scheduledCourse/view/:id',
    component: ScheduledCourseView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'scheduledCourse',
    itemName: 'courseTitle',
    heading: 'Scheduled Course'
  },
  {
    path: '/training/scheduledCourse',
    component: ScheduledCourseListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'scheduleCourses',
    functionName: 'listEntities',
    heading: 'Scheduled Course'
  }
];

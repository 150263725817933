import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import { colors, globalSpacing, commonFont } from '../../assets/constants';
import { flexRowCentered } from '../../assets/mixins';

const style = {
  heading: {
    ...commonFont,
    fontSize: '18px',
    fontWeight: 600,
    color: colors.blueDark
  },
  actions: {
    height: '48px',
    ...flexRowCentered,
    // position: 'absolute',
    alignItems: 'center',
    right: 0,
    bottom: 0,
    '& button': { padding: `${globalSpacing / 4}px` }
  },
  headingContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  underline: {
    height: '4px',
    width: '38px',
    backgroundColor: colors.orangeNew,
    marginTop: '10px',
    marginBottom: '18px',
    borderRadius: '10px'
  },
  headingRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
};

const NewSectionHeading = ({ classes, className, heading, children, ...rest }) => (
  <div className={classes.headingRow}>
    <div className={classNames(classes.headingContainer, className)} {...rest}>
      <div className={classes.heading}>{heading}</div>
      <div className={classes.underline} />
    </div>
    <div className={classes.actions}>{children}</div>
  </div>
);

NewSectionHeading.defaultProps = {
  className: null
};

NewSectionHeading.propTypes = {
  classes: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string
};

export default withStyles(style)(NewSectionHeading);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getObservationUrl } from '../../utils/searchUrls';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { OBSERVATION_STATUS_TYPE } from '../../assets/constants';
import { listObservationRequest } from '../../utils/entityRequests/workPlaceRequests';
import NewHeaderActions from '../header/NewHeaderActions';
import { ReactComponent as AddWhiteIcon } from '../../assets/images/new-white-icon.svg';

const styles = {
  ...style
};

class ObservationListView extends React.Component {
  actionNavigate = observationId => {
    const { history, match } = this.props;
    const { params } = match;
    const { id } = params;

    history.push(`/workplace/location/view/${id}/observation/view/${observationId}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(observation => ({
      clickAction: () => this.actionNavigate(observation.identifier),
      cells: [
        { value: observation.title },
        { value: observation.read ? <i className="material-icons">done</i> : <i className="material-icons">clear</i> },
        { value: OBSERVATION_STATUS_TYPE[observation.status].label }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Title' }, { value: 'Read' }, { value: 'Status' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Observations Found"
        {...props}
      />
    );
  };

  listObservations = async (page, pageSize, additionalSearchParam) => {
    const { listEntities } = this.props;
    await listEntities(0, 20, additionalSearchParam.request(additionalSearchParam.id));
  };

  render() {
    const { classes, table, match } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions
              header="Observation"
              buttons={[
                {
                  text: 'Add Observation',
                  icon: <AddWhiteIcon />,
                  url: `/workplace/location/view/${id}/observation/create`,
                  primary: true
                }
              ]}
            />
            <ListingWithSearch
              name="observations"
              getSearchUrl={getObservationUrl}
              onList={this.listObservations}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={{ id, request: listObservationRequest }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

ObservationListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-observations'),
  ...tableSelector(state, 'observations')
});

export default compose(withStyles(styles), connect(mapStateToProps, { listEntities }))(ObservationListView);

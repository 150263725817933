import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import { listCourseRequest } from '../../utils/entityRequests/trainingRequests';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { tableSelector } from '../../store/selectors/table';
import ScrollableTable from '../table/ScrollableTable';
import { COURSE_STATUS_TYPE, LOCATION_TYPE } from '../../assets/constants';
import { getRecords } from '../../utils/utilities';
import { NO_SEARCH_VALUE } from '../../constants/global';
import { getSearchCoursesUrl } from '../../utils/searchUrls';

const styles = {
  ...style,
  borderTop: {
    borderTop: 'solid 1px #e0e0e0'
  }
};

class CourseListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/training/course/view/${id}`);
  };

  getListing = props => {
    const { search, isLoading } = this.props;
    const statusFilterValue = search && search.filters.status ? search.filters.status : NO_SEARCH_VALUE;
    const locationFilterValue = search && search.filters.location ? search.filters.location : NO_SEARCH_VALUE;
    const internalFilterValue = search && search.filters.internal ? search.filters.internal : NO_SEARCH_VALUE;

    return (
      <ScrollableTable
        headers={[
          { value: 'Title' },
          {
            value: 'Study Method',
            filter: {
              value: locationFilterValue,
              options: [LOCATION_TYPE.ONLINE, LOCATION_TYPE.IN_CLASSROOM],
              onChange: event => props.handleFilterChange(event, 'location')
            }
          },
          {
            value: 'Internal Only',
            filter: {
              value: internalFilterValue,
              options: [
                { value: 'true', label: 'Internal Only' },
                { value: 'false', label: 'Internal Only & External' }
              ],
              onChange: event => props.handleFilterChange(event, 'internal')
            }
          },
          {
            value: 'Status',
            filter: {
              value: statusFilterValue,
              options: [COURSE_STATUS_TYPE.DRAFT, COURSE_STATUS_TYPE.DEPRECATED, COURSE_STATUS_TYPE.PUBLISHED],
              onChange: event => props.handleFilterChange(event, 'status')
            }
          }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Courses Found"
        {...props}
      />
    );
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);

    return items.map(course => ({
      clickAction: () => this.actionNavigate(course.identifier),
      cells: [
        {
          value: course.title
        },
        {
          value: LOCATION_TYPE[course.locationType].label
        },
        {
          value: course.internal ? <i className="material-icons">done</i> : <i className="material-icons">clear</i>
        },
        {
          value: COURSE_STATUS_TYPE[course.courseStatusType].label
        }
      ]
    }));
  };

  render() {
    const { classes, table, listEntities, listCourseRequest } = this.props;

    return (
      <Grid container>
        <Grid item xs={12} className={classNames(classes.noPaddingLeftRightGrid)}>
          <ListingWithSearch
            name="courses"
            getSearchUrl={getSearchCoursesUrl}
            onList={listEntities}
            renderList={props => this.getListing(props)}
            list={table}
            additionalSearchParams={listCourseRequest}
            filtersList={{
              location: [LOCATION_TYPE.ONLINE, LOCATION_TYPE.IN_CLASSROOM],
              status: [COURSE_STATUS_TYPE.DRAFT, COURSE_STATUS_TYPE.DEPRECATED, COURSE_STATUS_TYPE.PUBLISHED],
              internal: [
                { value: 'true', label: 'Internal Only' },
                { value: 'false', label: 'Internal Only & External' }
              ]
            }}
          />
        </Grid>
      </Grid>
    );
  }
}

CourseListView.propTypes = {
  classes: PropTypes.object.isRequired,
  listEntities: PropTypes.func,
  listCourseRequest: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  table: PropTypes.object,
  reasons: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities'),
  ...tableSelector(state, 'courses')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listEntities, listCourseRequest })
)(CourseListView);

import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';
import FilledInput from '@material-ui/core/FilledInput';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import { style } from '../../assets/style';

const styles = {
  ...style,
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%',
    '& > div': {
      backgroundColor: '#fafafa !important'
    }
  },
  textFieldContainer: {
    borderBottom: 'none'
  }
};

function SelectWithOptions({
  classes,
  error,
  id,
  value,
  values,
  optionValue,
  optionName,
  label,
  handleChange,
  overrideClassName,
  inputConfig,
  selectConfig,
  labelConfig,
  helperText,
  disabled
}) {
  let options = null;

  if (values && Object.getOwnPropertyNames(values).length !== 0) {
    options = values.map((v, idx) => {
      return (
        <MenuItem
          key={`${idx}optionName`}
          value={optionValue ? v[optionValue] : v}
          disabled={v.disabled}
          classes={{
            root: classes.menuItemRoot,
            selected: classes.menuItemSelected
          }}
        >
          {v[optionName]}
        </MenuItem>
      );
    });
  }
  return values ? (
    <FormControl
      className={classNames({ [overrideClassName]: !!overrideClassName }, classes.formControl)}
      error={!!error}
      variant="filled"
      disabled={disabled}
    >
      <InputLabel {...labelConfig} htmlFor={id} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        value={value || ''}
        name={id}
        onChange={handleChange}
        inputProps={{ className: classes.textFieldContainer }}
        input={<FilledInput name={id} id={id} readOnly={false} {...inputConfig} className={classes.underline} />}
        {...selectConfig}
      >
        {options}
      </Select>

      {helperText && <FormHelperText className={classes.fieldHelperText}>{helperText}</FormHelperText>}
      {error && <FormHelperText className={classes.fieldHelperText}> {error}</FormHelperText>}
    </FormControl>
  ) : null;
}

SelectWithOptions.defaultProps = {
  disabled: false
};

SelectWithOptions.propTypes = {
  optionName: PropTypes.string.isRequired,
  optionValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  changeParameter: PropTypes.string,
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  values: PropTypes.array,
  handleChange: PropTypes.func,
  overrideClassName: PropTypes.string,
  inputConfig: PropTypes.object,
  selectConfig: PropTypes.object,
  labelConfig: PropTypes.object,
  helperText: PropTypes.string,
  disabled: PropTypes.bool
};

export default withStyles(styles)(SelectWithOptions);

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { listCrowdsourceCredentialTypeRequest } from '../../utils/entityRequests/crowdsourceRequests';
import NewHeaderActions from '../header/NewHeaderActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getCrowdsourceCredentialTypeUrl } from '../../utils/searchUrls';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { STANDARD_CREDENTIAL_TYPE } from '../../assets/constants';

const styles = {
  ...style
};

class CrowdsourceCredentialTypeListView extends React.Component {
  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/admin/crowdsource/credentialType/view/${id}`);
  };

  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);
    return items.map(credentialType => ({
      clickAction: () => this.actionNavigate(credentialType.identifier),
      cells: [
        {
          value: credentialType.name
        },
        {
          value: credentialType.holderName
        },
        {
          value: STANDARD_CREDENTIAL_TYPE[credentialType.standardCredentialType].label
        },
        {
          value: credentialType.organisation
            ? credentialType.organisation.name
            : credentialType.crowdsourceOrganisation.name
        }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[
          { value: 'Name' },
          { value: 'Holder' },
          { value: 'Credential Background' },
          { value: 'Organisation' }
        ]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No crowdsource credential types Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, table, listEntities } = this.props;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <NewHeaderActions header="Crowdsource Credential Type" buttons={[]} />

            <ListingWithSearch
              name="crowdCredentialTypes"
              getSearchUrl={getCrowdsourceCredentialTypeUrl}
              onList={listEntities}
              renderList={props => this.getListing(props)}
              list={table}
              additionalSearchParams={listCrowdsourceCredentialTypeRequest}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

CrowdsourceCredentialTypeListView.propTypes = {
  classes: PropTypes.object.isRequired,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  search: PropTypes.object,
  table: PropTypes.object,
  listEntities: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-crowdCredentialTypes'),
  ...tableSelector(state, 'crowdCredentialTypes')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, listEntities })
)(CrowdsourceCredentialTypeListView);

export const commonButtonStyles = {
  padding: '7px 16px',
  textDecoration: 'none',
  textTransform: 'capitalize',
  lineHeight: 1.14,
  borderRadius: '2px',
  fontWeight: 400
};

export const textButton = {
  textDecoration: 'none',
  fontSize: '13px'
};

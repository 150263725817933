import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import ls from 'local-storage';
import { getEntity, createInitialEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { ACTIVITY_TYPE } from '../../assets/constants';
import ScrollableTable from '../table/ScrollableTable';
import { loadingSelector } from '../../store/selectors/shared';
import { entityArraySelector, entitySelector } from '../../store/selectors/entity';
import { getWorkPlaceRequest } from '../../utils/entityRequests/workPlaceRequests';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import DisplayField from '../widgets/DisplayField';
import NewSectionHeading from '../common/NewSectionHeading';
import NewActionButton from '../widgets/NewActionButton';

const styles = {
  ...layout,
  ...style,
  displayLabelAndField: {
    marginTop: '6px'
  },
  qualifications: {
    marginTop: '20px'
  }
};

class WorkPlaceView extends React.Component {
  async componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await getEntity(getWorkPlaceRequest(id));
  }

  renderMarkers = (map, maps, workPlace) => {
    const marker = new maps.Marker({
      position: { lat: parseFloat(workPlace.position.latitude), lng: parseFloat(workPlace.position.longitude) },
      map
    });
    return marker;
  };

  getCurrentRowConfigs = () => {
    const { workPlaceActivitiesCurrent } = this.props;
    return this.getRowConfigs(workPlaceActivitiesCurrent);
  };

  getFutureRowConfigs = () => {
    const { workPlaceActivitiesFuture } = this.props;
    return this.getRowConfigs(workPlaceActivitiesFuture);
  };

  getRowConfigs = records => {
    const items = records || [];

    return items.map(activity => ({
      cells: [
        {
          value: activity.name
        },
        {
          value: ACTIVITY_TYPE[activity.activityType].label
        },
        {
          value: activity.startDate
        },
        {
          value: activity.endDate
        }
      ]
    }));
  };

  navigateToMembers = () => {
    const { history, match } = this.props;
    const { params } = match;
    const { id } = params;
    history.push(`/workplace/location/view/${id}/member`);
  };

  navigateToObservations = () => {
    const { history, match } = this.props;
    const { params } = match;
    const { id } = params;
    history.push(`/workplace/location/view/${id}/observation`);
  };

  render() {
    const { classes, match, workPlace, isLoading, workPlaceActivitiesCurrent, workPlaceActivitiesFuture } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(workPlace).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, {
            [classes.editMode]: false
          })}
        >
          <Grid container>
            <Grid item xs={12}>
              <NewHeaderViewActions
                header="Workplace Details"
                editUrl={ls.get('key') === workPlace.key ? `/workplace/location/edit/${id}` : ''}
                cancelUrl="/workplace"
              >
                {workPlace.privateWorkplace && (
                  <NewActionButton
                    primary={false}
                    icon={<i className="material-icons">groups</i>}
                    text="Members"
                    onChange={this.navigateToMembers}
                    filled
                  />
                )}

                <NewActionButton
                  primary={false}
                  icon={<i className="material-icons">assignments</i>}
                  text="Observations"
                  onChange={this.navigateToObservations}
                  filled
                />
              </NewHeaderViewActions>
            </Grid>
          </Grid>
          <Grid item lg={6} md={12}>
            <DisplayField heading="Name" value={workPlace.name} />
            <DisplayField heading="Address" value={workPlace.address} />
            <DisplayField heading="Latitude" value={(workPlace.position && workPlace.position.latitude) || 'Not Set'} />
            <DisplayField
              heading="Longitude"
              value={(workPlace.position && workPlace.position.longitude) || 'Not Set'}
            />
            <DisplayField heading="Type" value={workPlace.type} />
            <DisplayField heading="Open Hours" value={workPlace.openHours} />
            <DisplayField heading="Description" value={workPlace.description || 'Not Set'} />
            <DisplayField
              heading="Private"
              value={
                workPlace.privateWorkplace ? (
                  <i className="material-icons">done</i>
                ) : (
                  <i className="material-icons">clear</i>
                )
              }
            />
            {workPlace.privateWorkplace && (
              <Fragment>
                <NewSectionHeading heading="Workplace Admins" />
                <div className={classes.displayLabelAndField}>
                  {workPlace.admins.map(admin => (
                    <DisplayField heading="" value={admin.name} />
                  ))}
                </div>
              </Fragment>
            )}
            {workPlace.imageUrl && (
              <DisplayField
                heading="Logo"
                value={<img src={workPlace.imageUrl} alt="Uploaded" className={classes.avatar} height={200} />}
              />
            )}

            <NewSectionHeading heading="Compulsory Credential Types" className={classes.qualifications} />
            <div className={classes.displayLabelAndField}>
              {workPlace.compulsoryCredentialTypes.map(selectedCredentialType => (
                <DisplayField heading="" value={selectedCredentialType.name} />
              ))}
            </div>
            <NewSectionHeading heading="Compulsory Qualifications" className={classes.qualifications} />
            <div className={classes.displayLabelAndField}>
              {workPlace.compulsoryQualifications.map(selectedQualification => (
                <DisplayField heading="" value={selectedQualification.name} />
              ))}
            </div>
            {workPlaceActivitiesCurrent && workPlaceActivitiesCurrent.length > 0 && (
              <div>
                <NewSectionHeading heading="Current Activities" />
                <ScrollableTable
                  headers={[
                    { value: 'Name' },
                    { value: 'Activity Type' },
                    { value: 'Start Date' },
                    { value: 'End Date' }
                  ]}
                  rowConfigs={this.getCurrentRowConfigs()}
                  complete={!isLoading}
                  emptyMessage="No Current Activities Found"
                  overrideHeight="200px"
                />
              </div>
            )}
            {workPlaceActivitiesFuture && workPlaceActivitiesFuture.length > 0 && (
              <div>
                <NewSectionHeading heading="Future Activities" />
                <ScrollableTable
                  headers={[
                    { value: 'Name' },
                    { value: 'Activity Type' },
                    { value: 'Start Date' },
                    { value: 'End Date' }
                  ]}
                  rowConfigs={this.getFutureRowConfigs()}
                  complete={!isLoading}
                  emptyMessage="No Future Activities Found"
                />
              </div>
            )}
          </Grid>
          <Grid item lg={6} md={12}>
            {workPlace.position && workPlace.position.latitude && workPlace.position.longitude && (
              <div className={classes.googleMap}>
                {/*<GoogleMapReact*/}
                {/*  bootstrapURLKeys={{ key: window.reppleEnv.GOOGLE_KEY }}*/}
                {/*  defaultCenter={{*/}
                {/*    lat: parseFloat(workPlace.position.latitude),*/}
                {/*    lng: parseFloat(workPlace.position.longitude)*/}
                {/*  }}*/}
                {/*  defaultZoom={15}*/}
                {/*  yesIWantToUseGoogleMapApiInternals*/}
                {/*  onGoogleApiLoaded={({ map, maps }) => this.renderMarkers(map, maps, workPlace)}*/}
                {/*/>*/}
              </div>
            )}
          </Grid>
        </Grid>
      )
    );
  }
}

WorkPlaceView.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
  params: PropTypes.object,
  workPlaceActivitiesCurrent: PropTypes.array,
  workPlaceActivitiesFuture: PropTypes.array,
  getEntity: PropTypes.func,
  createInitialEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  workPlace: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'workPlace'),
  ...entityArraySelector(state, 'workPlaceActivitiesCurrent'),
  ...entityArraySelector(state, 'workPlaceActivitiesFuture'),
  isLoading: loadingSelector(state, 'getEntity')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity, createInitialEntity }))(WorkPlaceView);

import ls from 'local-storage';
import { Auth } from 'aws-amplify';
import {
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_VALIDATION_FAILURE,
  GLOBAL_ERROR,
  RESTART_LOGIN,
  MIDDLEWARE_VALIDATE
} from './types';
/* eslint-disable-next-line */
import ajax from '../../components/http/AjaxUtil';
import { startLoading, stopLoading } from './LoadingActions';

export const login = user => async dispatch => {
  if (!dispatch({ type: MIDDLEWARE_VALIDATE })) return;

  try {
    dispatch(startLoading('login'));
    ls.set('usernameAuth', true);
    const response = await Auth.signIn({ username: user.username, password: user.password });

    if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
      return response;
    }
    ls.set('authenticating', true);
  } finally {
    dispatch(stopLoading('login'));
  }
};

export const googleLogin = () => async dispatch => {
  ls.set('authenticating', true);
  ls.set('googleAuth', true);
  await Auth.federatedSignIn({ provider: 'Google' });
};

export const refreshOauthToken = () => {
  /* eslint-disable-next-line */
  console.log('**************************** Refresh token ***************************');
  return ajax
    .postWithoutRefresh(
      `https://${window.reppleEnv.cognito.DOMAIN}/oauth2/token`,
      new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    )
    .then(response => {
      ls.set('token', response.data.id_token);
      ls.set('tokenExpiry', new Date().getTime() + parseInt(response.data.expires_in, 10) * 1000);
    });
};

export const forgotPassword = forgotPassword => async dispatch => {
  if (!dispatch({ type: MIDDLEWARE_VALIDATE })) return;

  try {
    dispatch(startLoading('forgotPassword'));
    return await ajax.put(process.env.REACT_APP_FORGOT_PASSWORD, forgotPassword).then(response => {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS
      });

      return response.data;
    });
  } catch (e) {
    if (e.status === 400) {
      dispatch(forgotPasswordFailure(forgotPassword, e.data));
    } else {
      dispatch({
        type: GLOBAL_ERROR,
        payload: { error_description: e.data.error_description }
      });
    }
  } finally {
    dispatch(stopLoading('forgotPassword'));
  }
};

export const resetPasswordWithCode = passwordResetModel => async dispatch => {
  if (!dispatch({ type: MIDDLEWARE_VALIDATE })) return;

  try {
    dispatch(startLoading('resetPasswordWithCode'));
    await Auth.forgotPasswordSubmit(passwordResetModel.userId, passwordResetModel.code, passwordResetModel.password);
  } finally {
    dispatch(stopLoading('resetPasswordWithCode'));
  }
};

export const completeNewPassword = (user, password) => async dispatch => {
  if (!dispatch({ type: MIDDLEWARE_VALIDATE })) return;

  try {
    dispatch(startLoading('completeNewPassword'));
    await Auth.completeNewPassword(user, password);
  } finally {
    dispatch(stopLoading('completeNewPassword'));
  }
};

export function resetRestartLogin() {
  return {
    type: RESTART_LOGIN,
    restartLogin: false
  };
}

export function forgotPasswordFailure(forgotPassword, errors) {
  return {
    type: FORGOT_PASSWORD_VALIDATION_FAILURE,
    errors,
    forgotPassword
  };
}

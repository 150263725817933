import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import { searchSelector } from '../../store/selectors/search';
import { border } from '../../assets/constants';
import {
  search,
  clearSearch,
  changeSearchFilter,
  createInitialSearch,
  createInitialTable
} from '../../store/actions/SearchActions';
import { initialCurrentPage, pageSize } from '../../constants/global';
import { getValue } from '../control/SimplisticSelect';
import { loadingSelector } from '../../store/selectors/shared';

const styles = {
  searchInput: {
    fontSize: '13px'
  },
  searchContainer: {
    padding: '10px',
    borderTop: border.style_1px,
    borderBottom: border.style_1px
  },
  searchInputIcon: {
    color: '#666'
  },
  iconAction: {
    cursor: 'pointer'
  }
};

export const DEFAULT_TYPE_TIMEOUT = 300;
export const initialState = {
  searchText: '',
  currentPage: initialCurrentPage,
  isSearching: false,
  typingTimeout: 0
};

/*
 ! Very often used component around the project. !
 * It implements a few common functionalities:
 *   1: Search bar
 *   2: List view with the results of that search/filter bar
 * Has two main actions which are dispatched - onList() && search()
 * They fill up two different states.
 * List state is passed from the parent component as for the search is dependent on this
 * component's name which acts as an accessor for the corresponding search item in the redux state.
 ! This component communicates with the parent one via the renderList prop !
 */
class ListingWithSearch extends React.Component {
  state = initialState;

  inputRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (prevProps.searchText && !this.props.searchText) this.clearSearch();
  }

  /**
   * ScrollableTable needs to wait for the initial load so it can trigger intersection observable API call if needed.
   */
  initialSearch = async () => {
    const { createInitialSearch, createInitialTable, name } = this.props;
    await createInitialSearch(name);
    await createInitialTable(name);
    return this.resetList();
  };

  handleFilterChange = (event, filterName) => {
    const { search, changeSearchFilter, onList } = this.props;
    const { additionalSearchParams, name, getSearchUrl, filtersList } = this.props;
    const { isSearching, searchText } = this.state;
    const filter = filtersList ? getValue(event, filtersList[filterName]).value : event.target.value;
    const currentPage = initialCurrentPage;

    changeSearchFilter(name, filterName, filter);

    if (isSearching) {
      search(
        {
          name,
          searchText,
          pageSize,
          currentPage
        },
        getSearchUrl,
        _.isFunction(additionalSearchParams) ? additionalSearchParams(searchText) : additionalSearchParams
      );
    } else {
      onList(
        currentPage,
        pageSize,
        _.isFunction(additionalSearchParams) ? additionalSearchParams() : additionalSearchParams
      );
    }
  };

  handleChange = event => {
    event.persist();
    const { additionalSearchParams, getSearchUrl, name, shouldFilterFromState, search } = this.props;
    const { typingTimeout, isSearching, currentPage } = this.state;
    const { value: searchText } = event.target;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (!searchText) {
      this.clearSearch();
      return false;
    }

    if (!isSearching) {
      // When switching from normal listing to search call this same function, but with the updated state.
      this.setState({ isSearching: true, currentPage: initialCurrentPage }, () => {
        this.handleChange(event);
        return false;
      });
    }

    this.setState({
      searchText,
      typingTimeout: setTimeout(() => {
        if (shouldFilterFromState) return;
        search({ name, searchText, pageSize, currentPage }, getSearchUrl, additionalSearchParams);
      }, DEFAULT_TYPE_TIMEOUT)
    });

    return false;
  };

  // Handle escape key press clear search
  handleKeyDown = event => {
    // Escape key press
    if (event.key === 'Escape') {
      event.stopPropagation();
      this.clearSearch();
    }
  };

  getTotalPages = list => {
    return list.payload ? list.payload.totalPages : 0;
  };

  handleListScroll = async () => {
    const {
      additionalSearchParams,
      list,
      totalPages: totalSearchPages,
      name,
      getSearchUrl,
      shouldFilterFromState,
      search,
      onList
    } = this.props;
    const { isSearching, searchText, currentPage } = this.state;
    const totalPages = isSearching ? totalSearchPages : this.getTotalPages(list);

    if (shouldFilterFromState) return;

    if (currentPage < totalPages - 1) {
      if (isSearching) {
        search({ name, searchText, pageSize, currentPage: currentPage + 1 }, getSearchUrl, additionalSearchParams);
      } else {
        await onList(
          currentPage + 1,
          pageSize,
          _.isFunction(additionalSearchParams) ? additionalSearchParams() : additionalSearchParams
        );
      }

      this.setState({ currentPage: currentPage + 1 });
    }
  };

  clearSearch = () => {
    const { onClearSearch, name, clearSearch, shouldFilterFromState } = this.props;

    if (!shouldFilterFromState) {
      clearSearch(name);
    }
    this.setState(initialState, () => {
      this.resetList();

      onClearSearch();
    });
    this.inputRef.current.focus();
  };

  async resetList() {
    const { additionalSearchParams } = this.props;

    await this.props.onList(
      initialCurrentPage,
      pageSize,
      _.isFunction(additionalSearchParams) ? additionalSearchParams() : additionalSearchParams
    );
  }

  render() {
    const { classes, className, placeholder, renderList, isSearchLoading, hideSearch } = this.props;
    const { isSearching, searchText } = this.state;

    return (
      <div>
        {!hideSearch && (
          <Grid item xs={12} className={classNames(classes.searchContainer, className)}>
            <TextField
              fullWidth
              autoFocus
              value={searchText}
              className={classes.searchInputIcon}
              placeholder={placeholder}
              onKeyDown={this.handleKeyDown}
              inputRef={this.inputRef}
              InputProps={{
                disableUnderline: true,
                className: classes.searchInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <i className={classNames('material-icons filter-icon', [classes.searchInputIcon])}>filter_list</i>
                  </InputAdornment>
                ),
                endAdornment: isSearching ? (
                  <InputAdornment position="start" className={classes.iconAction} onClick={this.clearSearch}>
                    <i className={classNames('material-icons', [classes.searchInputIcon])}>clear</i>
                  </InputAdornment>
                ) : null
              }}
              onChange={this.handleChange}
            />
          </Grid>
        )}
        {renderList({
          initialSearch: this.initialSearch,
          scroll: this.handleListScroll,
          handleFilterChange: this.handleFilterChange,
          searchText,
          isSearchLoading
        })}
      </div>
    );
  }
}

ListingWithSearch.defaultProps = {
  placeholder: 'Type to filter',
  onClearSearch: () => {},
  filtersList: null,
  additionalSearchParams: null,
  shouldFilterFromState: false,
  hideSearch: false
};

ListingWithSearch.propTypes = {
  additionalSearchParams: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  renderList: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  // Can be null or an array
  list: PropTypes.object,
  records: PropTypes.any,
  // Meaning that there will be no search API call,
  // The use case is filtering through a raw list of items.
  shouldFilterFromState: PropTypes.bool,
  isSearchLoading: PropTypes.bool,
  totalPages: PropTypes.any,
  onList: PropTypes.func.isRequired,
  changeSearchFilter: PropTypes.func.isRequired,
  getSearchUrl: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  onClearSearch: PropTypes.func,
  createInitialTable: PropTypes.func,
  search: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  createInitialSearch: PropTypes.func.isRequired,
  filtersList: PropTypes.object,
  searchText: PropTypes.string,
  hideSearch: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => ({
  ...searchSelector(state, ownProps.name),
  isSearchLoading: loadingSelector(state, `search-${ownProps.name}`)
});

export default compose(
  connect(mapStateToProps, { search, clearSearch, changeSearchFilter, createInitialSearch, createInitialTable }),
  withStyles(styles)
)(ListingWithSearch);

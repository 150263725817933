import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import classNames from 'classnames';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import editor from '../../assets/editor';
import { LOCATION_TYPE, DIFFICULTY_TYPE, PERIOD_TYPE, VALIDATORS_BY_NAME } from '../../assets/constants';
import ValidatedField from '../widgets/ValidatedField';
import { entityArraySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import { courseInitialState } from '../../utils/entityInitialStates';
import { getCourseRequest, saveCourseRequest, listKeywordRequest } from '../../utils/entityRequests/trainingRequests';
import { uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads } from '../../store/actions/DocumentActions';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SectionHeading from '../common/SectionHeading';
import ImageUpload from '../widgets/ImageUpload';
import { documentUploadsSelector } from '../../store/selectors/document';
import ScrollableTable from '../table/ScrollableTable';
import TableCellInput from '../table/TableCellInput';
import TableCellSelect from '../table/TableCellSelect';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout,
  ...editor
});

class ChangeCourseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: courseInitialState,
      editorState: EditorState.createEmpty(),
      imageUpload: null
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/training/course/view/${id}` : '/training/course';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;
    await getEntity(listKeywordRequest());

    if (this.isEdit) {
      const course = await getEntity(getCourseRequest(match.params.id));
      const blocksFromHTML = htmlToDraft(course.description);
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

      this.setState({ course, editorState: EditorState.createWithContent(state) });
    } else {
      this.setState({
        course: courseInitialState
      });
    }
  }

  componentWillUnmount() {
    const { clearUserFileDownloads } = this.props;
    this.setState({ course: courseInitialState });
    clearUserFileDownloads();
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);
    _.set(courseResult, name, value);

    this.setState({
      course: courseResult
    });
  };

  handleTagChange = event => {
    const { name, value } = event.target;
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);
    _.set(courseResult, name, value ? value.map(item => ({ value: item.value, label: item.value })) : []);

    this.setState({
      course: courseResult
    });
  };

  handleBooleanChange = event => {
    const { name } = event.target;
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);

    _.set(courseResult, name, !_.get(courseResult, name));

    this.setState({
      course: courseResult
    });
  };

  handleEditorChange = event => {
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);

    if (event.getCurrentContent().hasText()) {
      courseResult.description = draftToHtml(convertToRaw(event.getCurrentContent()));
    } else courseResult.description = '';

    this.setState({
      editorState: event,
      course: courseResult
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, requestPublicImageUploadUrl, uploadToS3 } = this.props;
    const { course, imageUpload } = this.state;

    const courseResult = _.cloneDeep(course);

    if (imageUpload && !courseResult.imageKey) {
      const upload = await requestPublicImageUploadUrl(imageUpload);
      const response = await uploadToS3([{ url: upload.url, control: 'courseImage' }], [imageUpload]);
      if (response[0].status === 200) courseResult.imageKey = upload.key;
    }

    const courseId = await saveEntity(saveCourseRequest(courseResult));

    if (!courseId) return;

    history.push(`/training/course/view/${courseId}`);
  };

  handleImageUpload = imageUpload => {
    this.setState({
      imageUpload
    });
  };

  handleClearImageUpload = () => {
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);
    courseResult.imageKey = '';
    courseResult.imageUrl = '';

    this.setState({
      course: courseResult,
      imageUpload: null
    });
  };

  getRowConfigs = () => {
    const { course } = this.state;
    const { errors } = this.props;

    const items = course.courseSessions || [];
    return items.map((courseSession, index) => ({
      actions: [
        {
          action: (
            <Tooltip title="Delete training session" aria-label="add" placement="bottom">
              <IconButton onClick={() => this.handleRemoveTrainingSession(index)}>
                <i className="material-icons">delete</i>
              </IconButton>
            </Tooltip>
          )
        }
      ],
      cells: [
        {
          value: (
            <TableCellInput
              error={errors && errors.fieldErrors[`courseSessions[${index}].duration`]}
              value={courseSession.duration.toString()}
              handleChange={event => {
                this.handleChange(event);
              }}
              id={`courseSessions[${index}].duration`}
              name={`courseSessions[${index}].duration`}
            />
          )
        },
        {
          value: (
            <TableCellSelect
              error={errors && errors.fieldErrors[`courseSessions[${index}].periodType`]}
              handleChange={event => {
                this.handleChange(event);
              }}
              value={courseSession.periodType || ''}
              items={[PERIOD_TYPE.MINUTE, PERIOD_TYPE.HOUR, PERIOD_TYPE.DAY]}
              itemName="label"
              itemValue="id"
              id={`courseSessions[${index}].periodType`}
              name={`courseSessions[${index}].periodType`}
              index={index.toString()}
            />
          ),
          style: { padding: '0' }
        }
      ]
    }));
  };

  handleAddTrainingSession = () => {
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);
    courseResult.courseSessions.push({ identifier: '', duration: 0, periodType: '' });

    this.setState({
      course: courseResult
    });
  };

  handleRemoveTrainingSession = index => {
    const { course } = this.state;
    const courseResult = _.cloneDeep(course);
    courseResult.courseSessions.splice(index, 1);

    this.setState({
      course: courseResult
    });
  };

  render() {
    const { classes, isSaving, errors, documentUploads, keywords } = this.props;
    const { course, editorState } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={classNames(classes.viewPage, classes.page)} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              header="Course Details"
            />
          </Grid>
          <Grid container className={classes.formContent}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <ValidatedField
                    id="title"
                    label="Title"
                    name="title"
                    value={course.title}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.fieldContainer}>
                  <ValidatedField
                    id="blurb"
                    label="Blurb"
                    name="blurb"
                    value={course.blurb}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    multiline
                  />
                </div>
              </Grid>
              <Grid item xs={12} className={classes.sideBySideControl}>
                <Grid item xs={5}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      id="locationType"
                      label="Study Method"
                      name="locationType"
                      value={course.locationType}
                      handleChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      values={[LOCATION_TYPE.ONLINE, LOCATION_TYPE.IN_CLASSROOM]}
                      optionName="label"
                      optionValue="id"
                    />
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      type={VALIDATED_FIELD_TYPES.SELECT}
                      id="difficultyType"
                      label="Difficulty Type"
                      name="difficultyType"
                      value={course.difficultyType}
                      handleChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      values={[DIFFICULTY_TYPE.EASY, DIFFICULTY_TYPE.MEDIUM, DIFFICULTY_TYPE.HARD]}
                      optionName="label"
                      optionValue="id"
                    />
                  </div>
                </Grid>
              </Grid>
              {course.locationType === LOCATION_TYPE.ONLINE.id && (
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      id="url"
                      label="Redirection URL"
                      name="url"
                      value={course.url || ''}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs={12} className={classes.sideBySideControl}>
                <Grid item xs={5}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      type={VALIDATED_FIELD_TYPES.RADIO_GROUP}
                      label="Visibility"
                      value={course.internal ? 'true' : 'false'}
                      values={[
                        { name: 'Internal Only', identifier: 'true' },
                        { name: 'Internal & External', identifier: 'false' }
                      ]}
                      overrideClassName={classes.fullWidth}
                      id="internal"
                      name="internal"
                      optionName="name"
                      optionValue="identifier"
                      validators={[]}
                      onChange={this.handleBooleanChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className={classes.fieldContainer}>
                    <ValidatedField
                      id="cost"
                      label="Cost"
                      name="cost"
                      value={course.cost}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.DECIMAL_NUMBERS_ONLY_REGEX, VALIDATORS_BY_NAME.REQUIRED]}
                      multiline
                      errorText={errors && errors.fieldErrors.cost}
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.fieldContainer, classes.editorContainer)}>
                  <ValidatedField
                    multiple
                    type={VALIDATED_FIELD_TYPES.SELECT_WITH_INPUT}
                    label="Keywords"
                    values={course.keywords}
                    onChange={this.handleTagChange}
                    items={keywords || []}
                    itemName="label"
                    itemValue="value"
                    classes={classes.formControl}
                    id="keywords"
                    name="keywords"
                    validators={[]}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classNames(classes.fieldContainer, classes.editorContainer)}>
                  <Editor
                    editorState={editorState}
                    toolbarClassName={classes.toolbarClassName}
                    wrapperClassName="wrapperClassName"
                    editorClassName={classes.editorClassName}
                    onEditorStateChange={this.handleEditorChange}
                  />
                  {errors && errors.fieldErrors.description && (
                    <FormHelperText error>{errors.fieldErrors.description}</FormHelperText>
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
              <SectionHeading underlined heading="Course Image" />
              <ImageUpload
                width={300}
                height={200}
                handleImageUpload={this.handleImageUpload}
                clearImageUpload={this.handleClearImageUpload}
                uploadStatus={documentUploads && documentUploads.courseImage ? documentUploads.courseImage : []}
                isSaving={isSaving}
                image={course.imageUrl}
              />
              {errors && errors.fieldErrors.imageKey && (
                <FormHelperText error className={classes.credentialTypeError}>
                  {errors.fieldErrors.imageKey}
                </FormHelperText>
              )}
            </Grid>
            <Grid item xs={6}>
              <SectionHeading heading="Training Sessions" className={classes.headingAndAction}>
                <Tooltip title="Add Training Session" aria-label="add" placement="bottom">
                  <IconButton
                    aria-owns="menu-appbar"
                    onClick={this.handleAddTrainingSession}
                    color="inherit"
                    key="openSelectUser"
                  >
                    <i className={`material-icons ${classes.icon}`}>add</i>
                  </IconButton>
                </Tooltip>
              </SectionHeading>

              <ScrollableTable
                headers={[{ value: 'Duration' }, { value: 'Period Type' }]}
                rowConfigs={this.getRowConfigs()}
                complete
                emptyMessage="No training sessions Found"
                overrideStyle={{ height: 'auto', overflowY: 'visible' }}
              />
              {errors && errors.fieldErrors.courseSessions && (
                <FormHelperText error className={classes.formError}>
                  {errors.fieldErrors.courseSessions}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeCourseView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  keywords: PropTypes.array,
  uploadToS3: PropTypes.func.isRequired,
  requestPublicImageUploadUrl: PropTypes.func.isRequired,
  clearUserFileDownloads: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  documentUploads: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'course'),
  ...entityArraySelector(state, 'keywords'),
  isSaving: loadingSelector(state, 'saveEntity'),
  ...documentUploadsSelector(state)
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity, uploadToS3, requestPublicImageUploadUrl, clearUserFileDownloads }),
  withRouter
)(ChangeCourseView);

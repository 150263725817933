import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { entityArraySelector } from '../../store/selectors/entity';

const ReasonListBreadcrumb = ({ reasons, isLoading }) => {
  const subText = `${reasons ? reasons.length : 0} in total`;

  return <GenericBreadcrumbItem mainText="Reasons" subText={subText} isLoading={isLoading} />;
};

const mapStateToProps = state => ({
  ...entityArraySelector(state, 'reasons'),
  isLoading: loadingSelector(state, 'getEntity')
});

ReasonListBreadcrumb.propTypes = {
  reasons: PropTypes.array,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(ReasonListBreadcrumb);

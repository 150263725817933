import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { Button, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { loadingSelector } from '../../store/selectors/shared';
import { listEntities } from '../../store/actions/EntityActions';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { getQualificationUrl } from '../../utils/searchUrls';
import ScrollableTable from '../table/ScrollableTable';
import { colors } from '../../assets/constants';
import { tableSelector } from '../../store/selectors/table';
import { getRecords } from '../../utils/utilities';
import { listQualificationRequest } from '../../utils/entityRequests/qualificationRequests';
import NewActionButton from '../widgets/NewActionButton';

const styles = () => ({
  ...style,
  textField: {
    width: '100%'
  },
  secondaryHeading: {
    marginTop: '20px',
    borderBottom: 'none !important'
  },
  searchContainer: {
    padding: '10px',
    border: 'none'
  },
  scrollTableContainer: {
    overflowY: 'auto'
  },
  sliderBody: {
    ...layout.sliderBody,
    padding: 0
  },
  radio: {
    '& > span > svg': {
      height: '0.75em',
      width: '0.75em'
    }
  },
  selected: {
    '& > span > svg': {
      color: colors.orangeDark
    }
  }
});

class QualificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQualifications: []
    };
  }

  toggleQualificationSelection = event => {
    const { target } = event;
    const { value, name } = target;
    const { selectedQualifications } = this.state;
    const selectedQualification = selectedQualifications.find(item => item.identifier === value);

    if (!selectedQualification) {
      const qualifications = selectedQualifications.slice();
      qualifications.push({ name, identifier: value });
      this.setState({
        selectedQualifications: qualifications
      });
    } else {
      this.setState({
        selectedQualifications: selectedQualifications.slice().filter(item => item.identifier !== value)
      });
    }
  };

  getRowConfigs = () => {
    const { search, table, classes } = this.props;
    const { selectedQualifications } = this.state;
    const items = getRecords(search, table);
    return items.map((qualification, index) => ({
      cells: [
        { value: qualification.name },
        {
          value: (
            <Checkbox
              onClick={this.toggleQualificationSelection}
              value={qualification.identifier}
              name={qualification.name}
              id={`checkBox${index}`}
              checked={!!selectedQualifications.find(item => item.identifier === qualification.identifier)}
              className={classNames(classes.radio, {
                [classes.selected]: !!selectedQualifications.find(item => item.identifier === qualification.identifier)
              })}
            />
          )
        }
      ]
    }));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Qualification Name' }, { value: '', class: 'smallCol' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Qualifications Found"
        {...props}
      />
    );
  };

  handleSelectQualifications = () => {
    const { selectQualifications } = this.props;
    const { selectedQualifications } = this.state;
    selectQualifications(selectedQualifications);
  };

  render() {
    const { classes, table, closeDisplaySelectQualificationType, listEntities } = this.props;

    return (
      <div className={classes.overlayContainer}>
        <Grid container justify="space-between" className={classes.overLayHeadingActionContainer}>
          <Grid container alignItems="center" item xs={6}>
            <div>
              <div className={classes.pageHeading}>Qualifications</div>
            </div>
          </Grid>
          <Grid container item xs={6} justify="flex-end" direction="row" alignItems="center">
            <Button
              onClick={closeDisplaySelectQualificationType}
              className={classNames(classes.cancel)}
              data-qa="cancel"
            >
              Close
            </Button>

            <NewActionButton
              primary
              icon={<i className="material-icons">add</i>}
              text="Select Qualifications"
              onChange={this.handleSelectQualifications}
              filled
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={classNames(classes.formPage, classes.sliderBody)}>
          <Grid container item xs={12} alignContent="flex-start">
            <Grid item xs={12}>
              <ListingWithSearch
                name="qualifications"
                getSearchUrl={getQualificationUrl}
                onList={listEntities}
                // In case of multiple filters this would accept event and name params
                // based on which it would determine filterValue
                renderList={props => this.getListing(props)}
                list={table}
                additionalSearchParams={listQualificationRequest}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

QualificationList.propTypes = {
  classes: PropTypes.object,
  closeSelectHolder: PropTypes.func,
  listEntities: PropTypes.func,
  selectQualifications: PropTypes.func,
  closeDisplaySelectQualificationType: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  table: PropTypes.object,
  search: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'listEntities') || loadingSelector(state, 'search-qualifications'),
  ...tableSelector(state, 'qualifications')
});

export default withStyles(styles)(connect(mapStateToProps, { listEntities })(QualificationList));

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { loadingSelector } from '../../store/selectors/shared';
import GenericBreadcrumbItem from './GenericBreadcrumbItem';
import { entityArraySelector } from '../../store/selectors/entity';

const TeamListBreadcrumb = ({ teams, isLoading }) => {
  const subText = `${teams ? teams.length : 0} in total`;

  return <GenericBreadcrumbItem mainText="Teams" subText={subText} isLoading={isLoading} />;
};

const mapStateToProps = state => ({
  ...entityArraySelector(state, 'teams'),
  isLoading: loadingSelector(state, 'getEntity')
});

TeamListBreadcrumb.propTypes = {
  teams: PropTypes.array,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(TeamListBreadcrumb);

import color from 'color';
import { border, colors, sizes, globalSpacingPixels, textEllipsis, commonFont } from './constants';
import { commonButtonStyles } from './buttons';

const errorToolTip = {
  errorToolTip: {
    backgroundColor: '#e57373 !important'
  }
};

const style = {
  ...errorToolTip,
  fieldHelperText: {
    '&::first-letter': { textTransform: 'uppercase' }
  },
  popper: {
    zIndex: 100
  },
  checkboxContainer: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '16px',
    '& svg': {
      color: 'rgba(0, 0, 0, 0.54)'
    }
  },
  checkboxContainerSelected: {
    '& svg': {
      color: `${colors.orangeDark}`
    }
  },
  successText: {
    color: '#4caf50'
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px'
  },
  fullHeight: {
    height: '100vh'
  },
  fullWidth: {
    width: '100%'
  },
  stats: {
    color: '#999999',
    display: 'inline-flex',
    fontSize: '12px',
    lineHeight: '22px',
    '& svg': {
      top: '4px',
      width: '16px',
      height: '16px',
      position: 'relative',
      marginRight: '3px'
    }
  },
  removeOutline: {
    outline: 'none !important'
  },
  leftHandNavigation: {
    flex: '0 0 90px',
    flexShrink: 0,
    boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.15)',
    zIndex: 200
  },
  rightHandNavigation: {
    flex: `0 0 ${sizes.smallNavigationWidth}`,
    flexShrink: 0,
    borderLeft: border.style_1px,
    marginLeft: 'auto'
  },
  container: {
    display: 'flex',
    width: '100%',
    height: '100%'
  },
  iconNav: {
    boxShadow: '1px 0 0 0 #fff, 0 1px 0 0 #fff, 1px 1px 0 0 #fff, 1px 0 0 0 #fff inset, 0 1px 0 0 #fff inset',
    display: 'flex',
    height: '56px',
    width: '56px',
    cursor: 'pointer',
    color: '#fff'
  },
  iconNavDone: {
    boxShadow: 'none !important',
    display: 'inline-block',
    color: '#fff'
  },
  iconNavLeft: {
    marginLeft: '7px'
  },
  navigation: {
    listStyleType: 'none',
    padding: '0',
    margin: '0'
  },
  navigationVertical: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  navigationItem: {
    display: 'flex',
    justifyContent: 'center',
    '& i': {
      padding: '3px'
    },
    '& svg': {
      fill: `${colors.fontLightGrey}`
    },
    '& .selected i, & .selected svg': {
      color: colors.orangeNew,
      fill: `${colors.orangeNew} !important`
    }
  },
  bottomNavigationItem: {
    position: 'fixed',
    bottom: 20,
    left: 7,
    display: 'flex',
    alignItems: 'center'
  },
  bottomNavigationItemSelected: {
    position: 'fixed',
    bottom: 20,
    left: 0,
    display: 'flex',
    alignItems: 'center'
  },
  selectedNav: {
    backgroundColor: '#ddd',
    color: '#fff'
  },
  iconButton: {
    fontWeight: 'normal',
    textTransform: 'none',
    backgroundColor: 'transparent'
  },
  filterContainer: {
    backgroundColor: '#E8F4FD',
    fontWeight: 'normal'
  },
  whiteIcon: {
    color: 'white'
  },
  greyIcon: {
    color: colors.greyIcon
  },
  blueIcon: {
    color: colors.blue,
    cursor: 'pointer'
  },
  greyBackground: {
    background: colors.grey1
  },
  orangeIcon: {
    color: colors.orangeDark
  },
  linkNoDecoration: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none'
    },
    cursor: 'pointer'
  },
  avatar: {
    margin: '2px 7px 2px 0px',
    fontSize: '13px',
    backgroundColor: 'rgba(246,156,126)'
  },
  avatarContainer: {
    display: 'inline-block'
  },
  headingActionContainer: {
    borderBottom: border.style_1px,
    height: sizes.headerHeight,
    margin: sizes.globalSide
  },
  updatingHeaderContainer: {
    backgroundColor: colors.blue,
    color: '#fff',
    padding: `0 ${sizes.halfSpacing}`,
    margin: sizes.globalSide
  },
  pageHeading: {
    padding: '5px 0px 0px 10px',
    fontSize: '16px',
    fontFamily: 'Encode Sans, sans-serif',
    color: '#000'
  },
  whiteWriting: {
    color: '#fff !important'
  },
  pageHeadingDesc: {
    padding: '0px 0px 0px 10px',
    fontSize: '12px',
    fontFamily: 'Encode Sans, sans-serif',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  displayLabelAndField: {
    // borderBottom: border.style_1px,
    paddingLeft: '12px',
    marginTop: '13px'
  },
  displayLabel: {
    fontSize: '12px',
    color: '#9b9b9b'
  },
  displayField: {
    fontSize: '15px',
    color: 'rgba(0,0,0,.87)',
    marginBottom: '7px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54) !important'
  },
  backArrow: {
    color: '#222'
  },
  updatingIcon: {
    color: '#fff'
  },
  displayFieldHeading: {
    fontSize: '24px',
    fontWeight: 300,
    textTransform: 'uppercase',
    fontFamily: 'Encode Sans, sans-serif',
    paddingLeft: '12px',
    marginBottom: '5px',
    color: 'rgba(0, 0, 0, 0.87)',
    lineHeight: 0.67
  },
  viewPage: {
    backgroundColor: colors.backgroundBlue,
    overflow: 'auto',
    height: sizes.contentHeight,
    paddingTop: sizes.globalSpacingPixels
  },
  displayFieldHeadingContainer: {
    borderBottom: 'solid 2px #9e9e9e'
  },
  actionIcon: {
    color: '#777',
    marginRight: '7px'
  },
  icon: {
    color: '#777'
  },
  helperText: {
    paddingLeft: '45px',
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '12px',
    color: '#777'
  },
  noTextTransform: {
    textTransform: 'none'
  },
  background: {
    backgroundColor: '#000',
    opacity: '0.75'
  },
  pageContainer: {
    paddingTop: '0 !important',
    paddingRight: '0 !important',
    height: sizes.contentHeight,
    display: 'flex',
    overflowY: 'auto'
  },
  mainContentColumn: {
    padding: '0 30px'
  },
  hideBackground: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1100,
    position: 'fixed',
    backgroundColor: '#000',
    opacity: 0.7
  },
  noPaddingLeftRightGrid: {
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    overflow: 'auto'
  },
  noPaddingTop: {
    paddingTop: '0 !important'
  },
  largeAvatar: {
    height: '200px',
    width: '200px',
    margin: '50px 10px 0px 30px',
    fontSize: '54px'
  },
  actionStatusContainer: {
    paddingTop: '18px',
    paddingBottom: '18px',
    backgroundColor: colors.blue,
    fontSize: '14px',
    fontFamily: 'Encode Sans, sans-serif'
  },
  actionButton: {
    textTransform: 'none'
  },
  textFieldContainer: {
    backgroundColor: `${colors.grey1} !important`,
    // borderBottom: 'solid 1px #545454',
    color: `${colors.fontGreyDark} !important`
  },
  textField: {
    width: '100%',
    marginBottom: 0
  },
  fieldContainer: {
    width: '100%'
  },
  searchInput: {
    fontSize: '13px'
  },
  searchContainer: {
    padding: '10px',
    borderTop: border.style_1px
  },
  searchInputIcon: {
    color: '#666'
  },
  switchControlAndLabel: {
    backgroundColor: colors.grey1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '18px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    paddingLeft: '12px'
  },
  formLabel: {
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '15px',
    color: colors.fontGreyDark
  },
  iconAction: {
    cursor: 'pointer'
  },
  searchInputActive: {
    boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.5)',
    marginBottom: '2px'
  },
  hidden: {
    display: 'none'
  },
  sectionHeading: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    fontFamily: 'Encode Sans, sans-serif',
    fontSize: '18px',
    fontWeight: 300,
    color: '#9e9e9e',
    textTransform: 'uppercase',
    borderBottom: border.style_1px,
    lineHeight: 0.89,
    paddingBottom: '8px',
    paddingLeft: '14px'
  },
  sectionHeadingContainer: {
    marginTop: globalSpacingPixels
  },
  sectionHeadingActionIcon: {
    marginLeft: 'auto'
  },
  lightBackground: {
    backgroundColor: colors.grey1
  },
  error: {
    borderColor: 'red'
  },
  manualError: {
    borderBottom: 'solid 2px red !important'
  },
  orderedList: {
    listStyleType: 'none',
    padding: '0',
    margin: '0'
  },
  formPage: {
    padding: sizes.globalSide
  },
  flexRowCentered: {
    display: 'flex',
    alignItems: 'center'
  },
  viewHeadingContainer: {
    paddingBottom: '8px'
  },
  headingAndAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  globalTopOffset: {
    marginTop: globalSpacingPixels
  },
  viewColumn: {
    height: '100vh - 45px',
    paddingTop: globalSpacingPixels
  },
  viewContainer: {
    paddingLeft: globalSpacingPixels,
    paddingRight: globalSpacingPixels
  },
  noBorder: {
    border: 'none !important'
  },
  noPadding: {
    padding: '0 !important'
  },
  flex: {
    display: 'flex'
  },
  overlayContainer: {
    backgroundColor: '#fff',
    opacity: 1,
    height: '100vh',
    zIndex: '100001',
    width: '100%',
    overflowY: 'auto',
    boxShadow: '-2px 0px 12px -1px rgba(0,0,0,0.49)'
  },
  overLayHeadingActionContainer: {
    height: sizes.headerHeight,
    padding: sizes.globalSide
  },
  modalContainer: {
    height: '100vh',
    width: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    top: 0,
    right: 0,
    outline: 'none'
  },
  relative: {
    position: 'relative'
  },
  disabledByCss: { pointerEvents: 'none' },
  underline: {
    '&:before, &:after': {
      borderBottomColor: `${colors.grey4} !important`
    }
  },
  switchBase: {
    '&$checked': {
      color: colors.orangeDark,
      '& + $track': {
        backgroundColor: colors.orangeDark
      }
    }
  },
  track: {},
  checked: {},
  checkboxLabel: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  actionSeparator: {
    borderLeft: '1px solid #e0e0e0',
    height: '32px',
    margin: '0 16px',
    width: 0
  },
  dateControl: {
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'baseline',
    marginTop: '0 !important',
    '& > input': {
      marginLeft: '14px'
    }
  },
  submit: {
    ...commonButtonStyles,
    cursor: 'pointer',
    color: colors.white,
    background: colors.orangeDark,
    marginLeft: sizes.halfSpacing,
    '&:hover': {
      background: color(colors.orangeDark)
        .lighten(0.1)
        .hex()
    }
  },
  thumbnail: {
    display: 'flex',
    justifyContent: 'center',
    height: '88px',
    overflow: 'hidden',
    margin: '10px 15px 0 0',
    cursor: 'pointer',
    width: '90px'
  },
  thumbnailContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '20px'
  },
  googleMap: {
    height: 'calc(100vh - 50%)',
    width: '100%',
    paddingLeft: '30px',
    marginTop: '55px',
    paddingRight: '30px'
  },
  chipContainer: {
    backgroundColor: '#fafafa !important',
    padding: '20px 0px 10px 13px',
    cursor: 'pointer'
  },
  chipItem: {
    marginRight: '4px'
  },
  rotateIcon: {
    transform: 'rotate(180deg)'
  },
  radioOptionLabel: {
    '& > span': {
      fontSize: '14px'
    }
  },
  independentLabel: {
    color: 'rgba(0, 0, 0, 0.54) !important'
  },
  radio: {
    '& > span > svg': {
      height: '0.75em',
      width: '0.75em'
    }
  },
  ellipsisText: {
    ...textEllipsis
  },
  columnDirection: {
    display: 'flex',
    flexDirection: 'column'
  },
  rowDirection: {
    display: 'flex',
    flexDirection: 'row'
  },
  noFlexBasis: {
    flexBasis: 0
  },
  pointer: {
    cursor: 'pointer'
  },
  sideBySideControl: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end'
  },
  status: {
    width: '70px',
    height: '20px',
    borderRadius: '3px',
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '9px',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '6px'
  },
  pendingStatus: {
    backgroundColor: colors.orangeBackground
  },
  credentialTick: {
    marginRight: '5px',
    height: '14px',
    width: '14px'
  },
  credentialBackground: {
    width: '20px',
    height: '15px',
    backgroundColor: colors.orangeBackground,
    marginLeft: '11px',
    borderRadius: '2px',
    marginTop: '3px'
  },
  selected: {
    '& > span > svg': {
      color: `${colors.orangeDark} !important`
    }
  },
  pending: {
    backgroundColor: colors.orangeBackground
  },
  rejected: {
    backgroundColor: colors.blueDark
  },
  expired: {
    backgroundColor: '#F9C0C3'
  },
  verified: {
    backgroundColor: colors.lightGreen,
    color: colors.white
  },
  credentialTypeButton: {
    border: `1px solid ${colors.blue}`,
    height: '100px',
    borderRadius: '5px',
    fontSize: '12px',
    fontWeight: 600,
    ...commonFont,
    color: colors.blue,
    marginRight: '20px',
    cursor: 'pointer',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& div': {
      height: '60px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      textAlign: 'center'
    }
  },
  credentialTypeButtonSelected: {
    color: `${colors.white} !important`,
    backgroundColor: `${colors.blue} !important`
  },
  bottomSaveButton: {
    marginTop: '10px'
  }
};

export { style, errorToolTip };

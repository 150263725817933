import GenericHeadingOnlyBreadcrumb from '../components/breadcrumbs/GenericHeadingOnlyBreadcrumb';
import GenericListItemBreadcrumb from '../components/breadcrumbs/GenericListItemBreadcrumb';
import ChangeCredentialTypeView from '../components/credentialType/ChangeCredentialTypeView';
import CredentialTypeView from '../components/credentialType/CredentialTypeView';
import CredentialTypeListView from '../components/credentialType/CredentialTypeListView';
import GenericListBreadcrumb from '../components/breadcrumbs/GenericListBreadcrumb';
import ChangeFormView from '../components/form/ChangeFormView';
import FormView from '../components/form/FormView';
import FormListView from '../components/form/FormListView';
import CredentialVersionView from '../components/credential/CredentialVersionView';
import CredentialListView from '../components/credential/CredentialListView';
import ImportView from '../components/import/ImportView';
import ImportListView from '../components/import/ImportListView';
import InboxCredentialView from '../components/credential/InboxCredentialView';

export default [
  // credential type routes
  {
    path: '/credential/credentialType/view/:id',
    component: CredentialTypeView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'credentialType',
    itemName: 'name',
    heading: 'Credential Type'
  },
  {
    path: '/credential/inbox/:id/view/:transactionId',
    component: CredentialVersionView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'credentialVersion',
    itemName: 'version',
    heading: 'Version'
  },
  {
    path: '/credential/inbox',
    component: InboxCredentialView,
    breadcrumb: GenericHeadingOnlyBreadcrumb,
    protected: true,
    heading: 'Credential Inbox'
  },
  {
    path: '/credential/credentialType/create',
    component: ChangeCredentialTypeView,
    breadcrumb: null,
    protected: true
  },
  {
    path: '/credential/credentialType/edit/:id',
    component: ChangeCredentialTypeView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'credentialType',
    itemName: 'name',
    heading: 'Credential Type'
  },
  {
    path: '/credential/credentialType',
    component: CredentialTypeListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'credentialType',
    functionName: 'listEntities',
    heading: 'Credential Type'
  },
  // form routes
  {
    path: '/credential/credentialDefinition/form/create',
    component: ChangeFormView,
    breadcrumb: null,
    protected: true
  },
  {
    path: '/credential/credentialDefinition/form/edit/:id',
    component: ChangeFormView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'form',
    itemName: 'name',
    heading: 'Form'
  },
  {
    path: '/credential/credentialDefinition/form/view/:id',
    component: FormView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'form',
    itemName: 'name',
    heading: 'Form'
  },
  {
    path: '/credential/credentialDefinition/form',
    component: FormListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'form',
    functionName: 'listEntities',
    heading: 'Form'
  },
  // credential routes
  {
    path: '/credential/credentialDefinition/credentialLifeCycle/view/:id/list/view/:transactionId',
    component: CredentialVersionView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'credentialVersion',
    itemName: 'version',
    heading: 'Version'
  },
  {
    path: '/credential/credentialDefinition/credentialLifeCycle',
    component: CredentialListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'credentials',
    functionName: 'listEntities',
    heading: 'Credential'
  },
  // import routes
  {
    path: '/credential/import/view/:id',
    component: ImportView,
    breadcrumb: GenericListItemBreadcrumb,
    protected: true,
    reducer: 'entity',
    functionName: 'getEntity',
    propertyName: 'imported',
    itemName: 'credentialTypeName',
    heading: 'Import'
  },
  {
    path: '/credential/import',
    component: ImportListView,
    breadcrumb: GenericListBreadcrumb,
    protected: true,
    tableName: 'import',
    functionName: 'listEntities',
    heading: 'Import'
  }
];

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid, Modal, Slide, Tooltip, IconButton, Chip } from '@material-ui/core';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import NewValidatedField from '../widgets/NewValidatedField';
import CredentialTypeList from '../credentialType/CredentialTypeList';
import { getQualificationRequest, saveQualificationRequest } from '../../utils/entityRequests/qualificationRequests';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { qualificationInitialState } from '../../utils/entityInitialStates';
import SaveButton from '../widgets/SaveButton';
import NewSectionHeading from '../common/NewSectionHeading';

const styles = () => ({
  ...style,
  ...layout,
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
  displayField: {
    fontSize: '15px',
    color: 'rgba(0,0,0,.87)'
  },
  marginTop: {
    marginTop: '16px'
  }
});

class ChangeQualificationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualification: qualificationInitialState,
      shouldDisplaySelectCredentialType: false
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `/workforce/qualification/view/${id}` : '/workforce/qualification';
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const qualification = await getEntity(getQualificationRequest(match.params.id));
      this.setState({ qualification });
    } else {
      this.setState({
        qualification: qualificationInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ qualification: qualificationInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;

    this.setState(prevState => ({
      ...prevState,
      qualification: {
        ...prevState.qualification,
        [name]: value
      }
    }));
  };

  handleDeleteQualificationGroup = index => {
    const { qualification } = this.state;
    const groups = qualification.groups.slice();
    groups.splice(index, 1);

    this.setState(prevState => ({
      ...prevState,
      qualification: {
        ...prevState.qualification,
        groups
      }
    }));
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { qualification } = this.state;

    const qualificationId = await saveEntity(saveQualificationRequest(qualification));

    if (!qualificationId) return;

    history.push(`/workforce/qualification/view/${qualificationId}`);
  };

  handleSelectCredentialTypesChange = credentialTypes => {
    const { groupIndex, qualification } = this.state;
    const qualificationModel = _.cloneDeep(qualification);

    if (qualificationModel.groups.length === groupIndex) {
      qualificationModel.groups.push({ credentialTypes, identifier: '' });
    } else {
      qualificationModel.groups[groupIndex].credentialTypes = credentialTypes;
    }

    this.setState({ qualification: qualificationModel, shouldDisplaySelectCredentialType: false });
  };

  openDisplaySelectCredentialType = index => {
    this.setState(prevState => ({
      ...prevState,
      shouldDisplaySelectCredentialType: true,
      groupIndex: index
    }));
  };

  closeDisplaySelectCredentialType = () => {
    this.setState(prevState => ({
      ...prevState,
      shouldDisplaySelectCredentialType: false
    }));
  };

  render() {
    const { classes, isSaving, errors } = this.props;
    const { qualification, shouldDisplaySelectCredentialType } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={classNames(classes.viewPage, classes.page)} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              header="Qualifications"
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={qualification.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <NewSectionHeading heading="Qualifying Credential Type Groups" className={classes.marginTop}>
                    <Tooltip title="Create Qualifying Credential Type Group" aria-label="add" placement="bottom">
                      <IconButton
                        aria-owns="menu-appbar"
                        onClick={() => this.openDisplaySelectCredentialType(qualification.groups.length)}
                        color="inherit"
                        key="openSelectUser"
                      >
                        <i className={`material-icons ${classes.icon}`}>add</i>
                      </IconButton>
                    </Tooltip>
                  </NewSectionHeading>
                </Grid>
                <Grid item xs={12}>
                  {errors && errors.fieldErrors && errors.fieldErrors.groups && (
                    <FormHelperText error>{errors.fieldErrors.groups}</FormHelperText>
                  )}
                  <div className={classes.fieldContainer}>
                    {qualification.groups.map((group, index) => (
                      <div key={`group-${uuid()}`}>
                        <div className={classNames(classes.displayField, classes.headingAndAction)}>
                          <div
                            className={classNames(classes.formControl, classes.underline, classes.chipContainer)}
                            onClick={() => this.openDisplaySelectCredentialType(index)}
                          >
                            {group.credentialTypes.map(credentialType => (
                              <Chip
                                key={`chip-${credentialType.identifier}`}
                                label={credentialType.name}
                                className={classes.chipItem}
                              />
                            ))}
                          </div>
                          <Tooltip title="Delete Group" aria-label="add" placement="bottom">
                            <IconButton
                              aria-owns="menu-appbar"
                              onClick={() => this.handleDeleteQualificationGroup(index)}
                              color="inherit"
                              key={`delete-group-${group.identifier}`}
                            >
                              <i className={`material-icons ${classes.icon}`}>delete</i>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </div>
                </Grid>
              </Grid>
              <Modal open={shouldDisplaySelectCredentialType} onClose={this.closeDisplaySelectCredentialType}>
                <Slide direction="left" in={shouldDisplaySelectCredentialType} mountOnEnter unmountOnExit>
                  <Grid item xs={6} className={`${classes.modalContainer}`}>
                    <CredentialTypeList
                      closeDisplaySelectCredentialType={this.closeDisplaySelectCredentialType}
                      selectCredentialTypes={this.handleSelectCredentialTypesChange}
                    />
                  </Grid>
                </Slide>
              </Modal>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.bottomSaveButton}>
            <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeQualificationView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func,
  match: PropTypes.object,
  isSaving: PropTypes.bool,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'qualification'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeQualificationView);

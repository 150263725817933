import React, { Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Checkbox, TextField, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { colors, commonFont } from '../../assets/constants';
import { listEntities, clearEntity, getEntity, putEntity } from '../../store/actions/EntityActions';
import { toggleSetting } from '../../store/actions/SettingsActions';
import { createInitialTable } from '../../store/actions/SearchActions';
import { getCredentialFilterStatus, getCredentialStatusFromFrontEnd, getUserInitials } from '../../utils/utilities';
import PendingCredentialTickOrangeIcon from '../../assets/images/pending-credential-tick-orange.svg';
import { ReactComponent as PendingCredentialTickIcon } from '../../assets/images/pending-credential-tick.svg';
import { ReactComponent as AssigneeIcon } from '../../assets/images/assignee-icon.svg';
import { ReactComponent as VerifiedCredentialTickGreenIcon } from '../../assets/images/verified-tick-icon-green.svg';
import { ReactComponent as RevokeBlueIcon } from '../../assets/images/revoke-credential-icon-blue.svg';
import { ReactComponent as RevokeWhiteIcon } from '../../assets/images/revoke-credential-icon-white.svg';
import VerifiedCredentialTickIcon from '../../assets/images/verified-tick-icon.svg';
import RejectedExclamationBlueIcon from '../../assets/images/rejected-exclamation-icon-blue.svg';
import { ReactComponent as RejectedExclamationIcon } from '../../assets/images/rejected-exclamation-icon.svg';
import RevokedOrangeIcon from '../../assets/images/revoke-credential-icon-orange.svg';
import ExpiredCrossRedIcon from '../../assets/images/expired-cross-icon-red.svg';
import ExpiredCrossIcon from '../../assets/images/expired-cross-icon.svg';
import TopLevelButton from '../widgets/TopLevelButton';
/* eslint-disable max-len */
import { ReactComponent as RejectedExclamationWhiteIcon } from '../../assets/images/rejected-exclamation-icon-white.svg';
/* eslint-enable max-len */
import NewValidatedField from '../widgets/NewValidatedField';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import { ReactComponent as OrangeDownCarretIcon } from '../../assets/images/orange-down-carret.svg';
import BlueSearchIcon from '../../assets/images/blue-search-icon.svg';
import {
  assignCredentialRequest,
  getCredentialLogBookRequest,
  getCredentialMessagesRequest,
  getCredentialRequest,
  listCredentialRequest,
  listCredentialVersionRequest,
  listOtherCredentialRequest,
  rejectCredentialRequest,
  revokeCredentialRequest,
  verifyCredentialRequest
} from '../../utils/entityRequests/credentialRequests';
import { tableSelector } from '../../store/selectors/table';
import { getCredentialTypeRequest } from '../../utils/entityRequests/credentialTypeRequests';
import { entitySelector } from '../../store/selectors/entity';
import CredentialInboxFilterDialog from '../widgets/CredentialInboxFilterDialog';
import { settingSelector } from '../../store/selectors/shared';

const styles = {
  ...layout,
  ...style,
  inbox: {
    backgroundColor: colors.backgroundBlue,
    borderRight: `solid 1px ${colors.borderBlue}`,
    height: '100%'
  },
  inboxContainer: {},
  inboxRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: `solid 1px ${colors.borderBlue}`,
    paddingTop: '10px',
    paddingBottom: '16px',
    paddingLeft: '21px'
  },
  firstInboxRow: {
    borderTop: 'none !important'
  },
  selectedInboxRow: {
    backgroundColor: colors.white
  },
  avatar: {
    fontSize: '13px',
    backgroundColor: 'rgba(136,135,135)',
    borderRadius: '17px',
    width: '34px',
    height: '34px'
  },
  holderName: {
    ...commonFont,
    fontWeight: 600,
    fontSize: '15px',
    color: colors.blueDark,
    marginLeft: '10px'
  },
  twoSidedContainer: {
    justifyContent: 'space-between',
    width: '100%',
    paddingRight: '20px',
    display: 'flex'
  },
  status: {
    width: '70px',
    height: '20px',
    borderRadius: '3px',
    ...commonFont,
    fontWeight: 'bold',
    fontSize: '9px',
    justifyContent: 'flex-end',
    textTransform: 'uppercase',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '6px'
  },
  credentialBackground: {
    width: '20px',
    height: '15px',
    marginLeft: '10px',
    borderRadius: '2px',
    marginTop: '3px'
  },
  subText: {
    ...commonFont,
    fontSize: '12px',
    fontWeight: 'normal',
    color: colors.fontLightGrey,
    marginLeft: '5px',
    marginTop: '5px'
  },
  credentialSubText: {
    ...commonFont,
    fontWeight: 500,
    fontSize: '14px',
    color: colors.fontLightGrey,
    marginTop: '2px'
  },
  noResults: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '77px',
    ...commonFont,
    fontSize: '18px',
    fontWeight: 600,
    color: colors.blueDark
  },
  imageOrCheckbox: {
    width: '40px'
  },
  inboxStatusFilters: {
    paddingTop: '8px',
    ...commonFont,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.blueDark,
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      // marginRight: '38px',
      cursor: 'pointer'
    },
    height: '83px',
    width: '100%',
    alignItems: 'center'
  },
  paddingLeft: {
    paddingLeft: '38px'
  },
  inboxStatus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    // paddingTop: '10px',
    width: '100%'
  },
  statusIcon: {
    height: '20px'
  },
  selected: {
    borderBottom: `5px solid ${colors.orangeNew}`,
    '& div > div': {
      fontWeight: 600,
      color: colors.blueDark
    },
    '& $crossTxtContainer': {
      backgroundColor: colors.white
    }
  },
  crossTxtContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '65px',
    width: '65px',
    borderRadius: '5px',
    alignItems: 'center'
  },
  lineSpacer: {
    border: `solid 1px ${colors.spacerBlue}`,
    height: '50px'
  },
  searchAndFilter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '61px',
    borderTop: `solid 1px ${colors.borderBlue}`,
    borderBottom: `solid 1px ${colors.borderBlue}`,
    justifyContent: 'space-between'
  },
  openText: {
    ...commonFont,
    fontWeight: 600,
    fontSize: '16px',
    color: colors.blueDark,
    marginLeft: '3px'
  },
  search: {
    backgroundColor: colors.white,
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  openRadio: {
    marginLeft: '21px'
  },
  dropDownMenu: {
    marginLeft: '10px',
    cursor: 'pointer',
    '& svg': {
      height: '6px',
      width: '12px'
    }
  },
  searchIcon: {
    cursor: 'pointer',
    marginRight: '12px'
  },
  textFieldContainer: {
    backgroundColor: `${colors.white} !important`,
    color: `${colors.fontLightGrey} !important`,
    height: '100%',
    ...commonFont,
    fontWeight: 500,
    fontSize: '16px',
    marginLeft: '30px'
  },
  clearIcon: {
    color: colors.fontLightGrey,
    cursor: 'pointer',
    marginRight: '4px'
  },
  checkBoxSelected: {
    ...style.selected
  },
  assignee: {
    width: '300px',
    marginTop: 0,
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none'
    },
    '& input': {
      ...commonFont,
      fontWeight: '600 !important',
      fontSize: '16px !important',
      color: `${colors.blueDark} !important`
    }
  },
  inboxActions: {
    height: '83px',
    width: '100%',
    paddingLeft: '29px'
  },
  fadedWhite: {
    ...commonFont,
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgba(255, 255, 255, 0.5)'
  },
  white: {
    ...commonFont,
    fontWeight: 500,
    fontSize: '14px',
    color: colors.white
  },
  credentialActionButton: {
    backgroundColor: 'inherit',
    justifyContent: 'flex-start',
    width: '89px',
    '& img': {
      color: colors.white,
      backgroundColor: colors.white
    },
    '&:hover': {
      backgroundColor: 'inherit',
      opacity: 0.6
    }
  },
  buttonsLineSpacer: {
    border: '1px solid rgba(255, 255, 255, 0.2)',
    height: '27px'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  },
  marginLeft: {
    marginLeft: '17px'
  },
  globalAssignee: {
    marginTop: 0,
    marginRight: '10px',
    marginLeft: '10px',
    marginBottom: '0 !important',
    width: '200px',
    '& svg': {
      fill: colors.white
    },
    '& .MuiInputBase-root': {
      padding: 0,
      '& fieldset': {
        border: 'none !important'
      }
    },
    '& fieldset': {
      border: 'none !important'
    },
    '& .MuiFormLabel-root': {
      transform: 'translate(14px, 10px) scale(1)',
      color: 'white !important',
      ...commonFont,
      fontWeight: 600,
      fontSize: '14px'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: `${colors.orangeBackground} !important`
    }
  },
  pending: {
    backgroundColor: colors.orangeBackground,
    '& $globalAssignee fieldset': {
      backgroundColor: `${colors.orangeBackground} !important`
    },
    '& $globalAssignee .MuiOutlinedInput-root': {
      backgroundColor: `${colors.orangeBackground} !important`
    }
  },
  rejected: {
    backgroundColor: colors.blueDark,
    '& $globalAssignee fieldset': {
      backgroundColor: `${colors.blueDark} !important`
    },
    '& $globalAssignee .MuiOutlinedInput-root': {
      backgroundColor: `${colors.blueDark} !important`
    }
  },
  revoked: {
    backgroundColor: colors.reokeOrgange,
    '& $globalAssignee fieldset': {
      backgroundColor: `${colors.reokeOrgange} !important`
    },
    '& $globalAssignee .MuiOutlinedInput-root': {
      backgroundColor: `${colors.reokeOrgange} !important`
    }
  },
  expired: {
    backgroundColor: '#F9C0C3',
    '& $globalAssignee fieldset': {
      backgroundColor: '#F9C0C3 !important'
    },
    '& $globalAssignee .MuiOutlinedInput-root': {
      backgroundColor: '#F9C0C3 !important'
    }
  },
  verified: {
    backgroundColor: colors.lightGreen,
    color: colors.white,
    '& $globalAssignee fieldset': {
      backgroundColor: `${colors.lightGreen} !important`
    },
    '& $globalAssignee .MuiOutlinedInput-root': {
      backgroundColor: `${colors.lightGreen} !important`
    }
  },
  statusHeader: {
    marginLeft: '5px'
  },
  statusPadding: {
    paddingTop: '10px',
    paddingBottom: '7px'
  }
};

class CredentialInbox extends React.Component {
  state = {
    selectedDocumentId: '',
    showCheckbox: null,
    selectedRows: [],
    allCredentialsSelected: false,
    credentialStatus: 'PENDING',
    credentialSearch: {
      showSearch: false,
      searchText: ''
    },
    globalVerifying: false,
    globalRejecting: false,
    globalRevoking: false,
    order: 'desc',
    date: '',
    assignee: '',
    type: '',
    expire: '',
    filter: {
      filterOpen: false,
      anchorEl: null
    }
  };

  async componentDidMount() {
    const { createInitialTable, listEntities } = this.props;
    await createInitialTable('credentials');
    await listEntities(0, 20, listCredentialRequest('UNVERIFIED', 'desc', '', '', '', '', ''));
  }

  static getDerivedStateFromProps(props, prevProps) {
    const { clearFilter: previousClearFilter } = prevProps;
    const { clearFilter, listEntities, toggleSetting } = props;
    if (!previousClearFilter && clearFilter) {
      toggleSetting('clearFilter', false);
      const stateModel = _.cloneDeep(prevProps);
      stateModel.order = 'desc';
      stateModel.date = '';
      stateModel.assignee = '';
      stateModel.type = '';
      stateModel.expire = '';
      listEntities(
        0,
        20,
        listCredentialRequest(getCredentialStatusFromFrontEnd(prevProps.credentialStatus), 'desc', '', '', '', '', '')
      );

      return stateModel;
    }
    return props;
  }

  changeStatus = async name => {
    const { listEntities, clearEntity } = this.props;
    const { credentialSearch, order, date } = this.state;

    this.setState({
      credentialStatus: name
    });

    clearEntity('credential');
    await listEntities(
      0,
      20,
      listCredentialRequest(getCredentialStatusFromFrontEnd(name), order, date, '', '', '', credentialSearch.searchText)
    );
  };

  handleSelectRow = documentId => {
    this.setState({ selectedDocumentId: documentId });
    this.getCredential(documentId);
  };

  showCheckbox = documentId => {
    this.setState({ showCheckbox: documentId });
  };

  toggleCheckBox = documentId => {
    const { selectedRows } = this.state;
    const rows = selectedRows.slice();

    if (rows.indexOf(documentId) === -1) {
      rows.push(documentId);
    } else {
      rows.splice(selectedRows.indexOf(documentId), 1);
    }
    this.setState({ selectedRows: rows });
  };

  changeSearch = async e => {
    const { listEntities, clearEntity } = this.props;
    const { credentialStatus, order, date, assignee, type, expire } = this.state;
    const { target } = e;
    const { value } = target;

    this.setState({
      credentialSearch: {
        searchText: value,
        showSearch: true
      }
    });

    clearEntity('credential');
    await listEntities(
      0,
      20,
      listCredentialRequest(
        getCredentialStatusFromFrontEnd(credentialStatus),
        order,
        date,
        assignee,
        type,
        expire,
        value
      )
    );
  };

  closeSearch = async () => {
    const { listEntities, clearEntity } = this.props;
    const { credentialStatus, order, date, assignee, type, expire } = this.state;

    this.setState({
      credentialSearch: {
        showSearch: false,
        searchText: ''
      }
    });

    clearEntity('credential');
    await listEntities(
      0,
      20,
      listCredentialRequest(getCredentialStatusFromFrontEnd(credentialStatus), order, date, assignee, type, expire, '')
    );
  };

  search = async (order, date, assignee, type, expire) => {
    const { listEntities, clearEntity } = this.props;
    const { credentialStatus, credentialSearch } = this.state;

    this.setState({
      order,
      date,
      assignee: assignee || 'ALL',
      type: type || 'ALL',
      expire: expire || 'ALL'
    });

    clearEntity('credential');
    await listEntities(
      0,
      20,
      listCredentialRequest(
        getCredentialStatusFromFrontEnd(credentialStatus),
        order,
        date,
        assignee,
        type,
        expire,
        credentialSearch.searchText
      )
    );
  };

  openSearch = () => {
    this.setState({
      credentialSearch: {
        showSearch: true,
        searchText: ''
      }
    });
  };

  filterOpen = e => {
    this.setState({
      filter: {
        filterOpen: true,
        anchorEl: e.currentTarget
      }
    });
  };

  handleFilterClose = () => {
    this.setState({
      filter: {
        filterOpen: false,
        anchorEl: null
      }
    });
  };

  toogleAllCredentialsSelected = () => {
    const { table } = this.props;
    const { allCredentialsSelected } = this.state;

    if (!allCredentialsSelected) {
      this.setState({
        allCredentialsSelected: !allCredentialsSelected,
        selectedRows: table.payload.records.map(item => item.documentId)
      });
    } else {
      this.setState({
        allCredentialsSelected: !allCredentialsSelected,
        selectedRows: []
      });
    }
  };

  getCredential = async documentId => {
    const { getEntity } = this.props;
    const credential = await getEntity(getCredentialRequest(documentId));
    await getEntity(getCredentialTypeRequest(credential.credentialBean.credentialTypeUuid));
    await getEntity(getCredentialMessagesRequest(credential.credentialBean.documentId));
    await getEntity(getCredentialLogBookRequest(documentId));
    await getEntity(
      listOtherCredentialRequest(credential.credentialBean.holderUuid, credential.credentialBean.documentId)
    );
    await getEntity(listCredentialVersionRequest(credential.credentialBean.documentId));
  };

  handleGlobalAssign = async event => {
    const { putEntity, credential } = this.props;
    const { selectedRows } = this.state;
    const { target } = event;
    const { value } = target;

    const result = value === 'UNASSIGN' ? '' : value;
    await putEntity(assignCredentialRequest(result, selectedRows)).then(async () => {
      await listEntities(0, 20, listCredentialRequest('UNVERIFIED', 'desc', '', '', '', '', ''));

      if (credential.credentialBean) await this.getCredential(credential.credentialBean.documentId);
    });
  };

  handleGlobalVerify = async () => {
    const { putEntity, credential, table } = this.props;
    const { selectedRows, allCredentialsSelected } = this.state;
    const verifyingCredentials = allCredentialsSelected ? [] : selectedRows;
    this.setState({ globalVerifying: true });
    if (allCredentialsSelected) table.payload.records.forEach(item => verifyingCredentials.push(item.documentId));

    await putEntity(verifyCredentialRequest({ ids: verifyingCredentials })).then(async () => {
      await listEntities(0, 20, listCredentialRequest('UNVERIFIED', 'desc', '', '', '', '', ''));

      if (credential.credentialBean) await this.getCredential(credential.credentialBean.documentId);
      this.setState({ globalVerifying: false });
    });
  };

  handleGlobalReject = async () => {
    const { putEntity, credential, table } = this.props;
    const { selectedRows, allCredentialsSelected } = this.state;
    const rejectingCredentials = allCredentialsSelected ? [] : selectedRows;
    this.setState({ globalRejecting: true });

    if (allCredentialsSelected) table.payload.records.forEach(item => rejectingCredentials.push(item.documentId));

    await putEntity(rejectCredentialRequest({ ids: rejectingCredentials })).then(async () => {
      await listEntities(0, 20, listCredentialRequest('UNVERIFIED', 'desc', '', '', '', '', ''));

      if (credential.credentialBean) await this.getCredential(credential.credentialBean.documentId);
      this.setState({ globalRejecting: false });
    });
  };

  handleGlobalRevoke = async () => {
    const { putEntity, credential, table } = this.props;
    const { selectedRows, allCredentialsSelected } = this.state;
    const revokingCredentials = allCredentialsSelected ? [] : selectedRows;
    this.setState({ globalRevoking: true });

    if (allCredentialsSelected) table.payload.records.forEach(item => revokingCredentials.push(item.documentId));

    await putEntity(revokeCredentialRequest({ ids: revokingCredentials })).then(async () => {
      await listEntities(0, 20, listCredentialRequest('UNVERIFIED', 'desc', '', '', '', '', ''));

      if (credential.credentialBean) await this.getCredential(credential.credentialBean.documentId);
      this.setState({ globalRevoking: false });
    });
  };

  render() {
    const { classes, userList, table: credentials } = this.props;
    const {
      selectedDocumentId,
      showCheckbox,
      selectedRows,
      credentialStatus,
      globalVerifying,
      globalRejecting,
      globalRevoking,
      allCredentialsSelected,
      credentialSearch,
      filter,
      order,
      date,
      assignee,
      type,
      expire
    } = this.state;

    return (
      <Grid item xs={12} className={classes.inbox}>
        {selectedRows.length === 0 && !allCredentialsSelected && (
          <div className={classes.inboxStatusFilters}>
            <div
              className={classNames(classes.inboxStatus, { [classes.selected]: credentialStatus === 'PENDING' })}
              onClick={() => {
                this.changeStatus('PENDING');
              }}
            >
              <div className={classes.crossTxtContainer}>
                {credentialStatus === 'PENDING' ? (
                  <img src={PendingCredentialTickOrangeIcon} alt="Open" className={classes.statusIcon} />
                ) : (
                  <PendingCredentialTickIcon fill={colors.blue} />
                )}
                <div className={classes.credentialSubText}>Open</div>
              </div>
            </div>
            <div className={classes.lineSpacer} />
            <div
              className={classNames(classes.inboxStatus, { [classes.selected]: credentialStatus === 'VERIFIED' })}
              onClick={() => {
                this.changeStatus('VERIFIED');
              }}
            >
              <div className={classes.crossTxtContainer}>
                {credentialStatus === 'VERIFIED' ? (
                  <VerifiedCredentialTickGreenIcon />
                ) : (
                  <img src={VerifiedCredentialTickIcon} alt="Verified" className={classes.statusIcon} />
                )}
                <div className={classes.credentialSubText}>Verified</div>
              </div>
            </div>
            <div className={classes.lineSpacer} />
            <div
              className={classNames(classes.inboxStatus, { [classes.selected]: credentialStatus === 'REJECTED' })}
              onClick={() => {
                this.changeStatus('REJECTED');
              }}
            >
              <div className={classes.crossTxtContainer}>
                {credentialStatus === 'REJECTED' ? (
                  <img src={RejectedExclamationBlueIcon} alt="Rejected" className={classes.statusIcon} />
                ) : (
                  <RejectedExclamationIcon stroke={colors.blue} />
                )}
                <div className={classes.credentialSubText}>Rejected</div>
              </div>
            </div>
            <div className={classes.lineSpacer} />
            <div
              className={classNames(classes.inboxStatus, { [classes.selected]: credentialStatus === 'EXPIRED' })}
              onClick={() => {
                this.changeStatus('EXPIRED');
              }}
            >
              <div className={classes.crossTxtContainer}>
                <img
                  src={credentialStatus === 'EXPIRED' ? ExpiredCrossRedIcon : ExpiredCrossIcon}
                  alt="Expired"
                  className={classes.statusIcon}
                />
                <div className={classes.credentialSubText}>Expired</div>
              </div>
            </div>
            <div className={classes.lineSpacer} />
            <div
              className={classNames(classes.inboxStatus, { [classes.selected]: credentialStatus === 'REVOKED' })}
              onClick={() => {
                this.changeStatus('REVOKED');
              }}
            >
              <div className={classes.crossTxtContainer}>
                {credentialStatus === 'REVOKED' ? (
                  <img src={RevokedOrangeIcon} alt="Verified" className={classes.statusIcon} />
                ) : (
                  <RevokeBlueIcon />
                )}
                <div className={classes.credentialSubText}>Revoked</div>
              </div>
            </div>
          </div>
        )}
        {(selectedRows.length > 0 || allCredentialsSelected) && (
          <div
            className={classNames(classes.inboxActions, classes.columnDirection, {
              [classes.pending]: credentialStatus === 'PENDING',
              [classes.verified]: credentialStatus === 'VERIFIED',
              [classes.rejected]: credentialStatus === 'REJECTED',
              [classes.revoked]: credentialStatus === 'REVOKED',
              [classes.expired]: credentialStatus === 'EXPIRED'
            })}
          >
            <div className={classNames(classes.rowDirection)}>
              <div className={classNames(classes.flexRowCentered, classes.statusPadding)}>
                <div className={classes.fadedWhite}>Status:</div>
                <div className={classNames(classes.white, classes.statusHeader)}>
                  {getCredentialFilterStatus(credentialStatus)}
                </div>
              </div>
            </div>
            <div className={classes.actions}>
              {credentialStatus === 'PENDING' && (
                <Fragment>
                  <TopLevelButton
                    first={false}
                    last={false}
                    isActionOccurring={globalVerifying}
                    isDisabled={globalVerifying}
                    buttonColor={colors.white}
                    buttonText="Verify"
                    buttonIcon={<PendingCredentialTickIcon fill={colors.white} />}
                    onClick={this.handleGlobalVerify}
                    className={classes.credentialActionButton}
                  />
                  <div className={classes.buttonsLineSpacer} />
                  <TopLevelButton
                    first={false}
                    last={false}
                    isActionOccurring={globalRejecting}
                    isDisabled={globalRejecting}
                    buttonColor={colors.white}
                    buttonText="Reject"
                    buttonIcon={<RejectedExclamationWhiteIcon />}
                    onClick={this.handleGlobalReject}
                    className={classNames(classes.credentialActionButton, classes.marginLeft)}
                  />
                  <div className={classes.buttonsLineSpacer} />
                </Fragment>
              )}
              {credentialStatus === 'VERIFIED' && (
                <Fragment>
                  <TopLevelButton
                    first={false}
                    last={false}
                    isActionOccurring={globalRevoking}
                    isDisabled={globalRevoking}
                    buttonColor={colors.white}
                    buttonText="Revoke"
                    buttonIcon={<RevokeWhiteIcon />}
                    onClick={this.handleGlobalRevoke}
                    className={classes.credentialActionButton}
                  />
                  <div className={classes.buttonsLineSpacer} />
                </Fragment>
              )}
              <div className={classes.marginLeft}>
                <AssigneeIcon fill={colors.white} />
              </div>
              <NewValidatedField
                type={VALIDATED_FIELD_TYPES.SELECT}
                key="assignee"
                label="Assignee"
                value={{
                  label: '',
                  id: ''
                }}
                onChange={this.handleGlobalAssign}
                items={[{ id: 'UNASSIGN', label: 'Unassign' }, ...userList] || [{ id: 'UNASSIGN', label: 'Unassign' }]}
                id="assignee"
                itemName="label"
                itemValue="id"
                validators={[]}
                className={classes.globalAssignee}
                displayClear
              />
            </div>
          </div>
        )}
        <div className={classes.searchAndFilter}>
          {!credentialSearch.showSearch && (
            <Fragment>
              <div className={classes.flexRowCentered}>
                <Checkbox
                  checked={allCredentialsSelected}
                  onChange={this.toogleAllCredentialsSelected}
                  className={classNames(classes.openRadio, classes.radio, {
                    [classes.checkBoxSelected]: allCredentialsSelected
                  })}
                />
                {!allCredentialsSelected && (
                  <div className={classes.flexRowCentered}>
                    <div className={classes.openText}>{getCredentialFilterStatus(credentialStatus)}</div>
                    <div onClick={this.filterOpen} className={classes.dropDownMenu}>
                      <OrangeDownCarretIcon fill={colors.orangeNew} />
                    </div>
                  </div>
                )}
                {allCredentialsSelected && (
                  <div className={classes.flexRowCentered}>
                    <div className={classes.openText}>{`${selectedRows.length} Selected`}</div>
                  </div>
                )}
              </div>
              {!allCredentialsSelected && (
                <Tooltip title="Search" onClick={this.openSearch}>
                  <img src={BlueSearchIcon} alt="Search" className={classes.searchIcon} />
                </Tooltip>
              )}
            </Fragment>
          )}
          {credentialSearch.showSearch && (
            <div className={classes.search}>
              <TextField
                onChange={this.changeSearch}
                name="credentialSearch.searchText"
                placeholder="Search Text"
                className={classes.textField}
                InputProps={{
                  className: classes.textFieldContainer,
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="start">
                      <i className={classNames('material-icons', classes.clearIcon)} onClick={this.closeSearch}>
                        clear
                      </i>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          )}
        </div>
        <div className={classes.inboxContainer}>
          {(!credentials || !credentials.payload || credentials.payload.records.length === 0) && (
            <div className={classes.noResults}>No results</div>
          )}
          {((credentials.payload && credentials.payload.records) || []).map((item, index) => (
            <div
              className={classNames(
                classes.inboxRow,
                {
                  [classes.selectedInboxRow]: item.documentId === selectedDocumentId
                },
                { [classes.firstInboxRow]: index === 0 }
              )}
              key={`inboxRow-${index}`}
              onMouseLeave={() => {
                this.showCheckbox(null);
              }}
            >
              <div className={classes.imageOrCheckbox}>
                {(showCheckbox === item.documentId || selectedRows.indexOf(item.documentId) !== -1) && (
                  <Checkbox
                    checked={selectedRows.indexOf(item.documentId) !== -1}
                    onChange={() => {
                      this.toggleCheckBox(item.documentId);
                    }}
                    value={false}
                    className={classNames(classes.radio, {
                      [classes.checkBoxSelected]: selectedRows.indexOf(item.documentId) !== -1
                    })}
                  />
                )}
                {showCheckbox !== item.documentId && selectedRows.indexOf(item.documentId) === -1 && (
                  <Avatar
                    className={classes.avatar}
                    onMouseOver={() => {
                      this.showCheckbox(item.documentId);
                    }}
                    onFocus={() => {
                      this.showCheckbox(item.documentId);
                    }}
                  >
                    {getUserInitials(item.avatarUrl, item.holderName)}
                  </Avatar>
                )}
              </div>
              <div
                className={classNames(classes.rowDirection, classes.fullWidth, classes.pointer)}
                onClick={() => {
                  this.handleSelectRow(item.documentId);
                }}
              >
                <div className={classNames(classes.columnDirection, classes.fullWidth)}>
                  <div className={classNames(classes.rowDirection, classes.twoSidedContainer)}>
                    <div className={classes.holderName}>{item.holderName}</div>
                    <div className={classes.subText}>{moment(item.createDate, 'yyyy-MM-DD').format('MMM DD')}</div>
                  </div>
                  <div className={classNames(classes.rowDirection)}>
                    <div
                      className={classes.credentialBackground}
                      style={{ backgroundColor: item.credentialTypeBackgroundColor }}
                    >
                      &nbsp;
                    </div>
                    <div className={classes.twoSidedContainer}>
                      <div className={classes.subText}>{item.credentialTypeName}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {filter.filterOpen && (
          <CredentialInboxFilterDialog
            open={filter.filterOpen}
            handleClose={this.handleFilterClose}
            anchorEl={filter.anchorEl}
            search={this.search}
            order={order}
            date={date}
            assignee={assignee}
            type={type}
            expire={expire}
          />
        )}
      </Grid>
    );
  }
}

CredentialInbox.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  params: PropTypes.object,
  table: PropTypes.object,
  credential: PropTypes.object,
  getEntity: PropTypes.func,
  putEntity: PropTypes.func,
  listEntities: PropTypes.func,
  createInitialTable: PropTypes.func,
  clearEntity: PropTypes.func,
  clearFilter: PropTypes.bool,
  handleClearFilter: PropTypes.func,
  userList: PropTypes.array
};

const mapStateToProps = state => ({
  ...tableSelector(state, 'credentials'),
  ...entitySelector(state, 'credential'),
  clearFilter: settingSelector(state, 'clearFilter')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { listEntities, clearEntity, getEntity, putEntity, createInitialTable, toggleSetting }),
  withRouter
)(CredentialInbox);

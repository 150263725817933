import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { getEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import SectionHeading from '../common/SectionHeading';
import NewHeaderViewActions from '../header/NewHeaderViewActions';
import { entitySelector } from '../../store/selectors/entity';
import { getTeamRequest } from '../../utils/entityRequests/teamRequests';

const styles = {
  ...layout,
  ...style
};

class TeamView extends React.Component {
  componentDidMount() {
    const { getEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    getEntity(getTeamRequest(id));
  }

  render() {
    const { classes, match, team } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      Object.keys(team).length > 0 && (
        <Grid
          container
          className={classNames(classes.mainContentColumn, classes.flexNowrap, {
            [classes.editMode]: false
          })}
        >
          <NewHeaderViewActions
            editUrl={`/organisation/team/edit/${id}`}
            header="Team Details"
            cancelUrl="/organisation/team/"
          />
          <Grid item xs={6}>
            <div className={classes.displayLabelAndField}>
              <label className={classes.displayLabel}>Name</label>
              <div className={classes.displayField}>{team.name}</div>
            </div>
            <SectionHeading heading="Members" underlined />
            {team.users.map(user => (
              <div className={classes.displayLabelAndField} key={`team-user-${user.identifier}`}>
                <div className={classes.displayField}>{user.name}</div>
              </div>
            ))}
          </Grid>
        </Grid>
      )
    );
  }
}

TeamView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  team: PropTypes.object
};

const mapStateToProps = state => ({
  ...entitySelector(state, 'team')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity }))(TeamView);

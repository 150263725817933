import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME, OBSERVATION_STATUS_TYPE } from '../../assets/constants';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { observationInitialState, postInitialState } from '../../utils/entityInitialStates';
import { getObservationRequest, saveObservationRequest } from '../../utils/entityRequests/workPlaceRequests';
import editor from '../../assets/editor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';

const styles = () => ({
  ...style,
  ...layout,
  ...editor
});

class ChangeObservationView extends Component {
  constructor(props) {
    super(props);

    const { id, observationId } = props.match.params;

    this.state = {
      observation: {
        ...observationInitialState,
        workPlace: {
          identifier: id
        }
      }
    };

    this.cancelUrl = observationId
      ? `/workplace/location/view/${id}/observation/view/${observationId}`
      : `/workplace/location/view/${id}/observation`;
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;
    const { params } = match;
    const { id } = params;

    if (this.isEdit) {
      const observation = await getEntity(getObservationRequest(id));

      this.setState({ observation });
    } else {
      this.setState({
        observation: {
          ...observationInitialState,
          workPlace: {
            identifier: id
          }
        }
      });
    }
  }

  componentWillUnmount() {
    this.setState({ observation: observationInitialState });
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { observation } = this.state;

    this.setState({
      observation: {
        ...observation,
        [name]: value
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history, match } = this.props;
    const { observation } = this.state;
    const { params } = match;
    const { id } = params;

    const observationId = await saveEntity(saveObservationRequest(observation));

    if (!observationId) return;

    history.push(`/workplace/location/view/${id}/observation/view/${observationId}`);
  };

  render() {
    const { classes, isSaving, errors } = this.props;
    const { observation } = this.state;
    const observationStatuses = [
      OBSERVATION_STATUS_TYPE.NEW,
      OBSERVATION_STATUS_TYPE.ACTIONED,
      OBSERVATION_STATUS_TYPE.DISREGARDED,
      OBSERVATION_STATUS_TYPE.NOTED
    ];
    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="editObservationForm"
              header="Observation Details"
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="title"
                      label="Title"
                      name="title"
                      value={observation.title}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="description"
                      label="Description"
                      name="description"
                      value={observation.description}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      multiline
                    />
                  </div>
                </Grid>
                {this.isEdit && (
                  <Grid item xs={12}>
                    <div className={classes.fieldContainer}>
                      <NewValidatedField
                        type={VALIDATED_FIELD_TYPES.SELECT}
                        key="status"
                        label="Status"
                        value={observationStatuses.find(item => item.value === observation.status) || null}
                        onChange={this.handleChange}
                        items={observationStatuses}
                        id="status"
                        itemName="label"
                        itemValue="value"
                        validators={[VALIDATORS_BY_NAME.REQUIRED]}
                      />
                    </div>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeObservationView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  observation: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'observation'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangeObservationView);

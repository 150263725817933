import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox, FormGroup } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';

import { style } from '../../assets/style';
import { colors } from '../../assets/constants';

const styles = {
  ...style,
  formControl: {
    marginTop: '16px',
    marginBottom: '8px',
    width: '100%'
  },
  textFieldContainer: {
    borderBottom: 'none'
  },
  radioLabel: {
    fontSize: '12px'
  },
  radio: {
    '& > span > svg': {
      height: '0.75em',
      width: '0.75em'
    }
  },
  selected: {
    '& > span > svg': {
      color: colors.orangeDark
    }
  },
  radioGroup: {
    backgroundColor: '#fafafa !important',
    padding: '20px 0px 10px 13px',
    borderBottomColor: `${colors.grey4} !important`,
    borderBottom: `solid 1px ${colors.grey4}`
  }
};

function CheckBoxes({
  classes,
  error,
  id,
  value,
  values,
  optionValue,
  optionName,
  label,
  handleChange,
  overrideClassName,
  labelConfig,
  helperText,
  disabled,
  name
}) {
  let options = null;

  if (values && Object.getOwnPropertyNames(values).length !== 0) {
    options = values.map((v, idx) => {
      return (
        <FormControlLabel
          value={optionValue ? v[optionValue] : v}
          control={
            /* eslint-disable react/jsx-wrap-multilines */
            <Checkbox
              checked={value.includes(optionValue ? v[optionValue] : v)}
              onChange={() => handleChange(name, optionValue ? v[optionValue] : v)}
              value={optionValue ? v[optionValue] : v}
              className={classNames(classes.radio, {
                [classes.selected]: value.includes(optionValue ? v[optionValue] : v)
              })}
            />
            /* eslint-enable react/jsx-wrap-multilines */
          }
          label={v[optionName]}
          key={`${idx}optionName`}
          className={classes.radioOptionLabel}
        />
      );
    });
  }

  return values ? (
    <FormControl
      className={classNames(
        { [overrideClassName]: !!overrideClassName },
        { [classes.formControl]: !overrideClassName },
        classes.radioGroup,
        classes.underline
      )}
      component="fieldset"
      error={!!error}
      variant="filled"
      disabled={disabled}
    >
      <FormLabel {...labelConfig} htmlFor={id} className={classNames(classes.label, classes.radioLabel)}>
        {label}
      </FormLabel>
      <FormGroup>{options}</FormGroup>
      {helperText && <FormHelperText className={classes.fieldHelperText}>{helperText}</FormHelperText>}
      {error && (
        <FormHelperText className={classes.fieldHelperText} style={{ marginLeft: 0 }}>
          {' '}
          {error}
        </FormHelperText>
      )}
    </FormControl>
  ) : null;
}

CheckBoxes.defaultProps = {
  disabled: false
};

CheckBoxes.propTypes = {
  optionName: PropTypes.string.isRequired,
  optionValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  changeParameter: PropTypes.string,
  classes: PropTypes.object.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.array,
  values: PropTypes.array,
  handleChange: PropTypes.func,
  overrideClassName: PropTypes.string,
  inputConfig: PropTypes.object,
  selectConfig: PropTypes.object,
  labelConfig: PropTypes.object,
  helperText: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool
};

export default withStyles(styles)(CheckBoxes);

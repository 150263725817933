import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import layout from '../../assets/layout';
import { searchOrganisationMemberRequest } from '../../utils/entityRequests/organisationMemberRequests';
import { addWorkPlaceMemberRequest } from '../../utils/entityRequests/workPlaceRequests';
import { entitySelector } from '../../store/selectors/entity';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';
import DisplayField from '../widgets/DisplayField';

const styles = {
  ...style,
  ...layout,
  buttonSpace: {
    marginTop: '20px'
  }
};

class InviteWorkPlaceMemberView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searched: false
    };
  }

  actionNavigate = id => {
    const { history } = this.props;
    history.push(`/workplace/location/view/${id}/member`);
  };

  handleChange = event => {
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  };

  searchHolder = async () => {
    const { getEntity } = this.props;
    const { searchText } = this.state;
    await getEntity(searchOrganisationMemberRequest(searchText));

    this.setState({
      searched: true
    });
  };

  addWorkPlaceHolder = async () => {
    const { organisationMemberSearch, history, saveEntity, match } = this.props;
    const { params } = match;
    const { id } = params;
    await saveEntity(addWorkPlaceMemberRequest(id, { userUuid: organisationMemberSearch.uuid }));
    history.push(`/workplace/location/view/${id}/member`);
  };

  render() {
    const { classes, organisationMemberSearch, isSaving, match } = this.props;
    const { params } = match;
    const { id } = params;
    const { searchText, searched } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={`/workplace/location/view/${id}/member`}
              isSaving={isSaving}
              onSubmit={this.addWorkPlaceHolder}
              name="changeFormHeaderAction"
              header="Add Member"
              submitButtonText="Save"
              hasSubmitBtn={searched && organisationMemberSearch.uuid}
            />
            <Grid item xs={12} container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <NewValidatedField
                    id="searchText"
                    label="Search Email / Phone"
                    name="searchText"
                    value={searchText}
                    onChange={this.handleChange}
                    validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    inputTextProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <i
                            className={classNames('material-icons', classes.icon, classes.iconAction)}
                            onClick={this.searchHolder}
                          >
                            search
                          </i>
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
                {searched && organisationMemberSearch.uuid && (
                  <Grid item xs={12}>
                    <DisplayField heading="Name" value={organisationMemberSearch.name} />
                    <DisplayField heading="Email" value={organisationMemberSearch.email} />
                    <DisplayField heading="Phone" value={organisationMemberSearch.phone} />
                  </Grid>
                )}
                {searched && !organisationMemberSearch.uuid && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <div className={classes.displayLabelAndField}>
                        <label className={classes.displayLabel}>The holder could not be found.</label>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          {searched && organisationMemberSearch.uuid && (
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton isSaving={isSaving} isDisabled={false} text="Save" onSubmit={this.addWorkPlaceHolder} />
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

InviteWorkPlaceMemberView.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  history: PropTypes.object,
  saveEntity: PropTypes.func,
  match: PropTypes.func,
  getEntity: PropTypes.func,
  organisationMemberSearch: PropTypes.object
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  isSaving: loadingSelector(state, 'saveEntity'),
  ...entitySelector(state, 'organisationMemberSearch')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { saveEntity, getEntity })
)(InviteWorkPlaceMemberView);

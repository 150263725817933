import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';

import classNames from 'classnames';
import logo from '../../images/logo.png';
import { resetPasswordWithCode } from '../../store/actions/AuthenticationActions';
import ErrorDialog from '../widgets/ErrorDialog';
import authStyles from '../../assets/authStyles';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import NewValidatedField from '../widgets/NewValidatedField';
import { passwordResetSelector } from '../../store/selectors/authentication';
import { loadingSelector } from '../../store/selectors/shared';
import NewActionButton from '../widgets/NewActionButton';
import { ReactComponent as RightArrowIcon } from '../../assets/images/icon-right-arrow.svg';

const styles = {
  ...authStyles,
  marginBottom: {
    marginBottom: '15px'
  }
};

class PasswordResetView extends React.Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      passwordResetModel: {
        userId: match.params.userId,
        password: '',
        repeatedPassword: '',
        code: match.params.codeParam
      }
    };
  }

  static getDerivedStateFromProps(props) {
    const { history, sent } = props;
    if (sent) {
      history.push('/login');
    }

    return props;
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { passwordResetModel } = this.state;
    this.setState({
      passwordResetModel: {
        ...passwordResetModel,
        [name]: value
      }
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    const { passwordResetModel } = this.state;
    const { resetPasswordWithCode } = this.props;
    await resetPasswordWithCode(passwordResetModel);
  };

  render() {
    const { classes, errors, isSaving, match } = this.props;
    const { params } = match;
    const { codeParam } = params;
    const { passwordResetModel } = this.state;
    const { password, repeatedPassword, code } = passwordResetModel;
    const commonProps = {
      onChange: this.handleChange,
      validators: [VALIDATORS_BY_NAME.REQUIRED],
      autoComplete: 'off'
    };
    console.log(codeParam);
    return (
      <Grid container justify="center" alignContent="center" alignItems="center" className={classes.container}>
        <Grid item xs={12} className={classes.minHeight}>
          <img src={logo} alt="Repple Logo" className={classes.logo} />
          <div className={classNames(classes.formContainer, classes.passwordContainer)}>
            {errors && errors.objectErrors && (
              <FormHelperText error>
                {errors.objectErrors.map(error => {
                  return error;
                })}
              </FormHelperText>
            )}

            {codeParam && <div className={classes.marginBottom}>Please enter a new password.</div>}
            {!codeParam && (
              <Fragment>
                <div className={classes.marginBottom}>A verification code has been emailed to you.</div>
                <NewValidatedField id="code" name="code" label="Code" value={code} {...commonProps} />
              </Fragment>
            )}

            <NewValidatedField
              id="password"
              name="password"
              label="Password"
              value={password}
              type="PASSWORD"
              {...commonProps}
            />

            <NewValidatedField
              id="repeatedPassword"
              name="repeatedPassword"
              label="Confirm Password"
              value={repeatedPassword}
              type="PASSWORD"
              {...commonProps}
            />
            <div className={classes.bottomFields}>
              <Link to="/login" className={classes.cancelLink}>
                Cancel
              </Link>
              <NewActionButton
                onChange={this.handleSubmit}
                text="Change Password"
                icon={<RightArrowIcon />}
                primary
                filled
                isSaving={isSaving}
                style={{ width: '246px' }}
              />
            </div>
          </div>
          <ErrorDialog />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  ...passwordResetSelector(state),
  isSaving: loadingSelector(state, 'resetPasswordWithCode')
});

PasswordResetView.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object,
  errors: PropTypes.string,
  goToLogin: PropTypes.bool,
  isSaving: PropTypes.bool,
  resetPasswordWithCode: PropTypes.func
};

export default connect(mapStateToProps, { resetPasswordWithCode })(withStyles(styles)(PasswordResetView));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import FormHelperText from '@material-ui/core/FormHelperText';
import { getEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { getFirstPath, isEdit } from '../../utils/utilities';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import { entityWithErrorsSelector } from '../../store/selectors/entity';
import { postInitialState } from '../../utils/entityInitialStates';
import { getPostRequest, savePostRequest } from '../../utils/entityRequests/postRequests';
import editor from '../../assets/editor';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout,
  ...editor
});

class ChangePostView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      post: postInitialState,
      editorState: EditorState.createEmpty()
    };

    const { id } = this.props.match.params;
    this.cancelUrl = id ? `${this.getPath()}/post/view/${id}` : `${this.getPath()}/post`;
    this.isEdit = isEdit(this.props.location.pathname);
  }

  async componentDidMount() {
    const { match, getEntity } = this.props;

    if (this.isEdit) {
      const post = await getEntity(getPostRequest(match.params.id));
      const blocksFromHTML = htmlToDraft(post.content);
      const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

      this.setState({ post, editorState: EditorState.createWithContent(state) });
    } else {
      this.setState({
        post: postInitialState
      });
    }
  }

  componentWillUnmount() {
    this.setState({ post: postInitialState });
  }

  getPath = () => {
    const { location } = this.props;
    const { pathname } = location;
    return getFirstPath(pathname);
  };

  handleEditorChange = event => {
    const { post } = this.state;
    const postResult = _.cloneDeep(post);

    if (event.getCurrentContent().hasText()) {
      postResult.content = draftToHtml(convertToRaw(event.getCurrentContent()));
    } else postResult.description = '';

    this.setState({
      editorState: event,
      post: postResult
    });
  };

  handleChange = event => {
    const { name, value } = event.target;
    const { post } = this.state;

    this.setState({
      post: {
        ...post,
        [name]: value
      }
    });
  };

  submitForm = async e => {
    e.preventDefault();
    const { saveEntity, history } = this.props;
    const { post } = this.state;

    const postId = await saveEntity(savePostRequest(post));

    if (!postId) return;

    history.push(`${this.getPath()}/post/view/${postId}`);
  };

  render() {
    const { classes, isSaving, errors } = this.props;
    const { post, editorState } = this.state;

    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="editReasonForm"
              header="Incident Details"
            />
            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="name"
                      label="Name"
                      name="name"
                      value={post.name}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classNames(classes.fieldContainer, classes.editorContainer)}>
                    <Editor
                      editorState={editorState}
                      toolbarClassName={classes.toolbarClassName}
                      wrapperClassName="wrapperClassName"
                      editorClassName={classes.editorClassName}
                      onEditorStateChange={this.handleEditorChange}
                    />
                    {errors && errors.fieldErrors.content && (
                      <FormHelperText error>{errors.fieldErrors.content}</FormHelperText>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton isSaving={isSaving} onSubmit={this.submitForm} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangePostView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getEntity: PropTypes.func.isRequired,
  saveEntity: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  isSaving: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  post: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'post'),
  isSaving: loadingSelector(state, 'saveEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { getEntity, saveEntity }),
  withRouter
)(ChangePostView);

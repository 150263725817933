import React from 'react';
import Grid from '@material-ui/core/Grid';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { loadingSelector } from '../../store/selectors/shared';
import { getEntity, listEntities } from '../../store/actions/EntityActions';
import { style } from '../../assets/style';
import ScrollableTable from '../table/ScrollableTable';
import { listHolderRepRequest } from '../../utils/entityRequests/holderRequests';
import { REP_TYPE } from '../../assets/constants';
import ListingWithSearch from '../widgets/ListingWithSearch';
import { tableSelector } from '../../store/selectors/table';
import { getRecords, convertUtcToLocal } from '../../utils/utilities';

const styles = {
  ...style
};

class HolderRepListView extends React.Component {
  getRowConfigs = () => {
    const { search, table } = this.props;
    const items = getRecords(search, table);

    return items.map(rep => ({
      cells: [
        {
          value: rep.points
        },
        {
          value: REP_TYPE[rep.repType].label
        },
        {
          value: convertUtcToLocal('DD-MM-YYYY HH:mm', 'DD-MM-YYYY HH:mm', rep.date)
        }
      ]
    }));
  };

  listHolderReps = async (page, pageSize, additionalSearchParam) => {
    const { listEntities } = this.props;
    await listEntities(0, 20, additionalSearchParam.request(additionalSearchParam.id));
  };

  getListing = props => {
    const { isLoading } = this.props;

    return (
      <ScrollableTable
        headers={[{ value: 'Points' }, { value: 'Type' }, { value: 'Date' }]}
        rowConfigs={this.getRowConfigs()}
        complete={!isLoading}
        emptyMessage="No Reps Found"
        {...props}
      />
    );
  };

  render() {
    const { classes, table, match } = this.props;
    const { params } = match;
    const { id } = params;

    return (
      <div>
        <Grid container>
          <Grid item xs={12} className={classes.noPaddingLeftRightGrid}>
            <ListingWithSearch
              name="reps"
              onList={this.listHolderReps}
              renderList={props => this.getListing(props)}
              list={table}
              hideSearch
              additionalSearchParams={{ id, request: listHolderRepRequest }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

HolderRepListView.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object,
  params: PropTypes.object,
  getEntity: PropTypes.func,
  isLoading: PropTypes.bool,
  history: PropTypes.object,
  holderCredentials: PropTypes.array
};

const mapStateToProps = state => ({
  isLoading: loadingSelector(state, 'getEntity'),
  ...tableSelector(state, 'reps')
});

export default compose(withStyles(styles), connect(mapStateToProps, { getEntity, listEntities }))(HolderRepListView);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import { entityArraySelector, entityWithErrorsSelector } from '../../store/selectors/entity';
import { clearUserFileDownloads, requestPublicImageUploadUrl, uploadToS3 } from '../../store/actions/DocumentActions';
import { getEntity, putEntity, saveEntity } from '../../store/actions/EntityActions';
import { loadingSelector } from '../../store/selectors/shared';
import { style } from '../../assets/style';
import layout from '../../assets/layout';
import { getProfileRequest, saveProfileRequest } from '../../utils/entityRequests/userRequests';
import { profileInitialState } from '../../utils/entityInitialStates';
import { VALIDATORS_BY_NAME } from '../../assets/constants';
import { isUploadingCompleted } from '../../utils/utilities';
import { VALIDATED_FIELD_TYPES } from '../../constants/global';
import AvatarUpload from '../widgets/AvatarUpload';
import { documentUploadsSelector } from '../../store/selectors/document';
import NewHeaderEditActions from '../header/NewHeaderEditActions';
import NewValidatedField from '../widgets/NewValidatedField';
import SaveButton from '../widgets/SaveButton';

const styles = () => ({
  ...style,
  ...layout,
  marginTop: {
    marginTop: '10px'
  }
});

class ChangeProfileView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: profileInitialState,
      imageUpload: null
    };

    this.cancelUrl = '/profile/view';
  }

  async componentDidMount() {
    const { getEntity } = this.props;

    const profile = await getEntity(getProfileRequest());
    this.setState({ profile });
  }

  componentWillUnmount() {
    const { clearUserFileDownloads } = this.props;
    this.setState({ profile: profileInitialState });
    clearUserFileDownloads();
  }

  handleChange = event => {
    const { name, value } = event.target;
    const { profile } = this.state;
    const profileModel = _.cloneDeep(profile);
    _.set(profileModel, name, value);

    this.setState({
      profile: profileModel
    });
  };

  handleImageUpload = imageUpload => {
    this.setState({
      imageUpload
    });
  };

  handleClearImageUpload = () => {
    const { profile } = this.state;
    const profileResult = _.cloneDeep(profile);
    profileResult.avatarKey = '';
    profileResult.avatarUrl = '';

    this.setState({
      profile: profileResult
    });
  };

  submitForm = async e => {
    const { putEntity, history, requestPublicImageUploadUrl, uploadToS3 } = this.props;
    const { profile, imageUpload } = this.state;

    const profileResult = _.cloneDeep(profile);

    if (imageUpload && !profileResult.avatarKey) {
      const upload = await requestPublicImageUploadUrl(imageUpload);
      const response = await uploadToS3([{ url: upload.url, control: 'profileImage' }], [imageUpload]);
      if (response[0].status === 200) profileResult.avatarKey = upload.key;
    }

    const profileId = await putEntity(saveProfileRequest(profileResult));

    if (!profileId) return;

    history.push('/profile/view');
  };

  render() {
    const { classes, isSaving, documentUploads } = this.props;
    const { profile } = this.state;
    return (
      <Grid
        container
        className={classNames(classes.mainContentColumn, {
          [classes.editMode]: this.isEdit
        })}
      >
        <Grid container className={`${classes.viewPage} ${classes.page}`} alignContent="flex-start">
          <Grid item xs={12}>
            <NewHeaderEditActions
              cancelUrl={this.cancelUrl}
              isSaving={isSaving}
              onSubmit={this.submitForm}
              name="changeFormHeaderAction"
              isDisabled={!isUploadingCompleted(documentUploads)}
              header="Profile Details"
            />

            <Grid container className={classes.formContent}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <div className={classNames(classes.fieldContainer)}>
                    <NewValidatedField
                      id="firstName"
                      label="First Name"
                      name="firstName"
                      value={profile.firstName}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      id="lastName"
                      label="Last Name"
                      name="lastName"
                      value={profile.lastName}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classes.fieldContainer}>
                    <NewValidatedField
                      type={VALIDATED_FIELD_TYPES.PHONE}
                      id="phone"
                      label="Phone"
                      name="phone"
                      value={profile.phone}
                      onChange={this.handleChange}
                      validators={[VALIDATORS_BY_NAME.REQUIRED]}
                    />
                  </div>
                  <div className={classNames(classes.fieldContainer, classes.marginTop)}>
                    <label className={classes.displayLabel}>Avatar</label>
                    <AvatarUpload
                      width={150}
                      height={150}
                      handleImageUpload={this.handleImageUpload}
                      clearImageUpload={this.handleClearImageUpload}
                      uploadStatus={
                        documentUploads && documentUploads.organisationImage ? documentUploads.organisationImage : []
                      }
                      isSaving={isSaving}
                      image={profile ? profile.avatarUrl : ''}
                      round
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.bottomSaveButton}>
              <SaveButton
                isSaving={isSaving}
                onSubmit={this.submitForm}
                isDisabled={!isUploadingCompleted(documentUploads)}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ChangeProfileView.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  getEntity: PropTypes.func.isRequired,
  uploadToS3: PropTypes.func.isRequired,
  clearUserFileDownloads: PropTypes.func.isRequired,
  requestPublicImageUploadUrl: PropTypes.func.isRequired,
  saveEntity: PropTypes.func,
  putEntity: PropTypes.func,
  documentUploads: PropTypes.array,
  match: PropTypes.object,
  isSaving: PropTypes.bool,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  ...entityWithErrorsSelector(state, 'role'),
  ...entityArraySelector(state, 'permissions'),
  ...documentUploadsSelector(state),
  isSaving: loadingSelector(state, 'putEntity')
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getEntity,
    saveEntity,
    putEntity,
    uploadToS3,
    requestPublicImageUploadUrl,
    clearUserFileDownloads
  }),
  withRouter
)(ChangeProfileView);

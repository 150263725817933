// Define the whole app style guide here
import { createMuiTheme } from '@material-ui/core';

export const globalSpacing = 32;
export const globalMaterialUiSpacing = globalSpacing / 4;
export const globalSpacingPixels = `${globalSpacing}px`;
export const globalHalfSpacingPixels = `${globalSpacing / 2}px`;
export const globalQuarterSpacingPixels = `${globalSpacing / 4}px`;
export const globalDoubleSpacing = globalSpacing * 2;
export const globalDoubleSpacingPixels = `calc(${globalSpacing * 2}px)`;
export const headerHeight = 60;
export const duration = '200ms';

export const colors = {
  blue: '#8798AD',
  black: '#000',
  blueLight: '#2196f3',
  blueDark: '#251B54',
  white: '#ffffff',
  red: '#e57373',
  yellow: '#ffc107',
  grey1: '#fafafa',
  grey2: '#e0e0e0',
  grey3: '#f5f5f5',
  grey4: '#999999',
  grey5: 'rgba(245, 245, 245)',
  grey6: '#3C4858',
  orangeDark: 'rgba(241,90,41)',
  orangeLight: '#FFF6E8',
  orangeNew: '#F68A1A',
  orangeBackground: '#F37817',
  reokeOrgange: '#F78B0E',
  orangeDarkHalf: 'rgba(241,90,41,0.5)',
  fontGreyDark: '#424242',
  fontGreyLight: 'rgba(0, 0, 0, 0.4)',
  greyBackGround: 'rgba(58, 56, 56)',
  greyIcon: 'rgba(118, 118, 118)',
  fontGreyLessLight: 'rgba(0, 0, 0, 0.6)',
  fontLessWhite: 'rgba(255, 255, 255, 0.6)',
  green: '#26a69a',
  errorRed: '#f44336',
  purple: '#673ab7',
  purpleTransparent: 'rgba(103, 58, 183, 0.06)',
  dirtyWhiteTransparent: 'rgba(241, 241, 241, 0.6)',
  grey1Transparent: 'rgba(240, 240, 240, 0.4)',
  backgroundBlue: '#F0F6F9',
  fontLightGrey: '#8798AD',
  borderBlue: '#D3DBDF',
  spacerBlue: '#E5EAEC',
  lightGreen: '#8AC350',
  lighterGreen: '#F3F9EE',
  expiringYellow: '#FEC450',
  expiredRed: '#EC2D37'
};

export const paddings = {
  noSidePadding: {
    paddingLeft: 0,
    paddingRight: 0
  }
};

export const sizes = {
  globalSide: `0 ${globalSpacingPixels}`,
  globalHalfSide: `0 ${globalHalfSpacingPixels}`,
  headerHeight: `${headerHeight}px`,
  smallNavigationWidth: globalDoubleSpacingPixels,
  halfSpacing: globalHalfSpacingPixels,
  contentHeight: `calc(100vh - ${headerHeight}px)`,
  fontTiny: '10px',
  fontSmall: '12px',
  fontNormal: '14px',
  fontLarge: '15px',
  fontBig: '18px',
  chartHeight: 300,
  chartHeightPixels: '300px'
};

export const commonFont = {
  fontFamily: 'Encode Sans, sans-serif',
  fontWeight: '400',
  fontStyle: 'normal'
};

export const textStyles = {
  organisationSelectionText: {
    ...commonFont,
    color: '#F0CC6F',
    fontSize: '28px',
    fontWeight: 500
  }
};

export const border = {
  style_1px: `solid 1px ${colors.borderBlue}`
};

export const tableErrorBorder = {
  borderBottom: `solid 2px ${colors.errorRed}`
};

export const commonStyles = {
  transition: `all ${duration} cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
  hiddenLeft: 'translateX(-100%)',
  visible: 'translateX(0)'
};

export const overlayHidden = {
  transition: commonStyles.transition,
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  opacity: 0,
  zIndex: -1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export const overlayVisible = {
  zIndex: 10000,
  opacity: 1
};

export const textEllipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

export const VALIDATORS_BY_NAME = {
  REQUIRED: 'required',
  TAG: 'tag',
  STRING: 'string',
  NUMBER: 'number',
  NATURAL_NUMBER: 'natural_number',
  LOWERCASE: 'lowercase',
  MAX_LENGTH: 'max_length',
  DECIMAL_NUMBERS_ONLY_REGEX: 'max_length'
};

export const FORM_PRIORITY = {
  A_CRITICAL: { label: 'Critical', value: 'A_CRITICAL' },
  B_URGENT: { label: 'Urgent', value: 'B_URGENT' },
  C_STANDARD: { label: 'Standard', value: 'C_STANDARD' }
};

export const FORM_STATUS_TYPE = {
  PUBLISHED: { label: 'Published', value: 'PUBLISHED' },
  DEPRECATED: { label: 'Deprecated', value: 'DEPRECATED' },
  DRAFT: { label: 'Draft', value: 'DRAFT' }
};

export const OBSERVATION_STATUS_TYPE = {
  NEW: { label: 'New', value: 'NEW' },
  NOTED: { label: 'Noted', value: 'NOTED' },
  ACTIONED: { label: 'Actioned', value: 'ACTIONED' },
  DISREGARDED: { label: 'Disregarded', value: 'DISREGARDED' }
};

export const CREDENTIAL_REQUEST_STATUS_TYPE = {
  CREATED: { label: 'Received', value: 'CREATED' },
  ASSIGNED: { label: 'Assigned', value: 'ASSIGNED' },
  COMPLETE: { label: 'Complete', value: 'COMPLETE' },
  FAILED: { label: 'Rejected', value: 'FAILED' }
};

export const CREDENTIAL_REQUEST_TYPE = {
  CREDENTIAL: { label: 'Credential', value: 'CREDENTIAL' },
  CPD: { label: 'CPD', value: 'CPD' }
};

export const CREDENTIAL_STATUS_TYPE = {
  IN_VALID: { label: 'In Valid', value: 'IN_VALID', icon: 'close' },
  VERIFIED: { label: 'Verified', value: 'VERIFIED', icon: 'check' },
  VALID: { label: 'Valid', value: 'VALID', icon: 'check' },
  EXPIRED: { label: 'Expired', value: 'EXPIRED', icon: 'warning' },
  UNVERIFIED: { label: 'Pending', value: 'UNVERIFIED', icon: 'warning' },
  SELF_ATTESTED: { label: 'Self Attested', value: 'SELF_ATTESTED', icon: 'warning' }
};

export const CHECK_TYPE = {
  SCANNED: { label: 'Scanned', value: 'SCANNED' },
  CHECK_IN: { label: 'Check In', value: 'CHECK_IN' }
};

export const REP_TYPE = {
  CREATE_ACCOUNT: { label: 'Create Account', value: 'CREATE_ACCOUNT' },
  COMPLETE_PROFILE: { label: 'Complete Profile', value: 'COMPLETE_PROFILE' },
  NEW_VERIFIED_CREDENTIAL: { label: 'Credential Verified', value: 'NEW_VERIFIED_CREDENTIAL' },
  NOTED_POST_REACTION: { label: 'Post Noted', value: 'NOTED_POST_REACTION' },
  NOTED_CREDENTIAL_CHECK_REACTION: { label: 'Credential Check Noted', value: 'NOTED_CREDENTIAL_CHECK_REACTION' },
  NEW_FEEDBACK: { label: 'Feedback Provided', value: 'NEW_FEEDBACK' },
  FEEDBACK_REPLY: { label: 'Feedback Replied Too', value: 'FEEDBACK_REPLY' },
  RAISE_OBSERVATION: { label: 'Observation Raised', value: 'RAISE_OBSERVATION' },
  WORKPLACE_ATTENDANCE: { label: 'Workplace Attendance', value: 'WORKPLACE_ATTENDANCE' },
  INVITE_CO_WORKER: { label: 'Invite Co-Worker', value: 'INVITE_CO_WORKER' },
  HEALTH_STATUS: { label: 'Health Status Provided', value: 'HEALTH_STATUS' },
  CREDENTIAL_SCAN: { label: 'Credential Scanned', value: 'CREDENTIAL_SCAN' },
  REPORT_INCIDENT: { label: 'Incident Reported', value: 'REPORT_INCIDENT' },
  EXPERIENCE_FEEDBACK: { label: 'Experience Feedback', value: 'EXPERIENCE_FEEDBACK' },
  WORKPLACE_RATING: { label: 'Workplace Rating', value: 'WORKPLACE_RATING' }
};

export const CONTROL_TYPE = {
  TEXT: {
    id: 'TEXT',
    label: 'Text'
  },
  MULTIPLE_CHOICE: {
    id: 'MULTIPLE_CHOICE',
    label: 'Multiple Choice'
  },
  CHECKBOXES: {
    id: 'CHECKBOXES',
    label: 'Checkboxes'
  },
  DROP_DOWN: {
    id: 'DROP_DOWN',
    label: 'Drop Down'
  },
  DATE: {
    id: 'DATE',
    label: 'Date'
  },
  FILE: {
    id: 'FILE',
    label: 'File Upload'
  }
};

export const PERIOD_TYPE = {
  HOUR: {
    id: 'HOUR',
    label: 'Hours'
  },
  DAY: {
    id: 'DAY',
    label: 'Days'
  },
  MINUTE: {
    id: 'MINUTE',
    label: 'Minutes'
  }
};

export const DIFFICULTY_TYPE = {
  EASY: {
    id: 'EASY',
    label: 'Easy'
  },
  MEDIUM: {
    id: 'MEDIUM',
    label: 'Medium'
  },
  HARD: {
    id: 'HARD',
    label: 'Hard'
  }
};

export const LOCATION_TYPE = {
  ONLINE: {
    id: 'ONLINE',
    label: 'Online'
  },
  IN_CLASSROOM: {
    id: 'IN_CLASSROOM',
    label: 'In Classroom'
  }
};

export const COURSE_STATUS_TYPE = {
  PUBLISHED: { label: 'Published', value: 'PUBLISHED' },
  DEPRECATED: { label: 'Deprecated', value: 'DEPRECATED' },
  DRAFT: { label: 'Draft', value: 'DRAFT' }
};

export const FILE_UPLOAD_STATUS = {
  CLEAN: {
    id: 'CLEAN',
    label: 'Clean'
  },
  RESIZED_THUMBNAIL: {
    id: 'RESIZED_THUMBNAIL',
    label: 'Resized & Thumbnail'
  },
  LINKED: {
    id: 'LINKED',
    label: 'Linked'
  },
  INFECTED: {
    id: 'INFECTED',
    label: 'Infected'
  },
  UPLOADED: {
    id: 'UPLOADED',
    label: 'Uploaded'
  },
  ERROR: {
    id: 'ERROR',
    label: 'Error'
  }
};

export const IMPORT_STATUS = {
  UPLOADED: {
    id: 'UPLOADED',
    label: 'Uploaded'
  },
  IN_PROGRESS: {
    id: 'IN_PROGRESS',
    label: 'In Progress'
  },
  FAILED: {
    id: 'FAILED',
    label: 'Failed'
  },
  COMPLETE: {
    id: 'COMPLETE',
    label: 'Complete'
  },
  VALIDATED: {
    id: 'VALIDATED',
    label: 'Validated'
  }
};

export const IMPORT_ROW_STATUS = {
  ERROR: {
    id: 'ERROR',
    label: 'Error'
  },
  VALIDATION_FAILED: {
    id: 'VALIDATION_FAILED',
    label: 'Validation Failed'
  },
  VALID: {
    id: 'VALID',
    label: 'Valid'
  },
  COMPLETE: {
    id: 'COMPLETE',
    label: 'Complete'
  }
};

export const INCIDENT_TYPE = {
  DEATH: {
    id: 'DEATH',
    label: 'Death'
  },
  SERIOUS_INJURY: {
    id: 'SERIOUS_INJURY',
    label: 'Serious Injury'
  },
  SERIOUS_ILLNESS: {
    id: 'SERIOUS_ILLNESS',
    label: 'Serious Illness'
  },
  DANGEROUS_INCIDENT: {
    id: 'DANGEROUS_INCIDENT',
    label: 'Dangerous Incident'
  }
};

export const RELATIONSHIP_TYPE = {
  WORKER: {
    id: 'WORKER',
    label: 'Worker'
  },
  SELF_EMPLOYED: {
    id: 'SELF_EMPLOYED',
    label: 'Self Employed'
  },
  MEMBER_OF_PUBLIC: {
    id: 'MEMBER_OF_PUBLIC',
    label: 'Member of Public'
  },
  LABOUR_HIRE: {
    id: 'LABOUR_HIRE',
    label: 'Labour Hire'
  },
  CONTRACTOR: {
    id: 'CONTRACTOR',
    label: 'Contractor'
  },
  GROUP_TRAINING: {
    id: 'GROUP_TRAINING',
    label: 'Group Training'
  }
};

export const FILE_UPLOAD_TYPE = {
  DOCUMENT: 'DOCUMENT',
  FACIAL_AUTHENTICATION: 'FACIAL_AUTHENTICATION'
};

export const ACTIVITY_TYPE = {
  CONFERENCE: {
    id: 'CONFERENCE',
    label: 'Conference'
  },
  TRAINING: {
    id: 'TRAINING',
    label: 'Training'
  }
};

export const EMOTION_TYPE = {
  HAPPY: {
    id: 'HAPPY',
    label: 'Happy'
  },
  NEUTRAL: {
    id: 'NEUTRAL',
    label: 'Neutral'
  },
  SAD: {
    id: 'SAD',
    label: 'Sad'
  }
};

export const dateFilterPresets = {
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  THIS_WEEK: 'THIS_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  THIS_YEAR: 'THIS_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  CUSTOM_RANGE: 'CUSTOM_RANGE'
};

export const futureDateFilterPresets = {
  TODAY: 'TODAY',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_MONTH: 'LAST_MONTH',
  LAST_QUARTER: 'LAST_QUARTER',
  NEXT_7_DAYS: 'NEXT_7_DAYS',
  THIS_MONTH: 'THIS_MONTH',
  NEXT_MONTH: 'NEXT_MONTH',
  NEXT_QUARTER: 'NEXT_QUARTER',
  THIS_YEAR: 'THIS_YEAR',
  CUSTOM_RANGE: 'CUSTOM_RANGE'
};

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.orangeNew
    }
  },
  typography: {
    fontFamily: 'Roboto, Encode Sans, sans-serif'
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: colors.blue
      }
    },
    MuiPickersToolbarText: {
      color: colors.white,
      toolbarBtnSelected: {
        color: colors.white
      },
      toolbarTxt: {
        color: colors.white
      }
    },
    MuiCircularProgress: {
      colorPrimary: colors.white
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderColor: 'rgba(135, 152, 173, 0.3)'
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: 'rgba(135, 152, 173, 0.3)'
        },
        '&$disabled $notchedOutline': {
          borderColor: 'rgba(135, 152, 173, 0.3)'
        },
        '&$focused $notchedOutline': {
          borderColor: colors.orangeNew,
          borderWidth: 2
        }
      },
      input: {
        color: colors.blue,
        zIndex: 10
      }
    },
    MuiAutocomplete: {
      endAdornment: {
        zIndex: 10
      },
      tag: {
        zIndex: 1
      }
    },
    MuiFormControl: {
      root: {
        // backgroundColor: colors.white,
        color: colors.blue
      }
    }
  }
});

export const STANDARD_CREDENTIAL_TYPE = {
  LICENSE_AND_CERTIFICATE: {
    id: 'LICENSE_AND_CERTIFICATE',
    label: 'License and certificate'
  },
  EDUCATION: {
    id: 'EDUCATION',
    label: 'Education'
  },
  PROOF_OF_EMPLOYMENT: {
    id: 'PROOF_OF_EMPLOYMENT',
    label: 'Proof of employment'
  },
  PERSONAL_DOCUMENT: {
    id: 'PERSONAL_DOCUMENT',
    label: 'Personal document'
  },
  PASSPORT: {
    id: 'PASSPORT',
    label: 'Passport'
  },
  COVID19: {
    id: 'COVID19',
    label: 'Covid-19 test result'
  }
};
